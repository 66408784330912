import React from 'react'
import calender from "../../../../assetsOld/img/Calender.svg"
const Announcements = ({data}) => {
  return (
    <div className="mt-2 calender-pop position-relative">
        <div className="img_bgc overflow-hidden">
            <img src={calender} />
        </div>
        <div className="content card_tabs w-100">
            <div className="d-flex justify-content-between align-items-center pb-2">
                <p className="heading font-m fs-14">February 2023</p>
                <div className="icons_head d-flex align-items-center gap-2">
                    <span className="box cursor-pointer">
                        <i className="icon-arrow-left2 fs-10"></i>
                    </span>
                    <span className="box cursor-pointer">
                        <i className="icon-arrow-right2 fs-10"></i>
                    </span>
                </div>
            </div>
            <div className="pb-2">
                <p className="heading">Announcements</p>
                <p className="text sm gry_clr">Wednesday, 15 February 2024</p>
            </div>
            <div className="d-flex flex-column gap-15">
                <div className="ar_co-box">
                    <p className="text font-m">Payroll January</p>
                    <p className="xsm text font-m d-flex align-items-center gap-1"><i className="icon-Group-482193 fs-10"></i> 10:00 AM</p>
                </div>
                <div className="ar_co-box">
                    <p className="text font-m">Payroll January</p>
                    <p className="xsm text font-m d-flex align-items-center gap-1"><i className="icon-Group-482193 fs-10"></i> 10:00 AM</p>
                </div>
                <div className="ar_co-box">
                    <p className="text font-m">Payroll January</p>
                    <p className="xsm text font-m d-flex align-items-center gap-1"><i className="icon-Group-482193 fs-10"></i> 10:00 AM</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Announcements