import React, { useState } from "react";
import { Nav, NavItem, NavLink } from 'reactstrap'
import PayrollTopMenuContent from "./PayrollTopMenuContent";
import PayrollMenu from "./PayrollMenu";

const PayrollTopMenu = () => {
    const MenuItems = PayrollMenu();
    const activeTabElement = MenuItems.filter((item) => item.right === true)[0]
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState(21)


    return (

        <React.Fragment>
            <PayrollTopMenuContent activeTab={activeTab} dynamicTabs={tabList} />
        </React.Fragment>

    )
}

export default PayrollTopMenu



const EmployeeTopMenu = () => {
  

   
}