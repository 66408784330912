import React, { useEffect, useReducer, useState } from "react";
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest";
import NotificationHandler from "../../../../utils/NotificationHandler";
import { enumUtil } from "../../../../utils/Enum";
import FormValidator from "../../../../utils/FormValidator";
import isNull from "../../../../utils/NullChecking";
import Loader from "../../../shared/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { CancelButton } from "../../../../actions/CancelButton";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation";
import { Calendar, DateRange } from "react-date-range";
import format from "date-fns/format";
import Modal from "react-bootstrap/Modal";
import close from "../../../../assetsOld/img/x-close.svg";
import ReactDataGrid from "react-data-grid";
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants";
import CalendarInput from "../../../../utils/CalendarInput";
import jwt_decode from "jwt-decode";
import EmployeeInformation from "../EmployeeInformation/EmployeeInformation";
import ModalFooter from "../../../shared/ModalFooter/ModalFooter";
import CustomUseRef from "../../../shared/CustomUseRef/CustomUseRef";
import AssignNewDevice from "./AssignNewDevice";
import GridColumnHeader from "../../../shared/GridColumnHeader/GridColumnHeader";

const EmployeeStockReference = (props) => {
  const { type } = { ...props };

  let token = useSelector((state) => state.TokenReducer);
  let Token = token && jwt_decode(token);
  let activeEmployee = useSelector((state) => state.EmployeeActiveTabReducer);
  let EmployeeID = null;
  if (!isNull(type) && type === "Employee") {
    EmployeeID = activeEmployee;
  } else {
    EmployeeID = Token.UserID;
  }

  let dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API;
  let EmployeeStockReference_Modal = {
    StockID: "",
    ProductName: "",
    AssignedBy: "",
    AssignedDate: "",
    IsItemDefective: false,
    ProductDetails: "",
  };
  let valModal = {};
  let ValidationModal = {
    StockIDVal: "",
    AssignedBy: "",
  };
  const OrganizationID = useSelector((state) => state.Organization);
  const [isRefresh, setIsRefresh] = useState(false);
  const [EmployeeStockReferenceData, setEmployeeStockReferenceData] = useState(
    []
  );
  const [EmployeeStockReferenceID, setEmployeeStockReferenceID] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [
    EmployeeStockReferenceValidationModal,
    setEmployeeStockReferenceValidationModal,
  ] = useState(ValidationModal);
  const [addDropDown, setAddDropDown] = useState([]);
  const [actionsbutton, setActionsbutton] = useState(false);
  const [assigndevice, setAssigndevice] = useState(false);
  const [
    EmployeeStockReferenceSaveUpdateModal,
    setEmployeeStockReferenceSaveUpdateModal,
  ] = useState({});
  const [mainEmployeeStockReferenceModal, setmainEmployeeStockReferenceModal] =
    useState(EmployeeStockReference_Modal);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [DeviceID, setDeviceID] = useState(0);
  const [delID, setDelID] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [gridHeader, handleGridHeader] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, { defaultData: '', activeSortIcon: '' })

  const CloseActionPopup = () => {
    setActionsbutton(false);
  };
  const handeActionPopUp = (e, id) => {
    e.stopPropagation();
    setActionsbutton(true);
    setSelectedRow(id);
  };

  const columns = [
    {
      key: "ProductName",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Product'} sortIcon={true} type={'string'} colKey={'ProductName'} data={EmployeeStockReferenceData} setData={setEmployeeStockReferenceData} />),

      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return (
          <div
            className="heading text-primary font-m cursor-pointer office_name_truncate"
            title={row.ProductName}
            onClick={() => AssignDevice(row?.EmployeeStockReferenceID)}
          >
            {row.ProductName}
          </div>
        );
      },
    },
    {
      key: "AssignedBy",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Assigned By'} sortIcon={true} type={'string'} colKey={'AssignedBy'} data={EmployeeStockReferenceData} setData={setEmployeeStockReferenceData} />),

      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return (
          <div className="office_name_truncate" title={row.AssignedBy}>
            {row.AssignedBy}
          </div>
        );
      },
    },
    {
      key: "AssignedDate",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Assigned Date'} sortIcon={true} type={'date'} colKey={'AssignedDate'} data={EmployeeStockReferenceData} setData={setEmployeeStockReferenceData} />),

      resizable: true,
      flex: 1,
    },
    {
      key: "ProductDetails",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Details'} sortIcon={true} type={'string'} colKey={'ProductDetails'} data={EmployeeStockReferenceData} setData={setEmployeeStockReferenceData} />),
      width: 200,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <div className="office_name_truncate" title={row.ProductDetails}>
            {row.ProductDetails}
          </div>
        );
      },
    },
    {
      key: "IsItemDefective",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Defective'} sortIcon={true} type={'string'} colKey={'IsItemDefective'} data={EmployeeStockReferenceData} setData={setEmployeeStockReferenceData} />),

      resizable: true,
      formatter: ({ row }) => {
        return <>{row?.IsItemDefective ? "Defective" : "Non-Defective"}</>;
      },
    },
    {
      key: "Actions",
      name: "Actions",
      // flex: 1,
      width: 80,
      formatter: ({ row }) => {
        return (
          <>
            <div className="">
              <i
                className="icon-menuicon cursor-pointer"
                onClick={(e) =>
                  handeActionPopUp(e, row?.EmployeeStockReferenceID)
                }
              ></i>
            </div>
            {selectedRow === row?.EmployeeStockReferenceID && (
              <CustomUseRef
                onClose={CloseActionPopup}
                IsActivePtPayments={actionsbutton}
              >
                <div className="actions_popup cursor-pointer">
                  <p
                    className="text  p-2 py-0"
                    onClick={() => AssignDevice(row?.EmployeeStockReferenceID)}
                  >
                    <i className="icon-edit-pencil me-1"></i>Edit
                  </p>
                  <p
                    className="text p-2 py-0"
                    onClick={() =>
                      handleDeleteModal(row?.EmployeeStockReferenceID)
                    }
                  >
                    <i className="icon-Group-3994 me-1"></i>Delete
                  </p>
                </div>
              </CustomUseRef>
            )}
          </>
        );
      },
    },
  ];
  ///////Calendar////////
  const [assignedDate, setAssignedDate] = useState();
  const openAssignedDate = Boolean(assignedDate);
  const handleCalendarChange = (date, type) => {
    var FormatedDateSave = format(date, DateFormat);
    var FormatedDateShow = format(date, DateFormat_dMy);
    let modal = { ...mainEmployeeStockReferenceModal };
    let saveModal = { ...EmployeeStockReferenceSaveUpdateModal };
    modal[type] = FormatedDateShow;
    setmainEmployeeStockReferenceModal(modal);
    for (var property in EmployeeStockReference_Modal) {
      if (type === property) {
        saveModal[type] = FormatedDateSave;
      }
    }
    setEmployeeStockReferenceSaveUpdateModal(saveModal);
    closeCalendarMenu();
  };
  const closeCalendarMenu = () => {
    setAssignedDate(null);
  };
  const openCalendarMenu = (event, type) => {
    if (type === "AssignedDate") {
      setAssignedDate(event.currentTarget);
    } else {
      console.log("No Type Exist");
    }
  };
  ////////////////////////
  const handleDeleteModal = (EmployeeStockReferenceID) => {
    setDelID(EmployeeStockReferenceID);
    setIsOpenAlert(true);
  };
  const onCloseDelete = () => {
    setmainEmployeeStockReferenceModal(EmployeeStockReference_Modal);
    setEmployeeStockReferenceID(0);
    setIsOpenAlert(false);
    LoadData();
  };
  const onClose = () => {
    setIsOpenAlert(false);
  };
  let ConfirmDelete = "";
  if (isOpenAlert) {
    ConfirmDelete = (
      <DeleteConfirmation
        isOpenAlert={isOpenAlert}
        setIsOpenAlert={setIsOpenAlert}
        onCloseDelete={onCloseDelete}
        API_URL={API_URL}
        TableName={enumUtil.enumAPITableName.EmployeeStockReference}
        ID={delID}
        onClose={onClose}
      />
    );
  }

  const AssignDevice = (ID) => {
    setEmployeeStockReferenceID(ID);
    setAssigndevice(true);
  };

  const onCloseAssign = () => {
    setAssigndevice(false);
    if (isRefresh) {
      LoadData();
    }
  };

  let popUpScreen = "";
  if (assigndevice) {
    popUpScreen = (
      <AssignNewDevice
        assigndevice={assigndevice}
        onClose={onCloseAssign}
        ID={EmployeeStockReferenceID}
        setIsRefresh={setIsRefresh}
        type={type}
      />
    );
  }
  ////////////////////
  const LoadData = (param) => {
    setEmployeeStockReferenceData([]);
    setIsLoading(true);
    ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", "post", {
      TableName: enumUtil.enumAPITableName.EmployeeStockReference,
      SearchParam: param,
      SearchCriteria: {
        EmployeeID: EmployeeID + "",
      },
      PageNo: "1",
      PerPage: "100",
    })
      .then((response) => {
        setIsLoading(false);
        setEmployeeStockReferenceData(response);
        handleGridHeader({ defaultData: response });
      })
      .catch((error) => {
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        setIsLoading(false);
      });
  };

  const EmployeeStockReferenceValidation = async () => {
    let ValidationModal = {
      AssignedBy: FormValidator(
        mainEmployeeStockReferenceModal.AssignedBy,
        enumUtil.enumValidationType.Required,
        "Assigned By"
      ),
      StockIDVal: FormValidator(
        mainEmployeeStockReferenceModal.StockID,
        enumUtil.enumValidationType.Required,
        "Stock ID"
      ),
    };
    await setEmployeeStockReferenceValidationModal(ValidationModal);
    valModal = ValidationModal;
  };

  const handleEmployeeStockReferenceChange = (e) => {
    let { name, value } = e.target;
    let modal = { ...mainEmployeeStockReferenceModal };
    let saveModal = { ...EmployeeStockReferenceSaveUpdateModal };

    if (name === "IsItemDefective") {
      modal[name] = !modal[name];
    } else {
      modal[name] = value;
    }
    setmainEmployeeStockReferenceModal(modal);
    for (var property in EmployeeStockReference_Modal) {
      if (name === property) {
        if (name === "IsItemDefective") {
          saveModal[name] = modal[name] + "";
        } else {
          saveModal[name] = value;
        }
      }
    }
    setEmployeeStockReferenceSaveUpdateModal(saveModal);
  };
  const AddNewEmployeeStockReference = (ID) => {
    setEmployeeStockReferenceID(ID);
    if (ID !== EmployeeStockReferenceID) {
      setmainEmployeeStockReferenceModal(EmployeeStockReference_Modal);
    }
  };
  const handleSavehandleEmployeeStockReference = async () => {
    await EmployeeStockReferenceValidation();
    let validation = FormValidator(
      [valModal],
      enumUtil.enumValidationType.NullCheck
    );
    if (validation) {
      NotificationHandler("Fill Required Fields", enumUtil.enumtoaster.error);
      return;
    } else {
      if (EmployeeStockReferenceSaveUpdateModal["IsItemDefective"] !== "true") {
        EmployeeStockReferenceSaveUpdateModal["IsItemDefective"] = "false";
      }
      EmployeeStockReferenceSaveUpdateModal["OrganizationID"] =
        OrganizationID + "";
      setEmployeeStockReferenceSaveUpdateModal(
        EmployeeStockReferenceSaveUpdateModal
      );
      let saveModal = {
        TableName: enumUtil.enumAPITableName.EmployeeStockReference,
        ID: EmployeeStockReferenceID,
        ParentID: EmployeeID,
        data: { ...EmployeeStockReferenceSaveUpdateModal },
        value: null,
      };
      setIsLoading(true);
      ServerRequestProtectedParentID(
        API_URL + "/SaveSingleRecord",
        "post",
        saveModal
      )
        .then((response) => {
          if (!isNull(response.id)) {
            setIsLoading(false);
            NotificationHandler(
              "Record Saved Successfully!",
              enumUtil.enumtoaster.success
            );
            setEmployeeStockReferenceID(response.id);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error", error);
          NotificationHandler(error.message, enumUtil.enumtoaster.error);
        });
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  return (
    <React.Fragment>
      {isLoading ? <Loader /> : null}
      {ConfirmDelete}
      {popUpScreen}
      <div
        className={`basic_info pb-2 allocate_screen h-auto ${props.type === "Employee" ? "" : "my_info_screen_academics"
          }`}
      >
        {/* <div className="row h-100">
                    <div className="col-md-9">
                        <div className="app-padding">
                            <div className='row row-gap-30'>
                                {EmployeeStockReferenceData.map((items) => {
                                    return (
                                        <div className="col-md-3">
                                            <div className="card card-gray-1 border-0">
                                                <div className="card-body">
                                                    <div className="row row-gap-10">
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Assigned By</div>
                                                            <div className="text font-sb">{items.AssignedBy}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Product Name</div>
                                                            <div className="text font-sb">{items.ProductName}</div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Assigned Date</div>
                                                            <div className="text font-sb">{items.AssignedDate}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row gap-0">
                                                                <div className="col-md-7">
                                                                    <div className="text font-sb">{items.IsItemDefective}</div>
                                                                </div>
                                                                <div className="col-md-5 d-flex gap-1 card-action p-0">
                                                                    <button class="btn btn-icon-transparent clr-muted fs-22" onClick={() => handleDeleteModal(items.EmployeeStockReferenceID)}><i class="icon-delete"></i></button>
                                                                   
                                                                    <button className="edit-icon" onClick={() => AddNewEmployeeStockReference(items.EmployeeStockReferenceID)}></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>

                    </div>
                    <div className="col-md-3 ">
                        <div className="card rounded-0 br-none br-left-1 br-clr bg-transparent h-100">
                            <div className="bg-blue app-padding py-2">
                                <div className="row">
                                    <div className="col-md-2"><button class="btn-icon-transparent-add" onClick={() => AddNewEmployeeStockReference(0)}></button></div>
                                    <div className="col-md-10"><p className="text text-white fs-16">Assign Device</p></div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={EmployeeStockReferenceValidationModal.StockIDVal ? "form-group input error" : "form-group input"}>
                                    <label>Product Name<span className="req">*</span></label>

                                    {EmployeeStockReferenceID === 0 ?
                                        <select className="form-control pro_name"
                                            name="StockID"
                                            id="StockID"
                                            aria-label="Default select example"
                                            value={mainEmployeeStockReferenceModal.StockID}
                                            onChange={handleEmployeeStockReferenceChange}
                                        >
                                            <option value="">Select</option>
                                            {addDropDown.map((e, index) => (
                                                <option value={e.StockID} key={index}>
                                                    {e.ProductName}
                                                </option>
                                            ))}
                                        </select> :
                                        <select className="form-control"
                                            name="StockID"
                                            id="StockID"
                                            aria-label="Default select example"
                                            value={mainEmployeeStockReferenceModal.StockID}
                                            onChange={handleEmployeeStockReferenceChange}
                                            disabled="disabled"
                                        >
                                            <option value={mainEmployeeStockReferenceModal.StockID} >
                                                {mainEmployeeStockReferenceModal.ProductName}
                                            </option>
                                        </select>
                                    }
                                    {EmployeeStockReferenceValidationModal.StockIDVal}
                                </div>
                                <div className={EmployeeStockReferenceValidationModal.AssignedBy ? "form-group input error" : "form-group input"}>
                                    <label>Assigned By</label>
                                    <input type="text"
                                        className="form-control"
                                        name="AssignedBy"
                                        id="AssignedBy"
                                        value={mainEmployeeStockReferenceModal.AssignedBy}
                                        onChange={handleEmployeeStockReferenceChange}
                                    />
                                    {EmployeeStockReferenceValidationModal.AssignedBy}

                                </div>
                                <div className="form-group input">
                                    <CalendarInput
                                        label='Assigned Date'
                                        value={mainEmployeeStockReferenceModal.AssignedDate}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'AssignedDate')}
                                        anchorEl={assignedDate}
                                        open={openAssignedDate}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'AssignedDate')}
                                    />
                                </div>
                                <div className="form-group input">
                                    <label>Product Details</label>
                                    <input type="text"
                                        className="form-control"
                                        name="ProductDetails"
                                        id="ProductDetails"
                                        value={mainEmployeeStockReferenceModal.ProductDetails}
                                        onChange={handleEmployeeStockReferenceChange} />
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label" htmlFor="IsItemDefective">Is Item Defective</label>
                                    <input type="checkbox"
                                        className="form-check-input"
                                        name="IsItemDefective"
                                        id="IsItemDefective"
                                        checked={mainEmployeeStockReferenceModal.IsItemDefective}
                                        onChange={handleEmployeeStockReferenceChange}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>
                </div> */}
        <div className="row m-0">
 
          <div className="col-md-12 p-0">
            <div className="d-flex my-2  justify-content-between align-items-center action-row py-0 ps-1 ">
              <p className="heading doc py-1 px-2"> Assigned Device</p>
              {type === "Employee" && (
                <button
                  className="btn px-2 btn-blue rounded"
                  onClick={() => AssignDevice(0)}
                >
                  Assign Device
                </button>
              )}
            </div>
            {/* <Modal show={assigndevice} onHide={assigndevice} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_new_des add_new_device">
                            <Modal.Body>
                                {isLoading? <Loader/> : <></>}
                                <div className='d-flex justify-content-end'>
                                    <img className="close" src={close} onClick={() => onCloseAssign()}></img>
                                    
                                </div>
                                <div className="modal_header">
                                    <div className="text-center">
                                        <span className="icon">
                                            <i className="icon-Vector-9"></i>
                                        </span>
                                        <p class="heading mt-2">Assign Device</p>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className={EmployeeStockReferenceValidationModal.StockIDVal ? "form-group input error" : "form-group input"}>
                                        <label>Product Name<span className="req">*</span></label>

                                        {EmployeeStockReferenceID === 0 ?
                                            <select className="form-control pro_name"
                                                name="StockID"
                                                id="StockID"
                                                aria-label="Default select example"
                                                value={mainEmployeeStockReferenceModal.StockID}
                                                onChange={handleEmployeeStockReferenceChange}
                                            >
                                                <option value="">Select</option>
                                                {addDropDown.map((e, index) => (
                                                    <option value={e.StockID} key={index}>
                                                        {e.ProductName}
                                                    </option>
                                                ))}
                                            </select> :
                                            <select className="form-control"
                                                name="StockID"
                                                id="StockID"
                                                aria-label="Default select example"
                                                value={mainEmployeeStockReferenceModal.StockID}
                                                onChange={handleEmployeeStockReferenceChange}
                                                disabled="disabled"
                                            >
                                                <option value={mainEmployeeStockReferenceModal.StockID} >
                                                    {mainEmployeeStockReferenceModal.ProductName}
                                                </option>
                                            </select>
                                        }
                                        {EmployeeStockReferenceValidationModal.StockIDVal}
                                    </div>
                                    <div className={EmployeeStockReferenceValidationModal.AssignedBy ? "form-group input error" : "form-group input"}>
                                        <label>Assigned By</label>
                                        <input type="text"
                                            className="form-control"
                                            name="AssignedBy"
                                            id="AssignedBy"
                                            value={mainEmployeeStockReferenceModal.AssignedBy}
                                            onChange={handleEmployeeStockReferenceChange}
                                        />
                                        {EmployeeStockReferenceValidationModal.AssignedBy}

                                    </div>
                                    <div className="form-group input">
                                        <CalendarInput
                                            label='Assigned Date'
                                            value={mainEmployeeStockReferenceModal.AssignedDate}
                                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'AssignedDate')}
                                            anchorEl={assignedDate}
                                            open={openAssignedDate}
                                            closeCalendarMenufunc={closeCalendarMenu}
                                            handleCalendarChangefunc={date => handleCalendarChange(date, 'AssignedDate')}
                                        />
                                    </div>
                                    <div className="form-group input">
                                        <label>Product Details</label>
                                        <input type="text"
                                            className="form-control"
                                            name="ProductDetails"
                                            id="ProductDetails"
                                            value={mainEmployeeStockReferenceModal.ProductDetails}
                                            onChange={handleEmployeeStockReferenceChange} />
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label" htmlFor="IsItemDefective">Is Item Defective</label>
                                        <input type="checkbox"
                                            className="form-check-input"
                                            name="IsItemDefective"
                                            id="IsItemDefective"
                                            checked={mainEmployeeStockReferenceModal.IsItemDefective}
                                            onChange={handleEmployeeStockReferenceChange}
                                        />
                                    </div>

                                </div>
                            </Modal.Body>
                            <ModalFooter isSaved={onCloseAssign} handleSave={handleSavehandleEmployeeStockReference} />
                           
                        </Modal> */}
            <div>
              <ReactDataGrid
                columns={columns}
                rows={EmployeeStockReferenceData}
                rowHeight={30}
                className={
                  "fill-grid DataGrid-Table hk-exp-table employee_tabs-grid"
                }
                style={{
                  width: "100%",
                  // height:'100px',
                  maxHeight: "330px",
                }}
              />
              {isLoading ? null : EmployeeStockReferenceData?.length === 0 ? (
                <div className="no_data">
                  <span class="icon-ar-nodata">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                    <span class="path7"></span>
                    <span class="path8"></span>
                    <span class="path9"></span>
                    <span class="path10"></span>
                    <span class="path11"></span>
                    <span class="path12"></span>
                    <span class="path13"></span>
                    <span class="path14"></span>
                    <span class="path15"></span>
                    <span class="path16"></span>
                    <span class="path17"></span>
                    <span class="path18"></span>
                    <span class="path19"></span>
                    <span class="path20"></span>
                    <span class="path21"></span>
                    <span class="path22"></span>
                    <span class="path23"></span>
                    <span class="path24"></span>
                    <span class="path25"></span>
                    <span class="path26"></span>
                    <span class="path27"></span>
                    <span class="path28"></span>
                    <span class="path29"></span>
                    <span class="path30"></span>
                    <span class="path31"></span>
                    <span class="path32"></span>
                    <span class="path33"></span>
                    <span class="path34"></span>
                    <span class="path35"></span>
                    <span class="path36"></span>
                    <span class="path37"></span>
                    <span class="path38"></span>
                    <span class="path39"></span>
                    <span class="path40"></span>
                    <span class="path41"></span>
                    <span class="path42"></span>
                    <span class="path43"></span>
                    <span class="path44"></span>
                    <span class="path45"></span>
                    <span class="path46"></span>
                    <span class="path47"></span>
                    <span class="path48"></span>
                    <span class="path49"></span>
                    <span class="path50"></span>
                    <span class="path51"></span>
                    <span class="path52"></span>
                    <span class="path53"></span>
                    <span class="path54"></span>
                    <span class="path55"></span>
                    <span class="path56"></span>
                    <span class="path57"></span>
                    <span class="path58"></span>
                    <span class="path59"></span>
                    <span class="path60"></span>
                    <span class="path61"></span>
                    <span class="path62"></span>
                    <span class="path63"></span>
                    <span class="path64"></span>
                    <span class="path65"></span>
                    <span class="path66"></span>
                    <span class="path67"></span>
                    <span class="path68"></span>
                    <span class="path69"></span>
                    <span class="path70"></span>
                    <span class="path71"></span>
                    <span class="path72"></span>
                    <span class="path73"></span>
                    <span class="path74"></span>
                    <span class="path75"></span>
                    <span class="path76"></span>
                    <span class="path77"></span>
                    <span class="path78"></span>
                    <span class="path79"></span>
                    <span class="path80"></span>
                    <span class="path81"></span>
                    <span class="path82"></span>
                    <span class="path83"></span>
                    <span class="path84"></span>
                    <span class="path85"></span>
                    <span class="path86"></span>
                    <span class="path87"></span>
                    <span class="path88"></span>
                    <span class="path89"></span>
                    <span class="path90"></span>
                    <span class="path91"></span>
                    <span class="path92"></span>
                    <span class="path93"></span>
                    <span class="path94"></span>
                    <span class="path95"></span>
                    <span class="path96"></span>
                    <span class="path97"></span>
                    <span class="path98"></span>
                    <span class="path99"></span>
                    <span class="path100"></span>
                    <span class="path101"></span>
                    <span class="path102"></span>
                    <span class="path103"></span>
                    <span class="path104"></span>
                    <span class="path105"></span>
                    <span class="path106"></span>
                    <span class="path107"></span>
                    <span class="path108"></span>
                    <span class="path109"></span>
                    <span class="path110"></span>
                    <span class="path111"></span>
                    <span class="path112"></span>
                    <span class="path113"></span>
                    <span class="path114"></span>
                    <span class="path115"></span>
                    <span class="path116"></span>
                    <span class="path117"></span>
                    <span class="path118"></span>
                    <span class="path119"></span>
                    <span class="path120"></span>
                    <span class="path121"></span>
                    <span class="path122"></span>
                    <span class="path123"></span>
                    <span class="path124"></span>
                    <span class="path125"></span>
                    <span class="path126"></span>
                    <span class="path127"></span>
                    <span class="path128"></span>
                  </span>
                  <p className="text fst-italic text-center font-m">
                    No Data Found!
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div className='tabs-action-bottom'>
                        <div className='btns-group text-end px-3 br-top-1 br-clr d-flex align-items-center justify-content-end h-100'>
                            <button className='btn btn-gray'onClick={()=>dispatch(CancelButton(true))}>Cancel</button>
                            <button className='btn btn-orange'onClick={handleSavehandleEmployeeStockReference}>Save</button>
                        </div>
                    </div> */}
      </div>
    </React.Fragment>
  );
};

export default EmployeeStockReference;
