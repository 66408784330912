import React, { useEffect, useState } from 'react'
import close from "../../../../assetsOld/img/x-close.svg"
import CalendarInput from "../../../../utils/CalendarInput";
import { useDispatch, useSelector } from 'react-redux';
import { DateFormat, DateFormatCalculations, DateFormat_YMd_Time, DateFormat_dMy } from "../../../../utils/Constants"
import jwt_decode from 'jwt-decode';
import format from 'date-fns/format';
import isNull from '../../../../utils/NullChecking';
import { enumUtil } from '../../../../utils/Enum';
import NotificationHandler from '../../../../utils/NotificationHandler';
import { ServerRequestProtected } from '../../../../utils/ServerDataRequest';
import FormValidator from "../../../../utils/FormValidator"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal'
import DatePicker from '../../../../utils/DatePicker';

const AddNewEducation = (props) => {
    const { type } = { ...props }
    const { isOpenEducation, handleOnClose, handleOnCloseSave, ID, setIsRefresh } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    // else{
    //     EmployeeID = Token.UserID;
    // }

    let dispatch = useDispatch();
    let Education_Modal = {
        Title: "",
        Institute: "",
        StartDate: null,
        EndDate: null,
        GPA: "",
        CurrentStatus: ""
    }

    let valModal = {}
    let ValidationModal = {
        Title: "",
        Institute: "",
    }

    const [EducationValidationModal, setEducationValidationModal] = useState(ValidationModal)
    const [EducationSaveUpdateModal, setEducationSaveUpdateModal] = useState({})
    const [mainEducationModal, setmainEducationModal] = useState(Education_Modal);
    const [dateValidation, setDateValidation] = useState({
        StartDate: '',
        EndDate: '',
    })
    const API_URL = process.env.REACT_APP_API
    const [EducationID, setEducationID] = useState(0);
    // const [startDate, setStartDate] = useState(0);
    // const [endDate, setEndDate] = useState(0);

    //const [searchParam, setSearchParam] = useState();
    //const [EnterParam, setEnterParam] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [showCalendar, setShowCalendar] = useState({
        StartDate: false,
        EndDate: false,
    });
    ///////Calendar////////
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const openStartDate = Boolean(startDate)
    const openEndDate = Boolean(endDate)

    const handleCalendarChange = (date, type) => {
        const FormatedDateShow = format(date, "MM/dd/yyyy")
        const FormatedDateSave = format(date, DateFormat)
        //===========to split YYYY-MM-dd Format Date then to Time============//
        const currDate = DateFormat_YMd_Time(date);
        //===========to Convert dd/MM/YYYY to YYYY-MM-dd then Time============//
        const startDate = DateFormatCalculations(mainEducationModal.StartDate)
        const endDate = DateFormatCalculations(mainEducationModal.EndDate)
        if (type === 'StartDate') {
            if (endDate && currDate <= endDate) {
                setmainEducationModal({ ...mainEducationModal, StartDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, StartDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, StartDate: '' })
            }
            else if (!endDate) {
                setmainEducationModal({ ...mainEducationModal, StartDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, StartDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, StartDate: '' })
            }
            else {
                setDateValidation({ ...dateValidation, StartDate: 'error' })
            }
        }
        else if (type === 'EndDate') {
            if (startDate && currDate >= startDate) {
                setmainEducationModal({ ...mainEducationModal, EndDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, EndDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, EndDate: '' })
            }
            else if (!startDate) {
                setmainEducationModal({ ...mainEducationModal, EndDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, EndDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, EndDate: '' })
            }
            else {
                setDateValidation({ ...dateValidation, EndDate: 'error' })
            }
        }
        closeCalendarMenu()
    }
    const handleEducationChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainEducationModal };
        let saveModal = { ...EducationSaveUpdateModal }
        modal[name] = value
        setmainEducationModal(modal)
        for (var property in Education_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setEducationSaveUpdateModal(saveModal)
    }
    const closeCalendarMenu = () => {
        setStartDate(null)
        setEndDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'StartDate') { setStartDate(event.currentTarget) }
        else if (type === 'EndDate') { setEndDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };

    const EducationValidation = async () => {
        let ValidationModal = {

            Title: FormValidator(
                mainEducationModal.Title,
                enumUtil.enumValidationType.Required,
                'Title',
            ),
            Institute: FormValidator(
                mainEducationModal.Institute,
                enumUtil.enumValidationType.Required,
                'Institute',
            ),
        }

        await setEducationValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleSaveEducation = async () => {
        await EducationValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setEducationSaveUpdateModal(EducationSaveUpdateModal)
            EducationSaveUpdateModal["EmployeeID"] = EmployeeID + "";
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Education,
                ID: EducationID,
                // EmployeeID: EmployeeID,
                value: null,
                data: { ...EducationSaveUpdateModal }

            }
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setEducationID(response.id)
                    setIsRefresh(true)
                    handleOnCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log('error', error);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsRefresh(false)
            })
        }
    }
    useEffect(() => {
        if (!isNull(ID)) {
            setEducationID(ID);
            setIsLoading(true);
            ServerRequestProtected(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Education
            }).then((response) => {
                setmainEducationModal(response);
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [EducationID])

    return (
        <Modal show={isOpenEducation} onHide={isOpenEducation} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_new_des add_new_growth">
            <>
                <div className="education_popup">
                    {isLoading ? <Loader /> : null}
                    <img className="cursor-pointer" src={close} onClick={() => handleOnClose()}></img>
                    <div className="text-center">
                        <span className="icon">
                            <i className="icon-Icon-awesome-graduation-cap"></i>
                        </span>
                        <p className="heading mt-2">Add New Education</p>
                    </div>
                    <div>
                        <div className={EducationValidationModal.Title ? "form-group input error" : "form-group input"}>
                            <label>Title<span className='req'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                name="Title"
                                id="Title"
                                maxLength={35}
                                value={mainEducationModal.Title}
                                onChange={handleEducationChange}
                            />
                            {EducationValidationModal.Title}
                        </div>
                        <div className={EducationValidationModal.Institute ? "form-group input error" : "form-group input"}>
                            <label>Institute<span className='req'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                name="Institute"
                                id="Institute"
                                // maxLength={35}
                                value={mainEducationModal.Institute}
                                onChange={handleEducationChange}
                            />
                            {EducationValidationModal.Institute}
                        </div>
                        <div className={`form-group input ${dateValidation.StartDate}`}>
                            <DatePicker
                                label='Start Date'
                                value={mainEducationModal.StartDate}
                                openCalendarMenufunc={(event) => openCalendarMenu(event, 'StartDate')}
                                maxDate={new Date()}
                                anchorEl={startDate}
                                open={openStartDate}
                                closeCalendarMenufunc={closeCalendarMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'StartDate')}
                                placeholder={true}
                            />
                            {dateValidation.StartDate === 'error' ? <label>Start Date must be less than End Date</label> : ''}
                        </div>
                        <div className={`form-group input ${dateValidation.EndDate}`}>
                            <DatePicker
                                label='End Date'
                                value={mainEducationModal.EndDate}
                                openCalendarMenufunc={(event) => openCalendarMenu(event, 'EndDate')}
                                maxDate={new Date()}
                                anchorEl={endDate}
                                open={openEndDate}
                                closeCalendarMenufunc={closeCalendarMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'EndDate')}
                                placeholder={true}
                            />
                            {dateValidation.EndDate === 'error' ? <label>End Date must be greater than Start Date</label> : ''}
                        </div>
                        <div className="form-group input">
                            <label>Current Status</label>
                            {/* <input
                                type="text"
                                className="form-control"
                                name="CurrentStatus"
                                value={mainEducationModal.CurrentStatus}
                                id="CurrentStatus"
                                maxLength={15}
                                onChange={handleEducationChange}
                            /> */}
                            <select
                                className="form-control"
                                name="CurrentStatus"
                                value={mainEducationModal.CurrentStatus}
                                id="CurrentStatus"
                                onChange={handleEducationChange} >

                                <option value="">Select</option>
                                <option value="Graduated">Graduated</option>
                                <option value="In-Progress">In-Progress</option>

                            </select>
                        </div>
                        <div className="d-flex justify-content-end gap-3 ">
                            <button class="btn btn-gray" onClick={() => handleOnClose()}>Cancel</button>
                            <button class="btn btn-blue" onClick={() => handleSaveEducation()}>Save</button>
                        </div>
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default AddNewEducation
