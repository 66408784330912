import React, { useEffect, useState } from 'react'
import close from "../../../../assetsOld/img/x-close.svg"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import isNull from "../../../../utils/NullChecking"
import FormValidator from "../../../../utils/FormValidator"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux";
import jwt_decode from 'jwt-decode';
import { ServerRequestPublic } from "../../../../utils/ServerDataRequest"

const AddNewDocument = (props) => {
    const { ID, handleOnClose, handleOnCloseSave, setIsRefresh, type } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    } 
    // else {
    //     EmployeeID = Token.UserID;
    // }

    let Document_Modal = {
        DocumentName: "",
        AttachmentPath:"",
        DocumentDescription: "",
        Files: "",
    }
    let valModal = {}
    let ValidationModal = {
        Document: "",
        DocumentName: "",
    }

    const [DocumentID, SetDocumentID] = useState(0);
    const API_URL = process.env.REACT_APP_API
    const [DocumentValidationModal, setDocumentValidationModal] = useState(ValidationModal);
    const [mainDocumentModal, setmainDocumentModal] = useState(Document_Modal);
    const [DocumentSaveUpdateModal, setDocumentSaveUpdateModal] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState([])
    const [fileName, setFileName] = useState('')
    const [editId, setEditId] = useState(null);
    let maxIndex = 0;

    const savefile = (e) => {
        let allFiles = e.target.files;
        let filesLength = allFiles.length;

        for (let i = 0; i < filesLength; i++) {
            let tempObj = {
                file: e.target.files[i],
                key: maxIndex + 1
            }
            maxIndex = maxIndex + 1;
            // file.push(e.target.files[i]);
            file.push(tempObj);
        }

    };
    const DocumentValidation = async () => {
        let ValidationModal = {
            Document: FormValidator(
                fileName,
                enumUtil.enumValidationType.Required,
                'Document',
            ),
            DocumentName: FormValidator(
                mainDocumentModal.DocumentName,
                enumUtil.enumValidationType.Required,
                'Document Name ',
            ),
        }
        await setDocumentValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const onsubmitSaveDocument = async () => {
        await DocumentValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setIsLoading(true);
            let imageFiles = file;
            let data = { EmployeeID: EmployeeID, DocumentName: mainDocumentModal.DocumentName, DocumentDescription: mainDocumentModal.DocumentDescription }
            const formdata = new FormData()
            formdata.append("TableName", enumUtil.enumAPITableName.EmployeeDocument)
            formdata.append("ID", DocumentID);
            for (var key in data) {
                formdata.append(`data[${key}]`, data[key]);
            }
            if (imageFiles != undefined || imageFiles != null) {
                for (let i = 0; i < imageFiles.length; i++) {
                    // formdata.append('files[]', file[i]);
                    formdata.append('Files', imageFiles[i].file);
                }
            }
            ServerRequestPublic(API_URL + "/SaveEmployeeDocuments", 'post', formdata, 'Upload',)
                // saveModal
                .then((response) => {
                    // setmainDocumentModal({})
                    setIsLoading(false);
                    setIsRefresh(true);
                    handleOnCloseSave();
                    while (file.length > 0) {
                        file.pop();
                    }
                    
                    // SetDocumentID(response.id)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                }).catch((error) => {
                    setIsLoading(false);
                    setIsRefresh(false);
                    NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
                   
                })
        }
    }

    const handleDocumentChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainDocumentModal };
        let saveModal = { ...DocumentSaveUpdateModal }

        modal[name] = value

        setmainDocumentModal(modal)
        for (var property in Document_Modal) {
            if (name === property) {
                saveModal[name] = value

            }
        }
        setDocumentSaveUpdateModal(saveModal)
    }

    useEffect(() => {
        if (!isNull(ID)) {
            SetDocumentID(ID)
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.EmployeeDocument
            }).then((response) => {
                setIsLoading(false)
                // console.log(response.dt)
                setFileName(response["AttachmentPath"])
                setmainDocumentModal({ ...response })
            }).catch((error) => {
                NotificationHandler(error?.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [ID])
    return (
        <>
            {isLoading ? <Loader /> : null}
            <div className="education_popup">
                <img className="cursor-pointer" src={close} onClick={() => handleOnClose()}></img>
                <div className="text-center">
                    <span className="icon">
                        <div className='certification_icon'>
                            <i className="icon-Icon-ionic-md-document"></i>
                        </div>
                    </span>
                    <p className="heading my-2">Add New Document</p>
                </div>
                <div>
                    <div className="form-group input position-relative">
                        <label >Upload Document<span className='req'>*</span></label>
                        {/* <i className='icon-Icon-metro-attachment'></i> */}
                        <div className={DocumentValidationModal.Document ? "form-group input error upload-file" : "form-group input upload-file"}>
                            <label className='form-control d-flex align-items-center'>
                                <input type="file"
                                    className="form-control ps-4"
                                    name="File"
                                    id="File"
                                    // onChange={(e) => { savefile(e) }}
                                    onChange={(e) => {
                                        savefile(e)
                                        setFileName(e.target.files[0]?.name)
                                    }}
                                    multiple
                                    accept=" .gif, .jpg, .pdf,.png "

                                // accept="image/*,application/pdf,text/plain"

                                />
                                {!isNull(fileName) ? <span className='office_name_truncate'>{fileName}</span> : <label htmlFor="file"  className='pe-none'><i class="icon-link"></i>{"Upload a File"}</label>}
                                {/* {file.length > 0 ? file : <label htmlFor="file"><i class="icon-link"></i>{" "}Upload a File</label>}
                        <label htmlFor="file"><i class="icon-link"></i> Upload a File</label> */}
                            </label>
                            {DocumentValidationModal.Document}
                        </div>
                    </div>
                    <div className={DocumentValidationModal.DocumentName ? "form-group input error" : "form-group input"}>
                        <label>Document Name<span className='req'>*</span></label>
                        <input type="text"
                            className='form-control'
                            id='DocumentName'
                            name='DocumentName'
                            value={mainDocumentModal.DocumentName}
                            onChange={handleDocumentChange}
                        />
                        {DocumentValidationModal.DocumentName}
                    </div>
                    <div className="form-group input">
                        <label>  Document Description</label>
                        <textarea
                            name="DocumentDescription"
                            id="DocumentDescription"
                            className='form-control'
                            value={mainDocumentModal.DocumentDescription}
                            onChange={handleDocumentChange}
                            cols="30"
                            rows="5"
                        ></textarea>
                    </div>
                    <div className="d-flex justify-content-end gap-3 ">
                        <button class="btn btn-gray" onClick={() => handleOnClose()}>Cancel</button>
                        <button class="btn btn-blue" onClick={() => onsubmitSaveDocument()} >Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddNewDocument
