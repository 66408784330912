import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import FormValidator from "../../utils/FormValidator"
import { enumUtil } from '../../utils/Enum';
import close from "../../assetsOld/img/x-close.svg"
import NotificationHandler from "../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../utils/ServerDataRequest"
import Loader from '../shared/Loader/Loader';
import ModalFooter from '../shared/ModalFooter/ModalFooter';

const PopupCheckout = ({ type, refresh, setRefresh, isPopupOpen, setIsPopupOpen, attendance, EmployeeID }) => {

    const Checkout_Modal = {
        CheckInTime: attendance && attendance.Check_In,
        CheckOutTime: "",
        RequestedDate: attendance && attendance.Date,
        ApprovedDate: "",
        ApprovedBy: "",
        Message: "",
        EmployeeID: ''
    }
    let UserID = ""
    if (!type) {
        UserID = EmployeeID + "";
    }

    let validation_modal = {
        CheckOutTime: '',
        CheckInTime: '',
        RequestedDate: '',
    }
    let valModal = {}

    const [mainCheckoutModal, setMainCheckoutModal] = useState(Checkout_Modal);
    const [saveCheckoutModal, setSaveCheckoutModal] = useState({});
    const [checkoutValidationModal, setCheckoutValidationModal] = useState(validation_modal);
    const API_URL = process.env.REACT_APP_API;
    const [isLoading, setIsLoading] = useState(false);
    const [formatedDate, setFormatedDate] = useState(null)
    const [addManagerDropDown, setAddManagerDropDown] = useState([]);
    /// API for LoadDropdownList managerName ///

    const LoadDropdownList = () => {
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Employee,
            "Indicator": "Manager"
        }).then((response) => {
            setAddManagerDropDown(response)
        }).catch((error) => {
            // NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        });
    }
    const dateFormate = (date) => {
        const splitDate = date.split("/");
        const dateObj = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
        var month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to adjust zero-based month and pad with leading zero if necessary
        var day = dateObj.getDate().toString().padStart(2, '0'); // Pad with leading zero if necessary
        var year = dateObj.getFullYear();
        const formatDate = year + '-' + month + '-' + day;
        setFormatedDate(formatDate);
        saveCheckoutModal['RequestedDate'] = formatDate;
        // setMainCheckoutModal({...mainCheckoutModal,RequestDate:formatDate});

    }


    // console.log("mainModel1", mainCheckoutModal);
    const handleOnChangeCheckout = (e) => {

        const { value, name, checked} = e.target;
        const modal = { ...mainCheckoutModal };
        const saveModal = { ...saveCheckoutModal };
        if(name === 'ActiveTime'){
            // modal['CheckOutTime'] = checked  ?  : ;
        }
        else{

        }
        modal[name] = value;
        setMainCheckoutModal(modal);
        for (let property in Checkout_Modal) {
            if (name === property) {
                saveModal[name] = value;
            }
        }
        setSaveCheckoutModal(saveModal);
    }

    const checkoutValidation = () => {
        let validationModal = {
            // CheckInTime: FormValidator(
            //     mainCheckoutModal.CheckInTime,
            //     enumUtil.enumValidationType.Required,
            //     "CheckIn Time"
            // ),
            CheckOutTime: FormValidator(
                mainCheckoutModal.CheckOutTime,
                enumUtil.enumValidationType.Required,
                "Checkout Time"
            ),
            RequestedDate: FormValidator(
                mainCheckoutModal.RequestedDate,
                enumUtil.enumValidationType.Required,
                "Request Date"
            ),
            // ApprovedDate:FormValidator(
            //     mainCheckoutModal.RequestDate,
            //     enumUtil.enumValidationType.Required,
            //     "Approved Date"
            // ),
            // ApprovedBy:FormValidator(
            //     mainCheckoutModal.ApprovedBy,
            //     enumUtil.enumValidationType.Required,
            //     "Approved By"
            // ),
            Message: FormValidator(
                mainCheckoutModal.Message,
                enumUtil.enumValidationType.Required,
                "Reason"
            ),
        }
        setCheckoutValidationModal(validationModal);
        valModal = validationModal;
    }



    const handleCheckoutTimeRequest = async () => {

        await checkoutValidation();
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler("Fill all Required Fields", enumUtil.enumtoaster.error)
            return
        }
        else {
            setIsLoading(true);
            saveCheckoutModal['EmployeeID'] = UserID + "";
            let saveModal = {
                "TableName": enumUtil.enumAPITableName.UserActivity,
                "ID": 0,
                "Data": { ...saveCheckoutModal },
            }
            ServerRequestProtectedParentID(API_URL + "/CheckOutRequest", "post", saveModal).then((response) => {

                setRefresh(!refresh)
                setIsLoading(false);
                NotificationHandler("Request Sent Successfully", enumUtil.enumtoaster.success);

            }).catch((error) => {
                setIsLoading(false);
                NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
            })
        }

    }

    useEffect(() => {
        dateFormate(attendance?.Date);
        LoadDropdownList()
    }, [])



    return (
        <>

            <Modal show={isPopupOpen} onHide={() => setIsPopupOpen(false)} backdrop="static" keyboard={false} centered dialogClassName="app-modal modal-c-lg  add_new_vendor ems_modal">

                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" onClick={() => setIsPopupOpen(false)} src={close}></img>
                        {isLoading ? <Loader /> : null}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="icon_bg">
                                    <i className="icon-plus-icon"></i>
                                </div>
                            </span>
                            <p class="heading mt-2">Time Adjustment Request</p>
                        </div>
                    </div>
                    {/* {isLoading ? <Loader /> : null} */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className={"form-group input"}>
                                <label>Check_In Time</label>
                                <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    // maxLength={5}
                                    name="CheckInTime"
                                    id="CheckInTime"
                                    value={attendance?.Check_In}
                                    onChange={handleOnChangeCheckout}
                                // onKeyPress={(event) => handleNumericCheck(event)}
                                />
                                {/* {checkoutValidationModal.CheckinTime}  */}
                            </div>
                            <div className={checkoutValidationModal.CheckOutTime ? "form-group input error" : "form-group input"}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label>Check_Out Time<span className='req'>*</span></label>
                                    <div className='d-flex align-items-center gap-2'>
                                        <div class="custom_check_box d-flex">
                                            <input name='ActiveTime' id='ActiveTime' type="checkbox" checked={""} onChange={handleOnChangeCheckout} />
                                        </div>
                                        <span className='text'>Set to Last Active</span>
                                    </div>
                                </div>
                                <input
                                    type="time"
                                    className="form-control"
                                    // maxLength={5}
                                    name="CheckOutTime"
                                    id="CheckOutTime"
                                    value={mainCheckoutModal.CheckOutTime}
                                    onChange={handleOnChangeCheckout}
                                // onKeyPress={(event) => handleNumericCheck(event)}
                                />
                                {checkoutValidationModal.CheckOutTime}
                            </div>
                            <div className={"form-group input"}>
                                <label>Requested date</label>
                                <input
                                    type="date"
                                    readOnly
                                    className="form-control"
                                    // maxLength={5}
                                    name="RequestedDate"
                                    id="RequestedDate"
                                    value={formatedDate}
                                    onChange={handleOnChangeCheckout}
                                // onKeyPress={(event) => handleNumericCheck(event)}
                                />
                                {checkoutValidationModal.RequestedDate}
                            </div>
                            {/* <div className={"form-group input"}>
                                <label>Approved date</label>
                                <input type="date"
                                    disabled
                                    className="form-control"
                                    maxDate={new Date()}
                                    maxLength={5}
                                    name="ApprovedDate"
                                    id="ApprovedDate"
                                    value={mainCheckoutModal.ApprovedDate}
                                    onChange={handleOnChangeCheckout}
                                onKeyPress={(event) => handleNumericCheck(event)}
                                />
                                {checkoutValidationModal.ApprovedDate}
                            </div> */}
                        </div>
                        <div className='col-md-6'>
                            <div className={checkoutValidationModal.Message ? "form-group input error" : "form-group input"}>
                                <label>Reason<span className='req'>*</span></label>
                                <textarea
                                    type="text"
                                    rows={4}
                                    className="form-control"
                                    name="Message"
                                    id="Message"
                                    value={mainCheckoutModal.Message}
                                    onChange={handleOnChangeCheckout}
                                />
                                {checkoutValidationModal.Message}
                            </div>
                            {/* <div className={"form-group input"}>
                                <label>Approved by</label>
                                <select className="form-control"
                                    disabled
                                    name="ApprovedBy"
                                    id="ApprovedBy"
                                    value={mainCheckoutModal.ApprovedBy}
                                    aria-label="Default select example"
                                    onChange={handleOnChangeCheckout}
                                >
                                    <option value="">Select</option>
                                    {addManagerDropDown && addManagerDropDown.map((item, index) => {
                                        return <option value={item.EmployeeID} key={index}>{item.EmployeeName}</option>
                                    })}



                                </select>
                                {checkoutValidationModal.ApprovedBy}
                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                    <button className='btn btn-gray' onClick={() => setIsPopupOpen(false)}>Cancel</button>
                    <button className='btn btn-orange' onClick={handleCheckoutTimeRequest}>Save</button>
                </div> */}
                <ModalFooter isSaved={() => setIsPopupOpen(false)} handleSave={handleCheckoutTimeRequest} />
            </Modal>
        </>
    )
}

export default PopupCheckout
