import React, { useEffect, useState } from "react";
import {  NavItem, NavLink } from 'reactstrap'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import EmployeeInnerLeftMenuContent from "./EmployeeInnerLeftMenuContent";
import EmployeeMenu from "./EmployeeMenu";
import { useSelector } from "react-redux";

const EmployeeInnerLeftMenu = (props) => {
    const MenuItems = EmployeeMenu();
    const [subCategItems, setSubCategItems] = useState([])
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState()
    let menuTab = useSelector(state => state.HandleMenuActiveTab);
    const [activeMenuTab, seActiveMenuTab] = useState(false)
    let ActiveEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    useEffect(() => {
        const selected_Item_List = MenuItems.filter((item) => item.key === props.subCategKey);
        if (selected_Item_List && selected_Item_List.length > 0) {
            let subCategories = selected_Item_List[0].subCategoryItems
            
            if(ActiveEmployee === 0.1){setSubCategItems([subCategories[0]])}
            else{ setSubCategItems(subCategories)}

            setActiveTab(subCategories[0].key)
        }
        if(menuTab === 9){ // basicinfo hide
            seActiveMenuTab(true)
        }else{
            seActiveMenuTab(false)
        }
    }, [props.subCategKey, menuTab])
    
    const handleActiveTab = (item) => {
        const { key, name, right,component } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const activeTabElement = subCategItems.filter((item) => item.key === activeTab)
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
                component: activeTabElement.component,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
            component: component,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }

    return (
            <div className='app-tabs'>
            <Tab.Container defaultActiveKey="basicinformation">
            <div className='px-4 py-2 tabs-header '>
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="w-fit-content tabs-v2-custom">
                        <Nav variant="tabs" className="">
                            {subCategItems && subCategItems.map((item) => {
                                return (
                                    <React.Fragment key={item.key}>
                                        <NavItem onClick={() => handleActiveTab(item)}>
                                            <NavLink href="#" className={activeTab === item.key ? " active" : ""}>
                                                <i className={activeTab === item.key ?'icon-Icon-awesome-check-circle-orange':''}></i>
                                                {item.name}
                                            </NavLink>
                                        </NavItem>
                                    </React.Fragment>
                                )

                            })}
                        </Nav>
                
                    </div>
                    {/* <div className="col-md-1">
                        <div className='d-flex gap-1 align-items-center justify-content-end'>
                            <button class="btn-icon-transparent-file-export"></button>
                            <button class="btn-icon-transparent"><i className='icon-filter'></i></button>
                            <button class="btn-icon-transparent-add"></button>
                        </div>
                    </div> */}
                </div>
            </div>
                <EmployeeInnerLeftMenuContent activeTab={activeTab} dynamicTabs={tabList} subCategItems={subCategItems} />
            </Tab.Container>
            </div>
       )     
}

export default EmployeeInnerLeftMenu