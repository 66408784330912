import React, { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const AddNewLunchAndDinner = (props) => {
    const { isOpenLunchAndDinner, onClose } = { ...props }

    const [expanded, setExpanded] =useState(false);
    const handleChangeAccordian = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <React.Fragment>
       <Modal show={isOpenLunchAndDinner} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal modal-c-lg">
                <Modal.Body>
                <div className='d-flex justify-content-between mb-3'>
                        <p className="heading">Add New Lunch and Dinner</p>
                        <button className='btn-icon-transparent'><i class="bi bi-trash-fill"></i></button>
                    </div>
                    <div className="d-flex fa-layout h-100">
                        <div className="form-col w-35">
                            <div className="pe-4">
                                <div className='form-group input'>
                                    <label htmlFor="">Location Name<span className='req'>*</span></label>
                                    <input type="text" 
                                    className='form-control' 
                                    name="LocationName"
                                    id="LocationName"
                                    //value={mainLocationModal.LocationName}
                                    //onChange={handleLocationChange} 
                                    />
                                    {/* {LocationValidationModal.LocationNameVal} */}
                                    {/* error helperText="Error Message" */}
                                </div>
                               
                                <div className='form-group input'>
                                    <label htmlFor="">POS Code<span className='req'>*</span></label>
                                    <input type="text" 
                                    className='form-control' 
                                    name="POSCode"
                                    id="POSCode"
                                    //value={mainLocationModal.POSCode}
                                    //onChange={handleLocationChange} 
                                    />
                                    {/* error helperText="Error Message" */}
                                </div>
                                <div className='form-group input'>
                                    <label htmlFor="">NPI</label>
                                    <input type="text" 
                                    className='form-control' 
                                    placeholder="length should be 10"
                                    name="NPI"
                                    id="NPI"
                                    //value={mainLocationModal.NPI}
                                    //onChange={handleLocationChange} 
                                    />
                                     {/* {LocationValidationModal.LocationNPILengthVal}
                                     {LocationValidationModal.LocationNPIVal} */}
                                </div>

                                <div className='form-group input'>
                                    <label htmlFor="">Tax ID</label>
                                    <input type="text" 
                                    className='form-control' 
                                    placeholder="length should be 9"
                                    name="TaxID"
                                    id="TaxID"
                                    //value={mainLocationModal.TaxID}
                                    //onChange={handleLocationChange} 
                                    />
                                     {/* {LocationValidationModal.LocationTaxIDLengthVal}
                                     {LocationValidationModal.LocationTaxIDVal} */}
                                </div>
                                <div className='form-group input'>
                                    <label htmlFor="">CLIA Number</label>
                                    <input type="text" 
                                    className='form-control' 
                                    name="CLIANumber"
                                    id="CLIANumber"
                                    //value={mainLocationModal.CLIANumber}
                                    //onChange={handleLocationChange} 
                                    />
                                </div>
                                <div className='form-group input'>
                                    <label htmlFor="">Mammography Number</label>
                                    <input type="text" 
                                    className='form-control' 
                                    name="MemographyCertificationNum"
                                    id="MemographyCertificationNum"
                                    //value={mainLocationModal.MemographyCertificationNum}
                                    //onChange={handleLocationChange} 
                                    />
                                </div>

                                <div className='form-group input'>
                                    <label htmlFor="">Taxonomy Code *</label>
                                    <input type="text" 
                                    className='form-control' 
                                    name="TaxonomyCode"
                                    id="TaxonomyCode"
                                   // value={mainLocationModal.TaxonomyCode}
                                   // onChange={handleLocationChange} 
                                    />
                                     {/* {LocationValidationModal.LocationTaxonomyLengthVal}
                                     {LocationValidationModal.LocationTaxonomyVal} */}
                                </div>
                            </div>
                        </div>
                        <div className="accordian-col w-65">
                            <div className="app-accordian">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordian('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <p className="heading">Address Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row row-gap-15 row-half'>
                                            <div className="col-md-5">
                                                <div className='input'>
                                                    <label htmlFor="">Address Line 1</label>
                                                    <input type="text" className='form-control'
                                                     name="AddressLine1"
                                                     id="AddressLine1"
                                                    // value={mainLocationModal.AddressLine1}
                                                    //onChange={handleLocationChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className='input'>
                                                    <label htmlFor="">Address Line 2</label>
                                                    <input type="text" className='form-control'
                                                     name="AddressLine2"
                                                     id="AddressLine2"
                                                     //value={mainLocationModal.AddressLine2}
                                                     //onChange={handleLocationChange} 
                                                     />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row row-gap-15 row-half mt-3'>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">City</label>
                                                    <input type="text" className='form-control'
                                                     name="City"
                                                     id="City"
                                                     //value={mainLocationModal.City}
                                                    //onChange={handleLocationChange} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">State</label>
                                                    <Select
                                                        name="State"
                                                        id="State"
                                                        //value={mainLocationModal.State}
                                                        //onChange={handleLocationChange}
                                                        variant="outlined"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                        {/* {States.map((type) => {
                                                        return (
                                                            <option value={type.value} key={type.value}>{type.display}</option>
                                                        )
                                                        })} */}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Zip Code</label>
                                                    <input type="text" className='form-control'
                                                     name="ZipCode"
                                                     id="ZipCode"
                                                     //value={mainLocationModal.ZipCode}
                                                     //onChange={handleLocationChange} 
                                                     />
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordian('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <p className="heading">Contact Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row row-gap-15 row-half'>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Name</label>
                                                    <input type="text" className='form-control'
                                                     />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Contact Number</label>
                                                    <input type="text" className='form-control' 
                                                     name="ContactPhoneNumber"
                                                     id="ContactPhoneNumber"
                                                     //value={mainLocationModal.ContactPhoneNumber}
                                                    //onChange={handleLocationChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Email Address</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Fax Number</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Website</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordian('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <p className="heading">Pay to Address Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row row-gap-15 row-half'>
                                            <div className="col-md-5">
                                                <div className='input'>
                                                    <label htmlFor="">Address Line 1</label>
                                                    <input type="text" className='form-control'
                                                     name="PayToAddressLine1"
                                                     id="PayToAddressLine1"
                                                     //value={mainLocationModal.PayToAddressLine1}
                                                     //onChange={handleLocationChange} 
                                                     />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className='input'>
                                                    <label htmlFor="">Address Line 2</label>
                                                    <input type="text" className='form-control'
                                                     name="PayToAddressLine2"
                                                     id="PayToAddressLine2"
                                                   //  value={mainLocationModal.PayToAddressLine2}
                                                     //onChange={handleLocationChange} 
                                                     />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row row-gap-15 row-half mt-3'>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">City</label>
                                                    <input type="text" className='form-control' 
                                                     name="PayToCity"
                                                     id="PayToCity"
                                                    //value={mainLocationModal.PayToCity}
                                                    //onChange={handleLocationChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">State</label>
                                                    <Select
                                                        name="PayToState"
                                                        id="PayToState"
                                                       // value={mainLocationModal.PayToState}
                                                      //  onChange={handleLocationChange}
                                                        variant="outlined"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Zip Code</label>
                                                    <input type="text" className='form-control'
                                                    name="PayToZipCode"
                                                    id="PayToZipCode"
                                                   // value={mainLocationModal.PayToZipCode}
                                                   // onChange={handleLocationChange}  
                                                   />
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccordian('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel4a-header"
                                    >
                                        <p className="heading">Other Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row row-gap-15 row-half'>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Name</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Contact Number</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Email Address</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Fax Number</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className='input'>
                                                    <label htmlFor="">Website</label>
                                                    <input type="text" className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                        </Modal.Body>
                            <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                                <button className='btn btn-gray' onClick={onClose}>Cancel</button>
                                <button className='btn btn-orange' >Save</button>
                            </div>
                    </Modal> 
       </React.Fragment>
    )
}

export default AddNewLunchAndDinner