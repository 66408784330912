import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const ClusteredStackedColumnChart = ({data}) => {
  console.log("dataEmp",data)
  const monthCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let currMonth = new Date().getMonth() + 1;
  let currYear = new Date().getFullYear();
  let months = [
    ...monthCategories.slice(currMonth,12).map(month =>`${month } ${currYear-1}`),
    ...monthCategories.slice(0,currMonth).map(month =>`${month } ${currYear}`)
  ];
  const initialOptions = {
    series: data,
    chart: {
      type: 'bar',
      height: 220,
      stacked: true,
      toolbar: {
        show: false
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          fontSize: 12,
          fontFamily: 'Poppins-semibold',
          colors: '#707070',
        },
      },
    },
    yaxis: {
      show: true, 
      tickAmount: 5,
      labels: {
        style: {
          fontSize: 12,
          fontFamily: 'Poppins-medium',
          colors: '#707070',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ['#5AB461','#EBA889','#E16868','#EFBF20','#80ADBC'],
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: 10,
      fontFamily: 'Poppins-medium',
      markers: {
        height:10,
        width:10,
      }
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const seriesData = w.config.series.map((item,index) => {
          if(item.data?.[dataPointIndex]){
          return `
          <div class='d-flex justify-content-between mt-1' style = 'width: 150px;'>
          <p class='xsm_text'><span style='display: inline-block; background-color: ${w.globals.colors[index]};  width: 6px; height: 6px; border-radius: 3px ; margin-right: 5px;' ></span>${item.name}</p> 
          <p class='xsm_text'> ${item.data?.[dataPointIndex]}
          </div>`};
        });
        return `
        <div class="apexcharts-tooltip-title pt-0 mt-0">
          <p class='xsm heading app_border py-0 text-center border-end-0 border-start-0 border-top-0' style='margin: 0;'>${w.globals.labels[dataPointIndex]}</p>
          <div className='content'> ${seriesData.join('')}</div>
        </div>
        `;
      },
    },
    grid: {
      border: '0.25px solid #959595',  
    },
  };

  const [state, setState] = useState(initialOptions);

  return (
    <Chart
      options={state}
      series={state.series}
      type={state.chart.type}
      height={state.chart.height}
    />
  );
};

export default ClusteredStackedColumnChart;
