import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

const CustomUseRef = ({ children, onClose, IsActivePtPayments, type }) => {
  console.log("IsActivePtPayments", IsActivePtPayments)
  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef?.current && !containerRef?.current?.contains(event.target)) {
      onClose()
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  //   useLayoutEffect(() => {
  //     if (IsActivePtPayments && containerRef.current && containerRef.current.firstChild && type === 'menuButton') {

  //       containerRef.current.style.position = 'absolute' ;

  //    }
  //   }, [IsActivePtPayments, containerRef.current]);

  return (
    IsActivePtPayments ? <span ref={containerRef}>
      {children}
    </span> : null
  );
};

export default CustomUseRef;

