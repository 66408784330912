import React, { useState, useEffect} from "react"
import { useNavigate, useLocation } from 'react-router-dom'
import NotificationHandler from "../../utils/NotificationHandler"
import { enumUtil } from "../../utils/Enum"
import { ServerRequestProtected } from "../../utils/ServerDataRequest"
import Loader from "../shared/Loader/Loader"

const CheckEmail = () => {
    const AUTHENTICATE_API_URL = process.env.REACT_APP_AUHTENTICATE
    const [isLoading, setIsLoading] = useState(false)
    const  Email = useLocation().state;
    let navigate = useNavigate()

    const handleResendEmail = (Email) => {
        setIsLoading(true)
        ServerRequestProtected(AUTHENTICATE_API_URL + "/ForgotPassword", 'post',
        // ServerRequestProtected("http://192.168.18.5:438/api/Authentication/ForgotPassword", 'post',
        {
            ...Email
        }
      ).then((response) => {
          setIsLoading(false)
          NotificationHandler('Email Sent Successfully', enumUtil.enumtoaster.success)
      }).catch((error) => {
          setIsLoading(false)
          NotificationHandler('Failed to Resend', enumUtil.enumtoaster.error)
      })
    }
  return (
    <>
    {isLoading ? <Loader /> : null}
    <div className="login hk-login">
    <div className="d-lg-flex half">
        <div className="bg order-1 order-md-2">
            <img src={process.env.PUBLIC_URL + "/assets/images/Login_EMS_animation.png"} alt="" />
        </div>
        <div className="contents order-2 order-md-1">
            <h1 className="hk-title">Employee Management System</h1>
            <div className="container hk-form-t">
                <div className="row align-items-start justify-content-center">
                    <div className="col-md-8 d-flex flex-column justify-content-center text-center">
                        <div className="hk-t-img">
                            <img src={process.env.PUBLIC_URL + "/assets/images/confirm-icon.svg"} alt="confirm email icon" />
                        </div>
                        <h2 class="heading xl text-center">Check your email</h2>
                        <p className="text clr-muted text-center mt-3 forget-p">Reset password link has been sent to your email.</p>
                        <a className="btn rounded btn-blue w-100 mt-4 reset-pass-btn mt-5" href="https://gmail.com" target="_blank" rel="noreferrer">
                         Open email app </a>
                        <p className="text text-center fw-semibold mt-5">Didn’t receive the email? <a className="hk-link"onClick={()=>handleResendEmail(Email)}>Click to resend</a></p>
                        <a className="text text-center inst-txt mt-4 text-decoration-none" onClick={()=>navigate("../", { replace: true })}><span class="icon-Icon-ionic-md-arrow-round-back"></span> Back to Login</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</>
  )
}
export default  CheckEmail
