import React, { useState } from 'react'

const AddLeaveType = (props) => {
    const { Leave_Type_Modal, PolicyValidationModal, subTypes, setSubTypes, mainPolicyTypeModal, setMainPolicyTypeModal, savePolicyTypeModal, setSavePolicyTypeModal } = { ...props }

    const handleOnChangeTypes = (e, index, type) => {

        const { name, value, checked, type: inputType } = e.target;

        // console.log("CHECKED", index, value, checked)
        // setSubTypes(prev => {
        //     prev[index][name] = type !== 'radio' || checked;
        //     return prev;
        // })
        let modal = [...subTypes]
        if (inputType === 'radio') {
            modal[index][`Days${index}`] = type === 'Days';
            modal[index][`Hours${index}`] = type === 'Hours';
        } else {
            modal[index][name] = value;
        }
        setSubTypes(modal);
    }

    const handleSubTypesIteration = (e, index) => {
        let dynamicSubType = [...subTypes];
        let newObj = { key: index + 1, SubType: '', [`Days${index + 1}`]: false, [`Hours${index + 1}`]: false };
        dynamicSubType = [...dynamicSubType, newObj]
        setSubTypes(dynamicSubType)
        // setSubTypes([...subTypes, { ...SUB_TYPE, key: subTypes.length }])
    }

    const handleOnChange = (e) => {
        const { name, value, checked } = e.target;
        let modal = { ...mainPolicyTypeModal }
        let saveModal = { ...savePolicyTypeModal }
        if (name === "hasSubTypes") {
            modal[name] = checked;
        } else {
            modal[name] = value;
        }

        setMainPolicyTypeModal(modal);
        for (let property in Leave_Type_Modal) {
            if (property === name) {
                if (name === "hasSubTypes") {
                    saveModal[property] = checked;
                } else {
                    saveModal[property] = value;
                }
            }
        }
        setSavePolicyTypeModal(saveModal);
    }

    return (
        <div>
            <div className="d-flex flex-column gap-15">
                <div className={`${PolicyValidationModal?.LeaveName ? "error" : ""} form-group input`}>
                    <label>Leave Name<span className='req'>*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        name="LeaveName"
                        id="LeaveName"
                        value={mainPolicyTypeModal?.LeaveName}
                        onChange={handleOnChange}
                        maxLength={250}
                    />
                    {PolicyValidationModal?.LeaveName}
                </div>
                <div className={`${PolicyValidationModal?.AllocationFrequency ? "error" : ""} form-group input ps-0`}>
                    <label>Allocation Frequency<span className='req'>*</span></label>
                    <select
                        className="form-control fc-xs"
                        name="AllocationFrequency"
                        id="AllocationFrequency"
                        aria-label="Default select example"
                        value={mainPolicyTypeModal?.AllocationFrequency}
                        onChange={handleOnChange}
                    >
                        <option value=''></option>
                        <option value='Monthly'>Monthly</option>
                        <option selected value='Yearly'>Yearly</option>

                    </select>
                    {PolicyValidationModal?.AllocationFrequency}
                </div>
                <div className={`${PolicyValidationModal?.LeaveDescription ? "error" : ""} form-group input`}>
                    <label htmlFor="HolidayDescription">
                        Leave Description<span className='req'>*</span>
                    </label>
                    <textarea
                        name="LeaveDescription"
                        id="LeaveDescription"
                        className="form-control"
                        cols="30"
                        rows="3"
                        value={mainPolicyTypeModal?.LeaveDescription}
                        onChange={handleOnChange}
                    // placeholder="Enter Reason for leave"
                    ></textarea>
                    {PolicyValidationModal?.LeaveDescription}
                </div>
                {/* {console.log("Main", PolicyValidationModal,typeof PolicyValidationModal?.SubType)} */}
                <div className={`${PolicyValidationModal?.SubType ? 'error ' : ''} form-group input d-flex justify-content-between`}>
                    <div className={`d-flex gap-1 align-items-center`}>
                        <div className="hk-table-input custom_check_box d-flex align-items-center">
                            <input type="checkbox" id='hasSubTypes' name='hasSubTypes' value={mainPolicyTypeModal?.hasSubTypes} checked={mainPolicyTypeModal?.hasSubTypes} onChange={handleOnChange} className="mt-0" />
                        </div>
                        <p className="text clr_gry" >has subtypes</p>
                    </div>
                    {/* <div className='req'>{PolicyValidationModal?.SubType[0]}</div> */}
                </div>
                {console.log("MainSubTypes", mainPolicyTypeModal)}
                {/* {console.log("PolicyValidationModal", PolicyValidationModal)} */}
                {mainPolicyTypeModal?.hasSubTypes && subTypes?.map((item, index) => {
                    return (
                        <SubType index={index} item={item} subTypes={subTypes} handleOnChangeTypes={handleOnChangeTypes} handleSubTypesIteration={handleSubTypesIteration} PolicyValidationModal={PolicyValidationModal.SubType.length  > 0 ? PolicyValidationModal.SubType[index] : PolicyValidationModal.SubType } />
                        // <div className="row m-0">
                        //     <div className="col-md-8 ps-0">
                        //         <div className="form-group input">
                        //             <label>Subtype {index + 1}</label>
                        //             <input
                        //                 type="text"
                        //                 className="form-control"
                        //                 name="SubType"
                        //                 id="SubType"
                        //                 value={item?.SubType}
                        //                 onChange={(e) => handleOnChangeTypes(e, index)}
                        //                 maxLength={250}
                        //             ></input>
                        //         </div>
                        //     </div>
                        //     <div className="col-md-4 ps-0">
                        //         <p className="text clr_gry mb-2">Units</p>
                        //         <div className="d-flex align-items-center gap-2" key={subTypes}>
                        //             <div className="ar_radio-btn d-flex align-items-center gap-1">
                        //                 <input type="radio" id={`Days${index}`} name={`Days${index}`} checked={item?.Days} onChange={(e) => handleOnChangeTypes(e, index, 'radio')} />
                        //                 <p className="text">Days</p>
                        //             </div>
                        //             <div className="ar_radio-btn d-flex align-items-center gap-1">
                        //                 <input type="radio" id={`Hours${index}`} name={`Hours${index}`} checked={item?.Hours} onChange={(e) => handleOnChangeTypes(e, index, 'radio')} />
                        //                 <p className="text">Hours</p>
                        //             </div>
                        //             {subTypes.length - 1 == index ? <i className="icon-plus fs-22" onClick={handleSubTypesIteration}></i> : <></>}
                        //         </div>
                        //     </div>
                        // </div>
                    )
                })}

                {/* <div className="row m-0">
                    <div className="col-md-8 ps-0">
                        <div className="form-group input">
                            <label>Subtype 1</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Address"
                                id="Address"
                                maxLength={250}
                            ></input>
                        </div>
                    </div>
                    <div className="col-md-4 ps-0">
                        <p className="text clr_gry mb-2">Units</p>
                        <div className="d-flex align-items-center gap-2">
                            <div className="ar_radio-btn d-flex align-items-center gap-1">
                                <input type="radio" />
                                <p className="text">Days</p>
                            </div>
                            <div className="ar_radio-btn d-flex align-items-center gap-1">
                                <input type="radio" />
                                <p className="text">Hours</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-md-8 ps-0">
                        <div className="form-group input">
                            <label>Subtype 1</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Address"
                                id="Address"
                                maxLength={250}
                            ></input>
                        </div>
                    </div>
                    <div className="col-md-4 ps-0">
                        <p className="text clr_gry mb-1">Units</p>
                        <div className="d-flex align-items-center gap-2">
                            <div className="ar_radio-btn d-flex align-items-center gap-1">
                                <input type="radio" />
                                <p className="text">Days</p>
                            </div>
                            <div className="ar_radio-btn d-flex align-items-center gap-1">
                                <input type="radio" />
                                <p className="text">Hours</p>
                               
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default AddLeaveType

export const SubType = (props) => {
    const { index, item, handleOnChangeTypes, subTypes, handleSubTypesIteration, PolicyValidationModal } = { ...props }
    console.log("PolicyValidationModal", PolicyValidationModal)
    let hasValidation;
    let validationType;
    // console.log("PolicyValidationModal", PolicyValidationModal)
    // if (PolicyValidationModal.SubType. instanceof Array) {
    //     hasValidation = PolicyValidationModal.SubType.find((obj) => obj.key === item.key)
    // }
    return (
        <>
            <div className="row m-0">
                <div className="col-md-8 ps-0">
                    <div className={`${PolicyValidationModal} form-group input`}>
                        <label>Subtype {index + 1}</label>
                        <input
                            type="text"
                            className="form-control"
                            name="SubType"
                            id="SubType"
                            value={item?.SubType}
                            onChange={(e) => handleOnChangeTypes(e, index)}
                            maxLength={250}
                        />
                    </div>
                </div>
                <div className="col-md-4 ps-0">
                    <p className="text clr_gry mb-2">Units</p>
                    <div className="d-flex align-items-center gap-2" key={subTypes}>
                        <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" id={`Days`} name={`Days${index}`} checked={item[`Days${index}`]} onChange={(e) => handleOnChangeTypes(e, index, 'Days')} />
                            <p className="text">Days</p>
                        </div>
                        <div className="ar_radio-btn d-flex align-items-center gap-1">
                            <input type="radio" id={`Hours`} name={`Hours${index}`} checked={item[`Hours${index}`]} onChange={(e) => handleOnChangeTypes(e, index, 'Hours')} />
                            <p className="text">Hours</p>
                        </div>
                        {subTypes.length - 1 == index ? <i className="icon-plus fs-22" onClick={(e) => handleSubTypesIteration(e, index)}></i> : <></>}
                    </div>
                </div>
            </div>
            {/* <p>{PolicyValidationModal ? "Error Exist" : ""}</p> */}
        </>
    )
}
