import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import isNull from '../../../utils/NullChecking'
const CountriesStates = (props) => {
    const { mainEmployeeModal, handleEmployeeChange, columnLength } = { ...props }
    const config = {
        cUrl: 'https://api.countrystatecity.in/v1/countries',
        ckey: 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA=='
    }

    const [countriesData, SetCountriesData] = useState([]);
    const [provinceData, SetProvinceData] = useState([]);
    const [citiesData, SetCitiesData] = useState([]);


    const countrySelect = useRef(null);
    const stateSelect = useRef(null);
    const citySelect = useRef(null);
    let countryExist = countrySelect.current;
    let stateExist = stateSelect.current;
    let cityExist = citySelect.current;

    console.log("model he model", mainEmployeeModal?.City, mainEmployeeModal?.Province, mainEmployeeModal?.Nationality)

    function loadCountries() {
        SetCountriesData([])
        let apiEndPoint = config.cUrl
        // let countryExist = countrySelect.current;
        let stateExist = stateSelect.current;
        let cityExist = citySelect.current;
        axios(apiEndPoint, { headers: { "X-CSCAPI-KEY": config.ckey } })
            .then(data => {
                console.log(data?.data);
                SetCountriesData(data?.data);

                // data?.data.forEach(country => {
                //     const option = document.createElement('option')
                //     option.value = country.iso2
                //     option.textContent = country.name
                //     if (countryExist) {
                //         countryExist?.appendChild(option)
                //     }

                // })
            })
            .catch(error => console.error('Error loading countries:', error))

        if (stateExist && cityExist) {
            stateExist.disabled = true
            cityExist.disabled = true
            stateExist.style.pointerEvents = 'none'
            cityExist.style.pointerEvents = 'none'
        }
    }


    function loadStates() {
        SetProvinceData([])
        if (stateExist && cityExist) {
            stateExist.disabled = false
            cityExist.disabled = true
            stateExist.style.pointerEvents = 'auto'
            cityExist.style.pointerEvents = 'none'
        }

        axios(`${config.cUrl}/${mainEmployeeModal?.Nationality}/states`, { headers: { "X-CSCAPI-KEY": config.ckey } })
            // .then(response => response.json())
            .then(data => {
                // console.log(data);
                SetProvinceData(data?.data);

            })
            .catch(error => console.error('Error loading countries:', error))
    }


    function loadCities() {
        SetCitiesData([]);
        if (stateExist && cityExist) {
            cityExist.disabled = false
            cityExist.style.pointerEvents = 'auto'
        }


        axios(`${config.cUrl}/${mainEmployeeModal?.Nationality}/states/${mainEmployeeModal?.Province}/cities`, { headers: { "X-CSCAPI-KEY": config.ckey } })
            // .then(response => response.json())
            .then(data => {
                // console.log(data);
                SetCitiesData(data?.data);

            })
    }

    useEffect(() => {
        loadCountries()
    }, [mainEmployeeModal.Nationality])
    useEffect(() => {
        if (!isNull(mainEmployeeModal.Nationality)) {
            loadStates()
        }
    }, [mainEmployeeModal.Nationality])
    useEffect(() => {
        if (!isNull(mainEmployeeModal.Province)) {
            loadCities()
        }
    }, [mainEmployeeModal.Province])

    return (
        <>

            {/* <div className="row m-0"> */}
            <div className={`${columnLength} form-group input ps-0`}>
                <label>Nationality</label>
                <select className="form-control fc-xs" name='Nationality' id='Nationality' ref={countrySelect} aria-label="Default select example" onChange={handleEmployeeChange} value={mainEmployeeModal?.Nationality}>
                    <option selected>Select Country</option>
                    {countriesData?.map((item) => {
                        return (
                            <option value={item?.iso2}>{item?.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className={`${columnLength} form-group input`}>
                <label>Province</label>
                <select className="form-control fc-xs" name='Province' id='Province' ref={stateSelect} aria-label="Default select example" onChange={handleEmployeeChange} value={mainEmployeeModal?.Province}>
                    <option selected>Select State</option>
                    {provinceData?.map((item) => {
                        return (
                            <option value={item?.iso2}>{item?.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className={`${columnLength} form-group input`}>
                <label>City</label>
                <select className="form-control fc-xs" name='City' id='City' ref={citySelect} aria-label="Default select example" onChange={handleEmployeeChange} value={mainEmployeeModal?.City}>
                    <option selected>Select City</option>
                    {citiesData?.map((item) => {
                        return (
                            <option value={item?.iso2}>{item?.name}</option>
                        )
                    })}
                </select>
            </div>

        </>

        // </div>
    )
}

export default CountriesStates
