import React, { useReducer, useState } from 'react'
import CustomUseRef from '../../../shared/CustomUseRef/CustomUseRef';
import { enumUtil } from '../../../../utils/Enum';
import DeleteConfirmation from '../../../shared/Delete/DeleteConfirmation';
import ReactDataGrid from "react-data-grid";
import MainTop from '../../../../utils/MainTop';
import { ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import isNull from '../../../../utils/NullChecking';
import AddNewAnnouncement from './AddNewAnnouncement';
import Loader from '../../../shared/Loader/Loader';
import GridColumnHeader from '../../../shared/GridColumnHeader/GridColumnHeader';

const Announcement = () => {

    const [actionsbutton, setActionsbutton] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [AllAnnouncementData, setAllAnnouncementData] = useState([])
    const [AnnouncementID, SetAnnouncementID] = useState(0)
    const [status, setStatus] = useState("")
    const [IsAnnouncementOpen, setIsAnnouncementOpen] = useState()
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const API_URL = process.env.REACT_APP_API;
    const [delID, setDelID] = useState(0);
    const [delData, setDelData] = useState();
    const [searchParam, setSearchParam] = useState();
    const [EnterParam, setEnterParam] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [PerPage, setPerPage] = useState(10);
    const [PageNo, setPageNo] = useState(1);
    const [TotalPages, setTotalPages] = useState();
    const handlePageClick = (page) => { setPageNo(page); }
    const PreviousPage = () => { if (PageNo > 1) { setPageNo(PageNo - 1) } }
    const NextPage = () => { if (PageNo < TotalPages) setPageNo(PageNo + 1); }
    const handleFingByCreteria = (e) => {
        setPerPage(e.target.value)
        setPageNo(1)
    }
    const [gridHeader, handleGridHeader] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, { defaultData: '', activeSortIcon: '' })

    const CloseActionPopup = () => {
        setActionsbutton(false);
    }
    const handeActionPopUp = (e, id) => {
        e.stopPropagation();
        setActionsbutton(true);
        setSelectedRow(id);
    }

    const rows = [
        { id: 1, Title: 'EID', Description: 'Shawal Moon', CreatedDate: '01/01/2024', CreatedBy: 'Haris', Status: 'Approved' },
        { id: 2, Title: 'Shab e barat', Description: 'Night of blessings', CreatedDate: '01/01/2024', CreatedBy: 'Taimoor', Status: 'Approved' },
        { id: 3, Title: 'Eid Ul Azha', Description: 'Zil Haj Moon', CreatedDate: '01/01/2024', CreatedBy: 'Muhammad Usman', Status: 'Approved' },
        { id: 4, Title: 'Defence Day', Description: 'Hifazti din', CreatedDate: '01/01/2024', CreatedBy: 'Sheery', Status: 'Denied' },
        { id: 5, Title: 'Youm e takbeer', Description: 'Atom Bomb', CreatedDate: '01/01/2024', CreatedBy: 'Abdul Mateen', Status: 'Pending' },
    ]
    const columns = [
        {
            key: 'Title',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Title'} sortIcon={true} type={'string'} colKey={'Title'} data={AllAnnouncementData} setData={setAllAnnouncementData} />),
                width: 200,
            resizable: true,
            // flex: 1,
            // formatter: ({ row }) => {
            //     return (
            //         <button className='btn' onClick={""}>
            //             {row?.CategoryName}
            //         </button>
            //     )
            // }
        },
        {
            key: 'Description',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Description'} sortIcon={true} type={'string'} colKey={'Description'} data={AllAnnouncementData} setData={setAllAnnouncementData} />),
                width: 300,
            resizable: true,
            flex: 1,
            formatter: ({ row }) => {
                return (
                    <div className="validation_message" title={row?.Description}>
                        {row?.Description}
                    </div>
                )
            }
        },
        {
            key: 'CreatedDate',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'CreatedDate'} sortIcon={true} type={'date'} colKey={'Created Date'} data={AllAnnouncementData} setData={setAllAnnouncementData} />),

            resizable: true,
            flex: 1,
        },

        {
            key: 'CreatedBy',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'CreatedBy'} sortIcon={true} type={'string'} colKey={'Created By'} data={AllAnnouncementData} setData={setAllAnnouncementData} />),

            resizable: true,
            flex: 1,
        },
        {
            key: 'Status',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Status'} sortIcon={true} type={'string'} colKey={'Status'} data={AllAnnouncementData} setData={setAllAnnouncementData} />),

            resizable: true,
            flex: 1,
            // formatter: ({ row }) => {
            //     return (

            //         <div class="form-check form-switch">
            //             <input
            //                 class="form-check-input border-0 rounded-5 shadow"
            //                 type="checkbox"
            //                 role="switch"
            //                 id="flexSwitchCheckChecked"
            //             />
            //         </div>
            //     )
            // }
        },
        {
            key: 'Actions',
            name: 'Actions',
            width: 80,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <>
                        <div className="w-50 text-start" onClick={(e) => handeActionPopUp(e, row?.ID)} >
                            <i className="icon-menuicon cursor-pointer"></i>
                        </div>
                        {console.log("selectedRow === row?.EmployeeDocumentID", selectedRow === row?.ID, selectedRow, row?.ID)}
                        {selectedRow === row?.ID &&
                            <CustomUseRef onClose={CloseActionPopup} IsActivePtPayments={actionsbutton}>
                                <div className="actions_popup cursor-pointer">
                                    <p className="text  p-2 py-0" onClick={() => AddAnnouncement(row)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                                    {row?.Status === "Pending" && <p className="text p-2 py-0" onClick={() => handleDeleteModal(row?.ID)}><i className="icon-Group-3994 me-1"></i>Delete</p>}
                                </div>
                            </CustomUseRef>
                        }
                    </>
                );
            },
        },
    ];

    const handleDeleteModal = (row) => {
        setDelID(row?.CheckOutRequestID);
        setDelData(row);
        setIsOpenAlert(true);
    }

    const onCloseDelete = () => {
        setDelID(0);
        setIsOpenAlert(false)
        // LoadData()
    }

    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Announcement} ID={delID} data={delData} onClose={onClose} />
    }

    const AddAnnouncement = (row) => {
        SetAnnouncementID(row?.ID);
        setIsAnnouncementOpen(true);
        setStatus(row?.Status);
    }

    const OnCloseAnnouncement = () => {
        setIsAnnouncementOpen(false);
        if (isRefresh) {
            LoadData(searchParam)
        }
    }

    let PopUpScreen = ""
    if (IsAnnouncementOpen) {
        PopUpScreen = <AddNewAnnouncement isOpenPopup={IsAnnouncementOpen} onClose={OnCloseAnnouncement} setIsRefresh={setIsRefresh} />
    }

    const LoadData = (param) => {
        setAllAnnouncementData([])
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.Announcement,
            "SearchParam": param,
            "PerPage": PerPage,
            "PageNo": PageNo,
        }).then((response) => {
            setIsLoading(false);
            setAllAnnouncementData(response);
            handleGridHeader({defaultData: response})
            setTotalPages(response[0].NumOfPages);
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }

    const SearchOnEnter = (e) => {
        if (e.key === "Enter") {
            setEnterParam(e.target.value)
            // setSearchParam(EnterParam)
            SearchByParam(searchParam)
        }
    }
    const SearchByParam = (searchParam) => {
        if (!isNull(searchParam)) {
            setPageNo(1)
            LoadData(searchParam)
        } else {
            LoadData()
        }
    }
    const Change = (e) => {
        setSearchParam(e.target.value)
    }

    return (

        <div className='table-view all_employee'>
            {PopUpScreen}
            {isLoading ? <Loader /> : <></>}

            <MainTop openModal={AddAnnouncement} searchParam={searchParam} Change={Change} LoadData={LoadData} SearchOnEnter={SearchOnEnter} />
            <ReactDataGrid
                enableVirtualization={false}
                // virtualizeColumns={columns.length > 10}
                columns={columns}
                rows={rows}
                rowHeight={30}
                className={"fill-grid DataGrid-Table hk-exp-table all_employee_grid"}
                style={{
                    width: '100%',
                    // height:'100px',
                    // maxHeight: '330px',
                }}
            />
        </div>

    )
}

export default Announcement
