import SettingTopMenu from "./SettingLayout/SettingTopMenu"

const Setting = () => {

    return (
        <div>
            <SettingTopMenu />
        </div>
    )
}
export default Setting