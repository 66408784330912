import React, { useState } from "react"; 
import Nav from "react-bootstrap/Nav";
const ProvidentFundPolicy = () => {
    const [activeTab, setActiveTab] = useState("Yearly");  
      const handleTabSelect = (eventKey) => {
          setActiveTab(eventKey);
        }
  return (
    <div className='leave_policy'>
      <div className="leave_policy_content"> 
      <div className='mt-3 d-flex gap-3'> 
        <p className='heading '>Duration</p>   
      <Nav variant="tabs" className="rb-tabs-v3 tabs_view4 gap-3" activeKey={activeTab} onSelect={handleTabSelect}>
          <Nav.Item>
              <Nav.Link eventKey="Yearly"> 
                  <div className="form-check">
                      <input type="radio" className="radio_btn form-check-input rounded-5" id="Provider" name="GroupBy" value="Provider"/>
                      <label className="form-check-label m-0" for="radio2">Yearly</label>
                  </div>
              </Nav.Link>
          </Nav.Item>
          <Nav.Item>
              <Nav.Link eventKey="Monthly">  
                <div className="form-check">
                    <input type="radio" className="radio_btn form-check-input rounded-5" id="Provider" name="GroupBy" value="Provider"/>
                    <label className="form-check-label m-0" for="radio2">Monthly</label>
                </div>
              </Nav.Link>
          </Nav.Item> 
      </Nav>    
      </div>
      { activeTab === "Yearly" &&
        <> 
      <div className='gap-3 mt-3'>
        <p className='heading w-fit-content'>Fund Allocation</p>   
      </div>
      <div className='mt-2 ms-3 me-2'>
        <div className='table_section'>
          <div className='table_row d-flex bottom_border'>
              <div className='p-2 right_border'>
                <div className="form-check custom_check_box m-0 me-2">
                  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                  <label className="form-check-label gry_heading" for="inlineCheckbox1">No.</label>
                </div>
              </div>
              <div className='p-2 px-3 w-50 right_border'>
                <p className='gry_heading'>  Fund Type</p>
              </div>
              <div className='p-2 px-3 w-50 text-end right_border'>
                <p className='gry_heading'>Annual Allocation</p>
              </div>
              <div className='p-2 px-3 w-25'>
                <p className='gry_heading'>  Action</p>
              </div>
          </div> 
          <div className='table_row d-flex'>
              <div className='p-2 right_border num'>
                <div className="form-check custom_check_box m-0 me-2">
                  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                  <label className="form-check-label" for="inlineCheckbox1">1.</label>
                </div>
              </div>
              <div className='p-2 px-3 w-50 right_border'>
                <p className='text'>    Provident Fund</p>
              </div>
              <div className='p-2 px-3 w-50 text-end right_border'>
                <p className='text'>$12 </p>
              </div>
              <div className='p-2 px-3 w-25'>
                <p className='text'><i className='icon-edit-pencil me-1'></i>Edit</p>
              </div>
          </div> 
        </div>
      </div>
      <div className='gap-3 mt-3'>
        <p className='heading w-fit-content'>  Provident Fund Policy</p>   
      </div>
        <div className="ms-3 mt-3 w-fit-content">
        <p className="heading">Provident Fund Policy</p>
        <p className="text d-flex align-items-center">
            <span className="list"></span>
            Employees are required to contribute a specified percentage to the provident Fund
        </p>
        <p className="text d-flex align-items-center">
            <span className="list"></span>
            Provident Fund deduction will be made from the employee’s salary on a monthly basis.
        </p> 
        <p className="text mt-1 mb-2 d-flex align-items-center">
            <span className="list"></span>
            More than         
            <div class="form-group input my_input">
            <input type="text" class="form-control" name="BenefitName" id="BenefitName" maxlength="35" value=""/>
            </div>
            Medical Leaves will result in deduction to salary
        </p>
        </div>  
        </>
      }
      { activeTab === "Monthly" &&
        <> 
        <div className='gap-3 mt-3'>
          <p className='heading w-fit-content'>Leave Allocation</p>   
        </div>
        <div className='mt-2 ms-3 me-2'>
          <div className='table_section'>
            <div className='table_row d-flex bottom_border'>
                <div className='p-2 right_border'>
                  <div className="form-check custom_check_box m-0 me-2">
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                    <label className="form-check-label gry_heading" for="inlineCheckbox1">No.</label>
                  </div>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='gry_heading'>Leave Type</p>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='gry_heading'>  Monthly Allocation</p>
                </div>
                <div className='p-2 px-3 w-25'>
                  <p className='gry_heading'>  Action</p>
                </div>
            </div>
            <div className='table_row d-flex bottom_border'>
                <div className='p-2 right_border num'>
                  <div className="form-check custom_check_box m-0 me-2">
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                    <label className="form-check-label" for="inlineCheckbox1">1.</label>
                  </div>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='text'>  Full Day </p>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='text'>12 </p>
                </div>
                <div className='p-2 px-3 w-25'>
                  <p className='text'><i className='icon-edit-pencil me-1'></i>Edit</p>
                </div>
            </div>
            <div className='table_row d-flex bottom_border'>
                <div className='p-2 right_border num'>
                  <div className="form-check custom_check_box m-0 me-2">
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                    <label className="form-check-label" for="inlineCheckbox1">1.</label>
                  </div>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='text'>    Half Day</p>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='text'>12 </p>
                </div>
                <div className='p-2 px-3 w-25'>
                  <p className='text'><i className='icon-edit-pencil me-1'></i>Edit</p>
                </div>
            </div>
            <div className='table_row d-flex'>
                <div className='p-2 right_border num'>
                  <div className="form-check custom_check_box m-0 me-2">
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                    <label className="form-check-label" for="inlineCheckbox1">1.</label>
                  </div>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='text'>  Short Leaves</p>
                </div>
                <div className='p-2 px-3 w-50 right_border'>
                  <p className='text'>12 </p>
                </div>
                <div className='p-2 px-3 w-25'>
                  <p className='text'><i className='icon-edit-pencil me-1'></i>Edit</p>
                </div>
            </div>
          </div>
        </div>
      <div className='gap-3 mt-3'>
        <p className='heading w-fit-content'>  Medical Leave Policy</p>   
      </div>
        <div className="ms-3 mt-3 w-fit-content">
        <p className="heading">Medical Leave Policy</p>
        <p className="text d-flex align-items-center">
            <span className="list"></span>
            Employee should notify the employer in advance if unable to report to work due to illness.
        </p>
        <p className="text d-flex align-items-center">
            <span className="list"></span>
            Medical Leave will be considered valid with the submission of a medical certificate.
        </p>
        <p className="text mt-1 d-flex align-items-center">
            <span className="list"></span>
            Maximum          
            <div class="form-group input my_input">
            <input type="text" class="form-control" name="BenefitName" id="BenefitName" maxlength="35" value=""/>
            </div>
            Medical Leaves allowed per year
        </p>
        <p className="text mt-1 mb-2 d-flex align-items-center">
            <span className="list"></span>
            More than         
            <div class="form-group input my_input">
            <input type="text" class="form-control" name="BenefitName" id="BenefitName" maxlength="35" value=""/>
            </div>
            Medical Leaves will result in deduction to salary
        </p>
        </div>  
        </>
      }
      <div className="btns-group text-end mt-2 px-3 br-top-1 br-bottom-1 br-clr d-flex align-items-center justify-content-end py-1 position-absolute w-100 bottom-0 ar-tabs-action-bottom ar-tabs-action-bottom">
        <button className="btn btn-gray">Cancel</button>
        <button className="btn btn-orange">Save</button>
      </div>
      </div>
    </div>
  )
}

export default ProvidentFundPolicy
