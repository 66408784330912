import React from "react";
import EmployeeInformation from "../../../MyInfo/Account/EmployeeInformation/EmployeeInformation";
import PayrollEmployee from "./PayrollEmployee";

const Payslip = () => {
  return (
    <div className="basic_info leave_screen pb-2 ar_payslip-screen">
      <div className="row m-0 pt-1">
        <div className="col-md-3 ps-1">
          <EmployeeInformation />
        </div>
        <div className="col-md-9 ps-0 pe-1">
          <PayrollEmployee />
        </div>
      </div>
    </div>
  );
};
export default Payslip;
