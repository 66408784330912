import React, { useState } from 'react'
import isNull from '../../../utils/NullChecking';

const GridColumnHeader = (props) => {
    const { iconName, headerName, sortIcon, data, setData, type, colKey, gridHeader, handleGridHeader, infoIcon } = { ...props }
    const sortOrderTypeName = ['asc', 'desc', 'default'];
    const [sortOrderType, setSortOrderType] = useState(0)
    // console.log(gridHeader.activeSortIcon )
    const handleAlphabetSort = (type, colKey) => {
        ////=====To set icon to default in non active elements====///
        handleGridHeader({ activeSortIcon: colKey })
        ////=====sorting on basis of Data Type and Sorting Type====///
        const sortedData = [...data];
        let res = sortedData.sort((a, b) => {
            const valA = type === "string" && !isNull(a[colKey]) ? a[colKey].toLowerCase() : a[colKey];
            const valB = type === "string" && !isNull(a[colKey]) ? b[colKey].toLowerCase() : b[colKey];

            let comparison = 0;
            if (valA > valB) { comparison = 1; }
            else if (valA < valB) { comparison = -1; }
            else {
                comparison = 1;
            }

            if (type === "number") {
                return sortOrderTypeName[sortOrderType] === 'asc' ? valA - valB : sortOrderTypeName[sortOrderType] === 'desc' && valB - valA;
            }
            if (type === "string") {
                return sortOrderTypeName[sortOrderType] === 'asc' ? comparison : sortOrderTypeName[sortOrderType] === 'desc' && -comparison;
            }
            if (type === "date") {
                const dashFormat = /^(\d{2})-(\d{2})-(\d{4})$/;
                const slashFormat = /^(\d{2})\/(\d{2})\/(\d{4})$/;
                let matchesDashFormat = a[colKey].match(dashFormat);
                let matchesSlashFormat = a[colKey].match(slashFormat);
                let dateA = '';
                let A = '';
                let dateB = '';
                let B = '';

                if (matchesSlashFormat) {
                    dateA = a[colKey]?.split("/");
                    A = `${dateA[2]}-${dateA[0]}-${dateA[1]}`;
                    dateB = b[colKey]?.split("/");
                    B = `${dateB[2]}-${dateB[0]}-${dateB[1]}`;
                }
                else {
                    A = a[colKey]
                    B = b[colKey]
                }

                return sortOrderTypeName[sortOrderType] === 'asc' ? new Date(A) - new Date(B) : sortOrderTypeName[sortOrderType] === 'desc' && new Date(B) - new Date(A);
            }
            if (type === "time") {

                // if (isNull(valA)) return 1;  // If valA is undefined, place it after b.time
                // if (isNull(valB)) return -1;

                // const isAM1 = valA.includes("AM");
                // const isAM2 = valB.includes("AM");

                // let comparison = 0;
                // if (isAM1 && !isAM2) { comparison = 1; }
                // else if (!isAM1 && isAM2) { comparison = -1; }
                // else {
                //     comparison = valA.localeCompare(valB)
                // }
                return sortOrderTypeName[sortOrderType] === 'asc' ? comparison : sortOrderTypeName[sortOrderType] === 'desc' && -comparison;
            }
        })
        sortOrderTypeName[sortOrderType] === 'default' ? setData(gridHeader.defaultData) : setData(res);
        setSortOrderType(sortOrder => sortOrder < 2 ? sortOrder + 1 : 0)
    }
    return (
        <>
            <div className="column-container d-flex align-items-center gap-2">
                <span className=''>
                    {/* <i className={iconName}></i> */}
                    {headerName}</span>

                {sortIcon &&
                    (gridHeader.activeSortIcon === colKey ?
                        (<span className='d-flex' onClick={() => handleAlphabetSort(type, colKey)}>
                            {sortOrderType === 0 && <span class="icon-arrows-gray"></span>}
                            {sortOrderType === 1 && <span class="icon-arrows-ACC"><span class="path1"></span><span class="path2"></span></span>}
                            {sortOrderType === 2 && <span class="icon-arrows-DCC"><span class="path1"></span><span class="path2"></span></span>}
                        </span>) :
                        (<span className='d-flex' onClick={() => handleAlphabetSort(type, colKey)}>
                            {<i className="icon-arrows-gray" />}
                        </span>))}
                {infoIcon &&

                    (
                        <>
                            <div className='ar_info-icon'> 
                                <i className='icon-info-icon d-flex'></i>
                                <div className='za-total-hour gap-1 mt-3'>
                                    <p className='heading fs-11 total_hours'>Total Hours Calculation</p>
                                    <p className='text text-wrap fs-10 the_total_hours'>
                                        The total hours are calculated by determining the difference between
                                        your check-in and check-out times, excluding  break time that is
                                        added to  your selected shift.
                                    </p>
                                </div>
                            </div>
                            <span className='d-flex' onClick={() => handleAlphabetSort(type, colKey)}>
                                {<i className="icon-arrows-gray" />}
                            </span>
                        </>
                    )}
            </div>
        </>
    )
}

export default GridColumnHeader
