import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Loader from '../shared/Loader/Loader';
import { ServerRequestProtected } from '../../utils/ServerDataRequest';
import { enumUtil } from '../../utils/Enum';
import { useDispatch, useSelector } from 'react-redux';
import NotificationHandler from '../../utils/NotificationHandler';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import isNull from '../../utils/NullChecking';
import { getExpirationDate } from '../../utils/Constants';
import DashAtendImg from "../../../src/assetsOld/img/dash-check-img.svg"
import close from "../../../src/assetsOld/img/x-close.svg"
import CheckInimg from "../../../src/assetsOld/img/Chekin-img.svg"
import chkinTopIcon from "../../../src/assetsOld/img/chekin-top-icon.svg"
import { format } from 'date-fns';

const YourAttendance = () => {
    const API_URL = process.env.REACT_APP_API
    let UserActivity_Modal = {
        AttendanceActivityID: "",
        Message: "Office",
    }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token);
    const [checkIn, setCheckIn] = useState(false);
    const [location, setLocation] = useState(null);
    const [address, setAddress] = useState('');
    const [checkOut, setCheckOut] = useState(false);
    const [mainUserActivityModal, setMainUserActivityModal] = useState(UserActivity_Modal);
    const [userActivitySaveUpdateModal, setUserActivitySaveUpdateModal] = useState({})
    const [userActivityCheckInTime, setUserActivityCheckInTime] = useState("00:00:00")
    const [userActivityCheckOutTime, setUserActivityCheckOutTime] = useState("00:00:00")
    const [checkStatus, setCheckStatus] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // const dispatch = useDispatch();  

    // const [UserActivityMessage, setUserActivityMessage] = useState(TopNavBar_Modal);
    const [currentDate, setCurrentDate] = useState(new Date());
    const formattedDate = format(currentDate, 'MMMM d, yyyy');
    const formattedTime = format(currentDate, 'h:mm a');
    const formattedDayName = format(currentDate, 'EEEE');
    const formattedDayNumber = format(currentDate, 'd');
    const formattedYear = format(currentDate, 'yyyy');

    useEffect(() => {
        Token.UserID && LoadData(Token.UserID); UseData(Token.UserID);
    }, [refresh, Token.UserID])

    const LoadData = (ID) => {
        setIsLoading(true);
        ServerRequestProtected(API_URL + "/CheckUserActivities", 'post', {
            "ID": ID
        }).then((response) => {
            setIsLoading(false);
            setCheckStatus(response)
        }).catch((error) => {

            // console.log("ETOKENINN",error.response.data)
            NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }

    const UseData = (ID) => {
        setIsLoading(true)
        ServerRequestProtected(API_URL + "/FindByID", 'post', {
            "ID": ID,
            "TableName": enumUtil.enumAPITableName.Employee
        }).then((response) => {
            setMainUserActivityModal({...mainUserActivityModal, Message: response?.UserActivityMessage});
            setIsLoading(false)
        }).catch((error) => {
            // NotificationHandler(error.message, enumUtil.enumtoaster.error)
            setIsLoading(false)
        })
    }

    const handleUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        const response = await axios.get(
                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBxuyvFDBw0eaa9qRiLC9XrYfuyTYdlc3I`
                        );
                        const result = response.data.results[0];
                        console.log("ResultAddress", result);
                        const resultAddress = result.formatted_address.split(",").slice(1, 3);
                        setAddress(resultAddress.join(","));
                    } catch (error) {
                        console.error('Error getting address:', error);
                    }
                    setLocation({ latitude, longitude });
                    console.log("Location", location)
                },
                (error) => {
                    console.error('Error getting location:', error);
                },
                { enableHighAccuracy: true }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleCheckIn = () => {
        setCheckIn(!checkIn)
        setCurrentDate(new Date())
        setMainUserActivityModal(UserActivity_Modal)
        setUserActivitySaveUpdateModal(UserActivity_Modal)
    }
    const handleCheckOut = () => {
        setCheckOut(!checkOut);
        setCurrentDate(new Date())
        // setMainUserActivityModal(UserActivity_Modal)
        setUserActivitySaveUpdateModal(UserActivity_Modal)
    }
    const handleTime = () => {
        const current = new Date();
        const time = current.toLocaleTimeString("en-US");
        return time;
    }

    const expirationTime = getExpirationDate();
    const handleUserActivityCheckIn = (e) => {
        mainUserActivityModal['AttendanceActivityID'] = "1"
        userActivitySaveUpdateModal['AttendanceActivityID'] = "1"
        setUserActivityCheckInTime(handleTime())
    }
    const handleUserActivityCheckOut = (e) => {
        mainUserActivityModal['AttendanceActivityID'] = "2"
        userActivitySaveUpdateModal['AttendanceActivityID'] = "2"
        setUserActivityCheckOutTime(handleTime())
        Cookies.remove("time")
    }
    const handleUserActivityMessage = (e) => {
        let value;
        if (typeof e === 'string') { value = e }
        else { value = e.target.value }
        let modal = { ...mainUserActivityModal };
        let saveModal = { ...userActivitySaveUpdateModal }
        modal['Message'] = value
        setMainUserActivityModal(modal)
        for (var property in UserActivity_Modal) {
            if ('Message' === property) {
                saveModal['Message'] = value
            }
        }
        setUserActivitySaveUpdateModal(saveModal)
    }
    const handleSaveUserActivity = async (e) => {
        setIsLoading(true)
        checkIn && handleUserActivityCheckIn()
        checkOut && handleUserActivityCheckOut()
        setUserActivitySaveUpdateModal(userActivitySaveUpdateModal)
        let saveModal = {
            tablename: enumUtil.enumAPITableName.UserActivity,
            id: "0",
            data: {
                ...userActivitySaveUpdateModal
            },
            value: null
        }
        ServerRequestProtected(API_URL + "/savesinglerecord", 'post', saveModal).then((response) => {
            if (!isNull(response.id)) {
                NotificationHandler(checkIn ? 'You have successfully checked in.' : 'You have successfully checked out.', enumUtil.enumtoaster.success)
                checkIn ?
                    Cookies.set("time", handleTime(), { expires: expirationTime })
                    : Cookies.remove("time")
                checkIn ? handleUserLocation() : setAddress('');
                setCheckOut(!checkOut)
                setCheckIn(!checkIn)
            }
            setRefresh(!refresh)
            setIsLoading(false)
        }).catch((error) => {
            Cookies.remove("time")
            NotificationHandler(checkIn ? 'You have failed to checked in.' : 'You have failed to checked out.', enumUtil.enumtoaster.error)
            setIsLoading(false)
        })
    }
    return (<>
        <div className='d-flex justify-content-between align-items-center'>
            <img src={DashAtendImg} />
            <div className='d-flex flex-column align-items-center overflow-hidden'>
                <p className="heading fs-14 font-m">Your Attendance</p>
                <p className="heading fs-20">{![null, undefined, 'null', ''].includes(Cookies.get("time")) ? Cookies.get("time") : userActivityCheckOutTime}</p>
                <div className="d-flex gap-3 justify-content-center pt-1 w-100">
                    {checkStatus ?
                        <>
                            <button className="btn btn_orange rounded px-2" onClick={handleCheckOut}>
                                Check out</button>
                        </>
                        : <>
                            <button className="btn btn_orange rounded" onClick={handleCheckIn}>
                                Check in</button>

                        </>}
                </div>
            </div>


        </div>
        {/* <Modal show={checkIn} onHide={handleCheckIn} backdrop="static" keyboard={false} centered dialogClassName="app-modal hk-check-modal">
            <Modal.Body>
                {isLoading ? <Loader /> : null}
                <div className='d-flex justify-content-end'>
                    <button className='btn-icon-transparent' onClick={handleCheckIn}><i className="icon-Icon-metro-cross"></i></button>
                </div>
                <div className="hk-check-content">
                    <div className='d-flex flex-column'>
                        <h3>Hi, {Token ? Token.Username : "John Peter"} </h3>
                        <p>Are you ready to work now !! If yes please check in.</p>
                        <span><i className="icon-Icon-ionic-ios-time"></i> Current Checked-in Time</span>

                        <div className="ps-3">
                            <p>{userActivityCheckInTime}</p>

                            <label htmlFor="eventfield">Choose event</label>
                            <input type="text"
                                className="form-control"
                                name="Message"
                                id="Message"
                                value={mainUserActivityModal.Message}
                                onChange={handleUserActivityMessage}
                            />
                        </div>
                    </div>
                    <img src={process.env.PUBLIC_URL + "/assets/images/CHECK-IN.svg"} alt='' />
                </div>
                <div className="hk-check-suggestions">
                    <h4 onClick={() => handleUserActivityMessage("I have just came to office")}>I have just came to office</h4>
                    <h4 onClick={() => handleUserActivityMessage("I am back")}>I am back</h4>
                    <h4 onClick={() => handleUserActivityMessage("I was on half day leave")}>I was on half day leave</h4>
                    <h4 onClick={() => handleUserActivityMessage("I have just came to office")}>I have just came to office</h4>
                    <h4 onClick={() => handleUserActivityMessage("I am back")}>I am back</h4>
                    <h4 onClick={() => handleUserActivityMessage("I was on half day leave")}>I was on half day leave</h4>
                    <h4 onClick={() => handleUserActivityMessage("I have just came to office")}>I have just came to office</h4>
                    <h4 onClick={() => handleUserActivityMessage("I am back")}>I am back</h4>
                    <h4 onClick={() => handleUserActivityMessage("I was on half day leave")}>I was on half day leave</h4>
                </div>


            </Modal.Body>
            <div className='btns-group text-end px-5 py-3 d-flex justify-content-end'>
                {checkStatus ?
                    <button className="btn btn-orange" disabled>Check in</button> :
                    <button className="btn btn-orange" onClick={handleSaveUserActivity}>Check in</button>}
            </div>
        </Modal> */}
        {/* New Design */}

        <Modal show={checkIn} onHide={handleCheckIn} backdrop="static" keyboard={false} centered dialogClassName="app-modal ar-check-modal">
            <Modal.Body>
                {isLoading ? <Loader /> : <></>}
                <div className='text-end'>
                    <img src={close} onClick={handleCheckIn} className='cursor-pointer' />
                </div>
                <div className='d-flex align-items-center flex-column'>
                    <div className='ar-tabs d-flex align-items-center gap-10 mb-2'>
                        <p className={`text fs-13 font-m d-flex align-items-center gap-1 cursor-pointer blue-color ${mainUserActivityModal?.Message === "Home" ? "active" : ""}`} onClick={() => handleUserActivityMessage("Home")}><i className='icon-att-home'></i> Home</p>
                        <p className={`text fs-13 font-m d-flex align-items-center gap-1 cursor-pointer blue-color ${mainUserActivityModal?.Message === "Office" ? "active" : ""}`} onClick={() => handleUserActivityMessage("Office")}><i className='icon-att-home'></i> Office</p>
                    </div>
                    <p className='text clr_gry fs-13'>{formattedDayName}<span className='ms-2'>{formattedDate}</span></p>
                    <p className='heading fs-16 mb-2'>{formattedTime}</p>
                    <img className='img-brder' src={CheckInimg} />
                    <div className='d-flex gap-25 align-items-center my-2'>
                        <div className='text-center'>
                            <img src={chkinTopIcon} />
                            <p className='text font-m'>{Cookies.get("time") ? Cookies.get("time") : userActivityCheckInTime}</p>
                            <p className='text fs-11 clr_gry'>Check In</p>
                        </div>
                        <div className='text-center'>
                            <img src={chkinTopIcon} />
                            <p className='text font-m'>{userActivityCheckOutTime}</p>
                            <p className='text fs-11 clr_gry'>Check Out</p>
                        </div>
                        <div className='text-center'>
                            <img src={chkinTopIcon} />
                            <p className='text font-m'>08:00:00</p>
                            <p className='text fs-11 clr_gry'>Working Hr’s</p>
                        </div>
                    </div>
                    <button className="btn btn-orange w-100" onClick={handleSaveUserActivity}>Check in</button>
                    {/* <div className='w-100'>
                        <button className='btn btn-orange mb-2 w-100' onClick={handleSaveUserActivity}>Check out</button>
                        <p className='text fs-10 fst-italic blue-color d-flex align-items-center justify-content-center gap-1'> <i className='icon-chk-location'></i>Asghar Mall Road Scheme</p>
                    </div> */}
                </div>
            </Modal.Body>
        </Modal>
        <Modal show={checkOut} onHide={handleCheckOut} backdrop="static" keyboard={false} centered dialogClassName="app-modal ar-check-modal">
            <Modal.Body>
                {isLoading ? <Loader /> : <></>}
                <div className='text-end'>
                    <img src={close} onClick={handleCheckOut} className='cursor-pointer' />
                </div>
                <div className='d-flex align-items-center flex-column'>
                    <div className='ar-tabs d-flex align-items-center gap-10 mb-2'>
                        <p className={`text fs-13 font-m d-flex align-items-center gap-1 cursor-pointer blue-color ${mainUserActivityModal?.Message === "Home" ? "active" : ""}`} onClick={() => handleUserActivityMessage("Home")}><i className='icon-att-home'></i> Home</p>
                        <p className={`text fs-13 font-m d-flex align-items-center gap-1 cursor-pointer blue-color ${mainUserActivityModal?.Message === "Office" ? "active" : ""}`} onClick={() => handleUserActivityMessage("Office")}><i className='icon-att-home'></i> Office</p>
                    </div>
                    <p className='text clr_gry fs-13'>{formattedDayName} <span className='ms-2'>{formattedDate}</span></p>
                    <p className='heading fs-16 mb-2'>{formattedTime}</p>
                    <img className='img-brder' src={CheckInimg} />
                    <div className='d-flex gap-25 align-items-center my-2'>
                        <div className='text-center'>
                            <img src={chkinTopIcon} />
                            <p className='text font-m'>{Cookies.get("time") ? Cookies.get("time") : userActivityCheckInTime}</p>
                            <p className='text fs-11 clr_gry'>Check In</p>
                        </div>
                        <div className='text-center'>
                            <img src={chkinTopIcon} />
                            <p className='text font-m'>{userActivityCheckOutTime}</p>
                            <p className='text fs-11 clr_gry'>Check Out</p>
                        </div>
                        <div className='text-center'>
                            <img src={chkinTopIcon} />
                            <p className='text font-m'>08:00:00</p>
                            <p className='text fs-11 clr_gry'>Working Hr’s</p>
                        </div>
                    </div>
                    {/* <button className="btn btn-orange w-100" onClick={handleSaveUserActivity}>Check in</button>  */}
                    <div className='w-100'>
                        <button className='btn btn-orange mb-2 w-100' onClick={handleSaveUserActivity}>Check out</button>
                        {/* <p className='text fs-10 fst-italic blue-color d-flex align-items-center justify-content-center gap-1'> <i className='icon-chk-location'></i>Asghar Mall Road Scheme</p> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        {/* <Modal show={checkOut} onHide={handleCheckOut} backdrop="static" keyboard={false} centered dialogClassName="app-modal hk-check-modal">
            <Modal.Body>
                {isLoading ? <Loader /> : null}
                <div className='d-flex justify-content-end'>
                    <button className='btn-icon-transparent' onClick={handleCheckOut}><i className="icon-Icon-metro-cross"></i></button>
                </div>
                <div className="hk-check-content">
                    <div className='d-flex flex-column'>
                        <h3>Hi, {Token ? Token.Username : "John Peter"}</h3>
                        <p>Goodbye</p>

                        <span><i className="icon-Icon-ionic-ios-time"></i> Specify Checked in Time</span>

                        <p className="ps-3">{userActivityCheckOutTime}</p>
                        <label htmlFor="eventfield" className="ps-3">Choose event</label>
                        <input type="text"
                            className="form-control"
                            name="Message"
                            id="Message"
                            value={mainUserActivityModal.Message}
                            onChange={handleUserActivityMessage}
                        />
                    </div>
                    <img src={process.env.PUBLIC_URL + "/assets/images/CHECK-Out.svg"} alt='' />
                </div>
                <div className="hk-check-suggestions">
                    <h4 onClick={() => handleUserActivityMessage("Going to short leave")}>Going to short leave</h4>
                    <h4 onClick={() => handleUserActivityMessage("Leaving now")}>Leaving now</h4>
                    <h4 onClick={() => handleUserActivityMessage("Be Right back")}>Be Right back</h4>
                    <h4 onClick={() => handleUserActivityMessage("Going to short leave")}>Going to short leave</h4>
                    <h4 onClick={() => handleUserActivityMessage("Leaving now")}>Leaving now</h4>
                    <h4 onClick={() => handleUserActivityMessage("Be Right back")}>Be Right back</h4>
                    <h4 onClick={() => handleUserActivityMessage("Going to short leave")}>Going to short leave</h4>
                    <h4 onClick={() => handleUserActivityMessage("Leaving now")}>Leaving now</h4>
                    <h4 onClick={() => handleUserActivityMessage("Be Right back")}>Be Right back</h4>
                </div>
            </Modal.Body>
            <div className='btns-group text-end px-5 py-3 d-flex justify-content-end'>
                {checkStatus ?
                    <button className='btn btn-orange' onClick={handleSaveUserActivity}>Check out</button> :
                    <button className='btn btn-orange' disabled>Check out</button>}
            </div>
        </Modal> */}
    </>
    )
}

export default YourAttendance