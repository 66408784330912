import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtected } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleNumericCheck from "../../../../utils/HandleNumericKeys";
import format from "date-fns/format";
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import ScreenFooter from "../../../MenuLayout/ScreenFooter";
import CountriesStates from "../../../shared/CountriesStates/CountriesStates";
import DatePicker from '../../../../utils/DatePicker';

const AddNewOrganization = (props) => {
    let ID = useSelector(state => state.Organization)
    let Organization_Modal = {
        ProfilePicture: '',
        OrganizationName: '',
        OrganizationType: '',
        EstablishedDate: null,
        NTN: "",
        OfficialRegisteredCode: '',
        Address: '',
        Province: '',
        City: '',
        PostalCode: '',
        ContactPersonName: '',
        PhoneNumber: '',
        Email: '',
        Website: '',
        Nationality: '',
    }

    let valModal = {}
    let ValidationModal = {
        OrganizationName: "",
        OrganizationType: "",
        OrganizationEmail: "",
        OrganizationWebsite: "",
        NTNLength: "",
        NTN: '',
    }
    const [OrganizationValidationModal, setOrganizationValidationModal] = useState(ValidationModal)
    const [OrganizationID, setOrganizationID] = useState(null)
    const [organizationSaveUpdateModal, setOrganizationSaveUpdateModal] = useState({})
    const [mainOrganizationModal, setmainOrganizationModal] = useState(Organization_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [editImage, setEditImage] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const [isOpenOrganization, setIsOpenOrganization] = useState(false)
    const [organizationImageSaveUpdateModal, setOrganizationImageSaveUpdateModal] = useState({})
    const [profileUpdated, setProfileUpdated] = useState(false);
    const CloseOrganization = () => {
        setIsOpenOrganization(false);
    }

    const LoadOrganization = () => {
        if (!isNull(ID)) {
            setOrganizationID(ID)
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Organization
            }).then((response) => {
                setmainOrganizationModal(response);
                console.log("response", response)
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }

    //////Calendar////////////
    const [establishedDate, setEstablishedDate] = useState();
    const openEstablishedDate = Boolean(establishedDate)
    const handleCalendarChange = (date, type) => {
        var FormatedDateShow = format(date, "MM/dd/yyyy")
        var FormatedDateSave = format(date, DateFormat)
        if (type === 'EstablishedDate') {
            mainOrganizationModal['EstablishedDate'] = FormatedDateShow
            organizationSaveUpdateModal['EstablishedDate'] = FormatedDateSave
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setEstablishedDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'EstablishedDate') { setEstablishedDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ///////////////////////
    const handleOrganizationChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainOrganizationModal };
        let saveModal = { ...organizationSaveUpdateModal }
        modal[name] = value
        setmainOrganizationModal(modal)
        for (var property in Organization_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setOrganizationSaveUpdateModal(saveModal)
    }
    console.log("NTN", mainOrganizationModal)

    const OrganizationValidation = async () => {
        let ValidationModal = {
            OrganizationName: FormValidator(
                mainOrganizationModal.OrganizationName,
                enumUtil.enumValidationType.Required,
                'Organization Name',
            ),
            OrganizationType: FormValidator(
                mainOrganizationModal.OrganizationType,
                enumUtil.enumValidationType.Required,
                'Organization Type',
            ),
            OrganizationEmail: FormValidator(
                mainOrganizationModal.Email,
                enumUtil.enumValidationType.Email,
                'Email Address',
            ),
            OrganizationWebsite: FormValidator(
                mainOrganizationModal.Website,
                enumUtil.enumValidationType.Website,
                "Website URL"
            ),
            NTN: FormValidator(
                mainOrganizationModal.NTN,
                enumUtil.enumValidationType.Required,
                "NTN"
            ),
            NTNLength: FormValidator(
                mainOrganizationModal.NTN,
                enumUtil.enumValidationType.Length,
                'NTN',
                13,
            ),

        }
        await setOrganizationValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleOrganizationProfileChange = (e) => {
        const Type = e.target.files[0].type.substring(0, 5);
        if (Type === 'image') {
            let file = e.target.files[0]
            let reader = new FileReader();

            reader.onloadend = () => {
                let value = reader.result.toString();
                let { name } = e.target;
                // let modal = { ...mainOrganizationModal };
                let saveModal = { ...organizationImageSaveUpdateModal }
                // modal[name] = value
                // setmainOrganizationModal(modal)
                for (var property in Organization_Modal) {
                    if (name === property) {
                        saveModal[name] = value
                    }
                }
                setOrganizationImageSaveUpdateModal(saveModal)
                setProfileUpdated(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleOrganizationProfileDelete = (name) => {
        // let { name } = e.target;
        let modal = { ...mainOrganizationModal };
        let saveModal = { ...organizationImageSaveUpdateModal }
        modal[name] = ""
        console.log("MODALNAME", name, modal[name])
        setmainOrganizationModal(modal)
        saveModal[name] = ""
        setOrganizationImageSaveUpdateModal(saveModal)
        setProfileUpdated(true);
    }

    const handleSaveOrganizationPicture = () => {

        let saveModal = {
            TableName: enumUtil.enumAPITableName.Organization,
            ID: OrganizationID + "",
            // ParentID: OrganizationID + "",
            data: { ...organizationImageSaveUpdateModal },
            value: null
        }
        setIsLoading(true)
        ServerRequestProtected(API_URL + "/SaveEmployeeImage", 'post', saveModal).then((response) => {
            if (!isNull(response.id)) {
                setIsLoading(false)
                setProfileUpdated(false)
                NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                setProfileUpdated(false);
                LoadOrganization()
                // dispatch(EmployeeActiveTab(response.id))
                // dispatch(EmployeeNewEmployee(response.id))

            }
        }).catch((error) => {
            setIsLoading(false)
            setProfileUpdated(false)
            NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error)
        })
    }

    const handleSaveOrganization = async () => {
        await OrganizationValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Organization,
                ID: OrganizationID,
                data: { ...organizationSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setOrganizationID(response.id)
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log('error', error);
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            })
        }
    }
    let PopupScreen = "";
    if (isOpenOrganization) {
        PopupScreen = <AddNewOrganization isOpenOrganization={isOpenOrganization} onClose={CloseOrganization} />
    }

    useEffect(() => {
        LoadOrganization()
    }, [OrganizationID, ID])

    useEffect(() => {
        if (profileUpdated) {
            handleSaveOrganizationPicture();
        }
    }, [organizationImageSaveUpdateModal]);
    return (
        <React.Fragment>
            {isLoading ? <Loader /> : null}
            {PopupScreen}
            <div className="basic_info organization ar_organization">
                <div className="row m-0 ar_row">
                    <div className="col-md-3 pt-2">
                        <div className="left_side">
                            <div className="d-flex justify-content-center">
                                <div className="emp-profile-img position-relative mb-4 pl-4 d-flex flex-column align-items-center">
                                    <div className="img-container circle s-350 hk-imgHandler upload_image">
                                        {mainOrganizationModal.ProfilePicture ? (<img src={mainOrganizationModal.ProfilePicture} alt="" />
                                        ) : (<img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />)}
                                        <input type="file"
                                            id="ProfilePicture"
                                            name="ProfilePicture"
                                            alt="ProfilePicture"
                                            onChange={handleOrganizationProfileChange} />
                                    </div>
                                    <span className="edit_image" onClick={() => setEditImage(true)}>
                                        <i className="icon-edit-pencil"></i>
                                    </span>
                                    {editImage &&
                                        <div className="pic_edit p-2">
                                            <div className="d-flex justify-content-end cursor-pointer">
                                                <i className="icon-Icon-metro-cross" onClick={() => setEditImage(false)}></i>
                                            </div>
                                            <p className="heading text-center">Change Profile Picture</p>
                                            <div class="img-container mt-2 s-85 mx-auto hk-imgHandler">
                                                {mainOrganizationModal.ProfilePicture ? (<img src={mainOrganizationModal.ProfilePicture} alt="" />
                                                ) : (<img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />)}
                                            </div>
                                            <div className="text-center" >
                                                <button className="btn rounded-1 px-2 m-auto my-3 py-2 btn-blue hk-imgHandler img-container">
                                                    <input type="file"
                                                        id="ProfilePicture"
                                                        name="ProfilePicture"
                                                        alt="ProfilePicture"
                                                        onChange={handleOrganizationProfileChange} />
                                                    <i className="icon-Group-482270"></i> Upload Image</button>
                                            </div>
                                            <div className="d-flex align-items-baseline gap-1">
                                                <div className="bottom_border w-100"></div>
                                                <p className="gry_heading xsm">or</p>
                                                <div className="bottom_border w-100"></div>
                                            </div>
                                            <div
                                                className="heading mt-2 del_item text-center cursor-pointer"
                                                onClick={() => handleOrganizationProfileDelete("ProfilePicture")}>Delete Exisiting</div>
                                        </div>
                                    }
                                    {/* <div className="upload-emp-img mt-3 center-img">
                                        <label className='d-flex align-items-center gap-10'>
                                            <input type="file"
                                                id="ProfilePicture"
                                                name="ProfilePicture"
                                                onChange={handleOrganizationProfileChange} />
                                            <i className="bi bi-camera fs-20 lh-1"></i>
                                            Change profile photo</label>
                                    </div> */}
                                </div>
                            </div>
                            <div className={OrganizationValidationModal.OrganizationName ? "form-group input error" : "form-group input"}>
                                <label>Organization Name<span class="req">*</span></label>
                                <input type="text"
                                    className="form-control"
                                    name="OrganizationName"
                                    id="OrganizationName"
                                    maxLength={50}
                                    value={mainOrganizationModal.OrganizationName}
                                    onChange={handleOrganizationChange}
                                />
                                {OrganizationValidationModal.OrganizationName}
                            </div>
                            {/* dropdown from backend */}
                            <div className={OrganizationValidationModal.OrganizationType ? "form-group input error" : "form-group input"}>
                                <label>Organization Type<span class="req">*</span></label>
                                <select type="text"
                                    className="form-control"
                                    name="OrganizationType"
                                    id="OrganizationType"
                                    value={mainOrganizationModal.OrganizationType}
                                    onChange={handleOrganizationChange}
                                >
                                    <option value="">Select</option>
                                    <option value="Agriculture">Agriculture</option>
                                    <option value="Accounts and Banking">Accounts and Banking</option>
                                    <option value="Automobiles & Motorcycles">Automobiles & Motorcycles</option>
                                    <option value="Construction">Construction</option>
                                    <option value="Clothing and Accessories">Clothing and Accessories</option>
                                    <option value="Education">Education</option>
                                    <option value="Health & Medical">Health & Medical</option>
                                    <option value="Information Technology">Information Technology</option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="Manufacturing">Manufacturing</option>
                                    <option value="Oil/Gas Exploration">Oil/Gas Exploration</option>
                                    <option value="Software House">Software House</option>
                                    <option value="Security & Protection">Security & Protection</option>
                                    <option value="Others">Others</option>
                                </select>
                                {OrganizationValidationModal.OrganizationType}
                            </div>
                            <div className={'form-group input'}>
                                <DatePicker
                                    label='Established Date'
                                    maxDate={new Date()}
                                    value={mainOrganizationModal.EstablishedDate}
                                    openCalendarMenufunc={(event) => openCalendarMenu(event, 'EstablishedDate')}
                                    anchorEl={establishedDate}
                                    open={openEstablishedDate}
                                    closeCalendarMenufunc={closeCalendarMenu}
                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'EstablishedDate')}
                                    placeholder={true}
                                />
                            </div>
                            <div className={OrganizationValidationModal.NTN ? 'form-group input error' : OrganizationValidationModal.NTNLength ? "form-group input error" : 'form-group input'}>
                                <label>NTN<span className='req'>*</span></label>
                                <input type="text"
                                    className="form-control"
                                    maxLength={13}
                                    name="NTN"
                                    id="NTN"
                                    value={mainOrganizationModal.NTN}
                                    onChange={handleOrganizationChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                />
                                {OrganizationValidationModal.NTN}
                                {OrganizationValidationModal.NTNLength}
                            </div>
                            <div className='form-group input'>
                                <label>Official Registered Code</label>
                                <input type="text"
                                    className="form-control"
                                    name="OfficialRegisteredCode"
                                    id="OfficialRegisteredCode"
                                    maxLength={13}
                                    value={mainOrganizationModal.OfficialRegisteredCode}
                                    onChange={handleOrganizationChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 right_said p-0">
                        <div className="app-accordian">
                            <Accordion expanded={true}>
                                <AccordionSummary

                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <p className="heading">Address Information</p>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className='form-group input'>
                                                    <label>Address</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="Address"
                                                        id="Address"
                                                        maxLength={250}
                                                        value={mainOrganizationModal.Address}
                                                        onChange={handleOrganizationChange}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="row m-0">
                                                <CountriesStates mainEmployeeModal={mainOrganizationModal} handleEmployeeChange={handleOrganizationChange} columnLength={'col-md-4'} />
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group input'>
                                                    <label>Postal Code</label>
                                                    <input type="text"
                                                        maxLength={9}
                                                        className="form-control"
                                                        name="PostalCode"
                                                        id="PostalCode"
                                                        value={mainOrganizationModal.PostalCode}
                                                        onChange={handleOrganizationChange}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className='form-group input'>
                                                    <label>Province</label>
                                                    <select type="text"
                                                        className="form-control"
                                                        name="Province"
                                                        id="Province"
                                                        value={mainOrganizationModal.Province}
                                                        onChange={handleOrganizationChange}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Azad Jammu and Kashmir">Azad Jammu and Kashmir</option>
                                                        <option value="Balochistan">Balochistan</option>
                                                        <option value="Khyber Pakhunkhwa">Khyber Pakhunkhwa</option>
                                                        <option value="Punjab">Punjab</option>
                                                        <option value="Sindh">Sindh</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group input'>
                                                    <label>City</label>
                                                    <select type="text"
                                                        className="form-control"
                                                        name="City"
                                                        id="City"
                                                        value={mainOrganizationModal.City}
                                                        onChange={handleOrganizationChange}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Abbottabad">Abbottabad</option><option value="Ahmadpur">Ahmadpur</option><option value="Arifwala">Arifwala</option><option value="Attock">Attock</option>
                                                        <option value="Badin">Badin</option><option value="Bahawalnagar">Bahawalnagar</option><option value="Bahawalpur">Bahawalpur</option><option value="Bhakkar">Bhakkar</option>
                                                        <option value="Bolhari">Bolhari</option><option value="Burewala">Burewala</option><option value="Chakwal">Chakwal</option><option value="Chaman">Chaman</option>
                                                        <option value="Charsadda">Charsadda</option><option value="Chiniot">Chiniot</option><option value="Chishtian">Chishtian</option><option value="Dadu">Dadu</option>
                                                        <option value="Daska">Daska</option><option value="Dera Ghazi Khan">Dera Ghazi Khan</option><option value="Dera Ismail Khan">Dera Ismail Khan</option><option value="Faisalabad">Faisalabad</option>
                                                        <option value="Firozwala">Firozwala</option><option value="Ghotki">Ghotki</option><option value="Gojra">Gojra</option><option value="Gujranwala">Gujranwala</option>
                                                        <option value="Gujrat">Gujrat</option><option value="Hafizabad">Hafizabad</option><option value="Haroonabad">Haroonabad</option><option value="Hyderabad">Hyderabad</option>
                                                        <option value="Islamabad">Islamabad</option><option value="Jacobabad">Jacobabad</option><option value="Jhang">Jhang</option><option value="Kabal">Kabal</option>
                                                        <option value="Kamoki">Kamoki</option><option value="Karachi">Karachi</option><option value="Kasur">Kasur</option><option value="Khairpur">Khairpur</option>
                                                        <option value="Khanewal">Khanewal</option><option value="Khanpur">Khanpur</option><option value="Khushab">Khushab</option><option value="Khuzdar">Khuzdar</option>
                                                        <option value="Kohat">Kohat</option><option value="Kot Addu">Kot Addu</option><option value="Lahore">Lahore</option><option value="Larkana">Larkana</option>
                                                        <option value="Layyah">Layyah</option><option value="Lodhran">Lodhran</option><option value="Mandi Bahauddin">Mandi Bahauddin</option><option value="Mansehra">Mansehra</option>
                                                        <option value="Mardan">Mardan</option><option value="Mianwali">Mianwali</option><option value="Mirpur Khas">Mirpur Khas</option><option value="Multan">Multan</option>
                                                        <option value="Muridke">Muridke</option><option value="Murree">Murree</option><option value="Muzaffargarh">Muzaffargarh</option><option value="Narowal">Narowal</option>
                                                        <option value="Nawabshah">Nawabshah</option><option value="Nowshera">Nowshera</option><option value="Okara">Okara</option><option value="Pakpattan">Pakpattan</option>
                                                        <option value="Peshawar">Peshawar</option><option value="Quetta">Quetta</option><option value="Rahimyar Khan">Rahimyar Khan</option><option value="Rawalpindi">Rawalpindi</option>
                                                        <option value="Sadiqabad">Sadiqabad</option><option value="Sahiwal">Sahiwal</option><option value="Sargodha">Sargodha</option><option value="Shahdadkot">Shahdadkot</option>
                                                        <option value="Sheikhupura">Sheikhupura</option><option value="Sialkot">Sialkot</option><option value="Sukkur">Sukkur</option><option value="Swabi">Swabi</option>
                                                        <option value="Tando Adam">Tando Adam</option><option value="Tando Muhammad Khan">Tando Muhammad Khan</option><option value="Taxila">Taxila</option><option value="Turbat">Turbat</option>
                                                        <option value="Umerkot">Umerkot</option><option value="Vehari">Vehari</option><option value="Wah Cantonment">Wah Cantonment</option><option value="Wazirabad">Wazirabad</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            */}
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={true}>
                                <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <p className="heading">Contact Information</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='row row-gap-12'>
                                        <div className="col-md-4">
                                            <div className='form-group input'>
                                                <label htmlFor="">Person Name</label>
                                                <input type="text"
                                                    className="form-control"
                                                    name="ContactPersonName"
                                                    id="ContactPersonName"
                                                    maxLength={50}
                                                    value={mainOrganizationModal.ContactPersonName}
                                                    onChange={handleOrganizationChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className='form-group input'>
                                                <label htmlFor="">Contact Number</label>
                                                <input type="text"
                                                    className="form-control"
                                                    maxLength={13}
                                                    name="PhoneNumber"
                                                    id="PhoneNumber"
                                                    value={mainOrganizationModal.PhoneNumber}
                                                    onChange={handleOrganizationChange}
                                                    onKeyPress={(event) => handleNumericCheck(event)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={OrganizationValidationModal.OrganizationEmail ? 'form-group input error' : "form-group input"}>
                                                <label>Email Address</label>
                                                <input type="text"
                                                    maxLength={50}
                                                    className="form-control"
                                                    name="Email"
                                                    id="Email"
                                                    value={mainOrganizationModal.Email}
                                                    onChange={handleOrganizationChange}
                                                />
                                                {OrganizationValidationModal.OrganizationEmail}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className={OrganizationValidationModal.OrganizationWebsite ? 'form-group input error' : "form-group input"}>
                                                <label>Website</label>
                                                <input type="text"
                                                    className="form-control"
                                                    name="Website"
                                                    id="Website"
                                                    maxLength={256}
                                                    value={mainOrganizationModal.Website}
                                                    onChange={handleOrganizationChange}
                                                />
                                                {OrganizationValidationModal.OrganizationWebsite}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        {/* <div className='tabs-action-bottom-admin '>
                            <div className='btns-group text-end px-3  d-flex align-items-center justify-content-end h-100'>
                                <button className='btn btn-orange' onClick={handleSaveOrganization}>Save</button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="ar_screen-footer">
            <ScreenFooter isSaved={""} handleSave={handleSaveOrganization} />
            </div>
        </React.Fragment>
    )
}

export default AddNewOrganization