import React from 'react'

const LetterProfilePicture = React.memo(({ classes, name }) => {
    let capsName = '';
    capsName = name?.split(/\s+/)?.map(item => item[0]?.toUpperCase())?.slice(0,2)?.join('');
    return (
      <p className={classes}>
        {capsName}
      </p>
    );
})

export default LetterProfilePicture