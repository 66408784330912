import React from 'react'

const AddPolicy = (props) => {
    const { Leave_Policy_Modal, PolicyValidationModal, mainPolicyModal, setMainPolicyModal, savePolicyModal, setSavePolicyModal } = { ...props }

    const handleOnChange = (e, type) => {
        const { name, value, checked, type: inputType } = e.target;
        let modal = { ...mainPolicyModal }
        let saveModal = { ...savePolicyModal }
        if (name === "Admin" || name === "TeamLead" || name === "HR") {
            modal["CanApprove"][name] = checked;
        } else if (inputType === 'radio') {
            if (type === "LeaveApproval") {
                modal['canApply'] = name === "canApply";
                modal['canNotApply'] = name === "canNotApply";
            }
            if (type === "LeaveBeyond") {
                modal['combo1'] = name === "combo1";
                modal['combo2'] = name === "combo2";
            }

        } else {
            modal[name] = value;
        }

        setMainPolicyModal(modal);
        for (let property in Leave_Policy_Modal) {
            if (property === name) {
                if (name === "Admin" || name === "TeamLead" || name === "HR") {
                    saveModal["CanApprove"][property] = checked;
                } else {
                    saveModal[property] = value;
                }

            }
        }
        setSavePolicyModal(saveModal);
    }

    return (
        <div>
            <div className="d-flex flex-column gap-15">
                <div className={`${PolicyValidationModal?.PolicyName ? "error" : ""} form-group input`}>
                    <label>Policy Name<span className="req">*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        name="PolicyName"
                        id="PolicyName"
                        maxLength={250}
                        onChange={handleOnChange}
                    ></input>
                    {PolicyValidationModal?.PolicyName}
                </div>
                <div className={`${PolicyValidationModal?.PolicyPeriod ? "error" : ""} form-group input`}>
                    <label>Policy Period<span className="req">*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        name="PolicyPeriod"
                        id="PolicyPeriod"
                        maxLength={250}
                        onChange={handleOnChange}
                    ></input>
                    {PolicyValidationModal?.PolicyPeriod}
                </div>
                <p className="heading">Sub Types</p>
                <div className="row m-0">
                    <div className="col-md-6 ps-0">
                        <p className="text ar_right-input d-flex align-items-center">
                            Full Day
                        </p>
                    </div>
                    <div className="col-md-6 px-0">
                        <div className="form-group input">
                            <input
                                type="text"
                                className="form-control"
                                name="FullDayAllowed"
                                id="FullDayAllowed"
                                maxLength={250}
                                onChange={handleOnChange}
                                placeholder="Allowed Days"
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-md-6 ps-0">
                        <p className="text ar_right-input d-flex align-items-center">
                            Half Day
                        </p>
                    </div>
                    <div className="col-md-6 px-0">
                        {/* <div className="row m-0"> */}
                        <div className="col-md-12 ps-0">
                            <div className="form-group input">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="HalfDayAllowed"
                                    id="HalfDayAllowed"
                                    maxLength={250}
                                    onChange={handleOnChange}
                                    placeholder="Allowed Days"
                                ></input>
                            </div>
                            {/* </div> */}
                            {/* <div className="col-md-4 px-0">
                                <div className="form-group input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="ShortLeaves"
                                        id="ShortLeaves"
                                        maxLength={250}
                                        onChange={handleOnChange}
                                        placeholder="Qty"
                                    ></input>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-md-6 ps-0">
                        <p className="text ar_right-input d-flex align-items-center">
                            Short Leaves
                        </p>
                    </div>
                    <div className="col-md-6 px-0">
                        <div className="row m-0">
                            <div className="col-md-8 ps-0">
                                <div className="form-group input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="ShortLeavesAllowed"
                                        id="ShortLeavesAllowed"
                                        maxLength={250}
                                        onChange={handleOnChange}
                                        placeholder="Allowed Days"
                                    ></input>
                                </div>
                            </div>
                            <div className="col-md-4 px-0">
                                <div className="form-group input">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="ShortLeaveQty"
                                        id="ShortLeaveQty"
                                        maxLength={250}
                                        value={mainPolicyModal?.ShortLeaveQty}
                                        onChange={handleOnChange}
                                        placeholder="Qty"
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0">
                    <div className="col-md-7 ps-0">
                        <div className="d-flex  align-items-center gap-15">
                            <p className="heading">Effective After</p>
                            <div className={`form-group input ps-0`}>
                                <select
                                    className="form-control fc-xs"
                                    name="EffectiveAfter"
                                    id="EffectiveAfter"
                                    aria-label="Default select example"
                                    value={mainPolicyModal?.EffectiveAfter}
                                    onChange={handleOnChange}
                                >
                                    <option > 1 Month(s)</option>
                                    <option selected> 3 Month(s)</option>
                                    <option > 1 Year(s)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 px-0 ">
                        <div className="d-flex gap-2 align-items-center justify-content-end">
                            <p className="text clr_gry">From</p>
                            <div className={`form-group input ps-0`}>
                                <select
                                    className="form-control fc-xs"
                                    name="EffectiveFrom"
                                    id="EffectiveFrom"
                                    aria-label="Default select example"
                                    value={mainPolicyModal?.EffectiveFrom}
                                    onChange={handleOnChange}
                                >
                                    <option selected> Date of Joining</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${PolicyValidationModal?.LeaveApproval ? 'error' : ''}`}>
                    <p className={`  heading fs-11 mb-2 `}>
                        Who can approve leaves to employees:
                    </p>
                    <div className="d-flex align-items-center gap-10">
                        <div className="d-flex gap-1 align-items-center">
                            <div className="hk-table-input custom_check_box d-flex align-items-center">
                                <input type="checkbox" id={'Admin'} name={'Admin'} className="mt-0" checked={mainPolicyModal?.CanApprove?.Admin} onChange={(e) => handleOnChange(e, 'checkbox')} />
                            </div>
                            <p className="text clr_gry">Admin</p>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <div className="hk-table-input custom_check_box d-flex align-items-center">
                                <input type="checkbox" id={'TeamLead'} name={'TeamLead'} checked={mainPolicyModal?.CanApprove?.TeamLead} onChange={(e) => handleOnChange(e, 'checkbox')} className="mt-0" />
                            </div>
                            <p className="text clr_gry">Team Lead</p>
                        </div>
                        <div className="d-flex gap-1 align-items-center">
                            <div className="hk-table-input custom_check_box d-flex align-items-center">
                                <input type="checkbox" id={'HR'} name={'HR'} checked={mainPolicyModal?.CanApprove?.HR} onChange={(e) => handleOnChange(e, 'checkbox')} className="mt-0" />
                            </div>
                            <p className="text clr_gry" >HR</p>
                        </div>
                    </div>
                    {PolicyValidationModal?.LeaveApproval}
                </div>
                <div className="">
                    <p className="heading fs-11 mb-2">
                        Employees can apply beyond the current leave balance:
                    </p>
                    <div className="d-flex align-items-center gap-10">
                        <div className="ar_radio-btn d-flex gap-1 align-items-center">
                            <input type="radio" id='canApply' name='canApply' checked={mainPolicyModal?.canApply} onChange={(e) => handleOnChange(e, 'LeaveApproval')} />
                            <p className="text clr_gry">Yes</p>
                        </div>
                        <div className="ar_radio-btn d-flex gap-1 align-items-center">
                            <input type="radio" id='canNotApply' name='canNotApply' checked={mainPolicyModal?.canNotApply} onChange={(e) => handleOnChange(e, 'LeaveApproval')} />
                            <p className="text clr_gry">No</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column gap-1">
                    <p className="heading">Allowed Leaves Combination</p>
                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                        <input type="radio" id='combo1' name='combo1' checked={mainPolicyModal?.combo1} onChange={(e) => handleOnChange(e, 'LeaveBeyond')} />
                        <p className="text clr_gry">
                            1 Full Day OR 2 Half Days OR 4 Short Leaves per Month
                        </p>
                    </div>
                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                        <input type="radio" id='combo2' name='combo2' checked={mainPolicyModal?.combo2} onChange={(e) => handleOnChange(e, 'LeaveBeyond')} />
                        <p className="text clr_gry">
                            1 Half Day 2 Short Leaves OR per Month
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPolicy
