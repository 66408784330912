import React, { useEffect, useMemo, useState } from "react";

const ModalFooter = (props) => {
    const { isSaved, handleSave, type, RequestStatus } = { ...props };
    return (
        <div className='btns-group modal_footer br-top-1 clr_light d-flex justify-content-end gap-3'>
            <div className="btns-group d-flex justify-content-center align-items-center">
                {console.log("REQUESTSTATUS",RequestStatus,RequestStatus ? RequestStatus !== 'Pending' ? 'disabled' : 'EMPTYDISABLE' : 'EMPTY')}
                <button className={`btn btn-gray ${RequestStatus ? RequestStatus !== 'Pending' ? 'disabled' : '' : ''}`} onClick={()=>isSaved()}>
                    Cancel
                </button>

                <button className={`btn btn-blue ${RequestStatus ? RequestStatus !== 'Pending' ? 'disabled' : '' : ''}`} onClick={()=>handleSave()}>
                    Save
                </button>
            </div>
        </div>
    )
}

export default ModalFooter
