import SwitchOrganization from "../Account/SwitchOrganization/SwitchOrganization"
import RolesAndRights from "../Account/RolesAndRights/RolesAndRights"
import Miscellaneous from "../Account/Miscellaneous/Miscellaneous"

const SettingMenu = () => {
    return [
        {
            name: 'Setting',
            key: 91,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Switch Organization',
                    key: 911,
                    icon: "",
                    component: <SwitchOrganization />,
                    right: true,
                },
                {
                    name: 'Roles and Rights',
                    key: 912,
                    icon: "",
                    component: <RolesAndRights />,
                    right: true,
                },
                {
                    name: 'Miscellaneous',
                    key: 913,
                    icon: "",
                    component: <Miscellaneous />,
                    right: true,
                },
            ]
        },
    ]
}


export default SettingMenu