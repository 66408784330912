import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import close from "../../../../assetsOld/img/x-close.svg"
import Modal from 'react-bootstrap/Modal';
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"
import { Autocomplete, TextField } from "@mui/material";

const AddNewTeam = (props) => {

    let Team_Modal = {
        TeamName: "",
        TeamLeadIDs: "",
        Details: ""
    }

    let valModal = {}
    let ValidationModal = {
        Team_Modal: "",
    }
    const [newTeamSaved, setNewTeamSaved] = useState(false);
    const [TeamValidationModal, setTeamValidationModal] = useState(ValidationModal)
    const [TeamID, setTeamID] = useState(0)
    const [TeamSaveUpdateModal, setTeamSaveUpdateModal] = useState({})
    const [mainTeamModal, setmainTeamModal] = useState(Team_Modal);
    const [addreportingTotDropDown, setAddReportingToDropDown] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenTeam, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [del, setDel] = useState(false)
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [isOpenTeamModal, setIsOpenTeamModal] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [ReportingToValue, setReportingToValue] = useState([])

    useEffect(() => {
        setIsLoading(false)
        if (props.TeamID != null) {
            setTeamID(props.TeamID)
            setDel(true)
        } else {
            if (!newTeamSaved) {
                setTeamID(0)
                setDel(false)
            }
            else { setDel(true) }
        }

        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Employee,
        }).then((response) => {
            setIsLoading(false)
            setAddReportingToDropDown(response)
        }).catch((error) => {

            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })

        if (!isNull(TeamID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": TeamID,
                "TableName": enumUtil.enumAPITableName.Team

            }).then((response) => {
                setIsLoading(false)
                setmainTeamModal(response)
                if (response?.TeamLeadIDs) { setReportingToValue(JSON.parse(response?.TeamLeadIDs)) }
            }).catch((error) => {
                NotificationHandler(error.message)
                setIsLoading(false)
            })
        }
    }, [TeamID])

    const handleTeamChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainTeamModal };
        let saveModal = { ...TeamSaveUpdateModal }
        modal[name] = value
        setmainTeamModal(modal)
        for (var property in Team_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setTeamSaveUpdateModal(saveModal)
    }
    const handleMultiSelectChange = (values, type, reason) => {
        let arr = [];
        let arrVal = [];
        values.map((key, index) => {
            arr.push(key)
        })
        values.map((key, index) => {
            arrVal.push(key.EmployeeID)
        })
        setReportingToValue(arr);
        mainTeamModal['TeamLeadIDs'] = arr.length == 0 ? "" : arr
        TeamSaveUpdateModal['TeamLeadIDs'] = arrVal.toString()
    }
    const TeamValidation = async () => {
        let ValidationModal = {
            TeamName: FormValidator(
                mainTeamModal.TeamName,
                enumUtil.enumValidationType.Required,
                'Team Name',
            )
        }
        await setTeamValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveTeam = async () => {
        await TeamValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Team,
                ID: TeamID,
                data: { ...TeamSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewTeamSaved(true)
                    setTeamID(response.id)
                    setIsRefresh(true);
                    onCloseSave();
                    
                }
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error);
            })
        }
    }
    // const handleTeamDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": TeamID,
    //         "TableName": enumUtil.enumAPITableName.Team
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Team Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }
    const SetTeamName = () => {
        if (mainTeamModal.TeamName != null && mainTeamModal.TeamName != "") {
            return (
                <p className="validation_message heading">{mainTeamModal.TeamName}</p>
            )
        } else {
            return (
                <p className="heading">Add New Team</p>
            )
        }
    }
    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup = ""
    if (del) {
        DeletePopup = <button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Team} ID={TeamID} onClose={onClose} />
    }
    //////////
    return (
        <>
            {ConfirmDelete}
            <Modal show={isOpenTeam} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal add_new_team ems_modal">
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close} onClick={() => onClose(newTeamSaved)}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="">
                                    <i className="icon-Group-482483 fs-20"></i>
                                </div>
                            </span>
                            <p class="heading mt-2"> {SetTeamName()}</p>
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}
                    <div>
                        <div className={TeamValidationModal.TeamName ? "form-group input error" : "form-group input"}>
                            <label>Team Name<span className='req'>*</span></label>
                            <input type="text"
                                className="form-control"
                                name="TeamName"
                                id="TeamName"
                                maxLength={35}
                                value={mainTeamModal.TeamName}
                                onChange={handleTeamChange}
                            />
                            {TeamValidationModal.TeamName}
                        </div>
                        <div className="form-group input">
                            <label>Team Lead</label>
                            {/* <select className="form-control"
                                name="ReportingTo"
                                id="ReportingTo"
                                aria-label="Default select example"
                                value={mainTeamModal?.ReportingTo}
                                onChange={handleTeamChange}
                            >
                                <option value="">Select</option>
                                <option value="Haris">Haris</option>
                                                        <option value="Taimoor">Taimoor</option>
                                                        <option value="Dawood">Dawood</option>
                                {addreportingTotDropDown.map((e, index) => (
                                    <option value={e.EmployeeID} key={index}>
                                        {e.Name}
                                    </option>
                                ))}
                            </select> */}
                            <Autocomplete
                                multiple
                                limitTags={2}
                                name="TeamLeadIDs"
                                id="TeamLeadIDs"
                                value={ReportingToValue}
                                options={addreportingTotDropDown}
                                getOptionLabel={(option) => option.Name}
                                isOptionEqualToValue={(option, value) => option.EmployeeID === value.EmployeeID}
                                // defaultValue={[top100Films[13]]}
                                noOptionsText={"Not Available"}
                                onChange={(event, value, reason) => handleMultiSelectChange(value, 'TeamLeadIDs', reason)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder=""
                                    />
                                )}
                            />
                            {/* <label>Teams</label> */}
                            {/* <Autocomplete
                                multiple
                                limitTags={1}
                                id='TeamIDs'
                                name='TeamIDs'
                                value={ReportingToValue}
                                options={addreportingTotDropDown}
                                isOptionEqualToValue={(option, value) => option.EmployeeID === value.EmployeeID}
                                onChange={(event, value, reason) => handleMultiSelectChange(value, 'ReportingTo', reason)}
                                getOptionLabel={(option) => option.Name}
                                noOptionsText={"Not Available"}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder=""
                                    />
                                )}
                            /> */}

                        </div>
                        <div className="form-group input">
                            <label>Description of Team</label>
                            <textarea rows="4"
                                className="form-control"
                                name="Details"
                                id="Details"
                                maxLength={100}
                                value={mainTeamModal.Details}
                                onChange={handleTeamChange}></textarea>
                        </div>
                    </div>
                    {/* <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>
                            <div className="img-container circle s-30">
                                <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                            </div>

                        </div>
                        <div className="cursor-pointer" onClick={() => { setIsOpenTeamModal(!isOpenTeamModal); }}>
                            <p className="text font-m link-text">+ 3</p>
                            <p className="text font-m link-text">more</p>
                        </div>
                    </div> */}
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveTeam} />
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                            <button className='btn btn-gray' onClick={() => onClose(newTeamSaved)}>Cancel</button>
                            <button className='btn btn-orange' onClick={handleSaveTeam}>Save</button>
                        </div> */}
            </Modal>
            <Modal show={isOpenTeamModal} onHide={isOpenTeamModal} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal ar_team-popup">
                <Modal.Body>
                    <div className='text-end mb-3 px-10'>
                        <i className='icon-Vector7 fs-12' onClick={() => setIsOpenTeamModal(false)}></i>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-2 px-10'>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className='fs-11 text clr_gry'>Team:</p>
                            <p className='heading'>Frontend Designing</p>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className='fs-11 text clr_gry'>Team Lead:</p>
                            <p className='heading'>Taimoor Azhar</p>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className='fs-11 text clr_gry'>Members:</p>
                            <p className='heading'>8</p>
                        </div>
                    </div>
                    <div className='d-flex flex-wrap align-items-center gap-1 row-gap-10 px-10 scroll'>
                        <div className='hired-box '>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-column align-items-center px-2'>
                                    <div className="img-container circle border-2  s-60">
                                        <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                                    </div>
                                    <p className='heading'>ISRAH ABBASI</p>
                                    <p className='text fs-11 font-m clr_light' title="">UI/UX Designer</p>
                                </div>
                                <p className="icon mb-0 position-relative">
                                    <i className="icon-menuicon cursor-pointer " onClick={() => setShowOptions(!showOptions)}></i>
                                    {showOptions &&
                                        <div className="actions_popup cursor-pointer w-fit-content">
                                            <p className="text  p-2 text-nowrap d-flex align-items-center"><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                                            <p className="text  p-2 text-nowrap d-flex align-items-center"><i className="icon-icon-trash me-1 fs-10"></i>Remove from Team</p>
                                        </div>
                                    }
                                </p>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default AddNewTeam