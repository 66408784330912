import AcademicsTopMenu from "./AcademicsLayout/AcademicsTopMenu"
const Academics = (props) => {
    const { type } = {...props}
    return ( 
        <div>
            <AcademicsTopMenu type={type}/>
        </div>
     );
}
 
export default Academics;