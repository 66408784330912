import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import close from "../../../assetsOld/img/x-close.svg"
import ModalFooter from '../../shared/ModalFooter/ModalFooter'
import isNull from '../../../utils/NullChecking'
import FormValidator from '../../../utils/FormValidator'
import jwt_decode from 'jwt-decode';
import NotificationHandler from '../../../utils/NotificationHandler'
import { ServerRequestProtectedParentID } from '../../../utils/ServerDataRequest'
import { useSelector } from 'react-redux'
import Loader from '../../shared/Loader/Loader'
import { enumUtil } from '../../../utils/Enum'
import CalendarInput from '../../../utils/CalendarInput'
import { DateFormat, DateFormat_dMy, DateFormat_YMd_Strings } from "../../../utils/Constants"
import format from "date-fns/format";
import ModalFooterAdmin from '../../shared/ModalFooterAdmin/ModalFooterAdmin'
import ApproveConfirmation from '../../shared/Approve/ApproveConfirmation'
import DenyConfirmation from '../../shared/Deny/DenyConfirmation'
import DatePicker from '../../../utils/DatePicker';

const AddTimeAdjustment = (props) => {
    const { isRequestOpen, onClose, onCloseSave, ID, setIsRefresh, view, Active, Status, LoadOnSave } = { ...props }

    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    // let EmployeeID = Token.UserID;
    let EmployeeID = Active ? Active : "";


    const TimeAdjustment_Modal = {
        CheckInTime: "",
        CheckOutTime: "",
        RequestedDate: null,
        Date: null,
        ApprovedDate: null,
        ApprovedBy: "",
        Message: "",
    }

    let TIMEADJUSTMENTVALIDATION_MODAL = {
        CheckInTime: "",
        CheckOutTime: "",
        Date: "",
        Message: "",
    }

    let valModal = {}

    const API_URL = process.env.REACT_APP_API;
    const [timeAdjustmentID, setTimeAdjustmentID] = useState(0);
    const [mainTimeAdjustmentModal, setMainTimeAdjustmentModal] = useState(TimeAdjustment_Modal);
    const [saveTimeAdjustmentModal, setSaveTimeAdjustmentModal] = useState({});
    const [TimeAdjustmentValidationModal, setTimeAdjustmentValidationModal] = useState(TIMEADJUSTMENTVALIDATION_MODAL);
    const [isLoading, setIsLoading] = useState(false);
    const [position, setPosition] = useState(null);
    const [approveID, setApproveID] = useState(null);
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [denyID, setDenyID] = useState(null);
    const [isOpenDeny, setIsOpenDeny] = useState(false)
    const [LeaveStatus, setLeaveStatus] = useState({});
    const [RequestStatus, setRequestStatus] = useState('Pending');
    const [request, setRequest] = useState()

    const handleOnChange = (e) => {
        const { value, name } = e.target;
        const modal = { ...mainTimeAdjustmentModal };
        const saveModal = { ...saveTimeAdjustmentModal };
        modal[name] = value;
        setMainTimeAdjustmentModal(modal);
        for (let property in TimeAdjustment_Modal) {
            if (name === property) {
                saveModal[name] = value;
            }
        }
        setSaveTimeAdjustmentModal(saveModal);
    }

    const timeAdjustmentValidation = () => {
        let validationModal = {
            // CheckInTime: FormValidator(
            //     mainTimeAdjustmentModal.CheckInTime,
            //     enumUtil.enumValidationType.Required,
            //     "CheckIn Time"
            // ),
            CheckInTime: FormValidator(
                mainTimeAdjustmentModal.CheckInTime,
                enumUtil.enumValidationType.Required,
                "CheckIn Time"
            ),
            CheckOutTime: FormValidator(
                mainTimeAdjustmentModal.CheckOutTime,
                enumUtil.enumValidationType.Required,
                "CheckOut Time"
            ),
            RequestedDate: FormValidator(
                mainTimeAdjustmentModal.Date,
                enumUtil.enumValidationType.Required,
                "Date"
            ),
            // ApprovedDate:FormValidator(
            //     mainTimeAdjustmentModal.RequestDate,
            //     enumUtil.enumValidationType.Required,
            //     "Approved Date"
            // ),
            // ApprovedBy:FormValidator(
            //     mainTimeAdjustmentModal.ApprovedBy,
            //     enumUtil.enumValidationType.Required,
            //     "Approved By"
            // ),
            // Message: FormValidator(
            //     mainTimeAdjustmentModal.Message,
            //     enumUtil.enumValidationType.Required,
            //     "Reason"
            // ),
        }
        setTimeAdjustmentValidationModal(validationModal);
        valModal = validationModal;
    }

    const handleCalendarChange = (date, type) => {

        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, "MM/dd/yyyy")
        let modal = { ...mainTimeAdjustmentModal };
        let saveModal = { ...saveTimeAdjustmentModal }
        if (type === "Date") {
            modal["RequestedDate"] = FormatedDateShow
        }
        modal[type] = FormatedDateShow
        setMainTimeAdjustmentModal(modal)
        for (var property in TimeAdjustment_Modal) {
            if (type === property) {
                if (type === "Date") {
                    saveModal["RequestedDate"] = FormatedDateSave
                }
                saveModal[type] = FormatedDateSave
            }
        }
        setSaveTimeAdjustmentModal(saveModal)
        closeDateRangePickerMenu()
    }

    const openDateRangePicker = Boolean(position);
    const openDateRangePickerMenu = (event) => {
        setPosition(event.currentTarget);
    };

    const closeDateRangePickerMenu = () => {
        setPosition(null);
    };

    const handleCheckoutTimeRequest = async () => {

        await timeAdjustmentValidation();
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler("Fill all Required Fields", enumUtil.enumtoaster.error)
            return
        }
        else {
            setIsLoading(true)
            saveTimeAdjustmentModal["EmployeeID"] = EmployeeID + "";
            let saveModal = {
                "TableName": enumUtil.enumAPITableName.EmployeeTimeAdjustment,
                "ID": timeAdjustmentID,
                "Data": { ...saveTimeAdjustmentModal },
            }
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", "post", saveModal).then((response) => {
                // setIsRefresh(true);
                setIsLoading(false)
                NotificationHandler("Record Saved Successfully", enumUtil.enumtoaster.success);
                onCloseSave()
            }).catch((error) => {
                setIsLoading(false)
                // setIsRefresh(false);
                NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
            })
        }
    }

    const handleApproveModal = (ID, item) => {

        // setApproveID(ID);
        setIsOpenApprove(true);
        setLeaveStatus({...LeaveStatus, "RequestStatus": "Approved"})

    }

    const onCloseApprove = () => {
        setApproveID(0);
        setIsRefresh(true)
        setRequest("Approved")
        setIsOpenApprove(false)
        onCloseSave()
    }

    const onApprovalClose = () => {
        setIsOpenApprove(false)
    }

    let ApprovePopup = "";
    if (isOpenApprove) {
        ApprovePopup = <ApproveConfirmation isOpenAlert={isOpenApprove} onCloseDelete={onCloseApprove} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeTimeAdjustment} ID={approveID} LeaveStatus={LeaveStatus} LoadData={() => onCloseSave()} onClose={onApprovalClose} />
    }

    const handleDenyModal = (ID, item) => {
        // setDenyID(ID);
        setIsOpenDeny(true);
        setLeaveStatus({...LeaveStatus, "RequestStatus": "Dennied"})
        
    }

    const onCloseDeny = () => {
        setDenyID(0);
        setIsRefresh(true)
        setRequest("Denied")
        setIsOpenDeny(false)
        onCloseSave()
    }

    const onDenyClose = () => {
        setIsOpenDeny(false)
    }

    let DenyPopup = "";
    if (isOpenDeny) {
        DenyPopup = <DenyConfirmation isOpenAlert={isOpenDeny} onCloseDelete={onCloseDeny} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeTimeAdjustment} ID={denyID} LeaveStatus={LeaveStatus} onClose={onDenyClose} LoadData={() => onCloseSave()}/>
    }

    useEffect(() => {
        if (!isNull(ID)) {
            setTimeAdjustmentID(ID)
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.EmployeeTimeAdjustment,
            }).then((response) => {
                setIsLoading(false)
                let formatedRequestDate = DateFormat_YMd_Strings(response?.RequestedDate);
                let formatedDate = DateFormat_YMd_Strings(response?.Date);
                let saveResponse = {...response}
                saveResponse.RequestedDate = formatedRequestDate
                saveResponse.Date = formatedDate
                setMainTimeAdjustmentModal(response)
                setSaveTimeAdjustmentModal(saveResponse)
                setRequestStatus(response?.CheckOutRequestStatus)
                setApproveID(response?.CheckOutRequestID);
                setDenyID(response?.CheckOutRequestID);
                // let formatedDate = DateFormat_YMd_Strings(response?.RequestedDate)
                setLeaveStatus(
                    {
                        "AttandanceID": response?.AttandanceID + "",
                        "CheckOutRequestID": response?.CheckOutRequestID + "",
                        "CheckInRequestID": response?.CheckInRequestID + "",
                        "Message": response?.Message,
                        "RequestStatus": "Approved",
                        "RequestedDate": formatedRequestDate,
                        "CheckInTime": response?.CheckInTime,
                        "CheckOutTime": response?.CheckOutTime,
                        // "RequestStatus": "Approved",
                        "EmployeeID": EmployeeID + "",
                    }
                );
                // setPracticeID(response.PracticeID)
            }).catch((error) => {
                // NotificationHandler(error?.message)
                setIsLoading(false)
            })
        }
    }, [ID, request])

    return (
        <div>
            <Modal show={isRequestOpen} onHide={isRequestOpen} backdrop="static" keyboard={false} centered dialogClassName={`app-modal adjust_time ems_modal ${Status && Status === 'Pending' ?  '' : 'ar_disable-popup'}`}>
                <Modal.Body>
                    {ApprovePopup}
                    {DenyPopup}
                    <div className='d-flex justify-content-end'>
                        {isLoading ? <Loader /> : null}
                        <img className="close" src={close} onClick={() => onClose()}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Vector-4"></i>
                            </span>
                            {view && view === "Admin" ?
                                <p class="heading mt-2">Request Confirmation</p>
                                :
                                <p class="heading mt-2">Request Time Adjustment</p>
                            }
                        </div>
                    </div>
                    <div className='mt-2'>
                        <div className={`form-group input ${TimeAdjustmentValidationModal?.RequestedDate && 'error'}`}>
                            <DatePicker
                                label={<label>Date<span className='req'>*</span></label>}
                                value={mainTimeAdjustmentModal.Date}
                                openCalendarMenufunc={(event) => openDateRangePickerMenu(event, 'RequestedDate')}
                                maxDate={new Date()}
                                anchorEl={position}
                                open={openDateRangePicker}
                                closeCalendarMenufunc={closeDateRangePickerMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'Date')}
                                placeholder={true}
                            />
                            {TimeAdjustmentValidationModal?.RequestedDate}
                        </div>
                        <div className={`form-group input ${TimeAdjustmentValidationModal?.CheckInTime && 'error'}`}>
                            <label>Start Time<span className='req'>*</span></label>
                            <input
                                type="time"
                                className="form-control"
                                // maxLength={5}
                                name="CheckInTime"
                                id="CheckInTime"
                                value={mainTimeAdjustmentModal.CheckInTime}
                                onChange={handleOnChange}
                            />
                            {TimeAdjustmentValidationModal?.CheckInTime}
                        </div>
                        <div className={`form-group input ${TimeAdjustmentValidationModal?.CheckOutTime && 'error'}`}>
                            <label>End Time<span className='req'>*</span></label>
                            <input
                                type="time"
                                className="form-control"
                                // maxLength={5}
                                name="CheckOutTime"
                                id="CheckOutTime"
                                value={mainTimeAdjustmentModal.CheckOutTime}
                                onChange={handleOnChange}
                            />
                            {TimeAdjustmentValidationModal?.CheckOutTime}
                        </div>
                        <div className="form-group input">
                            <label>Remarks</label>
                            <textarea
                                type="text"
                                rows="3"
                                className="form-control"
                                name="Message"
                                id="Message"
                                value={mainTimeAdjustmentModal.Message}
                                onChange={handleOnChange}
                            />
                        </div>
                    </div>

                </Modal.Body>
                {view && view === "Admin" ?
                    <ModalFooterAdmin isSaved={handleDenyModal} handleSave={handleApproveModal} RequestStatus={Status} />
                    :
                    <ModalFooter isSaved={onClose} handleSave={handleCheckoutTimeRequest} RequestStatus={Status}/>
                }
            </Modal>
        </div>
    )
}

// AddTimeAdjustment.defaultProps = {
//     Status: 'Pending', // Default value for the Status prop
//   };

export default AddTimeAdjustment
