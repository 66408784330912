import React, { useEffect, useReducer, useState } from "react";
import { MonthsDropdown } from "../../utils/Constants";
import ReactDataGrid from "react-data-grid";
import { ServerRequestProtected } from "../../utils/ServerDataRequest";
import { enumUtil } from "../../utils/Enum";
import Loader from "../shared/Loader/Loader";
import { Nav, Tab, Tabs } from "react-bootstrap";
import DeleteConfirmation from "../shared/Delete/DeleteConfirmation";
import AddNewLeave from "./AddNewLeave";
import CustomUseRef from "../shared/CustomUseRef/CustomUseRef";
import GridColumnHeader from "../shared/GridColumnHeader/GridColumnHeader";

const MyLeaveRequests = (props) => {
  const { type } = { ...props };
  const currentMonth = new Date().getMonth() + 1;

  let TimeAdjustment_Modal = {
    Month: currentMonth + "",
    // UserID: UserID + "",
  };

  const API_URL_DASHBOARD = process.env.REACT_APP_API_Dashboard;
  const [isLoading, setIsLoading] = useState(false);
  const [mainDropDownModal, setMainDropDownModal] =
    useState(TimeAdjustment_Modal);
  const [saveDropDownModal, setSaveDropDownModal] = useState({});
  const [AllRequestData, setAllRequestData] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);
  const [EmployeeLeaveID, setEmployeeLeaveID] = useState(0);
  const [actionsbutton, setActionsbutton] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [delID, setDelID] = useState(0);
  const API_URL = process.env.REACT_APP_API;
  const [gridHeader, handleGridHeader] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, { defaultData: '', activeSortIcon: '' })

  const columns = [
    // {
    //   key: "EmployeeID",
    //   name: "Employee ID",
    //   resizable: true,
    // flex: 1,
    // width: 120,
    // formatter: ({ row }) => {
    //     return (
    //         <button className='btn' onClick={""}>
    //             {row?.CategoryName}
    //         </button>
    //     )
    // }
    // },
    // {
    //   key: "EmployeeName",
    //   name: "Employee Name",
    //   resizable: true,
    //   width: 160,
    //   formatter: ({ row }) => {
    //     return (
    //       <div className="heading text-primary font-m validation_message cursor-pointer" title={row?.EmployeeName} onClick={() => AddLeave(row?.ID, row?.LeaveStatus)}>
    //         {row?.EmployeeName}
    //       </div>
    //     );
    //   },
    // },
    {
      key: "LeaveType",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Leave Type'} sortIcon={true} type={'string'} colKey={'LeaveType'} data={AllRequestData} setData={setAllRequestData} />),

      resizable: true,
      // flex: 1,
      width: 100,
      formatter: ({ row }) => {
        return (
          <div className="validation_message" title={row?.LeaveType} >
            {row?.LeaveType}
          </div>
        );
      },
    },
    {
      key: "DateTo",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Requested Date'} sortIcon={true} type={'date'} colKey={'DateTo'} data={AllRequestData} setData={setAllRequestData} />),
      width: 180,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <div className="heading text-primary font-m validation_message cursor-pointer" title={row?.DateFrom + ' - ' + row?.DateTo} onClick={() => AddLeave(row?.ID, row?.LeaveStatus)}>
            {row?.DateFrom + ' - ' + row?.DateTo}
          </div>
        );
      },
      // flex: 1,
    },
    {
      key: "Description",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Description'} sortIcon={true} type={'string'} colKey={'Description'} data={AllRequestData} setData={setAllRequestData} />),
      width: 300,
      resizable: true,
      // flex: 1,
      formatter: ({ row }) => {
        return (
          <div className="validation_message" title={row?.Description}>
            {row?.Description}
          </div>
        );
      },
    },
    // {
    //   key: "AppliedOn",
    //   name: "Applied On",
    //   resizable: true,
    //   flex: 1,
    // },
    {
      key: "ApprovedDate",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Approved Date'} sortIcon={true} type={'date'} colKey={'ApprovedDate'} data={AllRequestData} setData={setAllRequestData} />),
      width: 160,
      resizable: true,
      // flex: 1,
    },
    {
      key: "ApprovedBy",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Decision By'} sortIcon={true} type={'string'} colKey={'ApprovedBy'} data={AllRequestData} setData={setAllRequestData} />),
      width: 180,
      resizable: true,
      // flex: 1,
    },
    {
      key: "LeaveStatus",
      name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Status'} sortIcon={true} type={'string'} colKey={'LeaveStatus'} data={AllRequestData} setData={setAllRequestData} />),

      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return <div className={`${row?.LeaveStatus} mt-1`}>{row?.LeaveStatus}</div>;
      },
    },
    {
      key: "Actions",
      name: "Actions",
      flex: 1,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <>
            <div
              className="text-start"
              onClick={(e) => handeActionPopUp(e, row?.ID)}
            >
              <i className="icon-menuicon cursor-pointer"></i>
            </div>
            {selectedRow === row?.ID && (
              <CustomUseRef
                onClose={CloseActionPopup}
                IsActivePtPayments={actionsbutton}
              >
                <div className="actions_popup cursor-pointer">
                  {row?.RequestStatus === "Pending" ?
                    <>
                      <p className="text  p-2 py-0" onClick={() => AddLeave(row?.ID, row?.LeaveStatus)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                      <p className="text p-2 py-0" onClick={() => handleDeleteModal(row?.ID)}><i className="icon-Group-3994 me-1"></i>Delete</p>
                    </>
                    :
                    <>
                      <p className="text  p-2 py-0" onClick={() => AddLeave(row?.ID, row?.LeaveStatus)}><i className="icon-Group-482282 me-1"></i>View</p>
                    </>
                  }
                </div>
              </CustomUseRef>
            )}
          </>
        );
      },
    },
  ];

  const handeActionPopUp = (e, id) => {
    e.stopPropagation();
    setActionsbutton(true);
    setSelectedRow(id);
  };
  const CloseActionPopup = () => {
    setActionsbutton(false);
  };
  let handleMonthChange = (e) => {
    const { name, value, selectedIndex } = e.target;

    let modal = { ...mainDropDownModal };
    let saveModal = { ...saveDropDownModal };
    const selectedOptionText = e.target.options[selectedIndex].text;
    modal[name] = value;

    setMainDropDownModal(modal);
    for (var property in TimeAdjustment_Modal) {
      if (name === property) {
        saveModal[name] = value;
      }
    }
    setSaveDropDownModal(saveModal);
  };

  // const LoadData = () => {
  //     setAllRequestData([]);
  //     setIsLoading(true);
  //     let saveModal = {
  //         "TableName": enumUtil.enumAPITableName.EmployeeLeave,
  //         "SearchParam": "Request",
  //         "SearchCriteria": {
  //             "RequestBy": "Month",
  //             "Month": mainDropDownModal.Month,
  //             "RequestAccess": "User",
  //             "RequestlistFor": "Leave",
  //         }
  //     }
  //     ServerRequestProtected(API_URL_DASHBOARD + '/FindBySingleCriteria', "post", saveModal
  //     ).then((response) => {
  //         setIsLoading(false);
  //         setAllRequestData(response);
  //         // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
  //     }).catch((error) => {
  //         setIsLoading(false);
  //         // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
  //     })
  // }

  const loadEmployeeLeaves = () => {
    setAllRequestData([]);
    setIsLoading(true);
    ServerRequestProtected(API_URL + "/FindBySingleCriteria", "post", {
      TableName: enumUtil.enumAPITableName.EmployeeLeave,
      SearchCriteria: {
        // "UserID": UserID,
        // "ParentID": OrgID,
        Month: mainDropDownModal.Month,
        RequestAccess: "User",
      },
    })
      .then((response) => {
        setIsLoading(false);
        setAllRequestData(response);
        handleGridHeader({ defaultData: response })
        // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
      })
      .catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
      });
  };

  const AddLeave = (ID, Status) => {
    setIsLeaveOpen(true);
    setEmployeeLeaveID(ID);
    setStatus(Status);
    // console.log("PENDING",Status)
  };

  const ClosePopupScreen = () => {
    setIsLeaveOpen(false);
  };
  const ClosePopupScreenOnSave = () => {
    setIsLeaveOpen(false);
    loadEmployeeLeaves();
  };

  let PopUpScreen = "";
  if (isLeaveOpen) {
    PopUpScreen = (
      <AddNewLeave
        isLeaveOpen={isLeaveOpen}
        onClose={ClosePopupScreen}
        onCloseSave={ClosePopupScreenOnSave}
        ID={EmployeeLeaveID}
        setIsRefresh={setIsRefresh}
        Status={status}
      />
    );
  }

  const handleDeleteModal = (ID) => {
    setDelID(ID);
    setIsOpenAlert(true);
  };

  const onCloseDelete = () => {
    setDelID(null);
    setIsOpenAlert(false);
    loadEmployeeLeaves();
  };
  const onClose = () => {
    setIsOpenAlert(false);
  };
  let ConfirmDelete = "";
  if (isOpenAlert) {
    ConfirmDelete = (
      <DeleteConfirmation
        isOpenAlert={isOpenAlert}
        onCloseDelete={onCloseDelete}
        API_URL={API_URL}
        TableName={enumUtil.enumAPITableName.EmployeeLeave}
        ID={delID}
        onClose={onClose}
      />
    );
  }

  useEffect(() => {
    loadEmployeeLeaves();
  }, [mainDropDownModal.Month]);

  return (
    <div>
      {PopUpScreen}
      {ConfirmDelete}
      {isLoading ? <Loader /> : <></>}
      <div className="ar_my-request-screen dropdown-bgc-position date-slect-bg basic_info leave_screen ar_leave_screen my-leave-requset">
        <div className="leave_right">
          <div className="content">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex pending align-items-center gap-2">
                <p className="heading num">20</p>
                <div>
                  <p className="heading sm">Available leaves this year</p>
                </div>
              </div>
              <div className="left_border"></div>
              <div className="d-flex used align-items-center gap-2">
                <p className="heading num">02</p>
                <div>
                  <p className="heading sm">Leaves used this year</p>
                </div>
              </div>
              <div className="left_border"></div>
              <div className="d-flex due align-items-center gap-2">
                <p className="heading num">01</p>
                <div>
                  <p className="heading sm">Leave Balance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4 pe-2 ">
          <p className="heading clr_drk-blue fs-14 doc  px-2 py-1">
            Leave Requests
          </p>
          {/* <div className='col-md-7 d-flex justify-content-between align-items-center'>
                            <div className="d-flex gap-2 leave_req_screen h-auto align-items-center">
                                <div className="left_border all"></div>
                                <div>
                                    <p className="heading">Total Request</p>
                                    <p className="heading sm">30</p>
                                </div>
                                <div className="left_border new"></div>
                                <div>
                                    <p className="heading"> New Request</p>
                                    <p className="heading sm">3</p>
                                </div>
                                <div className="left_border pending"></div>
                                <div>
                                    <p className="heading">Pending  </p>
                                    <p className="heading sm">30</p>
                                </div>
                                <div className="left_border approved"></div>
                                <div>
                                    <p className="heading">Approved  </p>
                                    <p className="heading sm">30</p>
                                </div>
                                <div className="left_border denied"></div>
                                <div>
                                    <p className="heading">Denied  </p>
                                    <p className="heading sm">30</p>
                                </div>

                            </div>
                            <div className="ar_request_screen ">
                                <Nav variant="tabs" className="rb-tabs-v3 tabs_view mb-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="TableView"> <i className='bi bi-grid fs-12'></i> Table</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="CardView"><i className='icon-Group-482468 fs-12'></i> Card</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div> */}
          <div className="d-flex align-items-center gap-10">
            <button
              className="btn btn-blue rounded"
              onClick={() => AddLeave(0, "Pending")}
            >
              Add Request{" "}
            </button>
            <div className="input">
              <select
                className="form-control fc-xs"
                id="Month"
                name="Month"
                value={mainDropDownModal.Month}
                onChange={(e) => handleMonthChange(e)}
              >
                {MonthsDropdown.map((item) => {
                  return <option value={item.value}>{item.display}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
        {/* <Tab.Content>
                        <Tab.Pane eventKey="TableView">
                            <TableView ColumnsData={AllRequestData} LoadData={LoadData} />
                            {isLoading ? null :
                                AllRequestData && AllRequestData?.length === 0 ?
                                    <div className='no_data'>
                                        <span>
                                            <i className='icon-documents-search-icon-1'></i>
                                        </span>
                                        <p>No Data Found</p>
                                    </div> : null
                            }
                        </Tab.Pane>
                        <Tab.Pane eventKey="CardView">
                            <CardView ColumnsData={AllRequestData} LoadData={LoadData} />
                            {isLoading ? null :
                                AllRequestData && AllRequestData?.length === 0 ?
                                    <div className='no_data'>
                                        <span>
                                            <i className='icon-documents-search-icon-1'></i>
                                        </span>
                                        <p>No Data Found</p>
                                    </div> : null
                            }
                        </Tab.Pane>
                    </Tab.Content> */}

        <div className="table-view all_employee">
          <ReactDataGrid
            enableVirtualization={false}
            // virtualizeColumns={columns.length > 10}
            columns={columns}
            rows={AllRequestData}
            rowHeight={30}
            className={
              "fill-grid DataGrid-Table hk-exp-table all_employee_grid"
            }
            style={{
              width: "100%",
              // height:'100px',
              // maxHeight: '330px',
            }}
          />
          {isLoading ? null : AllRequestData && AllRequestData?.length === 0 ? (
            <div className="no_data">
              <span class="icon-ar-nodata">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
                <span class="path11"></span>
                <span class="path12"></span>
                <span class="path13"></span>
                <span class="path14"></span>
                <span class="path15"></span>
                <span class="path16"></span>
                <span class="path17"></span>
                <span class="path18"></span>
                <span class="path19"></span>
                <span class="path20"></span>
                <span class="path21"></span>
                <span class="path22"></span>
                <span class="path23"></span>
                <span class="path24"></span>
                <span class="path25"></span>
                <span class="path26"></span>
                <span class="path27"></span>
                <span class="path28"></span>
                <span class="path29"></span>
                <span class="path30"></span>
                <span class="path31"></span>
                <span class="path32"></span>
                <span class="path33"></span>
                <span class="path34"></span>
                <span class="path35"></span>
                <span class="path36"></span>
                <span class="path37"></span>
                <span class="path38"></span>
                <span class="path39"></span>
                <span class="path40"></span>
                <span class="path41"></span>
                <span class="path42"></span>
                <span class="path43"></span>
                <span class="path44"></span>
                <span class="path45"></span>
                <span class="path46"></span>
                <span class="path47"></span>
                <span class="path48"></span>
                <span class="path49"></span>
                <span class="path50"></span>
                <span class="path51"></span>
                <span class="path52"></span>
                <span class="path53"></span>
                <span class="path54"></span>
                <span class="path55"></span>
                <span class="path56"></span>
                <span class="path57"></span>
                <span class="path58"></span>
                <span class="path59"></span>
                <span class="path60"></span>
                <span class="path61"></span>
                <span class="path62"></span>
                <span class="path63"></span>
                <span class="path64"></span>
                <span class="path65"></span>
                <span class="path66"></span>
                <span class="path67"></span>
                <span class="path68"></span>
                <span class="path69"></span>
                <span class="path70"></span>
                <span class="path71"></span>
                <span class="path72"></span>
                <span class="path73"></span>
                <span class="path74"></span>
                <span class="path75"></span>
                <span class="path76"></span>
                <span class="path77"></span>
                <span class="path78"></span>
                <span class="path79"></span>
                <span class="path80"></span>
                <span class="path81"></span>
                <span class="path82"></span>
                <span class="path83"></span>
                <span class="path84"></span>
                <span class="path85"></span>
                <span class="path86"></span>
                <span class="path87"></span>
                <span class="path88"></span>
                <span class="path89"></span>
                <span class="path90"></span>
                <span class="path91"></span>
                <span class="path92"></span>
                <span class="path93"></span>
                <span class="path94"></span>
                <span class="path95"></span>
                <span class="path96"></span>
                <span class="path97"></span>
                <span class="path98"></span>
                <span class="path99"></span>
                <span class="path100"></span>
                <span class="path101"></span>
                <span class="path102"></span>
                <span class="path103"></span>
                <span class="path104"></span>
                <span class="path105"></span>
                <span class="path106"></span>
                <span class="path107"></span>
                <span class="path108"></span>
                <span class="path109"></span>
                <span class="path110"></span>
                <span class="path111"></span>
                <span class="path112"></span>
                <span class="path113"></span>
                <span class="path114"></span>
                <span class="path115"></span>
                <span class="path116"></span>
                <span class="path117"></span>
                <span class="path118"></span>
                <span class="path119"></span>
                <span class="path120"></span>
                <span class="path121"></span>
                <span class="path122"></span>
                <span class="path123"></span>
                <span class="path124"></span>
                <span class="path125"></span>
                <span class="path126"></span>
                <span class="path127"></span>
                <span class="path128"></span>
              </span>
              <p className="text fst-italic text-center font-m">
                No Data Found!
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MyLeaveRequests;
