export const MainTabContentActiveTab = (activeTab) => {
    return {
        type: 'MainTabContentActiveTab',
        payload: activeTab
    }
}

export const MainTabContentTabList = (tabList) => {
    return {
        type: 'MainTabContentTabList',
        payload: tabList
    }
}