import React, { useEffect, useState } from 'react'
import YearlyAttendance from '../../EmployeeAttendance/YearlyAttendance';
import { useSelector } from 'react-redux';
import EmployeeInformation from '../../MyInfo/Account/EmployeeInformation/EmployeeInformation';
import { ServerRequestProtected } from '../../../utils/ServerDataRequest';
import { enumUtil } from '../../../utils/Enum';

const OverallView = (props) => {

  const { Active, refresh, setRefresh, type } = { ...props }
  let UserID = Active ? String(Active) : null;
  const currentYear = String(new Date().getFullYear());
  const API_URL = process.env.REACT_APP_API
  let OrgID = String(useSelector(state => state.Organization))
  var currentMonth = String(new Date().getMonth() + 1);

  const Month_Year = {
    Month: currentMonth,
    Year: currentYear,
  }

  let SelectYear_Modal = {
    Year: currentYear,
  }

  let AttAndLv_Modal = {
    // "OrganizationID": OrgID,
    "ShiftIDs": null,
    "TeamIDs": null,
    "DepartmentIDs": null,
    "Search": null,
    Month: currentMonth,
    Year: currentYear,
  }

  let AttAndLvEmp_Modal = {
    EmployeeID: UserID,
    Year: currentYear,
  }

  let OverAll_AttAndLv_Modal = {
    EmployeeID: UserID,
    // OrganizationID: OrgID + "",
    Months: "",
    Year: currentYear,
  }

  let OverAllData_Search = {
    // "OrganizationID": OrgID,
    "ShiftIDs": null,
    "TeamIDs": null,
    "DepartmentIDs": null,
    "EmployeeIDs": null,
    "Month": currentMonth,
    "Year": currentYear,
  }


  const [searchOverAllEmployeeData, setSearchOverAllEmployeeData] = useState(OverAll_AttAndLv_Modal);
  const [searchMonthlyParam, setsearchMonthlyParam] = useState(AttAndLvEmp_Modal);
  const [isLoading, setIsLoading] = useState(false)
  const [selectDateEmp, setSelectDateEmp] = useState(SelectYear_Modal)
  const [employeeOverAllAttendance, setEmployeeOverAllAttendance] = useState()
  const [YearOptions, setYearOptions] = useState([]);

  const LoadOverAllDataEmployee = (searchOverAllEmployeeData) => {
    setIsLoading(true);
    ServerRequestProtected(API_URL + "/GetCalculatedAttendance", "post", {
      "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
      "SearchCriteria": searchOverAllEmployeeData,
    }).then((response) => {
      setIsLoading(false);
      setEmployeeOverAllAttendance(response[0]);
      // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success);
    }).catch((error) => {
      setIsLoading(false);
      // NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
    })
  }

  /////----EmployeeChanges---///////
  const handleUpdateEmployeeYearSearchParam = (e) => {
    const updatedOverAllModal = { ...searchOverAllEmployeeData, Year: e.target.value }
    setSearchOverAllEmployeeData(updatedOverAllModal);
    const updatedMonthlyModel = { ...searchMonthlyParam, Year: e.target.value }
    setsearchMonthlyParam(updatedMonthlyModel);
    const updatedSelectDate = { ...selectDateEmp, Year: e.target.value }
    setSelectDateEmp(updatedSelectDate)
  }

  useEffect(() => {
    LoadOverAllDataEmployee(searchOverAllEmployeeData);
  }, [searchOverAllEmployeeData, OrgID]);

  return (
    <div>
      <div className='d-flex ps-2 mb-3 justify-content-between dropdown-bgc-position date-slect-bg'>
        <div className="d-flex gap-2 justify-content-start flex-wrap">
          <div className="hk-attendance-status green-bg">Present | {employeeOverAllAttendance?.PresentEmployees || 0}</div>
          <div className="hk-attendance-status litePink-bg">Absent | {employeeOverAllAttendance?.AbsentEmployees || 0}</div>
          <div className="hk-attendance-status gray-bg">Weekends | {employeeOverAllAttendance?.TotalWeekendDays || 0}</div>
          <div className="hk-attendance-status liteBlue-bg">National Holidays | {employeeOverAllAttendance?.NationalHolidays || 0}</div>
          <div className="hk-attendance-status pink-bg">Leaves | {employeeOverAllAttendance?.Leaves || 0}</div>
          <div className="hk-attendance-status liteOrnage-bg">Late count | {employeeOverAllAttendance?.LateCount || 0}</div>
        </div>
        <div className="input" >
          <select className="form-control"
            name="Year"
            id="Year"
            aria-label="Default select example"
            value={selectDateEmp.Year}
            onChange={(e) => handleUpdateEmployeeYearSearchParam(e)}
          >
            {YearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row m-0">
        <YearlyAttendance Active={Active} refresh={refresh} setRefresh={setRefresh} searchOverAllEmployeeData={searchOverAllEmployeeData} setSearchOverAllEmployeeData={setSearchOverAllEmployeeData} searchParamYear={searchMonthlyParam} type={type} YearOptions={YearOptions} setYearOptions={setYearOptions} />
      </div>
    </div>
  )
}

export default OverallView
