import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ModalBody } from "reactstrap"
import ModalFooter from '../../../shared/ModalFooter/ModalFooter';
import Loader from '../../../shared/Loader/Loader';
import close from "../../../../assetsOld/img/x-close.svg"
import { ServerRequestProtected, ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import FormValidator from '../../../../utils/FormValidator';
import { enumUtil } from '../../../../utils/Enum';
import NotificationHandler from '../../../../utils/NotificationHandler';
import isNull from '../../../../utils/NullChecking';
import CalendarInput from '../../../../utils/CalendarInput';
import { format } from 'date-fns';
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import jwt_decode from 'jwt-decode';
import ModalFooterAdmin from '../../../shared/ModalFooterAdmin/ModalFooterAdmin';
import ApproveConfirmation from '../../../shared/Approve/ApproveConfirmation';
import DenyConfirmation from '../../../shared/Deny/DenyConfirmation';
import DatePicker from '../../../../utils/DatePicker';

const AddNewRequest = (props) => {

    let Request_Modal = {
        // EmployeeID: "",
        RequestDate: null,
        RequestType: "",
        Description: "",
        // RequireDiscussion: "",
    }

    let valModal = {}
    let ValidationModal = {
        RequestDate: "",
        RequestType: "",
    }

    const { isOpenTask, onClose, onCloseSave, ID, setIsRefresh, type, Status, view, LoadOnSave } = { ...props }

    const API_URL = process.env.REACT_APP_API;
    const [isLoading, setIsLoading] = useState(false);
    const [requestID, setRequestID] = useState(0)
    const [position, setPosition] = useState(null);
    const [mainRequestModal, setMainRequestModal] = useState(Request_Modal);
    const [SaveRequestModal, setSaveRequestModal] = useState();
    const [RequestValidationModal, setRequestValidationModal] = useState(ValidationModal);
    const [RequestStatus, setRequestStatus] = useState()
    const [approveID, setApproveID] = useState(0);
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [denyID, setDenyID] = useState(null);
    const [isOpenDeny, setIsOpenDeny] = useState(false)
    const [LeaveStatus, setLeaveStatus] = useState({})
    const [request, setRequest] = useState()

    const handleRequestChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainRequestModal };
        let saveModal = { ...SaveRequestModal }

        modal[name] = value;

        setMainRequestModal(modal);
        for (let property in Request_Modal) {
            if (name === property) {
                saveModal[property] = value
            }
        }
        setSaveRequestModal(saveModal)
    }

    const RequestValidation = async () => {
        let ValidationModal = {
            RequestDate: FormValidator(
                mainRequestModal.RequestDate,
                enumUtil.enumValidationType.Required,
                'Requested Date',
            ),
            RequestType: FormValidator(
                mainRequestModal.RequestType,
                enumUtil.enumValidationType.Required,
                'Request Type',
            ),
        }
        await setRequestValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveTask = async (e) => {
        await RequestValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            // SaveRequestModal["OrganizationID"] = OrgID
            // SaveRequestModal["EmployeeID"] = UserID
            let saveModal = {
                TableName: enumUtil.enumAPITableName.REQUEST,
                ID: requestID,
                // UserID: UserID,
                data: { ...SaveRequestModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setRequestID(response.id)
                    setIsRefresh(true)
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }

    const handleCalendarChange = (date, name) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, "MM/dd/yyyy")
        let modal = { ...mainRequestModal };
        let saveModal = { ...SaveRequestModal }
        modal[name] = FormatedDateShow
        setMainRequestModal(modal)
        for (var property in Request_Modal) {
            if (name === property) {
                saveModal[name] = FormatedDateSave
            }
        }
        setSaveRequestModal(saveModal)
        closeDateRangePickerMenu()
    }

    const openDateRangePicker = Boolean(position);

    const openDateRangePickerMenu = (event) => {
        setPosition(event.currentTarget);
    };

    const closeDateRangePickerMenu = () => {
        setPosition(null);
    };

    const handleApproveModal = (ID) => {
        // setApproveID(ID);
        setIsOpenApprove(true);
        setLeaveStatus({ RequestStatus: "Approved" });
    }

    const onCloseApprove = () => {
        setApproveID(0);
        setIsRefresh(true)
        setRequest("Approved")
        setIsOpenApprove(false)
    }

    const onApprovalClose = () => {
        setIsOpenApprove(false)
    }

    let ApprovePopup = "";
    if (isOpenApprove) {
        ApprovePopup = <ApproveConfirmation isOpenAlert={isOpenApprove} onCloseDelete={onCloseApprove} API_URL={API_URL} TableName={enumUtil.enumAPITableName.REQUEST} ID={approveID} LeaveStatus={LeaveStatus} onClose={onApprovalClose} LoadData={onCloseSave} />
    }

    const handleDenyModal = (ID) => {
        // setDenyID(ID);
        setIsOpenDeny(true);
        setLeaveStatus({ RequestStatus: "Dennied" });
    }

    const onCloseDeny = () => {
        console.log("OnCLoseDeny")
        setDenyID(0);
        setRequest("Dennied")
        setIsRefresh(true)
        setIsOpenDeny(false)
    }

    const onDenyClose = () => {
        setIsOpenDeny(false)
    }

    let DenyPopup = "";
    if (isOpenDeny) {
        DenyPopup = <DenyConfirmation isOpenAlert={isOpenDeny} onCloseDelete={onCloseDeny} API_URL={API_URL} TableName={enumUtil.enumAPITableName.REQUEST} ID={denyID} LeaveStatus={LeaveStatus} onClose={onDenyClose} LoadData={onCloseSave} />
    }

    useEffect(() => {
        setIsLoading(false)
        if (!isNull(ID)) {
            setIsLoading(true);
            setRequestID(ID)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.REQUEST,
            }).then((response) => {
                setIsLoading(false)
                setMainRequestModal(response)
                setRequestStatus(response?.RequestStatus)
                setApproveID(response?.RequestID)
                setDenyID(response?.RequestID)
            }).catch((error) => {
                setIsLoading(false)
            })
        }
    }, [ID])

    return (
        <div>
            <Modal show={isOpenTask} onHide={isOpenTask} backdrop="static" keyboard={false} centered dialogClassName={`delete-modal hk-modal-v1 app-modal ems_modal ar_resignation_popup ${Status && Status === 'Pending' ? '' : 'ar_disable-popup'}`}>

                <ModalBody>
                    {isLoading ? <Loader /> : <></>}
                    {DenyPopup}
                    {ApprovePopup}
                    <div className='d-flex justify-content-end cursor-pointer' onClick={() => onClose()}>
                        <img className="close" src={close} ></img>
                    </div>
                    <div className="modal_header mb-0">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Vector-4 fs-20"></i>
                            </span>
                            <p class="heading my-2">Add Request</p>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-15'>
                        <div className={`form-group input ${RequestValidationModal.RequestDate ? 'error' : ''}`}>
                            <DatePicker
                                label={<span>Created Date<span className="req"> *</span></span>}
                                value={mainRequestModal?.RequestDate}
                                openCalendarMenufunc={(event) => openDateRangePickerMenu(event, 'RequestDate')}
                                maxDate={new Date()}
                                anchorEl={position}
                                open={openDateRangePicker}
                                closeCalendarMenufunc={closeDateRangePickerMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'RequestDate')}
                                placeholder={true}
                            />
                            {RequestValidationModal.RequestDate}
                        </div>
                        <div className={`form-group input  ${RequestValidationModal.RequestType ? 'error' : ''}`}>
                            <label>Request Type<span className="req"> *</span></label>
                            <input type="text"
                                className="form-control"
                                name="RequestType"
                                id="RequestType"
                                value={mainRequestModal?.RequestType}
                                onChange={handleRequestChange}
                            />
                            {RequestValidationModal.RequestType}
                        </div>
                        <div className="form-group input">
                            <label>Description</label>
                            <textarea
                                type="text"
                                rows={4}
                                className="form-control h-auto"
                                name="Description"
                                id="Description"
                                value={mainRequestModal?.Description}
                                onChange={handleRequestChange}
                            />
                        </div>
                    </div>
                </ModalBody>
                {view && view === "Admin" ?
                    <ModalFooterAdmin isSaved={handleDenyModal} handleSave={handleApproveModal} RequestStatus={RequestStatus} />
                    :
                    <ModalFooter isSaved={onClose} handleSave={handleSaveTask} RequestStatus={Status} />
                }
            </Modal>
        </div>
    )
}

export default AddNewRequest
