import SetupTopMenu from "./SetupLayout/SetupTopMenu"

const Setup = () => {

    return (
        <div>
            <SetupTopMenu />
        </div>
    )
}
export default Setup