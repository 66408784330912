import React from 'react'
import DonutChart from '../Charts/DonutChart'
import NoDataFound from './NoDataFound'

const GenderDiversity = ({data}) => {
  console.log("data",data)
  return (
    <div className="gender-diversity card_tabs overflow-hidden h-100">
        <p className='heading pb-2'>Gender Diversity</p>
        {data && 
        (!["",null,undefined].includes(data?.MalePercentage) || !["",null,undefined].includes(data?.FemalePercentage))  ? 
        <>
        <p className='text light_clr fs-10'>Current Employee</p>
        <DonutChart type={'Gender Diversity'} data={data}/> </>: <div className='position-relative' style={{height:201}}><NoDataFound/></div>}
    </div>
  )
}

export default GenderDiversity