import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import close from "../../../../assetsOld/img/x-close.svg"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux";
import { CancelButton } from "../../../../actions/CancelButton";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import format from 'date-fns/format';
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import jwt_decode from 'jwt-decode';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';
import DatePicker from '../../../../utils/DatePicker';

const AddNewSkills = (props) => {
    const { isOpenSkills, handleOnClose, handleOnCloseSave, ID, setIsRefresh, type } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let EmployeeID = "";
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    //  else {
    //     EmployeeID = Token.UserID;
    // }

    let dispatch = useDispatch();
    let Skill_Modal = {
        SkillName: "",
        Institute: "",
        Remarks: "",
        GrantedOn: null,
        Rating: "",
    }
    let valModal = {}
    let ValidationModal = {
        SkillName: "",
        Institute: "",
    }
    const [skillValidationModal, setSkillValidationModal] = useState(ValidationModal)
    const [skillSaveUpdateModal, setSkillSaveUpdateModal] = useState({})
    const [mainSkillModal, setmainSkillModal] = useState(Skill_Modal);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const API_URL = process.env.REACT_APP_API
    const [SkillID, setSkillID] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [adddelete2, setpopdelete1] = useState(false);
    const [delID, setDelID] = useState(0)
    ///////Calendar////////
    const [grantedOn, setGrantedOn] = useState();
    const openGrantedOn = Boolean(grantedOn);

    const handleCalendarChange = (date, type) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, "MM/dd/yyyy")

        let modal = { ...mainSkillModal };
        let saveModal = { ...skillSaveUpdateModal }
        modal[type] = FormatedDateShow
        setmainSkillModal(modal)
        for (var property in Skill_Modal) {
            if (type === property) {
                saveModal[type] = FormatedDateSave
            }
        }
        setSkillSaveUpdateModal(saveModal)
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setGrantedOn(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'GrantedOn') { setGrantedOn(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    const handleSkillChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainSkillModal };
        let saveModal = { ...skillSaveUpdateModal }
        modal[name] = value
        setmainSkillModal(modal)
        for (var property in Skill_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setSkillSaveUpdateModal(saveModal)
    }

    const OfficeValidation = async () => {
        let ValidationModal = {
            SkillName: FormValidator(
                mainSkillModal.SkillName,
                enumUtil.enumValidationType.Required,
                'Skill Name',
            ),
            // Institute: FormValidator(
            //     mainSkillModal.Institute,
            //     enumUtil.enumValidationType.Required,
            //     ' Institute',
            // ),
        }
        await setSkillValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveOffice = async () => {
        await OfficeValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setSkillSaveUpdateModal(skillSaveUpdateModal)
            skillSaveUpdateModal["EmployeeID"] = EmployeeID + "";
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Skill,
                ID: SkillID,
                // ParentID: EmployeeID,
                data: { ...skillSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setSkillID(response.id)
                    setIsRefresh(true);
                    handleOnCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log('error', error);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsRefresh(false);
            })
        }
    }

    useEffect(() => {
        if (!isNull(ID)) {
            setIsLoading(true);
            setSkillID(ID);
            // setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Skill
            }).then((response) => {
                setmainSkillModal(response);
                setIsLoading(false)
                // setAddNew(true);
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [SkillID])
    return (
        <Modal show={isOpenSkills} onHide={isOpenSkills} backdrop="static" keyboard={false} centered dialogClassName="add_new_skill">
            <div className="education_popup px-0">
                {isLoading ? <Loader /> : null}
                <img className="cursor-pointer" src={close} onClick={() => handleOnClose()}></img>
                <div className="text-center">
                    <span className="icon">
                        <div className="certification_icon">
                            <i className="icon-Icon-material-work"></i>
                        </div>
                    </span>
                    <p className="heading mt-2">Add New Skills</p>
                </div>
                <div className='px-14'>
                    <div className={skillValidationModal.SkillName ? "form-group input error" : "form-group input"}>
                        <label>Skill Name<span className='req pe-2'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="SkillName"
                            id="SkillName"
                            maxLength={25}
                            value={mainSkillModal.SkillName}
                            onChange={handleSkillChange} />
                        {skillValidationModal.SkillName}
                    </div>
                    <div className={"form-group input"}>
                        <label>Institute</label>
                        <input type="text" className="form-control"
                            name="Institute"
                            id="Institute"
                            maxLength={40}
                            value={mainSkillModal.Institute}
                            onChange={handleSkillChange}
                        />
                    </div>
                    <div className="form-group input">
                        <DatePicker
                            label='Granted On'
                            maxDate={new Date()}
                            value={mainSkillModal.GrantedOn}
                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'GrantedOn')}
                            anchorEl={grantedOn}
                            open={openGrantedOn}
                            closeCalendarMenufunc={closeCalendarMenu}
                            handleCalendarChangefunc={date => handleCalendarChange(date, 'GrantedOn')}
                            placeholder={true}
                        />
                    </div>
                    {/* <div className="form-group input">
                    <label>Remarks</label>
                    <input
                        type="text"
                        className="form-control"
                        name="Remarks"
                        id="Remarks"
                        value={mainSkillModal.Remarks}
                        onChange={handleSkillChange}
                    />
                </div> */}
                    <div className=''>
                        <Typography component="legend">Rating</Typography>
                        <Rating name="Rating" value={mainSkillModal.Rating} defaultValue={2} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} onChange={handleSkillChange} />

                    </div>
                </div>
                <div className="d-flex justify-content-end gap-3 br-top-1 clr_light px-14 pt-2">
                    <button class="btn btn-gray" onClick={() => handleOnClose()}>Cancel</button>
                    <button class="btn btn-blue" onClick={() => handleSaveOffice()}>Save</button>
                </div>
            </div>
        </Modal>
    )
}

export default AddNewSkills
