import React, { useEffect, useState } from "react";
import { NavItem , NavLink } from 'reactstrap'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import SettingInnerLeftMenuContent from "./SettingInnerLeftMenuContent";
import SettingMenu from "./SettingMenu";

const SettingInnerLeftMenu = (props) => {

    const MenuItems = SettingMenu();
    const [subCategItems, setSubCategItems] = useState([])
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState()
    useEffect(() => {
        const selected_Item_List = MenuItems.filter((item) => item.key === props.subCategKey);
        if (selected_Item_List && selected_Item_List.length > 0) {
            let subCategories = selected_Item_List[0].subCategoryItems
            setSubCategItems(subCategories)
            setActiveTab(subCategories[0].key)
        }
    }, [props.subCategKey])

    const handleActiveTab = (item) => {
        const { key, name, right,component } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const activeTabElement = subCategItems.filter((item) => item.key === activeTab)
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
                component: activeTabElement.component,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
            component: component,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }
    return (
        <div className='app-tabs fix-tab'>
            <Nav className='rb-tabs bg-light-blue'>
                {subCategItems && subCategItems.map((item) => {
                    return (
                        <React.Fragment key={item.key}>
                            <NavItem onClick={() => handleActiveTab(item)}>
                                <NavLink href="#" className={activeTab === item.key ? "py-2 active" : "py-2"}>
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        </React.Fragment>
                    )
                })}
            </Nav>
        <SettingInnerLeftMenuContent activeTab={activeTab} dynamicTabs={tabList} subCategItems={subCategItems} />
        </div>
    )
}

export default SettingInnerLeftMenu