import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect, useDispatch } from 'react-redux';
import { TabAction } from '../../../../actions/HandleTabAction';
import { GoTo } from '../../../../actions/GotoPage';
import CustomUseRef from '../../../shared/CustomUseRef/CustomUseRef';
import { ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import NotificationHandler from '../../../../utils/NotificationHandler';
import Loader from '../../../shared/Loader/Loader';
import { enumUtil } from '../../../../utils/Enum';
import LetterProfilePicture from '../shared/LetterProfilePicture';
import { isFemale, isMale } from '../../../../utils/Constants';

const Team = ({ data, TabAction , setReloadData}) => {
    const teamsList = [...new Set(data?.map(obj => obj.TeamID))].map(teamID =>
        data?.filter(obj => obj.TeamID === teamID)
    );
    let teamLead = teamsList.map(item => {
        return item.filter(emp =>['True','true',true].includes(emp.TeamLead))
    })
    // const [showAllMembers, setShowAllMembers] = useState({});
    const [showTeam, setShowTeam] = useState();
    const [showOptions, setShowOptions] = useState({})
    const [isOpenTeamModal, setIsOpenTeamModal] = useState(false)
    // const [showOptions, setShowOptions] = useState(false)
    // const handleShowAllMembers = (id) => {
    //     setShowAllMembers(prev => ({ ...prev, [id]: !prev[id] }))
    // }
    return (
        <>
            <div className='card_tabs ar_new_request w-100'>
                <p className='heading top-head pee-10 pb-2'>Team</p>
                <div className='ar_teams-content pee-10 ar_hov-scrollbar'>
                    {teamsList ?
                        teamsList?.map((teamList, indx) => {
                            return (
                                <>
                                    <div className='align-items-center d-flex justify-content-between row-gap-15 mb-3'>
                                        <div className='d-flex gap-1 align-items-center'>
                                            {teamList ?
                                                teamList?.map((teamMember, index) => (
                                                    <>
                                                        {index === 0 ?
                                                            <>
                                                                <div className='position-relative img-tooltip'>
                                                                    <div className="img-container circle s-35">
                                                                    {teamMember.ProfilePicture ? (
                                                                    <img src={teamMember.ProfilePicture} alt="Profile" />
                                                                    ) : (
                                                                    <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-14 text-white ${isMale(teamMember.Gender)  ? 'male' : isFemale(teamMember.Gender) ? 'female':'rather-undefined'}`} name={teamMember.EmployeeName} />
                                                                    )}
                                                                    </div>
                                                                    <div className='tool_tip'>
                                                                        <p className='heading text-white fs-10'>{teamMember.EmployeeName}</p>
                                                                        <p className='text font-m text-white fs-9'>{teamMember.DesignationName}</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className='heading font-m'>{teamMember.TeamName}</p>
                                                                    {/* <p className='text font-m grey validation_message' title={teamMember.DesignationName}>{teamMember.DesignationName}</p> */}
                                                                </div> </> : <></>}
                                                    </>)) : <></>}
                                        </div>
                                        <div className='align-items-center d-flex '>
                                            <div className='align-items-center d-flex pe-2 position-relative ar_user_avatar img-tooltip'>
                                                {teamList?.map((teamMember, index) => (
                                                    <>
                                                        {index === 1 ?
                                                            <>
                                                                <div className="img-container circle s-35  left-img ">
                                                                    {teamMember.ProfilePicture ? (
                                                                    <img src={teamMember.ProfilePicture} alt="Profile" />
                                                                    ) : (
                                                                        <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-14 text-white ${isMale(teamMember.Gender) ? 'male' : isFemale(teamMember.Gender) ? 'female' : 'rather-undefined'}`} name={teamMember.EmployeeName} />
                                                                    )}
                                                                </div>
                                                                <div className='tool_tip'>
                                                                    <p className='heading text-white fs-10'>{teamMember.EmployeeName}</p>
                                                                    <p className='text font-m text-white fs-9'>{teamMember.DesignationName}</p>
                                                                </div>
                                                            </> :
                                                            index === 2 ?
                                                                <>
                                                                    <div className="img-container circle s-35 center-img ">
                                                                    {teamMember.ProfilePicture ? (
                                                                    <img src={teamMember.ProfilePicture} alt="Profile" />
                                                                    ) : (
                                                                        <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-14 text-white ${isMale(teamMember.Gender) ? 'male' : isFemale(teamMember.Gender)  ? 'female' : 'rather-undefined'}`} name={teamMember.EmployeeName} />
                                                                    )}
                                                                    </div>
                                                                    <div className='tool_tip'>
                                                                        <p className='heading text-white fs-10'>{teamMember.EmployeeName}</p>
                                                                        <p className='text font-m text-white fs-9'>{teamMember.DesignationName}</p>
                                                                    </div>
                                                                </>
                                                                :
                                                                index === 3 ?
                                                                    <>
                                                                        <div className="img-container circle s-35  right-img ">
                                                                        {teamMember.ProfilePicture ? (
                                                                        <img src={teamMember.ProfilePicture} alt="Profile" />
                                                                        ) : (
                                                                            <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-14 text-white ${isMale(teamMember.Gender) ? 'male' : isFemale(teamMember.Gender) ? 'female' : 'rather-undefined'}`} name={teamMember.EmployeeName} />
                                                                        )}
                                                                        </div>
                                                                        <div className='tool_tip'>
                                                                            <p className='heading text-white fs-10'>{teamMember.EmployeeName}</p>
                                                                            <p className='text font-m text-white fs-9'>{teamMember.DesignationName}</p>
                                                                        </div>
                                                                    </>
                                                                    : <></>}
                                                    </>))}
                                            </div>
                                            {teamList?.length > 4 ?
                                                <div className="cursor-pointer">
                                                    {/* {showAllMembers?.[teamList?.[0]?.TeamID] &&
                                                        // <p className='text link-text cursor-pointer' onClick={() => { 
                                                            // handleShowAllMembers(teamList?.[0]?.TeamID);
                                                            // setShowTeam(indx);setIsOpenTeamModal(!isOpenTeamModal);}}>-{teamList.length - 4}</p> 
                                                            // :
                                                        // <p className='text link-text cursor-pointer' onClick={() => { 
                                                        //     // handleShowAllMembers(teamList?.[0]?.TeamID);
                                                        //     setShowTeam(indx);setIsOpenTeamModal(!isOpenTeamModal);}}>+{teamList.length - 4}</p>
                                                    } */}
                                                    <p className='text link-text cursor-pointer' onClick={() => { setShowTeam(indx); setIsOpenTeamModal(!isOpenTeamModal); }}>+{teamList.length - 4}</p>
                                                </div> : <></>}
                                        </div>
                                    </div>
                                    {/* {teamsList?.[showTeam] &&
                                        <div className='align-items-center d-flex flex-wrap gap-1 mb-2'>
                                            {teamList?.map((teamMember, index) => (
                                                <>
                                                    {index > 3 ?
                                                        <div className="img-container circle border-2 border-white s-35  left-img ">
                                                            <img src={teamMember.ProfilePicture || 'https://www.w3schools.com/howto/img_avatar.png'}></img>
                                                        </div> :
                                                        <></>}
                                                </>))}
                                        </div>} */}
                                </>)
                        }) : <></>}
                </div>
            </div>
            <Modal show={isOpenTeamModal} onHide={isOpenTeamModal} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal ar_team-popup">
                <Modal.Body>
                    <div className='text-end mb-3 px-10'>
                        <i className='icon-Vector7 fs-12' onClick={() => setIsOpenTeamModal(false)}></i>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-2 px-10 br-bottom-1 clr-light pb-2'>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className='fs-11 text clr_gry'>Team:</p>
                            <p className='heading'>{teamsList?.[showTeam]?.[0]?.TeamName}</p>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className='fs-11 text clr_gry'>Team Lead:</p>
                            <p className='heading'>{teamLead?.[showTeam]?.[0]?.EmployeeName || 'N/A'}</p>
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <p className='fs-11 text clr_gry'>Members:</p>
                            <p className='heading'>{teamsList?.[showTeam]?.length}</p>
                        </div>
                    </div>
                    <div className="ar_hov-scrollbar px-10 scroll">
                    <div className="pb-2">
              <div className="d-flex align-items-center gap-2">
                <div className="img-container circle border-2  s-40">
                  <img src="https://www.w3schools.com/howto/img_avatar.png"></img>
                </div>
                <div>
                  <p className="heading clr_drk-blue">ISRAH ABBASI</p>
                  <p className="text fs-11 font-m clr_light" title="">
                    UI/UX Designer
                  </p>
                </div>
              </div>
            </div>
                    <div className='d-flex flex-wrap align-items-center gap-1 '>
                        {teamsList?.[showTeam] &&
                            <>
                                {teamsList?.[showTeam]?.map((teamMember, index) => (
                                    <>
                                        {
                                        // index > 3 ? <TeamMember teamMember={teamMember} TabAction={TabAction} setShowTeam={setShowTeam} setIsOpenTeamModal={setIsOpenTeamModal}/> : <></>
                                            <TeamMember teamMember={teamMember} TabAction={TabAction} setShowTeam={setShowTeam} setIsOpenTeamModal={setIsOpenTeamModal} 
                                            setReloadData={setReloadData}  showOptions={showOptions} setShowOptions={setShowOptions} index={index}/>  
                                            }
                                    </>))}</>
                        }
                        {/* <div className='hired-box '>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-column align-items-center px-2'>
                                    <div className="img-container circle border-2  s-60">
                                        <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                                    </div>
                                    <p className='heading'>ISRAH ABBASI</p>
                                    <p className='text fs-11 font-m clr_light' title="">UI/UX Designer</p>
                                </div>
                                <p className="icon mb-0 position-relative">
                                    <i className="icon-menuicon cursor-pointer " onClick={() => setShowOptions(!showOptions)}></i>
                                    {showOptions &&
                                        <div className="actions_popup cursor-pointer w-fit-content">
                                            <p className="text  p-2 text-nowrap d-flex align-items-center"><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                                            <p className="text  p-2 text-nowrap d-flex align-items-center"><i className="icon-icon-trash me-1 fs-10"></i>Remove from Team</p>
                                        </div>
                                    }
                                </p>
                            </div>
                        </div> */}
                    </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

function mapStateToPropss(state) {
    return {
    };
}

const action = {
    TabAction,
};

export default connect(mapStateToPropss, action)(Team);

const TeamMember = ({ teamMember , TabAction, setShowTeam ,setIsOpenTeamModal , setReloadData, showOptions ,setShowOptions ,index}) => {
    const API_URL = process.env.REACT_APP_API
    let dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const OpenEmployee = (data) => {
        setIsOpenTeamModal(false);
        setShowOptions({})
        setShowTeam();
        let obj = {
            key: 2,
            name: "Employee",
            active: true,
            data: {
                "id": data.EmployeeID,
                "FirstName": data.EmployeeName
            },
        };
        TabAction(obj);
        dispatch(GoTo(21));
    }
    
    const handeleRemoveTeamMember = (id) => {
      setIsLoading(true);
      ServerRequestProtectedParentID(API_URL + '/RemoveTeamMember', "post", {
          "TableName": "JoiningDetails",
          "ID": id
      })
        .then((response) => {
          setIsLoading(false);
          setIsOpenTeamModal(false);
          setShowOptions(false);
          setShowTeam();
          setReloadData(id)
        })
        .catch((error) => {
          setIsLoading(false);
          NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        });
    };

    const handleMenu = (e,emp) =>{
        e.stopPropagation();
        setShowOptions(prev => ({[index]:!prev[index]}));
    }
    const handleDefaultView = () => {
        setShowOptions({})
    }
    return (
        <div className='hired-box '>
        {isLoading ? <Loader /> : <></>}
            <div className="d-flex">
                <div className='d-flex flex-column align-items-center px-2'>
                    <div className="img-container circle  s-60">
                        {teamMember.ProfilePicture ? (
                        <img src={teamMember.ProfilePicture} alt="Profile" />
                        ) : (
                            <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-14 text-white ${isMale(teamMember.Gender) ? 'male' : isFemale(teamMember.Gender) ? 'female' : 'rather-undefined'}`} name={teamMember.EmployeeName} />
                        )}
                    </div>
                    <p className='heading text-center'>{teamMember.EmployeeName}</p>
                    <p className='text fs-11 font-m clr_light validation_message text-center' title="">{teamMember.DesignationName}</p>
                </div>
                <p className="icon mb-0 position-relative">
                    <i className="icon-menuicon cursor-pointer " onClick={(e) => handleMenu(e)}></i>
                    {showOptions[index] &&
                    <CustomUseRef IsActivePtPayments={showOptions[index]} onClose={()=>handleDefaultView()} >
                        <div className="actions_popup cursor-pointer w-fit-content">
                            <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={()=>{OpenEmployee(teamMember);}}><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                            <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={()=>{handeleRemoveTeamMember(teamMember.EmployeeID)}}><i className="icon-icon-trash me-1 fs-10"></i>Remove from Team</p>
                        </div>
                    </CustomUseRef>
                    }
                </p>
            </div>
        </div>
    )
}