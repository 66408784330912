import React, { useEffect, useState } from 'react'
import close from "../../../../assetsOld/img/x-close.svg"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import isNull from "../../../../utils/NullChecking"
import FormValidator from "../../../../utils/FormValidator"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux";
import { CancelButton } from "../../../../actions/CancelButton";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import format from "date-fns/format"
import { Calendar } from "react-date-range"
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import jwt_decode from 'jwt-decode';
import ModalFooter from '../../../shared/ModalFooter/ModalFooter'
import Modal from 'react-bootstrap/Modal'
import { DateFormat_dMY_Time } from '../../../../utils/Constants'
import DatePicker from '../../../../utils/DatePicker'

const AddNewCertification = (props) => {
    const { type } = { ...props }
    const { isOpenCertification, handleOnClose, handleOnCloseSave, ID, setIsRefresh } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    // else {
    //     EmployeeID = Token.UserID;
    // }

    let dispatch = useDispatch();
    let Certification_Modal = {
        // SkillID: "1",
        CertificationName: "",
        Institute: "",
        GrantedOn: null,
        ValidTill: null,
        Remarks: ""
    }

    let valModal = {}
    let ValidationModal = {
        CertificationName: "",
        Institute: "",
    }

    const [CertificationValidationModal, setCertificationValidationModal] = useState(ValidationModal)
    const [delID, setDelID] = useState(0)
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [CertificationSaveUpdateModal, setCertificationSaveUpdateModal] = useState({})
    const [mainCertificationModal, setmainCertificationModal] = useState(Certification_Modal);
    const API_URL = process.env.REACT_APP_API
    const [CertificationID, setCertificationID] = useState();
    // const [searchParam, setSearchParam] = useState();
    // const [EnterParam, setEnterParam] = useState();
    const [isLoading, setIsLoading] = useState(false)
    // const [isClose, setIsClose] = useState()
    const [dateValidation, setDateValidation] = useState({
        GrantedOn: '',
        ValidTill: '',
    })

    const [grantedOn, setGrantedOn] = useState();
    const [validTill, setValidTill] = useState();
    const openGrantedOn = Boolean(grantedOn);
    const openValidTill = Boolean(validTill);
    // const [isOpenCertification, setIsOpenCertification] = useState(false);
    const handleCalendarChange = (date, type) => {
        var FormatedDateShow = format(date, "MM/dd/yyyy")
        var FormatedDateSave = format(date, DateFormat)
        if (type === 'GrantedOn') {
            if (mainCertificationModal.ValidTill && new Date(FormatedDateShow) < new Date(mainCertificationModal.ValidTill)) {
                mainCertificationModal['GrantedOn'] = FormatedDateShow
                CertificationSaveUpdateModal['GrantedOn'] = FormatedDateSave
                setDateValidation({ ...dateValidation, GrantedOn: '' })
            }
            else if (!mainCertificationModal.ValidTill) {
                mainCertificationModal['GrantedOn'] = FormatedDateShow
                CertificationSaveUpdateModal['GrantedOn'] = FormatedDateSave
                setDateValidation({ ...dateValidation, GrantedOn: '' })
            }
            else {
                setDateValidation({ ...dateValidation, GrantedOn: 'error' })
            }
            // setShowCalendar({GrantedOn: false})
        }
        else if (type === 'ValidTill') {
            if (mainCertificationModal.GrantedOn && new Date(FormatedDateShow) > new Date(mainCertificationModal.GrantedOn)) {
                mainCertificationModal['ValidTill'] = FormatedDateShow
                CertificationSaveUpdateModal['ValidTill'] = FormatedDateSave
                setDateValidation({ ...dateValidation, ValidTill: '' })
            }
            else if (!mainCertificationModal.GrantedOn) {
                mainCertificationModal['ValidTill'].ValidTill = FormatedDateShow
                CertificationSaveUpdateModal['ValidTill'] = FormatedDateSave
                setDateValidation({ ...dateValidation, ValidTill: '' })
            }
            else {
                setDateValidation({ ...dateValidation, ValidTill: 'error' })
            }
            // setShowCalendar({ValidTill: false})
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setGrantedOn(null)
        setValidTill(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'GrantedOn') { setGrantedOn(event.currentTarget) }
        else if (type === 'ValidTill') { setValidTill(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    const handleCertificationChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainCertificationModal };
        let saveModal = { ...CertificationSaveUpdateModal }
        modal[name] = value
        setmainCertificationModal(modal)
        for (var property in Certification_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setCertificationSaveUpdateModal(saveModal)
    }
    const CertificationValidation = async () => {
        let ValidationModal = {

            CertificationName: FormValidator(
                mainCertificationModal.CertificationName,
                enumUtil.enumValidationType.Required,
                'Certification Name',
            ),
            Institute: FormValidator(
                mainCertificationModal.Institute,
                enumUtil.enumValidationType.Required,
                'Institute',
            ),
        }

        await setCertificationValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleSaveCertification = async () => {
        await CertificationValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setCertificationSaveUpdateModal(CertificationSaveUpdateModal)
            CertificationSaveUpdateModal["EmployeeID"] = EmployeeID + "";
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Certification,
                ID: CertificationID,
                data: { ...CertificationSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setCertificationID(response.id)
                    setIsRefresh(true)
                    handleOnCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsRefresh(false)
            })
        }
    }
    ////////////////////////
    useEffect(() => {
        if (!isNull(ID)) {
            setCertificationID(ID)
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Certification
            }).then((response) => {
                setmainCertificationModal(response);
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [CertificationID])


    return (
        <Modal show={isOpenCertification} onHide={isOpenCertification} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_new_des add_new_growth add_new_skill">
            <div className="education_popup">
                {isLoading ? <Loader /> : null}
                <img className="cursor-pointer" src={close} onClick={() => handleOnClose()}></img>
                <div className="text-center">
                    <span className="icon">
                        <div className="certification_icon">
                            <i className="icon-Icon-awesome-certificate"></i>
                        </div>
                    </span>
                    <p className="heading mt-2">Add New Certification</p>
                </div>
                <div>
                    <div className={CertificationValidationModal.CertificationName ? "form-group input error" : "form-group input"}>
                        <label>Title<span className='req'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="CertificationName"
                            id="CertificationName"
                            maxLength={50}
                            value={mainCertificationModal.CertificationName}
                            onChange={handleCertificationChange}
                        />
                        {CertificationValidationModal.CertificationName}
                    </div>
                    <div className={CertificationValidationModal.Institute ? "form-group input error" : "form-group input"}>
                        <label>Institute<span className='req'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="Institute"
                            id="Institute"
                            maxLength={50}
                            value={mainCertificationModal.Institute}
                            onChange={handleCertificationChange}
                        />
                        {CertificationValidationModal.Institute}
                    </div>
                    <div className={`form-group input ${dateValidation.GrantedOn}`}>
                        <DatePicker
                            label='Granted On'
                            maxDate={new Date()}
                            value={mainCertificationModal.GrantedOn}
                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'GrantedOn')}
                            anchorEl={grantedOn}
                            open={openGrantedOn}
                            closeCalendarMenufunc={closeCalendarMenu}
                            handleCalendarChangefunc={date => handleCalendarChange(date, 'GrantedOn')}
                            placeholder={true}
                        />
                        {dateValidation.GrantedOn === 'error' ? <span className='text-error'>Granted On Date must be less than Valid Till Date </span> : ''}
                    </div>
                    <div className={`form-group input ${dateValidation.ValidTill}`}>
                        <DatePicker
                            label='Valid Till'
                            value={mainCertificationModal.ValidTill}
                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'ValidTill')}
                            anchorEl={validTill}
                            open={openValidTill}
                            closeCalendarMenufunc={closeCalendarMenu}
                            handleCalendarChangefunc={date => handleCalendarChange(date, 'ValidTill')}
                            placeholder={true}
                        />
                        {dateValidation.ValidTill === 'error' ? <span className='text-error'>Valid Till Date must be greater than Granted On Date </span> : ''}
                    </div>
                    <div className="form-group input">
                        <label>Current Status</label>
                        <input
                            className="form-control"
                            name="Remarks"
                            id="Remarks"
                            value={mainCertificationModal.Remarks}
                            onChange={handleCertificationChange}
                        />
                    </div>
                    <div className="d-flex justify-content-end gap-3 ">
                        <button class="btn btn-gray" onClick={() => handleOnClose()}>Cancel</button>
                        <button class="btn btn-blue" onClick={() => handleSaveCertification()}>Save</button>
                    </div>
                </div>
            </div>
        </Modal>

    )
}

export default AddNewCertification
