import React from 'react'

const AmountFormatter = (props) => {
    let {val} = {...props};
    let data = parseFloat(val).toLocaleString('ur-PK',{ style: 'currency', currency: 'PKR' });
  return (
    <div>{data}</div>
  )
}

export default AmountFormatter