import React, { useState } from 'react';
import Chart from 'react-apexcharts';
// import useSidebarSubMainTab from '../../../shared/HandleOpenSidebarSubTab/useSidebarSubMainTab';

const BarChart = ({seriesStraightBarData,type}) => {
  console.log("seriesStraightBarData",seriesStraightBarData)
  let colors = {Permanent :'#FD8458', Consultant: '#E04CFF',PartTime :'#724CFB', Contract :'#FF6262', Intern :'#4391ED'};
  let Colors = [];
  seriesStraightBarData.map(item => {
    let res = colors[item.JobStatus] || '#724CFB'
    Colors.push(res)
  })
  let options = {
      chart: {
        type: 'bar',
        height: 50,
        stacked: true,
        stackType: '100%',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          show: true, 
          horizontal: true,
          borderRadius: 5,
          barHeight: 10,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        row: {
          show: false, // Set show to false to hide the horizontal grid lines
        },
      },
      colors: Colors,
      legend: {
        show: false, 
        // position: 'bottom',
        itemMargin: {
          horizontal: 9, // Adjust the horizontal margin between legend items
          vertical: 0, // Adjust the vertical margin between legend items
        },
        // markers: {
        //   fillColors: Colors,
        //   radius: 12,
        //   height: 6,
        //   width: 6,
        // },
        // labels: {
        //   colors: Colors,
        // },
        // itemMargin: {
        //   horizontal: 9, // Adjust the horizontal margin between legend items
        //   vertical: 0, // Adjust the vertical margin between legend items
        // },
      },
      fill: {
        opacity: 1,
      },
    };
  return (
  <div className="mx-2">
      <Chart 
      options={options} 
      series={seriesStraightBarData} 
      type="bar" 
      height={70} />
  </div>);
}

export default BarChart;