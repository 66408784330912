import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect, useDispatch } from 'react-redux';
import { TabAction } from '../../../../actions/HandleTabAction';
import { GoTo } from '../../../../actions/GotoPage';
import CustomUseRef from '../../../shared/CustomUseRef/CustomUseRef';
import { ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import NotificationHandler from '../../../../utils/NotificationHandler';
import Loader from '../../../shared/Loader/Loader';
import { enumUtil } from '../../../../utils/Enum';
import isNull from '../../../../utils/NullChecking';
import ModalFooter from '../../../shared/ModalFooter/ModalFooter';

const ExploreTeam = (props) => {
    const { onClose, ID, setReloadData, isOpenTeamModal } = { ...props }

    let Team_Modal = {
        TeamName: '',
        MemberCount: '',
    }

    const API_URL = process.env.REACT_APP_API
    const [teamsList, setTeamsList] = useState([])
    const [showTeam, setShowTeam] = useState();
    const [showOptions, setShowOptions] = useState({})
    const [teamDetails, setTeamDetails] = useState(Team_Modal)
    // const [isLoading, setIsLoading] = useState(false)
    // const API_URL = process.env.REACT_APP_API
    let dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [actionsbutton, setActionsbutton] = useState(false)
    let teamLead = teamsList?.filter(item => {
        return item.DesignationName === "Team Lead"
    })

    const OpenEmployee = (data) => {
        // setIsOpenTeamModal(false);
        console.log("DATA", data)
        onClose()
        let obj = {
            key: 2,
            name: "Employee",
            active: true,
            data: {
                id: data.EmployeeID,
                FirstName: data.EmployeeName,

            },
        };
        props.TabAction(obj);
        // dispatch(GoTo(21));
    }

    const handeleRemoveTeamMember = (id) => {
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + '/RemoveTeamMember', "post", {
            "TableName": "JoiningDetails",
            "ID": id
        })
            .then((response) => {
                setIsLoading(false);
                // setIsOpenTeamModal(false);
                setShowOptions(false);
                setShowTeam();
                setReloadData(id)
            })
            .catch((error) => {
                setIsLoading(false);
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
            });
    };

    const handleMenu = (e, emp) => {
        e.stopPropagation();
        setActionsbutton(true);
        setSelectedRow(emp?.EmployeeID);
    }

    const CloseActionPopup = () => {
        setActionsbutton(false);
    }
    const FindByID = (id) => {
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + '/GetTeamMember', "post", {
            "TableName": "Team",
            "SearchCriteria": {
                "TeamID": id + "",
            }
        })
            .then((response) => {
                setIsLoading(false);
                setTeamsList(response)
                setTeamDetails({ ...teamDetails, TeamName: response[0]?.TeamName, MemberCount: response[0]?.MemberCount })
            })
            .catch((error) => {
                setIsLoading(false);
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
            });
    };

    useEffect(() => {
        if (!isNull(ID)) {
            FindByID(ID)
        }
    }, [ID])
    return (
        <Modal show={isOpenTeamModal} onHide={isOpenTeamModal} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal ar_team-popup">
            <Modal.Body>
                {isLoading ? <Loader /> : <></>}
                <div className='text-end mb-3 px-10'>
                    <i className='icon-Vector7 fs-12' onClick={() => onClose()}></i>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-2 px-10'>
                    <div className='d-flex gap-2 align-items-center'>
                        <p className='fs-11 text clr_gry'>Team:</p>
                        <p className='heading'>{teamDetails.TeamName}</p>
                    </div>
                    {/* <div className='d-flex gap-2 align-items-center'>
                        <p className='fs-11 text clr_gry'>Team Lead:</p>
                        <p className='heading'>{teamLead[0]?.EmployeeName || 'N/A'}</p>
                    </div> */}
                    <div className='d-flex gap-2 align-items-center'>
                        <p className='fs-11 text clr_gry'>Members:</p>
                        <p className='heading'>{teamDetails.MemberCount}</p>
                    </div>
                </div>
                <div className='ar_hov-scrollbar px-10 scroll'>
                    {teamsList &&
                        <>
                            {teamsList?.map((teamMember, index) => (
                                <>

                                    {/* {isLoading ? <Loader /> : <></>} */}
                                    <div className="py-2">
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="img-container circle border-2  s-40">
                                                {teamMember?.ProfilePicture ?
                                                    <img src={teamMember?.ProfilePicture || 'https://www.w3schools.com/howto/img_avatar.png'}></img>
                                                    :
                                                    <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-16 text-white ${teamMember?.Gender === 'M' ? 'male' : teamMember?.Gender === 'FM' ? 'female' : 'rather-undefined'}`}>{teamMember?.EmployeeName?.split(' ')[0]?.split('')[0]}{teamMember?.EmployeeName?.split(' ')[1]?.split('')[0]}</p>}
                                            </div>
                                            <div>
                                                <p className="heading clr_drk-blue cursor-pointer" onClick={() => { OpenEmployee(teamMember); }}>{teamMember?.EmployeeName}</p>
                                                <p className="text fs-11 font-m clr_light " title="">
                                                    {teamMember?.DesignationName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex">
                                            <div className='d-flex flex-column align-items-center px-2'>
                                                <div className="img-container circle border-2  s-60">
                                                    {teamMember?.ProfilePicture ?
                                                        <img src={teamMember?.ProfilePicture || 'https://www.w3schools.com/howto/img_avatar.png'}></img>
                                                        :
                                                        <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-16 text-white ${teamMember?.Gender === 'M' ? 'male' : teamMember?.Gender === 'FM' ? 'female' : 'rather-undefined'}`}>{teamMember?.EmployeeName?.split(' ')[0]?.split('')[0]}{teamMember?.EmployeeName?.split(' ')[1]?.split('')[0]}</p>}
                                                </div>
                                                <p className='heading text-center'>{teamMember?.EmployeeName}</p>
                                                <p className='text fs-11 font-m clr_light validation_message office_name_truncate text-center' title="">{teamMember?.DesignationName}</p>
                                            </div>
                                            <p className="icon mb-0 position-relative">
                                                <i className="icon-menuicon cursor-pointer" onClick={(e) => handleMenu(e, teamMember)}></i>
                                                {selectedRow === teamMember?.EmployeeID &&
                                                    <CustomUseRef IsActivePtPayments={actionsbutton} onClose={CloseActionPopup} >
                                                        <div className="actions_popup cursor-pointer w-fit-content">
                                                            <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={() => { OpenEmployee(teamMember); }}><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                                                            <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={() => { handeleRemoveTeamMember(teamMember?.EmployeeID) }}><i className="icon-icon-trash me-1 fs-10"></i>Remove from Team</p>
                                                        </div>
                                                    </CustomUseRef>
                                                }
                                            </p>
                                        </div> */}
                                    <div className='d-flex flex-wrap align-items-center gap-1 '>
                                        {JSON.parse(teamMember?.TeamMember)?.map((item) => (
                                            <TeamMember item={item} TabAction={props.TabAction} setReloadData={setReloadData} onClose={onClose} setSelectedRow={setSelectedRow} selectedRow={selectedRow}/>
                                            // <div className='hired-box '>
                                            //     {/* {isLoading ? <Loader /> : <></>} */}
                                            //     <div className="d-flex">
                                            //         <div className='d-flex flex-column align-items-center px-2'>
                                            //             <div className="img-container circle border-2  s-60">
                                            //                 {item?.ProfilePicture ?
                                            //                     <img src={item?.ProfilePicture || 'https://www.w3schools.com/howto/img_avatar.png'}></img>
                                            //                     :
                                            //                     <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-16 text-white ${item?.Gender === 'M' ? 'male' : item?.Gender === 'FM' ? 'female' : 'rather-undefined'}`}>{item?.EmployeeName?.split(' ')[0]?.split('')[0]}{item?.EmployeeName?.split(' ')[1]?.split('')[0]}</p>
                                            //                 }
                                            //             </div>
                                            //             <p className='heading text-center'>{item?.EmployeeName}</p>
                                            //             <p className='text fs-11 font-m clr_light validation_message office_name_truncate text-center' title="">{item?.DesignationName}</p>
                                            //         </div>
                                            //         <p className="icon mb-0 position-relative">
                                            //             <i className="icon-menuicon cursor-pointer" onClick={(e) => handleMenu(e, item)}></i>
                                            //             {selectedRow === item?.EmployeeID &&
                                            //                 <CustomUseRef IsActivePtPayments={actionsbutton} onClose={CloseActionPopup} >
                                            //                     <div className="actions_popup cursor-pointer w-fit-content">
                                            //                         <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={() => { OpenEmployee(item); }}><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                                            //                         <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={() => { handeleRemoveTeamMember(item.EmployeeID) }}><i className="icon-icon-trash me-1 fs-10"></i>Remove from Team</p>
                                            //                     </div>
                                            //                 </CustomUseRef>
                                            //             }
                                            //         </p>
                                            //     </div>
                                            // </div>
                                        ))}
                                    </div>

                                </>
                            ))}
                        </>
                    }
                    {/* <div className='hired-box '>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex flex-column align-items-center px-2'>
                                    <div className="img-container circle border-2  s-60">
                                        <img src='https://www.w3schools.com/howto/img_avatar.png'></img>
                                    </div>
                                    <p className='heading'>ISRAH ABBASI</p>
                                    <p className='text fs-11 font-m clr_light' title="">UI/UX Designer</p>
                                </div>
                                <p className="icon mb-0 position-relative">
                                    <i className="icon-menuicon cursor-pointer " onClick={() => setShowOptions(!showOptions)}></i>
                                    {showOptions &&
                                        <div className="actions_popup cursor-pointer w-fit-content">
                                            <p className="text  p-2 text-nowrap d-flex align-items-center"><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                                            <p className="text  p-2 text-nowrap d-flex align-items-center"><i className="icon-icon-trash me-1 fs-10"></i>Remove from Team</p>
                                        </div>
                                    }
                                </p>
                            </div>
                        </div> */}
                </div>
            </Modal.Body>
        </Modal>
    )
}

function mapStateToPropss(state) {
    return {
    };
}

const action = {
    TabAction,
};

export default connect(mapStateToPropss, action)(ExploreTeam);

const TeamMember = ({ item, TabAction,  setReloadData, onClose, setSelectedRow, selectedRow }) => {
    const API_URL = process.env.REACT_APP_API
    let dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [actionsbutton, setActionsbutton] = useState(false);

    const OpenEmployee = (data) => {
        onClose()
        let obj = {
            key: 2,
            name: "Employee",
            active: true,
            data: {
                id: data.EmployeeID,
                FirstName: data.EmployeeName,

            },
        };
        TabAction(obj);
    }

    const handeleRemoveTeamMember = (id) => {
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + '/RemoveTeamMember', "post", {
            "TableName": "JoiningDetails",
            "ID": id
        })
            .then((response) => {
                setIsLoading(false);
                // setIsOpenTeamModal(false);
                // setShowOptions(false);
                // setShowTeam();
                setReloadData(id)
            })
            .catch((error) => {
                setIsLoading(false);
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
            });
    };

    const handleMenu = (e, emp) => {
        e.stopPropagation();
        setActionsbutton(true);
        setSelectedRow(emp?.EmployeeID);
    }

    const CloseActionPopup = () => {
        setActionsbutton(false);
    }
    return (
        <div className='hired-box '>
            {/* {isLoading ? <Loader /> : <></>} */}
            <div className="d-flex">
                <div className='d-flex flex-column align-items-center px-2'>
                    <div className="img-container circle border-2  s-60">
                        {item?.ProfilePicture ?
                            <img src={item?.ProfilePicture || 'https://www.w3schools.com/howto/img_avatar.png'}></img>
                            :
                            <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-16 text-white ${item?.Gender === 'M' ? 'male' : item?.Gender === 'FM' ? 'female' : 'rather-undefined'}`}>{item?.EmployeeName?.split(' ')[0]?.split('')[0]}{item?.EmployeeName?.split(' ')[1]?.split('')[0]}</p>
                        }
                    </div>
                    <p className='heading text-center'>{item?.EmployeeName}</p>
                    <p className='text fs-11 font-m clr_light validation_message office_name_truncate text-center' title="">{item?.DesignationName}</p>
                </div>
                <p className="icon mb-0 position-relative">
                    <i className="icon-menuicon cursor-pointer" onClick={(e) => handleMenu(e, item)}></i>
                    {selectedRow === item?.EmployeeID &&
                        <CustomUseRef IsActivePtPayments={actionsbutton} onClose={CloseActionPopup} >
                            <div className="actions_popup cursor-pointer w-fit-content">
                                <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={() => { OpenEmployee(item); }}><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                                <p className="text  p-2 text-nowrap d-flex align-items-center" onClick={() => { handeleRemoveTeamMember(item.EmployeeID) }}><i className="icon-icon-trash me-1 fs-10"></i>Remove from Team</p>
                            </div>
                        </CustomUseRef>
                    }
                </p>
            </div>
        </div>
    )
}