import React, { useEffect, useState } from 'react'
import { enumUtil } from '../../../../utils/Enum';
import { ServerRequestProtected } from '../../../../utils/ServerDataRequest';
import EmployeeLeaveAdmin from '../../../Leaves/EmployeeLeaveAdmin';
import TimeAdjustmentAdmin from '../../../MyAttendance/TimeAdjustment/TimeAdjustmentAdmin';
import ApproveConfirmation from '../../../shared/Approve/ApproveConfirmation';
import DenyConfirmation from '../../../shared/Deny/DenyConfirmation';
import NoDataFound from './NoDataFound';
import LetterProfilePicture from '../shared/LetterProfilePicture';
import { isFemale, isMale } from '../../../../utils/Constants';

const NewRequests = () => {
    const API_URL = process.env.REACT_APP_API
    const API_URL_DASHBOARD = process.env.REACT_APP_API_Dashboard;
    const [requestsPeriodData, setRequestsPeriodData] = useState([]);
    const [requestsPeriod, setRequestsPeriod] = useState("Month");

    const [requestType, setRequestType] = useState({})
    const [approveID, setApproveID] = useState(0);
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [denyID, setDenyID] = useState(null);
    const [isOpenDeny, setIsOpenDeny] = useState(false)
    const [requestStatus, setRequestStatus] = useState({})
    const [request, setRequest] = useState()

    const handleRequestChange = (e) => {
        setRequestsPeriod(e.target.value);
    }

    const LoadData = () => {
        setRequestsPeriodData([]);
        // setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.Dashboard,
            "SearchParam": "Request",
            "SearchCriteria": {
                "RequestBy": requestsPeriod
            }
        }
        ServerRequestProtected(API_URL_DASHBOARD + '/GetRequest', "post", saveModal
        ).then((response) => {
            // setIsLoading(false);
            setRequestsPeriodData(response);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            // setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })
    }
    useEffect(() => {
        LoadData();
    }, [requestsPeriod])

    const handleRequestClick = (e, item) => {
        // console.log("handleRequestClick",e.target.innerHTML,"item",item)
    }

    const handleApproveModal = (item, type) => {
        setRequestType(type)
        setApproveID(item.ID);
        setIsOpenApprove(true);
        let req =
            type === "Leave" ?
                { RequestStatus: "Approved" } :
                type === "Time Adjustment" ?
                    {
                        "AttandanceID": item?.AttandanceID,
                        "CheckOutRequestID": item?.CheckOutRequestID + "",
                        "CheckInRequestID": item?.CheckInRequestID + "",
                        "Message": item?.Message,
                        "RequestedDate": item?.RequestedDate,
                        "CheckInTime": item?.CheckInTime,
                        "CheckOutTime": item?.CheckOutTime,
                        "EmployeeID": item?.EmployeeID,
                        "RequestStatus": "Approved"
                    } :
                    { RequestStatus: "Approved" };
        setRequestStatus(req);
    }

    const handleDenyModal = (item, type) => {
        setRequestType(type)
        setDenyID(item.ID);
        setIsOpenDeny(true);
        let req =
            type === "Leave" ?
                { RequestStatus: "Dennied" } :
                type === "Time Adjustment" ?
                    {
                        "AttandanceID": item?.AttandanceID,
                        "CheckOutRequestID": item?.CheckOutRequestID + "",
                        "CheckInRequestID": item?.CheckInRequestID + "",
                        "Message": item?.Message,
                        "RequestedDate": item?.RequestedDate,
                        "CheckInTime": item?.CheckInTime,
                        "CheckOutTime": item?.CheckOutTime,
                        "EmployeeID": item?.EmployeeID,
                        "RequestStatus": "Dennied"
                    } :
                    { RequestStatus: "Dennied" };
        setRequestStatus(req);
    }
    const onCloseApprove = () => {
        setApproveID(0);
        setRequest("Approved")
        setIsOpenApprove(false)
        // onClose()
    }
    const onApprovalClose = () => {
        setIsOpenApprove(false)
    }
    const onCloseDeny = () => {
        setDenyID(0);
        setRequest("Dennied")
        setIsOpenDeny(false)
        // onClose()
    }
    const onDenyClose = () => {
        setIsOpenDeny(false)
    }

    let ApprovePopup = "";
    if (isOpenApprove) {
        ApprovePopup = <ApproveConfirmation isOpenAlert={isOpenApprove} onCloseDelete={onCloseApprove} API_URL={API_URL}
            TableName={
                requestType === 'Leave' ? enumUtil.enumAPITableName.EmployeeLeave :
                    requestType === 'Time Adjustment' ? enumUtil.enumAPITableName.EmployeeTimeAdjustment :
                        enumUtil.enumAPITableName.REQUEST}
            ID={approveID} LeaveStatus={requestStatus} onClose={onApprovalClose} LoadData={LoadData} />
    }
    let DenyPopup = "";
    if (isOpenDeny) {
        DenyPopup = <DenyConfirmation isOpenAlert={isOpenDeny} onCloseDelete={onCloseDeny} API_URL={API_URL}
            TableName={
                requestType === 'Leave' ? enumUtil.enumAPITableName.EmployeeLeave :
                    requestType === 'Time Adjustment' ? enumUtil.enumAPITableName.EmployeeTimeAdjustment :
                        enumUtil.enumAPITableName.REQUEST}
            ID={denyID} LeaveStatus={requestStatus} onClose={onDenyClose} LoadData={LoadData} />
    }
    return (
        <div className='card_tabs new_request mb-2 w-100'>
            {ApprovePopup}
            {DenyPopup}
            <div className='heading d-flex justify-content-between align-items-center pb-3 px-2'>
                <p className='heading'>New Requests</p>
                <div class="input">
                    <select class="form-control" id="RequestsPeriod" name="RequestsPeriod" value={requestsPeriod} onChange={handleRequestChange}>
                        <option value="Week" >This Week</option>
                        <option value="Month">This Month</option>
                    </select>
                </div>
            </div>
            <div className='d-flex flex-column row-gap-15 scroll ar_hov-scrollbar px-2 '>
                {requestsPeriodData?.filter(item => item.RequestStatus === "Pending")?.length > 0 ?
                    requestsPeriodData?.map(item => {
                        if (item.RequestStatus === "Pending") {
                            return (
                                <div className='aprroval-box' >
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex gap-1 align-items-center me-2'>
                                            <div className="img-container circle border-2 border-white s-50">
                                                {item.ProfilePicture ? (
                                                    <img src={item.ProfilePicture} alt="Profile" />
                                                ) : (
                                                    <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-20 text-white  ${isMale(item.Gender) ? 'male' : isFemale(item.Gender) === 'F' ? 'female' : 'rather-undefined'}`} name={item.EmployeeName} />
                                                )}
                                            </div>
                                            <div>
                                                <p className='heading font-m fs-11'>{item.EmployeeName}</p>
                                                <p className='text fs-10'>{item.Type}</p>
                                                <p className='text fs-10 clr_gry validation_message'>{item.Description}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column gap-2 fs-20'>
                                            {/* <>
                                        <p className='deny text fs-11 font-m text-center cursor-pointer' onClick={() => handleDenyModal(item,item.Type)}>Deny</p>
                                        <p className='approve text fs-11 font-m text-center cursor-pointer' onClick={() => handleApproveModal(item,item.Type)}>Approve</p>
                                        </> */}
                                            <i className="icon-x-circle" onClick={() => handleDenyModal(item, item.Type)}></i>
                                            <i className="icon-check-circle1" onClick={() => handleApproveModal(item, item.Type)}></i>

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }) :
                    <div className="position-relative" style={{ height: 160 }}>
                        <NoDataFound />
                    </div>}
            </div>
        </div>
    )
}

export default NewRequests