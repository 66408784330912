import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

function DataTableMUI(props) {
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [value, setValue] = React.useState('');

   const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);

   return (
      <div>
         <div className='app-datagrid' style={{ height: '514px', width: '100%' }}>
            {console.log("-----",props.rows)}
            <DataGrid
               disableVirtualization
               // pageSize={10}
               // autoHeight={true}
               getRowId={(rows) => rows.RecordID}
               rows={props.rows}
               columns={props.columns}
               hideFooter={true}
               slotProps={{
                  cell: {
                      onMouseEnter: handlePopoverOpen,
                      onMouseLeave: handlePopoverClose,
                  },
              }}
            //checkboxSelection
            />

            <Popover
               sx={{
                  pointerEvents: 'none',
               }}
               open={open}
               anchorEl={anchorEl}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               onClose={handlePopoverClose}
            >
               <Typography sx={{ p: 1 }}>{`characters.`}</Typography>
            </Popover>
         </div>
      </div>

   )
}

export default DataTableMUI