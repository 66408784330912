import React, { useEffect, useState } from "react"
import MenuItemsList from "./Menu"
import { TabContent, TabPane } from 'reactstrap'
import { useSelector } from "react-redux"

const MainContent = (props) => {
    const Role = useSelector(state=>state.RoleReducer);
    let Sub_Role = useSelector((state) => state.SubRoleReducer)
    const MenuItems = MenuItemsList(Role);
    const [tabs, setTabs] = useState([]);
    const [component, setComponent] = useState({});
    useEffect(() => {
        setTabs(props.dynamicTabs)
        const activeComponent = MenuItems.filter((tab, key) => {
            return tab.right === true
        })[0]
        console.log("ACTIVECOMPONENT",activeComponent,Sub_Role);
        setComponent(activeComponent)
    }, [props.activeTab,Sub_Role])
    return (
        <>
            {tabs.length > 0 ? (
                <>
                    <TabContent activeTab={props.activeTab}>
                        {tabs &&
                            tabs.map((item) => {
                                return (
                                    <React.Fragment key={item.key}>
                                        <TabPane  tabId={item.key}>{item.component}</TabPane>
                                    </React.Fragment>
                                )
                            })}
                    </TabContent>
                </>
            ) : (
                <TabContent activeTab={component.key}>
                    <React.Fragment key={component.key}>
                        <TabPane tabId={component.key}>{component.component}</TabPane>
                    </React.Fragment>
                </TabContent>
            )}
        </>
    )
}

export default MainContent