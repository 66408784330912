import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import FormValidator from "../../utils/FormValidator"
import NotificationHandler from "../../utils/NotificationHandler"
import { enumUtil } from "../../utils/Enum"
import { ServerRequestProtected } from "../../utils/ServerDataRequest"
import Loader from "../shared/Loader/Loader"
import { ConstructionOutlined, Email, Password } from "@mui/icons-material";
import { Link } from "react-router-dom";


const ChangePassword = (props) => {
    const AUTHENTICATE_API_URL = process.env.REACT_APP_AUHTENTICATE
    let navigate = useNavigate();
    let changepassword_Modal={
        Password:"",
        ConfirmPassword:"",
    }
    let valModal = {}

    let ValidationModal = {
        PasswordVal:"",
        PasswordDigitsVal:"",
        ConfirmPasswordVal:"",
        ConfirmPasswordDigitsVal:"",
    }
    const [isLoading, setIsLoading] = useState(false)
    const [mainchangepasswordModal, setmainchangepasswordModal] = useState(changepassword_Modal);
    const [changepasswordSaveUpdateModal, setchangepasswordSaveUpdateModal] = useState({});
    const [changepasswordValidationModal, setchangepasswordValidationModal] = useState(ValidationModal)
    const [comparepasswordValidation ,setComparepasswordValidation] = useState()
    const [hidePwd,setHidePwd] = useState(true);
    const [hideCnfrmPwd,setHideCnfrmPwd] = useState(true);
   
    const handlechangepasswordChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainchangepasswordModal };
        let saveModal = { ...changepasswordSaveUpdateModal }
        modal[name] = value
        setmainchangepasswordModal(modal)    
        for (var property in changepassword_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setchangepasswordSaveUpdateModal(saveModal)
    }

    const changepasswordValidation = async () => {
        let ValidationModal = {
            PasswordVal: FormValidator(
                mainchangepasswordModal.Password,
                enumUtil.enumValidationType.Required,
                'Password',
            ),
            PasswordDigitsVal: FormValidator(
                mainchangepasswordModal.Password,
                enumUtil.enumValidationType.Password,
                'Password',
            ),
            ConfirmPasswordVal: FormValidator(
                mainchangepasswordModal.ConfirmPassword,
                enumUtil.enumValidationType.Required,
                'Confirm Password',
            ),
            ConfirmPasswordDigitsVal: FormValidator(
                mainchangepasswordModal.ConfirmPassword,
                enumUtil.enumValidationType.Password,
                'Confirm Password',
            ),
        }
        await setchangepasswordValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const ComparepasswordValidation = async () => {
        if( mainchangepasswordModal.Password!==mainchangepasswordModal.ConfirmPassword){
            await setComparepasswordValidation(true)
            return true
        }
        else{ await setComparepasswordValidation(false)
            return false
        }
    }
    // const hideshow =() =>{
    //     console.log(hidePwd)
    //     setHidePwd(!hidePwd);
    // }
    const handleSubmit = async(event) => {
        await changepasswordValidation()
        let compareValidation =await ComparepasswordValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else if(compareValidation==false && validation==false){
            console.log("Accept")
        let data={...changepasswordSaveUpdateModal}    
        var url = window.location.href;
        const urlParts = url.split('?');
        const params = urlParts[1].split('&');

        var obj = {};
        for(var i in params){
            const keyValues = params[i].split('=');
            const newItemInput = keyValues[0];
            const newRadioValue = keyValues[1];
            obj [newItemInput] = newRadioValue;
        }
        if(obj) {
        setIsLoading(true)
        ServerRequestProtected(AUTHENTICATE_API_URL + "/ResetPassword", 'post',{
        // ServerRequestProtected("http://192.168.18.5:438/api/Authentication/ResetPassword", 'post',{
            ...data,...obj
        }).then((response) => {
            setIsLoading(false)
            event.preventDefault();
            navigate("../", { replace: true });
            NotificationHandler('Changed Successfully', enumUtil.enumtoaster.success)
            navigate("../resetsuccessfull", { replace: true })
        }).catch((error) => {
            setIsLoading(false)
            console.log('error', error.response.data);
            NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })}
    }
    }

    const handleCopy = (e) => {
        e.preventDefault();
      };

    return (
        <React.Fragment >
             {isLoading ? <Loader /> : null}
        <div className="login hk-login hk-np-view">
            <div className="d-lg-flex half">
                <div className="bg order-1 order-md-2">
                    <img src={process.env.PUBLIC_URL + "/assets/images/Login_EMS_animation.png"} alt="" />
                </div>
                <div className="contents order-2 order-md-1">
                    <h1 className="hk-title">Employee Management System</h1>
                    <div className="container mt-5">
                        <div className="row align-items-start justify-content-center">
                            <div className="col-md-7">
                                <div className="hk-t-img">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/lock-password.png"} alt="forget password icon" />
                                </div>
                                <h2 class="heading xl text-center">Set new password</h2>
                                <p className="text clr-muted text-center forget-p">Your new password must be different to previously used passwords</p>
                                <div className="mt-4">
                                    <div className={changepasswordValidationModal.PasswordVal?"form-group input mb-3 hk-password-field error": changepasswordValidationModal.PasswordDigitsVal ?"form-group input mb-3 hk-password-field error":"form-group input mb-3 hk-password-field"}>
                                        <label ><b>Password</b></label>
                                        <input type={hidePwd?"password":"text"}
                                            className="form-control"
                                            name="Password"
                                            id="Password"
                                            value={mainchangepasswordModal.Password}
                                            onChange={handlechangepasswordChange} 
                                            onCopy={handleCopy}
                                            />
                                        <div className="hk-eye-btn" 
                                            onMouseDown={()=>setHidePwd(false)}
                                            onMouseUp={()=>setHidePwd(true)}
                                            onMouseLeave={()=>setHidePwd(true)}
                                            >
                                            {hidePwd ? <i class="icon-eye-blocked"></i> : <i class="icon-eye"></i>}
                                        </div>
                                    </div>
                                    <div className={changepasswordValidationModal.PasswordVal?"form-group input mb-3 hk-password-field error": changepasswordValidationModal.PasswordDigitsVal ?"form-group input mb-3 hk-password-field error":"form-group input mb-3 hk-password-field"}>
                                        {changepasswordValidationModal.PasswordVal}
                                        {changepasswordValidationModal.PasswordDigitsVal}
                                    </div>
                                    <div className={changepasswordValidationModal.ConfirmPasswordVal?"form-group input mb-3 hk-password-field error": changepasswordValidationModal.ConfirmPasswordDigitsVal ?"form-group input mb-3 hk-password-field error":"form-group input mb-3 hk-password-field"}>
                                        <label><b>Confirm Password</b></label>
                                        <input type={hideCnfrmPwd?"password":"text"}
                                            className="form-control"
                                            name="ConfirmPassword"
                                            id="ConfirmPassword"
                                            value={mainchangepasswordModal.ConfirmPassword}
                                            onChange={handlechangepasswordChange} 
                                            />
                                        <div className="hk-eye-btn" 
                                            onMouseDown={()=>setHideCnfrmPwd(false)}
                                            onMouseUp={()=>setHideCnfrmPwd(true)}
                                            onMouseLeave={()=>setHideCnfrmPwd(true)}
                                            >
                                            {hideCnfrmPwd ? <i class="icon-eye-blocked"></i> : <i class="icon-eye"></i>}
                                        </div>
                                    </div>
                                    <div className={changepasswordValidationModal.ConfirmPasswordVal?"form-group input mb-3 hk-password-field error": changepasswordValidationModal.ConfirmPasswordDigitsVal ?"form-group input mb-3 hk-password-field error":"form-group input mb-3 hk-password-field"}>
                                        {changepasswordValidationModal.ConfirmPasswordVal}
                                        {changepasswordValidationModal.ConfirmPasswordDigitsVal}
                                    </div>
                                    <div className={comparepasswordValidation?"form-group input error":"form-group input "}>
                                        <label>{!changepasswordValidationModal.PasswordVal&&!changepasswordValidationModal.PasswordDigitsVal
                                        && !changepasswordValidationModal.ConfirmPasswordVal&&!changepasswordValidationModal.ConfirmPasswordDigitsVal
                                        && comparepasswordValidation?"Password and Confirm Password must be same":""}</label>
                                    </div>
                                    <input type="submit" value="Reset Password" className="btn rounded btn-blue w-100 mt-3" onClick={handleSubmit}/>
                                </div>
                                <a className="d-block text text-center inst-txt mt-4 text-decoration-none" onClick={()=>navigate("../", { replace: true })}><span class="icon-Icon-ionic-md-arrow-round-back"></span> Back to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}
export default ChangePassword