// import React, { useState,useEffect } from "react";
// import ContextMenu from 'react-jsx-context-menu'
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css";

// import FormValidator from "../../utils/FormValidator"
// import Loader from "../shared/Loader/Loader"
// import isNull from "../../utils/NullChecking";


// import { useSelector } from "react-redux";
// import { ServerRequestProtectedParentID } from "../../utils/ServerDataRequest"
// import NotificationHandler from "../../utils/NotificationHandler"
// import { enumUtil } from "../../utils/Enum"


// const localizer = momentLocalizer(moment)


const Miscellaneous = () => {

//     let Attendence_Modal = {
//       title: "",
//       TodayDate:"",
//     }
//     let valModal = {}
//     let ValidationModal = {
//       title:"",
//     }
//     const API_URL = process.env.REACT_APP_API
//     const [isLoading, setIsLoading] = useState(false)
//     const [AttendenceValidationModal, setAttendenceValidationModal] = useState(ValidationModal)
//     const [newEvents, setnewEvents] = useState({ title: "", start: "", end: ""})
//     const [allEvents, setallEvents] = useState([{title: "", start: "", end: ""}])
//     const [isOpenEvent, setisOpenEvent] = useState(false)
//     const [attendence,setAttendence]=useState(false)
//     let [StartDate, setStartDate] = useState(null);
//     let [EndDate, setEndDate] = useState(null);
//     const [AttendenceSaveUpdateModal, setAttendenceSaveUpdateModal] = useState({})
//     const [mainAttendenceModal, setmainAttendenceModal] = useState(Attendence_Modal);
//     let EmployeeID = useSelector(state => state.EmployeeActiveTabReducer);
   
//     const LoadData = () => {
//       //setAttendenceData([])
//       setIsLoading(true);
//       ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", 'post', {
//           "TableName": enumUtil.enumAPITableName.EmployeeAttendence,
//           "ParentID":  "2",
//           "SearchParam" : "",
//           "PageNo" : "1",
//           "PerPage": "200" 
//       }).then((response) => {
//           setIsLoading(false);
//           const evt = response.map(event => ({
//           start: event.TodayDate,
//           end: event.TodayDate,
//           title: event.title+""
//         }))
//           setallEvents(evt)
//       }).catch((error) => {
//           NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
//           setIsLoading(false);
//       })

//     }
//       useEffect(() => {
//         LoadData();
//       }, [attendence])

//       const AttendenceValidation = async () => {
//         let ValidationModal = {
//             title: FormValidator(
//                 mainAttendenceModal. title,
//                 enumUtil.enumValidationType.Required,
//                 'Title',
//             ),
//         }
//         await setAttendenceValidationModal(ValidationModal)
//         valModal = ValidationModal
//     }

//     const handleChangeStart = (start) => {
//       start.setTime(start.getTime()-start.getTimezoneOffset()*60*1000)
//       setnewEvents({ ...newEvents, start })
//       setStartDate(start)
//     }

//     const handleChangeEnd = (end) => {
//       end.setTime(end.getTime()-end.getTimezoneOffset()*60*1000)
//       setnewEvents({ ...newEvents, end })
//       setEndDate(end)
  
//     }
//     const handleSaveAttendence = async () => {
//       await AttendenceValidation()
//       setAttendence(false)
//       let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
//       if (validation) {
//           NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
//           return
//       } else {
//           if(StartDate===null || ""){}
//           else{
//               StartDate.setTime(StartDate.getTime()-StartDate.getTimezoneOffset()*60*1000 )
//           }
//           if(EndDate===null || ""){}
//           else{
//             EndDate.setTime(EndDate.getTime()-EndDate.getTimezoneOffset()*60*1000 )
//           }
//           AttendenceSaveUpdateModal['TodayDate'] = StartDate;
//          // AttendenceSaveUpdateModal['TodayDate'] = EndDate;
//           AttendenceSaveUpdateModal['OrganizationID']= "11";
//           setAttendenceSaveUpdateModal(AttendenceSaveUpdateModal)
//           let saveModal = {
//               TableName: enumUtil.enumAPITableName.EmployeeAttendence,
//               ID : "0",
//               ParentID: "2",
//               value: null,
//               Data: { ...AttendenceSaveUpdateModal}  
//           }
//           setIsLoading(true)
//           ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
//               if (!isNull(response.id)) {
//                   setIsLoading(false)
//                   NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
//                   setisOpenEvent(false);
//                   mainAttendenceModal['title'] = "";
//                   setAttendence(true)
//               }
//           }).catch((error) => {
//               setIsLoading(false)
//               console.log('error', error);
//               NotificationHandler(error.message, enumUtil.enumtoaster.error)
//           })
//       }
//   }
//     const handleSlot = (e) => {
//       setStartDate(e.start)
//       setEndDate(e.end)
//       handleChangeStart(e.start)
//       handleChangeEnd(e.end)
//       setisOpenEvent(true);
//     }
//     const onClose = () => {
//       mainAttendenceModal['title'] = "";
//       setisOpenEvent(false);
//     }
//     const eventStyleGetter=(event) =>{
//       let evnt=(event.title.toLowerCase());
//       switch (evnt) {
//         case 'present':
//             var style = {
//                 opacity: 0.8,
//                 backgroundColor:'#D1EC78',
//                 color: 'black',
//                 border: '0px',
//                 display: 'block',
//                 paddingBottom:'8px',
//                 textAlign: 'center',
//                 width:'70%',
//                 height:'80%',
//                 opacity: 0.9,
//             };
//             return {
//                 style: style
//             };
  
//           case 'absent':
//             var style = {
//                 opacity: 0.8,
//                 backgroundColor:'#F95C47',
//                 color: 'white',
//                 border: '0px',
//                 display: 'block',
//                 paddingBottom:'8px',
//                 textAlign: 'center',
//                 width:'70%',
//                 height:'80%',
//                 opacity: 0.9,
//             };
//             return {
//                 style: style
//             };

//           default:
//             var style = {
//             backgroundColor:'black',
//             color:'white',
//             border: '0px',
//             display: 'block',
//             textAlign:'center',
//             borderRadius: '14px',
//             width:'50%',
//             height:'80%',
//             opacity: 0.9,     
//           };
//             return {
//                 style: style
//             };
//         // More cases here
//         }   
//     }
//     // Context menu
//    const handlePresentClick=(e)=>{
//     if(attendence){
//         setallEvents([...allEvents,  {
//           start: new Date,
//           end: new Date,
//           title: "Present"
          
//         }]);
//         setAttendence(false)
//         console.log(allEvents)
//         //setisOpenEvent(false)
//       }
//     }
//     const handleAbsentClick=()=>{
//       if(attendence){
//       setallEvents([...allEvents,  {
//         start: new Date,
//         end: new Date,
//         title: "Absent"
//       }]);
//       setAttendence(false)
//       console.log(allEvents)
//     }
//   }
//   ///
//      const handleAttendenceChange = (e) => {
//         let { name, value } = e.target;
//         let modal = { ...mainAttendenceModal };
//         let saveModal = { ...AttendenceSaveUpdateModal }
//         modal[name] = value
//         setmainAttendenceModal(modal)
//         for (var property in Attendence_Modal) {
//             if (name === property) {
//                 saveModal[name] = value
//             }
//         }
//         setAttendenceSaveUpdateModal(saveModal)
//     }
    
//         const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
//           <button className="example-custom-input" onClick={onClick} ref={ref}>
//             {value}
//           </button>
//         ));
  return (
    <>
    <h1>Misc.</h1>
      {/* <div className="re">
          <ContextMenu
          menu={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <button  
              style={{
                padding:'0px 30px',
                borderRadius : '3px',
                border:'0px'
              }} className="btn btn-lg btn-success solid"  onClick={handlePresentClick}>Present</button>
              <button 
              style={{
                padding:'0px 30px',
                borderRadius: '3px',
                border:'0px'
              }} 
              className="btn btn-lg btn-danger"   onClick={handleAbsentClick}>Absent</button>
            </div>
          }
        >  
          <div>
            <Calendar
            className="react-calendar"
            localizer={localizer}
            events={allEvents}
            defaultView='month'
            startAccessor={(event) => { return new Date(event.start) }}
            endAccessor={(event) => { return new Date(event.end) }}
            onSelectSlot={handleSlot}
            selectable={true}
            showMultiDayTimes={true}
            style={{ height: 400, width: 1000 }}
            eventPropGetter={eventStyleGetter}
             />
             </div>
             </ContextMenu>
      </div>
      <div
        className={isOpenEvent ? "modal fade show" : "modal fade"}
        style={{ display: isOpenEvent ? "block" : "none" }}
        id="addProvider"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5>Add New Event</h5>
              <button type="button" className="text-white close p-0 m-0" data-dismiss="modal" onClick={onClose}>
                <i className="fa fa-times-circle"></i>
              </button>
            </div>

            <div className="modal-body vertical-tabs">
              <div className="card">
                <div className="card-body p-md-0">
                  <div className="card-inner-body">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>Title</label>
                          <input type="text"
                            maxLength={50}
                            placeholder="Add Title"
                            className="form-control"
                            name="title"
                            id="title"
                            value={mainAttendenceModal.title}
                            onChange={handleAttendenceChange}
                             />
                           {AttendenceValidationModal.title}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                      <label>Start Date</label>
                        <div className="form-group">
                        <DatePicker placeholderText="Start Date"
                              selected={newEvents.start}
                              onChange={(start)=>handleChangeStart(start)}
                              dateFormat='dd/MM/yyyy'
                              filterDate={start=> start.getDay() != 6 && start.getDay() != 0 }
                              isClearable={true}
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              customInput={<ExampleCustomInput />}
                              >
                            </DatePicker>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                      <label>End Date</label>
                        <div className="form-group">
                          <DatePicker placeholderText="End Date"
                            selected={newEvents.end}
                            onChange={(end)=>handleChangeEnd(end)}
                            dateFormat='dd/MM/yyyy'
                            filterDate={end => end.getDay() != 6 && end.getDay() != 0}
                            isClearable={true}
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            customInput={<ExampleCustomInput />}
                          >
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary mw-100" data-dismiss="modal" onClick={onClose}>Cancel</button>
              <button type="button" className="btn btn-primary mw-100" onClick={handleSaveAttendence}>Save</button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Miscellaneous