import React, { useEffect, useState } from "react";
import { TabContent, TabPane } from 'reactstrap'
import EmployeeInformation from "../Account/EmployeeInformation/EmployeeInformation";


const EmployeeInnerLeftMenuContent = (props) => {
    const [tabs, setTabs] = useState([])
    const [component, setComponent] = useState({})

    useEffect(() => {
        setTabs(props.dynamicTabs)
        const activeComponent = props.subCategItems.filter((tab, key) => {
            return tab.right === true
        })[0]
        if (activeComponent !== undefined) {
            setComponent(activeComponent)
        }

    }, [props.activeTab, props.subCategItems])
    return (
        <>
            <div className={`row m-0 pt- h-auto basic_info user_basic_info`}>
                <div className="col-md-3 ps-1 ">
                    <EmployeeInformation />
                </div>
                <div className="col-md-9 px-0 ">
                    {tabs.length > 0 ? (
                        <>
                            <TabContent activeTab={props.activeTab} className="content">
                                {tabs &&
                                    tabs.map((item) => {
                                        return (
                                            <React.Fragment key={item.key}>
                                                <TabPane tabId={item.key}>{item.component}</TabPane>
                                            </React.Fragment>
                                        )
                                    })}
                            </TabContent>
                        </>
                    ) : (
                        <TabContent activeTab={component.key} className="content">
                            <React.Fragment key={component.key}>
                                <TabPane tabId={component.key}>{component.component}</TabPane>
                            </React.Fragment>
                        </TabContent>
                    )}
                </div>
            </div>
        </>
    )
}

export default EmployeeInnerLeftMenuContent