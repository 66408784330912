import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtected, ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleNumericCheck from "../../../../utils/HandleNumericKeys";
import format from "date-fns/format";
import close from "../../../../assetsOld/img/x-close.svg"
import CalendarInput from "../../../../utils/CalendarInput";
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import { Autocomplete, TextField } from "@mui/material";
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"
import CountriesStates from "../../../shared/CountriesStates/CountriesStates"

const AddNewSwitchOrganization = (props) => {
    const { isOpenOrganization, onClose, onCloseReload } = { ...props }
    let Organization_Modal = {
        ProfilePicture: '',
        InchargePersons: '',
        OrganizationName: '',
        OrganizationType: '',
        EstablishedDate: '',
        NTN: '',
        OfficialRegisteredCode: '',
        Address: '',
        Province: '',
        City: '',
        PostalCode: '',
        ContactPersonName: '',
        PhoneNumber: '',
        Email: '',
        Website: '',
        Nationality:'',
    }

    let valModal = {}
    let ValidationModal = {
        OrganizationName: "",
        OrganizationType: "",
        NTN: "",
        NTNLength: "",
        InchargePersons: "",
        Website: "",
        Email: "",
    }
    const API_URL = process.env.REACT_APP_API
    const [OrganizationValidationModal, setOrganizationValidationModal] = useState(ValidationModal)
    const [OrganizationID, setOrganizationID] = useState()
    const [organizationSaveUpdateModal, setOrganizationSaveUpdateModal] = useState({})
    const [organizationImageSaveUpdateModal, setOrganizationImageSaveUpdateModal] = useState({})
    const [mainOrganizationModal, setmainOrganizationModal] = useState(Organization_Modal);
    const [dropDownDataEmployee, setDropDownDataEmployee] = useState([])
    const [del, setDel] = useState(false)
    const [newOrganizationSaved, setNewOrganizationSaved] = useState(false);
    const [inchargePersonsValue, setInchargePersonsValue] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [profileUpdated, setProfileUpdated] = useState(false);

    const LoadOrganization = () => {
        if (!isNull(OrganizationID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": OrganizationID,
                "TableName": enumUtil.enumAPITableName.Organization
            }).then((response) => {
                setIsLoading(false)
                console.log(JSON.parse(response.InchargePersons))
                setmainOrganizationModal(response);
                if (response.InchargePersons) { setInchargePersonsValue(JSON.parse(response.InchargePersons)) }
            }).catch((error) => {
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }
   
    ///////Calendar/////
    const [establishedDate, setEstablishedDate] = useState();
    const openEstablishedDate = Boolean(establishedDate);
    const handleCalendarChange = (date, type) => {
        var FormatedDateShow = format(date, DateFormat_dMy)
        var FormatedDateSave = format(date, DateFormat)

        let modal = { ...mainOrganizationModal };
        let saveModal = { ...organizationSaveUpdateModal }
        modal[type] = FormatedDateShow
        setmainOrganizationModal(modal)
        for (var property in Organization_Modal) {
            if (type === property) {
                saveModal[type] = FormatedDateSave
            }
        }
        setOrganizationSaveUpdateModal(saveModal)
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setEstablishedDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'EstablishedDate') { setEstablishedDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ///////////////////
    const handleOrganizationChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainOrganizationModal };
        let saveModal = { ...organizationSaveUpdateModal }
        modal[name] = value
        setmainOrganizationModal(modal)
        for (var property in Organization_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setOrganizationSaveUpdateModal(saveModal)
    }
    const OrganizationValidation = async () => {
        let ValidationModal = {
            OrganizationName: FormValidator(
                mainOrganizationModal.OrganizationName,
                enumUtil.enumValidationType.Required,
                'Organization Name',
            ),
            OrganizationType: FormValidator(
                mainOrganizationModal.OrganizationType,
                enumUtil.enumValidationType.Required,
                'Organization Type',
            ),
            NTN: FormValidator(
                mainOrganizationModal.NTN,
                enumUtil.enumValidationType.Required,
                'NTN',
            ),
            NTNLength: FormValidator(
                mainOrganizationModal.NTN,
                enumUtil.enumValidationType.Length,
                'NTN',
                13,
            ),
            // InchargePersons: FormValidator(
            //     mainOrganizationModal.InchargePersons,
            //     enumUtil.enumValidationType.Required,
            //     'Incharge Person',
            // ), 
            Website: FormValidator(
                mainOrganizationModal.Website,
                enumUtil.enumValidationType.Website,
                'Website'
            ),
            Email: FormValidator(
                mainOrganizationModal.Email,
                enumUtil.enumValidationType.Email,
                'Email'
            ),
        }
        await setOrganizationValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleOrganizationProfileChange = (e) => {
        const Type = e.target.files[0].type.substring(0, 5);
        if (Type === 'image') {
            let file = e.target.files[0]
            let reader = new FileReader();

            reader.onloadend = () => {
                let value = reader.result.toString();
                let { name } = e.target;
                // let modal = { ...mainOrganizationModal };
                let saveModal = { ...organizationImageSaveUpdateModal }
                // modal[name] = value
                // setmainOrganizationModal(modal)
                for (var property in Organization_Modal) {
                    if (name === property) {
                        saveModal[name] = value
                    }
                }
                setOrganizationImageSaveUpdateModal(saveModal)
                setProfileUpdated(true);
            }
            reader.readAsDataURL(file);
        }
    }

    const handleOrganizationProfileDelete = (name) => {
        // let { name } = e.target;
        let modal = { ...mainOrganizationModal };
        let saveModal = { ...organizationImageSaveUpdateModal }
        modal[name] = ""
        console.log("MODALNAME", name, modal[name])
        setmainOrganizationModal(modal)
        saveModal[name] = ""
        setOrganizationImageSaveUpdateModal(saveModal)
        setProfileUpdated(true);
    }

    const handleSaveOrganizationPicture = () => {

        let saveModal = {
            TableName: enumUtil.enumAPITableName.Organization,
            ID: OrganizationID + "",
            // ParentID: OrganizationID + "",
            data: { ...organizationImageSaveUpdateModal },
            value: null
        }
        setIsLoading(true)
        ServerRequestProtected(API_URL + "/SaveEmployeeImage", 'post', saveModal).then((response) => {
            if (!isNull(response.id)) {
                setIsLoading(false)
                setProfileUpdated(false)
                NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                setProfileUpdated(false);
                LoadOrganization();
                // dispatch(EmployeeActiveTab(response.id))
                // dispatch(EmployeeNewEmployee(response.id))

            }
        }).catch((error) => {
            setIsLoading(false)
            setProfileUpdated(false)
            NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error)
        })
    }

    const handleSaveOrganization = async () => {
        await OrganizationValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setOrganizationSaveUpdateModal(organizationSaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Organization,
                ID: OrganizationID,
                data: { ...organizationSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewOrganizationSaved(true)
                    setOrganizationID(response.id)
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log('error', error);
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            })
        }
    }
    const SetOrganizationName = () => {
        if (mainOrganizationModal.OrganizationName != null && mainOrganizationModal.OrganizationName != "") {
            return (
                <p className="heading">{mainOrganizationModal.OrganizationName}</p>
            )
        } else {
            return (
                <p className="heading ">Add New Organization</p>
            )
        }
    }
    const handleMultiSelectChange = (values, type, reason) => {
        let arr = [];
        let arrVal = []
        values.map((key, index) => {
            arr.push(key)
        })
        values.map((key, index) => {
            arrVal.push(key.EmployeeID)
        })
        setInchargePersonsValue(arr)
        mainOrganizationModal['InchargePersons'] = arr
        organizationSaveUpdateModal['InchargePersons'] = arrVal.toString()
        console.log(arr, arrVal.toString)

    }

    useEffect(() => {
        setIsLoading(false)
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Employee,
        }).then((response) => {
            setDropDownDataEmployee(response)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);   
        })
        if (props.OrganizationID != null) {
            setOrganizationID(props.OrganizationID)
            setDel(true)
        } else {
            if (!newOrganizationSaved) {
                setOrganizationID(0)
                setDel(false)
            }
            else { setDel(true) }
        }
        LoadOrganization()
    }, [OrganizationID])

    useEffect(() => {
        if (profileUpdated) {
            handleSaveOrganizationPicture();
        }
    }, [organizationImageSaveUpdateModal]);

    return (
        <React.Fragment>
            <Modal show={isOpenOrganization} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_new_orgination">
                <Modal.Body>
                    <div className='d-flex justify-content-between mb-3'>
                        <p className="heading validation_message text-nowrap">{SetOrganizationName()}</p>
                        <img className="cursor-pointer" src={close} onClick={newOrganizationSaved ? onCloseReload : onClose}></img>
                    </div>
                    {isLoading ? <Loader /> : null}
                    <div className="row">
                        <div className="col-md-4 pe-0">
                            <div>
                                <div className="d-flex justify-content-center">
                                    <div className="emp-profile-img position-relative mb-4 pl-4 d-flex flex-column align-items-center cursor-pointer">
                                        <div className="img-container circle s-150 upload_image">
                                            {mainOrganizationModal.ProfilePicture ? (<img src={mainOrganizationModal.ProfilePicture} alt="" />
                                            ) : (<img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />)}
                                        </div>
                                        <div className="upload-emp-img mt-1">
                                            <label className='d-flex align-items-center  gap-10'>
                                                <input type="file"
                                                    id="ProfilePicture"
                                                    name="ProfilePicture"
                                                    onChange={handleOrganizationProfileChange} />
                                                <i className="bi bi-camera fs-20 lh-1"></i>
                                                Change profile photo</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={OrganizationValidationModal.OrganizationName ? "form-group input error" : "form-group input"}>
                                    <label>Organization Name<span class="req">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="OrganizationName"
                                        id="OrganizationName"
                                        value={mainOrganizationModal.OrganizationName}
                                        onChange={handleOrganizationChange}
                                    />
                                    {OrganizationValidationModal.OrganizationName}
                                </div>
                                {/* dropdown from backend */}
                                <div className={OrganizationValidationModal.OrganizationType ? "form-group input error" : "form-group input"}>
                                    <label>Organization Type<span class="req">*</span></label>
                                    <select type="text"
                                        className="form-control"
                                        name="OrganizationType"
                                        id="OrganizationType"
                                        value={mainOrganizationModal.OrganizationType}
                                        onChange={handleOrganizationChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Accounts and Banking">Accounts and Banking</option>
                                        <option value="Automobiles & Motorcycles">Automobiles & Motorcycles</option>
                                        <option value="Agriculture">Agriculture</option>
                                        <option value="Construction">Construction</option>
                                        <option value="Clothing and Accessories">Clothing and Accessories</option>
                                        <option value="Education">Education</option>
                                        <option value="Health & Medical">Health & Medical</option>
                                        <option value="Information Technology">Information Technology</option>
                                        <option value="Insurance">Insurance</option>
                                        <option value="Manufacturing">Manufacturing</option>
                                        <option value="Oil/Gas Exploration">Oil/Gas Exploration</option>
                                        <option value="Others">Others</option>
                                        <option value="Security & Protection">Security & Protection</option>
                                        <option value="Software House">Software House</option>

                                    </select>
                                    {OrganizationValidationModal.OrganizationType}
                                </div>
                                {/* ///////////// */}
                                <div className='form-group input'>
                                    <CalendarInput
                                        label='Established Date'
                                        maxDate={new Date()}
                                        value={mainOrganizationModal.EstablishedDate}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'EstablishedDate')}
                                        anchorEl={establishedDate}
                                        open={openEstablishedDate}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'EstablishedDate')}
                                    />
                                </div>
                                {/* ////////////// */}
                                <div className={OrganizationValidationModal.NTN ? "form-group input error" : OrganizationValidationModal.NTNLength ? "form-group input error" : "form-group input"}>
                                    <label>NTN<span class="req">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        maxLength={13}
                                        name="NTN"
                                        id="NTN"
                                        value={mainOrganizationModal.NTN}
                                        onChange={handleOrganizationChange}
                                        onKeyPress={(event) => handleNumericCheck(event)}
                                    />
                                    {OrganizationValidationModal.NTN}
                                    {OrganizationValidationModal.NTNLength}
                                </div>
                                <div className='form-group input'>
                                    <label>Official Registered Code</label>
                                    <input type="text"
                                        className="form-control"
                                        name="OfficialRegisteredCode"
                                        id="OfficialRegisteredCode"
                                        value={mainOrganizationModal.OfficialRegisteredCode}
                                        onChange={handleOrganizationChange}
                                        onKeyPress={(event) => handleNumericCheck(event)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="accordian-col col-md-8">
                            <div className="app-accordian">
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <p className="heading">Address Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className='form-group input'>
                                                        <label>Address</label>
                                                        <input type="text"
                                                            className="form-control"
                                                            name="Address"
                                                            id="Address"
                                                            value={mainOrganizationModal.Address}
                                                            onChange={handleOrganizationChange}
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div className="row m-0">
                                                    <CountriesStates mainEmployeeModal={mainOrganizationModal} handleEmployeeChange={handleOrganizationChange} columnLength={'col-md-4'} />
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <div className='form-group input'>
                                                        <label>Province</label>
                                                        <input type="text"
                                                            className="form-control"
                                                            name="Province"
                                                            id="Province"
                                                            value={mainOrganizationModal.Province}
                                                            onChange={handleOrganizationChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='form-group input'>
                                                        <label>City</label>
                                                        <input type="text"
                                                            className="form-control"
                                                            name="City"
                                                            id="City"
                                                            value={mainOrganizationModal.City}
                                                            onChange={handleOrganizationChange}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-4">
                                                    <div className='form-group input'>
                                                        <label>Postal Code</label>
                                                        <input type="text"
                                                            maxLength={9}
                                                            className="form-control"
                                                            name="PostalCode"
                                                            id="PostalCode"
                                                            value={mainOrganizationModal.PostalCode}
                                                            onChange={handleOrganizationChange}
                                                            onKeyPress={(event) => handleNumericCheck(event)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <p className="heading">Contact Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row row-gap-12'>
                                            <div className="col-md-4">
                                                <div className='form-group input'>
                                                    <label htmlFor="">Person Name</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="ContactPersonName"
                                                        id="ContactPersonName"
                                                        value={mainOrganizationModal.ContactPersonName}
                                                        onChange={handleOrganizationChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group input'>
                                                    <label htmlFor="">Contact Number</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        maxLength={10}
                                                        name="PhoneNumber"
                                                        id="PhoneNumber"
                                                        value={mainOrganizationModal.PhoneNumber}
                                                        onChange={handleOrganizationChange}
                                                        onKeyPress={(event) => handleNumericCheck(event)}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className={OrganizationValidationModal.Email ? "form-group input error" : "form-group input"}>
                                                    <label>Email Address</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="Email"
                                                        id="Email"
                                                        value={mainOrganizationModal.Email}
                                                        onChange={handleOrganizationChange}
                                                    />
                                                    {OrganizationValidationModal.Email}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className={OrganizationValidationModal.Website ? "form-group input error" : "form-group input"}>
                                                    <label>Website</label>
                                                    <input type="text"
                                                        className="form-control"
                                                        name="Website"
                                                        id="Website"
                                                        value={mainOrganizationModal.Website}
                                                        onChange={handleOrganizationChange}
                                                    />
                                                    {OrganizationValidationModal.Website}
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                {/* <div className={OrganizationValidationModal.InchargePersons ? "form-group input error" : "form-group input"}> */}
                                                <div className={"form-group input"}>
                                                    <label>Incharge Person</label>
                                                    <Autocomplete
                                                        multiple
                                                        limitTags={2}
                                                        id='InchargePersons'
                                                        name='InchargePersons'
                                                        value={inchargePersonsValue}
                                                        options={dropDownDataEmployee}
                                                        isOptionEqualToValue={(option, value) => option.EmployeeID === value.EmployeeID}
                                                        onChange={(event, value, reason) => handleMultiSelectChange(value, 'InchargePersons', reason)}
                                                        getOptionLabel={(option) => option.Name}
                                                        noOptionsText={"Not Available"}
                                                        renderInput={(params) => <TextField {...params} variant="standard" />}
                                                    />
                                                    {/* {OrganizationValidationModal.InchargePersons} */}
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                    <button className='btn btn-gray' onClick={newOrganizationSaved ? onCloseReload : onClose}>Cancel</button>
                    <button className='btn btn-orange' onClick={handleSaveOrganization}>Save</button>
                </div> */}
                <div className='btns-group modal_footer br-top-1 br-clr d-flex justify-content-end gap-3'>
                    <div className="btns-group d-flex justify-content-center align-items-center">
                        <button className="btn btn-gray" onClick={newOrganizationSaved ? onCloseReload : onClose}>
                            Cancel
                        </button>

                        <button className="btn btn-blue" onClick={handleSaveOrganization}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default AddNewSwitchOrganization