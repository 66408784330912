import React, { useState } from 'react'
import DeleteConfirmation from '../../shared/Delete/DeleteConfirmation'
import { enumUtil } from '../../../utils/Enum';
import Loader from '../../shared/Loader/Loader';
import { ServerRequestPublic } from '../../../utils/ServerDataRequest';
import { saveAs, } from "file-saver"

const ScreenShot = (props) => {
    const API_URL_Download = process.env.REACT_APP_API_Download
    const { items, LoadData } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [delID, setDelID] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const API_URL = process.env.REACT_APP_API

    const downloadFile = (filepath, filename) => {
        setIsLoading(true)
        let postData = {
            "BucketPath": filepath
        };
        // const API_URL = 'http://localhost:63476/wasabi/DownloadImage'

        ServerRequestPublic(API_URL_Download, 'post', postData, "Download")
            .then((res) => {
                const blobWithContentType = new Blob([res], { type: 'image/jpeg' });
                saveAs(blobWithContentType, filename.split('/').pop());
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false)
            })
    }

    const saveImage = async (url) => {
        try {
            const imageUrl = url;
            window.open(imageUrl, '_blank');
            // Replace this with the URL of your image
            // const response = await fetch(imageUrl);
            // const blob = await response.blob();
            // const urlBlob = window.URL.createObjectURL(blob);
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = url;
            // link.href = urlBlob;

            // Set the download attribute to specify the filename
            // link.setAttribute('download', 'image.jpg'); 
            // Change the filename as desired

            // Trigger a click on the anchor element to initiate the download
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
        } catch (error) {
            console.error('Error saving image:', error);
        }
    };

    const handleDeleteModal = (ID) => {
        setDelID(ID)
        setIsOpenAlert(true);
    }

    const onCloseDelete = () => {
        setDelID(null);
        setIsOpenAlert(false)
        LoadData();
    }
    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.SessionScreenshot} ID={delID} onClose={onClose} />
    }

    return (
        <div>
            {ConfirmDelete}
            {isLoading ? <Loader /> : <></>}
            <div className='screenshot'>
                <div className="img-container m-auto s-175" onClick={() => saveImage(items?.ScreenshotURL)}>
                    <img src={items?.ScreenshotURL} alt="" />
                </div>
                <div className='d-flex my-2 justify-content-between'>
                    <p className='text sm'>Time</p>
                    <p className='heading font-m'>{items?.Time}</p>
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='text sm'>Keyboard Strokes</p>
                    <p className='heading font-m'>{items?.KeyboardStrokes}</p>
                </div>
                <div className='d-flex my-2 justify-content-between'>
                    <p className='text sm'>Mouse Clicks</p>
                    <p className='heading font-m'>{items?.MouseClicks}</p>
                </div>
                <div className='d-flex my-2 justify-content-between align-items-center'>
                    <div className='d-flex gap-1 align-items-center ranges'>
                        <div class="progress range w-100">
                            <div class="progress-bar fill" style={{ width: `${items?.ActivityPercent}%` }}></div>
                        </div>
                        <p class="heading precentage">{items?.Activitycount + "/10"}</p>
                    </div>
                    <div>
                        <i className='icon-Group-3994' onClick={() => handleDeleteModal(items?.SessionScreenshotID)}></i>
                        <i className='icon-Group-482426 ms-1' onClick={() => downloadFile(items?.ScreenshotURL, items?.BucketPath)}></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScreenShot
