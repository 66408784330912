import React, { useEffect, useState } from "react"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import isNull from "../../../../utils/NullChecking"
import FormValidator from "../../../../utils/FormValidator"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux";
import { CancelButton } from "../../../../actions/CancelButton";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import format from "date-fns/format"
import { Calendar } from "react-date-range"
import { DateFormat, DateFormat_dMy, handleRenderData } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import jwt_decode from 'jwt-decode';
import AddNewCertification from "./AddNewCertification"

const Certification = (props) => {
    const { type } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    // else{
    //     EmployeeID = Token.UserID;
    // }

    let dispatch = useDispatch();
    let Certification_Modal = {
        // SkillID: "1",
        CertificationName: "",
        Institute: "",
        GrantedOn: "",
        ValidTill: "",
        Remarks: ""
    }

    let valModal = {}
    let ValidationModal = {
        CertificationName: "",
        Institute: "",
    }

    const [CertificationValidationModal, setCertificationValidationModal] = useState(ValidationModal)
    const [isRefresh, setIsRefresh] = useState(false)
    const [delID, setDelID] = useState(0)
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    //  const [addNew, setAddNew] = useState(false);
    const [CertificationSaveUpdateModal, setCertificationSaveUpdateModal] = useState({})
    const [mainCertificationModal, setmainCertificationModal] = useState(Certification_Modal);
    const API_URL = process.env.REACT_APP_API
    // const [isOpenCertification, setIsOpenCertification] = useState(false)
    const [CertificationData, setCertificationData] = useState([]);
    const [CertificationID, setCertificationID] = useState();
    // const [searchParam, setSearchParam] = useState();
    // const [EnterParam, setEnterParam] = useState();
    const [isLoading, setIsLoading] = useState(false)
    // const [isClose, setIsClose] = useState()

    const [dateValidation, setDateValidation] = useState({
        GrantedOn: '',
        ValidTill: '',
    })
    ///EmployeeDelete///
    const handleDeleteModal = (ID) => {
        console.log(ID)
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        setmainCertificationModal(Certification_Modal)
        setCertificationID(0)
        setIsOpenAlert(false)
        LoadData()
    }
    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Certification} ID={delID} onClose={onClose} />
    }

    ////////////////////
    const LoadData = (param) => {
        setCertificationData([])
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.Certification,
            "SearchParam": param,
            "SearchCriteria": {
                "EmployeeID": EmployeeID + "",
            }
        }).then((response) => {
            setIsLoading(false);
            setCertificationData(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }
    // const SearchOnEnter = (e)=> {
    //     if (e.key === "Enter") {
    //         setEnterParam(e.target.value) 
    //         setSearchParam(EnterParam)   
    //      LoadData(searchParam)
    //      } 
    // }
    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };
    ///////Calendar////////
    const [grantedOn, setGrantedOn] = useState();
    const [validTill, setValidTill] = useState();
    const openGrantedOn = Boolean(grantedOn);
    const openValidTill = Boolean(validTill);
    const [isOpenCertification, setIsOpenCertification] = useState(false);
    const handleCalendarChange = (date, type) => {
        var FormatedDateShow = format(date, DateFormat_dMy)
        var FormatedDateSave = format(date, DateFormat)
        if (type === 'GrantedOn') {
            if (mainCertificationModal.ValidTill && FormatedDateShow < mainCertificationModal.ValidTill) {
                mainCertificationModal['GrantedOn'] = FormatedDateShow
                CertificationSaveUpdateModal['GrantedOn'] = FormatedDateSave
                setDateValidation({ ...dateValidation, GrantedOn: '' })
            }
            else if (!mainCertificationModal.ValidTill) {
                mainCertificationModal['GrantedOn'] = FormatedDateShow
                CertificationSaveUpdateModal['GrantedOn'] = FormatedDateSave
                setDateValidation({ ...dateValidation, GrantedOn: '' })
            }
            else {
                setDateValidation({ ...dateValidation, GrantedOn: 'error' })
            }
            // setShowCalendar({GrantedOn: false})
        }
        else if (type === 'ValidTill') {
            if (mainCertificationModal.GrantedOn && FormatedDateShow > mainCertificationModal.GrantedOn) {
                mainCertificationModal['ValidTill'] = FormatedDateShow
                CertificationSaveUpdateModal['ValidTill'] = FormatedDateSave
                setDateValidation({ ...dateValidation, ValidTill: '' })
            }
            else if (!mainCertificationModal.GrantedOn) {
                mainCertificationModal['ValidTill'].ValidTill = FormatedDateShow
                CertificationSaveUpdateModal['ValidTill'] = FormatedDateSave
                setDateValidation({ ...dateValidation, ValidTill: '' })
            }
            else {
                setDateValidation({ ...dateValidation, ValidTill: 'error' })
            }
            // setShowCalendar({ValidTill: false})
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setGrantedOn(null)
        setValidTill(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'GrantedOn') { setGrantedOn(event.currentTarget) }
        else if (type === 'ValidTill') { setValidTill(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ////////////////////////
    useEffect(() => {
        LoadData();
    }, [])

    const AddCertification = (ID) => {
        if (ID !== CertificationID) {
            setmainCertificationModal(Certification_Modal)
        }
        setCertificationID(ID);
        setIsOpenCertification(true);
    }
    const handleCertificationChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainCertificationModal };
        let saveModal = { ...CertificationSaveUpdateModal }
        modal[name] = value
        setmainCertificationModal(modal)
        for (var property in Certification_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setCertificationSaveUpdateModal(saveModal)
    }
    const CertificationValidation = async () => {
        let ValidationModal = {

            CertificationName: FormValidator(
                mainCertificationModal.CertificationName,
                enumUtil.enumValidationType.Required,
                'Certification Name',
            ),
            Institute: FormValidator(
                mainCertificationModal.Institute,
                enumUtil.enumValidationType.Required,
                'Institute',
            ),
        }

        await setCertificationValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleSaveCertification = async () => {
        await CertificationValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setCertificationSaveUpdateModal(CertificationSaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Certification,
                ID: CertificationID,
                ParentID: EmployeeID,
                data: { ...CertificationSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setCertificationID(response.id)
                }
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    const handleOnClose = () => {
        setIsOpenCertification(false)
    }
    const handleOnCloseSave = () => {
        setIsOpenCertification(false)
        LoadData();
    }
    let CertificationPopup = "";
    if (isOpenCertification) {
        CertificationPopup = <AddNewCertification isOpenCertification={isOpenCertification} handleOnClose={handleOnClose} handleOnCloseSave={handleOnCloseSave} ID={CertificationID} setIsRefresh={setIsRefresh} type={type} />
    }
    return (
        <React.Fragment>
            {ConfirmDelete}
            {isLoading ? <Loader /> : null}
            {CertificationPopup}
            {/* <div className="tabs-container"> */}
            {/* <div className="row row-half row-gap-15"> */}
            {/* <div className="col-md-8"> */}
            {/* <div className="app-padding"> */}
            {/* <div className='row row-half row-gap-15'> */}
            <div className="ps-0 education certification">
                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                        <span><i className="icon-Icon-awesome-certificate"></i></span>
                        <p className="heading">Certification</p>
                    </div>
                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => AddCertification(0)}></i>
                </div>
                {CertificationData && CertificationData?.length >= 1 &&
                    <div className="card_content pb-3 mt-2">

                        {CertificationData?.map((item, index) => {
                            return (
                                <div className="content">
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading office_name_truncate">{item?.CertificationName}</p>
                                        <p className="text">{handleRenderData(item?.GrantedOn, item?.ValidTill, 'Brackets')}</p>
                                    </div>
                                    <p className="text px-2 office_name_truncate">{item?.Institute}</p>
                                    <div className="d-flex status_content justify-content-between pe-2 align-items-center">
                                        {item?.Remarks ? (<div className="status py-1 px-2">
                                            <p className="heading sm office_name_truncate">{item?.Remarks}</p>
                                        </div>) : <div></div>}
                                        <div className="icons">
                                            <i className="icon-edit-pencil me-1 cursor-pointer" onClick={() => AddCertification(item?.CertificationID)}></i>
                                            <i className="icon-Group-3994 cursor-pointer" onClick={() => handleDeleteModal(item?.CertificationID)}></i>
                                        </div>
                                    </div>
                                    {index === CertificationData?.length - 1 ? <></> : <div className="bottom_border mt-3"></div>}
                                </div>

                            )
                        })}
                    </div>}
            </div>
            {/* {CertificationData.map((items) => {
                                    return (
                                        <div className="col-md-6">
                                            <div className="card card-certification border-0">
                                                <div className="card-body">
                                                    <div className="row row-half row-gap-10">
                                                        <div className="col-md-12">
                                                            <div className="text font-sb">{items.CertificationName || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2" style={{ flex: "0 0 80px" }}>Institute</div>
                                                            <div className="text font-sb">{items.Institute || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2" style={{ flex: "0 0 80px" }}>Remarks</div>
                                                            <div className="text font-sb remark_truncate" title={items.Remarks}>{items.Remarks || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="text font-m clr-light2">Granted On</div>
                                                            <div className="text font-sb">{items.GrantedOn || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="text font-m clr-light2">Valid Till</div>
                                                            <div className="text font-sb">{items.ValidTill || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row align-items-end g-0">
                                                                <div className="col-md-12 text-end">
                                                                    <div className="d-flex justify-content-end gap-1">
                                                                        <button class="btn btn-icon-transparent clr-muted fs-22" onClick={() => handleDeleteModal(items.ExperienceID)}><i class="icon-delete"></i></button>
                                                                        <button className="edit-icon" onClick={() => AddCertification(items.CertificationID)}></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })} */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <div className="col-md-4">
                        <div className="card rounded-0 br-none br-left-1 br-clr bg-transparent h-100">
                            <div className="bg-blue app-padding py-2">
                                <div className="row">
                                    <div className="col-md-2"><button class="btn-icon-transparent-add" onClick={() => AddCertification(0)}></button></div>
                                    <div className="col-md-10"><p className="text text-white fs-16">Add New Certification</p></div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={CertificationValidationModal.CertificationName ? "form-group input error" : "form-group input"}>
                                    <label>Certification Name</label>
                                    <input type="text" className="form-control" name="CertificationName"
                                        id="CertificationName"
                                        value={mainCertificationModal.CertificationName}
                                        onChange={handleCertificationChange}
                                    />
                                    {CertificationValidationModal.CertificationName}
                                </div>
                                <div className={CertificationValidationModal.Institute ? "form-group input error" : "form-group input"}>
                                    <label>Institute</label>
                                    <input type="text" className="form-control" name="Institute"
                                        id="Institute"
                                        value={mainCertificationModal.Institute}
                                        onChange={handleCertificationChange}
                                    />
                                    {CertificationValidationModal.Institute}
                                </div>
                                <div className={`form-group input ${dateValidation.GrantedOn}`}>
                                    <CalendarInput
                                        label='Granted On'
                                        maxDate={new Date()}
                                        value={mainCertificationModal.GrantedOn}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'GrantedOn')}
                                        anchorEl={grantedOn}
                                        open={openGrantedOn}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'GrantedOn')}
                                    />
                                    {dateValidation.GrantedOn === 'error' ? <label>Granted On Date must be less than Valid Till Date </label> : ''}
                                </div>
                                <div className={`form-group input ${dateValidation.ValidTill}`}>
                                    <CalendarInput
                                        label='Valid Till'
                                        value={mainCertificationModal.ValidTill}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'ValidTill')}
                                        anchorEl={validTill}
                                        open={openValidTill}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'ValidTill')}
                                    />
                                    {dateValidation.ValidTill === 'error' ? <label>Valid Till Date must be greater than Granted On Date </label> : ''}
                                </div>
                                <div className="form-group input">
                                    <label>Remarks</label>
                                    <textarea 
                                    className="form-control"
                                        name="Remarks"
                                        id="Remarks"
                                        value={mainCertificationModal.Remarks}
                                        onChange={handleCertificationChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* </div> */}
            {/* <div className='tabs-action-bottom'>
                    <div className='btns-group text-end px-3 br-top-1 br-clr d-flex align-items-center justify-content-end h-100'>
                        <button className='btn btn-gray'onClick={()=>dispatch(CancelButton(true))}>Cancel</button>
                        <button className='btn btn-orange' onClick={handleSaveCertification}>Save</button>
                    </div>
                </div> */}
            {/* </div> */}
        </React.Fragment>
    )
}

export default Certification