import React, { useEffect, useState } from "react"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux"
import { CancelButton } from "../../../../actions/CancelButton";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import format from "date-fns/format"
import { Calendar } from "react-date-range"
import { DateFormat, DateFormat_dMy, handleRenderData } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import jwt_decode from 'jwt-decode';
import AddNewExperiences from "./AddNewExperiences"
import { TypeSpecimen } from "@mui/icons-material"

const Experience = (props) => {
    const { type } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    // else{
    //     EmployeeID = Token.UserID;
    // }
    let dispatch = useDispatch();
    let Experience_Modal = {
        CompanyName: "",
        Designation: "",
        LeavingReason: "",
        Salary: "",
        StartDate: "",
        EndDate: "",

    }
    let valModal = {}
    let ValidationModal = {
        CompanyName: "",
        Designation: "",
    }
    //const dispatch = useDispatch();
    const [experienceValidationModal, setExperienceValidationModal] = useState(ValidationModal)
    const [experienceSaveUpdateModal, setExperienceSaveUpdateModal] = useState({})
    const [mainExperienceModal, setmainExperienceModal] = useState(Experience_Modal);
    // let [startSelectedDate, setStartSelectedDate] = useState(null);
    // let [endSelectedDate, setEndSelectedDate] = useState(null);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false)
    const API_URL = process.env.REACT_APP_API
    // const [isOpenExperience, setIsOpenExperience] = useState(false)
    const [ExperienceData, setExperienceData] = useState([]);
    const [ExperienceID, setExperienceID] = useState();
    // const [searchParam, setSearchParam] = useState();
    // const [EnterParam, setEnterParam] = useState();
    const [isLoading, setIsLoading] = useState(false)
    // const [isClose, setIsClose] = useState()
    const [delID, setDelID] = useState(0)
    const [isOpenExperience, setIsOpenExperience] = useState(false);
    const [dateValidation, setDateValidation] = useState({
        StartDate: '',
        EndDate: '',
    })
    const handleDeleteModal = (ID) => {
        console.log(ID)
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        setmainExperienceModal(Experience_Modal)
        setExperienceID(0)
        setIsOpenAlert(false)
        LoadData()
    }
    const onClose = () => {
        setIsOpenAlert(false);
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Experience} ID={delID} onClose={onClose} />
    }
    ////////////////////
    const LoadData = (param) => {
        setExperienceData([])
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.Experience,
            "SearchParam": param,
            "SearchCriteria": {
                "EmployeeID": EmployeeID + "",
            }
        }).then((response) => {
            setIsLoading(false);
            setExperienceData(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }
    // const SearchOnEnter = (e)=> {
    //     if (e.key === "Enter") {
    //         setEnterParam(e.target.value) 
    //         setSearchParam(EnterParam)   
    //      LoadData(searchParam)
    //      } 
    // }
    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };
    ///////Calendar////////
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const openStartDate = Boolean(startDate)
    const openEndDate = Boolean(endDate)
    const handleCalendarChange = (date, type) => {
        var FormatedDateShow = format(date, DateFormat_dMy)
        var FormatedDateSave = format(date, DateFormat)
        if (type === 'StartDate') {
            if (mainExperienceModal.EndDate && FormatedDateShow < mainExperienceModal.EndDate) {
                mainExperienceModal['StartDate'] = FormatedDateShow
                experienceSaveUpdateModal['StartDate'] = FormatedDateSave
                setDateValidation({ ...dateValidation, StartDate: '' })
            }
            else if (!mainExperienceModal.EndDate) {
                mainExperienceModal['StartDate'] = FormatedDateShow
                experienceSaveUpdateModal['StartDate'] = FormatedDateSave
                setDateValidation({ ...dateValidation, StartDate: '' })
            }
            else {
                setDateValidation({ ...dateValidation, StartDate: 'error' })
            }
            // setShowCalendar({StartDate: false})
        }
        else if (type === 'EndDate') {
            if (mainExperienceModal.StartDate && FormatedDateShow > mainExperienceModal.StartDate) {
                mainExperienceModal['EndDate'] = FormatedDateShow
                experienceSaveUpdateModal['EndDate'] = FormatedDateSave
                setDateValidation({ ...dateValidation, EndDate: '' })
            }
            else if (!mainExperienceModal.StartDate) {
                mainExperienceModal['EndDate'] = FormatedDateShow
                experienceSaveUpdateModal['EndDate'] = FormatedDateSave
                setDateValidation({ ...dateValidation, EndDate: '' })
            }
            else {
                setDateValidation({ ...dateValidation, EndDate: 'error' })
            }
            // setShowCalendar({EndDate: false})
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setStartDate(null)
        setEndDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'StartDate') { setStartDate(event.currentTarget) }
        else if (type === 'EndDate') { setEndDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ////////////////////////
    useEffect(() => {
        LoadData();
    }, [])

    // const handleRangeChange = (date) => {
    //     mainExperienceModal.DateOfBirth = format(date[0].startDate, DateFormat) + ' to ' + format(date[0].endDate, DateFormat)
    //     experinceSaveUpdateModal['DateOfBirth'] = mainExperienceModal.DateOfBirth
    //     setRange(date)
    //     // setShowCalendar(false)
    // }

    // const handleCalendarChange = (date, type) => {
    //     console.log("date",date)
    //         // setValue(date)
    //         if(type === 'StartDate'){
    //         if(mainExperienceModal.EndDate &&  date < mainExperienceModal.EndDate){
    //                 mainExperienceModal.StartDate = date
    //                 experienceSaveUpdateModal['StartDate'] = date
    //         }
    //         else if(! mainExperienceModal.EndDate){
    //                 mainExperienceModal.StartDate = date
    //                 experienceSaveUpdateModal['StartDate'] = date
    //         }
    //         setShowCalendar({StartDate: false})
    //     }
    //         else if(type === 'EndDate'){
    //         if(mainExperienceModal.StartDate &&  date > mainExperienceModal.StartDate){
    //                 mainExperienceModal.EndDate = date
    //                 experienceSaveUpdateModal['EndDate'] = date
    //         }
    //         else if(! mainExperienceModal.StartDate){
    //                 mainExperienceModal.EndDate = date
    //                 experienceSaveUpdateModal['EndDate'] = date
    //             }
    //             setShowCalendar({EndDate: false})
    //         }
    // }

    const handleExperienceChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainExperienceModal };
        let saveModal = { ...experienceSaveUpdateModal }
        modal[name] = value
        setmainExperienceModal(modal)
        for (var property in Experience_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setExperienceSaveUpdateModal(saveModal)
    }

    const ExperienceValidation = async () => {
        let ValidationModal = {
            CompanyName: FormValidator(
                mainExperienceModal.CompanyName,
                enumUtil.enumValidationType.Required,
                'Company Name',
            ),
            Designation: FormValidator(
                mainExperienceModal.Designation,
                enumUtil.enumValidationType.Required,
                'Designation',
            ),
        }
        await setExperienceValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveExperience = async () => {
        await ExperienceValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            // experienceSaveUpdateModal['StartDate'] =startSelectedDate;
            // experienceSaveUpdateModal['EndDate'] = endSelectedDate;
            // experienceSaveUpdateModal['OrganizationID'] = OrganizationID;
            setExperienceSaveUpdateModal(experienceSaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Experience,
                ParentID: EmployeeID,
                ID: ExperienceID,
                data: { ...experienceSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setExperienceID(response.id)
                    //Setting Save model to null
                    //setExperienceSaveUpdateModal('')
                }
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    const AddExperience = (ID) => {
        if (ID !== ExperienceID) {
            setmainExperienceModal(Experience_Modal)
        }
        setExperienceID(ID);
        setIsOpenExperience(true);
    }

    // const CloseExperience = () => {
    //     setIsOpenExperience(false);
    //     setIsClose((Math.random()));
    // }
    // const columns = [
    //     {
    //         key: 'CompanyName',
    //         name: 'Company Name',
    //         width: 300,
    //         sortable: true,
    //         resizable: true,
    //         formatter({ row }) {
    //             return (
    //                 <button
    //                     className="btn btn-link p-0" onClick={() => AddExperience(row.ExperienceID)}>
    //                     {row.CompanyName}
    //                 </button>
    //             );
    //         },
    //     },
    //     {
    //         key: 'Designation',
    //         name: 'Designation',
    //         width: 200,
    //         sortable: true,
    //         resizable: true,
    //     },
    //     {
    //         key: 'LeavingReason',
    //         name: 'Leaving Reason',
    //         width: 200,
    //         sortable: true,
    //         resizable: true,
    //     },
    //     {
    //         key: 'Salary',
    //         name: 'Salary',
    //         width: 200,
    //         sortable: true,
    //         resizable: true,
    //     },
    //     {
    //         key: 'StartDate',
    //         name: 'StartDate',
    //         sortable: true,
    //         resizable: true,
    //     },
    //     {
    //         key: 'EndDate',
    //         name: 'End Date',
    //         sortable: true,
    //         resizable: true,
    //     },
    // ]
    // const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    //     <button className="example-custom-input" onClick={onClick} ref={ref}>
    //       {value}
    //     </button>
    //   ));
    // let PopupScreen = "";
    // if (isOpenExperience) {
    //     PopupScreen = <AddNewExperience isOpenExperience={isOpenExperience} onClose={CloseExperience} ExperienceID={ExperienceID} EmployeeID={EmployeeID} />
    // }
    const handleOnClose = () => {
        setIsOpenExperience(false);
    }
    const handleOnCloseSave = () => {
        setIsOpenExperience(false);
        LoadData();

    }
    let ExperiencePopup = "";
    if (isOpenExperience) {
        ExperiencePopup = <AddNewExperiences isOpenExperience={isOpenExperience} handleOnClose={handleOnClose} handleOnCloseSave={handleOnCloseSave} ID={ExperienceID} setIsRefresh={setIsRefresh} type={type} />
    }
    return (
        <React.Fragment>
            {ExperiencePopup}
            {ConfirmDelete}
            {isLoading ? <Loader /> : null}
            {/* <div className="tabs-container"> */}
            {/* <div className="row row-half row-gap-15"> */}
            {/* <div className="col-md-8"> */}
            {/* <div className="app-padding"> */}
            {/* <div className='row row-half row-gap-15'> */}
            <div className="mt-3 ps-0 education experience">
                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                        <span><i class="icon-Group-2694-1"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i></i></span>
                        <p className="heading">Experience</p>
                    </div>
                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => AddExperience(0)}></i>
                </div>
                {ExperienceData && ExperienceData.length >= 1 &&
                    <div className="card_content pb-3 mt-2">
                        {ExperienceData?.map((item, index) => {
                            return (
                                <React.Fragment>
                                    <div className="content">
                                        <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                            <p className="heading office_name_truncate">{item?.Designation}</p>
                                            <p className="text">{handleRenderData(item?.StartDate, item?.EndDate, 'Brackets')}</p>
                                        </div>
                                        <p className="text px-2 office_name_truncate">{item?.CompanyName}</p>
                                        <div className="d-flex status_content justify-content-between pe-2 align-items-center">
                                            {item?.LeavingReason ? (<div className="status py-1 px-2">
                                                <p className="heading sm office_name_truncate">{handleRenderData(item?.LeavingReason)}</p>
                                            </div>) : <div></div>}
                                            <div className="icons">
                                                <i className="icon-edit-pencil me-1 cursor-pointer" onClick={() => AddExperience(item?.ExperienceID)}></i>
                                                <i className="icon-Group-3994 cursor-pointer" onClick={() => handleDeleteModal(item?.ExperienceID)}></i>
                                            </div>
                                        </div>
                                        {index === ExperienceData?.length - 1 ? <></> : <div className="bottom_border mt-3"></div>}
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>}

            </div>
            {/* {ExperienceData.map((items) => {
                                    return (
                                        <div className="col-md-6">
                                            <div className="card card-tan-two-ton border-0">
                                                <div className="card-body">
                                                    <div className="row row-half row-gap-10">
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Company Name</div>
                                                            <div className="text font-sb">{items.CompanyName || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Designation</div>
                                                            <div className="text font-sb">{items.Designation || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="text font-m clr-light2">Start Date</div>
                                                            <div className="text font-sb">{items.StartDate || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="text font-m clr-light2">End Date</div>
                                                            <div className="text font-sb">{items.EndDate || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Salary</div>
                                                            <div className="text font-sb">{items.Salary || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row align-items-end g-0">
                                                                <div className="col-md-12">
                                                                    <div className="text font-m clr-light2">Leaving Reason</div>
                                                                    <div className="text font-sb remark_truncate" title={items.LeavingReason}>{items.LeavingReason || "N/A"}</div>
                                                                </div>
                                                                <div className="col-md-12 text-end">
                                                                    <div className="d-flex justify-content-end gap-1">
                                                                        <button class="btn btn-icon-transparent clr-muted fs-22" onClick={() => handleDeleteModal(items.ExperienceID)}><i class="icon-delete"></i></button>
                                                                        <button className="edit-icon" onClick={() => AddExperience(items.ExperienceID)}></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })} */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <div className="col-md-4">
                        <div className="card rounded-0 br-none br-left-1 br-clr bg-transparent h-100">
                            <div className="bg-blue app-padding py-2">
                                <div className="row">
                                    <div className="col-md-2"><button class="btn-icon-transparent-add" onClick={() => AddExperience(0)}></button></div>
                                    <div className="col-md-10"> <p className="text text-white fs-16">Add New Experience</p></div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={experienceValidationModal.CompanyName ? "form-group input error" : "form-group input"}>
                                    <label>Company Name</label>
                                    <input type="text" className="form-control"
                                        name="CompanyName"
                                        id="CompanyName"
                                        value={mainExperienceModal.CompanyName}
                                        onChange={handleExperienceChange}
                                    />
                                    {experienceValidationModal.CompanyName}
                                </div>
                                <div className={experienceValidationModal.Designation ? "form-group input error" : "form-group input"}>
                                    <label>Designation</label>
                                    <input type="text" className="form-control"
                                        name="Designation"
                                        id="Designation"
                                        value={mainExperienceModal.Designation}
                                        onChange={handleExperienceChange}
                                    />
                                    {experienceValidationModal.Designation}
                                </div>
                                <div className="form-group input">
                                    <label>Salary</label>
                                    <input type="type"
                                        maxLength={7}
                                        className="form-control"
                                        name="Salary"
                                        id="Salary"
                                        value={mainExperienceModal.Salary}
                                        onChange={handleExperienceChange}
                                        onKeyPress={(event) => handleNumericCheck(event)}
                                    />
                                </div>

                                <div className="form-group input">
                        <label htmlFor="Established Date">Start Date</label>
                        <input  
                                className='form-control'
                                value={mainExperienceModal.StartDate}
                                onFocus={() => setShowCalendar({StartDate: true})}
                                />
                        <Calendar
                            className={showCalendar.StartDate ? "" : 'hide'}
                            onChange={ date => handleCalendarChange(format(date, DateFormat),'StartDate')}
                            />
                    </div>
                    <div className="form-group input">
                        <label htmlFor="Established Date">End Date</label>
                        <input  className='form-control'
                            value={mainExperienceModal.EndDate}
                            onFocus={() => setShowCalendar({EndDate: true})}
                        />
                        <Calendar
                            className={showCalendar.EndDate ? "" : 'hide'}
                            onChange={ date => handleCalendarChange(format(date, DateFormat),'EndDate')}
                        />
                    </div>
                                <div className={`form-group input ${dateValidation.StartDate}`}>
                                    <CalendarInput
                                        label='Start Date'
                                        value={mainExperienceModal.StartDate}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'StartDate')}
                                        anchorEl={startDate}
                                        open={openStartDate}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'StartDate')}
                                    />
                                    {dateValidation.StartDate === 'error' ? <label>Start Date must be less than End Date </label> : ''}
                                </div>
                                <div className={`form-group input ${dateValidation.EndDate}`}>
                                    <CalendarInput
                                        label='End Date'
                                        value={mainExperienceModal.EndDate}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'EndDate')}
                                        anchorEl={endDate}
                                        open={openEndDate}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'EndDate')}
                                    />
                                    {dateValidation.EndDate === 'error' ? <label>End Date must be greater than Start Date</label> : ''}
                                </div>
                                <div className="form-group input">
                                    <label>Leaving Reason</label>
                                    <textarea className="form-control"
                                        name="LeavingReason"
                                        id="LeavingReason"
                                        value={mainExperienceModal.LeavingReason}
                                        onChange={handleExperienceChange}
                                        ></textarea>
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* </div> */}
            {/* <div className='tabs-action-bottom'>
                    <div className='btns-group text-end px-3 br-top-1 br-clr d-flex align-items-center justify-content-end h-100'>
                        <button className='btn btn-gray'onClick={()=>dispatch(CancelButton(true))}>Cancel</button>
                        <button className='btn btn-orange' onClick={handleSaveExperience}>Save</button>
                    </div>
                </div> */}
            {/* </div> */}
        </React.Fragment>

    )
}

export default Experience