import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ data, type ,name }) => {
  console.log("LineChart", data);
  const daysCategories =  ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat','Sun'];
  let currDay = new Date().getDay() - 1;
  let days = [
    ...daysCategories.slice(currDay ,7),
    ...daysCategories.slice(0,currDay+1)
  ];
  const initialOptions = {
    chart: {
      type: 'line',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      toolbar: {
        show: false, 
      },
      sparkline: {
        enabled: false,
      },
      zoom: {
        enabled: false, // Disable zoom
      },
    },
    series: [{
      name: name,
      data: data,
    }],
    xaxis: {
      categories: days,
      labels: {
        show: true,
        style: {
          fontSize: '10px',
          fontFamily: 'Poppins-semibold',
          colors: '#707070',
        },
      },
      axisBorder: {
        show: false, 
      },
      axisTicks: {
        show: false, 
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false, 
      tickAmount: 2, 
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: '#f4f4f4',
      strokeWidth: 0.25,
    },
    
    colors: ['+'].includes(type) ? ['#6fcf97'] : ['#ff5252']
  };

  const [state, setState] = useState(initialOptions);

  return (
    <Chart
      options={state}
      series={state.series}
      type={state.chart.type}
      height="90"  // Adjust the height to your desired value
      // width="10%"   // Adjust the width to your desired value
      // style={{ margin: 'auto' }}  // Additional styling for centering
    />
  );
};

export default LineChart;
