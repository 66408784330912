import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import close from "../../../../assetsOld/img/x-close.svg"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"

const AddNewProduct = (props) => {
    let Product_Modal = {
        ProductName : "",
        ProductType : "",
        Model : "",
        Brand : "",
    }

    let valModal = {}
    let ValidationModal = {
        ProductName:"",
    }

    const [newProductSaved,setNewProductSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [ProductValidationModal, setProductValidationModal] = useState(ValidationModal)
    const [ProductID, setEducationID] = useState()
    const [ProductSaveUpdateModal, setProductSaveUpdateModal] = useState({})
    const [mainProductModal, setmainProductModal] = useState(Product_Modal);
    const[del,setDel]=useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenProduct, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    

    useEffect(() => {
        setIsLoading(false)
        if (props.ProductID != null) {
            setEducationID(props.ProductID)
            setDel(true)
        } else {
            if(!newProductSaved){
                setEducationID(0)
                setDel(false)
            }
            else{setDel(true)} 
        }
        if (!isNull(ProductID)) {
            setIsLoading(true)  
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ProductID,
                "TableName": enumUtil.enumAPITableName.Product
            }).then((response) => {
                setmainProductModal(response);
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [ProductID])

    const ProductValidation = async () => {
        let ValidationModal = {
            ProductName: FormValidator(
                mainProductModal.ProductName,
                enumUtil.enumValidationType.Required,
                'Product Name',
            ),
        }
        await setProductValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    
    const handleProductChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainProductModal };
        let saveModal = { ...ProductSaveUpdateModal }
        modal[name] = value
        setmainProductModal(modal)
        for (var property in Product_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setProductSaveUpdateModal(saveModal)
    }

    const handleSaveProduct = async () => {
        await ProductValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Product,
                ID: ProductID,
                data: { ...ProductSaveUpdateModal},
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewProductSaved(true)                    
                    setEducationID(response.id)
                    setIsRefresh(true);
                    onCloseSave();
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(true);
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
            })
        }
    }
    const SetProductName = () => {
        if (mainProductModal.ProductName != null && mainProductModal.ProductName != "") {
            return (
                <p className="heading validation_message">{mainProductModal.ProductName}</p>
            )
        } else {
            return (
                <p className="heading">Add New Product</p>
            )
        }
    }
    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup=""
    if(del){
        DeletePopup=<button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if(isOpenAlert){
        ConfirmDelete = <DeleteConfirmation  isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Product} ID={ProductID} onClose={onClose}/>
    }
    //////////
    return (
        <React.Fragment>
        {ConfirmDelete}
              <Modal show={isOpenProduct} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal add_new_product ems_modal">
                <Modal.Body> 
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close}onClick={() => onClose(newProductSaved)}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="icon_bg">
                                    <i className="icon-plus-icon"></i>
                                </div>
                            </span>
                            <p class="heading mt-2"> {SetProductName()}</p>
                        </div>
                    </div>  
                    {isLoading ? <Loader/> : null}
                    <div>
                        <div className={ProductValidationModal.ProductName ? "form-group input error":"form-group input"}>
                                        <label>Product Name<span className='req'>*</span></label>
                                        <input type="text"
                                            className="form-control pro_name"
                                            name="ProductName"
                                            id="ProductName"
                                            maxLength={50}
                                            value={mainProductModal.ProductName}
                                            onChange={handleProductChange}
                                        />
                                        {ProductValidationModal.ProductName}
                        </div>
                        <div className='form-group input'>
                                <label>Product Type</label>
                                <input type="text"
                                    maxLength={35}
                                    className="form-control"
                                    name="ProductType"
                                    id="ProductType"
                                    value={mainProductModal.ProductType}
                                    onChange={handleProductChange}
                                />
                        </div>
                        <div className="form-group input">
                            <label>Brand</label>
                            <input type="text"
                                className="form-control"
                                name="Brand"
                                id="Brand"
                                maxLength={35}
                                value={mainProductModal.Brand}
                                onChange={handleProductChange}
                            />
                        </div>
                        <div className="form-group input">
                            <label>Model</label>
                            <input type="text"
                                className="form-control"
                                name="Model"
                                id="Model"
                                maxLength={35}
                                value={mainProductModal.Model}
                                onChange={handleProductChange}
                            />
                        </div>
                    </div>
                        </Modal.Body>
                        <ModalFooter isSaved={onClose} handleSave={handleSaveProduct}/>
                    {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                        <button className='btn btn-gray' onClick={() => onClose(newProductSaved)}>Cancel</button>
                        <button className='btn btn-orange' onClick={handleSaveProduct}>Save</button>
                    </div> */}
                </Modal>
        </React.Fragment>
    )
}

export default AddNewProduct