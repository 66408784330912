
// TabAction for Attendance
export const TabActionAttendance = (data, id, sideNavKey, childActiveId) => {
    return {
        type: 'OPEN_TAB_ATTENDANCE',
        payload: {
            data: data,
            id: id ? id : undefined,
            sideNavKey: sideNavKey,
            childActiveId: childActiveId
        },
    }
}