const handleNumericCheck = (event) => {
    if (event.charCode >= 48 && event.charCode <= 57) {
      return true
    } else {
      event.preventDefault()
      return false
    }
  }
  
  export default handleNumericCheck
  