import React, { useEffect, useMemo, useReducer, useState } from "react";
import Popover from "./Popover"
import { useDispatch } from 'react-redux';
import { GoTo } from '../../actions/GotoPage';
import { OpenEmployeeAttendance } from '../../actions/OpenEmployeeAttendance';
import PopupCheckout from "./PopupCheckout";
import { TabActionAttendance } from "../../actions/TabActions";
import Loader from "../shared/Loader/Loader";
import { enumUtil } from "../../utils/Enum";
import { ServerRequestProtected } from "../../utils/ServerDataRequest";
import NoDataFound from "../shared/NoDataFound/NoDataFound";

const WeeklyAttendance = (props) => {
  const { handleCheckboxChange, handleWeekSearch, selectDate, setCounter, current, monthlyParam } = { ...props }

  const OpenAttendance = (data) => {
    let obj = {
      key: 2,
      name: "Attendance",
      active: true,
      data: {
        id: data.id,
        name: data.name,
      },
    };

    dispatch(TabActionAttendance(obj));
  }
  const API_URL = process.env.REACT_APP_API
  const selectedDate = new Date()
  selectedDate.setMonth(parseInt(selectDate.Month, 10) - 1)
  selectedDate.setFullYear(parseInt(selectDate.Year, 10));
  let dispatch = useDispatch();
  const [currentWeek, setCurrentWeek] = useState(selectedDate);
  const [popup, setPopups] = useState(null);
  const [hover, handleHover] = useReducer((prev, next) => { return { ...prev, ...next } }, { empID: null, empDate: '' })
  const currDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [checkoutPopup, setCheckoutPopup] = useState("")
  const [attendanceData, setAttendanceData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleMissingCheckOut = () => {

    setIsPopupOpen(true)

  }

  const LoadData = (searchParam) => {
    setIsLoading(true);
    // setSearchOverAllDataParam(OverAllData_Search)
    setAttendanceData([])
    ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
      "TableName": enumUtil.enumAPITableName.Attendance,
      "SearchCriteria": searchParam,
    })
      .then((response) => {
        let jsonStr = '';
        for (let i in response) {
          const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
          for (let j in jsonData) {
            jsonStr += jsonData[j];
          }
        }

        const parsedJson = JSON.parse(jsonStr);
        setAttendanceData(parsedJson.Employees);
        setIsLoading(false);
        // CloseFiltersPopup()
      }).catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
      })
  }

  useEffect(() => {
    setCurrentWeek(current)

  }, [current])

  const getWeekDates = (date) => {

    const startOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1);
    const endOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7);
    const days = [];
    for (let d = startOfWeek; d <= endOfWeek; d.setDate(d.getDate() + 1)) {
      days.push(new Date(d));
    }
    return days;
  };

  const [rows, setRows] = useState([]);
  const [dateHeaders, setDateHeaders] = useState([])

  let row = useMemo(() => {
    return attendanceData?.length === 0 ? [] :
      attendanceData?.map(({ EmployeeID, Name, ProfilePicture, Attendance }) => {
        const cells = getWeekDates(currentWeek).map((date) => {
          const dateString = `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;
          const attendance = Attendance.find((item) => item.Date === dateString);

          return (
            <td onMouseEnter={(e) => attendance?.Status === "P" ? showPopup(e, attendance, dateString, EmployeeID) : null} onMouseLeave={() => hidePopup()} key={dateString}>
              {attendance && attendance?.Status === "P" &&
                (<div className={`hk-tabel-status green-bg`}>{attendance?.Status}
                  {!attendance.Check_Out ? date.getTime() < currDate ? <div className="astrik-circle">*</div> : <></> : <></>}
                </div>)}
              {attendance && attendance.Status === "A" && <div className="hk-tabel-status litePink-bg">{attendance?.Status}</div>}
              {attendance && attendance.Status === "W" && <div className="ar_weakly"></div>}
              {attendance && attendance.Status === "L" && <div className="hk-tabel-status pink-bg">{attendance?.Status}</div>}
              {attendance && attendance.Status === "NH" && <div className="hk-tabel-status liteBlue-bg">{attendance?.Status}</div>}
              {EmployeeID === hover.empID && dateString === hover.empDate && attendance?.Status === "P" && popup}

            </td>
          );
        });
        return (
          <tr key={EmployeeID}>
            <td>
              <div className="hk-table-input custom_check_box">
                <input type="checkbox" onChange={(e) => handleCheckboxChange(e, EmployeeID)} />
              </div>
            </td>
            <td className="hk-table-UserCol">
              <div className="gap-3">
                <img
                  src={ProfilePicture ? ProfilePicture : "https://www.w3schools.com/howto/img_avatar.png"}
                  alt="" />
                <p className="heading office_name_truncate cursor-pointer" title={Name}
                  onClick={() =>
                    // handleOpenEmployee(EmployeeID)
                    OpenAttendance({ id: EmployeeID, name: Name })
                  }
                >{Name}</p>
              </div>
            </td>
            {cells}
          </tr>
        );
      });
  }, [attendanceData, currentWeek, hover])

  let dateHeader = useMemo(() => {
    return (
      getWeekDates(currentWeek).map((date) => (
        <th key={date}>
          {date.toLocaleString("default", { weekday: "long" })} <span>{date.getDate()}</span>
        </th>
      ))
    )
  }, [attendanceData, currentWeek])


  // useEffect(() => {


  //   setRows(row)


  //   setDateHeaders(dateHeader);
  // }, [attendanceData, currentWeek, hover])

  // useEffect(() => {

  // }, [currentWeek])
  //////////////////OpenEmployee////////////////
  const handleOpenEmployee = (ID) => {
    const goToEmployee = (component, ID) => {
      dispatch(GoTo(component));
      dispatch(OpenEmployeeAttendance(ID));
    };
    return goToEmployee(2, ID)
  }
  //////////////////Popover////////////////
  const showPopup = (e, attendance, dateString, EmployeeID) => {
    setPopups(attendance ? <Popover attendance={attendance} setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen} setCheckoutPopup={setCheckoutPopup} /> : null);
    handleHover({ empDate: dateString, empID: EmployeeID })

  }
  const hidePopup = () => {
    setPopups(null);
  }

  useEffect(() => {
    LoadData(monthlyParam);
  }, [monthlyParam])
  /////////////////////////////////
  return (
    <>
      {isLoading ? <Loader /> : <></>}
      {isPopupOpen && checkoutPopup}
      <div className="row m-0 p-0">
        <div className="attendenceScreen col-md-12 overflow-auto">
          <table className="hk-attendance-table">
            <thead>
              <tr>
                <th>ID</th>
                <th className="at-right">Employee Name</th>
                {dateHeader}
              </tr>
            </thead>
            <tbody>{row?.length > 0 ?
              <>
                {row}
              </>
              :
              <NoDataFound isLoading={isLoading} Data={row} />
            }</tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default WeeklyAttendance;