import React, { useEffect, useState } from 'react'
import FormValidator from "../../utils/FormValidator"
import NotificationHandler from "../../utils/NotificationHandler"
import { enumUtil } from "../../utils/Enum"
import { ServerRequestProtectedParentID } from "../../utils/ServerDataRequest"
import Loader from "../shared/Loader/Loader"
import { useNavigate } from 'react-router-dom'
const EmailVerification = () => {
    const AUTHENTICATE_API_URL = process.env.REACT_APP_AUHTENTICATE
    var url = window.location.href;
    let navigate = useNavigate()

    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const urlParts = url.split('?');

    const LoadData = () => {
        setIsLoading(true)
        ServerRequestProtectedParentID(AUTHENTICATE_API_URL + "/ConfirmEmail?"+urlParts[1], 'get',{
        // ServerRequestProtectedParentID("http://192.168.18.5:438/api/Authentication/ConfirmEmail?"+urlParts[1], 'get',{
        }).then((response) => {
            setIsLoading(false)
            if(response.status!=="Error"){
                setShow(true)
                NotificationHandler(response.message, enumUtil.enumtoaster.success)
            }
            else{
                setShow(false)
                NotificationHandler(response.message, enumUtil.enumtoaster.success)
            }
        }).catch((error) => {
            setIsLoading(false)
            console.log('error', error.response.data);
            NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })}

    useEffect(()=>{
        LoadData();
    },[])
  return (
    <>
    {isLoading ? <Loader /> : null}
    <div className="login hk-login">
        <div className="d-lg-flex half">
            <div className="bg order-1 order-md-2">
                <img src={process.env.PUBLIC_URL + "/assets/images/Login_EMS_animation.png"} alt="" />
            </div>
            <div className="contents order-2 order-md-1">
                <h1 className="hk-title">Employee Management System</h1>
                <div className="container hk-form-t">
                    <div className="row align-items-start justify-content-center">
                        <div className="col-md-8 d-flex flex-column justify-content-center text-center">
                            <div className="hk-t-img">
                            {show && <img src={process.env.PUBLIC_URL + "/assets/images/confirm-icon.svg"} alt="confirm email icon" />}
                            </div>
                            {show ? <h2 class="heading xl text-center">Email Verified</h2> : <h2 class="heading xl text-center">Verification Failed</h2>}
                            {/* <button className="btn rounded btn-blue w-100 mt-4 reset-pass-btn mt-5">
                                Open email app
                            </button> */}
                            <p className="text text-center fw-semibold mt-5">Your Account has been created  <a href="https://gmail.com" target="_blank" rel="noreferrer">Click Here</a> to see default password.</p>
                            <a className="text text-center inst-txt mt-4 text-decoration-none" onClick={()=>navigate("../", { replace: true })}><span class="icon-Icon-ionic-md-arrow-round-back"></span> Back to Login</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
export default EmailVerification