import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import PopupCheckout from './PopupCheckout';

const Popover = ({type, access, refresh, setRefresh, attendance, EmployeeID,  isPopupOpen, setIsPopupOpen ,setCheckoutPopup}) => {

  const handleMissingCheckOut = () => {
    setIsPopupOpen(true);
    setCheckoutPopup(<PopupCheckout type={type} refresh={refresh} setRefresh={setRefresh}  EmployeeID={EmployeeID} attendance={attendance} setIsPopupOpen={setIsPopupOpen} isPopupOpen={true} />)
  }
  const checkStatus = [
    { value: "P", class: "green-bg" },
    { value: "W", class: "liteBlue-bg" },
    { value: "A", class: "litePink-bg" },
    { value: "L", class: "liteOrnage-bg" },
    { value: "NH", class: "liteBlue-bg" },
  ];
  const boxRef = useRef(null);
  const statusClass = checkStatus.find(
    (item) => item.value.toLowerCase() === attendance.Status.toLowerCase()
  );

  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const currDate = new Date(year, month, 1)

  useEffect(() => {
    if (boxRef.current) {
      let { top, right } = boxRef.current.getBoundingClientRect();
      if (top > 540 && right > 1200) {
        boxRef.current.classList.add('flipXY');
      }
      if (top > 540) {
        boxRef.current.classList.add('flip');
      }
      if (right > 1200) {
        boxRef.current.classList.add('flipX');
      }
    }
  }, []);
  return (
    <>
    
      <div className="table-infobox" ref={boxRef}>
        <div className="table-infobox__arrow"></div>
        <div className="table-infobox__header">
          <div className={`hk-tabel-status cursor-default ${statusClass?.class}`}>{attendance.Status}</div>
          <span>{attendance.Date}</span>
        </div>
        <div className="table-infobox__body">
          <div className="table-infobox__body__item">
            <span> <i className="icon-Icon-ionic-ios-time text-success"></i>Check In
              Time
            </span>
            <span className="infobox__time">{attendance.Check_In}</span>
          </div>
          <div className="table-infobox__body__item">
            <span><i className="icon-Icon-ionic-ios-time text-danger"></i>Check Out
              Time
            </span>
            {attendance.Status === 'P' ? (<span className="infobox__time">{attendance.CheckOutRequestStatus === "Pending" ? "Pending..." : attendance.Check_Out ? (attendance.Check_Out) : (access  ? <>Missing</>: <div className="btn-icon-transparent-add cursor-pointer" onClick={()=>handleMissingCheckOut()}></div>)}

            </span>) : (<span className="infobox__time">{attendance.Check_Out ? (attendance.Check_Out) : <></>}

            </span>)}

          </div>
          <div className="table-infobox__body__item">
            <span><i className="icon-Icon-ionic-ios-time text-warning"></i>Logged In
              Time
            </span>
            <span className="infobox__time">{attendance.TotalHours}</span>
          </div>
        </div>
      </div>
    </>
  )
}


Popover.defaultProps = {
  attendance: {
    TotalHours: 0,
    Status: '',
    Date: '',
    Check_In: '',
    Check_Out: ''
  }
};
Popover.propTypes = {
  attendance: PropTypes.object,
};
export default Popover