export const EmployeeRecordAction = (Data) => {
    return {
        type: 'EmployeeRecordAction',
        payload: Data
    }
}

export const EmployeeActiveTab = (key) => {
    return {
        type: 'EmployeeActiveTab',
        payload: key
    }
}

export const EmployeeDataID = (id) => {
    return {
        type: 'EmployeeDataID',
        payload: id
    }
}