import React from 'react'
import LineChart from '../Charts/LineChart'
import NoDataFound from './NoDataFound'
const OverallActivity = ({data ,filter , setFilter}) => {
    console.log("OverallActivity",data)
    return (
        <div className="col-md-8">
            {data?.length > 0 ?
            <div className="overlay_act card_tabs">
                <div className="d-flex justify-content-between">
                    <p className="heading">Overall Activity</p>
                    <div class="input">
                        <select class="form-control" id="RequestsPeriod" name="RequestsPeriod" value={filter} onChange={(e) => setFilter(e.target.value)}>
                            <option value="Previous Week">Previous Week</option>
                            <option value="This Week">This Week</option>
                        </select>
                    </div>
                </div>
                <div>
                    <LineChart data={data} />
                </div>
            </div> : 
            <div style={{height:"100%"}}>
                <div className="overlay_act card_tabs">
                    <div className="d-flex justify-content-between">
                        <p className="heading">Overall Activity</p>
                    </div>
                    <div className='position-relative' style={{height:215}}><NoDataFound/></div>
                </div>
            </div>}
        </div>

    )
}

export default OverallActivity