import NotificationHandler from "../utils/NotificationHandler";
import isNull from "../utils/NullChecking";

export default function Open_Attendance_Tab(state = [], action) {
    if (action.type === 'OPEN_TAB_ATTENDANCE') {
        let arrayList; let arrayObject;

        if (action.payload.data) {
            arrayObject = action.payload.data;

            // check if we have data in data object
            if (arrayObject.length === 0) {
                arrayList = []
            } else {

                if (isNull(action.payload.id)) {
                    console.log("action.payload.id",action.payload.id)
                    let initiallData = [...state]
                    let isTabExist = [...state].filter((tab) => {
                        return tab.key === arrayObject.key && tab.data.id === arrayObject.data.id
                    })
                    if (!(isTabExist && isTabExist.length > 0)) {
                        if (state.length === 5) {
                            NotificationHandler('Sorry! You can add maximum 5', 'success')
                            arrayList = [...state]
                        } else {
                            arrayObject.active = true
                            initiallData.forEach((row) => (row.active = false))
                            arrayList = [...state, arrayObject]
                        }
                    } else {
                        initiallData.forEach((row, index) => {
                            if (arrayObject.data.id === row.data.id) {
                                row.active = true
                                initiallData[index] = arrayObject;
                            } else {
                                row.active = false
                            }
                        })
                        arrayList = initiallData
                    }
                } else {
                    let updatedArray = [...state].filter((items) => items.data.id !== action.payload.id)
                    let arrayLength = updatedArray.length;
                    if (arrayLength > 0) {
                        updatedArray[arrayLength - 1].active = true
                    }

                    arrayList = updatedArray
                }
            }
            return arrayList

        } else {
            return []
        }
    }else if (action.type === 'Open_New_Attendance_Tab'){
        if(action.payload.data){
            return action.payload.data
        }else{
            return []
        }
        }


    return state

}