import React, { useEffect, useState } from "react"
import { ServerRequestProtected } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import CalendarInput from "../../../../utils/CalendarInput"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import { CancelButton } from "../../../../actions/CancelButton";
import { Calendar, DateRange } from 'react-date-range';
import format from 'date-fns/format';
import { DateFormat, DateFormatCalculations, DateFormat_YMd_Time, DateFormat_dMy, handleRenderData } from "../../../../utils/Constants"
import jwt_decode from 'jwt-decode';
import AddNewEducation from "./AddNewEducation"

const Education = (props) => {
    const { type } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    // else{
    //     EmployeeID = Token.UserID;
    // }

    let dispatch = useDispatch();
    let Education_Modal = {
        Title: "",
        Institute: "",
        StartDate: "",
        EndDate: "",
        GPA: "",
        CurrentStatus: ""
    }

    let valModal = {}
    let ValidationModal = {
        Title: "",
        Institute: "",
    }

    const [EducationValidationModal, setEducationValidationModal] = useState(ValidationModal)
    const [EducationSaveUpdateModal, setEducationSaveUpdateModal] = useState({})
    const [mainEducationModal, setmainEducationModal] = useState(Education_Modal);
    const [dateValidation, setDateValidation] = useState({
        StartDate: '',
        EndDate: '',
    })
    const API_URL = process.env.REACT_APP_API
    const [EducationData, setEducationData] = useState([]);
    const [EducationID, setEducationID] = useState(0);
    // const [startDate, setStartDate] = useState(0);
    // const [endDate, setEndDate] = useState(0);

    //const [searchParam, setSearchParam] = useState();
    //const [EnterParam, setEnterParam] = useState();
    const [isRefresh, setIsRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [delID, setDelID] = useState(0)
    const [isOpenEducation, setIsOpenEducation] = useState(false);
    const [showCalendar, setShowCalendar] = useState({
        StartDate: false,
        EndDate: false,
    });
    ///////Calendar////////
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const openStartDate = Boolean(startDate)
    const openEndDate = Boolean(endDate)

    const handleCalendarChange = (date, type) => {
        const FormatedDateShow = format(date, DateFormat_dMy)
        const FormatedDateSave = format(date, DateFormat)
        //===========to split YYYY-MM-dd Format Date then to Time============//
        const currDate = DateFormat_YMd_Time(date);
        //===========to Convert dd/MM/YYYY to YYYY-MM-dd then Time============//
        const startDate = DateFormatCalculations(mainEducationModal.StartDate)
        const endDate = DateFormatCalculations(mainEducationModal.EndDate)
        if (type === 'StartDate') {
            if (endDate && currDate <= endDate) {
                setmainEducationModal({ ...mainEducationModal, StartDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, StartDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, StartDate: '' })
            }
            else if (!endDate) {
                setmainEducationModal({ ...mainEducationModal, StartDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, StartDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, StartDate: '' })
            }
            else {
                setDateValidation({ ...dateValidation, StartDate: 'error' })
            }
        }
        else if (type === 'EndDate') {
            if (startDate && currDate >= startDate) {
                setmainEducationModal({ ...mainEducationModal, EndDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, EndDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, EndDate: '' })
            }
            else if (!startDate) {
                setmainEducationModal({ ...mainEducationModal, EndDate: FormatedDateShow })
                setEducationSaveUpdateModal({ ...EducationSaveUpdateModal, EndDate: FormatedDateSave })
                setDateValidation({ ...dateValidation, EndDate: '' })
            }
            else {
                setDateValidation({ ...dateValidation, EndDate: 'error' })
            }
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setStartDate(null)
        setEndDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'StartDate') { setStartDate(event.currentTarget) }
        else if (type === 'EndDate') { setEndDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ////////////////////////
    ///EmployeeDelete///
    const handleDeleteModal = (ID) => {
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        setDateValidation({ StartDate: '', EndDate: '' })
        setmainEducationModal(Education_Modal)
        setEducationID(0)
        setIsOpenAlert(false)
        LoadData()
    }
    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Education} ID={delID} onClose={onClose} />
    }
    ////////////////////
    const LoadData = (param) => {

        setEducationData([])
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.Education,
            "SearchParam": param,
            "SearchCriteria": {
                "EmployeeID": EmployeeID + "",
            }
            // "EmployeeID": EmployeeID,

        }
        ServerRequestProtected(API_URL + "/FindBySingleCriteria", 'post', saveModal).then((response) => {
            setIsLoading(false);
            setEducationData(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }
    // const SearchOnEnter = (e)=> {
    //     if (e.key === "Enter") {
    //         setEnterParam(e.target.value) 
    //         setSearchParam(EnterParam)   
    //      LoadData(searchParam)
    //      } 
    // }

    useEffect(() => {
        LoadData();
    }, [])

    const handleEducationChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainEducationModal };
        let saveModal = { ...EducationSaveUpdateModal }
        modal[name] = value
        setmainEducationModal(modal)
        for (var property in Education_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setEducationSaveUpdateModal(saveModal)
    }
    const AddEducation = (ID) => {
        if (ID != EducationID) {
            setmainEducationModal(Education_Modal)
        }
        setEducationID(ID)
        setIsOpenEducation(true)
        // setDateValidation({StartDate:'',EndDate:''})
    }
    const EducationValidation = async () => {
        let ValidationModal = {

            Title: FormValidator(
                mainEducationModal.Title,
                enumUtil.enumValidationType.Required,
                'Title',
            ),
            Institute: FormValidator(
                mainEducationModal.Institute,
                enumUtil.enumValidationType.Required,
                'Institute',
            ),
        }

        await setEducationValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleOnClose = () => {
        setIsOpenEducation(false)
    }
    const handleOnCloseSave = () => {
        setIsOpenEducation(false)
        LoadData();
    }
    let EductionPopup = "";
    if (isOpenEducation) {
        EductionPopup = <AddNewEducation isOpenEducation={isOpenEducation} handleOnClose={handleOnClose} handleOnCloseSave={handleOnCloseSave} ID={EducationID} setIsRefresh={setIsRefresh} type={type} />
    }
    return (
        <React.Fragment>
            {EductionPopup}
            {ConfirmDelete}
            {isLoading ? <Loader /> : null}

            <div className="ps-0 education">
                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                        <span><i className="icon-Icon-awesome-graduation-cap"></i></span>
                        <p className="heading">Education</p>
                    </div>
                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => AddEducation(0)}></i>
                </div>

                {EducationData && EducationData.length >= 1 &&
                    <div className="card_content pb-3 mt-2">
                        {EducationData?.map((item, index) => {
                            return (
                                <>
                                    <div className="content">
                                        <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                            <p className="heading office_name_truncate">{item?.Title}</p>
                                            <p className="text">{handleRenderData(item?.StartDate, item?.EndDate, 'Brackets')}</p>
                                        </div>
                                        <p className="text px-2 office_name_truncate">{item?.Institute}</p>
                                        <div className="d-flex status_content justify-content-between pe-2 align-items-center">
                                            {item?.CurrentStatus ? (<div className="status py-1 px-2 ">
                                                <p className="heading sm office_name_truncate">{item?.CurrentStatus}</p>

                                            </div>) : <div></div>}
                                            <div className="icons">
                                                <i className="icon-edit-pencil me-1 cursor-pointer" onClick={() => AddEducation(item?.EducationID)}></i>
                                                <i className="icon-Group-3994 cursor-pointer" onClick={() => handleDeleteModal(item?.EducationID)}></i>
                                            </div>
                                        </div>
                                        {index === EducationData?.length - 1 ? <></> : <div className="bottom_border mt-3"></div>}
                                    </div>
                                </>

                            )
                        })}
                    </div>}
            </div>
            {/* {EducationData.map((items) => {
                                    return (
                                        <div className="col-md-5">
                                            <div className="card card-gray-1 border-0">
                                                <div className="card-body">
                                                    <div className="row row-gap-10">
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Title</div>
                                                            <div className="text font-sb">{items.Title || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Institute</div>
                                                            <div className="text font-sb"><div className="two_rows">{items.Institute || "N/A"}</div></div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="text font-m clr-light2">Start Date</div>
                                                            <div className="text font-sb">{items.StartDate || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="text font-m clr-light2">End Date</div>
                                                            <div className="text font-sb">{items.EndDate || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">GPA</div>
                                                            <div className="text font-sb">{items.GPA || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row align-items-end g-0">
                                                                <div className="col-md-7">
                                                                    <div className="text font-m clr-light2">Current Status</div>
                                                                    <div className="text font-sb">{items.CurrentStatus || "N/A"}</div>
                                                                </div>
                                                                <div className="col-md-5 text-end">
                                                                    <div className="d-flex justify-content-end gap-1">
                                                                        <button class="btn btn-icon-transparent clr-muted fs-22" onClick={() => handleDeleteModal(items.EducationID)}><i class="icon-delete"></i></button>
                                                                        <button className="edit-icon" onClick={() => AddEducation(items.EducationID)}></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })} */}
            {/* </div>
                        </div>

                    </div> */}
            {/* <div className="col-md-4 add_new_edu">
                        <div className="card rounded-0 br-none br-left-1 br-clr bg-transparent h-100">
                            <div className="bg-blue app-padding py-2">
                                <div className="row">
                                    <div className="col-md-2"><button class="btn-icon-transparent-add" onClick={() => AddEducation(0)}></button></div>
                                    <div className="col-md-10"><p className="text text-white fs-16">Add New Education</p></div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={EducationValidationModal.Title ? "form-group input error" : "form-group input"}>
                                    <label>Title</label>
                                    <input 
                                    type="text"
                                        className="form-control"
                                        name="Title"
                                        id="Title"
                                        maxLength={35}
                                        value={mainEducationModal.Title}
                                        onChange={handleEducationChange}
                                    />
                                    {EducationValidationModal.Title}
                                </div>
                                <div className={EducationValidationModal.Institute ? "form-group input error" : "form-group input"}>
                                    <label>Institute</label>
                                    <input 
                                    type="text"
                                        className="form-control"
                                        name="Institute"
                                        id="Institute"
                                        maxLength={35}
                                        value={mainEducationModal.Institute}
                                        onChange={handleEducationChange}
                                    />
                                    {EducationValidationModal.Institute}
                                </div>
                                <div className={`form-group input ${dateValidation.StartDate}`}>
                                    <CalendarInput
                                        label='Start Date'
                                        value={mainEducationModal.StartDate}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'StartDate')}
                                        maxDate={new Date()}
                                        anchorEl={startDate}
                                        open={openStartDate}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'StartDate')}
                                    />
                                    {dateValidation.StartDate === 'error' ? <label>Start Date must be less than End Date</label> : ''}
                                </div>
                                <div className={`form-group input ${dateValidation.EndDate}`}>
                                    <CalendarInput
                                        label='End Date'
                                        value={mainEducationModal.EndDate}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'EndDate')}
                                        maxDate={new Date()}
                                        anchorEl={endDate}
                                        open={openEndDate}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'EndDate')}
                                    />
                                    {dateValidation.EndDate === 'error' ? <label>End Date must be greater than Start Date</label> : ''}
                                </div>
                                <div className="form-group input">
                                    <label>GPA/Scores</label>
                                    <input type="text"
                                        className="form-control"
                                        name="GPA"
                                        value={mainEducationModal.GPA}
                                        id="GPA"
                                        onChange={handleEducationChange}
                                    />
                                </div>
                                <div className="form-group input">
                                    <label>Current Status</label>
                                    <input 
                                    type="text"
                                        className="form-control"
                                        name="CurrentStatus"
                                        value={mainEducationModal.CurrentStatus}
                                        id="CurrentStatus"
                                        maxLength={10}
                                        onChange={handleEducationChange}
                                    />
                                </div>
                            </div>
                        </div>

                    </div> */}
            {/* </div> */}
            {/* <div className='tabs-action-bottom'>
                    <div className='btns-group text-end px-3 br-top-1 br-clr d-flex align-items-center justify-content-end h-100'>
                        <button className='btn btn-gray'onClick={()=>dispatch(CancelButton(true))}>Cancel</button>
                        <button className='btn btn-orange' onClick={handleSaveEducation}>Save</button>
                    </div>
                </div> */}
        </React.Fragment >
    )
}

export default Education