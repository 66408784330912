import axios from "axios";
import store from '../store';
import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import NotificationHandler from "./NotificationHandler";
import { enumUtil } from "./Enum";



const LoginServerRequestProtected = (endpoint, method, payload) => {
    const axiosConfigation = {
        method,
        url: endpoint,
        data: payload,
        headers: {
            Accept: '*/*',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Max-Age': '6000',
            'Access-Control-Allow-Headers': '*'
        },
    }
    return new Promise((resolve, reject) => {
        axios(axiosConfigation)
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error)

            })
    })
}

const ServerRequestProtectedParentID = async (endpoint, method, payload) => {

    // const navigate = useNavigate();
    // grab current state
    const state = store.getState();
    // get the token out of it
    const token = state.TokenReducer;
    let Token = token && jwt_decode(token)
    // get  who is logged in UserId
    console.log("state.Organization", state.Organization)
    let UserID = Token.UserID;
    let Data = payload.data ? {

        // "ParentID": state.Organization, "UserID": UserID, 
        ...payload
    }
        : { 
            // "ParentID": state.Organization, 
        ...payload }
    const authorizationTokenHeader = token
        ? {
            Authorization: `Bearer ${token}`,
        }
        : {}
    const axiosConfigation = {
        method,
        url: endpoint,
        data: Data,
        headers: {
            Accept: '*/*',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Max-Age': '6000',
            'Access-Control-Allow-Headers': '*',
            ...authorizationTokenHeader,
        },
    }

    return new Promise((resolve, reject) => {
        axios(axiosConfigation)
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                console.log("error", error)
                if (error.response && error.response.status === 401) {
                    let data = window.location.href.split("/").slice(0, -2).join("/")
                    setTimeout(() => {
                        window.location.replace(data);
                    }, 2000)
                    error.response.data = "Unauthorized Token...";
                    reject(error);
                } else {
                    // Handle other errors
                    reject(error)
                    // console.error('An error occurred:', error.message);
                }

            })
    })
}

const ServerRequestProtected = async (endpoint, method, payload) => {
    // grab current state
    const state = store.getState();
    // get the token out of it
    const token = state.TokenReducer;
    let Token = token && jwt_decode(token)
    console.log("Token", Token.exp)
    // get  who is logged in "UserID"
    let UserID = Token.UserID;
    let Data = payload.data ? {
        // "UserID": UserID, 
        ...payload
    } : { ...payload }

    const authorizationTokenHeader = token
        ? {
            Authorization: `Bearer ${token}`,
        }
        : {}
    const axiosConfigation = {
        method,
        url: endpoint,
        data: Data,
        headers: {
            Accept: '*/*',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Access-Control-Max-Age': '6000',
            'Access-Control-Allow-Headers': '*',
            ...authorizationTokenHeader,
        },
    }

    return new Promise((resolve, reject) => {
        axios(axiosConfigation)
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {

                if (error.response && error.response.status === 401) {
                    let data = window.location.href.split("/").slice(0, -2).join("/")
                    
                    setTimeout(() => {
                        window.location.replace(data);
                    }, 2000)
                    error.response.data = "Unauthorized Token...";
                    reject(error);
                }
                else {
                    // Handle other errors
                    reject(error)
                    // console.error('An error occurred:', error.message);
                }

            })
    })
}

const ServerRequestPublic = (endpoint, method, payload, type) => {
    // grab current state
    const state = store.getState();
    // get the token out of it
    const token = state.TokenReducer;
    let Token = token && jwt_decode(token)
    // get  who is logged in UserId
    let UserID = Token.UserID;
    let Data = payload.data ? {
        // "UserID": UserID, 
        ...payload
    }
        : { ...payload }
    const authorizationTokenHeader = token
        ? {
            Authorization: `Bearer ${token}`,
        }
        : {}

    let axiosConfigation;
    // axios obj
    if (type === "Download") {
        axiosConfigation = {
            method,
            url: endpoint,
            data: Data,
            responseType: 'blob',
            headers: {
                Accept: '*/*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Max-Age': '6000',
                'Access-Control-Allow-Headers': '*',
                "Content-Type": "application/json",
                ...authorizationTokenHeader,
            },
        }
    }

    else if (type === "Delete") {
        axiosConfigation = {
            method,
            url: endpoint,
            data: payload,
            responseType: 'blob',
            headers: {
                Accept: '*/*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Max-Age': '6000',
                'Access-Control-Allow-Headers': '*',
                "Content-Type": "multipart/form-data",
                ...authorizationTokenHeader,
            },
        }
    }

    else if (type === "Upload") {
        axiosConfigation = {
            method,
            url: endpoint,
            data: payload,
            headers: {
                Accept: '*/*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Max-Age': '6000',
                'Access-Control-Allow-Headers': '*',
                "Content-Type": "multipart/form-data",
                ...authorizationTokenHeader,
            },
        }
    }
    return new Promise((resolve, reject) => {
        axios(axiosConfigation)
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export { LoginServerRequestProtected, ServerRequestProtectedParentID, ServerRequestProtected, ServerRequestPublic }