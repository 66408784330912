import React, { useState } from "react";
import NoDataFound from "./NoDataFound";
import LetterProfilePicture from "../shared/LetterProfilePicture";
import { isFemale, isMale } from "../../../../utils/Constants";

const NewHirings = (props) => {
  const [showAllMembers, setShowAllMembers] = useState({});

  const handleShowAllMembers = (id) => {
    setShowAllMembers((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  return (
    <div className="dashboard">
      <div className="employe_hiring card_tabs col-md-12">
        <p className="heading pb-2">New Hirings</p>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-15 align-items-center overflow-auto ar_hov-scrollbar">
            {props?.data?.length > 0 ? (
              props?.data?.map((emp, index1) => {
                if (showAllMembers[props?.data?.[0]?.GroupName] || index1 < 2) {
                  console.log("first", props?.data);
                  return (
                    <div className="d-flex flex-column align-items-center">
                      <div className="img-container circle border-2 border-white s-50">
                          {emp.ProfilePicture ? (
                          <img src={emp.ProfilePicture} alt="Profile" />
                          ) : (
                          <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-20 text-white ${isMale(emp.Gender) ? 'male' : isFemale(emp.Gender) ? 'female':'rather-undefined'}`} name={emp.EmployeeName} />
                          )}
                      </div>
                      <div className="text-center">
                        <p className="heading fs-11 font-m">
                          {emp.EmployeeName}
                        </p>
                        <p
                          className="text fs-10 validation_message designation text-nowrap"
                          title={emp.DesignationName}
                        >
                          {emp.DesignationName}
                        </p>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="position-relative" style={{ height: 100 }}>
                <NoDataFound />
              </div>
            )}
            {props?.data?.length > 2 ? (
              <div
                className="cursor-pointer text-center"
                onClick={() => {
                  handleShowAllMembers(props?.data?.[0]?.GroupName);
                }}
              >
                {showAllMembers[props?.data?.[0]?.GroupName] ? (
                  <>
                    <p className="text font-m link-text">
                      -{props?.data?.length - 2}
                    </p>
                    <p className="text font-m link-text">less</p>
                  </>
                ) : (
                  <>
                    <p className="text font-m link-text">
                      +{props?.data?.length - 2}
                    </p>
                    <p className="text font-m link-text">more</p>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHirings;
