import isNull from "../utils/NullChecking";

const initialState = 'MM/DD/YYYY';

const DateFormateReducer  = (state = initialState, action) => {
   
    switch(action.type){
        case 'DateFormat': {
            return !isNull(action.payload) ? action.payload : state } ;
        default: return state;
    }
}
export default DateFormateReducer;