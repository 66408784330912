import React from "react"
import AddNewOrganization from "./AddNewOrganization"

const Organization = (props) => {
    return (
        <>
            <AddNewOrganization/>
        </>
    )
}
export default Organization