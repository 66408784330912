export const TabAction = (data, id, sideNavKey, childActiveId) => {
    return {
        type: 'OPEN_TAB',
        payload: {
            data: data,
            id: id ? id : undefined,
            sideNavKey: sideNavKey,
            childActiveId: childActiveId
        },
    }
}

export const NewTabAction = (data, id, sideNavKey, childActiveId) => {
    return {
        type: 'UPDATE_OPEN_TAB',
        payload: {
            data: data,
            id: id ? id : undefined,
            sideNavKey: sideNavKey,
            childActiveId: childActiveId
        },
    }
}
export const MenuActiveTab = (key) => {
    return {
        type: 'MenuActiveTab',
        payload: key
    }
}

export const BackToSetup = (TabIndex) => {
    return {
        type: 'BackToSetup',
        payload: TabIndex
    }
}
export const BackToOffice = (TabIndex) => {
    return {
        type: 'BackToOffice',
        payload: TabIndex
    }
}


// export const BackToEmployee = (TabIndex) => {
//     return {
//         type: 'BackToEmployee',
//         payload: TabIndex
//     }
// }

// export const BackToDepartment = (TabIndex) => {
//     return {
//         type: 'BackToDepartment',
//         payload: TabIndex
//     }
// }