import React, { useState } from "react";
import SetupMenu from "./SetupMenu"
import { Nav, NavItem, NavLink } from 'reactstrap'
import SetupTopMenuContent from "./SetupTopMenuContent";
// import { useDispatch } from "react-redux";
// import { SearchSetup } from "../../../actions/SerachParam"; 

const SetupTopMenu = () => {
    const MenuItems = SetupMenu();
    const activeTabElement = MenuItems.filter((item) => item.right === true)[0]
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState(61)
    const handleActiveTab = (item) => {
        const { key, name, right } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        }) 
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }
    return (
        <div className='app-tabs setup_tabs'>
            <Nav className="rb-tabs d-flex align-content-center gap-40 ps-3" >
                {MenuItems && MenuItems.map((item) => {
                    return (
                        <React.Fragment key={item.key}>
                            <NavItem onClick={() => handleActiveTab(item)}>
                                <NavLink href="#" className={activeTab === item.key ? "active p-0" : "p-0"}>{item.name}</NavLink>
                            </NavItem>
                        </React.Fragment>

                    )
                })}
            </Nav>
            <SetupTopMenuContent activeTab={activeTab} dynamicTabs={tabList} />
        </div>

    )
}
export default SetupTopMenu