// common funtion that will handle input validations

import { enumUtil } from "./Enum"
import isNull from "./NullChecking"


const FormValidator = (data, type, name, length) => {
    // data will be input field value of modal 
    // type will be required or null check etc 
    // name will be used for returning msg
    // length if we are going to check lenght of filed then type will be lengthCheck

    if (data instanceof Array && type === enumUtil.enumValidationType.NullCheck) {
        //if any value of Array is a Object then it will retrun true otherwise false

        if (!isNull(data)) {
            let array = Object.values(data[0])
            let dummy_Array = []
            for (let i = 0; i < array.length; i++) {
                dummy_Array.push(array[i])
            }
            let result = !dummy_Array.every((VALUE) => !hasObject(VALUE))
            return result
        }

    }
    if (type === enumUtil.enumValidationType.Required) {
        if (isNull(data)) {
            return (
                <div className="text-error" style={{ display: "block" }}>
                    {capitalize(name)} Is Required!
                </div>
            )
        } else {
            return ''
        }
    } else if (type === enumUtil.enumValidationType.Length) {
       
        if (!isNull(data) && data?.length < length && data?.length > 0) {
            return (
                <div className="text-error" style={{ display: "block" }}>
                    {' '}
                    {capitalize(name)} length should be {length} !
                </div>
            )
        } else {
            return ''
        }
        ///////////////////////// For Normal Validation Code END/////////////////////
    }
    // else if (type === enumUtil.enumValidationType.Password) {
    //     console.log("dsds")
    //     if (data.length < length && data.length > 0) {
    //         return (
    //             <div className="text-error" style={{ display: "block" }}>
    //                 {' '}
    //                 {capitalize(name)} length should be greater than {length} !
    //             </div>
    //         )
    //     } else {
    //         return ''
    //     }
    // }
    else if (type === enumUtil.enumValidationType.Password) {
        var regularExpression = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/
        if (!regularExpression.test(data) && data.length > 0) {
            return (
                <div className="text-error" style={{ display: "block" }}>
                    {' '}
                    {capitalize(name)} must contain number, uppercase letter, special character, no space, and must be atleast 8 characters long.
                </div>
            )
        }
    }

    else if (type === enumUtil.enumValidationType.Email) {

        const Regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (data && !Regex.test(data) && data?.length > 0) {
            return (
                <div className="text-error" style={{ display: "block" }}>
                    {' '}
                    {capitalize(name)} must be valid.
                </div>
            )
        }
    }

    else if (type === enumUtil.enumValidationType.Website) {

        const Reg = /^www\.[A-Za-z0-9-]+\.[A-Za-z]{2,4}$/g;
        if (data && !Reg.test(data) && data?.length > 0) {
            return (
                <div className="text-error" style={{ display: "block" }}>
                    {' '}
                    {capitalize(name)} must be valid.
                </div>
            )
        }
    }
    else if (data instanceof Array && type === enumUtil.enumValidationType.arrayOfObject) {

        // function hasNonNullProperty(arrayOfObjects) {
            for (let i = 0; i < data.length; i++) {
                const obj = data[i];
                for (const key in obj) {
                    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
                        return true; // Found a non-null, non-undefined, non-empty value
                    }
                }
            }
            return false; // No non-null, non-undefined, non-empty value found in any property of any object
        // }

    }
    else {
        return ''
    }
}

// first letter of str to cap like name to Name
const capitalize = (params) => {
    if (typeof params === 'string') {
        return params.charAt(0).toUpperCase() + params.slice(1)
    }
    return null
}

// check if value is object 
const hasObject = (value) => {
    if (value instanceof Object) {
        return true
    } else {
        return false
    }
}
export default FormValidator