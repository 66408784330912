import React from 'react'
// import patientIcon from "../../assetsOld/img/patient_icon.png"
// import schedulerIcon from "../../assetsOld/img/schedular_icon.png"
// import chargesIcon from "../../assetsOld/img/charges_icon.png"
// import reportIcon from "../../assetsOld/img/reports_icon.png"
// import setupIcon from "../../assetsOld/img/setup_icon.png"
// import dashboard from "../../assetsOld/img/dashboard_icon.png"
import Employee from "../Employee/Employee"
import EmployeeAttendance from "../EmployeeAttendance/EmployeeAttendance"
import miscIcon from "../../assetsOld/img/misc_icon.png"
// import Payroll from "../Payroll/Payroll"
import Setup from "../Setup/Setup"
import Miscellaneous from "../Miscellaneous/Miscellaneous"
import Expense from "../Expenses/Expense"
import EmployeeTopMenu from '../MyInfo/EmployeeLayout/EmployeeTopMenu'
import { enumUtil } from "../../utils/Enum"
import Setting from '../Setting/Setting'
import MyAttendance from '../MyAttendance/MyAttendance'
import EmployeeLeave from '../Leaves/EmployeeLeave'
import DailyTracking from '../DailyTracking/DailyTracking'
// import PayrollSearch from '../Payroll/PayrollAdmin'
// import PayrollAdmin from '../Payroll/PayrollComponents/PayrollAdmin/PayrollAdmin'
import Payroll from '../Payroll/PayrollComponents/PayrollAdmin/Payroll'
// import { DashboardAdmin } from '../Dashboard/DashboardAdmin'
import EmployeeDashboard from '../Dashboard/EmployeeDashboard/EmployeeDashboard'
import AdminDashboard from '../Dashboard/AdminDashboard/AdminDashboard'
import TeamDashboard from '../Dashboard/TeamDashboard/TeamDashboard'
import { AllRequests } from '../AllRequest/AllRequests'
import Payslip from '../Payroll/PayrollComponents/PayrollEmployee/PaySlip'
import AllEmployeesTask from '../DailyTracking/DailyTrackingComponents/AllEmployeesTask'

const GetMenuDetails = (key) => {
    if (key === enumUtil.menuItemsList.DashboardEmployeeKey) {
        return {
            name: 'Dashboard',
            key: enumUtil.menuItemsList.DashboardEmployeeKey,
            icon: "icon-Vector6 fs-15",
            component: <EmployeeDashboard />,
            right: true,
        }
    }
    else if (key === enumUtil.menuItemsList.DashboardAdminKey) {
        return {
            name: 'Dashboard',
            key: enumUtil.menuItemsList.DashboardAdminKey,
            icon: "icon-Vector6 fs-15",
            component: <AdminDashboard />,
            right: true,
        }
    }
    else if (key === enumUtil.menuItemsList.DashboardTeamKey) {
        return {
            name: 'Dashboard',
            key: enumUtil.menuItemsList.DashboardTeamKey,
            icon: "icon-Vector6 fs-15",
            component: <TeamDashboard />,
            right: true,
        }
    }
    else if (key === enumUtil.menuItemsList.EmployeeKey) {
        return {
            name: 'Employee',
            key: enumUtil.menuItemsList.EmployeeKey,
            icon: "icon-Vector-21",
            component: '',
            right: true,
            subComponents: EmployeeMenuList(),
        }
    }
    // else if(key === enumUtil.menuItemsList.EmployeeKey){
    //     return{
    //         name: 'Manage Employee',
    //         key: enumUtil.menuItemsList.EmployeeKey,
    //         icon: "icon-Vector-21",
    //         component: <Employee />,
    //         right: true,
    //     }
    // } 
    else if (key === enumUtil.menuItemsList.ExpenseKey) {
        return {
            name: 'Expenses',
            key: enumUtil.menuItemsList.ExpenseKey,
            icon: "icon-Icon-ionic-ios-wallet",
            component: <Expense />,
            right: true,
        }
    }
    // else if (key === enumUtil.menuItemsList.PayrollKey) {
    //     return {
    //         name: 'Payroll',
    //         key: enumUtil.menuItemsList.PayrollKey,
    //         icon: "icon-payroll",
    //         component: <Payroll />,
    //         right: true,
    //     }
    // }
    else if (key === enumUtil.menuItemsList.PayslipKey) {
        return {
            name: 'Payslip',
            key: enumUtil.menuItemsList.PayslipKey,
            icon: "icon-Payments",
            component: <Payslip />,
            right: true,
        }
    }
    // else if (key === enumUtil.menuItemsList.AttendenceKey) {
    //     return {
    //         name: 'Attendance',
    //         key: enumUtil.menuItemsList.AttendenceKey,
    //         icon: "icon-Icon-clock",
    //         component: <EmployeeAttendance />,
    //         right: true,
    //     }
    // }
    else if (key === enumUtil.menuItemsList.MiscllaneousKey) {
        return {
            name: 'Miscellaneous',
            key: enumUtil.menuItemsList.MiscllaneousKey,
            icon: miscIcon,
            component: <Miscellaneous />,
            right: true,
        }
    } else if (key === enumUtil.menuItemsList.SetupKey) {
        return {
            name: 'Setup',
            key: enumUtil.menuItemsList.SetupKey,
            icon: "icon-setup text-white",
            component: <Setup />,
            right: true,
        }
    } else if (key === enumUtil.menuItemsList.MyInfo) {
        return {
            name: 'My Info',
            key: enumUtil.menuItemsList.MyInfo,
            icon: "icon-info-icon",
            component: <EmployeeTopMenu />,
            right: true,
        }
    } else if (key === enumUtil.menuItemsList.Logout) {
        return {
            name: 'Logout',
            key: enumUtil.menuItemsList.Logout,
            icon: "icon-Group-icon",
            // component:  <>Logout</>,
            right: true,
        }
    } else if (key === enumUtil.menuItemsList.Leaves) {
        return {
            name: 'My Requests',
            key: enumUtil.menuItemsList.Leaves,
            icon: "icon-request",
            component: <EmployeeLeave />,
            right: true,
        }
    } else if (key === enumUtil.menuItemsList.Setting) {
        return {
            name: 'Settings',
            key: enumUtil.menuItemsList.Setting,
            icon: "icon-settings1",
            component: <Setting />,
            right: true,
        }
    } else if (key === enumUtil.menuItemsList.EmployeeAttendance) {
        return {
            name: "My Attendance",
            key: enumUtil.menuItemsList.EmployeeAttendance,
            icon: "icon-attandence text-white",
            component: <MyAttendance />,
            right: true,
        }
    } else if (key === enumUtil.menuItemsList.DailyTracking) {
        return {
            name: "Daily Tracking",
            key: enumUtil.menuItemsList.DailyTracking,
            icon: "icon-tracking text-white",
            component: <DailyTracking />,
            right: true,
        }
    }
    // else if (key === enumUtil.menuItemsList.AllRequest) {
    //     return {
    //         name: "All Request",
    //         key: enumUtil.menuItemsList.AllRequest,
    //         icon: "icon-Icon-clock",
    //         component: <AllRequests />,
    //         right: true,
    //     }
    // }
}
export { GetMenuDetails };

export const EmployeeMenuList = () => [
    {
        name: 'Manage Employee',
        key: 21,
        icon: "icon-manage-icon",
        component: <Employee />,
        right: true,
    },
    {
        name: 'Attendance',
        key: 22,
        icon: "icon-attandence",
        component: <EmployeeAttendance />,
        right: true,
    },
    {
        name: 'Daily Progress',
        key: 23,
        icon: "icon-tracking text-white",
        component: <AllEmployeesTask />,
        right: true,
    },
    {
        name: 'Payroll',
        key: 24,
        icon: "icon-Payments",
        component: <Payroll />,
        right: true,
    },
    {
        name: "Request",
        key: 25,
        icon: "icon-request",
        component: <AllRequests />,
        right: true,
    }

]
