
export const enumUtil = {
    enumtoaster: {
        success: 'success',
        info: 'info',
        error: 'error',
        warning: 'warning',
        basic: 'basic',
        dark: 'dark',
    },
    enumValidationType: {
        Required: 'requried',
        NullCheck: 'nullcheck',
        Length: 'length',
        Password: "Password",
        Email:"Email",
        arrayOfObject:'arrayOfObject',
        Website: "Website",
    },
    enumAPITableName: {
        Dashboard:'Dashboard',
        TodayEmployeeList : 'TodayEmployeeList',
        Organization: 'Organization',
        Office : "Office",
        Team:"Team",
        Designation:"Designation",
        Shift:"Shift",
        Employee: 'Employee',
        Department: 'Department',
        Expense: "Expense",
        ExpenseCategory: 'ExpenseCategory',
        Experience:'Experience',
        Skill:'Skill',
        JoiningDetails:'JoiningDetails',
        Certification:'Certification',
        Education:'Education',
        Stock:'Stock',
        Product:'Product',
        Vendor:'Vendor',
        EmployeeStockReference:'EmployeeStockReference',
        EmployeeAttendence:'EmployeeAttendence',
        LeaveType: 'LeaveType',
        EmployeeLeave: "EmployeeLeave",
        BenefitType: "BenefitType",
        OrganizationBenefit: "OrganizationBenefit",
        FileReference:"FileReference",
        LeavePeriod:"LeavePeriod",
        LeaveType: "LeaveType",
        Roles: "AspnetRoles",
        UserOrganization: "UserOrganization",
        EmployeeDocument:"EmployeeDocument",
        UserActivity:"UserActivity",
        LateCountPolicies:"LateCountPolicies",
        Attendance:"Attandance",
        NationalHolidayEventsPolicy:"NationalHolidayEventsPolicy",
        DocumentType:"DocumentType",
        EmployeeTimeAdjustment:"EmployeeTimeAdjustment",
        DAILYPROGRESS:"DAILYPROGRESS",
        Payroll:"PayRoll",
        CareerGrowth: "CareerGrowth",
        SessionScreenshot: "SessionScreenshot",
        ActivitySession: "ActivitySession",
        Resignation: "Resignation",
        REQUEST: 'REQUEST',
        Announcement: 'Announcement',
        TeamLead : 'TeamLead',
        LeavePolicy:'LeavePolicy'
    },
    enumController:{
        Dashboard:'Dashboard',
    },
    menuItemsList: {
        DashboardEmployeeKey: 1,
        EmployeeKey: 2,
        ExpenseKey: 3,
        PayrollKey: 4,
        AttendenceKey: 5,
        MiscllaneousKey: 6,
        SetupKey: 7,
        MyInfo: 8,
        Setting:9,
        EmployeeAttendance:10,
        Logout:12,
        Leaves:13,
        DailyTracking:14,
        DashboardAdminKey: 15,
        AllRequest:16,
        DashboardTeamKey: 17,
        PayslipKey:18,
    }

}
