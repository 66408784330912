import React from 'react'
import ClusteredStackedColumnChart from '../Charts/ClusteredStackedColumnChart'
import NoDataFound from './NoDataFound';
const PayrollSummary = ({ data }) => {
    const currentYear = new Date().getFullYear();
    const years = [currentYear, currentYear - 1, currentYear - 2];
    let structuredData = data?.map(item => {
        if (item.name === "GrossSalary" || item.name === "Deductions") {
            return {
                ...item,
                name: item.name === 'GrossSalary' ? 'Gross Salary' : item.name,
                group: 'Payroll',
                type: 'column',
            }
        } else if (item.name === 'NetSalary') {
            return {
                ...item,
                name: 'Net Salary',
            }
        }
    });
    return (
        <>
            <div className="dashboard d-flex mt-2 pe-0">
                <div className="PayrollSummary card_tabs col-md-12 overflow-hidden">
                    <div className="pb-2">
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className="heading">Payroll Summary</p>
                            {/* <div class="input">
                                <select class="form-control" id="PaymentType" name="PaymentType">
                                {years.map((item)=>{return <option value={item}>{item}</option>})}
                                </select>
                            </div> */}
                        </div>
                    </div>
                    {structuredData?.length > 0 ?
                        <ClusteredStackedColumnChart name={'Payroll Summary'} data={structuredData} /> : <div className='position-relative' style={{height:250}}><NoDataFound/></div>}
                </div>
            </div>
        </>
    )
}

export default PayrollSummary