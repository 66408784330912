import React from 'react'
import { useUserData } from '../../../shared/UserData/useUserData'

const WeeklyInsight = ({isviewReport,setIsviewReport,reportData}) => {
    const data = useUserData();
  return (
    <div className="col-md-6 px-0">
        <p className="text fs-13 font-m pb-2 text-nowrap">Good Afternoon <span className="sun">🌤</span> {data?.FirstName || 'N/A'}</p>
        <p className="text xsm pt-1">Here is your weekly overview report</p>
        <div className="position-relative">
            <button className="btn mt-2 btn_orange rounded" onClick={() => setIsviewReport(!isviewReport)}>View Report

            </button>
            {isviewReport &&
                <div className="ar_view-report-popup">
                    <div className="d-flex justify-content-between align-items-center br-bottom-1 pb-1 mb-1">
                        <p className="heading"> Weekly Report</p>
                        <p className="font-m fs-11 fst-italic clr_gry text">{reportData?.[0]?.Date}</p>
                    </div>
                    <div className="content mb-2 pb-1 d-flex flex-column gap-2">
                        <div className="d-flex align-items-center gap-2">
                            <div className="ar_box"></div>
                            <p className="heading font-m blue-color">Attendance</p>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex align-items-center">
                                <p className="text fs-11 clr_gry col-md-4"> Present </p>
                                <p className="text  clr-green font-m"> : {reportData?.[0]?.Present || 'N/A'} </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="text fs-11 clr_gry col-md-4"> Absent  </p>
                            <p className="text  red-color  font-m"> : {reportData?.[0]?.Absent || 'N/A'} </p>
                        </div>

                        <div className="d-flex align-items-center">
                            <p className="text fs-11 clr_gry col-md-4"> Late Count </p>
                            <p className="text late-count font-m"> : {reportData?.[0]?.['Late Count'] || 'N/A'} </p>
                        </div>

                        <div className="d-flex align-items-center">
                            <p className="text fs-11 clr_gry col-md-4"> Leaves </p>
                            <p className="text  yelo-clr font-m"> : {reportData?.[0]?.['Leaves'] || 'N/A'} </p>
                        </div>
                    </div>
                    <div className="content d-flex flex-column gap-2">
                        <div className="d-flex align-items-center gap-2">
                            <div className="ar_box"></div>
                            <p className="heading font-m blue-color">Productivity</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="text fs-11 clr_gry col-md-7"> Total Active Time   </p>
                            <p className="text font-m">   : {reportData?.[0]?.TotalActiveHours || 'N/A'} </p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="text fs-11 clr_gry col-md-7"> Total Active Days  </p>
                            <p className="text  clr-green font-m"> : {reportData?.[0]?.TotalActiveDays || 'N/A'} </p>
                        </div>

                    </div>
                </div>
            }
        </div>
    </div>
  )
}

export default WeeklyInsight