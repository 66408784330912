
import React, { useEffect, useState } from "react"
import { Nav, Tab, Tabs } from "react-bootstrap";
import EmployeeInformation from "../MyInfo/Account/EmployeeInformation/EmployeeInformation"
import Menu from "@mui/material/Menu";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import format from "date-fns/format";
// import AddLeaveForm from './AddLeaveForm';
import { ServerRequestProtected } from '../../utils/ServerDataRequest';
import { enumUtil } from '../../utils/Enum';
import jwt_decode from 'jwt-decode';
import NotificationHandler from '../../utils/NotificationHandler';
import DeleteConfirmation from "../shared/Delete/DeleteConfirmation"
import { useSelector } from 'react-redux';
import { DateFormat, DateFormat_YMd_String, DateFormat_dMy } from '../../utils/Constants';
import Loader from '../shared/Loader/Loader';
import ReactDataGrid from "react-data-grid";
import CustomUseRef from "../shared/CustomUseRef/CustomUseRef"
import AddLeaveForm from "../MyAttendance/AddLeaveForm"
import AddNewLeave from "./AddNewLeave"
import EmployeeLeaveUser from "./EmployeeLeaveUser";
import EmployeeLeaveAdmin from "./EmployeeLeaveAdmin";
import MyLeaveRequests from "./MyLeaveRequests";
import MyOtherRequests from "./MyOtherRequests";
import LeaveTopMenu from "./LeaveLayout/LeaveTopMenu";

const EmployeeLeave = () => {
    let Role = useSelector((state) => state.RoleReducer);
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let UserID = Token.UserID;
    const [isLeaveOpen, setIsLeaveOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const [employeeLeaves, setEmployeeLeaves] = useState([]);
    const [EmployeeLeaveID, setEmployeeLeaveID] = useState(0);
    const [actionsbutton, setActionsbutton] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null);
    const [isRefresh, setIsRefresh] = useState(false)
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [delID, setDelID] = useState(0);
    const [range, setRange] = useState([
        {
            startDate: null,
            endDate: addDays(new Date(), 7),
            // endDate: null,
            key: "selection",
        },
    ]);

    const [position, setPosition] = useState(null);
    const openDateRangePicker = Boolean(position);
    const openDateRangePickerMenu = (event) => {
        setPosition(event.currentTarget);
    };
    const closeDateRangePickerMenu = () => {
        setPosition(null);
    };
    // const handleRangeChange = (date) => {
    //     setValueR(
    //         format(date[0].startDate, "yyyy/MM/dd") +
    //         " to " +
    //         format(date[0].endDate, "yyyy/MM/dd")
    //     );
    //     setRange(date);
    //     setShowCalendarRange(false);
    // };


    /// Load Employee Leaves Api ///

    const loadEmployeeLeaves = () => {
        setEmployeeLeaves([]);
        setIsLoading(true);
        ServerRequestProtected(API_URL + "/FindBySingleCriteria", "post", {
            "TableName": enumUtil.enumAPITableName.EmployeeLeave,
            "SearchCriteria": {
                // "UserID": UserID,
                // "EmployeeID": UserID,
            },
        }).then((response) => {
            setIsLoading(false);
            console.log("Leaves agai", response)
            setEmployeeLeaves(response);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })

    }

    const handleformat = (date, name) => {
        let nameShow = `${name}Show`
        let nameSave = `${name}Save`
        return { [nameShow]: date, [nameSave]: DateFormat_YMd_String(date) };
    }


    const ClosePopupScreen = () => {
        setIsLeaveOpen(false);
    }
    const ClosePopupScreenOnSave = () => {
        setIsLeaveOpen(false);
        loadEmployeeLeaves()
    }

    let PopUpScreen = "";
    if (isLeaveOpen) {
        PopUpScreen = <AddNewLeave isLeaveOpen={isLeaveOpen} onClose={ClosePopupScreen} onCloseSave={ClosePopupScreenOnSave} ID={EmployeeLeaveID} setIsRefresh={setIsRefresh} />
    }

    const handleDeleteModal = (ID) => {
        setDelID(ID)
        setIsOpenAlert(true);
    }

    const onCloseDelete = () => {
        setDelID(null);
        setIsOpenAlert(false)
        loadEmployeeLeaves();
    }

    const onClose = () => {
        setIsOpenAlert(false)
    }

    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeLeave} ID={delID} onClose={onClose} />
    }

    // useEffect(() => {
    //     loadEmployeeLeaves()
    // }, [])

    return (
        <>
            <div className="mt-3">
                <LeaveTopMenu />
                {/* <Tab.Container defaultActiveKey="leaves">
                    <div className='app-tabs'>
                        <div className='tabs-header position-relative mx-auto'>
                            <div className="row h-100 justify-content-center align-items-center">
                                <div className="tabs-v2-custom ar_tab-v2-custom">
                                    <Nav variant="tabs" className="rb-tabs-v3 tabs_view mb-2">
                                        <Nav.Item>
                                            <Nav.Link eventKey="leaves">Leave Requests</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="leavereq">Other Requests</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="leaves">
                            <MyLeaveRequests />
                            {/* <EmployeeLeaveUser /> */}
                {/* </Tab.Pane>
                        <Tab.Pane eventKey="leavereq">
                            <MyOtherRequests /> */}
                {/* <EmployeeLeaveAdmin /> */}
                {/* </Tab.Pane>
                    </Tab.Content>
                </Tab.Container> */}

            </div>
        </>
    )
    // } else {
    //     return (
    //         <EmployeeLeaveUser />
    //     )
    // }

}

export default EmployeeLeave
