import React, { useEffect, useState } from "react"
import Modal from 'react-bootstrap/Modal'
import close from "../../../../assetsOld/img/x-close.svg"
import ModalFooter from '../../../shared/ModalFooter/ModalFooter'
import isNull from "../../../../utils/NullChecking"
import { ServerRequestProtected } from "../../../../utils/ServerDataRequest"
import Loader from "../../../shared/Loader/Loader"
import { enumUtil } from "../../../../utils/Enum"

const OpenPayslip = (props) => {
    const { isPaySlipOpen, onClose, searchData } = { ...props }
    const { ID, Month, Year } = { ...searchData };
    const [isLoading, setIsLoading] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const [paySlipData, setPaySlipData] = useState([]);

    const LoadData = () => {
        if (!isNull(searchData.ID)) {
            setPaySlipData([])
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/GetPayrollSlip", "post", {
                "TableName": enumUtil.enumAPITableName.Payroll,
                // "UserID": ID,
                "SearchCriteria": {
                    "Month": Month,
                    "Year": Year
                },
            })
                .then((response) => {
                    setIsLoading(false);
                    setPaySlipData(response);
                    // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
                }).catch((error) => {
                    setIsLoading(false);
                    // NotificationHandler(error?.message, enumUtil.enumtoaster.error)
                })
        }
    }
    useEffect(() => {
        LoadData()
    }, [])

    const FieldOutput = (data, type) => {
        return ["", undefined, null].includes(data) ? 'N/A' : `${type === 'PKR' ? type : ''} ${data}`;
    }
    return (
        <div>
            <Modal show={isPaySlipOpen} onHide={isPaySlipOpen} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal payslip">
                <Modal.Body>
                <div className='d-flex justify-content-end '>
                        <div className="d-flex align-items-center gap-2">
                        <span class="icon-Group-71301"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                            <i className="icon-pslip-download"></i>
                            <img className="close position-static" src={close} onClick={() => onClose()}></img>
                        </div>

                    </div>
                    <div className="bg-white ar_content">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="heading sm">{FieldOutput(paySlipData[0]?.OrganizationName, "")}</p>
                                <p className="text clr_gry">{FieldOutput(paySlipData[0]?.OrganizationAddress, "")}</p>
                            </div>
                            <div>
                                <p className="text clr_gry">Payslip For the Month</p>
                                <p className="heading sm">{FieldOutput(paySlipData[0]?.PayPeriod, "")}</p>
                            </div>
                        </div>
                        <div className="bottom_border mt-2"></div>
                        <div className="row m-0 justify-content-between mt-3">
                            <div className="col-md-4 ps-0">
                                <p className="gry_heading sm">EMPLOYEE SUMMARY</p>
                                <div className="row m-0">
                                    <div className="col-md-6 ps-0">
                                        <p className="gry_heading">Employee Name</p>
                                    </div>
                                    <div className="col-md-6 ps-0">
                                        <p className="text">:  {FieldOutput(paySlipData[0]?.EmployeeName, "")}  </p>
                                    </div>
                                </div>
                                <div className="row m-0 mt-1">
                                    <div className="col-md-6 ps-0">
                                        <p className="gry_heading">  Employee ID</p>
                                    </div>
                                    <div className="col-md-6 ps-0">
                                        <p className="text">:  {FieldOutput(paySlipData[0]?.EMPLOYEEID, "")}  </p>
                                    </div>
                                </div>
                                <div className="row m-0 mt-1">
                                    <div className="col-md-6 ps-0">
                                        <p className="gry_heading"> Pay Period</p>
                                    </div>
                                    <div className="col-md-6 ps-0">
                                        <p className="text">:  {FieldOutput(paySlipData[0]?.PayPeriod, "")}  </p>
                                    </div>
                                </div>
                                <div className="row m-0 mt-1">
                                    <div className="col-md-6 ps-0">
                                        <p className="gry_heading">Pay Date</p>
                                    </div>
                                    <div className="col-md-6 ps-0">
                                        <p className="text">:  {FieldOutput(paySlipData[0]?.PayDate, "")}  </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 pe-0">
                                <div className="cards">
                                    <div className="cards_content">
                                        <div className="d-flex gap-3">
                                            <div className="left_border"></div>
                                            <div>
                                                <p className="heading xl">{FieldOutput(paySlipData[0]?.NetSalary, 'PKR')}</p>
                                                <p className="gry_heading sm"> Employee Net Pay</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 px-3 mb-2">
                                        <div className="d-flex gap-3">
                                            <p className="gry_heading"> Paid Days</p>
                                            <p className="text ms-3">:  {FieldOutput(paySlipData[0]?.PaidDays, '')}</p>
                                        </div>
                                        <div className="d-flex mt-1 gap-3">
                                            <p className="gry_heading">UnPaid Days</p>
                                            <p className="text">: {FieldOutput(paySlipData[0]?.UnPaidDays, '')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 row m-0 cards pt-3 pb-0">
                            <div className="col-md-6 ps-3">
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <p className="heading">EARNINGS</p>
                                    </div>
                                    <div className="col-md-6 p-0 text-end">
                                        <p className="heading">AMOUNT</p>
                                    </div>
                                </div>
                                <div className="bottom_border my-2"></div>
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <p className="gry_heading">Basic</p>
                                    </div>
                                    <div className="col-md-6 p-0 text-end">
                                        <p className="heading">{FieldOutput(paySlipData[0]?.BasicSalary, 'PKR')}</p>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <p className="gry_heading">Petrol Allowance</p>
                                    </div>
                                    <div className="col-md-6 p-0 text-end">
                                        <p className="heading">{FieldOutput(paySlipData[0]?.PetrolAllowance, 'PKR')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 pe-3">
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <p className="heading">DEDUCTIONS</p>
                                    </div>
                                    <div className="col-md-6 p-0 text-end">
                                        <p className="heading">AMOUNT</p>
                                    </div>
                                </div>
                                <div className="bottom_border my-2"></div>
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <p className="gry_heading">Leave Deductions</p>
                                    </div>
                                    <div className="col-md-6 p-0 text-end">
                                        <p className="heading">{FieldOutput(paySlipData[0]?.LeaveDeductions, 'PKR')}</p>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-md-6 p-0">
                                        <p className="gry_heading">Other Deductions</p>
                                    </div>
                                    <div className="col-md-6 p-0 text-end">
                                        <p className="heading">{FieldOutput(paySlipData[0]?.OtherDeductions, 'PKR')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0 mt-3 total">
                                <div className="col-md-6 ps-1">
                                    <div className="row m-0">
                                        <div className="col-md-6">
                                            <p className="gry_heading">Gross Earnings</p>
                                        </div>
                                        <div className="col-md-6 p-0 text-end">
                                            <p className="heading clr-green">{FieldOutput(paySlipData[0]?.GrossEarnings, 'PKR')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pe-1">
                                    <div className="row m-0">
                                        <div className="col-md-6 p-0">
                                            <p className="gry_heading">Total Deductions</p>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <p className="heading clr-red">{FieldOutput(paySlipData[0]?.TotalDeductions, 'PKR')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cards mt-2">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="ps-3">
                                    <p className="heading sm">TOTAL NET PAYABLE</p>
                                    <p className="text clr_gry">Gross Earnings - Total Deductions  </p>
                                </div>
                                <div className="net_payment py-3 ps-3 pe-2">
                                    <p className="heading sm clr-green">{FieldOutput(paySlipData[0]?.NetSalary, 'PKR')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 text-end pb-3 mb-1">
                            <p className="text font-m fs-13"><span className="clr_gry">Amount In Words </span>: {paySlipData[0]?.AmountInWords || 'N/A'}</p>
                        </div>
                        <div className="br-top-1 clr_light pt-2 text-center">
                            <p className="text fst-italic fs-11 clr_gry">This payslip <span className="clr_blue"> is system</span>-generated</p>
                        </div>
                        {isLoading ? <Loader /> : null}
                    </div>
                </Modal.Body>
                {/* <ModalFooter isSaved={onClose} handleSave={""} /> */}
            </Modal>
        </div>
    )
}

export default OpenPayslip
