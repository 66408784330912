import React, { useState } from "react";
import ExpenseMenu from "./ExpenseMenu"
import { Nav, NavItem, NavLink } from 'reactstrap'
import ExpenseTopMenuContent from "./ExpenseTopMenuContent";


const ExpenseTopMenu = () => {
    const MenuItems = ExpenseMenu();
    const activeTabElement = MenuItems.filter((item) => item.right === true)[0]
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState(61)

    const handleActiveTab = (item) => {
        const { key, name, right } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }


    return (

        <React.Fragment>
            <ExpenseTopMenuContent activeTab={activeTab} dynamicTabs={tabList} />
        </React.Fragment>

    )
}
export default ExpenseTopMenu