import LetterProfilePicture from "../../Dashboard/AdminDashboard/shared/LetterProfilePicture"

const DataList = ({ data }) => {
    console.log("EmployeeList", data)
    return (
        <>
            {data?.length > 0 ?
                <div className="ar_employee-detail">
                    <div className="my-2 px-2 d-flex flex-column gap-10 scroll ar_hov-scrollbar">
                        {data?.map(emp => {
                            return (
                                <div className="d-flex align-items-center gap-2" key={emp?.EMPLOYEEID}>
                                    <div className="img-container s-30 circle">
                                        {emp.ProfilePicture ? (
                                            <img src={emp.ProfilePicture} alt="Profile" />
                                        ) : (
                                            <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-20 text-white ${emp.Gender === 'M' ? 'male' : emp.Gender === 'F' ? 'female' : 'rather-undefined'}`} name={emp.EmployeeName} />
                                        )}
                                    </div>
                                    <p className="text fs-11">{emp?.EmployeeName}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                : <></>}
        </>)
}

export default DataList