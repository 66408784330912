import React, { useEffect, useMemo } from 'react'
import Modal from 'react-bootstrap/Modal'
import { MonthsDropdown } from '../../../../utils/Constants'
import { useSelector } from 'react-redux';

const CreatePopup = ({ createPopup, setCreatePopup, mainDropDownModal, setMainDropDownModal, handleData, handleMonthChange }) => {
    let currentMonth = new Date().getMonth() + 1;
    let monthIndex = MonthsDropdown?.findIndex(it => it.value == currentMonth);
    let currentYear = new Date().getFullYear();
    let monthsDD = useMemo(() => {
        if (mainDropDownModal?.Year == currentYear) {
            return monthIndex !== -1 ? MonthsDropdown?.slice(0, monthIndex + 1) : MonthsDropdown;
        } else {
            return MonthsDropdown;
        }
    }, [mainDropDownModal?.Year]) 

    return (
        <Modal show={createPopup} onHide={createPopup} backdrop="static" keyboard={false} centered dialogClassName="delete-modal hk-modal-v1 select_mounth">
            <div className="">
                <div className="d-flex align-items-center justify-content-between pb-10">
                    <p className="heading fs-13">Please select month</p>
                    <span onClick={() => setCreatePopup(false)}>
                        <i className='fs-10 icon-Icon-metro-cross'></i>
                    </span>
                </div>
                <div className='d-flex gap-23 align-items-center pb-10'>
                    <div className="dropdown-bgc-position col-md-7">
                        <div class="input">
                            <select class="form-control" id="Month" name="Month" value={mainDropDownModal.Month} onChange={handleMonthChange}>
                                <option value="">Select</option>
                                {monthsDD.map((item) => {
                                    return (
                                        <option value={item.value} >{item.display}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="dropdown-bgc-position">
                        <div class="input">
                            <select class="form-control" id="Year" name="Year" value={mainDropDownModal.Year} onChange={handleMonthChange}>
                                <option value="">Select</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    {(mainDropDownModal.Month && mainDropDownModal.Year) ?
                        <button className='btn btn-blue w-100 rounded' onClick={() => { handleData(); }}>Create Payroll</button> :
                        <button className='btn btn-blue w-100 rounded' onClick={() => { handleData(); }} disabled>Create Payroll</button>}
                </div>
            </div>
        </Modal>
    )
}

export default CreatePopup