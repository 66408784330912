import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { ServerRequestProtectedParentID } from "../../../utils/ServerDataRequest"
import NotificationHandler from "../../../utils/NotificationHandler"
import { enumUtil } from "../../../utils/Enum"
import Loader from "../../shared/Loader/Loader"
import { useState } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

const DeleteConfirmation = (props) => {
    const { requestedID, type, setRequestedID, isOpenAlert, setIsOpenAlert, onCloseDelete, onClose, API_URL, DeleteDucment } = { ...props }
    const [isLoading, setIsLoading] = useState(false)
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let UserID = Token.UserID;

    const handleDelete = () => {
        if (DeleteDucment) {
            DeleteDucment()
        }
        setIsLoading(true)
        let saveModal = {
            // "UserID": UserID,
            "ID": props.ID,
            "TableName": props.TableName,
        }
        if (type) {
            saveModal = { ...saveModal, SearchParam: type }
        }
        ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', saveModal).then((response) => {
            // console.log("resposnse aya",response['value'])
            setIsLoading(false)
            NotificationHandler('Record Deleted Successfully!', enumUtil.enumtoaster.success)
            onCloseDelete()
            props && props.setRequestedID && setRequestedID(!requestedID)
        }).catch((error) => {
            NotificationHandler(error?.message, enumUtil.enumtoaster.error)
            setIsLoading(false)
            onClose()
        })
    }
    
    return (
        <>

            <Modal show={isOpenAlert} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="aprroved_confirmation ar_delete-modal">
                <form action="" onSubmit={(e) => {
                    e.preventDefault()
                }}  >
                    <Modal.Body>
                        {isLoading ? <Loader /> : null}
                        <div className='d-flex align-items-center justify-content-between pb-10'>
                            <p className='heading fs-13 w-100 text-center'>Are you sure?</p>
                            <i className='icon-Icon-metro-cross fs-10' onClick={() => onClose()}></i>
                        </div>
                        <div className='pb-2 mb-1'>
                            <p className="text fs-11 ar-light-clr">Once deleted cannot be recovered.</p>
                        </div>
                        <div className='d-flex align-items-center gap-2 justify-content-center'>
                            <p class="heading xsm cancel cursor-pointer d-flex justify-content-center align-items-center px-0" onClick={onClose}>Cancel</p>
                            <p class="heading xsm deny cursor-pointer d-flex justify-content-center align-items-center px-0" onClick={handleDelete}>Delete</p>
                        </div>
                    </Modal.Body>
                </form>

            </Modal>
        </>
    )
}
export default DeleteConfirmation