import React from 'react'
import { useNavigate } from 'react-router-dom'

const ResetSuccessfull = () => {
    let navigate=useNavigate()
  return (
    <div className="login hk-login">
        <div className="d-lg-flex half">
            <div className="bg order-1 order-md-2">
                <img src={process.env.PUBLIC_URL + "/assets/images/Login_EMS_animation.png"} alt="" />
            </div>
            <div className="contents order-2 order-md-1">
                <h1 className="hk-title">Employee Management System</h1>
                <div className="container hk-form-t">
                    <div className="row align-items-start justify-content-center">
                        <div className="col-md-8 d-flex flex-column justify-content-center text-center">
                            <div className="hk-t-img">
                                <img src={process.env.PUBLIC_URL + "/assets/images/lock-password.png"} alt="confirm email icon" />
                            </div>
                            <h2 class="heading xl text-center">Password reset</h2>
                            <p className="text clr-muted text-center mt-3 forget-p">Your password has been successfully reset.<br/> Click below to log in.</p>
                            <button className="btn rounded btn-blue w-100 mt-4 reset-pass-btn mt-5" onClick={()=>navigate("../", { replace: true })}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResetSuccessfull