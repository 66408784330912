import React, { useEffect, useState } from 'react'
import Nav from "react-bootstrap/Nav";
import Modal from 'react-bootstrap/Modal'
import close from "../../../assetsOld/img/x-close.svg"
import EmployeeInformation from '../../MyInfo/Account/EmployeeInformation/EmployeeInformation'
import ModalFooter from "../../shared/ModalFooter/ModalFooter";
import CalendarInput from '../../../utils/CalendarInput';
import { enumUtil } from '../../../utils/Enum';
import NotificationHandler from '../../../utils/NotificationHandler';
import { ServerRequestProtected } from '../../../utils/ServerDataRequest';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import DeleteConfirmation from '../../shared/Delete/DeleteConfirmation';
import AddNewTask from './AddNewTask';
import Loader from '../../shared/Loader/Loader';
import { format } from 'date-fns';
import { DateFormat, DateFormat_dMy } from "../../../utils/Constants"
import ScreenShot from './ScreenShot';
import OverAllSessionDetails from './OverAllSessionDetails';
import isNull from '../../../utils/NullChecking';
import DOMPurify from 'dompurify';
import AddTaskNew from './AddTask';
import NoDataFound from '../../shared/NoDataFound/NoDataFound';

const TodayProgress = (props) => {

    const { type, setIsRefresh, isRefresh } = { ...props }
    let currentDay = format(new Date(), DateFormat);
    const API_URL = process.env.REACT_APP_API

    let token = useSelector(state => state.TokenReducer);
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let Token = token && jwt_decode(token)
    let UserID = "";
    if (!isNull(type) && type === "Employee") {
        UserID = activeEmployee + "";
    }
    //  else {
    //     UserID = Token.UserID + "";
    // }

    const [activeTab2, setActiveTab2] = useState("todayProgress");
    const [addTodayTask, setAddTodayTask] = useState(false);
    const handleTabSelect2 = (eventKey) => {
        setActiveTab2(eventKey);
    }

    const [TaskID, setTaskID] = useState();
    // const [isRefresh, setIsRefresh] = useState(0);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [delID, setDelID] = useState(0);
    const [isOpenTask, setIsOpenTask] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [TrackingData, setTrackingData] = useState([]);
    const [ScreenShotData, setScreenShotData] = useState({});

    const LoadData = () => {
        setTrackingData([]);
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.DAILYPROGRESS,
            "SearchCriteria": {
                "EmployeeID": UserID + "",
                "DateFrom": currentDay,
                "DateTo": currentDay,
            }
        }
        ServerRequestProtected(API_URL + "/FindByMultipleCriteria", "post", saveModal).then((response) => {
            setIsLoading(false);
            setTrackingData(response);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })
    }

    const LoadScreenShotData = () => {
        setScreenShotData([]);
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.ActivitySession,
            "SearchCriteria": {
                "Datefrom": currentDay,
                "Dateto": currentDay,
                "Search": "no",
                "Session": "Current",
                "EmployeeID": UserID + "",
            }
        }
        ServerRequestProtected(API_URL + "/LoadSessionDetails", "post", saveModal).then((response) => {
            setIsLoading(false);
            setScreenShotData(response);
            setIsRefresh(false)
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            setIsRefresh(false)
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })

    }

    const HtmlContent = ({ html }) => {
        const sanitizedHtml = DOMPurify.sanitize(html);
        console.log("Html", html, "sanitizedHtml", sanitizedHtml)
        const inlineHtml = sanitizedHtml
            .replace(/<ul>/g, '') // Remove <ul> tags
            .replace(/<\/ul>/g, '') // Remove </ul> tags
            .replace(/<ol>/g, '') // Remove <ol> tags
            .replace(/<\/ol>/g, '') // Remove </ol> tags
            .replace(/<li>/g, '') // Remove <li> tags
            .replace(/<\/li>/g, ' ') // Replace </li> tags with a space
            .replace(/<p>/g, '') // Remove <p> tags
            .replace(/<\/p>/g, ' ') // Replace </p> tags with a space
            .replace(/<blockquote>/g, ' ') // Replace </blockquote> tags with a space
            .replace(/<\/blockquote>/g, ' ') // Replace </blockquote> tags with a space
            .replace(/<strong>/g, ' ') // Replace </strong> tags with a space
            .replace(/<\/strong>/g, ' ') // Replace </strong> tags with a space
            .replace(/<a>/g, ' ') // Replace </a> tags with a space
            .replace(/<\/a>/g, ' ') // Replace </strong> tags with a space
            .replace(/<s>/g, ' ') // Replace </s> tags with a space
            .replace(/<\/s>/g, ' ') // Replace </strong> tags with a space
            .replace(/<span>/g, ' ') // Replace </span> tags with a space
            .replace(/<\/span>/g, ' ') // Replace </strong> tags with a space
            .replace(/<img>/g, ' ') // Replace </img> tags with a space
            .replace(/<\/img>/g, ' '); // Replace </strong> tags with a space
            
        return <div dangerouslySetInnerHTML={{ __html: inlineHtml }} />;
    };

    const handleDeleteModal = (ID) => {
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        // setDateValidation({StartDate:'',EndDate:''})
        // setmainEducationModal(Education_Modal)
        setTaskID(0)
        setIsOpenAlert(false)
    }

    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.DAILYPROGRESS} ID={delID} onClose={onClose} />
    }
    const AddTask = (ID) => {
        setIsOpenTask(true)
        setTaskID(ID)
    }

    const CloseTask = () => {
        setIsOpenTask(false)
    }
    const CloseTaskOnSave = () => {
        setIsOpenTask(false);
        LoadData()
    }

    let PopupScreen = "";
    if (isOpenTask) {
        PopupScreen = <AddNewTask isOpenTask={isOpenTask} onClose={CloseTask} OnCloseSave={CloseTaskOnSave} ID={TaskID} setIsRefresh={setIsRefresh} type={type} />
    }

    useEffect(() => {

        LoadData()
        LoadScreenShotData()

    }, [isRefresh])
    return (
        <div className='ar_daily_tracking_screnshot e'>
            <>
                {PopupScreen}
                {ConfirmDelete}
                {isLoading ? <Loader /> : <></>}
                <div className="d-flex m-2 ms-3 justify-content-between align-items-center">
                    <p className="heading">Today’s Progress</p>
                    {!type && <button className="btn px-1 btn-blue rounded" onClick={() => AddTask(0)}>Add Daily Progress</button>}
                </div>
                <div className="today_task">
                    <div className="card_section ms-3 za-nodata-found m-3">

                        {TrackingData?.length > 0 ? TrackingData?.map((item) => {
                            return (
                                <>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="gry_heading">{item?.Date}</p>
                                        {/* <p className="clr_gry text sm">3/5/3034</p> */}
                                    </div>
                                    <div className="text-center mt-2 cursor-pointer" onClick={() => AddTask(item?.DailyProgressID)}>
                                        <p className="text"><i className="icon-edit-pencil me-1"></i>Edit</p>
                                    </div>
                                    <div className="d-flex  justify-content-between align-items-center">
                                    </div>
                                    <p className="text my-2">{HtmlContent({ html: item?.TodayPlan.slice(0, 100) + '...' })}</p>

                                    <div className="d-flex  justify-content-between align-items-center">
                                    </div>
                                    <div className="blocker d-flex justify-content-between align-items-center">
                                        <p className="heading">Blockers</p>
                                        <p className="text">{item?.Blockers}</p>
                                        {/* <p className="text"><i className="icon-Icon-metro-attachment me-1"></i>2</p> */}
                                    </div>
                                </>
                            )
                        })
                            :
                            <NoDataFound isLoading={isLoading} Data={TrackingData} />
                        }

                    </div>

                    <div className={`ar_today_nodata position-relative ${Object.keys(ScreenShotData)?.length != 0 ? '' : 'ar-height'}`}>
                        <p className='heading mt-3 ms-3'>Today’s  Summary</p>
                        {Object.keys(ScreenShotData)?.length != 0 ?
                            <>
                                <OverAllSessionDetails SearchType={"Current"} CurrentDate={currentDay} type={type} />
                                <div className='mt-3 ms-3 me-3'>
                                    {ScreenShotData?.Session?.length != 0 && ScreenShotData?.Session?.map((item) => {
                                        return (
                                            <>
                                                <p className='gry_heading font-m'><span className='active_time'></span>{item?.StartTime + " - " + item?.EndTime} <span className='text sm clr_gry ms-1'>{item?.SessionTime + " logged time "}</span></p>
                                                <div className='ms-3 overflow-x-auto mt-3 d-flex pb-1 gap-3'>
                                                    {item?.Screenshot?.length != 0 && item?.Screenshot?.map((items) => {
                                                        return (
                                                            <ScreenShot items={items} LoadData={LoadScreenShotData} />
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </>
                            :
                            <NoDataFound isLoading={isLoading} Data={Object.keys(ScreenShotData)} />
                        }

                    </div>
                </div>
            </>
        </div>
    )
}

export default TodayProgress
