/*styling*/
import './app.css'
import './app.scss';
/*layout*/
// import Header from './components/layout/header';
// import Footer from './components/layout/footer'
// import Sidebar from './pages/layout/sidebar';
/*pages*/
import SideBar from './components/MenuLayout/SideBar';
import TopNavBar from './components/MenuLayout/TopNavBar';
import Footer from './components/MenuLayout/Footer'

import MainContent from './components/MenuLayout/MainContent';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import Cookies from "js-cookie";
import jwt_decode from 'jwt-decode';
import { OrganizationID } from "../src/actions/Organization";
import { Token } from "../src/actions/Token";
import { Role } from "../src/actions/Role";
function App() {
  let dispatch = useDispatch();
  let activeTab = useSelector(state => state.MainTabContentReducer);
  let dynamicTabs = useSelector(state => state.MainTabContentTabListReducer);
  //////To Set Dashboard as Default Page on App Refresh////// 
  // dispatch(GoTo(0))
  // dispatch(MainTabContentActiveTab(1))
  useEffect(() => {
    var getScreenWidth = window.innerWidth
    if (getScreenWidth > 992) {
      document.body.classList.add("desktop")
    }
    if (getScreenWidth > 600 && getScreenWidth < 992) {
      document.body.classList.add("tablet", "sidebar-slim")
    }
    if (getScreenWidth < 600) {
      document.body.classList.add("mobile")
    }
    window.addEventListener("resize", () => {
      var getScreenWidth = window.innerWidth

      if (getScreenWidth > 992) {
        document.body.classList.remove("mobile", "tablet", "sidebar-slim", "sidebar-mobile")
        document.body.classList.add("desktop")
      }
      if (getScreenWidth > 600 && getScreenWidth < 992) {
        document.body.classList.remove("mobile", "desktop", "mobile", "sidebar-mobile-open")
        document.body.classList.add("tablet", "sidebar-slim")
      }
      if (getScreenWidth < 600) {
        document.body.classList.remove("tablet", "desktop", "sidebar-slim")
        document.body.classList.add("mobile")
      }
    }, false);
  });
  // Get the cookie value and dispatch it to the Redux
  useEffect(() => {
    const cookieValue = Cookies.get('cookedToken');
    if (cookieValue) {
      dispatch(Token(cookieValue));
      const user = jwt_decode(cookieValue)
      user.OrganizationID && dispatch(OrganizationID(user.OrganizationID));
      if (user.Role) {
        let role;
        switch (user.Role.toString().toUpperCase()) {
          case 'SUPERADMIN':
          case 'ADMIN':
          case 'HR':
          case 'MANAGER':
            role = 1;
            break;
          case 'USER':
            role = 2;
            break;
          default:
            role = 1;
            break;
        }
        dispatch(Role(role));
      }
    }
  }, []);
  return (
    <div className="layout">
      <SideBar />
      <div className='app-container'>
        <TopNavBar />
        <div className='app-content'>
          <MainContent activeTab={activeTab} dynamicTabs={dynamicTabs} />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
