import React from "react";
import Tab from 'react-bootstrap/Tab';
import { useState, useEffect } from "react"
import { TabContent, TabPane, NavItem, NavLink } from 'reactstrap'
import Nav from 'react-bootstrap/Nav';
import EmployeeSearch from "./EmployeeSearch"
import { connect } from "react-redux";
import { TabAction } from "../../actions/HandleTabAction";
import EmployeeTab from "./EmployeeTab";
import { useDispatch } from "react-redux";

import { EmployeeRecordAction } from "../../actions/EmployeeRecordAction";
import { CancelButton } from "../../actions/CancelButton";
import { DeleteEmployeeID } from "../../actions/DeleteEmployeeID";

import { useSelector } from "react-redux";
import { NewEmployeeData } from "../../actions/NewEmployeeData"

const Employee = (props) => {
    let TotalEmployee = useSelector(state => state.TotalEmployeesReducer);
    let ActiveEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let NewEmployee = useSelector(state => state.NewEmployeeDataReducer)

    let dispatch = useDispatch();
    const [tabList, setTabsList] = useState([{
        active: true,
        key: 2,
        name: "Employee",
        data: {
            FirstName: "All Employees",
            id: 0,

        },
    },])
    const [IsOpenEmployee, setIsOpenEmployee] = useState(false);
    const [activeTab, setActiveTab] = useState(0)
    dispatch(EmployeeRecordAction(props.OpenDynamicTab));

    useEffect(() => {
        if (props?.OpenDynamicTab && props?.OpenDynamicTab?.length > 0)
            AddDynamicTabData(props)

    }, [props.OpenDynamicTab, NewEmployee])


    const AddDynamicTabData = (data) => {
        let tabDataFromRedux = data?.OpenDynamicTab?.length > 0 ? data?.OpenDynamicTab : [];
        //Adding New Employee in tab////
        console.log("TABDATAFROMREDUX", tabDataFromRedux)
        for (var i in tabDataFromRedux) {

            if (tabDataFromRedux[i]?.data?.id === 0.1 && tabDataFromRedux[i]?.active === true && NewEmployee !== 0) {
                tabDataFromRedux[i].data = NewEmployee;
                setIsOpenEmployee(false)
                dispatch(NewEmployeeData(0))
            }
        }
        ///////////////////////////////
        const DataFromRedux = tabDataFromRedux.filter((ele) => { return ele.key === 2; });   // fetch only whose id is 2
        const activeElements = DataFromRedux.filter((row) => row.active === true); // get active 
        console.log("TABDATAactiveElements", activeElements)
        if (activeElements.length > 0 && tabList.length === 1) {
            let tabsData = [...tabList]
            tabsData = tabsData.concat(...DataFromRedux)
            setTabsList(tabsData)
            setActiveTab(activeElements[0]?.data?.id)
        } else {
            let firstEmployeeTab = tabList.filter((el) => el.data.id === 0);
            let tabs = [...firstEmployeeTab]
            tabs = tabs.concat(...DataFromRedux)
            setTabsList(tabs)
            setActiveTab(activeElements[0]?.data?.id)
        }
    }
    const handleActiveTab = (key) => {

        if (key != 0.1) { setIsOpenEmployee(false) }

        if (activeTab !== key) {
            setActiveTab(key)
        }
    }

    const handleCloseTab = async (id) => {

        setIsOpenEmployee(false);
        let index = tabList.findIndex((el) => el.data.id === id);
        console.log("index", index)
        tabList.splice(index, 1);
        let activTab =
            tabList.length > 0
                ? tabList[tabList.length - 1].data.id
                : 0;

        setTabsList(tabList)
        setActiveTab(activTab)
        props.TabAction({}, id);
    };

    const AddEmployee = () => {
        setIsOpenEmployee(true)
    }

    let CancelBtn = useSelector(state => state.CancelButtonReducer);

    let DeleteEmpID = useSelector(state => state.DeleteEmployeeIDReducer);
    if (CancelBtn) {
        handleCloseTab(ActiveEmployee);
        dispatch(CancelButton(false))
    }
    if (DeleteEmpID) {
        console.log("DeleteEmployeeID", DeleteEmpID)

        let index = tabList.findIndex((el) => el.data.id === DeleteEmpID);
        index !== -1 && handleCloseTab(DeleteEmpID);
        dispatch(DeleteEmployeeID(''))
    }
    return (
        <>
            <div className='app-tabs fix-tabs-v2'>
                <Tab.Container>
                    <div className='pt-1 ps-3 employee_header'>

                        <Nav variant="tabs" className="" >
                            {tabList && tabList.map((item) => {
                                console.log("ITEMEMPLOYEE", item)
                                return (
                                    <React.Fragment key={item.data.id}>
                                        <NavItem >
                                            <NavLink
                                                // href="#"
                                                className={activeTab === item.data.id ? "active" : ""}
                                                onClick={() => handleActiveTab(item.data.id)}
                                            >
                                                <div className="heading sm"> {item.data.FirstName} </div>

                                                {item.data.FirstName === "All Employees" ? <span className="counter text-light heading">{TotalEmployee}</span> : <></>}

                                                {item.data.id > 0 ? (
                                                    <span onClick={(e) => { handleCloseTab(item.data.id); e.stopPropagation(); }} className={activeTab === item.data.id ? "cross" : "cross"}>
                                                        <i className="icon-Icon-metro-cross"></i>
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </NavLink>
                                        </NavItem>
                                    </React.Fragment>
                                )
                            })}
                        </Nav>

                        {/* <div>
                            <button className='btn btn-orange btn-xs ' onClick={() => AddEmployee(true)}>Add New Employee</button>
                        </div> */}

                    </div>
                    <TabContent activeTab={activeTab}>
                        {tabList &&
                            tabList.filter((el) => el.data.id === 0).map((item) => {
                                return (
                                    <React.Fragment key={item.data.id}>
                                        <TabPane tabId={item.data.id}>

                                            <EmployeeSearch IsOpenEmployee={IsOpenEmployee} AddEmployee={AddEmployee} />
                                        </TabPane>
                                    </React.Fragment>
                                )
                            })}

                        {tabList &&
                            tabList.filter((el) => el.data.id !== 0).map((item) => {
                                return (
                                    <React.Fragment key={item.data.id}>
                                        <TabPane tabId={item.data.id}>
                                            <div className="">

                                                <EmployeeTab Active={activeTab} />

                                            </div>
                                        </TabPane>
                                    </React.Fragment>
                                )

                            })}

                    </TabContent>
                </Tab.Container>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        OpenDynamicTab: state.OPEN_TAB ? state.OPEN_TAB : [],
    };
}
const action = {
    TabAction,
};

export default connect(mapStateToProps, action)(Employee)