import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ModalFooter from '../../../../shared/ModalFooter/ModalFooter';
import { enumUtil } from '../../../../../utils/Enum';
import NotificationHandler from '../../../../../utils/NotificationHandler';
import isNull from '../../../../../utils/NullChecking';
import { ServerRequestProtectedParentID } from '../../../../../utils/ServerDataRequest';
import FormValidator from '../../../../../utils/FormValidator';
import AddLeaveType from '../AddLeaveType/AddLeaveType';
import AddPolicy from '../AddPolicy/AddPolicy';
import AddAdvancePolicy from '../AddAdvancePolicy/AddAdvancePolicy';
import MenuList from '../MenuList'

const AddNewLeavePolicy = (props) => {
    const { isOpenPopup, onClose, onCloseSave, ID, setIsRefresh, } = { ...props }
    const MenuItems = MenuList();
    let activeTabElement = MenuItems.filter((item) => item.right === true)[0];
    let lastTabElement = MenuItems.filter((item) => item.right === true)[MenuItems?.length - 1];
    const API_URL = process.env.REACT_APP_API;
 
    const [validation,setValidation] = useState({})
    let Leave_Type_Modal = {
        LeaveName: '',
        AllocationFrequency: '',
        LeaveDescription: '',
        hasSubTypes: true,
    }

    // let SUB_TYPE = 
    let SubType_Modal = { key: 0, SubType: '', 'Days0': false, 'Hours0': false };;

    const [subTypes, setSubTypes] = useState([SubType_Modal]);

    let Leave_Policy_Modal = {
        PolicyName: '',
        PolicyPeriod: '',
        FullDayAllowed: '',
        HalfDayAllowed: '',
        ShortLeavesAllowed: '',
        ShortLeaveQty: '',
        EffectiveAfter: '',
        EffectiveFrom: '',
        CanApprove: {
            Admin: '',
            TeamLead: '',
            HR: '',
        },
        ApplyBeyond: '',
    }

    let Policy_Advance_Modal = {
        NoticePeriod: '',
        MailNotification: '',
        CarriedForward: '',
        CarriedForwardPercentage: '',
        Availability: '',
    }

    let approval_Validation_Modal = {
        CanApprove: ''
    }

    let approveValModal = {

    }

    let Validation_Modal = {
        LeaveName: '',
        AllocationFrequency: '',
        LeaveDescription: '',
        PolicyName: '',
        PolicyPeriod: '',
        LeaveApproval: '',
        SubType: '',
    }

    let valModal = {

    }

    const [activeTab, setActiveTab] = useState(1);
    const [tabList, setTabList] = useState([]);
    const [PolicyID, setPolicyID] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [mainPolicyTypeModal, setMainPolicyTypeModal] = useState(Leave_Type_Modal);
    const [savePolicyTypeModal, setSavePolicyTypeModal] = useState({});
    const [mainPolicyModal, setMainPolicyModal] = useState(Leave_Policy_Modal);
    const [savePolicyModal, setSavePolicyModal] = useState({});
    const [mainPolicyAdvanceModal, setMainPolicyAdvanceModal] = useState(Policy_Advance_Modal);
    const [savePolicyAdvanceModal, setSavePolicyAdvanceModal] = useState({});

    const [PolicyValidationModal, setPolicyValidationModal] = useState(Validation_Modal);
    const [PolicyApprovalValidationModal, setPolicyApprovalValidationModal] = useState(approval_Validation_Modal);

    const handleActiveTab = (item) => {
        const { key, name, right, component } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
                component: activeTabElement.component,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
            component: component,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }

    const objectIsNullCheck = (object, message, array) => {
        let objKeys = Object.keys(object)
        let hasNonFalsy = Object.values(object).some(value => Boolean(value))

        if (hasNonFalsy && array) {
            let hasSubType = !isNull(object[objKeys[1]]);
            let hasRadio = Boolean(object[objKeys[2]]) || Boolean(object[objKeys[3]])
            // let hasEveryValue = Object.values(object).every(value => Boolean(value))
            console.log("hasEveryValue", (hasSubType && hasRadio), (!hasSubType && !hasRadio))
            if ((hasSubType && hasRadio) ||  (!hasSubType && !hasRadio) ) {
                return
            } else {
                return object
            }
        }
        else if (hasNonFalsy) {
            return
        } else {
            return message;
        }
        // let NotNullCount = 0;
        // for (let prop in object) {
        //     if (!isNull(object[prop])) {
        //         if (array) {
        //             array.push(object)
        //         }
        //         NotNullCount++
        //     }
        // }
        // if (NotNullCount > 0) {
        //     return ""
        // } else {
        //     return (
        //         <div>{propName + ' is Required!'}</div>
        //     )
        // }
    }

    const arrayOfObjectNullCheck = (arr, message) => {

        let itemArray = [];
        let filteredValidationArray;
        let validationArray = arr.map(obj => objectIsNullCheck(obj, message, true)).filter(val => !isNull(val))
        let isString = typeof validationArray[0] === 'string';
        console.log("validationArray", validationArray);
        if (!isString) {
            filteredValidationArray = validationArray?.map(obj => {
                console.log("obj", obj);
                let objKeys = Object.keys(obj);
                if (!isNull(obj[objKeys[1]])) {
                    console.log("if 1st", obj[objKeys[2]], obj[objKeys[3]])
                    if (!obj[objKeys[2]] && !obj[objKeys[3]]) {
                        console.log("if 1st -> 2nd, 3rd")
                        return { errorMessage: "Please select unit", key: obj.key, }
                    }
                } else if (!isNull(obj[objKeys[2]]) || !isNull(obj[objKeys[3]])) {
                    if (isNull(obj[objKeys[1]])) {
                        return { errorMessage: 'Please add sub type', key: obj.key, [objKeys[1]]: objKeys[1] }
                    }
                }
                else return
            }).filter((item) => !isNull(item))
        }
        console.log("FILTEREDARRAY", filteredValidationArray, validationArray)

        return !isString ? filteredValidationArray : validationArray;
    }

    const PolicyValidation = () => {
        let validation_Modal = {
            LeaveName: FormValidator(mainPolicyTypeModal?.LeaveName, enumUtil.enumValidationType.Required, 'Leave Name'),
            AllocationFrequency: FormValidator(mainPolicyTypeModal?.AllocationFrequency, enumUtil.enumValidationType.Required, 'Leave Name'),
            LeaveDescription: FormValidator(mainPolicyTypeModal?.LeaveDescription, enumUtil.enumValidationType.Required, 'Leave Name'),
            PolicyName: FormValidator(mainPolicyModal?.PolicyName, enumUtil.enumValidationType.Required, 'Leave Name'),
            PolicyPeriod: FormValidator(mainPolicyModal?.PolicyPeriod, enumUtil.enumValidationType.Required, 'Leave Name'),
            LeaveApproval: objectIsNullCheck(mainPolicyModal?.CanApprove, 'Please select atleast one'),
            // SubType: mainPolicyTypeModal?.hasSubTypes ? arrayOfObjectNullCheck(subTypes, 'Should have atleast one sub type') : "",
            SubType: mainPolicyTypeModal?.hasSubTypes ? SubTypeValidation(subTypes) : [],
        }
        setPolicyValidationModal(validation_Modal);
        valModal = validation_Modal;
    }
    const SubTypeValidation = (data)=> {
        // SubType: '', Days0: true, Hours0: false

        return data?.map((item,index) => {
            if(item.SubType && (item?.[`Days${index}`] ||  item?.[`Hours${index}`]) || (!item.SubType && !(item?.[`Days${index}`] || item?.[`Hours${index}`]))){
                return false
            } else {
                let ErrorObj = {}
                if(item.SubType && (!item?.[`Days${index}`] ||  !item?.[`Hours${index}`])){
                    ErrorObj = {SubType:'Sub Type required!', key: index}
                } else if(!item.SubType && (item?.[`Days${index}`] ||  item?.[`Hours${index}`])) {
                    ErrorObj = {Unit:'Unit is required!', key: index}
                }
                return ErrorObj
            }
        })
    }

    // const PolicySubTypesValidation = () => {
    //     let validation_Modal = {

    //     }
    //     setPolicyValidationModal(validation_Modal);
    //     valModal = validation_Modal;
    // }

    const handleSavePolicy = async () => {
        await PolicyValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            // console.log("validation", PolicyValidationModal);
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)

            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.LeavePolicy,
                ID: PolicyID,
                data: { ...savePolicyModal, ...savePolicyTypeModal, ...savePolicyAdvanceModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setPolicyID(response.id)
                    setIsRefresh(true);
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(true);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }

    const propsById = (ID) => {
        switch (ID) {
            case 1: return { Leave_Type_Modal, subTypes, setSubTypes, mainPolicyTypeModal, setMainPolicyTypeModal, savePolicyTypeModal, setSavePolicyTypeModal };
            case 2: return { Leave_Policy_Modal, mainPolicyModal, setMainPolicyModal, savePolicyModal, setSavePolicyModal };
            case 3: return { Policy_Advance_Modal, mainPolicyAdvanceModal, setMainPolicyAdvanceModal, savePolicyAdvanceModal, setSavePolicyAdvanceModal };
            default: return
        }
    }

    useEffect(() => {
        // ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
        //     "TableName": enumUtil.enumAPITableName.Team,
        // }).then((response) => {
        //     setIsLoading(false)
        //     setAddTeamDropDown(response)
        // }).catch((error) => {

        //     // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        //     setIsLoading(false)
        // })

        if (!isNull(ID)) {
            setIsLoading(true)
            setPolicyID(ID)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.LeavePolicy,
            }).then((response) => {
                // setMianAnnouncmentModal(response);
                setMainPolicyAdvanceModal();
                setMainPolicyTypeModal();
                setMainPolicyModal();
                setIsLoading(false)
                // if (response?.Shift[0]?.DepartmentIDs) { setTeamValue(JSON.parse(response?.Shift[0]?.DepartmentIDs)) }
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [ID])

    return (
        <Modal
            show={isOpenPopup}
            onHide={isOpenPopup}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="delete-modal hk-modal-v1 ems_modal add_new-policy "
        >
            <ModalBody>
                <div className="d-flex justify-content-end">
                    <i
                        className="icon-Vector7"
                        onClick={() => onClose()}
                    ></i>
                </div>
                <div className="modal_header">
                    <div className="text-center">
                        <span className="icon">
                            <i className="icon-Group-1 fs-22"></i>
                        </span>
                        <p class="heading mt-2 mb-3">Add New Policy</p>
                    </div>
                </div>
                <Tab.Container defaultActiveKey={activeTab}>
                    <div className="d-flex mt-1 mb-4">
                        <Nav
                            variant="tabs"
                            className="d-flex border-0 gx-0 justify-content-between w-100"
                        >
                            {
                                MenuItems?.map((item) => {
                                    return (
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey={item?.key}
                                                className="text clr_gry fs-11 d-flex align-content-center gap-2 position-relative"
                                                onClick={() => handleActiveTab(item)}
                                            >
                                                {" "}
                                                <p className="align-items-center clr_gry d-flex fs-10 justify-content-center number heading">
                                                    {item?.key}
                                                </p>
                                                {item?.name} <p className={`${item?.key != lastTabElement.key ? 'ar-br-right-1' : ''} mb-0`}></p>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </Nav>
                    </div>
                    <Tab.Content>

                        {tabList?.length > 0 ?
                            tabList?.map((item) => {
                                return (
                                    <Tab.Pane eventKey={item?.key}>
                                        {React.cloneElement(item.component, { ...propsById(item?.key), PolicyValidationModal: PolicyValidationModal })}
                                    </Tab.Pane>
                                )
                            })
                            :
                            <Tab.Pane eventKey={activeTabElement.key}>
                                {React.cloneElement(activeTabElement.component, { ...propsById(activeTabElement.key), PolicyValidationModal: PolicyValidationModal })}
                            </Tab.Pane>}

                    </Tab.Content>
                </Tab.Container>
            </ModalBody>
            <ModalFooter isSaved={onClose} handleSave={handleSavePolicy} />
        </Modal >
    )
}

export default AddNewLeavePolicy
