import React, { useState } from 'react'
import MenuList from './Menu'
import { Nav, NavLink, NavItem } from 'reactstrap'
import LeaveTopMenuContent from './LeaveTopMenuContent';
const LeaveTopMenu = () => {
    const MenuItems = MenuList();
    const activeComponent = MenuItems?.filter((tab) => tab.right === true)[0];
    const [tabList, setTabList] = useState([]);
    const [activeTab, setActiveTab] = useState(1)

    const handleActiveTab = (item) => {
        let newObj = {
            key: item.key,
            Name: item.Name,
            component: item.component,
        }

        let tabExist = tabList?.find((tab) => tab?.key === item?.key);

        if (!tabExist) {
            setTabList([...tabList, newObj])
        }
        setActiveTab(item?.key)
    }

    return (
        <div>
            <div className='app-tabs'>
                <div className='tabs-header position-relative mx-auto'>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="tabs-v2-custom ar_tab-v2-custom">
                            <Nav variant="tabs" className="rb-tabs-v3 tabs_view mb-2 nav-tabs">
                                {MenuItems && MenuItems?.map((tab) => {
                                    return (
                                        <>
                                            <NavItem onClick={() => handleActiveTab(tab)} key={tab.key}>
                                                <NavLink className={activeTab === tab.key ? 'active' : ''}>
                                                    {tab.Name}
                                                </NavLink>
                                            </NavItem>
                                        </>
                                    )
                                })}
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
            <LeaveTopMenuContent activeTab={activeTab} dynamicTabs={tabList}/>
        </div>
    )
}

export default LeaveTopMenu
