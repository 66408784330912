import React, { useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import FormValidator from "../../utils/FormValidator"
import NotificationHandler from "../../utils/NotificationHandler"
import { enumUtil } from "../../utils/Enum"
import { ServerRequestProtected } from "../../utils/ServerDataRequest"
import Loader from "../shared/Loader/Loader"

const ForgotPassword = (props) => {
    const AUTHENTICATE_API_URL = process.env.REACT_APP_AUHTENTICATE
    let navigate = useNavigate()
    const dispatch = useDispatch();

    let ForgotPassword_Modal={
        Email: "",
    }
    let valModal = {}
    let ValidationModal = {
        EmailVal: "",
        Email:"",
    }
    const [isLoading, setIsLoading] = useState(false)
    const [mainForgotPasswordModal, setmainForgotPasswordModal] = useState(ForgotPassword_Modal);
    const [ForgotPasswordSaveUpdateModal, setForgotPasswordSaveUpdateModal] = useState({});
    const [ForgotPasswordValidationModal, setForgotPasswordValidationModal] = useState(ValidationModal)
    const [hide,setHide] = useState(false);
    const handleForgotPasswordChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainForgotPasswordModal };
        let saveModal = { ...ForgotPasswordSaveUpdateModal }
        modal[name] = value
        setmainForgotPasswordModal(modal)    
        for (var property in ForgotPassword_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setForgotPasswordSaveUpdateModal(saveModal)
    }
    const ForgotPasswordValidation = async () => {
        let ValidationModal = {
            EmailVal: FormValidator(
                mainForgotPasswordModal.Email,
                enumUtil.enumValidationType.Required,
                'Email',
            ),
            Email: FormValidator(
                mainForgotPasswordModal.Email,
                enumUtil.enumValidationType.Email,
                'Email',
            ),
        }
        await setForgotPasswordValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleSubmit = async(event) => {
        await ForgotPasswordValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            // NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setIsLoading(true);
        ServerRequestProtected(AUTHENTICATE_API_URL + "/ForgotPassword", 'post',
        // ServerRequestProtected("http://192.168.18.5:438/api/Authentication/ForgotPassword", 'post',
          {
             ...ForgotPasswordSaveUpdateModal
           }
        ).then((response) => {
            setIsLoading(false)
            event.preventDefault();
            NotificationHandler('Email Sent Successfully', enumUtil.enumtoaster.success)
            navigate("../checkemail",{
                    state:{ ...ForgotPasswordSaveUpdateModal }
                    })
        }).catch((error) => {
            setIsLoading(false)
            NotificationHandler("Invalid email address, please enter valid email. ",enumUtil.enumtoaster.error)
        })
    }
    }
    return (
        <React.Fragment >
            {isLoading ? <Loader /> : null}
            <div className="login hk-login">
            <div className="d-lg-flex half">
                <div className="bg order-1 order-md-2">
                    <img src={process.env.PUBLIC_URL + "/assets/images/Login_EMS_animation.png"} alt="" />
                </div>
                <div className="contents order-2 order-md-1">
                    <h1 className="hk-title">Employee Management System</h1>
                    <div className="container hk-form-t">
                        <div className="row align-items-start justify-content-center">
                            <div className="col-md-8 d-flex flex-column justify-content-center text-center">
                                <div className="hk-t-img">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/lock-password.png"} alt="forget password icon" />
                                </div>
                                <h2 class="heading xl text-center">Forgot Password?</h2>
                                <p className="text clr-muted text-center mt-3 forget-p">No worries, we’ll send you reset notifications.</p>
                                <div className="mt-5">
                                    <div className={ForgotPasswordValidationModal.EmailVal?"form-group input error":" form-group input"}>
                                    <div className={ForgotPasswordValidationModal.Email?"form-group input error":" form-group input"}>
                                        <input type="text"
                                            placeholder="Enter Your Email"
                                            className="form-control forget-input"
                                            maxLength={50}
                                            name="Email"
                                            id="Email"
                                            value={mainForgotPasswordModal.Email}
                                            onChange={handleForgotPasswordChange}
                                            // onchangetext={(e)=>{e.target.value = e.target.value.replace(" ", "");}}  
                                            // onChangeCapture={ValidatorEmail}     
                                            />
                                        {ForgotPasswordValidationModal.EmailVal}
                                        {ForgotPasswordValidationModal.Email}
                                    </div>
                                    <input type="submit" value="Reset password" className="btn rounded btn-blue w-100 mt-4 reset-pass-btn" onClick={handleSubmit}/>

                                </div>
                                <a className="text text-center inst-txt mt-4 text-decoration-none" onClick={()=>navigate("../", { replace: true })}><span class="icon-Icon-ionic-md-arrow-round-back"></span> Back to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </React.Fragment>
    )
}
export default ForgotPassword