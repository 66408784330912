import isNull from "./NullChecking";

export const States = [
    { value: '', display: 'Select Type' },
    { value: 'AL', display: 'AL - Alabama' },
    { value: 'AK', display: 'AK - Alaska' },
    { value: 'AZ', display: 'AZ - Arizona' },
    { value: 'AR', display: 'AR - Arkansas' },
    { value: 'CA', display: 'CA - California' },
    { value: 'CO', display: 'CO - Colorado' },
    { value: 'CT', display: 'CT - Connecticut' },
    { value: 'DE', display: 'DE - Delaware' },
    { value: 'FL', display: 'FL - Florida' },
    { value: 'GA', display: 'GA - Georgia' },
    { value: 'HI', display: 'HI - Hawaii' },
    { value: 'ID', display: 'ID - Idaho' },
    { value: 'IL', display: 'IL - Illinois' },
    { value: 'IN', display: 'IN - Indiana' },
    { value: 'IA', display: 'IA - Iowa' },
    { value: 'KS', display: 'KS - Kansas' },
    { value: 'KY', display: 'KY - Kentucky' },
    { value: 'LA', display: 'LA - Louisiana' },
    { value: 'ME', display: 'ME - Maine' },
    { value: 'MD', display: 'MD - Maryland' },
    { value: 'MA', display: 'MA - Massachusetts' },
    { value: 'MI', display: 'MI - Michigan' },
    { value: 'MN', display: 'MN - Minnesota' },
    { value: 'MS', display: 'MS - Mississippi' },
    { value: 'MO', display: 'MO - Missouri' },
    { value: 'MT', display: 'MT - Montana' },
    { value: 'NE', display: 'NE - Nebraska' },
    { value: 'NV', display: 'NV - Nevada' },
    { value: 'NH', display: 'NH - New Hampshire' },
    { value: 'NJ', display: 'NJ - New Jersey' },
    { value: 'NM', display: 'NM - New Mexico' },
    { value: 'NY', display: 'NY - New York' },
    { value: 'NC', display: 'NC - North Carolina' },
    { value: 'ND', display: 'ND - North Dakota' },
    { value: 'OH', display: 'OH - Ohio' },
    { value: 'OK', display: 'OK - Oklahoma' },
    { value: 'OR', display: 'OR - Oregon' },
    { value: 'PA', display: 'PA - Pennsylvania' },
    { value: 'RI', display: 'RI - Rhode Island' },
    { value: 'SC', display: 'SC - South Carolina' },
    { value: 'SD', display: 'SD - South Dakota' },
    { value: 'TN', display: 'TN - Tennessee' },
    { value: 'TX', display: 'TX - Texas' },
    { value: 'UT', display: 'UT -Utah' },
    { value: 'VT', display: 'VT - Vermont' },
    { value: 'VA', display: 'VA -Virginia' },
    { value: 'WA', display: 'WA - Washington' },
    { value: 'WV', display: 'WV - West Virginia' },
    { value: 'WI', display: 'WI - Wisconsin' },
    { value: 'WY', display: 'WY - Wyoming' },
    { value: 'DC', display: 'DC - District of Columbia' },
    { value: 'AS', display: 'AS - American Samoa' },
    { value: 'GU', display: 'GU - Guam' },
    { value: 'MP', display: 'MP - Northern Mariana Islands' },
    { value: 'PR', display: 'PR - Puerto Rico' },
    { value: 'UM', display: 'UM - United States Minor Outlying Islands' },
    { value: 'VI', display: 'VI - Virgin Islands, U.S.' },
]

export const Credentials = [
    { value: '', display: 'Select Type' },
    { value: 'MD', display: 'MD' },
    { value: 'DO', display: 'DO' },
    { value: 'MBBS or MBBCh', display: 'MBBS or MBBCh' },
    { value: 'PhD', display: 'PhD' },
    { value: 'DNP ', display: 'DNP ' },
    { value: 'NP and PA', display: 'NP and PA' },
    { value: 'CNP', display: 'CNP' },
    { value: 'CNNP', display: 'CNNP' },
    { value: 'PA', display: 'PA' },
    { value: 'CNS', display: 'CNS' },
    { value: 'CNM', display: 'CNM' },
]
export const DateFormat = 'yyyy/MM/dd';
export const DateFormat_dMy = 'dd/MM/yyyy';
export const DateFormat_Mdy = 'MM/dd/yyyy';

export function DateFormatCalculations(date) {
    if (date) {
        const dateParts = date.split("/");
        //===========YYYY-MM-dd============//Time
        const formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
        return new Date(formattedDate).getTime();
    }
}
export function DateFormatCalculations_Mdy(date) {
    if (date) {
        const dateParts = date.split("/");
        //===========YYYY-MM-dd============//Time
        const formattedDate = `${dateParts[1]}-${dateParts[0]}-${dateParts[2]}`;
        return new Date(formattedDate).getTime();
    }
}
export function DateFormat_YMd(date) {
    if (date) {
        // console.log(date)
        const dateParts = date.split("/");
        //===========Shown value to new Date(YYYY-MM-dd) for Calendar============//
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        return new Date(formattedDate);
    }
}
export function DateFormat_YMd_String(date) {
    if (date) {
        const dateParts = date?.split("/");
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        return formattedDate;
    }
}
export function DateFormat_YMd_Strings(date) {
    if (date) {
        const dateParts = date?.split("/");
        const formattedDate = `${dateParts[2]}/${dateParts[0]}/${dateParts[1]}`;
        return formattedDate;
    }
}
export function DateFormat_YMd_Time(date) {
    const current = `${date?.getFullYear()}-${date?.getMonth() < 9 ? '0' : ""}${date?.getMonth() + 1}-${date?.getDate() < 10 ? '0' + date?.getDate() : date?.getDate()}`;
    return new Date(current)?.getTime()
}
export function DateFormat_MdY_Time(date) {
    if (date) {
        const current = `${date?.getMonth() < 9 ? '0' : ""}${date?.getMonth() + 1}-${date?.getDate() < 10 ? '0' + date?.getDate() : date?.getDate()}-${date?.getFullYear()}`;
        return new Date(current)?.getTime()
    } else {
        return "Invalid Date"
    }

}
export function DateFormat_dMY_Time(date) {
    const current = `${date?.getDate() < 10 ? '0' + date?.getDate() : date?.getDate()}-${date?.getMonth() < 9 ? '0' : ""}${date?.getMonth() + 1}-${date?.getFullYear()}`;
    return new Date(current)?.getTime()
}
export const LengthCheck = (value, lastIndex) => {

    let slicedNumber = value.slice(0, lastIndex);
    return slicedNumber;

}
export const handleRenderData = (dataToRenderLeft, dataToRenderRight, type) => {

    if (!isNull(dataToRenderLeft) && !isNull(dataToRenderRight)) {
        return (`[${dataToRenderLeft} - ${dataToRenderRight}]`);
    }
    else if (!isNull(dataToRenderLeft)) {
        if (type === 'Brackets') {
            return (`[${dataToRenderLeft}]`)
        } else {
            return (`${dataToRenderLeft}`)
        }

    }
    else if (!isNull(dataToRenderRight)) {
        if (type === 'Brackets') {
            return (`[${dataToRenderRight}]`)
        } else {
            return (`${dataToRenderLeft}`)
        }

    }
    else {
        return ""
    }
}

export const getExpirationDate = () => {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1)
    return expirationDate;
}

export const DateTimeFormats = 'yyyy/MM/dd HH:mm';
export const TimeFormats = 'HH:mm'
export const MonthsDropdown = [
    { value: '1', display: 'January' },
    { value: '2', display: 'February' },
    { value: '3', display: 'March' },
    { value: '4', display: 'April' },
    { value: '5', display: 'May' },
    { value: '6', display: 'June' },
    { value: '7', display: 'July' },
    { value: '8', display: 'August' },
    { value: '9', display: 'September' },
    { value: '10', display: 'October' },
    { value: '11', display: 'November' },
    { value: '12', display: 'December' },
]

export const isMale = (val) => {
    return ['Male','male','M'].includes(val);
};
export const isFemale = (val) => {
    return ['Female','female','FM'].includes(val)
};