import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import close from "../../../../assetsOld/img/x-close.svg"
import Modal from 'react-bootstrap/Modal';
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"

const AddNewBenefitType = (props) => {
    let BenefitType_Modal = {
        BenefitName: "",
        Description: "",

    }
    let valModal = {}
    let ValidationModal = {
        BenefitName: "",
    }
    const [newBenefitTypeSaved, setNewBenefitTypeSaved] = useState(false);
    const [BenefitTypeValidationModal, setBenefitTypeValidationModal] = useState(ValidationModal)
    const [BenefitNameID, setBenefitNameID] = useState()
    const [BenefitTypeUpdateModal, setBenefitTypeUpdateModal] = useState({})
    const [mainBenefitTypeModal, setmainBenefitTypeModal] = useState(BenefitType_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [del, setDel] = useState(false)
    const [OrganizationID, setOrganizationID] = useState()
    const API_URL = process.env.REACT_APP_API
    const { isOpenBenefitType, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setOrganizationID(null)
        setIsLoading(false)
        // right now, use any providerid to test update case/find by id
        if (props.BenefitTypeID != null) {
            setBenefitNameID(props.BenefitTypeID)
            setDel(true)
        } else {
            setBenefitNameID(0);
            setDel(false)
        }
        if (!isNull(BenefitNameID)) {
            setIsLoading(true)
            // setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "TableName": enumUtil.enumAPITableName.BenefitType,
                "ID": BenefitNameID,
            }).then((response) => {
                setmainBenefitTypeModal(response);
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [BenefitNameID])

    const handleSaveBenefit = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainBenefitTypeModal };
        let saveModal = { ...BenefitTypeUpdateModal }
        modal[name] = value
        setmainBenefitTypeModal(modal)
        for (var property in BenefitType_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setBenefitTypeUpdateModal(saveModal)
    }

    const BenefitTypeValidation = async () => {
        let ValidationModal = {
            BenefitName: FormValidator(
                mainBenefitTypeModal.BenefitName,
                enumUtil.enumValidationType.Required,
                'Benefit Name',
            ),
        }
        await setBenefitTypeValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveBenefitType = async () => {
        await BenefitTypeValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return

        } else {
            // BenefitTypeUpdateModal['OrganizationID'] = OrganizationID;
            setBenefitTypeUpdateModal(BenefitTypeUpdateModal)

            let saveModal = {
                TableName: enumUtil.enumAPITableName.BenefitType,
                ID: BenefitNameID,
                data: { ...BenefitTypeUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewBenefitTypeSaved(true)
                    setBenefitNameID(response.id)
                    setIsRefresh(true)
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false)
                console.log('error', error);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    // const handleDeleteclick = () => {
    //     setIsOpenAlert(true)
    // }
    // const AlertClose = () => {
    //     setIsOpenAlert(false)
    // }
    // const handleBenefitTypeDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": BenefitNameID,
    //         "TableName": enumUtil.enumAPITableName.BenefitType
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Benefit Type Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }
    const SetLeaveTypeName = () => {
        if (mainBenefitTypeModal.BenefitName != null && mainBenefitTypeModal.BenefitName != "") {
            return (
                <p className="heading validation_message">{mainBenefitTypeModal.BenefitName}</p>
            )
        } else {
            return (
                <p className="heading">Add New Benefit Type</p>
            )
        }
    }

    //////////
    return (
        <>
            <Modal show={isOpenBenefitType} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal add_benfit_type ems_modal">
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close} onClick={() => onClose(newBenefitTypeSaved)}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="icon_bg">
                                    <i className="icon-plus-icon"></i>
                                </div>
                            </span>
                            <p class="heading mt-2"> {SetLeaveTypeName()}</p>
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}
                    <div>
                        <div className={BenefitTypeValidationModal.BenefitName ? "form-group input error" : "form-group input"}>
                            <label>Benefit Name<span className='req'>*</span></label>
                            <input type="text"
                                className="form-control"
                                name="BenefitName"
                                id="BenefitName"
                                maxLength={35}
                                value={mainBenefitTypeModal.BenefitName}
                                onChange={handleSaveBenefit}
                            />
                            {BenefitTypeValidationModal.BenefitName}
                        </div>

                        <div className="form-group input">
                            <label>Description</label>
                            <textarea rows="5"
                                className="form-control"
                                name="Description"
                                id="Description"
                                maxLength={500}
                                value={mainBenefitTypeModal.Description}
                                onChange={handleSaveBenefit}
                            ></textarea>
                        </div>


                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveBenefitType} />
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                               <button className='btn btn-gray' onClick={() => onClose(newBenefitTypeSaved)}>Cancel</button>
                               <button className='btn btn-orange' onClick={handleSaveBenefitType}>Save</button>
                           </div> */}
            </Modal>
        </>
    )
}

export default AddNewBenefitType