import React from 'react'

const ScreenFooter = (props) => {
    const { isSaved, handleSave } = { ...props };
    return (
        <div>
            <>
                <div className='br-bottom-1 clr_light screen_footer br-top-1 btns-group d-flex gap-3 justify-content-end px-2 py-1'>
                    <div className="btns-group d-flex justify-content-center align-items-center">
                        <button className="btn btn-gray" onClick={() => isSaved()}>
                            Cancel
                        </button>

                        <button className="btn btn-orange" onClick={() => handleSave()}>
                            Save
                        </button>
                    </div>
                </div>
            </>
        </div>
    )
}

export default ScreenFooter
