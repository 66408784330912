import React, { useEffect } from 'react'
import handleNumericCheck from "../../../utils/HandleNumericKeys"
import { useState } from 'react';

function Pagination(props) {
    let [arrOfCurrentButtons, setArrOfCurrentButtons] = useState([]);
    const[ goToPage , setGoToPage ] = useState()
    const ShowingPages = [
        { value: '10', display: '10' },
        { value: '20', display: '20' },
        { value: '30', display: '30' },
        { value: '40', display: '40' },
        { value: '50', display: '50' },
    ]
  
     
    let  numberOfPages = [];
    for (let i = 1; i <= props.TotalPages; i++) {
        numberOfPages.push(i)
        }

    const paginationn = () => {
      let tempNumberOfPages = [...arrOfCurrentButtons]
      let dotsInitial = '...'
      let dotsLeft = '... '
      let dotsRight = ' ...'
      if (numberOfPages.length < 6) {
          tempNumberOfPages = numberOfPages
          }
          else if(props.PageNo >=1 && props.PageNo <3 ){
          tempNumberOfPages = [1,2,3, dotsInitial, numberOfPages.length]
          }
          else if (props.PageNo >= 2 && props.PageNo <= 3) {
          tempNumberOfPages = [ 2, 3, 4, dotsInitial, numberOfPages.length]
          }
          else if (props.PageNo === 4) {
          const sliced = numberOfPages.slice(2, 5)
          tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length]
          }
          else if (props.PageNo > 4 && props.PageNo < numberOfPages.length - 2) {               // from 5 to 8 -> (10 - 2)
          const sliced1 = numberOfPages.slice(props.PageNo - 2, props.PageNo)                 // sliced1 (5-2, 5) -> [4,5] 
          const sliced2 = numberOfPages.slice(props.PageNo, props.PageNo + 1)                 // sliced1 (5, 5+1) -> [6]
          tempNumberOfPages = ([1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numberOfPages.length]) // [1, '...', 4, 5, 6, '...', 10]
          }
          else if (props.PageNo > numberOfPages.length - 3) {                 // > 7
          const sliced = numberOfPages.slice(numberOfPages.length - 3)       // slice(10-4) 
          tempNumberOfPages = ([1, dotsLeft, ...sliced])                        
          }
          
          else if (props.PageNo === dotsInitial) {
              props.handlePageClick(arrOfCurrentButtons[arrOfCurrentButtons.length-3] + 1)
         }
          else if (props.PageNo === dotsRight) {
              props.handlePageClick(arrOfCurrentButtons[3] + 2)
          }
      
          else if (props.PageNo === dotsLeft) {
          //setPageNo(arrOfCurrentButtons[3] - 2)
          props.handlePageClick(arrOfCurrentButtons[3] - 2)
      }
    setArrOfCurrentButtons(tempNumberOfPages)
  }
  useEffect(()=> {
    paginationn();
  },[props.PerPage,props.PageNo,props.TotalPages])

  return (
   <>
   <div className='grid-pagination'>
    <div className='row-per-page'>
        <p>Rows per page</p>
        <select
        value={props.PerPage}
        onChange={props.handleFingByCreteria}>
        {ShowingPages.map((type) => {
                return (
                    <option value={type.value} key={type.value}>{type.display}</option>
                )
            })}
        </select>
    </div>
    <div className='d-flex align-items-center gap-10 flex-wrap'>
        <div className='pagination'>
            <button className={props.PageNo === 1 ? "page-nav left disabled" : "page-nav left"}>
                <i className='icon-angle-right' onClick={() => props.PreviousPage()}></i>
            </button>
            <ul>
                {arrOfCurrentButtons.map((item,index)=>{
                    return <li className={item === props.PageNo ? 'active' : ''} key={index} onClick={() => props.handlePageClick(item)}>{item}</li>
                })}
            </ul>
            <button className={props.PageNo === props.TotalPages ? "page-nav right disabled" : "page-nav right"}>
                <i className='icon-angle-right'onClick={() => props.NextPage()}></i>
            </button>
        </div>
        <div className='go-to-page'>
            <p>Go to page</p>
            <input type="text" value={goToPage} onChange={(e)=>setGoToPage(e.target.value)} onKeyPress={(event) => handleNumericCheck(event)}/>
            <button>
                <i className='icon-angle-right' style={{fontSize:"12px"}}  onClick={()=>props.handlePageClick(goToPage-"")} ></i>
            </button>
        </div>
    </div>
    </div>
     
   </>
  )
}

export default Pagination