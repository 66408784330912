import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import format from "date-fns/format";
import { DateFormat, DateFormatCalculations, DateFormat_YMd_Time, DateFormat_dMy } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"
import close from "../../../../assetsOld/img/x-close.svg"
import DatePicker from '../../../../utils/DatePicker';

const AddNewStock = (props) => {
    let Stock_Modal = {
        ProductID: "",
        PurchaseDate: null,
        VendorID: "",
        Warranty: "",// -- (Yes/No)
        WarrantyFrom: null,
        WarrantyTill: null,
        Quantity: "",
        UnitCost: "",
    }

    let valModal = {}
    let ValidationModal = {
        ProductID: "",
        VendorID: "",
        Quantity: "",
        WarrantyTill: "",
        WarrantyFrom: ""
    }

    const DateValidationModal = {
        DateFrom: '',
        DateTo: '',
    }

    const [newStockSaved, setNewStockSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [StockValidationModal, setStockValidationModal] = useState(ValidationModal)
    const [StockID, setStockID] = useState(0)
    const [StockSaveUpdateModal, setStockSaveUpdateModal] = useState({})
    const [del, setDel] = useState(false)
    const [mainStockModal, setmainStockModal] = useState(Stock_Modal);
    const [dateValidation, setDateValidation] = useState(DateValidationModal);
    const API_URL = process.env.REACT_APP_API
    const { isOpenStock, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [hideWrrantyFrom, sethideWrrantyFrom] = useState(false)
    const [ProductdropDown, setProductdropDown] = useState([]);
    const [VendordropDown, setVendordropDown] = useState([]);
    const [checked, setChecked] = useState(false);
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        if (props.StockID != null) {
            setStockID(props.StockID)
            setDel(true)
        } else {
            if (!newStockSaved) {
                setStockID(0)
                setDel(false)
            }
            else { setDel(true) }
        }

        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Product,
        }).then((response) => {
            setIsLoading(false)
            setProductdropDown(response)
        }).catch((error) => {
            setIsLoading(false)
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        })

        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Vendor,
        }).then((response) => {
            setIsLoading(false)
            setVendordropDown(response)
        }).catch((error) => {
            setIsLoading(false)
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        })
        // right now, use any providerid to test update case/find by id

        if (!isNull(StockID)) {
            setIsLoading(true)
            // setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": StockID,
                "TableName": enumUtil.enumAPITableName.Stock
            }).then((response) => {
                setmainStockModal(response);
                setIsLoading(false)
                setChecked(response.Warranty)
                if (response.Warranty === true) {
                    sethideWrrantyFrom(true)
                }
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [StockID])
    //////Calendar////////////
    const [PurchasedDate, setPurchasedDate] = useState();
    const openPurchasedDate = Boolean(PurchasedDate)
    const [warrantyFrom, setWarrantyFrom] = useState();
    const openWarrantyFrom = Boolean(warrantyFrom)
    const [warrantyTill, setWarrantyTill] = useState();
    const openWarrantyTill = Boolean(warrantyTill)

    const handleCalendarChange = (date, type) => {
        var FormatedDateShow = format(date, "MM/dd/yyyy")
        var FormatedDateSave = format(date, DateFormat)
        let currDate = DateFormat_YMd_Time(date)
        let startDate = DateFormatCalculations(mainStockModal.WarrantyFrom)
        let endDate = DateFormatCalculations(mainStockModal.WarrantyTill)
        let PurchasedDate = DateFormatCalculations(mainStockModal.PurchaseDate)

        if (type === 'PurchasedDate') {
            console.log("PurchasedDate", date)
            mainStockModal['PurchaseDate'] = FormatedDateShow
            console.log("FormatedDateShow", FormatedDateShow)
            StockSaveUpdateModal['PurchasedDate'] = FormatedDateSave
            console.log("FormatedDateSave", FormatedDateSave)
        }
        else if (type === 'WarrantyFrom') {
            if (endDate && currDate <= endDate && currDate >= PurchasedDate) {
                mainStockModal['WarrantyFrom'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyFrom'] = FormatedDateSave
                dateValidation['DateFrom'] = ""
            } else if (!endDate && currDate >= PurchasedDate) {
                mainStockModal['WarrantyFrom'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyFrom'] = FormatedDateSave
                dateValidation['DateFrom'] = ""
            } else if (!PurchasedDate && currDate <= endDate) {
                mainStockModal['WarrantyFrom'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyFrom'] = FormatedDateSave
                dateValidation['DateFrom'] = ""
            }
            else if (!endDate && !PurchasedDate) {
                mainStockModal['WarrantyFrom'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyFrom'] = FormatedDateSave
                dateValidation['DateFrom'] = ""
            }
            else {
                dateValidation['DateFrom'] = "error"

            }
        }
        else if (type === 'WarrantyTill') {
            if (startDate && currDate >= startDate) {
                mainStockModal['WarrantyTill'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyTill'] = FormatedDateSave
                dateValidation['DateTo'] = ""

            } else if (!startDate && currDate >= PurchasedDate) {
                mainStockModal['WarrantyTill'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyTill'] = FormatedDateSave
                dateValidation["DateTo"] = ""

            } else if (!PurchasedDate && currDate >= startDate) {
                mainStockModal['WarrantyTill'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyTill'] = FormatedDateSave
                dateValidation["DateTo"] = ""

            }
            else if (!startDate && !PurchasedDate) {
                mainStockModal['WarrantyTill'] = FormatedDateShow
                StockSaveUpdateModal['WarrantyTill'] = FormatedDateSave
                dateValidation["DateTo"] = ""

            }
            else {
                dateValidation["DateTo"] = "error"
            }
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setPurchasedDate(null)
        setWarrantyFrom(null)
        setWarrantyTill(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'PurchasedDate') { setPurchasedDate(event.currentTarget) }
        else if (type === 'WarrantyFrom') { setWarrantyFrom(event.currentTarget) }
        else if (type === 'WarrantyTill') { setWarrantyTill(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ///////////////////////

    const StockValidation = async () => {
        let ValidationModal = {};
        if (StockSaveUpdateModal.Warranty === "true" && checked) {
            ValidationModal = {
                ProductID: FormValidator(
                    mainStockModal.ProductID,
                    enumUtil.enumValidationType.Required,
                    'Product Name',
                ),
                VendorID: FormValidator(
                    mainStockModal.VendorID,
                    enumUtil.enumValidationType.Required,
                    'Vendor Name',
                ),
                Quantity: FormValidator(
                    mainStockModal.Quantity,
                    enumUtil.enumValidationType.Required,
                    'Quantity',
                ),
                WarrantyFrom: FormValidator(
                    mainStockModal.WarrantyFrom,
                    enumUtil.enumValidationType.Required,
                    'Warranty From',
                ),
                WarrantyTill: FormValidator(
                    mainStockModal.WarrantyTill,
                    enumUtil.enumValidationType.Required,
                    'Warranty Till',
                ),
            }
        } else {
            ValidationModal = {
                ProductID: FormValidator(
                    mainStockModal.ProductID,
                    enumUtil.enumValidationType.Required,
                    'Product Name',
                ),
                VendorID: FormValidator(
                    mainStockModal.VendorID,
                    enumUtil.enumValidationType.Required,
                    'Vendor Name',
                ),
                Quantity: FormValidator(
                    mainStockModal.Quantity,
                    enumUtil.enumValidationType.Required,
                    'Quantity',
                ),
            }
        }

        await setStockValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleStockChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainStockModal };
        let saveModal = { ...StockSaveUpdateModal }

        if (name === "Warranty") {
            modal[name] = !modal[name]
            handleWarrantyChange()
        } else {
            modal[name] = value
        }
        setmainStockModal(modal)
        for (var property in Stock_Modal) {
            if (name === property) {
                if (name === "Warranty") {
                    saveModal[name] = modal[name] + ""
                } else {
                    saveModal[name] = value
                }
            }
        }
        setStockSaveUpdateModal(saveModal)
    }
    const handleSaveStock = async () => {
        await StockValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Stock,
                ID: StockID,
                data: { ...StockSaveUpdateModal },
                value: null,
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewStockSaved(true)
                    setStockID(response.id)
                    setIsRefresh(true);
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false);
                setIsRefresh(false);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    // const handleStockDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": StockID,
    //         "TableName": enumUtil.enumAPITableName.Stock
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Stock Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }

    const handleWarrantyChange = () => {
        setChecked(!checked)
        if (StockSaveUpdateModal.Warranty != "true") {
            sethideWrrantyFrom(false)
        } else {
            sethideWrrantyFrom(true)
        }
        if (checked) {
            sethideWrrantyFrom(false)
        } else {
            sethideWrrantyFrom(true)
        }
    }

    const SetStockName = () => {
        if (mainStockModal.StockName != null && mainStockModal.StockName != "") {
            return (
                <span>{mainStockModal.StockName}</span>
            )
        } else {
            return (
                <span>Add New Stock</span>
            )
        }
    }
    //////////
    return (
        <>
            <Modal show={isOpenStock} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal modal-c-lg add_new_stock ems_modal">
                <Modal.Body>
                    {/* <div className='d-flex justify-content-between mb-3'>
                        {SetStockName()}
                    </div> */}
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close} onClick={() => onClose()}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-stock fs-22"></i>
                            </span>
                            <p class="heading mt-2"> {SetStockName()}</p>
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}
                    <div className="row">
                        <div className="col-md-6">
                            <div className={StockValidationModal.ProductID ? "form-group input error" : `form-group input`}>
                                <label>Product Name<span className="req">*</span></label>
                                <select className="form-control pro_name"
                                    name="ProductID"
                                    id="ProductID"
                                    aria-label="Default select example"
                                    value={mainStockModal.ProductID}
                                    onChange={handleStockChange}
                                >
                                    <option value="">Select</option>
                                    {ProductdropDown.map((e, index) => (
                                        <option value={e.ProductID} key={index}>
                                            {e.ProductName}
                                        </option>
                                    ))}
                                </select>
                                {StockValidationModal.ProductID}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='form-group input'>
                                {/* {console.log("01234560",mainStockModal.PurchasedDate)} */}
                                <DatePicker
                                    label='Purchase Date'
                                    maxDate={new Date()}
                                    value={mainStockModal.PurchaseDate}
                                    openCalendarMenufunc={(event) => openCalendarMenu(event, 'PurchasedDate')}
                                    anchorEl={PurchasedDate}
                                    open={openPurchasedDate}
                                    closeCalendarMenufunc={closeCalendarMenu}
                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'PurchasedDate')}
                                    placeholder={true}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={StockValidationModal.VendorID ? "form-group input error" : "form-group input"}>
                                <label>Vendor Name<span className="req">*</span></label>
                                <select className="form-control pro_name"
                                    name="VendorID"
                                    id="VendorID"
                                    value={mainStockModal.VendorID}
                                    aria-label="Default select example"
                                    onChange={handleStockChange}
                                >
                                    <option value="">Select</option>
                                    {VendordropDown.map((e, index) => (
                                        <option value={e.VendorID} key={index}>
                                            {e.VendorName}
                                        </option>
                                    ))}
                                </select>
                                {StockValidationModal.VendorID}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={StockValidationModal.Quantity ? "form-group input error" : "form-group input"}>
                                <label>Quantity<span className="req">*</span></label>
                                <input type="text"
                                    className="form-control"
                                    maxLength={5}
                                    name="Quantity"
                                    id="Quantity"
                                    value={mainStockModal.Quantity}
                                    onChange={handleStockChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                />
                                {StockValidationModal.Quantity}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group input">
                                <label>Unit Cost</label>
                                <input type="text"
                                    className="form-control"
                                    name="UnitCost"
                                    id="UnitCost"
                                    maxLength={10}
                                    value={mainStockModal.UnitCost}
                                    onChange={handleStockChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-check form-check-inline mt-4 custom_check_box">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="Warranty"
                                    id="Warranty"
                                    value={mainStockModal.Warranty}
                                    checked={checked}
                                    onChange={handleStockChange}
                                />
                                <label class="form-check-label" htmlFor="Warranty">Warranty</label>
                            </div>
                        </div>
                        {hideWrrantyFrom &&
                            <>
                                <div className="col-md-6">
                                    <div className={StockValidationModal.WarrantyFrom ? "form-group input error" : `form-group input  ${dateValidation.DateFrom}`}>

                                        <DatePicker
                                            label={<label>Warranty From<span className="req">*</span></label>}
                                            value={mainStockModal.WarrantyFrom}
                                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'WarrantyFrom')}
                                            anchorEl={warrantyFrom}
                                            open={openWarrantyFrom}
                                            closeCalendarMenufunc={closeCalendarMenu}
                                            handleCalendarChangefunc={date => handleCalendarChange(date, 'WarrantyFrom')}
                                            placeholder={true}
                                        />
                                        {dateValidation.DateFrom === 'error' ? <label>Warranty From must be less than Warranty Till</label> : <label></label>}
                                        {StockValidationModal.WarrantyFrom}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className={StockValidationModal.WarrantyTill ? "form-group input error" : `form-group input ${dateValidation.DateTo}`}>
                                        <DatePicker
                                            label={<label>Warranty Till<span className="req">*</span></label>}
                                            value={mainStockModal.WarrantyTill}
                                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'WarrantyTill')}
                                            anchorEl={warrantyTill}
                                            open={openWarrantyTill}
                                            closeCalendarMenufunc={closeCalendarMenu}
                                            handleCalendarChangefunc={date => handleCalendarChange(date, 'WarrantyTill')}
                                            placeholder={true}
                                        />
                                        {dateValidation.DateTo === 'error' ? <label>Warranty Till must be greater than Warranty From</label> : <label></label>}
                                        {StockValidationModal.WarrantyTill}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveStock} />
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                    <button className='btn btn-gray' onClick={() => onClose(newStockSaved)}>Cancel</button>
                    <button className='btn btn-orange' onClick={handleSaveStock}>Save</button>
                </div> */}
            </Modal>
        </>
    )
}
export default AddNewStock