import React from 'react'

const ModalFooterAdmin = (props) => {
    const { isSaved, handleSave, RequestStatus } = { ...props };

    return (
        <div className='btns-group modal_footer br-top-1 br-clr d-flex justify-content-end gap-3'>
            <div className="btns-group d-flex justify-content-center align-items-center">
                <button className={`btn btn-gray ${RequestStatus !== 'Pending' ? 'disabled' : ''}`}  onClick={() => isSaved()}>
                    Deny
                </button>

                <button className={`btn btn-blue ${RequestStatus !== 'Pending' ? 'disabled' : ''}`}  onClick={() => handleSave()}>
                    Approve
                </button>
            </div>
        </div>
    )
}
ModalFooterAdmin.defaultProps = {
    RequestStatus: 'Pending',
  };

export default ModalFooterAdmin
