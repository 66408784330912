import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"
import { ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import { enumUtil } from '../../../../utils/Enum';
import { useSelector } from 'react-redux';
import FormValidator from '../../../../utils/FormValidator';
import CalendarInput from "../../../../utils/CalendarInput"
import jwt_decode from 'jwt-decode';
import close from "../../../../assetsOld/img/x-close.svg"
import Loader from '../../../shared/Loader/Loader';
import NotificationHandler from '../../../../utils/NotificationHandler';
import isNull from '../../../../utils/NullChecking';
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import format from 'date-fns/format';
import DatePicker from '../../../../utils/DatePicker';

const AssignNewDevice = (props) => {

    const { assigndevice, onClose, ID, setIsRefresh, type } = { ...props }

    let EmployeeStockReference_Modal = {
        StockID: "",
        ProductName: "",
        AssignedBy: "",
        AssignedDate: null,
        IsItemDefective: false,
        ProductDetails: "",
    }
    let valModal = {}
    let ValidationModal = {
        StockIDVal: '',
        AssignedBy: "",
    }

    const API_URL = process.env.REACT_APP_API
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let Username = Token.Username;
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    } else {
        EmployeeID = Token.UserID;
    }

    const OrganizationID = useSelector((state) => state.Organization);
    const [EmployeeStockReferenceSaveUpdateModal, setEmployeeStockReferenceSaveUpdateModal] = useState({})
    const [mainEmployeeStockReferenceModal, setmainEmployeeStockReferenceModal] = useState(EmployeeStockReference_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [EmployeeStockReferenceValidationModal, setEmployeeStockReferenceValidationModal] = useState(ValidationModal)
    const [EmployeeStockReferenceID, setEmployeeStockReferenceID] = useState(0);
    const [addDropDown, setAddDropDown] = useState([]);
    ///////Calendar////////
    const [assignedDate, setAssignedDate] = useState();
    const openAssignedDate = Boolean(assignedDate)

    const handleCalendarChange = (date, type) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, "MM/dd/yyyy")
        let modal = { ...mainEmployeeStockReferenceModal };
        let saveModal = { ...EmployeeStockReferenceSaveUpdateModal }
        modal[type] = FormatedDateShow
        setmainEmployeeStockReferenceModal(modal)
        for (var property in EmployeeStockReference_Modal) {
            if (type === property) {
                saveModal[type] = FormatedDateSave
            }
        }
        setEmployeeStockReferenceSaveUpdateModal(saveModal)
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setAssignedDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'AssignedDate') { setAssignedDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };

    const handleEmployeeStockReferenceChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainEmployeeStockReferenceModal };
        let saveModal = { ...EmployeeStockReferenceSaveUpdateModal }

        if (name === "IsItemDefective") {
            modal[name] = !modal[name]
        } else {
            modal[name] = value
        }
        setmainEmployeeStockReferenceModal(modal)
        for (var property in EmployeeStockReference_Modal) {
            if (name === property) {
                if (name === "IsItemDefective") {
                    saveModal[name] = modal[name] + ""
                } else {
                    saveModal[name] = value
                }
            }
        }
        setEmployeeStockReferenceSaveUpdateModal(saveModal)
    }

    const EmployeeStockReferenceValidation = async () => {
        let ValidationModal = {
            AssignedBy: FormValidator(
                mainEmployeeStockReferenceModal.AssignedBy,
                enumUtil.enumValidationType.Required,
                'Assigned By',
            ),
            StockIDVal: FormValidator(
                mainEmployeeStockReferenceModal.StockID,
                enumUtil.enumValidationType.Required,
                'Stock ID',
            ),
        }
        await setEmployeeStockReferenceValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSavehandleEmployeeStockReference = async () => {
        await EmployeeStockReferenceValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            // if (EmployeeStockReferenceSaveUpdateModal['IsItemDefective'] !== "true") {
            //     EmployeeStockReferenceSaveUpdateModal['IsItemDefective'] = "false";
            // }
            EmployeeStockReferenceSaveUpdateModal['EmployeeID'] = EmployeeID + "";
            setEmployeeStockReferenceSaveUpdateModal(EmployeeStockReferenceSaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.EmployeeStockReference,
                ID: EmployeeStockReferenceID,
                // ParentID: EmployeeID,
                data: { ...EmployeeStockReferenceSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setEmployeeStockReferenceID(response.id)
                    setIsRefresh(true);
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }

    useEffect(() => {

        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.EmployeeStockReference,
        }).then((response) => {
            setIsLoading(false)
            setAddDropDown(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })

        if (!isNull(ID)) {
            setEmployeeStockReferenceID(ID)
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.EmployeeStockReference
            }).then((response) => {
                setmainEmployeeStockReferenceModal(response);
                setIsLoading(false)
                if (response.IsItemDefective === null) {
                    mainEmployeeStockReferenceModal['IsItemDefective'] = "false";
                }
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [EmployeeStockReferenceID])

    useEffect(() => {
        if (Username) {
            mainEmployeeStockReferenceModal["AssignedBy"] = Username;
            EmployeeStockReferenceSaveUpdateModal["AssignedBy"] = Username;
        }
    }, [Username])
    return (
        <div>
            <Modal show={assigndevice} onHide={assigndevice} backdrop="static" keyboard={false} centered dialogClassName={`app-modal ems_modal add_new_des add_new_device ${!type ? 'ar_disable-popup' : ''}`}>
                <Modal.Body>
                    {isLoading ? <Loader /> : <></>}
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={() => onClose()}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Vector-9"></i>
                            </span>
                            <p class="heading mt-2">Assign Device</p>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className={EmployeeStockReferenceValidationModal.StockIDVal ? "form-group input error" : "form-group input"}>
                            <label>Product Name<span className="req">*</span></label>

                            {EmployeeStockReferenceID === 0 ?
                                <select className="form-control pro_name"
                                    name="StockID"
                                    id="StockID"
                                    aria-label="Default select example"
                                    value={mainEmployeeStockReferenceModal.StockID}
                                    onChange={handleEmployeeStockReferenceChange}
                                >
                                    <option value="">Select</option>
                                    {addDropDown?.map((e, index) => (
                                        <option value={e.StockID} key={index}>
                                            {e.ProductName}
                                        </option>
                                    ))}
                                </select> :
                                <select className="form-control"
                                    name="StockID"
                                    id="StockID"
                                    aria-label="Default select example"
                                    value={mainEmployeeStockReferenceModal.StockID}
                                    onChange={handleEmployeeStockReferenceChange}
                                    disabled="disabled"
                                >
                                    <option value={mainEmployeeStockReferenceModal.StockID} >
                                        {mainEmployeeStockReferenceModal.ProductName}
                                    </option>
                                </select>
                            }
                            {EmployeeStockReferenceValidationModal.StockIDVal}
                        </div>
                        <div className={EmployeeStockReferenceValidationModal.AssignedBy ? "form-group input error" : "form-group input"}>
                            <label>Assigned By<span className="req">*</span></label>
                            <input type="text"
                                maxLength={200}
                                className="form-control"
                                name="AssignedBy"
                                id="AssignedBy"
                                value={mainEmployeeStockReferenceModal.AssignedBy}
                                onChange={handleEmployeeStockReferenceChange}
                                readOnly
                                disabled
                            />
                            {EmployeeStockReferenceValidationModal.AssignedBy}

                        </div>
                        <div className="form-group input">
                            <DatePicker
                                label='Assigned Date'
                                maxDate={new Date()}
                                value={mainEmployeeStockReferenceModal.AssignedDate}
                                openCalendarMenufunc={(event) => openCalendarMenu(event, 'AssignedDate')}
                                anchorEl={assignedDate}
                                open={openAssignedDate}
                                closeCalendarMenufunc={closeCalendarMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'AssignedDate')}
                                placeholder={true}
                            />
                        </div>
                        <div className="form-group input">
                            <label>Product Details</label>
                            <textarea type="text"
                                rows="3"
                                className="form-control"
                                name="ProductDetails"
                                id="ProductDetails"
                                value={mainEmployeeStockReferenceModal.ProductDetails}
                                onChange={handleEmployeeStockReferenceChange} />
                        </div>
                        <div className='d-flex align-items-center gap-1'>
                            <div class="custom_check_box">
                                <input type="checkbox"
                                    className="form-check-input"
                                    name="IsItemDefective"
                                    id="IsItemDefective"
                                    checked={mainEmployeeStockReferenceModal.IsItemDefective}
                                    onChange={handleEmployeeStockReferenceChange}
                                />
                            </div>
                            <p className='text clr_gry'>Is Item Defective</p>
                        </div>
                    </div>
                </Modal.Body>
                {type && <ModalFooter isSaved={onClose} handleSave={handleSavehandleEmployeeStockReference} />}
            </Modal>
        </div>
    )
}

export default AssignNewDevice
