import "./Loader.css"
const Loader = () => {

    return (
        <>
            <div className="loading">
                <div className="spinner">
                </div>
            </div>
        </>
    )
}
export default Loader