import BasicInformation from "../../BasicInformation/BasicInformation"
import Certification from "../../Certification/Certification"
import Education from "../../Education/Education"
import Experience from "../../Experience/Experience"
import Skills from "../../Skill/Skills"
const EmployeeMenu = () => {
    return [
        {
            name: '',
            key: 21,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Education',
                    key: 211,
                    icon: "icon-Icon-awesome-graduation-cap",
                    class: 'education',
                    component: <Education />,
                    //component:<p>Education</p>,
                    right: true,
                },
                {
                    name: 'Certification',
                    key: 212,
                    icon: "icon-Icon-awesome-certificate",
                    class: 'certification',
                    component: <Certification />,
                    right: true,
                },
                {
                    name: 'Experience',
                    key: 213,
                    icon: "icon-Icon-awesome-graduation-cap",
                    class: 'experience',
                    component: <Experience />,
                    right: true,
                },
                {
                    name: 'Skills',
                    key: 214,
                    icon: "icon-Icon-material-work",
                    class: 'skills',
                    component: <Skills/>,
                    right: true,
                },
            ]
        },
    ]
}


export default EmployeeMenu