import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import handleNumericCheck from "../../../../utils/HandleNumericKeys";
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import Loader from "../../../shared/Loader/Loader"
import close from "../../../../assetsOld/img/x-close.svg"
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux"
//import DatePicker from "react-datepicker"
import { Autocomplete, TextField } from "@mui/material";
import ModalFooter from "../../../shared/ModalFooter/ModalFooter";

const AddNewDepartment = (props) => {
    let department_Modal = {
        ManagerID: "",
        DepartmentName: '',
        ExtensionNumber: "",
        TotalEmployees: ""
    }
    let valModal = {}
    let ValidationModal = {
        DepartmentName: "",
        ManagerIDval: "",
        TeamIDVal: "",
    }

    const [newDepartmentSaved, setNewDepartmentSaved] = useState(false);
    const [addManagerDropDown, setAddManagerDropDown] = useState([]);
    const [dropDownDataTeam, setDropDownDataTeam] = useState([])
    const [teamValue, setTeamValue] = useState([])
    const [DepartmentValidationModal, setDepartmentValidationModal] = useState(ValidationModal)
    const [DepartmentID, setDepartmentID] = useState()
    const [departmentSaveUpdateModal, setdepartmentSaveUpdateModal] = useState({})
    const [mainDepartmentModal, setmainDepartmentModal] = useState(department_Modal);
    const [isLoading, setIsLoading] = useState(false)
    let [SelectedDate, setSelectedDate] = useState(null);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [del, setDel] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenDepartment, onClose, onCloseSave, CallFrom, setIsRefresh } = { ...props }
    //////////
    useEffect(() => {
        setIsLoading(false)
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Team,
        }).then((response) => {
            setDropDownDataTeam(response)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        })
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Employee,
            "Indicator": "Manager"
        }).then((response) => {
            setIsLoading(false)
            setAddManagerDropDown(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })
        // right now, use any providerid to test update case/find by id
        if (props.DepartmentID != null) {
            setDepartmentID(props.DepartmentID)
            setDel(true)
        } else {
            if (!newDepartmentSaved) {
                setDepartmentID(0)
                setDel(false)
            }
            else { setDel(true) }
        }
        if (!isNull(DepartmentID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": DepartmentID,
                "TableName": enumUtil.enumAPITableName.Department
            }).then((response) => {
                setmainDepartmentModal(response);
                if (response?.TeamIDs) { setTeamValue(JSON.parse(response?.TeamIDs)) }
                setIsLoading(false)
                var a = new Date(response.EstablishedDate)
                setSelectedDate(a);
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [DepartmentID])

    const handleDepartmentChange = (e) => {
        let { name, value } = e.target;
        value = value || null
        let modal = { ...mainDepartmentModal };
        let saveModal = { ...departmentSaveUpdateModal }
        modal[name] = value
        setmainDepartmentModal(modal)
        for (var property in department_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setdepartmentSaveUpdateModal(saveModal)
    }

    const DepartmentValidation = async () => {
        let ValidationModal = {
            DepartmentName: FormValidator(
                mainDepartmentModal.DepartmentName,
                enumUtil.enumValidationType.Required,
                'Department Name',
            ),
            ManagerIDval: FormValidator(
                mainDepartmentModal.ManagerID,
                enumUtil.enumValidationType.Required,
                'Manager ID',
            ),
            TeamIDVal: FormValidator(
                mainDepartmentModal.TeamIDs,
                enumUtil.enumValidationType.Required,
                'Team ID',
            )
        }
        await setDepartmentValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveDepartment = async () => {
        await DepartmentValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Department,
                ID: DepartmentID,
                data: { ...departmentSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewDepartmentSaved(true)
                    setDepartmentID(response.id)
                    setIsRefresh(true);
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(true);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    const handleMultiSelectChange = (values, type, reason) => {
        let arr = [];
        let arrVal = []
        values.map((key, index) => {
            arr.push(key)
        })
        values.map((key, index) => {
            arrVal.push(key.TeamID)
        })
        setTeamValue(arr)
        mainDepartmentModal['TeamIDs'] = !arr.length <= 0 ? arr : "";
        departmentSaveUpdateModal['TeamIDs'] = arrVal.toString()
    }
    // const handleDepartmentDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": DepartmentID,
    //         "TableName": enumUtil.enumAPITableName.Department
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Department Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }
    const SetDepartmentName = () => {
        if (mainDepartmentModal.DepartmentName != null && mainDepartmentModal.DepartmentName != "") {
            return (
                <p className="heading validation_message">{mainDepartmentModal.DepartmentName}</p>
            )
        } else {
            return (
                <p className="heading">Add New Department</p>
            )
        }
    }

    return (
        <>
            <Modal show={isOpenDepartment} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal add_new_dep ems_modal">
                <Modal.Body>
                    {isLoading ? <Loader /> : null}
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close} onClick={() => onClose(newDepartmentSaved)}></img>

                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="icon_bg">
                                    <i className="icon-plus-icon"></i>
                                </div>
                            </span>
                            <p class="heading mt-2"> {SetDepartmentName()}</p>
                        </div>
                    </div>
                    <div>
                        <div className={DepartmentValidationModal.ManagerIDval ? "form-group input error" : "form-group input"}>
                            <label>Manager<span className='req'>*</span></label>
                            <select className="form-control"
                                name="ManagerID"
                                id="ManagerID"
                                aria-label="Default select example"
                                value={mainDepartmentModal.ManagerID}
                                onChange={handleDepartmentChange}
                            >

                                {/* {ManagerValueName ? <option>{ManagerValueName}</option>:<option value="">Select</option>}  */}
                                {/* <option value="">Select</option> */}
                                {addManagerDropDown.map((e, index) => (
                                    <option value={e.EmployeeID} key={index}>
                                        {e.EmployeeName}
                                    </option>
                                ))}
                            </select>
                            {DepartmentValidationModal.ManagerIDval}
                        </div>
                        {/* ///////// */}
                        <div className={DepartmentValidationModal.TeamIDVal ? "form-group input error" : "form-group input"}>
                            <label>Team <span className='req'>*</span></label>
                            <div className="w-100">
                                <Autocomplete
                                    multiple
                                    limitTags={2}
                                    id='TeamIDs'
                                    name='TeamIDs'
                                    value={teamValue}
                                    options={dropDownDataTeam}
                                    isOptionEqualToValue={(option, value) => option.TeamID === value.TeamID}
                                    onChange={(event, value, reason) => handleMultiSelectChange(value, 'TeamIDs', reason)}
                                    getOptionLabel={(option) => option.TeamName}
                                    noOptionsText={"Not Available"}
                                    renderInput={(params) => <TextField {...params} variant="standard" />}
                                />
                            </div>
                            {DepartmentValidationModal.TeamIDVal}
                        </div>
                        {/* ///////// */}
                        <div className={DepartmentValidationModal.DepartmentName ? "form-group input error" : "form-group input"}>
                            <label>Department Name<span className='req'>*</span></label>
                            <input type="text"
                                className="form-control"
                                name="DepartmentName"
                                id="DepartmentName"
                                maxLength={35}
                                value={mainDepartmentModal.DepartmentName}
                                onChange={handleDepartmentChange}
                            />
                            {DepartmentValidationModal.DepartmentName}
                        </div>
                        <div className="form-group input">
                            <label>Total Employees</label>
                            <input type="text"
                                className="form-control"
                                name="TotalEmployees"
                                id="TotalEmployees"
                                maxLength={5}
                                value={mainDepartmentModal.TotalEmployees}
                                onChange={handleDepartmentChange}
                                onKeyPress={(event) => handleNumericCheck(event)}
                            />
                        </div>
                        <div className="form-group input">
                            <label>Extension Number</label>
                            <input type="text"
                                className="form-control"
                                name="ExtensionNumber"
                                id="ExtensionNumber"
                                maxLength={3}
                                value={mainDepartmentModal.ExtensionNumber}
                                onChange={handleDepartmentChange}
                                onKeyPress={(event) => handleNumericCheck(event)}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveDepartment} />
            </Modal>
        </>
    )
}

export default AddNewDepartment