import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import close from "../../../../assetsOld/img/x-close.svg"
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"

const AddNewFile = (props) => {
    let FileType_Modal = {
        DocumentName: "",
        DocumentDescription:"",


    }
    let valModal = {}
    let ValidationModal = {
        DocumentName: "",
        
    }
    const [newFileTypeSaved,setNewFileTypeSaved] = useState(false);
    const [FileTypeValidationModal, setFileTypeValidationModal] = useState(ValidationModal)
    const [FileNameID, setFileNameID] = useState()
    const [FileTypeUpdateModal, setFileTypeUpdateModal] = useState({})
    const [mainFileTypeModal, setmainFileTypeModal] = useState(FileType_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [del, setDel] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenFileType, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setIsLoading(false)
        // right now, use any providerid to test update case/find by id
        if (props.DocumentTypeID != null) {
            setFileNameID(props.DocumentTypeID)
            setDel(true)
        } else {
            if(!newFileTypeSaved){
                setFileNameID(0);
                setDel(false)
            }
            else{setDel(true)} 
       
        }
        if (!isNull(FileNameID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "TableName": enumUtil.enumAPITableName.DocumentType,
                "ID": FileNameID,
            }).then((response) => {
                setmainFileTypeModal(response);
                console.log(response)
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [FileNameID])

    const handleSaveFileType = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainFileTypeModal };
        let saveModal = { ...FileTypeUpdateModal }
        modal[name] = value
        setmainFileTypeModal(modal)
        for (var property in FileType_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setFileTypeUpdateModal(saveModal)
    }

    const FileTypeValidation = async () => {
        let ValidationModal = {
            DocumentName: FormValidator(
                mainFileTypeModal.DocumentName,
                enumUtil.enumValidationType.Required,
                'File Name',
            ),
        }
        await setFileTypeValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveFile = async () => {
        await FileTypeValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return

        } else {
            setFileTypeUpdateModal(FileTypeUpdateModal)

            let saveModal = {
                TableName: enumUtil.enumAPITableName.DocumentType,
                ID: FileNameID,
                data: { ...FileTypeUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewFileTypeSaved(true)
                    setFileNameID(response.id)
                    setIsRefresh(true);
                    onCloseSave();
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }

    // const handleFileTypeDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": FileNameID,
    //         "TableName": enumUtil.enumAPITableName.DocumentType
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Document Name Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }
    const SetLeaveTypeName = () => {
        if (mainFileTypeModal.DocumentName != null && mainFileTypeModal.DocumentName != "") {
            return (
                <p className="heading validation_message">{mainFileTypeModal.DocumentName}</p>
            )
        } else {
            return (
                <p className="heading">Add New  Document Type</p>
            )
        }
    }
  
    //////////
    return (
        <>
      
            <Modal show={isOpenFileType} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal add_new_file ems_modal">
                <Modal.Body> 
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close}onClick={() => onClose(newFileTypeSaved)}></img>
               
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                    <i className="fs-18 icon-Group-482490"></i>
                            </span>
                            <p class="heading my-2"> {SetLeaveTypeName()}</p>
                        </div>
                    </div>  
                    {isLoading ? <Loader /> : null}
                    <div>
                        <div className={FileTypeValidationModal.DocumentName ? "form-group input error" : "form-group input"}>
                            <label>Document Name<span className='req'>*</span></label>
                            <input type="text"
                                maxLength={35}
                                className="form-control"
                                name="DocumentName"
                                id="DocumentName"
                                value={mainFileTypeModal.DocumentName}
                                onChange={handleSaveFileType}
                            />
                            {FileTypeValidationModal.DocumentName}
                        </div>
                        <div className="form-group input">
                            <label>Document Description</label>
                            <textarea rows="5"
                                className="form-control"
                                name="DocumentDescription"
                                id="DocumentDescription"
                                maxLength={500}
                                value={mainFileTypeModal.DocumentDescription}
                                onChange={handleSaveFileType}
                            ></textarea>
                        </div>
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveFile} />
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                    <button className='btn btn-gray' onClick={() => onClose(newFileTypeSaved)}>Cancel</button>
                    <button className='btn btn-orange' onClick={handleSaveFile}>Save</button>
                </div> */}
            </Modal>
        </>
    )
}

export default AddNewFile