import React from 'react'

const PayrollSummary = () => {
  return (
    <div className="card_tabs payroll_sum mt-2 mb-1">
        <p className="heading pb-10 d-flex align-items-center">Payroll Summary <span className="xsm_text orange">. This Month</span></p>
        <p className="heading blue-clr fs-20 d-flex align-items-center">12 <span className="text clr_gry ms-2">days until next month payment</span></p>
        <p className="heading orange"><i className="icon-Vector3 fs-10 cursor-pointer"></i>-2.36% <span className="xsm_text clr_gry font-m ">next pay versus previous pay</span></p>
    </div>
  )
}

export default PayrollSummary