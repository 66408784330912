import React, { useEffect, useState } from 'react'
import { enumUtil } from '../../../../utils/Enum';
import ReactDataGrid from "react-data-grid";
import { ServerRequestProtected } from '../../../../utils/ServerDataRequest';
import CustomUseRef from '../../../shared/CustomUseRef/CustomUseRef';
import DeleteConfirmationAdjustment from '../../../shared/Delete/DeleteConfirmationAdjustment';
import AddTimeAdjustment from '../../../MyAttendance/AddTimeAdjustment.js/AddTimeAdjustment';
import AddNewRequest from '../AddNewRequest/AddNewRequest';
import AddNewLeave from '../../../Leaves/AddNewLeave';
import DeleteConfirmation from '../../../shared/Delete/DeleteConfirmation';
import AddNewResign from '../../../MyInfo/Account/Resign/AddNewResign';
import GridColumnHeader from '../../../shared/GridColumnHeader/GridColumnHeader';

const TableView = (props) => {
    const { ColumnsData, type, LoadData, searchParams, RequestCount, gridHeader, handleGridHeader, view } = { ...props }
    const [isLoading, setIsLoading] = useState(false);
    const [reqeustsData, setRequestsData] = useState([]);
    const [actionsbutton, setActionsbutton] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isRequestOpen, setIsRequestOpen] = useState(false);
    const [delID, setDelID] = useState(0);
    const [delData, setDelData] = useState();
    const [RequestID, SetRequestID] = useState(0)
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [requestType, setRequestType] = useState("");
    const [status, setStatus] = useState("")
    const [isRefresh, setIsRefresh] = useState(false);
    const [resignData, setResignData] = useState({})
    const API_URL = process.env.REACT_APP_API;
    const API_URL_DASHBOARD = process.env.REACT_APP_API_Dashboard;



    const CloseActionPopup = () => {
        setActionsbutton(false);
    }
    const handeActionPopUp = (e, id) => {
        e.stopPropagation();
        setActionsbutton(true);
        setSelectedRow(id);
    }
    const columns = [
        {
            key: 'EmployeeID',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Employee ID'} sortIcon={true} type={'number'} colKey={'EmployeeID'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,
            // formatter: ({ row }) => {
            //     return (
            //         <button className='btn' onClick={""}>
            //             {row?.CategoryName}
            //         </button>
            //     )
            // }
        },
        {
            key: 'EmployeeName',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Employee Name'} sortIcon={true} type={'string'} colKey={'EmployeeName'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            // flex: 1,
            width: 200,
            formatter: ({ row }) => {
                return (
                    <div className="\validation_message">
                        {row?.EmployeeName}
                    </div>
                )
            }

        },

        ...(view === "Leave" ? [
            {
                key: 'LeaveType',
                name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                    iconName={'icon-card'} headerName={'Leave Type'} sortIcon={true} type={'string'} colKey={'LeaveType'} data={reqeustsData} setData={setRequestsData} />),

                resizable: true,
                flex: 1,
                formatter: ({ row }) => {
                    return (
                        <div className="validation_message" title={row?.LeaveType}>
                            {row?.LeaveType}
                        </div>
                    )
                }
            },
            {
                key: 'DateFrom',
                name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                    iconName={'icon-card'} headerName={'Requested Date'} sortIcon={true} type={'date'} colKey={'AppliedOn'} data={reqeustsData} setData={setRequestsData} />),

                resizable: true,
                flex: 1,
                formatter: ({ row }) => {
                    return (
                        <div className="heading text-primary font-m validation_message cursor-pointer" title={row?.EmployeeName} onClick={() => AddRequest(row)}>
                            {row?.DateFrom + ' - ' + row?.DateTo}
                        </div>
                    )
                }
            },
        ] : [
            {
                key: 'Type',
                name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                    iconName={'icon-card'} headerName={'Request Type'} sortIcon={true} type={'string'} colKey={'Type'} data={reqeustsData} setData={setRequestsData} />),

                resizable: true,
                flex: 1,
                formatter: ({ row }) => {
                    return (
                        <div className="validation_message" title={row?.Type}>
                            {row?.Type}
                        </div>
                    )
                }
            },
            {
                key: 'RequestedDate',
                name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                    iconName={'icon-card'} headerName={'Requested Date'} sortIcon={true} type={'date'} colKey={'RequestedDate'} data={reqeustsData} setData={setRequestsData} />),

                resizable: true,
                flex: 1,
                formatter: ({ row }) => {
                    return (
                        <div className="heading text-primary font-m validation_message cursor-pointer" title={row?.EmployeeName} onClick={() => AddRequest(row)}>
                            {row?.RequestedDate}
                        </div>
                    )
                }
            },
        ]),


        {
            key: 'Description',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Description'} sortIcon={true} type={'string'} colKey={'Description'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,
            formatter: ({ row }) => {
                return (
                    <div className="office_name_truncate" title={row?.Description}>
                        {row?.Description}
                    </div>
                )
            }

        },
        {
            key: 'AppliedOn',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Applied On'} sortIcon={true} type={'date'} colKey={'AppliedOn'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            // width: 110,
            flex: 1,
        },
        {
            key: 'ApprovedDate',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Decision Date'} sortIcon={true} type={'date'} colKey={'ApprovedDate'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,
        },
        {
            key: 'ApprovedBy',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Decision By'} sortIcon={true} type={'string'} colKey={'ApprovedBy'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            // width: 110,
            flex: 1,
        },
        {
            key: 'RequestStatus',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Status'} sortIcon={true} type={'string'} colKey={'RequestStatus'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            // width: 120,
            flex: 1,
            formatter: ({ row }) => {
                return (
                    <div className={`${row?.RequestStatus} mt-1`}>
                        {row?.RequestStatus}
                    </div>
                )
            }
        },
        {
            key: 'Actions',
            name: 'Actions',
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <>
                        <div className="w-50 text-start" onClick={(e) => handeActionPopUp(e, row?.ID)} >
                            <i className="icon-menuicon cursor-pointer"></i>
                        </div>

                        {selectedRow === row?.ID &&
                            <CustomUseRef onClose={CloseActionPopup} IsActivePtPayments={actionsbutton}>
                                <div className="actions_popup cursor-pointer">
                                    {/* <p className="text  p-2 py-0" onClick={() => AddRequest(row)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                                    // {row?.RequestStatus === "Pending" && <p className="text p-2 py-0" onClick={() => handleDeleteModal(row?.ID)}><i className="icon-Group-3994 me-1"></i>Delete</p>} */}
                                    {row?.RequestStatus === "Pending" ?
                                        <>
                                            <p className="text  p-2 py-0" onClick={() => AddRequest(row)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                                            <p className="text p-2 py-0" onClick={() => handleDeleteModal(row)}><i className="icon-Group-3994 me-1"></i>Delete</p>
                                        </>
                                        :
                                        <>
                                            <p className="text p-2 py-0" onClick={() => handleDeleteModal(row)}><i className="icon-Group-3994 me-1"></i>Delete</p>
                                            <p className="text  p-2 py-0" onClick={() => AddRequest(row)}><i className="icon-Group-482282 me-1"></i>View</p>
                                        </>
                                    }
                                </div>
                            </CustomUseRef>
                        }
                    </>
                );
            },
        }
    ];
    const columnsForLeaves = [
        {
            key: 'EmployeeID',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Employee ID'} sortIcon={true} type={'number'} colKey={'EmployeeID'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,

            // formatter: ({ row }) => {
            //     return (
            //         <button className='btn' onClick={""}>
            //             {row?.CategoryName}
            //         </button>
            //     )
            // }
        },
        {
            key: 'EmployeeName',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Employee Name'} sortIcon={true} type={'string'} colKey={'EmployeeName'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            // flex: 1,
            width: 200,
            formatter: ({ row }) => {
                return (
                    <div className="validation_message">
                        {row?.EmployeeName}
                    </div>
                )
            }

        },

        {
            key: 'LeaveType',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Leave Type'} sortIcon={true} type={'string'} colKey={'LeaveType'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,
            formatter: ({ row }) => {
                return (
                    <div className="validation_message" title={row?.LeaveType}>
                        {row?.LeaveType}
                    </div>
                )
            }
        },
        {
            key: 'AppliedOn',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Applied On'} sortIcon={true} type={'date'} colKey={'AppliedOn'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,
        },

        {
            key: 'Description',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Description'} sortIcon={true} type={'string'} colKey={'Description'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,
            formatter: ({ row }) => {
                return (
                    <div className="office_name_truncate" title={row?.Description}>
                        {row?.Description}
                    </div>
                )
            }

        },
        // {
        //     key: 'AppliedOn',
        //     name: 'Applied On',
        //     resizable: true,
        //     width: 110,
        //     // flex: 1,
        // },
        {
            key: 'ApprovedDate',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Decision Date'} sortIcon={true} type={'date'} colKey={'ApprovedDate'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            flex: 1,
        },
        {
            key: 'Approvedby',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Decision By'} sortIcon={true} type={'date'} colKey={'Approvedby'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            // width: 110,
            flex: 1,
        },
        {
            key: 'RequestStatus',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Status'} sortIcon={true} type={'string'} colKey={'RequestStatus'} data={reqeustsData} setData={setRequestsData} />),

            resizable: true,
            // width: 120,
            flex: 1,
            formatter: ({ row }) => {
                return (
                    <div className={`${row?.RequestStatus} mt-1`}>
                        {row?.RequestStatus}
                    </div>
                )
            }
        },
        {
            key: 'Actions',
            name: 'Actions',
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <>
                        <div className="w-50 text-start" onClick={(e) => handeActionPopUp(e, row?.ID)} >
                            <i className="icon-menuicon cursor-pointer"></i>
                        </div>
                        {console.log("selectedRow === row?.EmployeeDocumentID", selectedRow === row?.ID, selectedRow, row?.ID)}
                        {selectedRow === row?.ID &&
                            <CustomUseRef onClose={CloseActionPopup} IsActivePtPayments={actionsbutton}>
                                <div className="actions_popup cursor-pointer">
                                    {/* <p className="text  p-2 py-0" onClick={() => AddRequest(row)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                                    {row?.RequestStatus === "Pending" && <p className="text p-2 py-0" onClick={() => handleDeleteModal(row?.ID)}><i className="icon-Group-3994 me-1"></i>Delete</p>} */}
                                    {row?.RequestStatus === "Pending" ?
                                        <>
                                            <p className="text  p-2 py-0" onClick={() => AddRequest(row)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                                            <p className="text p-2 py-0" onClick={() => handleDeleteModal(row)}><i className="icon-Group-3994 me-1"></i>Delete</p>
                                        </>
                                        :
                                        <>
                                            <p className="text p-2 py-0" onClick={() => handleDeleteModal(row)}><i className="icon-Group-3994 me-1"></i>Delete</p>
                                            <p className="text  p-2 py-0" onClick={() => AddRequest(row)}><i className="icon-Group-482282 me-1"></i>View</p>
                                        </>
                                    }
                                </div>
                            </CustomUseRef>
                        }
                    </>
                );
            },
        }
    ];

    const handleDeleteModal = (row) => {
        setDelID(() => {
            if (row?.Type === "Time Adjustment") { return row?.CheckOutRequestID }
            else { return row?.ID }
        });
        setDelData(row);
        setIsOpenAlert(true);
        setRequestType(row?.Type);
    }

    const onCloseDelete = () => {
        setDelID(0);
        setIsOpenAlert(false);
        LoadData();
    }

    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        if (requestType === "Time Adjustment") {
            ConfirmDelete = <DeleteConfirmationAdjustment isOpenAlert={isOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeTimeAdjustment} ID={delID} data={delData} onClose={onClose} />
        }

        else {
            ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.REQUEST} ID={delID} data={delData} onClose={onClose} />
        }
    }

    const AddRequest = (row) => {
        SetRequestID(row?.ID);
        setIsRequestOpen(true);
        setStatus(row?.RequestStatus);
        setRequestType(row?.Type);
        if (row?.Type === 'Resign') {
            setResignData(row);
        }
    }
    const CloseRequestPopUp = () => {
        setIsRequestOpen(false);
        if (isRefresh) {
            LoadData();
            RequestCount();
        }
    }

    const LoadOnSave = () => {
        LoadData(searchParams);
        RequestCount(searchParams);
    }
    let popUpScreen = "";
    if (isRequestOpen) {
        if (requestType === "Time Adjustment") {
            popUpScreen = <AddTimeAdjustment isRequestOpen={isRequestOpen} ID={RequestID} onClose={CloseRequestPopUp} setIsRefresh={setIsRefresh} Status={status} view={"Admin"} onCloseSave={LoadOnSave} />
        }
        else if (requestType === "Leave") {
            popUpScreen = <AddNewLeave isLeaveOpen={isRequestOpen} ID={RequestID} onClose={CloseRequestPopUp} setIsRefresh={setIsRefresh} Status={status} view={"Admin"} onCloseSave={LoadOnSave} />
        }
        else if (requestType === 'Resign') {
            popUpScreen = <AddNewResign isOpenResign={isRequestOpen} onClose={CloseRequestPopUp} ID={RequestID} setIsRefresh={setIsRefresh} Status={status} Data={resignData} resignType={'Exist'} onCloseSave={LoadOnSave} />
        }
        else {
            popUpScreen = <AddNewRequest isOpenTask={isRequestOpen} ID={RequestID} onClose={CloseRequestPopUp} setIsRefresh={setIsRefresh} Status={status} view={"Admin"} onCloseSave={LoadOnSave} />
        }
    }

    useEffect(() => {
        setRequestsData(ColumnsData);
    }, [ColumnsData])
    return (
        <div className=' table-view'>
            {popUpScreen}
            {ConfirmDelete}
            <ReactDataGrid
                // enableVirtualization={true}
                columns={columns}
                rows={reqeustsData}
                rowHeight={30}
                className={"fill-grid DataGrid-Table hk-exp-table all_employee_grid"}
            />
        </div>
    )
}

export default TableView
