import React, { useEffect, useState } from "react";
import { NavItem, NavLink } from 'reactstrap'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ExpenseInnerLeftMenuContent from "./ExpenseInnerLeftMenuContent";
import ExpenseMenu from "./ExpenseMenu";

const ExpenseInnerLeftMenu = (props) => {

    const MenuItems = ExpenseMenu();
    const [subCategItems, setSubCategItems] = useState([])
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState()
    
    useEffect(() => {
        const selected_Item_List = MenuItems.filter((item) => item.key === props.subCategKey);
        if (selected_Item_List && selected_Item_List.length > 0) {
            let subCategories = selected_Item_List[0].subCategoryItems
            setSubCategItems(subCategories)
            setActiveTab(subCategories[0].key)
        }
    }, [props.subCategKey])

    const handleActiveTab = (item) => {
        const { key, name, right, component } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const activeTabElement = subCategItems.filter((item) => item.key === activeTab)
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
                component: activeTabElement.component,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
            component: component,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }
    return (
        <React.Fragment>
            <div className='h-100 tabs-container'>
                <div className='d-flex flex-column h-100'>
                    <Tab.Container defaultActiveKey="expenses">
                        <div className=' py-1'>
                            <div className='app-tabs'>
                                <div className='py-2 tabs-header position-relative mx-auto'>
                                    <div className="row h-100 justify-content-center align-items-center">
                                        <div className="tabs-v2-custom ar_tab-v2-custom">
                                            <Nav variant="tabs" className='tabs_view'>
                                                {subCategItems && subCategItems.map((item) => {
                                                    return (
                                                        <React.Fragment key={item.key}>
                                                            <NavItem onClick={() => handleActiveTab(item)}>
                                                                <NavLink href="#" className={activeTab === item.key ? "py-1 active" : "py-1"}>
                                                                    {item.name}
                                                                </NavLink>
                                                            </NavItem>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ExpenseInnerLeftMenuContent activeTab={activeTab} dynamicTabs={tabList} subCategItems={subCategItems} />
                    </Tab.Container>
                </div>
            </div>
        </React.Fragment>

    )
}

export default ExpenseInnerLeftMenu