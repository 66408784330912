import React from 'react'
const MainTop=(props)=>{
    const{openModal,LoadData,searchParam,SearchOnEnter,EnterParam,Change,excel,pdf}={...props}
    
    let AddModalButton=""
    if(openModal){AddModalButton= <button className='btn-icon-transparent-add' onClick={() => openModal(null)}></button>}
    let SearchField=""
    if(LoadData){
        SearchField=<div className="input">
                    <div className='input-group'>
                        <input type="text" className='form-control fc-xs' placeholder='Search by name or id…'
                                onChange={Change}
                                onKeyDown={SearchOnEnter}
                                value={searchParam}/>
                        <span className="input-group-search bg-orange br-clr-orange cursor-pointer" id="basic-addon2">
                            <p className='text text-light' onClick={() => LoadData(searchParam)}>Search</p>
                        </span>
                    </div>
                </div>
    }
    let ExcelButton=""
    if(excel){ExcelButton=<button className='btn-icon-transparent text-success'><i className="icon-excel"></i></button>}
    let pdfButton=""
    if(pdf){pdfButton=<button className='btn-icon-transparent text-success'><i className="icon-excel"></i></button>}
  return (
    
  <div className="container-fluid action-row br-clr pe-3">
     <div className="row justify-content-between">
        <div className="col-md-3 ps-0 search_field">
            {SearchField}
        </div>
        <div className="col-md-6">
            <div className='d-flex justify-content-end gap-1'>
                {AddModalButton}
                {pdfButton}
                {ExcelButton}
                {/* <button className='btn-icon-transparent'><i className="icon-delete"></i></button>
                <button className='btn-icon-transparent'><i className="icon-filter"></i></button> */}
                {/* <button className='btn-icon-transparent'><i className="icon-card-view"></i></button>
                <button className='btn-icon-transparent active'><i className="icon-table-view"></i></button> */}
            </div>
        </div>
    </div>
  </div>
  )
}

export default MainTop