import React, { useEffect, useState } from 'react'
import { Nav, Tab, Tabs } from "react-bootstrap";
import { ServerRequestProtected, ServerRequestProtectedParentID } from '../../../utils/ServerDataRequest';
import { useSelector } from 'react-redux';
import NotificationHandler from '../../../utils/NotificationHandler';
import { enumUtil } from '../../../utils/Enum';
import jwt_decode from 'jwt-decode';
import Pagination from '../../shared/Pagination/Pagination';
import AddTimeAdjustment from '../AddTimeAdjustment.js/AddTimeAdjustment';
import ApproveConfirmation from '../../shared/Approve/ApproveConfirmation';
import DenyConfirmation from '../../shared/Deny/DenyConfirmation';
import Loader from '../../shared/Loader/Loader';
import { DateFormat, DateFormat_dMy, MonthsDropdown } from "../../../utils/Constants"
import format from "date-fns/format";

const TimeAdjustmentAdmin = ({ Active, type, item, LoadData }) => {

    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let UserID = Active ? Active : "";
    const API_URL = process.env.REACT_APP_API
    const currentMonth = String(new Date().getMonth() + 1);
    let currentMonthNum = new Date().getMonth() + 1;
    let monthIndex = MonthsDropdown?.findIndex(it => it.value == currentMonth);
    let monthsDD = monthIndex !== -1 ? MonthsDropdown?.slice(0, monthIndex + 1) : MonthsDropdown;

    let TimeAdjustment_Modal = {
        Month: currentMonth,
        RequestAccess: "All",
        // UserID: UserID + "",
    }

    const [mainDropDownModal, setMainDropDownModal] = useState(TimeAdjustment_Modal);
    const [saveDropDownModal, setSaveDropDownModal] = useState({})
    const [timeAdjustmentData, setTimeAdjustmentData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isRequestOpen, setIsRequestOpen] = useState(false);
    const [timeAdjustmentID, setTimeAdjustmentID] = useState(0)
    const [approveID, setApproveID] = useState(null);
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [denyID, setDenyID] = useState(null);
    const [isOpenDeny, setIsOpenDeny] = useState(false)
    const [LeaveStatus, setLeaveStatus] = useState({})
    const [overAllRecord, setOverallRecord] = useState({});
    const [status, setStatus] = useState("")
    //For Pagination
    const [PerPage, setPerPage] = useState(10);
    const [PageNo, setPageNo] = useState(1);
    const [TotalPages, setTotalPages] = useState();

    const handlePageClick = (page) => { setPageNo(page); }
    const PreviousPage = () => { if (PageNo > 1) { setPageNo(PageNo - 1) } }
    const NextPage = () => { if (PageNo < TotalPages) setPageNo(PageNo + 1); }
    const handleFingByCreteria = (e) => {
        setPerPage(e.target.value)
        setPageNo(1)
    }
    ///////////


    const loadTimeAdjustmentRequests = (searchParam) => {
        setTimeAdjustmentData([]);
        setIsLoading(true);
        let joinStr = ""
        let saveModal = {
            TableName: enumUtil.enumAPITableName.EmployeeTimeAdjustment,
            "PerPage": PerPage,
            "PageNo": PageNo,
            // "ParentID": UserID,
            SearchCriteria: { ...mainDropDownModal },
        }
        ServerRequestProtectedParentID(API_URL + '/EmployeeTimeAdjustment', 'post', saveModal).then((response) => {
            // for (let i in response) {
            //     const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B']
            //     for (let j in jsonData) {
            //         joinStr += jsonData[j]
            //     }
            // }
            // const parsedJson = JSON.parse(joinStr);
            // console.log("parsedJson123AdminTime", parsedJson);
            setTimeAdjustmentData(response);
            setTotalPages(response[0].NumOfPages);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler("Failed to load Data", enumUtil.enumtoaster.error);
        })
    }

    const loadOverAllRequests = () => {
        setOverallRecord({});
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/RequestCount", "post", {
            "TableName": enumUtil.enumAPITableName.EmployeeTimeAdjustment,
            "SearchCriteria": { ...mainDropDownModal },
        }).then((response) => {
            setIsLoading(false);
            setOverallRecord(response[0]);
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })

    }

    useEffect(() => {
        loadTimeAdjustmentRequests();
        loadOverAllRequests()
    }, [mainDropDownModal.Month])

    let handleMonthChange = (e) => {
        const { name, value, selectedIndex } = e.target;

        let modal = { ...mainDropDownModal };
        let saveModal = { ...saveDropDownModal }
        const selectedOptionText = e.target.options[selectedIndex].text;
        modal[name] = value;

        setMainDropDownModal(modal)
        for (var property in TimeAdjustment_Modal) {
            if (name === property) {
                saveModal[name] = value

            }
        }
        setSaveDropDownModal(saveModal)
    }

    const AddNewRequest = (data) => {
        setTimeAdjustmentID(data?.CheckOutRequestID);
        setIsRequestOpen(true);
        setStatus(data?.CheckOutRequestStatus)
    }

    const CloseRequestPopUp = () => {
        setIsRequestOpen(false);
    }
    const LoadOnSave = () => {
        loadTimeAdjustmentRequests();
        loadOverAllRequests()
    }

    let popUpScreen = "";
    if (isRequestOpen) {
        popUpScreen = <AddTimeAdjustment isRequestOpen={isRequestOpen} ID={timeAdjustmentID} onClose={CloseRequestPopUp} setIsRefresh={setIsRefresh} view={"Admin"} Active={Active} Status={status} onCloseSave={LoadOnSave} />
    }

    const handleApproveModal = (ID, item) => {
        // const formatedDate =  item?.RequestedDate.split("/");
        let formatedDate = item?.RequestedDate.split("/").reverse().join('/')
        console.log("DAY?MONTH?YEAR", item?.RequestedDate.split("/").reverse().join('/'));
        setApproveID(ID);
        setIsOpenApprove(true);
        setLeaveStatus(
            {
                "AttandanceID": String(item.AttandanceID),
                "CheckOutRequestID": item.CheckOutRequestID + "",
                "CheckInRequestID": item.CheckInRequestID + "",
                "Message": item.Message,
                "RequestStatus": "Approved",
                "RequestedDate": formatedDate,
                "CheckInTime": item.CheckInTime,
                "CheckOutTime": item.CheckOutTime,
                // "RequestStatus": "Approved",
                "EmployeeID": String(UserID),
            }
        );
    }

    const onCloseApprove = () => {
        setApproveID(0);
        setIsOpenApprove(false)
        loadTimeAdjustmentRequests()
        loadOverAllRequests()
        if (type === 'Dashboard') {
            LoadData();
        }
    }

    const onClose = () => {
        setIsOpenApprove(false)
    }

    let ApprovePopup = "";
    if (isOpenApprove) {
        ApprovePopup = <ApproveConfirmation isOpenAlert={isOpenApprove} onCloseDelete={onCloseApprove} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeTimeAdjustment} ID={approveID} LeaveStatus={LeaveStatus} onClose={onClose} LoadData={() => { }} />
    }

    const handleDenyModal = (ID, item) => {
        setDenyID(ID);
        setIsOpenDeny(true);
        let formatedDate = item?.RequestedDate.split("/").reverse().join('/')
        setLeaveStatus(
            {
                "AttandanceID": String(item.AttandanceID),
                "CheckOutRequestID": item.CheckOutRequestID + "",
                "CheckInRequestID": item.CheckInRequestID + "",
                "Message": item.Message,
                "RequestStatus": "Dennied",
                "RequestedDate": formatedDate,
                "CheckInTime": item.CheckInTime,
                "CheckOutTime": item.CheckOutTime,
                // "RequestStatus": "Dennied",
                "EmployeeID": String(UserID),
            }
        );
    }

    const onCloseDeny = () => {
        setDenyID(0);
        setIsOpenDeny(false)
        loadTimeAdjustmentRequests()
        loadOverAllRequests()
        if (type === 'Dashboard') {
            LoadData();
        }
        // loadOverAllLeaves();
    }

    const onDenyClose = () => {
        setIsOpenDeny(false)
    }

    let DenyPopup = "";
    if (isOpenDeny) {
        DenyPopup = <DenyConfirmation isOpenAlert={isOpenDeny} onCloseDelete={onCloseDeny} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeTimeAdjustment} ID={denyID} LeaveStatus={LeaveStatus} onClose={onDenyClose} LoadData={() => { }} />
    }

    // let timeAdjustmentData = timeAdjustmentData && timeAdjustmentData.flatMap((item) => {

    //     return item?.Attendance || []

    // })
    // console.log("FLATTENARRAY", timeAdjustmentData)

    return (
        <>
            <div>
                {popUpScreen}
                {ApprovePopup}
                {DenyPopup}
                {isLoading ? <Loader /> : <></>}
                {type === "Dashboard" ?
                    <>
                        <p className='deny text fs-11 font-m text-center cursor-pointer' onClick={() => handleDenyModal(item.ID, item)}>Deny</p>
                        <p className='approve text fs-11 font-m text-center cursor-pointer' onClick={() => handleApproveModal(item.ID, item)}>Approve</p>
                    </>
                    :
                    <>
                        <div className="leave_req_screen">
                            <div className="d-flex align-items-center justify-content-between me-12">
                                <p className="heading sm leave_heading">Time Adjustment  Requests</p>
                                <div className="d-flex gap-2">
                                    <div className="left_border all"></div>
                                    <div>
                                        <p className="heading">Total Request</p>
                                        <p className="heading sm">{overAllRecord?.TotalRequest || 0}</p>
                                    </div>
                                    <div className="left_border new"></div>
                                    <div>
                                        <p className="heading"> New Request</p>
                                        <p className="heading sm">{overAllRecord?.New || 0}</p>
                                    </div>
                                    <div className="left_border pending"></div>
                                    <div>
                                        <p className="heading">Pending  </p>
                                        <p className="heading sm">{overAllRecord?.Pending || 0}</p>
                                    </div>
                                    <div className="left_border approved"></div>
                                    <div>
                                        <p className="heading">Approved  </p>
                                        <p className="heading sm">{overAllRecord?.Approved || 0}</p>
                                    </div>
                                    <div className="left_border denied"></div>
                                    <div>
                                        <p className="heading">Denied  </p>
                                        <p className="heading sm">{overAllRecord?.Dennied || 0}</p>
                                    </div>
                                </div>
                            </div>
                            <Tabs className="rb-tabs bg-light-blue">
                                <Tab eventKey="timeAdjustment" title="">
                                    <Tab.Container defaultActiveKey="allreqa">
                                        <Nav variant="tabs" className="rb-tabs-v3 ps-2 tabs_view mt-1 gap-4 tabs_view2 border-end-0">
                                            <Nav.Item>
                                                <Nav.Link eventKey="allreqa">All Requests  </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="newRequests">  New Requests</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Pending">Pending</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Approved">Approved</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Dennied">Denied</Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                        <Tab.Content>

                                            <Tab.Pane eventKey="allreqa">
                                                <>
                                                    <>
                                                        {/* {popUpScreen}
                                                        {ApprovePopup}
                                                        {DenyPopup} */}

                                                        <div className="all_req px-2 my-2 d-flex align-items-center justify-content-between dropdown-bgc-position date-slect-bg me-12 pe-0">
                                                            <p className="heading sm">Request Approval <span className="text fs-11"><i className="icon-Vector-4 me-1"></i>All Requests</span></p>
                                                            <div className="input">
                                                                <select className="form-control fc-xs" id="Month" name="Month" value={mainDropDownModal.Month} onChange={(e) => handleMonthChange(e)}>
                                                                    {monthsDD.map((item) => {
                                                                        return (
                                                                            <option value={item.value} >{item.display}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="tim-adj-req_content">
                                                            <div className="row m-0 px-2 row-gap-10">
                                                                {timeAdjustmentData && timeAdjustmentData.map((item) => {

                                                                    return (
                                                                        <>
                                                                            <div className="col-md-3 ps-0">
                                                                                <div className="card">
                                                                                    <div className=" d-flex align-items-center justify-content-between">
                                                                                        <div className="d-flex gap-1 align-items-center">
                                                                                            <div class="img-container s-35 circle">
                                                                                                <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <p className="heading xsm clr_gry">{item?.RequestedBy}</p>
                                                                                                <p className="text xsm clr_gry">1011</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="heading xsm clr_gry ">Applied on</p>
                                                                                            <p className="text xsm clr_gry">{item?.AppliedOn}</p>
                                                                                            <p className="text xsm clr_gry">{"(" + item?.CheckOutRequestStatus + ")"}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="text xsm mt-2"><span className="start_times">{item?.CheckInTime}</span> to <span className="end_times">{item?.CheckOutTime} </span> on {item?.RequestedDate}</p>
                                                                                    <div className="content">
                                                                                        <p className="text xsm clr_gry mt-1 validation_message" title={item?.Message}>{item?.Message}</p>
                                                                                    </div>
                                                                                    <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                                        {/* {item?.CheckOutRequestStatus === "Pending" && <>
                                                                                            <p class="heading xsm deny cursor-pointer" onClick={() => handleDenyModal(item?.CheckOutRequestID, item)}>Deny</p>
                                                                                            <p class="heading xsm approve cursor-pointer" onClick={() => handleApproveModal(item?.CheckOutRequestID, item)}>Approve</p>
                                                                                        </>} */}
                                                                                        {item?.CheckOutRequestStatus === "Pending" ?
                                                                                            <>
                                                                                                <p className="heading xsm deny cursor-pointer" onClick={() => handleDenyModal(item?.CheckOutRequestID, item)}>Deny</p>
                                                                                                <p className="heading xsm approve cursor-pointer" onClick={() => handleApproveModal(item?.CheckOutRequestID, item)}>Approve</p>

                                                                                            </>
                                                                                            :
                                                                                            item?.CheckOutRequestStatus === "Approved" ? <p className="heading bg-white xsm approve">Approved</p>
                                                                                                :
                                                                                                item?.CheckOutRequestStatus === 'Dennied' ? <p className="heading bg-white xsm deny">Denied</p>
                                                                                                    :
                                                                                                    <></>}
                                                                                        <p class="heading xsm detail cursor-pointer py-1" onClick={() => AddNewRequest(item)}>Detail</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )

                                                                })}
                                                                {/* <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="new_req px-2 my-2">
                                                    <p className="heading sm">Leave Approval <span className="text xsm"><i className="icon-Vector-4 me-1"></i>New Requests </span></p>
                                                </div>
                                                <div className="row m-0 px-2 row-gap-10">
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pend px-2 my-2">
                                                    <p className="heading sm">Leave Approval <span className="text xsm"><i className="icon-Vector-4 me-1"></i>Pending </span></p>
                                                </div>
                                                <div className="row m-0 px-2 row-gap-10">
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="app px-2 my-2">
                                                    <p className="heading sm">Leave Approval <span className="text xsm"><i className="icon-Vector-4 me-1"></i>Approved </span></p>
                                                </div>
                                                <div className="row m-0 px-2 row-gap-10">
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="den px-2 my-2">
                                                    <p className="heading sm">Leave Approval <span className="text xsm"><i className="icon-Vector-4 me-1"></i>Denied </span></p>
                                                </div>
                                                <div className="row m-0 px-2 row-gap-10">
                                                    <div className="col-md-4 ps-0">
                                                        <div className="card">
                                                            <div className=" d-flex align-items-center justify-content-between">
                                                                <div className="d-flex gap-1 align-items-center">
                                                                    <div class="img-container s-35 circle">
                                                                        <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <p className="heading xsm clr_gry">Israh Abbasi</p>
                                                                        <p className="text xsm clr_gry">1011</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p className="heading xsm clr_gry ">Applied on</p>
                                                                    <p className="text xsm clr_gry">2/27/2024</p>
                                                                </div>
                                                            </div>
                                                            <p className="text xsm mt-2"><span className="start_times">10:30 AM </span>on 2/26/2024 to <span className="end_times">6:30 PM </span>on 2/26/2024</p>
                                                            <p className="text content xsm clr_gry mt-1">Notes that Notes that u added while applying for leave request requ.. u added while applying for leave request requ</p>
                                                            <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                <p class="heading xsm deny">Deny</p>
                                                                <p class="heading xsm approve">Approve</p>
                                                                <p class="heading xsm detail">Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                        {/* <div>
                                                    <Pagination
                                                        PerPage={PerPage}
                                                        PageNo={PageNo}
                                                        TotalPages={TotalPages}
                                                        handlePageClick={handlePageClick}
                                                        PreviousPage={PreviousPage}
                                                        handleFingByCreteria={handleFingByCreteria}
                                                        NextPage={NextPage}
                                                    />
                                                </div> */}
                                                    </>
                                                </>

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="newRequests">
                                                <>
                                                    <div className="new_req px-2 my-2 d-flex align-items-center justify-content-between dropdown-bgc-position date-slect-bg">
                                                        <p className="heading sm">Request Approval <span className="text fs-11"><i className="icon-Vector-4 me-1"></i>New Requests </span></p>
                                                        <div className="input">
                                                            <select className="form-control fc-xs" id="Month" name="Month" value={mainDropDownModal.Month} onChange={(e) => handleMonthChange(e)}>
                                                                {monthsDD.map((item) => {
                                                                    return (
                                                                        <option value={item.value} >{item.display}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="tim-adj-req_content">
                                                        <div className="row m-0 px-2 row-gap-10 ">
                                                            {timeAdjustmentData && timeAdjustmentData.filter((item) => {
                                                                return item?.NewRequest === "True";
                                                            }).map((item) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-md-3 ps-0">
                                                                            <div className="card">
                                                                                <div className=" d-flex align-items-center justify-content-between">
                                                                                    <div className="d-flex gap-1 align-items-center">
                                                                                        <div class="img-container s-35 circle">
                                                                                            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="heading xsm clr_gry">{item?.RequestedBy}</p>
                                                                                            <p className="text xsm clr_gry">1011</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="heading xsm clr_gry ">Applied on</p>
                                                                                        <p className="text xsm clr_gry">{item?.AppliedOn}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text xsm mt-2"><span className="start_times">{item?.CheckInTime}</span> to <span className="end_times">{item?.CheckOutTime} </span> on {item?.RequestedDate}</p>
                                                                                <div className='content'>
                                                                                    <p className="text xsm clr_gry mt-1">{item?.Message}</p>
                                                                                </div>
                                                                                <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                                    {item?.CheckOutRequestStatus === "Pending" && <>
                                                                                        <p class="heading xsm deny cursor-pointer" onClick={() => handleDenyModal(item?.CheckOutRequestID, item)}>Deny</p>
                                                                                        <p class="heading xsm approve cursor-pointer" onClick={() => handleApproveModal(item?.CheckOutRequestID, item)}>Approve</p>
                                                                                    </>}
                                                                                    <p class="heading xsm detail cursor-pointer" onClick={() => AddNewRequest(item)}>Detail</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Pending">
                                                <>
                                                    <div className="pend px-2 my-2 d-flex align-items-center justify-content-between dropdown-bgc-position date-slect-bg">
                                                        <p className="heading sm">Request Approval <span className="text fs-11"><i className="icon-Vector-4 me-1"></i>Pending </span></p>
                                                        <div className="input">
                                                            <select className="form-control fc-xs" id="Month" name="Month" value={mainDropDownModal.Month} onChange={(e) => handleMonthChange(e)}>
                                                                {monthsDD.map((item) => {
                                                                    return (
                                                                        <option value={item.value} >{item.display}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="tim-adj-req_content">
                                                        <div className="row m-0 px-2 row-gap-10">
                                                            {timeAdjustmentData && timeAdjustmentData.filter((item) => {
                                                                return item?.CheckOutRequestStatus === "Pending";
                                                            }).map((item) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-md-3 ps-0">
                                                                            <div className="card">
                                                                                <div className=" d-flex align-items-center justify-content-between">
                                                                                    <div className="d-flex gap-1 align-items-center">
                                                                                        <div class="img-container s-35 circle">
                                                                                            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="heading xsm clr_gry">{item?.RequestedBy}</p>
                                                                                            <p className="text xsm clr_gry">1011</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="heading xsm clr_gry ">Applied on</p>
                                                                                        <p className="text xsm clr_gry">{item?.AppliedOn}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text xsm mt-2"><span className="start_times">{item?.CheckInTime}</span>on {item?.RequestedDate} to <span className="end_times">{item?.CheckOutTime} </span>on {item?.RequestedDate}</p>
                                                                                <div className='content'>
                                                                                    <p className="text xsm clr_gry mt-1">{item?.Message}</p>
                                                                                </div>
                                                                                <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                                    {item?.CheckOutRequestStatus === "Pending" && <>
                                                                                        <p class="heading xsm deny cursor-pointer" onClick={() => handleDenyModal(item?.CheckOutRequestID, item)}>Deny</p>
                                                                                        <p class="heading xsm approve cursor-pointer" onClick={() => handleApproveModal(item?.CheckOutRequestID, item)}>Approve</p>
                                                                                    </>}
                                                                                    <p class="heading xsm detail cursor-pointer" onClick={() => AddNewRequest(item)}>Detail</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Dennied">
                                                <>
                                                    <div className="den px-2 my-2 d-flex align-items-center justify-content-between dropdown-bgc-position date-slect-bg">
                                                        <p className="heading sm">Request Approval <span className="text fs-11"><i className="icon-Vector-4 me-1"></i>Dennied </span></p>
                                                        <div className="input">
                                                            <select className="form-control fc-xs" id="Month" name="Month" value={mainDropDownModal.Month} onChange={(e) => handleMonthChange(e)}>
                                                                {monthsDD.map((item) => {
                                                                    return (
                                                                        <option value={item.value} >{item.display}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="tim-adj-req_content">
                                                        <div className="row m-0 px-2 row-gap-10">
                                                            {timeAdjustmentData && timeAdjustmentData.filter((item) => {
                                                                return item?.CheckOutRequestStatus === "Dennied";
                                                            }).map((item) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-md-3 ps-0">
                                                                            <div className="card">
                                                                                <div className=" d-flex align-items-center justify-content-between">
                                                                                    <div className="d-flex gap-1 align-items-center">
                                                                                        <div class="img-container s-35 circle">
                                                                                            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="heading xsm clr_gry">{item?.RequestedBy}</p>
                                                                                            <p className="text xsm clr_gry">1011</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="heading xsm clr_gry ">Applied on</p>
                                                                                        <p className="text xsm clr_gry">{item?.AppliedOn}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text xsm mt-2"><span className="start_times">{item?.CheckInTime}</span>on {item?.RequestedDate} to <span className="end_times">{item?.CheckOutTime} </span>on {item?.RequestedDate}</p>
                                                                                <div className='content'>
                                                                                    <p className="text xsm clr_gry mt-1">{item?.Message}</p>
                                                                                </div>
                                                                                <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                                    {/* {item?.CheckOutRequestStatus === "Pending" && <>
                                                                            <p class="heading xsm deny cursor-pointer" onClick={() => handleDenyModal(item?.CheckOutRequestID, item)}>Deny</p>
                                                                            <p class="heading xsm approve cursor-pointer" onClick={() => handleApproveModal(item?.CheckOutRequestID, item)}>Approve</p>
                                                                        </>} */}
                                                                                    <p className="heading bg-white xsm deny">Denied</p>
                                                                                    <p class="heading xsm detail cursor-pointer" onClick={() => AddNewRequest(item)}>Detail</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="Approved">
                                                <>
                                                    <div className="app px-2 my-2 d-flex align-items-center justify-content-between dropdown-bgc-position date-slect-bg">
                                                        <p className="heading sm">Request Approval <span className="text fs-11"><i className="icon-Vector-4 me-1"></i>Approved </span></p>
                                                        <div className="input">
                                                            <select className="form-control fc-xs" id="Month" name="Month" value={mainDropDownModal.Month} onChange={(e) => handleMonthChange(e)}>
                                                                {monthsDD.map((item) => {
                                                                    return (
                                                                        <option value={item.value} >{item.display}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="tim-adj-req_content">
                                                        <div className="row m-0 px-2 row-gap-10">
                                                            {timeAdjustmentData && timeAdjustmentData.filter((item) => {
                                                                return item?.CheckOutRequestStatus === "Approved";
                                                            }).map((item) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-md-4 ps-0">
                                                                            <div className="card">
                                                                                <div className=" d-flex align-items-center justify-content-between">
                                                                                    <div className="d-flex gap-1 align-items-center">
                                                                                        <div class="img-container s-35 circle">
                                                                                            <img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="heading xsm clr_gry">{item?.RequestedBy}</p>
                                                                                            <p className="text xsm clr_gry">1011</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="heading xsm clr_gry ">Applied on</p>
                                                                                        <p className="text xsm clr_gry">{item?.AppliedOn}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text xsm mt-2"><span className="start_times">{item?.CheckInTime}</span>on {item?.RequestedDate} to <span className="end_times">{item?.CheckOutTime} </span>on {item?.RequestedDate}</p>
                                                                                <div className='content'>
                                                                                    <p className="text validation_message xsm clr_gry mt-1">{item?.Message}</p>
                                                                                </div>
                                                                                <div class="d-flex mt-2 align-items-center justify-content-end gap-2">
                                                                                    {/* {item?.CheckOutRequestStatus === "Pending" && <>
                                                                            <p class="heading xsm deny cursor-pointer" onClick={() => handleDenyModal(item?.CheckOutRequestID, item)}>Deny</p>
                                                                            <p class="heading xsm approve cursor-pointer" onClick={() => handleApproveModal(item?.CheckOutRequestID, item)}>Approve</p>
                                                                        </>} */}
                                                                                    <p className="heading bg-white xsm approve">Approved</p>
                                                                                    <p class="heading xsm detail cursor-pointer" onClick={() => AddNewRequest(item)}>Detail</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Tab>
                            </Tabs>
                        </div>
                    </>}
            </div>
        </>
    )
}

export default TimeAdjustmentAdmin
