// import BasicInformation from "../Account/BasicInformation/BasicInformation"
import Academics from "../../MyInfo/Account/Academics/Academics"
import Certification from "../Account/Certification/Certification"
import Office from "../Account/Education/Education"
// import EmployeeStockReference from "../Account/EmployeeStockReference/EmployeeStockReference"
import Experience from "../Account/Experience/Experience"
// import JoiningDetailsU from "../Account/JoiningDetails/JoiningDetails"
import Skills from "../Account/Skill/Skills"
import Attendance from "../Account/Attendance/Attendance"
// import EmployeeDocuments from "../Account/Documnets/Documents"
import Leave from "../Account/LeavesEmp/Leave"
import BasicInformation from "../../MyInfo/Account/BasicInformation/BasicInformation"
import JoiningDetails from "../../MyInfo/Account/JoiningDetails/JoiningDetails"
import EmployeeStockReference from "../../MyInfo/Account/EmployeeStockReference/EmployeeStockReference"
import EmployeeDocuments from "../../MyInfo/Account/Documnets/Documents"
import CareerGrowth from "../../MyInfo/Account/CareerGrowth/CareerGrowth"
import DailyTracking from "../../DailyTracking/DailyTracking"

const EmployeeMenu = () => {

    return [
        {
            name: '',
            key: 21,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Basic Information',
                    key: 211,
                    icon: "",
                    component: <BasicInformation type={"Employee"} />,
                    right: true,
                },
                {
                    name: 'Academics',
                    key: 212,
                    icon: "",
                    component: <Academics type={"Employee"} />,
                    right: true,
                },
                // {
                //     name: 'Joining Details',
                //     key: 215,
                //     icon: "",
                //     component: <JoiningDetails type={"Employee"}/>,
                //     right: true,
                // },
                {
                    name: 'Allocated Devices',
                    key: 217,
                    icon: "",
                    component: <EmployeeStockReference type={"Employee"}/>,
                    right: true,
                },
                {
                    name: 'Documents',
                    key: 218,
                    icon: "",
                    component: <EmployeeDocuments type={"Employee"} />,
                    right: true,
                },
                {
                    name: 'Career Growth ',
                    key: 215,
                    icon: "",
                    component: <CareerGrowth type={"Employee"}/>,
                    right: true,
                },
                {
                    name: 'Daily Tracking',
                    key: 216,
                    icon: "",
                    component: <DailyTracking type={"Employee"}/>,
                    right: true,
                },
                
                // {
                //     name: 'Attendance',
                //     key: 219,
                //     icon: "",
                //     component: <Attendance/>,
                //     right: true,
                // },
                // {
                //     name: 'Leaves',
                //     key: 221,
                //     icon: "",
                //     component: <Leave/>,
                //     right: true,
                // },
                // {
                //     name: 'Certification',
                //     key: 213,
                //     icon: "",
                //     component: <Certification />,
                //     right: true,
                // },
                // {
                //     name: 'Education',
                //     key: 214,
                //     icon: "",
                //     //component: <Office />,
                //     right: true,
                // },
               
                // {
                //     name: 'Skills',
                //     key: 216,
                //     icon: "",
                //     //component: <Skills/>,
                //     right: true,
                // },
               
                
               
            ]
        },
    ]
}


export default EmployeeMenu