import React, { useEffect, useState } from 'react'
import ModalFooter from '../../../shared/ModalFooter/ModalFooter'
import Modal from 'react-bootstrap/Modal'
import close from "../../../../assetsOld/img/x-close.svg"
import CalendarInput from '../../../../utils/CalendarInput';
import { enumUtil } from '../../../../utils/Enum';
import { ServerRequestProtected, ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import isNull from '../../../../utils/NullChecking';
import NotificationHandler from '../../../../utils/NotificationHandler';
import FormValidator from '../../../../utils/FormValidator';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { DateFormat, DateFormat_YMd, DateFormat_YMd_String, DateFormat_YMd_Strings, DateFormat_dMy } from "../../../../utils/Constants"
import jwt_decode from 'jwt-decode';
import Loader from '../../../shared/Loader/Loader';
import ModalFooterAdmin from '../../../shared/ModalFooterAdmin/ModalFooterAdmin';
import handleNumericCheck from '../../../../utils/HandleNumericKeys';
import ApproveConfirmation from '../../../shared/Approve/ApproveConfirmation';
import DenyConfirmation from '../../../shared/Deny/DenyConfirmation';
import moment from 'moment';
import DatePicker from '../../../../utils/DatePicker';

const AddNewResign = (props) => {

    const { isOpenResign, onClose, onCloseSave, ID, setIsRefresh, type, Data, resignType} = { ...props }

    let token = useSelector(state => state.TokenReducer);
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let Token = token && jwt_decode(token)
    let minDate = type && type === "Employee" ? {} : {minDate: new Date()};
    let EmployeeID = Token.UserID;
    let UserID = null;
    if (!isNull(type) && type === "Employee") {
        UserID = activeEmployee + "";
    }
    // else {
    //     UserID = Token.UserID + "";
    // }
    // let OrgID = useSelector(state => state.Organization)

    let RESIGN_Modal = {
        EmployeeID: "",
        EmployeeName: "",
        DesignationName: "",
        JoiningDate: "",
        CurrentStatus: "",
        LastDate: null,
        NoticePeriod: "",
        RequestTo: "",
        Reason: "",
        RequestStatus: "",
        ResignationType: "",
    }

    let ValidationModal = {
        NoticePeriod: "",
        LastDate: "",
        RequestTo: "",
        ResignationType: "",
    }
    let valModal = {}

    const [ResignID, setResignID] = useState(0);
    const [SaveResignModal, setSaveResignModal] = useState({});
    const [mainResignModal, setmainResginModal] = useState(RESIGN_Modal);
    const [ResignValidationModal, setResignValidationModal] = useState(ValidationModal)
    const [isLoading, setIsLoading] = useState(false);
    const [del, setDel] = useState(false);
    const [position, setPosition] = useState(null);
    const [dateDisabled, setDateDisabled] = useState(false);
    const API_URL = process.env.REACT_APP_API;

    const [request, setRequest] = useState()
    const [approveID, setApproveID] = useState(0);
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [denyID, setDenyID] = useState(null);
    const [isOpenDeny, setIsOpenDeny] = useState(false)
    const [LeaveStatus, setLeaveStatus] = useState({})
    const [dateOfApproval, setDateOfApproval] = useState();
    const [LastEmploymentDate, setLastEmploymentDate] = useState();
    const [dateOfJoining, setDateOfJoining] = useState();
    const [ReportingToDropDown, setReportingToDropDownData] = useState([]);
    const openApprovedLastDate = Boolean(dateOfApproval)
    const openLastDateOfEmployement = Boolean(LastEmploymentDate)
    const openDateOfJoining = Boolean(dateOfJoining)

    const closeCalendarMenuResign = () => {
        setDateOfApproval(null)
        setLastEmploymentDate(null)
        setDateOfJoining(null)
    };
    const openCalendarMenuJoining = (event, type) => {
        if (type === 'ApprovedLastDate') { setDateOfApproval(event.currentTarget) }
        else if (type === 'LastDate') { setLastEmploymentDate(event.currentTarget) }
        else if (type === 'JoiningDate') { setDateOfJoining(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    const handleResignChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainResignModal };
        let saveModal = { ...SaveResignModal }

        if (name === 'NoticePeriod') {
            modal['LastDate'] = addDaysToCurrentDate(value)?.DateForMainModal;
        }
            modal[name] = value;
        

        setmainResginModal(modal);
        for (var property in RESIGN_Modal) {
            if (name === property) {
                if (name === 'NoticePeriod') {
                    saveModal['LastDate'] = addDaysToCurrentDate(value)?.DateForSaveModal;
                }
                saveModal[name] = value
            }
        }
        setSaveResignModal(saveModal)
    }

    const ResignValidation = async () => {

        let ValidationModal = {
            // RequestTo: FormValidator(
            //     mainResignModal.RequestTo,
            //     enumUtil.enumValidationType.Required,
            //     'Request To',
            // ),

            LastDate: FormValidator(
                mainResignModal.LastDate,
                enumUtil.enumValidationType.Required,
                'Last Date Of Employement',
            ),

            NoticePeriod: FormValidator(
                mainResignModal.NoticePeriod,
                enumUtil.enumValidationType.Required,
                'Notice Period',
            ),
            ResignationType: FormValidator(
                mainResignModal.ResignationType,
                enumUtil.enumValidationType.Required,
                'Resignation Type',
            ),

        }
        await setResignValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveResignation = async (e) => {
        await ResignValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            // SaveResignModal["OrganizationID"] = OrgID
            // SaveResignModal["EmployeeID"] = UserID
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Resignation,
                ID: ResignID,
                // UserID: UserID,
                data: { ...SaveResignModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setResignID(response.id)
                    setIsRefresh(true)
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }

    const addDaysToCurrentDate = (daysToAdd) => {
        // Get the current date
        const currentDate = moment();

        // Add the specified number of days
        const newDate = currentDate.add(daysToAdd, 'days');

        // Return the new date in a preferred format
        return {
            DateForMainModal: newDate.format('MM/DD/YYYY'),
            DateForSaveModal: newDate.format('YYYY/MM/DD'),
        }; // Change format as needed
    };

    const handleCalendarChange = (date, name) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, 'MM/dd/yyyy')
        let modal = { ...mainResignModal };
        let saveModal = { ...SaveResignModal };
        modal[name] = FormatedDateShow;
        setmainResginModal(modal)
        for (var property in RESIGN_Modal) {
            if (name === property) {
                saveModal[name] = FormatedDateSave
            }
        }
        setSaveResignModal(saveModal)
        closeCalendarMenuResign()
    }

    const handleApproveModal = (ID) => {
        // setApproveID(ID);
        setIsOpenApprove(true);
        setLeaveStatus({
            ResignationStatus: "Approved",
            EmployeeID: Data?.EmployeeID + '',
            ResignationType: mainResignModal?.ResignationType,
        });
    }

    const onCloseApprove = () => {
        setApproveID(0);
        setRequest("Approved")
        setIsOpenApprove(false)
        setIsRefresh(true)
    }

    const onApprovalClose = () => {
        setIsOpenApprove(false)
    }

    let ApprovePopup = "";
    if (isOpenApprove) {
        ApprovePopup = <ApproveConfirmation isOpenAlert={isOpenApprove} onCloseDelete={onCloseApprove} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Resignation} ID={approveID} LeaveStatus={LeaveStatus} onClose={onApprovalClose} LoadData={onCloseSave} />
    }

    const handleDenyModal = (ID) => {
        // setDenyID(ID);
        setIsOpenDeny(true);
        setLeaveStatus({
            ResignationStatus: "Dennied",
            EmployeeID: Data?.EmployeeID + '',
            ResignationType: mainResignModal?.ResignationType,

        });
    }

    const onCloseDeny = () => {
        setDenyID(0);
        setRequest("Dennied")
        setIsOpenDeny(false)
        setIsRefresh(true)
    }

    const onDenyClose = () => {
        setIsOpenDeny(false)
    }

    let DenyPopup = "";
    if (isOpenDeny) {
        DenyPopup = <DenyConfirmation isOpenAlert={isOpenDeny} onCloseDelete={onCloseDeny} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Resignation} ID={denyID} LeaveStatus={LeaveStatus} onClose={onDenyClose} LoadData={onCloseSave} />
    }

    useEffect(() => {
        let mainModal = { ...mainResignModal }
        let saveModal = { ...SaveResignModal }
        mainModal['EmployeeID'] = Data?.EmployeeID;
        mainModal['EmployeeName'] = Data?.FirstName + ' ' + Data?.LastName;
        mainModal['CurrentStatus'] = Data?.JobStatus;
        mainModal['JoiningDate'] = Data?.DateOfJoining;
        mainModal['DesignationName'] = Data?.DesignationName;
        saveModal['DesignationID'] = Data?.DesignationID + '';
        saveModal['EmployeeID'] = Data?.EmployeeID + '';
        saveModal['CurrentStatus'] = Data?.JobStatus;
        saveModal['JoiningDate'] = DateFormat_YMd_Strings(Data?.DateOfJoining);
        if (!type) {
            mainModal['ResignationType'] = 'Resign'
            saveModal['ResignationType'] = 'Resign'
        }
        setmainResginModal(mainModal);
        setSaveResignModal(saveModal);
        setIsLoading(false)
        if (!isNull(ID)) {
            setIsLoading(true);
            setResignID(ID);
            setApproveID(ID);
            setDenyID(ID);
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Resignation,
            }).then((response) => {
                // const [year, month, day] = response?.Date.split("-");
                // response = {...response, CreatedDate: format(new Date(year, month - 1, day), DateFormat_dMy)}
                setIsLoading(false)
                setmainResginModal(response)
            }).catch((error) => {
                setIsLoading(false)
            })
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Resignation,
        }).then((response) => {
            setReportingToDropDownData(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);    
        })
    }, [ID])
    const handleCopy = (e) => {
        e.preventDefault();
    };


    return (
        <Modal show={isOpenResign} onHide={isOpenResign} backdrop="static" keyboard={false} centered dialogClassName={`delete-modal hk-modal-v1 ems_modal ar_resignation_popup`}>
            {/* <div className="">
        <p className="heading fs-13 pb-2">Please select month</p>
        <div class="input">
            <select class="form-control" id="PaymentType" name="PaymentType">
                <option value="1">September</option>
                <option value="2">September</option>
                <option value="2">September</option>
            </select>
        </div>
        <div>

        </div>
    </div> */}
            {/* Resignation */}
            <Modal.Body>
                {isLoading ? <Loader /> : <></>}
                {ApprovePopup}
                {DenyPopup}
                <div className='d-flex justify-content-end'>
                    <img className="close cursor-pointer" src={close} onClick={(e) => onClose(e)}></img>
                </div>
                <div className="modal_header">
                    <div className="text-center">
                        <span className="icon">
                            <div className="icon_bg">
                                <i className="icon-check fs-20 fw-bolder clr_drk-blue"></i>
                            </div>
                        </span>
                        <p class="heading mt-3 mb-1">Apply for Resignation</p>
                    </div>
                </div>
                <div className='d-flex flex-column gap-15'>
                    <div className='mt-3'>
                        <div className='d-flex gap-25 align-items-center'>
                            <div className='d-flex flex-column gap-2'>
                                <p className='text fs-11 clr_gry'>Employee ID:</p>
                                <p className='text fs-11 clr_gry'>Employee Name:</p>
                                <p className='text fs-11 clr_gry'>Designation:</p>
                                <p className='text fs-11 clr_gry'>Current Status:</p>
                                <p className='text fs-11 clr_gry'>Joining Date:</p>
                            </div>
                            <div className='d-flex flex-column gap-2'>
                                <p className='text font-m'>{mainResignModal?.EmployeeID}</p>
                                <p className='text font-m'>{mainResignModal?.EmployeeName}</p>
                                <p className='text font-m office_name_truncate'>{mainResignModal?.DesignationName}</p>
                                <p className='text font-m'>{mainResignModal?.CurrentStatus}</p>
                                <p className='text font-m'>{mainResignModal?.JoiningDate}</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center gap-25 mb-2'>
                        </div>
                        <div className='d-flex align-items-center gap-25 mb-2'>
                        </div>
                    </div>

                    <div className={`d-flex flex-column gap-15 ${resignType === "New" ? '' : resignType === "Exist" ? 'ar_disable-popup' : ""}`}>
                        {/* <div className="form-group input">
                            <label>Request To</label>
                            <input type="text"
                                className="form-control"
                                name="RequestTo"
                                id="RequestTo"
                                value={mainResignModal?.RequestTo}
                                onChange={handleResignChange}
                            />
                            <select
                                className="form-control"
                                name="RequestTo"
                                id="RequestTo"
                                value={mainResignModal?.RequestTo}
                                onChange={handleResignChange}
                            >
                                <option value="">Select</option>
                                {ReportingToDropDown?.map((item) => {
                                    return (
                                        <option value={item?.value}>{item?.display}</option>
                                    )
                                })}
                            </select>
                        </div> */}
                        <div className="form-group input">
                            <label>Request Type</label>

                            <select
                                className="form-control"
                                name="ResignationType"
                                id="ResignationType"
                                disabled={type ? false : true}
                                value={mainResignModal?.ResignationType}
                                onChange={handleResignChange}
                            >
                                <option value="">Select</option>
                                <option value="Resign">Resign</option>
                                <option value="Terminate">Terminate</option>

                            </select>
                        </div>
                        <div className="form-group input">
                            <label>Notice Period In Days<span className='req'>*</span></label>
                            <div className={ResignValidationModal?.NoticePeriod ? "form-group input error" : "form-group input"}>
                                {/* <input type="text"
                                    className="form-control"
                                    name="NoticePeriod"
                                    id="NoticePeriod"
                                    value={mainResignModal?.NoticePeriod}
                                    onChange={handleResignChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                /> */}
                                <select
                                    className="form-control"
                                    name="NoticePeriod"
                                    id="NoticePeriod"
                                    value={mainResignModal?.NoticePeriod}
                                    onChange={handleResignChange}
                                >
                                    <option value={''}>Select</option>
                                    <option value={'15'}>15</option>
                                    <option value={'30'}>30</option>
                                    <option value={'60'}>60</option>
                                </select>
                                {ResignValidationModal?.NoticePeriod}
                            </div>
                        </div>
                        <div className="input">
                            <div className={ResignValidationModal?.LastDate ? "input error" : "form-group input"}>

                                <DatePicker
                                    label={<label>Last Date of Employement<span className='req'>*</span></label>}
                                    value={mainResignModal.LastDate}
                                    openCalendarMenufunc={(event) => openCalendarMenuJoining(event, 'LastDate')}
                                    {...minDate}
                                    anchorEl={LastEmploymentDate}
                                    open={openLastDateOfEmployement}
                                    closeCalendarMenufunc={closeCalendarMenuResign}
                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'LastDate')}
                                    placeholder={true}
                                />
                                {ResignValidationModal?.LastDate}
                            </div>
                        </div>
                        <div className="form-group input">
                            <label>Reason</label>
                            <textarea type="text"
                                className="form-control"
                                name="Reason"
                                id="Reason"
                                value={mainResignModal?.Reason}
                                onChange={handleResignChange}
                                rows="5"
                            ></textarea>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className="input">
                        <label>Employee Name<span className='req'>*</span></label>
                        <div className={ResignValidationModal?.EmployeeName ? "form-group input error" : "form-group input"}>
                            <input type="text"
                                className="form-control"
                                name="EmployeeName"
                                id="EmployeeName"
                                value={mainResignModal?.EmployeeName}
                                onChange={handleResignChange}
                            />
                            {ResignValidationModal?.EmployeeName}
                        </div>
                    </div>
                    <div className="input">
                        <label>Designation<span className='req'>*</span></label>
                        <div className={ResignValidationModal?.DesignationID ? "form-group input error" : "form-group input"}>
                            <select type="text"
                                className="form-control"
                                name="DesignationID"
                                id="DesignationID"
                                value={mainResignModal?.DesignationID}
                                onChange={handleResignChange}
                            >
                                <option value={"-1"}>Select</option>
                            </select>

                            {ResignValidationModal?.DesignationID}
                        </div>
                    </div>
                    <div className="input">
                        <label>Current Status<span className='req'>*</span></label>
                        <div className={ResignValidationModal?.CurrentStatus ? "form-group input error" : "form-group input"}>
                            <input type="text"
                                className="form-control"
                                name="CurrentStatus"
                                id="CurrentStatus"
                                value={mainResignModal?.CurrentStatus}
                                onChange={handleResignChange}
                            />
                            {ResignValidationModal?.CurrentStatus}
                        </div>
                    </div>
                    <div className="input">
                        <div className={ResignValidationModal?.JoiningDate ? "form-group input error" : "form-group input"}>
                            <CalendarInput
                                label={<label>Joining Date<span className='req'>*</span></label>}
                                value={mainResignModal.JoiningDate}
                                openCalendarMenufunc={(event) => openCalendarMenuJoining(event, 'JoiningDate')}
                                maxDate={new Date()}
                                anchorEl={dateOfJoining}
                                open={openDateOfJoining}
                                closeCalendarMenufunc={closeCalendarMenuResign}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'JoiningDate')}
                            />
                            {ResignValidationModal?.JoiningDate}
                        </div>
                    </div>

                    <div className="form-group input">

                        <CalendarInput
                            label={<label>Approved Last Date</label>}
                            value={mainResignModal.ApprovedLastDate}
                            openCalendarMenufunc={(event) => openCalendarMenuJoining(event, 'ApprovedLastDate')}
                            maxDate={new Date()}
                            anchorEl={dateOfApproval}
                            open={openApprovedLastDate}
                            closeCalendarMenufunc={closeCalendarMenuResign}
                            handleCalendarChangefunc={date => handleCalendarChange(date, 'ApprovedLastDate')}
                        />
                    </div>
                    <div className="form-group input">
                        <label>Resignation Type<span className='req'>*</span></label>
                        <div className={ResignValidationModal?.ResignationType ? "form-group input error" : "form-group input"}>
                            <select type="text"
                                className="form-control"
                                name="ResignationType"
                                id="ResignationType"
                                value={mainResignModal?.ResignationType}
                                onChange={handleResignChange}
                            >
                                <option value={"-1"}>Select</option>
                            </select>
                            {ResignValidationModal?.ResignationType}
                        </div>
                    </div>


                </div> */}
            </Modal.Body>
            {resignType === "New" ?
                <ModalFooter isSaved={onClose} handleSave={handleSaveResignation} RequestStatus={"Pending"} />
                : resignType === "Exist" ?
                    <ModalFooterAdmin isSaved={handleDenyModal} handleSave={handleApproveModal} RequestStatus={mainResignModal?.ResignationStatus} />
                    :
                    <></>
            }
        </Modal>
    )
}

export default AddNewResign
