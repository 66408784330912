import React from "react";
import Tab from 'react-bootstrap/Tab';
import { useState, useEffect } from "react"
import { TabContent, TabPane, NavItem, NavLink } from 'reactstrap'
import Nav from 'react-bootstrap/Nav';
import { connect } from "react-redux";
import { NewTabAction, TabAction } from "../../../../actions/HandlePaymentTabAction";
import { useDispatch } from "react-redux";

import { PayrollRecordAction } from "../../../../actions/PayrollRecordAction";
import { CancelButton } from "../../../../actions/CancelButton";

import { useSelector } from "react-redux";
import { NewPayrollData } from "../../../../actions/NewPayrollData"
import PayrollAdmin from "./PayrollAdmin";
import PayrollTab from "./PayrollTab";
import CreatePopup from "./CreatePopup";

const Payroll = (props) => {

    let TotalPayroll = useSelector(state => state.TotalPayrollReducer);
    let ActivePayroll = useSelector(state => state.PayrollActiveTabReducer);
    let NewPayroll = useSelector(state => state.NewPayrollDataReducer)
    // let PayrollList = useSelector(state => state)?.OPEN_TAB_PAYROLL;
    let dispatch = useDispatch();

    const [createPopup, setCreatePopup] = useState(false)
    console.log("ActivePayroll",ActivePayroll)
    let TimeAdjustment_Modal = {
        Month: String(new Date().getMonth()+1),
        Year: String(new Date().getFullYear()),
    }
    const [mainDropDownModal, setMainDropDownModal] = useState(TimeAdjustment_Modal);
    const [tabList, setTabsList] = useState([{
        active: true,
        key: 2,
        name: "Payroll",
        data: {
            FirstName: "Payroll",
            id: 0,

        },
    },])
    const [IsOpenPayroll, setIsOpenPayroll] = useState(false);
    const [activeTab, setActiveTab] = useState(0)
    dispatch(PayrollRecordAction(props.OpenDynamicTab));

    useEffect(() => {
        if (props.OpenDynamicTab && props.OpenDynamicTab.length > 0)
            AddDynamicTabData(props)

    }, [props.OpenDynamicTab, NewPayroll])


    const AddDynamicTabData = (data) => {
        let tabDataFromRedux = data.OpenDynamicTab.length > 0 ? data.OpenDynamicTab : [];
        //Adding New Employee in tab////

        for (var i in tabDataFromRedux) {
            if (tabDataFromRedux[i].data?.id === 0.1 && tabDataFromRedux[i].active === true && NewPayroll !== 0) {
                tabDataFromRedux[i].data = NewPayroll;
                setIsOpenPayroll(false)
                dispatch(NewPayrollData(0))
            }
        }
        ///////////////////////////////
        const DataFromRedux = tabDataFromRedux.filter((ele) => { return ele.key === 2; });   // fetch only whose id is 2
        const activeElements = DataFromRedux.filter((row) => row.active === true); // get active 
        if (activeElements.length > 0 && tabList.length === 1) {
            let tabsData = [...tabList]
            tabsData = tabsData.concat(...DataFromRedux)
            setTabsList(tabsData)
            console.log("IFactiveElements[0]", activeElements[0], activeElements[0].data?.id, tabsData)
            setActiveTab(activeElements[0].data?.id)
        } else {
            let firstEmployeeTab = tabList.filter((el) => el.data?.id === 0);
            let tabs = [...firstEmployeeTab]
            tabs = tabs.concat(...DataFromRedux)
            setTabsList(tabs)
            console.log("ElseactiveElements[0]", activeElements[0])
            setActiveTab(activeElements[0]?.data?.id)
        }
    }
    const handleActiveTab = (key) => {

        if (key != 0.1) { setIsOpenPayroll(false) }

        if (activeTab !== key) {
            setActiveTab(key)
        }
    }

    const handleCloseTab = async (id) => {

        console.log("id", id)

        setIsOpenPayroll(false);
        let index = tabList.findIndex((el) => el.data?.id === id);
        tabList.splice(index, 1);
        let activTab =
            tabList.length > 0
                ? tabList[tabList.length - 1].data?.id
                : 0;

        setTabsList(tabList)
        setActiveTab(activTab)
        props.TabAction({}, id);
    };

    const AddPayroll = () => {
        setMainDropDownModal(() => TimeAdjustment_Modal)
        setCreatePopup(true);
    }

    let CancelBtn = useSelector(state => state.CancelButtonReducer);

    let DeletePayrollID = useSelector(state => state.DeletePayrollIDReducer);
    if (CancelBtn) {
        handleCloseTab(ActivePayroll);
        dispatch(CancelButton(false))
    }
    if (DeletePayrollID) {
        console.log("DeletePayrollID", DeletePayrollID)

        let index = tabList.findIndex((el) => el.data?.id === DeletePayrollID);
        index !== -1 && handleCloseTab(DeletePayrollID);
        dispatch(DeletePayrollID(''))
    }
    const handleMonthChange = (e) => {
        const { name, value } = e.target;
        setMainDropDownModal((prev) => ({ ...prev, [name]: value }))
    }
    const handleData = () => {
        setCreatePopup(false);
        setIsOpenPayroll(true);
    }
    return (
        <>
            <CreatePopup createPopup={createPopup} setCreatePopup={setCreatePopup} mainDropDownModal={mainDropDownModal} setMainDropDownModal={setMainDropDownModal} handleData={handleData} handleMonthChange={handleMonthChange}/>
            <div className='app-tabs fix-tabs-v2'>
                <Tab.Container>
                    <div className='pt-1 ps-3 employee_header'>

                        <Nav variant="tabs" className="" >
                            {tabList && tabList.map((item) => {

                                return (
                                    <React.Fragment key={item.data?.id}>
                                        <NavItem >
                                            <NavLink
                                                // href="#"
                                                className={activeTab === item.data?.id ? "active" : ""}
                                                onClick={() => handleActiveTab(item.data?.id)}
                                            >
                                                <div className="heading sm"> {item.data?.FirstName} </div>

                                                {item.data?.FirstName === "Payroll" ? <span className="counter">{TotalPayroll}</span> : <></>}

                                                {item.data?.id > 0 ? (
                                                    <span onClick={(e) => { handleCloseTab(item.data?.id); e.stopPropagation() }} className={activeTab === item.data?.id ? "cross" : "cross"}>
                                                        <i className="icon-Icon-metro-cross"></i>
                                                    </span>

                                                ) : (
                                                    ""
                                                )}
                                            </NavLink>
                                        </NavItem>
                                    </React.Fragment>
                                )
                            })}
                        </Nav>

                        {/* <div>
                            <button className='btn btn-orange btn-xs ' onClick={() => AddPayroll(true)}>Add New Employee</button>
                        </div> */}

                    </div>
                    <TabContent activeTab={activeTab}>
                        {tabList &&
                            tabList.filter((el) => el.data?.id === 0).map((item) => {
                                return (
                                    <React.Fragment key={item.data?.id}>
                                        <TabPane tabId={item.data?.id}>
                                            <PayrollAdmin IsOpenPayroll={IsOpenPayroll} mainDropDownModal={mainDropDownModal} AddPayroll={AddPayroll} />
                                        </TabPane>
                                    </React.Fragment>
                                )
                            })}

                        {tabList &&
                            tabList.filter((el) => el.data?.id !== 0).map((item) => {
                                return (
                                    <React.Fragment key={item.data?.id}>
                                        <TabPane tabId={item.data?.id}>
                                            <div className="">

                                                <PayrollTab Active={activeTab} />

                                            </div>
                                        </TabPane>
                                    </React.Fragment>
                                )

                            })}

                    </TabContent>
                </Tab.Container>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        OpenDynamicTab: state.OPEN_TAB_PAYROLL ? state.OPEN_TAB_PAYROLL : [],
    };
}
const action = {
    TabAction,
};

export default connect(mapStateToProps, action)(Payroll)
