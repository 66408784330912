import React, { useEffect, useState } from 'react'
import Menu from '@mui/material/Menu'
import { Calendar } from 'react-date-range'
import { DateFormat_YMd } from './Constants'
import format from 'date-fns/format'

const CalendarInput = (props) => {
    const { anchorEl, closeCalendarMenufunc, handleCalendarChangefunc, label, minDate, maxDate, open, openCalendarMenufunc, value, onChangeValue } = { ...props };
    const currDate = DateFormat_YMd(value);
    const [inputVal, setInputVal] = useState(value)
    const [inputDate, setInputDate] = useState(currDate)

    const handleCalendarInput = (e) => {
        setInputVal(e.target.value)
        handleCalendarChangefunc(inputVal)
    }
    const handleInputFocus = (e) => {
        e.target.blur(); // This prevents the native date picker from opening
    };
    const handleKeyPress = (e) => {
        // Check if the key pressed is not the backspace key (key code 8)
        if (e.keyCode !== 8) {
            e.preventDefault(); // Prevent the default behavior
        } else {
            setInputVal("")
            setInputDate(null);
        }
    };

    useEffect(() => {
        setInputVal(value);
        setInputDate(currDate);
    }, [value])
    
    return (
        <div className='input calender'>
            <label>{label}</label>
            <div className='calender-icon'>
                <input

                    // type="date"
                    onChange={handleCalendarInput}
                    value={inputVal}
                    className='form-control'
                    onKeyDown={handleKeyPress}
                    onPaste={(e) => e.preventDefault()} // Prevent paste events
                    onCut={(e) => e.preventDefault()}   // Prevent cut events
                // onClick={openCalendarMenufunc}
                // onFocus={handleInputFocus}
                />
                <i class="icon-calender input-icon" onClick={openCalendarMenufunc}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></i>
            </div>

            <Menu
                className='calender-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={closeCalendarMenufunc}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div id="date-popup" style={{ position: 'relative' }}>
                    <Calendar
                        showMonthAndYearPickers
                        date={inputDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={handleCalendarChangefunc}
                        getPopupContainer={() => document.getElementById("date-popup")}
                        popupStyle={{
                            position: "relative"
                        }}
                    />
                </div>
            </Menu>
        </div >
    )
}

export default CalendarInput