import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const ClusteredStackedColumnChart = ({name,data}) => {
  const monthCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // let curr = name === 'Payroll Summary' ? 0 : 1;
  let currMonth = new Date().getMonth();
  let currYear = new Date().getFullYear();
  let months = [
    ...monthCategories.slice(currMonth,12).map(month =>`${month } ${currYear-1}`),
    ...monthCategories.slice(0,currMonth).map(month =>`${month } ${currYear}`)
  ];

  const calculateYAxisMax = (activeSeries) => {
    let data = activeSeries.filter(item=>['Gross Salary','Deductions'].includes(item.name));
    let maxValues = months.map((_, index) => 
      data.reduce((sum, series) => sum + series.data[index], 0)
    );
    const numLength = Math.max(...maxValues).toString().length;
    const multiple = Math.pow(10, numLength - 2); // Determine rounding factor based on length - 2
    return Math.ceil(Math.max(...maxValues) / multiple) * multiple;
    // return Math.max(...maxValues)
  };
  const initialOptions = {
    series: data,
    chart: {
      type: 'bar',
      height: 250,
      stacked: true,
      toolbar: {
        show: false
      },
    },
    stroke: {
      width: [0, 0, 3], // Adjust line width for Net Salary
      curve: 'smooth', // Smooth the line
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40%',
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          fontSize: 12,
          fontFamily: 'Poppins-semibold',
          colors: '#707070',
        },
      },
    },
    yaxis: {
      min: 0, // Initial y-axis mix
      max: calculateYAxisMax(data), // Initial y-axis max
      labels: {
        style: {
          fontSize: 12,
          fontFamily: 'Poppins-medium',
          colors: '#707070',
        },
        formatter: (val) => {
          return String(val).length < 4 ? val : String(val).length < 7 ? val / 1000 + 'K' : String(val).length >= 7 ? parseFloat(val/ 1000000)?.toFixed(2) + ' M' : val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ['#FFF5C8','#CBFFFC','#EFD5E6'],
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: 10,
      fontFamily: 'Poppins-medium',
      markers: {
        height:10,
        width:10,
      }
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const seriesData = w.config.series.map((item,index) => {
          if(item.data?.[dataPointIndex]){
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2, // minimum number of fractional digits
              maximumFractionDigits: 2, // maximum number of fractional digits
            });
            let amount = item.data?.[dataPointIndex] ? formatter.format(item.data?.[dataPointIndex]) : formatter.format(0);
          return `
          <div class='d-flex justify-content-between mt-1' style = 'width: 170px;'>
          <p class='xsm_text'><span style='display: inline-block; background-color: ${w.globals.colors[index]};  width: 6px; height: 6px; border-radius: 3px ; margin-right: 5px;' ></span>${item.name}</p> 
          <p class='xsm_text'> ${amount}</div>`};
        });
        return `
        <div class="apexcharts-tooltip-title pt-0 mt-0">
          <p class='xsm heading app_border py-0 text-center border-end-0 border-start-0 border-top-0 pb-2'>${w.globals.labels[dataPointIndex]}</p>
          <div className='content'> ${seriesData.join('')}</div>
        </div>
        `;
      },
    },
    grid: {
      border: '0.25px solid #959595',  
    },
  };

  const [state, setState] = useState(initialOptions);

  return (
    <Chart
      options={state}
      series={state.series}
      type={state.chart.type}
      height={state.chart.height}
    />
  );
};

export default ClusteredStackedColumnChart;
