import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { CancelSaveReload } from "../../../../actions/CancelSaveReload";
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import { useSelector } from "react-redux"
import Modal from 'react-bootstrap/Modal';
import close from "../../../../assetsOld/img/x-close.svg"
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter";

const AddNewDesignation = (props) => {
    let dispatch = useDispatch();

    let Designation_Modal = {
        DesignationName: "",
        Detail: "",
        HasTeam: false,
    }

    let valModal = {}
    let ValidationModal = {
        DesignationName: "",
    }
    const [newDesignationSaved, setNewDesignationSaved] = useState(false);
    const [designationValidationModal, setDesignationValidationModal] = useState(ValidationModal)
    const [DesignationID, setDesignationID] = useState(0)
    const [DesignationSaveUpdateModal, setDesignationSaveUpdateModal] = useState({})
    const [mainDesignationModal, setmainDesignationModal] = useState(Designation_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [del, setDel] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenDesignation, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    console.log(onClose)
    // const OrganizationID=useSelector((state)=>state.Organization);
    // const[delID,setDelID]=useState(0)

    useEffect(() => {
        setIsLoading(false)
        if (props.DesignationID != null) {
            setDesignationID(props.DesignationID)
            setDel(true)
        } else {
            if (!newDesignationSaved) {
                setDesignationID(0)
                setDel(false)
            }
            else { setDel(true) }
        }
        if (!isNull(DesignationID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": DesignationID,
                "TableName": enumUtil.enumAPITableName.Designation

            }).then((response) => {
                setIsLoading(false)
                setmainDesignationModal(response)
            }).catch((error) => {
                NotificationHandler(error.message)
                setIsLoading(false)
            })
        }
    }, [DesignationID])

    const handleDesignationChange = (e) => {
        let { name, value, checked } = e.target;
        let modal = { ...mainDesignationModal };
        let saveModal = { ...DesignationSaveUpdateModal }
        if (name === "HasTeam") {
            modal[name] = checked;
        } else {
            modal[name] = value;
        }

        setmainDesignationModal(modal)
        for (var property in Designation_Modal) {
            if (name === property) {
                if (name === "HasTeam") {
                    saveModal[name] = checked + "";
                } else {
                    saveModal[name] = value;
                }
            }
           
        }
        setDesignationSaveUpdateModal(saveModal)
    }

    const DesignationValidation = async () => {
        let ValidationModal = {
            DesignationName: FormValidator(
                mainDesignationModal.DesignationName,
                enumUtil.enumValidationType.Required,
                'Designation Name',
            ),
        }
        await setDesignationValidationModal(ValidationModal)
        valModal = ValidationModal
    }


    const handleSaveDesignation = async (e) => {
        await DesignationValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Designation,
                ID: DesignationID,
                data: { ...DesignationSaveUpdateModal },
                value: null
            }
            //setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewDesignationSaved(true)
                    setDesignationID(response.id)
                    setIsRefresh(true)
                    onCloseSave()
                    // dispatch(CancelSaveReload(true))
                }
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsRefresh(false)
            })
        }
    }

    // const handleDesignationDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": DesignationID,
    //         "TableName": enumUtil.enumAPITableName.Designation
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Designation Deleted Successfully!', enumUtil.enumtoaster.success)  
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onDeleteClose()
    // }
    const SetDesignationName = () => {
        if (mainDesignationModal.DesignationName != null && mainDesignationModal.DesignationName != "") {
            return (
                <p className="heading validation_message">{mainDesignationModal.DesignationName}</p>
            )
        } else {
            return (
                <p className="heading ">Add New Designation</p>
            )
        }
    }
    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup = ""
    if (del) {
        DeletePopup = <button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Designation} ID={DesignationID} onClose={onClose} />
    }
    //////////
    return (
        <>
            {ConfirmDelete}
            <Modal show={isOpenDesignation} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_new_des">
                <Modal.Body>
                    {isLoading ? <Loader /> : <></>}
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={() => onClose(newDesignationSaved)}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-designation fs-22"></i>
                            </span>
                            <p class="heading mt-2 mb-2"> {SetDesignationName()}</p>
                        </div>
                    </div>
                    <div>
                        <div className={designationValidationModal.DesignationName ? "form-group input error" : "form-group input"}>
                            <label>Designation Name<span className='req'>*</span></label>
                            <input type="text"
                                className="form-control "
                                name="DesignationName"
                                id="DesignationName"
                                maxLength={35}
                                value={mainDesignationModal.DesignationName}
                                onChange={handleDesignationChange}
                            />
                            {designationValidationModal.DesignationName}
                        </div>
                        <div className="form-group input">
                            <label>Description</label>
                            <textarea rows="4"
                                className="form-control"
                                name="Detail"
                                id="Detail"
                                maxLength={100}
                                value={mainDesignationModal.Detail}
                                onChange={handleDesignationChange}
                            ></textarea>
                        </div>
                        {/* <div className='d-flex align-items-center gap-1'>
                            <div class="custom_check_box">
                                <input type="checkbox"
                                    className="form-check-input"
                                    name="HasTeam"
                                    id="HasTeam"
                                    // value={mainLeaveModal.HalfDay}
                                    checked={mainDesignationModal.HasTeam}
                                    // onChange={handleCheckBoxChange}
                                    onChange={handleDesignationChange}
                                />
                            </div>
                            <p className='text clr_gry'>Has Team</p>
                        </div> */}
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveDesignation} />
            </Modal>
        </>

    )
}

export default AddNewDesignation