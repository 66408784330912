import React, { useEffect, useState } from "react"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux";
import { CancelButton } from "../../../../actions/CancelButton";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import format from 'date-fns/format';
import { DateFormat, DateFormat_dMy, handleRenderData } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import jwt_decode from 'jwt-decode';
import AddNewSkills from "./AddNewSkills"
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';

const Skills = (props) => {
    const { type } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    } 
    // else {
    //     EmployeeID = Token.UserID;
    // }

    let dispatch = useDispatch();
    let Skill_Modal = {
        SkillName: "",
        Institute: "",
        Remarks: "",
        GrantedOn: "",
        // Rating: "",
    }
    let valModal = {}
    let ValidationModal = {
        SkillName: "",
        Institute: "",
    }
    const [skillValidationModal, setSkillValidationModal] = useState(ValidationModal)
    const [skillSaveUpdateModal, setSkillSaveUpdateModal] = useState({})
    const [mainSkillModal, setmainSkillModal] = useState(Skill_Modal);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const API_URL = process.env.REACT_APP_API
    const [SkillData, setSkillData] = useState([]);
    const [SkillID, setSkillID] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [delID, setDelID] = useState(0)
    ///////Calendar////////
    const [grantedOn, setGrantedOn] = useState();
    const openGrantedOn = Boolean(grantedOn);
    const [isOpenSkills, setIsOpenSkills] = useState(false);

    const handleCalendarChange = (date, type) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, DateFormat_dMy)

        let modal = { ...mainSkillModal };
        let saveModal = { ...skillSaveUpdateModal }
        modal[type] = FormatedDateShow
        setmainSkillModal(modal)
        for (var property in Skill_Modal) {
            if (type === property) {
                saveModal[type] = FormatedDateSave
            }
        }
        setSkillSaveUpdateModal(saveModal)
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setGrantedOn(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'GrantedOn') { setGrantedOn(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ////////////////////////
    const handleDeleteModal = (ID) => {
        console.log(ID)
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        setmainSkillModal(Skill_Modal)
        setSkillID(0)
        setIsOpenAlert(false)
        LoadData()
    }
    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Skill} ID={delID} onClose={onClose} />
    }

    ////////////////////
    const LoadData = (param) => {
        setSkillData([])
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.Skill,
            "SearchParam": param,
            "SearchCriteria": {
                "EmployeeID": EmployeeID + "",
            }
        }).then((response) => {
            setIsLoading(false);
            setSkillData(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }
    const handleSkillChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainSkillModal };
        let saveModal = { ...skillSaveUpdateModal }
        modal[name] = value
        setmainSkillModal(modal)
        for (var property in Skill_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setSkillSaveUpdateModal(saveModal)
    }

    const OfficeValidation = async () => {
        let ValidationModal = {
            SkillName: FormValidator(
                mainSkillModal.SkillName,
                enumUtil.enumValidationType.Required,
                'Skill Name',
            ),
            Institute: FormValidator(
                mainSkillModal.Institute,
                enumUtil.enumValidationType.Required,
                ' Institute',
            ),
        }
        await setSkillValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveOffice = async () => {
        await OfficeValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            console.log("ONCLICK")
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            console.log("ONCLICK")
            setSkillSaveUpdateModal(skillSaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Skill,
                ID: SkillID,
                ParentID: EmployeeID,
                data: { ...skillSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setSkillID(response.id)
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log('error', error);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    useEffect(() => {
        LoadData();
    }, [])

    const AddSkill = (ID) => {
        if (ID !== SkillID) {
            setmainSkillModal(Skill_Modal)
        }
        setSkillID(ID);
        setIsOpenSkills(true)
    }
    const handleOnClose = () => {
        setIsOpenSkills(false)
    }
    const handleOnCloseSave = () => {
        setIsOpenSkills(false)
            LoadData();
    }
    let SkillsPopup = "";
    if (isOpenSkills) {
        SkillsPopup = <AddNewSkills isOpenSkills={isOpenSkills} handleOnClose={handleOnClose} handleOnCloseSave={handleOnCloseSave} ID={SkillID} setIsRefresh={setIsRefresh} type={type} />
    }
    return (
        <React.Fragment>
            {ConfirmDelete}
            {SkillsPopup}
            {isLoading ? <Loader /> : null}
            {/* <div className="tabs-container"> */}
            {/* <div className="row row-half row-gap-15"> */}
            {/* <div className="col-md-9"> */}
            {/* <div className="app-padding"> */}
            {/* <div className='row row-half row-gap-15'> */}
            <div className="mt-3 ps-0 education skills">
                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                        <span><i className="icon-Icon-material-work"></i></span>
                        <p className="heading">Skills</p>
                    </div>
                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => AddSkill(0)}></i>
                </div>
                {SkillData && SkillData.length >= 1 &&
                    <div className="card_content pb-3 mt-2">
                        {SkillData?.map((item, index) => {
                            return (

                                <div className="content">
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading office_name_truncate" title={item?.SkillName}>{item?.SkillName}</p>
                                        <p className="text">{handleRenderData(item?.GrantedOn,null,'Brackets')}</p>
                                    </div>
                                    <p className="text px-2 office_name_truncate" title={item?.Institute}>{item?.Institute}</p>
                                    <div className="d-flex status_content justify-content-between pe-2 align-items-center">
                                        {/* <div className="status py-1 px-2"> */}
                                        {/* <p className="heading sm office_name_truncate">{item?.Remarks}</p> */}
                                        <div>
                                            <Rating name="Rating" readOnly value={item.Rating} defaultValue={2} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />

                                        </div>
                                        {/* <div>
                                            <Typography component="legend">Rating</Typography>
                                            <Rating name="Rating" readOnly value={item.Rating} defaultValue={2} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />

                                        </div> */}
                                        <div className="icons">
                                            <i className="icon-edit-pencil me-1 cursor-pointer" onClick={() => AddSkill(item?.SkillID)}></i>
                                            <i className="icon-Group-3994 cursor-pointer" onClick={() => handleDeleteModal(item?.SkillID)}></i>
                                        </div>
                                    </div>
                                    {index === SkillData?.length - 1 ? <></> : <div className="bottom_border mt-3"></div>}
                                </div>

                            )
                        })}
                    </div>}
            </div>
            {/* {SkillData.map((items) => {
                                    return (
                                        <div className="col-md-5">
                                            <div className="card card-skills border-0">
                                                <div className="card-body">
                                                    <div className="row row-gap-10">
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Skill Name</div>
                                                            <div className="text font-sb">{items.SkillName || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Institute</div>
                                                            <div className="text font-sb">{items.Institute || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">GrantedOn </div>
                                                            <div className="text font-sb">{items.GrantedOn || "N/A"}</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text font-m clr-light2">Remarks</div>
                                                            <div className="text font-sb remark_truncate" title={items.Remarks}>{items.Remarks || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="rating">
                                                    <p className="text font-m">Rating stars</p>
                                                    <div className="d-flex gap-1 ">
                                                        <div className="d-flex gap-1 justify-content-center align-items-center">
                                                            <i className="icon-fullfilled-star"></i>
                                                            <i className="icon-fullfilled-star"></i>
                                                            <i className="icon-halffilled-star"></i>
                                                            <i className="icon-empty-star"></i>
                                                            <i className="icon-empty-star"></i>
                                                        </div>
                                                        <button class="btn btn-icon-transparent clr-muted fs-22" onClick={() => handleDeleteModal(items.SkillID)}><i class="icon-delete"></i></button>
                                                        <button className="edit-icon" onClick={() => AddSkill(items.SkillID)}></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })} */}
            {/* </div> */}
            {/* </div> */}

            {/* </div> */}
            {/* <div className="col-md-3">
                        <div className="card rounded-0 br-none br-left-1 br-clr bg-transparent h-100">
                            <div className="bg-blue app-padding py-2">
                                <div className="row">
                                    <div className="col-md-2"><button class="btn-icon-transparent-add" onClick={() => AddSkill(0)}></button></div>
                                    <div className="col-md-10"><p className="text text-white fs-16">Add New Skill</p></div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className={skillValidationModal.SkillName ? "form-group input error" : "form-group input"}>
                                    <label>Skill Name</label>
                                    <input 
                                    type="text" 
                                    className="form-control"
                                        name="SkillName"
                                        id="SkillName"
                                        value={mainSkillModal.SkillName}
                                        onChange={handleSkillChange}
                                    />
                                    {skillValidationModal.SkillName}
                                </div>
                                <div className={skillValidationModal.Institute ? "form-group input error" : "form-group input"}>
                                    <label>Institute</label>
                                    <input 
                                    type="text" className="form-control"
                                        name="Institute"
                                        id="Institute"
                                        value={mainSkillModal.Institute}
                                        onChange={handleSkillChange}
                                    />
                                    {skillValidationModal.Institute}
                                </div>
                                <div className="form-group input">
                                    <CalendarInput
                                        label='Granted On'
                                        maxDate={new Date()}
                                        value={mainSkillModal.GrantedOn}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'GrantedOn')}
                                        anchorEl={grantedOn}
                                        open={openGrantedOn}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'GrantedOn')}
                                    />
                                </div>
                                <div className="form-group input">
                                    <label>Remarks</label>
                                    <textarea 
                                    className="form-control"
                                        name="Remarks"
                                        id="Remarks"
                                        value={mainSkillModal.Remarks}
                                        onChange={handleSkillChange}
                                        ></textarea>
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* <div className='tabs-action-bottom'>
                <div className='btns-group text-end px-3 br-top-1 br-clr d-flex align-items-center justify-content-end h-100'>
                    <button className='btn btn-gray'onClick={()=>dispatch(CancelButton(true))}>Cancel</button>
                    <button className='btn btn-orange' onClick={handleSaveOffice}>Save</button>
                </div>
            </div> */}
        </React.Fragment>
    )
}

export default Skills