import React, { useEffect, useState } from "react";
import YourAttendance from "../YourAttendance";
import LineChart from "../AdminDashboard/Charts/LineChart";
import { enumUtil } from "../../../utils/Enum";
import { ServerRequestProtectedParentID } from "../../../utils/ServerDataRequest";
import NewHirings from "./Components/NewHirings";
import Team from "./Components/Team";
import Announcements from "./Components/Announcements";
import NewRequests from "./Components/NewRequests";
import CelebrationCorner from "./Components/CeleberationCorner";
import PayrollSummary from "./Components/PayrollSummary";
import GenderDiversity from "./Components/GenderDiversity";
import EmployementStatus from "./Components/EmployementStatus";
import CustomUseRef from "../../shared/CustomUseRef/CustomUseRef";
import LetterProfilePicture from "./shared/LetterProfilePicture";

const AdminDashboard = () => {
  const API_URL_DASHBOARD = process.env.REACT_APP_API_Dashboard;

  const [adminDashboardData, setAdminDashboardData] = useState([]);
  const [employeeCountData, setEmployeeCountData] = useState([]);

  const [reloadTeamData, setReloadTeamData] = useState('');
  const [teamData, setTeamData] = useState([]);
  const [employementStatusData, setEmployementStatusData] = useState([]);
  const [genderDiversityData, setGenderDiversityData] = useState([]);
  const [newHiringData, setNewHiringData] = useState([]);
  const [celebrationData, setCelebrationData] = useState([]);
  const [viewDetail, setViewDetail] = useState({Present : false, OnLeave : false, Remote : false, Late : false,});
  const [empListDetail, setEmpListDetail] = useState({});

  const [newHiringFilter, setNewHiringFilter] = useState("Month");
  const [celebrationCornerFilter, setCelebrationCornerFilter] =
    useState("Month");

  const [payrollSummaryData, setPayrollSummaryData] = useState([]);
  const [payrollSummaryYear, setPayrollSummaryYear] = useState(
    new Date().getFullYear()
  );
  const [isLoading, setIsLoading] = useState(false);

  const LoadData = (searchParam) => {
    searchParam === "TodayEmployeeList" && setEmpListDetail({});
    searchParam === "TodayEmployeeList" && setViewDetail({Present : false, OnLeave : false, Remote : false, Late : false,});

    let url =
      //  searchParam === "GridData" ? '/GetData' :
      searchParam === "PayrollSummary"
        ? "/GetPayrollSummary"
        : searchParam === "Team"
        ? "/Team"
        : searchParam === "EmployementStatus"
        ? "/EmployementStatus" :
        searchParam === "TodayEmployeeList" ? '/TodayEmployeeList' :
         searchParam === "GenderDiversity"
        ? "/GenderDiversity"
        : searchParam === "NewHiring"
        ? "/NewHiring"
        : searchParam === "Celebration"
        ? "/Celebration"
        : searchParam === "EmployeeCount"
        ? "/GetEmployeeCount"
        : "";

    let value =
      // searchParam === "GridData" ? {RequestBy : celebrationCornerFilter}:
      searchParam === "NewHiring"
        ? { RequestBy: newHiringFilter }
        : searchParam === "Celebration"
        ? { RequestBy: celebrationCornerFilter }
        : // searchParam === "PayrollSummary" ? {RequestBy : payrollSummaryYear}:
          "";

          let TableName = searchParam === "TodayEmployeeList" ? enumUtil.enumAPITableName.Dashboard : 
          enumUtil.enumAPITableName.Dashboard;

    searchParam === "GridData" && setAdminDashboardData([]);
    searchParam === "PayrollSummary" && setPayrollSummaryData([]);
    searchParam === "EmployeeCount" && setEmployeeCountData([]);
    setIsLoading(true);
    let saveModal = {
      TableName: TableName,
      SearchParam: searchParam,
      ...(value ? { SearchCriteria: value } : {}),
    };
    ServerRequestProtectedParentID(API_URL_DASHBOARD + url, "post", saveModal)
      .then((response) => {
        setIsLoading(false);
        searchParam === "GridData" && setAdminDashboardData(response);
        searchParam === "PayrollSummary" && setPayrollSummaryData(response);
        searchParam === "EmployeeCount" && setEmployeeCountData(response);
        searchParam === "TodayEmployeeList" && setEmpListDetail(response);
        // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)

        searchParam === "EmployementStatus" &&
          setEmployementStatusData(response);
        searchParam === "Team" && setTeamData(response);
        searchParam === "GenderDiversity" && setGenderDiversityData(response);
        searchParam === "NewHiring" && setNewHiringData(response);
        searchParam === "Celebration" && setCelebrationData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
      });
  };

  const LoadDashboardData = () => {
    // LoadData("GridData");
    LoadData("PayrollSummary");
    LoadData("EmployeeCount");

    LoadData("Team");
    LoadData("EmployementStatus");
    LoadData("GenderDiversity");
    LoadData("NewHiring");
    LoadData("Celebration");
    LoadData("TodayEmployeeList");
  };
  const PercentageField = ({ value }) => {
    const num = parseInt(value, 10) ? parseInt(value, 10) : 0;
    return (
      <p
        className={`text fs-10 font-m red d-flex align-items-center gap-0 ${
          num < 0 ? "red" : "green"
        }`}
      >
      <i className={`${num < 0 ? "bi bi-arrow-down-short fs-18" : "bi bi-arrow-up-short fs-18"}`}></i>
        {" "}
        <i className="icon-inverted-arrow fs-10 cursor-pointer"></i> {num}% vs
        previous week
      </p>
    );
  };

  useEffect(() => {
    LoadData("NewHiring");
  }, [newHiringFilter]);

  useEffect(() => {
    LoadData("Celebration");
  }, [celebrationCornerFilter]);

  useEffect(() => {
    if(reloadTeamData){
      LoadData("Team");
    }
  }, [reloadTeamData]);

  useEffect(() => {
    LoadDashboardData();
  }, []);

  const handleToggleView = (e) => {
    e.stopPropagation();
    let Prev = {Present : false, OnLeave : false, Remote : false, Late : false,};
    setViewDetail((prev)=>({...Prev , [e.target.dataset.jobStatus] : !prev[e.target.dataset.jobStatus]}))
  }
  const handleDefaultView = () => {
    setViewDetail({Present : false, OnLeave : false, Remote : false, Late : false,})
  }
  return (
    <div>
      <div className="dashboard_screen">
        <div className="row m-0">
          <div className="mt-3  dashboard col-md-9 row m-0 ps-0">
            <div className="row col-md-7 m-0 px-0">
              <div className="flex-column gap-10 d-flex col-md-6 pe-0">
                <div className="card_tabs dashboar pe-0 active_employe">
                  <p className="heading fs-14">Present Employees</p>
                  <div className="d-flex justify-content-between ar_hov-icon">
                    <div className="d-flex align-items-center gap-1   position-relative">
                      <p className="fs-26 heading">
                        {employeeCountData?.[0]?.currentCount}
                      </p>
                      <i
                        className="icon-arrow-top-right fs-12"
                        data-job-status="Present"
                        onClick={handleToggleView}
                      ></i>
                      {viewDetail['Present'] && <CustomUseRef IsActivePtPayments={viewDetail['Present']} onClose={()=>handleDefaultView()} ><EmployeeList data={empListDetail?.ActiveEmployee}/></CustomUseRef>}
                    </div>
                    <PercentageField
                      value={employeeCountData?.[0]?.currentPer}
                    />
                  </div>
                  <div>
                    {employeeCountData?.[0]?.data ? (
                      <LineChart
                        name={"Present"}
                        data={employeeCountData?.[0]?.data}
                        type={
                          parseInt(employeeCountData?.[0]?.currentPer, 10) < 0
                            ? "-"
                            : "+"
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="card_tabs dashboar pe-0 active_employe ">
                  <p className="heading fs-14">Remote/WFH Employees</p>
                  <div className="d-flex justify-content-between ar_hov-icon">
                    <div className="d-flex align-items-center gap-1   position-relative">
                      <p className="fs-26 heading">
                      {employeeCountData?.[2]?.currentCount}
                      </p>
                      <i
                        className="icon-arrow-top-right fs-12"
                        data-job-status="Remote"
                        onClick={handleToggleView}
                      ></i>
                      {viewDetail['Remote'] && <CustomUseRef IsActivePtPayments={viewDetail['Remote']} onClose={()=>handleDefaultView()} ><EmployeeList data={empListDetail?.RemoteEmployee}/></CustomUseRef>}
                    </div>

                    <PercentageField
                      value={employeeCountData?.[2]?.currentPer}
                    />
                  </div>
                  <div>
                    {employeeCountData?.[2]?.data ? (
                      <LineChart
                        name={"Overtime"}
                        data={employeeCountData?.[2]?.data}
                        type={
                          parseInt(employeeCountData?.[2]?.currentPer, 10) < 0
                            ? "-"
                            : "+"
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-column gap-10 d-flex col-md-6 pe-0">
                <div className="card_tabs dashboar pe-0 active_employe">
                  <p className="heading fs-14">On Leave/Absent Employees</p>
                  <div className="d-flex justify-content-between ar_hov-icon">
                    <div className="d-flex align-items-center gap-1   position-relative">
                      <p className="fs-26 heading">
                      {employeeCountData?.[1]?.currentCount}
                      </p>
                      <i
                        className="icon-arrow-top-right fs-12"
                        data-job-status="OnLeave"
                        onClick={handleToggleView}
                      ></i>
                      {/* {viewDetail['OnLeave'] && <EmployeeList data={empListDetail?.LeaveEmployee}/>} */}
                      {viewDetail['OnLeave'] && <CustomUseRef IsActivePtPayments={viewDetail['OnLeave']} onClose={()=>handleDefaultView()} ><EmployeeList data={empListDetail?.LeaveEmployee}/></CustomUseRef>}
                    </div>

                    <PercentageField
                      value={employeeCountData?.[1]?.currentPer}
                    />
                  </div>
                  <div>
                    {employeeCountData?.[1]?.data ? (
                      <LineChart
                        name={"On Leave"}
                        data={employeeCountData?.[1]?.data}
                        type={
                          parseInt(employeeCountData?.[1]?.currentPer, 10) < 0
                            ? "-"
                            : "+"
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="card_tabs dashboar pe-0 active_employe ">
                  <p className="heading fs-14">Late Employees</p>
                  <div className="d-flex justify-content-between ar_hov-icon">
                    <div className="d-flex align-items-center gap-1   position-relative">
                      <p className="fs-26 heading">
                      {employeeCountData?.[3]?.currentCount}
                      </p>
                      <i
                        className="icon-arrow-top-right fs-12"
                        data-job-status="Late"
                        onClick={handleToggleView}
                      ></i>
                      {/* {viewDetail['Late'] && <EmployeeList data={empListDetail?.LateEmployee}/>} */}
                      {viewDetail['Late'] && <CustomUseRef IsActivePtPayments={viewDetail['Late']} onClose={()=>handleDefaultView()} ><EmployeeList data={empListDetail?.LateEmployee}/></CustomUseRef>}
                    </div>

                    <PercentageField
                      value={employeeCountData?.[3]?.currentPer}
                    />
                  </div>
                  <div>
                    {employeeCountData?.[3]?.data ? (
                      <LineChart
                        name={"Late"}
                        data={employeeCountData?.[3]?.data}
                        type={
                          parseInt(employeeCountData?.[3]?.currentPer, 10) < 0
                            ? "-"
                            : "+"
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <EmployementStatus
              data={employementStatusData?.EmployementStatus}
            />
            {console.log("payrollSummaryData", payrollSummaryData)}
            <PayrollSummary data={payrollSummaryData} />
            <div className="row dashboard mt-2 m-0 px-0">
              <div className="col-md-4 pe-0">
                <NewHirings
                  data={newHiringData?.NewHiring}
                  filter={newHiringFilter}
                  setFilter={setNewHiringFilter}
                />
              </div>
              <div className="col-md-4 pe-0">
                <GenderDiversity
                  data={genderDiversityData?.GenderDiversity?.[0]}
                />
              </div>
              <div className="col-md-4 pe-0">
                <CelebrationCorner
                  data={celebrationData?.Celebration}
                  filter={celebrationCornerFilter}
                  setFilter={setCelebrationCornerFilter}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 ps-0 pe-1">
            <div className="dashboard mt-3 px-0 w-100">
              <div className="card_tabs attendence mb-2  h-100">
                <YourAttendance />
              </div>
              <NewRequests />
              <Team data={teamData?.Team} setReloadData={setReloadTeamData} />
              <Announcements />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminDashboard;

const EmployeeList = ({data}) => {
  console.log("DATA",data)
  return(
    <>
    {data?.length > 0 ?
    <div className="ar_employee-detail">
      <div className="my-2 px-2 d-flex flex-column gap-10 scroll ar_hov-scrollbar">
        {data?.map(emp => {
          return(
              <div className="d-flex align-items-center gap-2" key={emp?.EMPLOYEEID}>
                <div className="img-container s-30 circle">
                  {emp.ProfilePicture ? (
                  <img src={emp.ProfilePicture} alt="Profile" />
                  ) : (
                      <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-20 text-white ${emp.Gender === 'M' ? 'male' : emp.Gender === 'F'? 'female':'rather-undefined'}`} name={emp.EmployeeName} />
                  )}
                </div>
                <p className="text fs-11">{emp?.EmployeeName}</p>
              </div>
          )
        })}
        </div>
      </div> 
      :<></>}
    </>)}
