import React, { useEffect, useState } from 'react'
import { enumUtil } from '../../../../utils/Enum';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import NotificationHandler from '../../../../utils/NotificationHandler';
import Loader from '../../../shared/Loader/Loader';
import { ServerRequestProtected, ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import isNull from '../../../../utils/NullChecking';
import AddNewSkills from '../Skill/AddNewSkills';
import DeleteConfirmation from '../../../shared/Delete/DeleteConfirmation';
import CustomUseRef from '../../../shared/CustomUseRef/CustomUseRef';

const EmployeeInformation = (props) => {
    const { type, isSaved } = { ...props }
    console.log("MOUNTED EI",type)
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let EmployeeID = null;
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    } else {
        EmployeeID = props.Active ? props.Active : Token.UserID;
    }
    let Employee_Modal = {
        ProfilePicture: "",
        DepartmentID: "",
        DesignationID: "",
        Username: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Nationality: "",
        Gender: "",
        DateOfBirth: "",
        HiringDateFormate: "",
        CNIC: "",
        Address: "",
        City: "",
        Province: "",
        Age: "",
        MobileNumber: "",
        LaneLineNumber: "",
        Education: "",
        Email: "",
        DateOfBirthFormate: "",
        Position: "",
    }

    const OrganizationID = useSelector((state) => state.Organization);
    // const [newEmployeeSaved, setNewEmployeeSaved] = useState(false);
    const [employeeData, setEmployeeData] = useState(Employee_Modal);
    const [employeeSkillsData, setEmployeeSkillsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [editImage, setEditImage] = useState(false)
    const [SkillID, setSkillID] = useState();
    const [isOpenSkills, setIsOpenSkills] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [delID, setDelID] = useState(0)
    const [EmployeeSaveUpdateModal, setEmployeeSaveUpdateModal] = useState({});
    const [profileUpdated, setProfileUpdated] = useState(false);
    const API_URL = process.env.REACT_APP_API

    const LoadEmployeeData = () => {
        if (!isNull(EmployeeID)) {
            setIsLoading(true)
            ServerRequestProtected(API_URL + "/FindByID", 'post', {
                "ID": EmployeeID,
                "TableName": enumUtil.enumAPITableName.Employee
            }).then((response) => {
                setEmployeeData(response);
                // setisSaved(false);
                // setNewEmployeeSaved(false)
                setIsLoading(false)
            }).catch((error) => {
                // setisSaved(false);
                // NotificationHandler(error.message, enumUtil.enumtoaster.error)
                // setNewEmployeeSaved(false)
                setIsLoading(false)
            })
        }
    }

    const LoadSkillsData = (param) => {
        setEmployeeSkillsData([])
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.Skill,
            "SearchParam": param,
            "SearchCriteria": {
                "EmployeeID": EmployeeID + "",
            }
        }).then((response) => {
            setIsLoading(false);
            setEmployeeSkillsData(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }

    const handleEmployeeProfileChange = async (e) => {
        const Type = e.target.files[0].type.substring(0, 5);
        if (Type === 'image') {
            let file = e.target.files[0]
            let reader = new FileReader();

            reader.onloadend = () => {
                let value = reader.result.toString();
                let { name } = e.target;
                let saveModal = { ...EmployeeSaveUpdateModal }
                saveModal[name] = value
                setEmployeeSaveUpdateModal(saveModal)
                setProfileUpdated(true);
            }
            reader.readAsDataURL(file);
        }

    }

    const handleOrganizationProfileDelete = (name) => {
        // let { name } = e.target;
        let modal = { ...employeeData };
        let saveModal = { ...EmployeeSaveUpdateModal }
        modal[name] = ""
        setEmployeeData(modal)
        for (var property in Employee_Modal) {
            if (name === property) {
                saveModal[name] = ""
            }
        }
        setEmployeeSaveUpdateModal(saveModal)
        setProfileUpdated(true)
    }

    const handleSaveEmployeePicture = () => {

        let saveModal = {
            TableName: enumUtil.enumAPITableName.Employee,
            ID: EmployeeID + "",
            // ParentID: OrganizationID + "",
            data: { ...EmployeeSaveUpdateModal },
            value: null
        }
        setIsLoading(true)
        ServerRequestProtected(API_URL + "/SaveEmployeeImage", 'post', saveModal).then((response) => {
            setIsLoading(false)
            if (!isNull(response.id)) {
                setProfileUpdated(false)
                // NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                // setNewEmployeeSaved(true)
                LoadEmployeeData()
                // dispatch(EmployeeActiveTab(response.id))
                // dispatch(EmployeeNewEmployee(response.id))

            }
        }).catch((error) => {
            setIsLoading(false)
            setProfileUpdated(false)
            // NotificationHandler('Failed to Delete Record', enumUtil.enumtoaster.error)
        })
    }

    const handleDeleteModal = (ID) => {
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        setSkillID(0)
        setIsOpenAlert(false)
        LoadSkillsData()
    }
    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Skill} ID={delID} onClose={onClose} />
    }

    const AddSkill = (ID) => {
        setSkillID(ID);
        setIsOpenSkills(true)
    }

    const handleOnClose = () => {
        setIsOpenSkills(false)
        if (isRefresh) {
            LoadSkillsData();
        }
    }
    let SkillsPopup = "";
    if (isOpenSkills) {
        SkillsPopup = <AddNewSkills isOpenSkills={isOpenSkills} handleOnClose={handleOnClose} ID={SkillID} setIsRefresh={setIsRefresh} type={type} />
    }
    const handleEditImagePopup = (e) => {
        e.stopPropagation()
        setEditImage(true)
    }
    useEffect(() => {
        LoadEmployeeData()
        LoadSkillsData()
    }, [])
    // }, [EmployeeID])

    useEffect(() => {
        if (profileUpdated) {
            handleSaveEmployeePicture();
        }
    }, [EmployeeSaveUpdateModal]);
    return (
        <div>
            {ConfirmDelete}
            {SkillsPopup}
            <div className="content h-100 pe-0">
                {/* {isLoading ? <Loader /> : null} */}
                <p className="heading">Profile Strength</p>
                <div className="d-flex gap-1 align-items-center pe-10">
                    <div className="range w-100">
                        <div className="fill w-25"></div>
                    </div>
                    <p className="heading precentage">20%</p>
                </div>

                <div className={`emp-profile-img mb-3 position-relative overflow-hidden position-relative me-10  ${editImage ? 'overflow-visible' : ''}`}>
                    <div className="mt-2 hk-imgHandler img-container s-265">
                        {employeeData?.ProfilePicture ? (<img src={employeeData?.ProfilePicture} alt="" />
                        ) : (<img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />)}
                        <input type="file"
                            id="ProfilePicture"
                            name="ProfilePicture"
                            onChange={handleEmployeeProfileChange} />
                        <div className="edit">
                            <i className="icon-edit-pencil" onClick={handleEditImagePopup}></i>
                        </div>
                    </div>

                    {/* <div className="upload-emp-img">
                                <div className="text-center">
                                    <i className="icon-Clip-path"></i>
                                </div>
                            <label className='d-flex align-items-center  gap-10'>
                                <input type="file" 
                                id="ProfilePicture"
                                name="ProfilePicture"
                                onChange={handleEmployeeProfileChange}/>
                                Change profile photo</label>
                            </div>  */}


                    <CustomUseRef IsActivePtPayments={editImage} onClose={() => setEditImage(false)}>
                        <div className="pic_edit p-2">
                            <div className="d-flex justify-content-end cursor-pointer">
                                <i className="icon-Icon-metro-cross fs-11" onClick={() => setEditImage(false)}></i>
                            </div>
                            <p className="heading text-center fs-16">Change Profile Picture</p>
                            <div class="hk-imgHandler">
                                <div className="d-flex hk-imgHandler img-container justify-content-center mt-2 mx-auto s-120">
                                    {employeeData?.ProfilePicture ? (<img src={employeeData?.ProfilePicture} alt="" />
                                    ) : (<img src="https://www.w3schools.com/howto/img_avatar.png" alt="" />)}
                                    <input type="file"
                                        id="ProfilePicture"
                                        name="ProfilePicture"
                                        onChange={handleEmployeeProfileChange} />
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="btn rounded-1 px-2 my-3 py-2 btn-blue img-container hk-imgHandler mx-auto"><i className="icon-Group-482270"></i>
                                    <input type="file"
                                        className=''
                                        id="ProfilePicture"
                                        name="ProfilePicture"
                                        onChange={handleEmployeeProfileChange} /> Upload Image</button>
                            </div>
                            <div className="d-flex align-items-baseline gap-1">
                                <div className="bottom_border w-100"></div>
                                <p className="gry_heading xsm">or</p>
                                <div className="bottom_border w-100"></div>
                            </div>
                            <p className="heading mt-2 del_item text-center cursor-pointer" onClick={() => handleOrganizationProfileDelete("ProfilePicture")}>Delete Exisiting</p>
                        </div>
                    </CustomUseRef>

                    <div className="text-center mt-2">
                        <p className="heading sm ">{employeeData?.FirstName + " " + employeeData?.LastName}</p>
                        <button class="btn px-2 py-0 btn-blue cursor-default office_name_truncate">{employeeData?.Position}</button>
                    </div>
                </div>
                <div className='ar_employee-info ar_hov-scrollbar'>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="gry_heading font-m">Hire Date</p>
                            <p className="text fs-13 text-nowrap">{employeeData?.HiringDateFormate}</p>
                        </div>
                        <div>
                            <p className="gry_heading font-m">Date of Birth</p>
                            <p className="text fs-13 text-nowrap">{employeeData?.DateOfBirthFormate}</p>
                        </div>
                        <div>
                            <p className="gry_heading font-m">Age</p>
                            <p className="text fs-13 text-nowrap">{employeeData?.Age}</p>
                        </div>
                    </div>
                    <div className="bottom_border my-3"></div>
                    <div className="row m-0">
                        <div className="col-md-4 p-0">
                            <p className="gry_heading">Mobile No</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text">{employeeData?.MobileNumber}</p>
                        </div>
                    </div>
                    <div className="row m-0 mt-2">
                        <div className="col-md-4 p-0">
                            <p className="gry_heading ">Email</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text validation_message" title={employeeData?.Email}>{employeeData?.Email}</p>
                        </div>
                    </div>
                    <div className="row m-0 mt-2">
                        <div className="col-md-4 p-0">
                            <p className="gry_heading">Department</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text office_name_truncate">{employeeData?.DepartmentName}</p>
                        </div>
                    </div>
                    <div className="row m-0 mt-2">
                        <div className="col-md-4 p-0">
                            <p className="gry_heading">Position</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text office_name_truncate">{employeeData?.Position}</p>
                        </div>
                    </div>
                    <div className="bottom_border my-3"></div>
                    <div className="d-flex skill_content justify-content-between align-items-center">
                        <p className="heading">skills</p>
                        <div className="add_icon">
                            <i className="icon-plus-icon cursor-pointer" onClick={() => AddSkill(0)}></i>
                        </div>
                    </div>
                    {employeeSkillsData?.slice(0, 5)?.map((item, index) => {
                        return (
                            <>
                                <div className="d-flex  mt-2 skills justify-content-between">
                                    <button class={`btn px-2 py-0 cursor-default skill-${index}`}>{item?.SkillName.substring(0, 20)}</button>
                                    <div className="icons">
                                        <i className="icon-edit-pencil cursor-pointer" onClick={() => AddSkill(item?.SkillID)}></i>
                                        <i className="ms-1 icon-Group-3994 cursor-pointer" onClick={() => handleDeleteModal(item?.SkillID)}></i>
                                    </div>
                                </div>
                            </>
                        )

                    })}
                </div>
            </div>

        </div >
    )
}

export default EmployeeInformation
