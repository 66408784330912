import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import close from "../../../../assetsOld/img/x-close.svg"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import { Autocomplete, TextField } from "@mui/material";
import format from "date-fns/format";
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import CalendarInput from "../../../../utils/CalendarInput"
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"
import CountriesStates from "../../../shared/CountriesStates/CountriesStates"
import DatePicker from '../../../../utils/DatePicker';

const AddNewOffice = (props) => {
    let Office_Modal = {
        OfficeName: "",
        ShiftIDs: "",
        Address: "",
        City: "",
        Province: "",
        Nationality: "",
        PostalCode: "",
        ContactPersonName: "",
        PhoneNumber: "",
        Email: "",
        Website: "",
        EstablishedDate: null,
    }
    let valModal = {}
    let ValidationModal = {
        OfficeNameVal: "",
        ShiftIDVal: "",
        OfficeEmailVal: "",
        OfficeWebsiteVal: "",

    }
    const [newOfficeSaved, setNewOfficeSaved] = useState(false);
    const [officeValidationModal, setOfficeValidationModal] = useState(ValidationModal)
    const [OfficeID, setOfficeID] = useState()
    const [officeSaveUpdateModal, setOfficeSaveUpdateModal] = useState({})
    const [mainOfficeModal, setmainOfficeModal] = useState(Office_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [del, setDel] = useState(false)
    const [dropDownDataShift, setDropDownDataShift] = useState([])
    const [shiftValue, setShiftValue] = useState([])
    const API_URL = process.env.REACT_APP_API
    const { isOpenOffice, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setIsLoading(false)
        // right now, use any providerid to test update case/find by id
        if (props.OfficeID != null) {
            setOfficeID(props.OfficeID)
            setDel(true)
        } else {
            if (!newOfficeSaved) {
                setOfficeID(0)
                setDel(false)
            }
            else { setDel(true) }
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Shift,
        }).then((response) => {
            setDropDownDataShift(response)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);    
        })
        if (!isNull(OfficeID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": OfficeID,
                "TableName": enumUtil.enumAPITableName.Office
            }).then((response) => {
                setmainOfficeModal(response);
                if (response.ShiftIDs) { setShiftValue(JSON.parse(response.ShiftIDs)) }
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [OfficeID])
    //////Calendar////////////
    const [establishedDate, setEstablishedDate] = useState();
    const openEstablishedDate = Boolean(establishedDate)
    const handleCalendarChange = (date, type) => {
        var FormatedDateShow = format(date, "MM/dd/yyyy");
        var FormatedDateSave = format(date, DateFormat);
        if (type === 'EstablishedDate') {
            mainOfficeModal['EstablishedDate'] = FormatedDateShow
            officeSaveUpdateModal['EstablishedDate'] = FormatedDateSave
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setEstablishedDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'EstablishedDate') { setEstablishedDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ///////////////////////
    const handleMultiSelectChange = (values, type, reason) => {
        let arr = [];
        let arrVal = []
        if (values.length <= 0) {
            arr.fill(null)
        }
        values.map((key, index) => {
            arr.push(key)
        })
        values.map((key, index) => {
            arrVal.push(key.ShiftID)
        })
        setShiftValue(arr)
        mainOfficeModal['ShiftIDs'] = !arr.length <= 0 ? arr : ""
        officeSaveUpdateModal['ShiftIDs'] = arrVal.toString()
    }
    const handleOfficeChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainOfficeModal };
        let saveModal = { ...officeSaveUpdateModal }
        modal[name] = value
        setmainOfficeModal(modal)
        for (var property in Office_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setOfficeSaveUpdateModal(saveModal)
    }

    const OfficeValidation = async () => {
        let ValidationModal = {
            OfficeNameVal: FormValidator(
                mainOfficeModal.OfficeName,
                enumUtil.enumValidationType.Required,
                'Office Name',
            ),
            ShiftIDVal: FormValidator(
                mainOfficeModal.ShiftIDs,
                enumUtil.enumValidationType.Required,
                'Shift',
            ),
            OfficeEmailVal: FormValidator(
                mainOfficeModal.Email,
                enumUtil.enumValidationType.Email,
                "Email",
            ),
            OfficeWebsiteVal: FormValidator(
                mainOfficeModal.Website,
                enumUtil.enumValidationType.Website,
                "Website URL",
            ),
        }
        await setOfficeValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleSaveOffice = async () => {
        console.log("AsyncAwait");
        await OfficeValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            // if(SelectedDate===null || ""){}
            // else{
            // SelectedDate.setTime( SelectedDate.getTime()-SelectedDate.getTimezoneOffset()*60*1000)
            // }
            // officeSaveUpdateModal['EstablishedDate'] = SelectedDate;
            // officeSaveUpdateModal['OrganizationID'] = OrganizationID+"";
            // setOfficeSaveUpdateModal(officeSaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Office,
                ID: OfficeID,
                data: { ...officeSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewOfficeSaved(true)
                    setOfficeID(response.id)
                    setIsRefresh(true);
                    onCloseSave();
                }
            }).catch((error) => {
                setIsLoading(false);
                setIsRefresh(false);
                NotificationHandler(error.message, enumUtil.enumtoaster.error);
            })
        }
    }
    // const handleOfficeDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": OfficeID,
    //         "TableName": enumUtil.enumAPITableName.Office
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Office Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }
    const SetOfficeName = () => {
        if (mainOfficeModal.OfficeName != null && mainOfficeModal.OfficeName != "") {
            return (
                <p className="heading">{mainOfficeModal.OfficeName}</p>
            )
        } else {
            return (
                <p className="heading mb-3">Add New Office</p>
            )
        }
    }
    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup = ""
    if (del) {
        DeletePopup = <button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Office} ID={OfficeID} onClose={onClose} />
    }
    //////////
    return (
        <>
            {ConfirmDelete}
            <Modal show={isOpenOffice} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_new_office modal-lg">
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={() => onClose(newOfficeSaved)}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Group-482400 fs-20"></i>
                            </span>
                            <p class="heading mt-2 mb-3"> {SetOfficeName()}</p>
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}

                    <div className="d-flex fa-layout h-100">
                        <div className="col-md-5">
                            <div className="pe-4">
                                <div className={officeValidationModal.OfficeNameVal ? "form-group input error" : "form-group input"}>
                                    <label>Office Name<span className='req'>*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="OfficeName"
                                        id="OfficeName"
                                        maxLength={50}
                                        value={mainOfficeModal.OfficeName}
                                        onChange={handleOfficeChange}
                                    />
                                    {officeValidationModal.OfficeNameVal}
                                </div>
                                {/* ///////// */}
                                {/* <div className='form-group input'>
                                <label htmlFor="EstablishedDate">Established Date</label>
                                    <input  
                                        className='form-control'
                                        value={mainOfficeModal.EstablishedDate}
                                        onFocus={() => setShowCalendar({EstablishedDate: true})}
                                    />
                                    <Calendar
                                        className={showCalendar.EstablishedDate ? "" : 'hide'}
                                        onChange={ date => handleCalendarChange(format(date, 'yyyy/MM/dd'),'EstablishedDate')}
                                    />
                            </div> */}
                                <div className='form-group input'>
                                    <DatePicker
                                        label='Established Date'
                                        maxDate={new Date()}
                                        value={mainOfficeModal.EstablishedDate}
                                        openCalendarMenufunc={(event) => openCalendarMenu(event, 'EstablishedDate')}
                                        anchorEl={establishedDate}
                                        open={openEstablishedDate}
                                        closeCalendarMenufunc={closeCalendarMenu}
                                        handleCalendarChangefunc={date => handleCalendarChange(date, 'EstablishedDate')}
                                        placeholder={true}
                                    />
                                </div>
                                <div className={officeValidationModal.ShiftIDVal ? "form-group input error" : "form-group input"}>
                                    <label>Shift<span className="req">*</span></label>
                                    {/* <div className='input-group'> */}
                                    <Autocomplete
                                        multiple
                                        limitTags={2}
                                        id='ShiftIDs'
                                        name='ShiftIDs'
                                        value={shiftValue}
                                        options={dropDownDataShift}
                                        isOptionEqualToValue={(option, value) => option.ShiftID === value.ShiftID}
                                        onChange={(event, value, reason) => handleMultiSelectChange(value, 'ShiftIDs', reason)}
                                        getOptionLabel={(option) => option.ShiftName}
                                        noOptionsText={"Not Available"}
                                        renderInput={(params) => <TextField {...params} variant="standard" />}
                                    />
                                    {/* </div> */}
                                    {officeValidationModal.ShiftIDVal}
                                </div>
                                {/* <div className={officeValidationModal.ShiftIDval? "form-group input error":"form-group input"}>
                                <label>Shift<span className='req'>*</span></label>
                                <select className="form-control"
                                    name="ShiftID"
                                    id="ShiftID"
                                    aria-label="Default select example"
                                    value={mainOfficeModal.ShiftID}
                                    onChange={handleOfficeChange}
                                >
                                <option value="">Select</option>
                                    {dropDownDataShift.map((e, index) => (
                                        <option value={e.ShiftID} key={index}>
                                            {e.ShiftName}
                                        </option>
                                    ))}
                                </select>
                                {officeValidationModal.ShiftIDval}
                            </div> */}
                            </div>
                        </div>
                        <div className="col-md-7 br-left-1 clr_light">
                            <div className="app-accordian">
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <p className="heading">Address Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row row-gap-15 row-half'>
                                            <div className="col-md-12">
                                                <div className='input'>
                                                    <label htmlFor="">Address Information</label>
                                                    <input type="text" className='form-control'
                                                        name="Address"
                                                        id="Address"
                                                        maxLength={250}
                                                        value={mainOfficeModal.Address}
                                                        onChange={handleOfficeChange} />
                                                </div>
                                            </div>

                                            <CountriesStates mainEmployeeModal={mainOfficeModal} handleEmployeeChange={handleOfficeChange} columnLength={'col-md-6'} />
                                            <div className="col-md-6">
                                                <div className='input'>
                                                    <label htmlFor="">Postal Code</label>
                                                    <input type="text" className='form-control'
                                                        maxLength={9}
                                                        name="PostalCode"
                                                        id="PostalCode"
                                                        value={mainOfficeModal.PostalCode}
                                                        onChange={handleOfficeChange}
                                                        onKeyPress={(event) => handleNumericCheck(event)}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                            <div className='input'>
                                                <label htmlFor="">City</label>
                                                <input type="text" className='form-control'
                                                    name="City"
                                                    id="City"
                                                    maxLength={50}
                                                    value={mainOfficeModal.City}
                                                    onChange={handleOfficeChange}
                                                    />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='input'>
                                                <label htmlFor="">Province</label>
                                                <input type="text" className='form-control'
                                                    name="Province"
                                                    id="Province"
                                                    maxLength={50}
                                                    value={mainOfficeModal.Province}
                                                    onChange={handleOfficeChange}   />
                                            </div>
                                        </div>
                                        */}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <p className="heading">Contact Information</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row row-gap-15 row-half'>
                                            <div className="col-md-6">
                                                <div className='input'>
                                                    <label htmlFor="">Name</label>
                                                    <input type="text" className='form-control'
                                                        name="ContactPersonName"
                                                        id="ContactPersonName"
                                                        maxLength={50}
                                                        value={mainOfficeModal.ContactPersonName}
                                                        onChange={handleOfficeChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='input'>
                                                    <label htmlFor="">Contact #</label>
                                                    <input type="text" className='form-control'
                                                        maxLength={15}
                                                        name="PhoneNumber"
                                                        id="PhoneNumber"
                                                        value={mainOfficeModal.PhoneNumber}
                                                        onChange={handleOfficeChange}
                                                        onKeyPress={(event) => handleNumericCheck(event)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={officeValidationModal.OfficeEmailVal ? "form-group input error" : "form-group input"}>
                                                    <label htmlFor="">Email Address</label>
                                                    <input type="text" className='form-control'
                                                        name="Email"
                                                        id="Email"
                                                        maxLength={50}
                                                        value={mainOfficeModal.Email}
                                                        onChange={handleOfficeChange}
                                                    />
                                                    {officeValidationModal.OfficeEmailVal}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={officeValidationModal.OfficeWebsiteVal ? "form-group input error" : "form-group input"}>
                                                    <label htmlFor="">Website</label>
                                                    <input type="text" className='form-control'
                                                        name="Website"
                                                        id="Website"
                                                        value={mainOfficeModal.Website}
                                                        onChange={handleOfficeChange} />
                                                    {officeValidationModal.OfficeWebsiteVal}
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveOffice} />
                {/* <div className='btns-group text-end br-top-1px br-clr'>
                <button className='btn btn-gray' onClick={() => onClose(newOfficeSaved)}>Cancel</button>
                <button className='btn btn-orange' onClick={handleSaveOffice}>Save</button>
            </div> */}
            </Modal>
        </>
    )
}

export default AddNewOffice