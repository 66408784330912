import EmployeeTopMenu from "./EmployeeLayout/EmployeeTopMenu"
import React from "react"
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { useDispatch } from "react-redux";
import { EmployeeRecordAction, EmployeeActiveTab } from "../../actions/EmployeeRecordAction";

      
const EmployeeTab = (props) => {
    let dispatch = useDispatch();
    dispatch(EmployeeActiveTab(props.Active))
    return (
        <div>
                <EmployeeTopMenu/>
        </div>
    )
}
export default EmployeeTab