import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ScreenFooter from '../../../MenuLayout/ScreenFooter';


const RolesAndRights = () => {
  return (

    <div className="ar_roles_screen">
      <div className="ar_content d-flex">
        <Tab.Container defaultActiveKey="superAdmin">
          <div className='col-md-2 clr_gry br-right-1'>
            <div className="d-flex mt-3 pt-1" >
              <Nav variant="tabs" className="d-flex flex-column border-0 gx-0  gap-25 w-100">
                <Nav.Item>
                  <Nav.Link eventKey="superAdmin" className="text font-m fs-14 pb-2 ">Super Admin</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="admin" className="text font-m fs-14 pb-2">Admin</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="user" className="text font-m fs-14 pb-2">User</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className='col-md-10 h-100'>
            <Tab.Content>
              <Tab.Pane eventKey="superAdmin">
                <div className='d-flex h-100'>
                  <div className='mx-2 h-100 align-items-center d-flex'>
                    <i className='icon-roles-swap fs-20'></i>
                  </div>
                  <div className='w-100 pe-2 me-1 mt-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <p className='heading fs-14 clr_gry'>Assigned Roles</p>
                      <button className='btn btn-blue rounded'>Create Role</button>
                    </div>
                    <div className='roles-content'>
                      <div className='row m-0 pb-3'>
                        <div className='col-md-4'>
                          <div className="d-flex align-items-center gap-1">
                            <div className="hk-table-input custom_check_box">
                              <input type="checkbox" className='mt-0' />
                            </div>
                            <p className='heading fs-13'>Select All</p>
                          </div>
                        </div>
                        <div className='col-md-8 d-flex'>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>View</p>
                          </div>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>Add/Edit</p>
                          </div>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>Delete</p>
                          </div>
                        </div>
                      </div>
                      <p className='heading  clr_gry mb-3 pb-1 ps-2 ms-1'> Employees</p>
                      <div className='d-flex flex-column gap-2'>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employees</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Dashboard</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employees</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employee’s Attendance</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Daily Tracking</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Payroll</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>All Requests</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Leaves</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Expenses</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>My Info</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Setup</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="admin">
                <div className='d-flex h-100'>
                  <div className='mx-2 h-100 align-items-center d-flex'>
                    <i className='icon-roles-swap fs-20'></i>
                  </div>
                  <div className='w-100 pe-2 me-1 mt-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <p className='heading fs-14 clr_gry'>Assigned Roles</p>
                      <button className='btn btn-blue rounded'>Create Role</button>
                    </div>
                    <div className='roles-content'>
                      <div className='row m-0 pb-3'>
                        <div className='col-md-4'>
                          <div className="d-flex align-items-center gap-1">
                            <div className="hk-table-input custom_check_box">
                              <input type="checkbox" className='mt-0' />
                            </div>
                            <p className='heading fs-13'>Select All</p>
                          </div>
                        </div>
                        <div className='col-md-8 d-flex'>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>View</p>
                          </div>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>Add/Edit</p>
                          </div>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>Delete</p>
                          </div>
                        </div>
                      </div>
                      <p className='heading  clr_gry mb-3 pb-1 ps-2 ms-1'> Employees</p>
                      <div className='d-flex flex-column gap-2'>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employees</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Dashboard</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employees</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employee’s Attendance</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Daily Tracking</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Payroll</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>All Requests</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Leaves</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Expenses</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>My Info</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Setup</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="user">
                <div className='d-flex h-100'>
                  <div className='mx-2 h-100 align-items-center d-flex'>
                    <i className='icon-roles-swap fs-20'></i>
                  </div>
                  <div className='w-100 pe-2 me-1 mt-2'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <p className='heading fs-14 clr_gry'>Assigned Roles</p>
                      <button className='btn btn-blue rounded'>Create Role</button>
                    </div>
                    <div className='roles-content'>
                      <div className='row m-0 pb-3'>
                        <div className='col-md-4'>
                          <div className="d-flex align-items-center gap-1">
                            <div className="hk-table-input custom_check_box">
                              <input type="checkbox" className='mt-0' />
                            </div>
                            <p className='heading fs-13'>Select All</p>
                          </div>
                        </div>
                        <div className='col-md-8 d-flex'>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>View</p>
                          </div>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>Add/Edit</p>
                          </div>
                          <div className='col-md-4 text-center'>
                            <p className='heading fs-13'>Delete</p>
                          </div>
                        </div>
                      </div>
                      <p className='heading  clr_gry mb-3 pb-1 ps-2 ms-1'> Employees</p>
                      <div className='d-flex flex-column gap-2'>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employees</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Dashboard</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employees</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Employee’s Attendance</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Daily Tracking</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Payroll</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>All Requests</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Leaves</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Expenses</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>My Info</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row m-0'>
                          <div className='col-md-4'>
                            <p className='text clr_gry'>Setup</p>
                          </div>
                          <div className='col-md-8 d-flex'>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                            <div className='col-md-4 text-center'>
                              <div className="hk-table-input custom_check_box">
                                <input type="checkbox" className='mt-0' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>

        </Tab.Container>
      </div>
      <div className='br-bottom-1 clr_light screen_footer br-top-1 btns-group d-flex gap-3 justify-content-end px-2 py-1 position-static'>
        <div className="btns-group d-flex justify-content-center align-items-center">
          <button className="btn btn-gray">
            Cancel
          </button>

          <button className="btn btn-orange">
            Save
          </button>
        </div>
      </div>
    </div >

  )
}

export default RolesAndRights
