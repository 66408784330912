import AddAdvancePolicy from "./AddAdvancePolicy/AddAdvancePolicy"
import AddLeaveType from "./AddLeaveType/AddLeaveType"
import AddPolicy from "./AddPolicy/AddPolicy"

const MenuList = () => {
    return [

        {
            name: 'Add Leave Type',
            key: 1,
            icon: "",
            component: <AddLeaveType />,
            right: true,
        },
        {
            name: 'Set Policy',
            key: 2,
            icon: "",
            component: <AddPolicy />,
            right: true,
        },
        {
            name: 'Advance',
            key: 3,
            icon: "",
            component: <AddAdvancePolicy />,
            right: true,
        },

    ]
}

export default MenuList