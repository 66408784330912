import React, { useEffect, useState } from 'react'
import MenuList from './RequestMenu'
import { TabContent, TabPane } from 'reactstrap'

const RequestTopMenuContent = (props) => {
    const { activeTab, dynamicTab } = { ...props }
    const MenuItems = MenuList()
    const activeComponent = MenuItems?.filter((tab) => tab.right === true)[0]
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        setTabs(dynamicTab)
    }, [activeTab])
    return (
        <div>
            {tabs?.length > 0 ?
                <TabContent activeTab={activeTab} className="content">
                    {
                        tabs?.map((tab) => {
                            return (
                                <React.Fragment>
                                    <TabPane tabId={tab?.key}>
                                        {tab?.component}
                                    </TabPane>
                                </React.Fragment>
                            )
                        })

                    }
                </TabContent>
                :
                <React.Fragment key={activeComponent?.key}>
                    <TabContent activeTab={activeComponent?.key} className="content">
                        <TabPane tabId={activeComponent?.key}>
                            {activeComponent?.component}
                        </TabPane>
                    </TabContent>
                </React.Fragment>
            }
        </div>
    )
}

export default RequestTopMenuContent
