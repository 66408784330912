import { Component } from "react";
import MyLeaveRequests from "../MyLeaveRequests";
import MyOtherRequests from "../MyOtherRequests";

export default function MenuList(){
    return [
        {
            key:1,
            Name:'Leave Requests',
            right: true,
            component:<MyLeaveRequests/>

        },
        {
            key:2,
            Name:'Other Requests',
            right: true,
            component:<MyOtherRequests/>

        },
    ]
}