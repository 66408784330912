import Expense from "../Account/Expenses/ExpenseTable"
import ExpenseCategory from "../Account/ExpenseCategory/ExpenseCategory"

const ExpenseMenu = () => {
    return [
        {
            name: 'Expense',
            key: 31,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Expenses',
                    key: 311,
                    icon: "",
                    component: <Expense />,
                    right: true,
                },
                {
                    name: 'Expense Type',
                    key: 312,
                    icon: "",
                    component: <ExpenseCategory />,
                    right: true,
                },
            ]
        },
        // {
        //     name: 'Expenses',
        //     key: 31,
        //     icon: "",
        //     component: <Expense />,
        //     right: true,
        //     // subCategoryItems: [
        //     //     {
        //     //         name: 'Expenses',
        //     //         key: 311,
        //     //         icon: "",
        //     //         component: <Expense />,
        //     //         right: true,
        //     //     },
        //     //     {
        //     //         name: 'Expense Type',
        //     //         key: 312,
        //     //         icon: "",
        //     //         component: <ExpenseCategory />,
        //     //         right: true,
        //     //     },
        //     // ]
        // },
        // {
        //     name: 'Expense Type',
        //     key: 312,
        //     icon: "",
        //     component: <ExpenseCategory />,
        //     right: true,
        // },
    ]
}


export default ExpenseMenu