import React, { useEffect } from "react"
import { useState } from "react"
import Tab from 'react-bootstrap/Tab';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import AttendanceSearch from "./AttendanceSearch"
import { connect } from "react-redux";
// import AttendanceTab from './AttendanceTab'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TabActionAttendance } from "../../actions/TabActions";
import EmployeeTab from "../Employee/EmployeeTab";
import MyAttendance from "../MyAttendance/MyAttendance";
// import handleCopyText from "../../shared/CopyData";

const EmployeeAttendance = (props) => {
    // console.log("OPEN_ATTENDANCE_TAB",useSelector(state => state))
    // let AttendanceType = useSelector(state => state.OPEN_ATTENDANCE_TAB.find((pt) => pt.active === true));
    // if (AttendanceType && AttendanceType.data) {
    //     AttendanceType = AttendanceType.data.visitType;
    // }
    // let TotalEmployee = useSelector(state => state.TotalEmployeesReducer);
    // let dispatch = useDispatch();
    // const [filesView, setFilesView] = useState(false)
    // const [recordsNum, setRecordsNum] = useState(false)
    const [tabList, setTabsList] = useState([{
        active: true,
        key: 2,
        name: "Attendance",
        data: {
            name: "All Attendance",
            id: 0,
            // time: new Date(),
            // visitType: ClaimType,
        },
    },])

    const [activeTab, setActiveTab] = useState(0)
    // useEffect(() => {
    //     if (props.OpenDynamicTab && props.OpenDynamicTab.length > 0)
    //         AddDynamicTabData(props)
    //     else {
    //         setTabsList([{
    //             active: true,
    //             key: 2,
    //             name: "Attendance",
    //             data: {
    //                 name: "All Attendance",
    //                 id: 0,
    //                 // time: new Date()
    //             },
    //         },])
    //         setActiveTab(0)
    //     }

    // }, [props.OpenDynamicTab])
    useEffect(() => {
        if (props.OpenDynamicTab && props.OpenDynamicTab.length > 0)
            AddDynamicTabData(props)

    }, [props.OpenDynamicTab])

    // const AddDynamicTabData = (data) => {
    //     let tabDataFromRedux = data.OpenDynamicTab.length > 0 ? data.OpenDynamicTab : [];
    //     const DataFromRedux = tabDataFromRedux.filter((ele) => { return ele.key === 2; });   // fetch only whose id is 2

    //     const activeElements = DataFromRedux.filter((row) => row.active === true); // get active 
    //     if (activeElements.length > 0 && tabList.length === 1) {
    //         let tabsData = [...tabList]
    //         tabsData = tabsData.concat(...DataFromRedux)
    //         setTabsList(tabsData)
    //         setActiveTab(activeElements[0].data.id)
    //     } else {
    //         let firstAttendanceTab = tabList.filter((el) => el.data.id === 0);
    //         let tabs = [...firstAttendanceTab]
    //         // check if all pat exist in redux 
    //         const isExist = tabDataFromRedux.some((ele) => { return ele.key === 2 && ele.data.id === 0; });
    //         if (isExist) {
    //             setTabsList(DataFromRedux)
    //         } else {
    //             tabs = tabs.concat(...DataFromRedux)
    //             setTabsList(tabs)
    //         }
    //         setActiveTab(activeElements[0].data.id)
    //     }
    // }
    const AddDynamicTabData = (data) => {
        let tabDataFromRedux = data.OpenDynamicTab.length > 0 ? data.OpenDynamicTab : [];
        //Adding New Employee in tab////

        // for (var i in tabDataFromRedux) {
        //     console.log("TABDATAFROMREDUX",tabDataFromRedux)
        //     if (tabDataFromRedux[i]?.data?.id === 0.1 && tabDataFromRedux[i]?.active === true && NewEmployee !== 0) {
        //         tabDataFromRedux[i].data = NewEmployee;
        //         setIsOpenEmployee(false)
        //         dispatch(NewEmployeeData(0))
        //     }
        // }
        ///////////////////////////////
        const DataFromRedux = tabDataFromRedux.filter((ele) => { return ele.key === 2; });   // fetch only whose id is 2
        const activeElements = DataFromRedux.filter((row) => row.active === true); // get active 
        if (activeElements.length > 0 && tabList.length === 1) {
            let tabsData = [...tabList]
            tabsData = tabsData.concat(...DataFromRedux)
            setTabsList(tabsData)
            setActiveTab(activeElements[0].data.id)
        } else {
            let firstEmployeeTab = tabList.filter((el) => el.data.id === 0);
            let tabs = [...firstEmployeeTab]
            tabs = tabs.concat(...DataFromRedux)
            setTabsList(tabs)
            setActiveTab(activeElements[0].data.id)
        }
    }
    // active tab 
    const handleActiveTab = (item) => {
        const { data } = { ...item }
        if (activeTab !== data?.id) {
            setActiveTab(data?.id)
        }

        // if (item.data.id !== 0) {
        //     let obj = {
        //         key: 2,
        //         name: "Attendance",
        //         active: true,
        //         data: {
        //             id: data?.id,
        //             name: data?.name,
        //             time: new Date(),
        //             type: data?.type,
        //         },
        //     };
        //     dispatch(TabActionAttendance(obj));
        // }
    }
    // handle to close tab
    const handleCloseTab = async (id) => {

        // setIsOpenEmployee(false);
        let index = tabList.findIndex((el) => el.data.id === id);
        tabList.splice(index, 1);
        let activTab =
            tabList.length > 0
                ? tabList[tabList.length - 1].data.id
                : 0;
        setTabsList(prev=> tabList)
        setActiveTab(activTab)
        props.TabActionAttendance({}, id);
    };
    
    const handleCopyText = (value) => {
        navigator.clipboard.writeText(value);
    };

    return (
        <div className='app-tabs fix-tabs-v2'>
            <Tab.Container>
                <div className='pt-1 ps-3 employee_header atendance_employee_header'>
                    <Nav variant="tabs" className="" >
                        {tabList && tabList.map((item) => {
                            return ( 
                                <React.Fragment key={item.data.id}>
                                    <NavItem>
                                        <NavLink
                                            // href="#"
                                            className={activeTab === item.data.id ? "active" : ""}
                                            onClick={() => {
                                                handleActiveTab(item);
                                                handleCopyText(`${item.data.name}`)
                                            }}
                                        >
                                        <div className="heading sm">{item.data.name}</div>
                                        {/* {item.data.name === "All Attendance" ? <span className="counter text-light heading">{recordsNum}</span> : <></>} */}
                                        {item.data.id > 0 ? (
                                            <span onClick={(e) => {handleCloseTab(item.data.id);e.stopPropagation();}} className={activeTab === item.data.id ? "cross" : "cross"}>
                                                <i className="icon-Icon-metro-cross"></i>
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        </NavLink>
                                    </NavItem>
                                </React.Fragment>
                            )
                        })}
                    <>
                    {/* {activeTab === 0 &&
                        <div className=" d-flex me-2 ar-toggle align-items-center">
                            <p className="heading me-4">Files View  </p>
                                <div class="pe-0 m-0 insurance_radio_btn mb-1">
                                    <div class="form-check form-switch radio_button">
                                        <input class="form-check-input position-absolute" type="checkbox" id="filesView" name="filesView" autocomplete="off" checked={filesView} onChange={(e)=>setFilesView(e.target.checked)}/>
                                    </div>
                                </div>
                        </div>} */}
                    </>
                    </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                    {tabList &&
                        tabList.filter((el) => el.data.id === 0).map((item) => {
                            return (
                                <React.Fragment key={item.data.id}>
                                    <TabPane tabId={item.data.id}>
                                        <AttendanceSearch/>
                                    </TabPane>
                                </React.Fragment>
                            )
                        })}
                    {tabList &&
                        tabList.filter((el) => el.data.id !== 0).map((item) => {
                            return (
                                <React.Fragment key={item.data.id}>
                                    <TabPane tabId={item.data.id}>
                                        <div className="">
                                        <MyAttendance Active={activeTab}/>
                                        {/* <EmployeeTab Active={activeTab} /> */}
                                        </div>
                                    </TabPane>
                                </React.Fragment>
                            )
                        })}
                </TabContent>
            </Tab.Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        OpenDynamicTab: state.OPEN_ATTENDANCE_TAB ? state.OPEN_ATTENDANCE_TAB : [],
    };
}

const action = {
    TabActionAttendance,
};
export default connect(mapStateToProps, action)(EmployeeAttendance)
