import React, { useEffect, useState } from "react";
import { NavItem, NavLink } from 'reactstrap'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from "react-redux";
import PayrollInnerLeftMenuContent from "./PayrollInnerLeftMenuContent";
import PayrollMenu from "./PayrollMenu";

const PayrollInnerLeftMenu = (props) => {

    const MenuItems = PayrollMenu();
    const [subCategItems, setSubCategItems] = useState([])
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState()
    let menuTab = useSelector(state => state.HandleMenuActiveTab);
    const [activeMenuTab, seActiveMenuTab] = useState(false)
    let ActiveEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    useEffect(() => {
        const selected_Item_List = MenuItems.filter((item) => item.key === props.subCategKey);
        if (selected_Item_List && selected_Item_List.length > 0) {
            let subCategories = selected_Item_List[0].subCategoryItems

            if (ActiveEmployee === 0.1) { setSubCategItems([subCategories[0]]) }
            else { setSubCategItems(subCategories) }

            setActiveTab(subCategories[0].key)
        }
        if (menuTab === 9) { // basicinfo hide
            seActiveMenuTab(true)
        } else {
            seActiveMenuTab(false)
        }
    }, [props.subCategKey, menuTab])

    return (

        <div className='app-tabs'>
            <Tab.Container defaultActiveKey="basicinformation">
                <PayrollInnerLeftMenuContent activeTab={activeTab} dynamicTabs={tabList} subCategItems={subCategItems} />
            </Tab.Container>
        </div>
    )

}

export default PayrollInnerLeftMenu
