import React, { useEffect, useState } from 'react'
import { OpenEmployeeAttendance } from '../../../../actions/OpenEmployeeAttendance';
import { TabAction } from '../../../../actions/HandleTabAction';
import { GoTo } from '../../../../actions/GotoPage';
import { connect, useDispatch } from 'react-redux';
import NoDataFound from './NoDataFound';
import CustomUseRef from '../../../shared/CustomUseRef/CustomUseRef';
import LetterProfilePicture from '../shared/LetterProfilePicture';
import { isFemale, isMale } from '../../../../utils/Constants';

const NewHirings = (props) => {
    let dispatch = useDispatch();
    const [showOptions, setShowOptions] = useState({})
    const OpenEmployee = (data) => {
        let obj = {
            key: 2,
            name: "Employee",
            active: true,
            data: {
                "id": data.EmployeeID,
                "FirstName": data.EmployeeName
            },
        };
        props.TabAction(obj);
        dispatch(GoTo(21));
        setShowOptions(prev => ({...prev , [data.EmployeeID]:false}))
    }
    const handleMenu = (e,emp) =>{
        e.stopPropagation();
        setShowOptions(prev => ({[emp.EmployeeID]:!prev[emp.EmployeeID]}));
    }
    const handleDefaultView = () => {
        setShowOptions({})
    }
    return (
        <div className="new_hiring card_tabs">
            <div className='d-flex justify-content-between align-items-center pe-2 me-1 mb-2'>
                <p className='heading pb-2'>New Hirings</p>
                <div class="input">
                    <select class="form-control" id="RequestsPeriod" name="RequestsPeriod" value={props?.filter} onChange={(e)=>props?.setFilter(e.target.value)}>
                        <option value="Week">This Week</option>
                        <option value="Month">This Month</option>
                    </select>
                </div>
            </div>
            <div className='d-flex flex-column row-gap-10 content ar_hov-scrollbar '>
                {props?.data?.length  > 0 ?
                    props?.data?.map(emp => (
                        <div className='hired-box d-flex align-items-center justify-content-between '>
                            <div className='d-flex gap-1 align-items-center'>
                                <div className="img-container circle border-2 border-white s-50">
                                    {emp.ProfilePicture ? (
                                    <img src={emp.ProfilePicture} alt="Profile" />
                                    ) : (
                                    <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-20 text-white ${isMale(emp.Gender) ? 'male' : isFemale(emp.Gender) ? 'female':'rather-undefined'}`} name={emp.EmployeeName} />
                                    )}
                                </div>
                                <div>
                                    <p className='heading fs-11 font-m'>{emp.EmployeeName}</p>
                                    <p className='text fs-10 validation_message' title={emp.DesignationName}>{emp.DesignationName}</p>
                                </div>
                            </div>
                            <p className="icon mb-0 position-relative">
                                <i className="icon-menuicon cursor-pointer " onClick={(e) => handleMenu(e,emp)}></i>
                                {showOptions[emp.EmployeeID] &&
                                <CustomUseRef IsActivePtPayments={showOptions[emp.EmployeeID]} onClose={()=>handleDefaultView()} >
                                    <div className="actions_popup cursor-pointer">
                                        <p className="text  p-2 pt-1 pb-0 text-nowrap" onClick={()=>{OpenEmployee(emp)}}><i className="icon-Group-482282 me-1 fs-10"></i>View Profile</p>
                                        {/* <p className="text p-2 pt-1 pb-0 mt-1" onClick={() => handleDeleteModal(items?.EmployeeID)}><i className="icon-Group-3994 fs-10 me-1"></i>Delete</p> */}
                                    </div>
                                </CustomUseRef>
                                }
                            </p>
                        </div>
                    )) : <div className='position-relative' style={{height:260}}><NoDataFound/></div>}
            </div>
        </div>
    )
}
function mapStateToPropss(state) {
    return {
    };
}

const action = {
    TabAction,
};

export default connect(mapStateToPropss, action)(NewHirings);