import React, { useEffect } from "react";
import { useReducer, useState } from "react"
import userimage from "../../assetsOld/img/user_img.jpg"
import sun from "../../assetsOld/img/sun.svg"
import { GoTo } from "../../actions/GotoPage";
import { UserLogout } from "../../actions/UserLogout";
import { connect, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import NotificationHandler from "../../utils/NotificationHandler";
import { enumUtil } from "../../utils/Enum";
import { useNavigate } from "react-router-dom";
import { ServerRequestProtected } from "../../utils/ServerDataRequest";
import jwt_decode from 'jwt-decode';
import CustomUseRef from "../shared/CustomUseRef/CustomUseRef";
import { TabAction } from "../../actions/HandleTabAction";

const TopNavBar = (props) => {

    let TopNavBar_Modal = {
        ProfilePicture: "",
        Username: "",
        Role: "",
        FirstName: "",
        LastName: "",
        HiringDateFormate: "",
        DateOfBirthFormate: "",
        Age: "",
        JobTitle: "",
        Email: "",
        MobileNumber: "",
        id: "",
        MiddleName: "",
        AdditionalInfo: "",
        City: "",
        TeamID: "",
        DepartmentID: "",
        DesignationID: "",
        EmployeeID: "",
        Gender: "",
        MaritalStatus: "",
        CNIC: "",
        Address: "",
        OrganizationID: "",
        PostalCode: "",
        Province: "",
        JoiningDate: "",
        OrganizationName: "",
    }

    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let UserID = Token?.UserID;
    const API_URL = process.env.REACT_APP_API
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userprofile, setuserprofile] = useState(false);
    const [actionPopup, setActionPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [mainTopNavbarModal, setMainTopNavbarModal] = useState(TopNavBar_Modal);
    const [actionsbutton, setActionsbutton] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const OpenEmployee = (data) => {
        let obj = {
            key: 8,
            name: "MyInfo",
            active: true,
            data: {
                id: data?.EmployeeID,
                FirstName: data?.FirstName,
                // MiddleName: data?.MiddleName,
                // LastName: data?.LastName,
                // AdditionalInfo: data?.AdditionalInfo,
                // City: data?.City,
                // TeamID: data?.TeamID,
                // DepartmentID: data?.DepartmentID,
                // DesignationID: data?.DesignationID,
                // EmployeeID: data?.EmployeeID,
                // Gender: data?.Gender,
                // MaritalStatus: data?.MaritalStatus,
                // CNIC: data?.CNIC,
                // Address: data?.Address,
                // Email: data?.Email,
                // MobileNumber: data?.MobileNumber,
                // OrganizationID: data?.OrganizationID,
                // PostalCode: data?.PostalCode,
                // Province: data?.Province,
                // JoiningDate: data?.JoiningDate
            },
        };
        dispatch(GoTo(8));
        props.TabAction(obj,8);
    }

    const handleLogout = () => {
        navigate("../", { replace: true })
        NotificationHandler("Logged Out", enumUtil.enumtoaster.success)
        Cookies.remove("cookedToken")
        dispatch(UserLogout());
        dispatch(GoTo(''));
    }

    const UseData = (ID) => {
        setIsLoading(true)
        ServerRequestProtected(API_URL + "/FindByID", 'post', {
            "ID": ID,
            "TableName": enumUtil.enumAPITableName.Employee
        }).then((response) => {
            setMainTopNavbarModal(response)
            setIsLoading(false)
        }).catch((error) => {
            // NotificationHandler(error.message, enumUtil.enumtoaster.error)
            setIsLoading(false)
        })
    }

    const CloseUserProfilePopup = () => {
        setuserprofile(false);
        setActionPopup(false);
    }

    const handeUserProfilePopUp = (e) => {
        e.stopPropagation();
        setuserprofile(!userprofile);
    }
    const CloseActionPopup = () => {
        setActionPopup(false);
    }

    const handeActionPopUp = (e) => {
        e.stopPropagation();
        setActionPopup(!actionPopup);
    }

    useEffect(() => {
        Token.UserID && UseData(UserID)
    }, [UserID])

    return (
        <>
            <div className="header pe-0">
                <div className="row m-0 w-100 align-items-center">
                    <div className="col-md-6">
                        <p className="heading md">{mainTopNavbarModal?.OrganizationName}
                        </p>
                        {/* <p className="text">02:30 PM . 19 April 2021</p> */}
                    </div>
                    {/* <div className="col-md-4">
                        <div className="row m-0 align-items-center">
                            <div className="col-md-7">
                                <div class="form-group  m-0 input">
                                    <input type="text" minlength="11" class="rounded-2 form-control" name="CNIC" id="CNIC" placeholder="search" />
                                    <i className="icon-search-2"></i>
                                </div>
                            </div>
                            <div className="col-md-2 pt-1">
                                <i class="icon-Notifications"></i>
                            </div>
                            <div className="col-md-3">
                                <div className="mode">
                                    <img src={sun}></img>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-6 d-flex gap-15 align-items-center justify-content-end ar_top_navbar">
                        <div>
                            {/* <i className="icon-Group-icon fs-18" onClick={() => handleLogout()}></i> */}

                            {/* <div className="ar_box check_in d-flex justify-content-center align-items-center">
                                <i className="icon-check1"></i>
                            </div> */}
                            <div className="ar_box notification d-flex justify-content-center align-items-center" onClick={() => handleLogout()}>
                                <i className="icon-checkout"></i>
                            </div>
                        </div>
                        <div className="ar_box notification d-flex justify-content-center align-items-center">
                            <i className="icon-Group-482492 fs-20"></i>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="d-flex gap-2 align-items-center">
                                <div>
                                    <p className="text font-m dark-text">{`${mainTopNavbarModal?.FirstName}`}</p>
                                    <p className="text font-m fs-10 light-text">{`${mainTopNavbarModal?.JobTitle}`}</p>
                                </div>
                                <span className="user_image circle img-container">
                                    {mainTopNavbarModal?.ProfilePicture ?
                                        <img src={mainTopNavbarModal?.ProfilePicture ? mainTopNavbarModal?.ProfilePicture : userimage}></img>
                                        : <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-16 text-white ${mainTopNavbarModal?.Gender === 'M' ? 'male' : mainTopNavbarModal?.Gender === 'FM' ? 'female' : 'rather-undefined'}`}>{(mainTopNavbarModal?.FirstName && mainTopNavbarModal?.LastName && mainTopNavbarModal?.FirstName  + " " + mainTopNavbarModal?.LastName)?.split(/\s+/)?.map(item => item[0]?.toUpperCase())?.slice(0,2)?.join('')}</p>}
                                    {/* <span className="user_status"></span> */}
                                </span>

                            </div>
                            <i className="icon-down-direction ms-2 mt-4" onClick={(e) => handeUserProfilePopUp(e)}></i>
                        </div>

                    </div>
                </div>
            </div>
            <CustomUseRef onClose={CloseUserProfilePopup} IsActivePtPayments={userprofile}>
                {/* <div className="user_profile">
                    <div className="px-2 pb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="img-container circle border-2 border-white s-60">
                                <img src={mainTopNavbarModal?.ProfilePicture ? mainTopNavbarModal?.ProfilePicture : "https://www.w3schools.com/howto/img_avatar.png"}></img>
                            </div>
                            <div className="dot_icon position-relative">
                                <p className="icon">
                                    <i className="icon-menuicon cursor-pointer" onClick={(e) => handeActionPopUp(e)}></i>
                                </p>
                                <CustomUseRef onClose={CloseActionPopup} IsActivePtPayments={actionPopup}>
                                    <div className="actions_popup cursor-pointer">
                                        <p className="text  px-2 d-flex align-items-center mb-2" onClick={() => OpenEmployee(mainTopNavbarModal)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                                        <p className="text px-2 d-flex align-items-center mt-1" onClick={() => handleLogout()}><i className="icon-Group-icon fs-11 me-1"></i>Logout</p>
                                    </div>
                                </CustomUseRef>

                            </div>
                        </div>
                        <div>
                            <p className="heading fs-13">{`${mainTopNavbarModal?.FirstName} ${mainTopNavbarModal?.LastName}`}</p>
                            <p className="text clr_light font-m">{`${mainTopNavbarModal?.JobTitle}`}</p>
                        </div>
                    </div>
                    <div className="ar_content">
                        <div className="d-flex gap-22">
                            <div>
                                <p className="text clr_light font-m fs-11">Hire Date</p>
                                <p className="text fs-11">{`${mainTopNavbarModal?.HiringDateFormate}`}</p>
                            </div>
                            <div>
                                <p className="text clr_light font-m fs-11">Date of Birth</p>
                                <p className="text fs-11">{`${mainTopNavbarModal?.DateOfBirthFormate}`}</p>
                            </div>
                            <div>
                                <p className="text clr_light font-m fs-11">Age</p>
                                <p className="text fs-11">{`${mainTopNavbarModal?.Age}`}</p>
                            </div>
                        </div>
                        <div className="pt-3">
                            <p className="text d-flex align-items-center gap-6 mb-2"><i className="icon-Vector-1 fs-12"></i>{`${mainTopNavbarModal?.Email}`}</p>
                            <p className="text d-flex align-items-center  gap-6"><i className="icon-Vector-2 fs-16"></i>{`${mainTopNavbarModal?.MobileNumber}`}</p>
                        </div>
                    </div>
                </div> */}
                <div className="ar_user_profile">
                    <div className="text-end" onClick={CloseUserProfilePopup}>
                        <i className="icon-Vector7"></i>
                    </div>
                    <div>
                        <p className="heading fs-14 text-center">Hi, {`${mainTopNavbarModal?.FirstName} ${mainTopNavbarModal?.LastName}`}</p>
                    </div>
                    <div class="hk-imgHandler">
                        <div className="d-flex hk-imgHandler img-container justify-content-center mt-2 mx-auto s-85">
                            {mainTopNavbarModal?.ProfilePicture ?
                                <img src={mainTopNavbarModal?.ProfilePicture ? mainTopNavbarModal?.ProfilePicture : "https://www.w3schools.com/howto/img_avatar.png"} alt="" />
                                : <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-24 text-white ${mainTopNavbarModal?.Gender === 'M' ? 'male' : mainTopNavbarModal?.Gender === 'FM' ? 'female' : 'rather-undefined'}`}>{(mainTopNavbarModal?.FirstName && mainTopNavbarModal?.LastName && mainTopNavbarModal?.FirstName  + " " + mainTopNavbarModal?.LastName)?.split(/\s+/)?.map(item => item[0]?.toUpperCase())?.slice(0,2)?.join('')}</p>
                            }
                            {/* <input type="file"
                                id="ProfilePicture"
                                name="ProfilePicture"
                            /> */}
                        </div>
                    </div>
                    <div className="text-center" onClick={() => OpenEmployee(mainTopNavbarModal)}>
                        <button className="btn btn-blue my-2 px-2 py-1 rounded-3 d-flex align-items-center gap-1 mx-auto"><i className="icon-edit-pencil"></i>Edit Profile </button>
                    </div>
                    <div className="d-flex align-items-baseline gap-1">
                        <div className="bottom_border w-100"></div>
                        <p className="gry_heading xsm">or</p>
                        <div className="bottom_border w-100"></div>
                    </div>
                    <p className="text font-m clr-red text-center mt-1 cursor-pointer" onClick={() => handleLogout()}>Log Out</p>
                </div>
            </CustomUseRef>
        </>
    )
}

function mapStateToPropss(state) {
    return {
        OpenDynamicTab: state.OPEN_TAB ? state.OPEN_TAB : [],
    };
}

const action = {
    TabAction,
};

export default connect(mapStateToPropss, action)(TopNavBar);