import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { ServerRequestProtectedParentID } from "../../../utils/ServerDataRequest"
import NotificationHandler from "../../../utils/NotificationHandler"
import { enumUtil } from "../../../utils/Enum"
import Loader from "../../shared/Loader/Loader"
import { useState } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

const DenyConfirmation = (props) => {

    const { requestedID, type, setRequestedID, isOpenAlert, setIsOpenAlert, onCloseDelete, onClose, API_URL, LoadData, DeleteDucment } = { ...props }
    const [isLoading, setIsLoading] = useState(false)
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let UserID = Token.UserID;

    const handleDelete = () => {
       
        setIsLoading(true)
        let saveModal = {
            // "UserID": UserID,
            "ID": props.ID,
            "TableName": props.TableName,
            "Data": {
                ...props['LeaveStatus'],
            }
        }
        if (type) {
            saveModal = { ...saveModal, SearchParam: type }
        }
        ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
            // console.log("resposnse aya",response['value'])
            setIsLoading(false)
            if(props.LoadData()){ props.LoadData(); }
            NotificationHandler('Request Denied Successfully!', enumUtil.enumtoaster.success)
            onCloseDelete()
            props && props.setRequestedID && setRequestedID(!requestedID)
        }).catch((error) => {
            NotificationHandler(error.message, enumUtil.enumtoaster.error)
            setIsLoading(false)
            onClose()
        })

    }

    return (
        <div>
             <Modal show={isOpenAlert} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="aprroved_confirmation">
                <form action="" onSubmit={(e) => {
                    e.preventDefault()
                }}  >
                    <Modal.Body>
                        {isLoading ? <Loader /> : null}
                        <div className='d-flex align-items-center justify-content-between pb-2 mb-1'>
                            <p className='heading fs-13 w-100 text-center'>Leave Denial Confirmation</p>
                            <i className='icon-Icon-metro-cross fs-10' onClick={()=>onClose()}></i>
                        </div>
                        <div className='pb-2 mb-1'>
                            <p className="text fs-11">Are you sure you want to <span className='red_text'>deny</span> this leave Request?</p>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                        <p class="heading xsm cancel cursor-pointer"onClick={onClose}>Cancel</p>
                        <p class="heading xsm deny cursor-pointer" onClick={handleDelete}>Deny</p>
                    </div>
                    </Modal.Body>
            
                </form>

            </Modal>
        </div>
    )
}

export default DenyConfirmation
