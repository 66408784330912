import React from 'react'
import NoDataFound from './NoDataFound';
import LetterProfilePicture from '../shared/LetterProfilePicture';
import { isMale ,isFemale} from '../../../../utils/Constants';

const TeamMembers = ({data}) => {
    console.log("TeamMembers",data)
  return (<>
    <div className="col-md-7 ps-0">
        <div className="card_tabs ar_team_member overflow-hidden">
            <p className="heading pb-4">Team Members</p>
            <div className="d-flex align-items-center gap-2">
                {data ?
                data.map((member, index) => {
                    if(index < 2)
                    return(
                        <div className="ar_content" key = {member.EmployeeID}>
                            <div className="mx-2">
                                <div className="img-container circle border-2 s-65">
                                    {member.ProfilePicture ? (
                                    <img src={member.ProfilePicture} alt="Profile" />
                                    ) : (
                                    <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-16 text-white ${isMale(member.Gender) ? 'male' : isFemale(member.Gender)? 'female':'rather-undefined'}`} name={member.EmployeeName} />
                                    )}
                                </div>
                            </div>
                            <p className="heading">{member.EmployeeName}</p>
                            <p className="heading font-m fs-11 clr_light">{member.DesignationName}</p>
                        </div>
                    )
                }) : 
              <></>}
                {data?.length > 2 ? 
                <div className="cursor-pointer">
                    <p className="text font-m link-text">+{ parseInt(data?.length, 10) - 2}</p>
                    <p className="text font-m link-text">more</p>
                </div> : <></>}
            </div>
           {data?<></>: <div className='position-relative' style={{height:160}}><NoDataFound/></div>}
        </div>
    </div>
  </>
  )
}

export default TeamMembers