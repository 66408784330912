import React, { useState, useEffect } from "react"
import { Nav, NavItem, NavLink } from 'reactstrap'
import MenuItemsList from "./Menu"
import { useDispatch, useSelector } from "react-redux"
import { MainTabContentActiveTab, MainTabContentTabList } from "../../actions/MainTabContentActiveTab"
import { useNavigate } from "react-router-dom";
import { GoTo } from "../../actions/GotoPage";
import { UserLogout } from "../../actions/UserLogout";
import Cookies from "js-cookie";
import { SubRole } from "../../actions/Role"
// import Setup from "../Setup/Setup"
// // import Dashboard from "../Dashboard/Dashboard"
import jwt_decode from 'jwt-decode';
import { ServerRequestProtectedParentID } from "../../utils/ServerDataRequest"
import { enumUtil } from "../../utils/Enum"
import NotificationHandler from "../../utils/NotificationHandler"
import Loader from "../shared/Loader/Loader"
import { getExpirationDate } from "../../utils/Constants"
// import { Cookie } from "@mui/icons-material"


const SideBar = () => {
    ////Pass Role id in MenuList////
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const API_URL = process.env.REACT_APP_API
    let Role = useSelector((state) => state.RoleReducer)
    let Sub_Role = useSelector((state) => state.SubRoleReducer)
    let ID = useSelector(state => state.GoToReducer);
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    const MenuItems = MenuItemsList(Role)
    console.log("MenuItems", MenuItems, Role)
    const [toggle, setToggle] = useState(false);
    const [openSub, setOpenSub] = useState({})
    const handleOpenSubTab = (name) => {
        setOpenSub((prev) => ({ ...prev, [name]: !prev[name] }))
    }
    let SideBar_Modal = {
        ProfilePicture: "",
        Username: "",
        Role: "",
    }
    const [mainSidebarModal, setMainSidebarModal] = useState(SideBar_Modal)
    const [isLoading, setIsLoading] = useState(false)
    const UseData = (ID) => {
        setIsLoading(true)
        ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
            "ID": ID,
            "TableName": enumUtil.enumAPITableName.Employee
        }).then((response) => {
            setMainSidebarModal(response)
            setIsLoading(false)
            Cookies.set('time', response?.ActivityTime, { expires: getExpirationDate() })
        }).catch((error) => {
            // NotificationHandler(error.message, enumUtil.enumtoaster.error)
            setIsLoading(false)
        })
        ServerRequestProtectedParentID(API_URL + "/GetUserOrganizations", 'post', {
            "ID": ID,
            "TableName": enumUtil.enumAPITableName.UserOrganization
        }).then((response) => {
            dispatch(SubRole(response));
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)

        })
    }
    const handleLogout = () => {
        setActiveTab(12);
        navigate("../", { replace: true })
        NotificationHandler("Logged Out", enumUtil.enumtoaster.success)
        Cookies.remove("cookedToken")
        dispatch(UserLogout());
        dispatch(GoTo(''));
    }
    const handleGoToFirstSideTab = () => {
        let item = MenuItems?.[0];
        // MenuItems && MenuItems.map((item) => {
        //     if(item.name===name){
        handleActiveTab(item.key, item.name, item.component, item.right)
        //     }
        // })
    }
    const handleGoToActiveTab = (ID) => {
        MenuItems && MenuItems.map((item) => {
            if (ID && item.key === ID) {
                handleActiveTab(item.key, item.name, item.component, item.right)
                dispatch(GoTo(''))
            } else if (item.subComponents) {
                item.subComponents.map(it => {
                    if (ID && it.key === ID) {
                        handleActiveTab(it.key, it.name, it.component, it.right)
                        dispatch(GoTo(''))
                    }
                })
            }
        })
    }
    useEffect(() => {
        handleGoToFirstSideTab(); //Setting Tab to Dashboard on Reload
        // Token.UserID && UseData(Token.UserID)
    }, [Sub_Role])
    useEffect(() => {
        ID && handleGoToActiveTab(ID);
    }, [ID])
    useEffect(() => {
        Token.UserID && UseData(Token.UserID)
    }, [Token.UserID])
    function toggleSidebar() {
        var getScreenWidth = window.innerWidth;
        setToggle(prev => !prev);

        if (getScreenWidth > 992) {
            document.body.classList.toggle("sidebar-slim")
        }
        if (getScreenWidth > 600 && getScreenWidth < 992) {
            if (document.body.classList.contains("tablet")) {
                document.body.classList.toggle("sidebar-slim")
            }
        }
        if (getScreenWidth < 600) {
            if (document.body.classList.contains("mobile")) {
                document.body.classList.toggle("sidebar-mobile-open")
            }
        }
        setOpenSub(() => ({}))
    }
    ////////////////////////////////
    const activeTabElement = MenuItems.filter((item) => item?.right === true)[0]
    const [activeTab, setActiveTab] = useState(1)
    const [tabList, setTabsList] = useState([])

    const handleActiveTab = (key, name, component, right) => {
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                component: activeTabElement.component,
                right: activeTabElement.right,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            component: component,
            right: right,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        dispatch(MainTabContentTabList(dynamicTabs));
        if (activeTab !== key) {
            setActiveTab(key)
        }
        ///MainContentTab to active component Key///
        dispatch(MainTabContentActiveTab(key))
        ///////////////////////////////////////////
    }
    return (
        <React.Fragment>
            {isLoading ? <Loader /> : null}
            <nav className="sidebar">
                <div className="sidebar-header">
                    <div className="user-view text-center">
                        {console.log("object",mainSidebarModal?.FirstName  + " " + mainSidebarModal?.LastName)?.split(/\s+/)?.map(item => item[0]?.toUpperCase())?.slice(0,2)?.join('')}
                        <div className="img-container m-auto s-77 circle">
                            {mainSidebarModal?.ProfilePicture ?
                                <img src={mainSidebarModal?.ProfilePicture ? mainSidebarModal?.ProfilePicture : "https://www.w3schools.com/howto/img_avatar.png"} alt="" />
                                :
                                <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-24 text-white ${mainSidebarModal?.Gender === 'M' ? 'male' : mainSidebarModal?.Gender === 'FM' ? 'female' : 'rather-undefined'}`}>{mainSidebarModal?.FirstName && mainSidebarModal?.LastName && (mainSidebarModal?.FirstName  + " " + mainSidebarModal?.LastName)?.split(/\s+/)?.map(item => item[0]?.toUpperCase())?.slice(0,2)?.join('')}</p>
                            }
                        </div>
                        <div className="user-info mt-3">
                            <p className="heading mb-1 clr_white fs-16">{mainSidebarModal?.Username}</p>
                            <p className="heading sidebar_heading fs-13">{mainSidebarModal?.Role}</p>
                        </div>
                    </div>
                    <button className="toggle-sidebar" onClick={toggleSidebar}>
                        {toggle ? <i className="icon-right-direction1"></i> : <i className="icon-angle-left1 me-1"></i>}
                    </button>
                </div>
                <div className="menu-list-container">
                    <ul className="menu-lists top">
                        {MenuItems && MenuItems.map((item) => {
                            // if (item.key !== 12) {
                            if (item?.subComponents?.length > 0) {
                                return (
                                    <li className={`submenu ${openSub[item.name] ? 'open' : ''}`} key={item.key}>
                                        <a className="submenu-top-item" onClick={() => handleOpenSubTab(item.name)}>
                                            {/* {item.name === "Home" ?  */}
                                            <i className={item.icon} alt={item.name + "icon"} />
                                            {/* <img src={item.icon} alt={item.name + "icon"} />
                                        } */}
                                            <span className="nav-text">{item.name} </span>
                                            <i className={'angle-right-bottom bi bi-arrow-right-short'} />
                                            {/* <div className="dot" style={activeColorutilities}></div> */}
                                            {/* <i className="angle-right-bottom bi bi-arrow-right-short"></i> */}
                                        </a>
                                        <div className="sub-menu-list ar_menu_list ">
                                            <ul>
                                                {item.subComponents.map((subItems) => {
                                                    return (
                                                        <React.Fragment key={subItems.key}>
                                                            <NavItem onClick={() => handleActiveTab(subItems.key, subItems.name, subItems.component, subItems.right, new Date(), subItems.Background)}>
                                                                <NavLink href="#" className={`${activeTab === subItems.key ? "active" : ""} ${subItems.right === false ? 'sidebar-disableed' : ''}`} disabled={item.right}>
                                                                    {/* {item.name === "Billing" ? <img src={subItems.icon}></img> : subItems.icon} */}
                                                                    <div className="ar_dot"></div>
                                                                    <i className={subItems.icon} alt={subItems.name + "icon"} />
                                                                    {subItems.name}
                                                                    {/* {count[subItems.name] ? (<span className="count"> {count[subItems.name]}</span>) : ''} */}
                                                                </NavLink>
                                                            </NavItem>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </li>
                                )
                            } else {
                                return (
                                    <React.Fragment key={item.key}>
                                        <NavItem
                                            onClick={() => handleActiveTab(item.key, item.name, item.component, item.right)}>
                                            <NavLink href="#" className={activeTab === item.key ? "active" : ""} >
                                                {/* <img src={item.icon} alt={item.name + "icon"} /> */}
                                                <i className={item.icon}></i>
                                                <span className="nav-text">{item.name}</span>
                                            </NavLink>
                                        </NavItem>
                                    </React.Fragment>
                                )
                            }

                            // } 
                            // else {
                            //     return (
                            //         <React.Fragment key={item.key}>
                            //             <NavItem
                            //                 onClick={handleLogout}>
                            //                 <NavLink href="#" className={activeTab === item.key ? "active" : ""} >
                            //                     <i className={item.icon}></i>
                            //                     <span className="nav-text">{item.name}</span>
                            //                 </NavLink>
                            //             </NavItem>
                            //         </React.Fragment>)
                            // }
                        })}
                    </ul>
                    <ul className="menu-lists bottom">
                        <div className="mx-3 check_in ps-3 p-2">
                            <i className="icon-Icon-time"></i>
                            <p className="mt-1 heading clr_white">Check in </p>
                            <p className="heading xsm clr_gry">{Cookies.get("time") ? <span className="text-white">{Cookies.get("time")}</span> : "00:00:00"}</p>
                            <p className="text mt-2 clr_white"><i className="icon-Page-1 me-1 cursor-default"></i>Asghar Mall Road Scheme</p>
                        </div>
                    </ul>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default SideBar