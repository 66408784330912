import React, { useEffect, useReducer, useState } from "react";
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest";
import NotificationHandler from "../../../../utils/NotificationHandler";
import { enumUtil } from "../../../../utils/Enum";
import AddNewExpenseCategory from "./AddNewExpenseCategory";
import DataTableMUI from "../../../shared/DataTable/DataTableMUI";
import Loader from "../../../shared/Loader/Loader";
import MainTop from "../../../../utils/MainTop";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation";
import ReactDataGrid from "react-data-grid";
import ModalFooter from "../../../shared/ModalFooter/ModalFooter";
import CustomUseRef from "../../../shared/CustomUseRef/CustomUseRef";
import GridColumnHeader from "../../../shared/GridColumnHeader/GridColumnHeader";

const ExpenseCategory = () => {
  const API_URL = process.env.REACT_APP_API;
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [CategoryID, setCategoryID] = useState();
  const [searchParam, setSearchParam] = useState();
  const [EnterParam, setEnterParam] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [delID, setDelID] = useState(0);
  const [actionsbutton, setActionsbutton] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [gridHeader, handleGridHeader] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, { defaultData: '', activeSortIcon: '' })
  //For Pagination
  // const [PerPage, setPerPage] = useState(10);
  // const [PageNo, setPageNo] = useState(1);
  // const [TotalPages, setTotalPages] = useState();

  // const handlePageClick = (page) => { setPageNo(page); }
  // const PreviousPage = () => { setPageNo(PageNo - 1); }
  // const NextPage = () => { setPageNo(PageNo + 1); }
  // const handleFingByCreteria = (e) => {
  //     setPerPage(e.target.value)
  //     setPageNo(1)
  // }
  ////////////////////
  ///EmployeeDelete///
  const handleDeleteModal = (ID) => {
    setDelID(ID);
    setIsOpenAlert(true);
  };
  const onCloseDelete = () => {
    // setDateValidation({StartDate:'',EndDate:''})
    // setmainEducationModal(Education_Modal)
    setCategoryID(0);
    setIsOpenAlert(false);
  };
  const onClose = () => {
    setIsOpenAlert(false);
  };
  let ConfirmDelete = "";
  if (isOpenAlert) {
    ConfirmDelete = (
      <DeleteConfirmation
        isOpenAlert={isOpenAlert}
        setIsOpenAlert={setIsOpenAlert}
        onCloseDelete={onCloseDelete}
        API_URL={API_URL}
        TableName={enumUtil.enumAPITableName.ExpenseCategory}
        ID={delID}
        onClose={onClose}
      />
    );
  }

  const CloseActionPopup = () => {
    setActionsbutton(false);
  };
  const handeActionPopUp = (e, id) => {
    e.stopPropagation();
    setActionsbutton(true);
    setSelectedRow(id);
  };
  const columns = [
    {
      key: "CategoryName",
      name:(<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Category'} sortIcon={true} type={'string'} colKey={'CategoryName'} data={CategoryData} setData={setCategoryData} />),

      resizable: true,
      flex: 1,
      formatter: ({ row }) => {
        return (
          <div
            className=" heading text-primary font-m cursor-pointer"
            onClick={() => AddCategory(row?.ExpenseCategoryID)}
          >
            {row?.CategoryName}
          </div>
        );
      },
    },
    {
      key: "CategoryCode",
      name:(<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
        iconName={'icon-card'} headerName={'Category Code'} sortIcon={true} type={'number'} colKey={'CategoryCode'} data={CategoryData} setData={setCategoryData} />),

      resizable: true,
      flex: 1,
    },
    {
      key: "Actions",
      name: "Actions",
      // flex: 1,
      width:80,
      formatter: ({ row }) => {
        return (
          <>
            <div className="w-50">
              <issss
                className="icon-menuicon cursor-pointer"
                onClick={(e) => handeActionPopUp(e, row?.ExpenseCategoryID)}
              ></issss>
            </div>
            {selectedRow === row?.ExpenseCategoryID && (
              <CustomUseRef
                onClose={CloseActionPopup}
                IsActivePtPayments={actionsbutton}
              >
                <>
                  <div className="actions_popup cursor-pointer">
                    <p
                      className="text  p-2 py-0"
                      onClick={() => AddCategory(row?.ExpenseCategoryID)}
                    >
                      <i className="icon-edit-pencil me-1"></i>Edit
                    </p>
                    <p
                      className="text p-2 py-0"
                      onClick={() => handleDeleteModal(row?.ExpenseCategoryID)}
                    >
                      <i className="icon-Group-3994 me-1"></i>Delete
                    </p>
                  </div>
                </>
              </CustomUseRef>
            )}
          </>
        );
      },
    },
  ];

  ////////////////////
  const LoadData = (param) => {
    setCategoryData([]);
    setIsLoading(true);
    ServerRequestProtectedParentID(API_URL + "/FindBySingleCriteria", "post", {
      TableName: enumUtil.enumAPITableName.ExpenseCategory,
      SearchParam: param,
      PerPage: "100",
      PageNo: "1",
    })
      .then((response) => {
        setIsLoading(false);
        setCategoryData(response);
        handleGridHeader({defaultData: response})
        // setTotalPages(response[0].NumOfPages);
      })
      .catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
      });
  };
  const SearchOnEnter = (e) => {
    if (e.key === "Enter") {
      setEnterParam(e.target.value);
      setSearchParam(EnterParam);
      LoadData(searchParam);
    }
  };
  const handleChangeSearchParam = (e) => {
    setSearchParam(e.target.value);
  };

  useEffect(() => {
      LoadData();
  }, [isOpenAlert])

  const AddCategory = (ID) => {
    setIsOpenCategory(true);
    setCategoryID(ID);
  };

  const CloseCategory = () => {
    setIsOpenCategory(false);
    
  };
  const CloseCategoryOnSave = () => {
    setIsOpenCategory(false);
      LoadData();
  };

  let PopupScreen = "";
  if (isOpenCategory) {
    PopupScreen = (
      <AddNewExpenseCategory
        isOpenCategory={isOpenCategory}
        onClose={CloseCategory}
        onCloseSave={CloseCategoryOnSave}
        CategoryID={CategoryID}
        setIsRefresh={setIsRefresh}
      />
    );
  }

  return (
    <React.Fragment>
      {PopupScreen}
      {ConfirmDelete}
      {isLoading ? <Loader /> : null}
      <MainTop
        openModal={AddCategory}
        searchParam={searchParam}
        Change={handleChangeSearchParam}
        LoadData={LoadData}
        SearchOnEnter={SearchOnEnter}
      />
      <div className="tabs-expenses-content expense-nodata">
        <div>
          <div className="row m-0 row-half row-gap-15">
            <ReactDataGrid
              columns={columns}
              rows={CategoryData}
              rowHeight={30}
              className={
                "fill-grid DataGrid-Table hk-exp-table p-0 expense_grid overflowX-hidden"
              }
              style={{
                width: "100%",
                // height:'100px',
                maxHeight: "330px",
              }}
            />

            {/* {CategoryData.map((items) => {
                            return (<>
                                <div className="col-md-3">
                                    <div class="card card-gray-1 border-0">
                                        <div class="card-body">
                                            <div className='row row-gap-10'>
                                                <div className='d-flex gap-2 col-md-12'>
                                                    <div className='category-icon'>
                                                        <i class="bi bi-pencil-fill"></i>
                                                    </div>
                                                    <div class="">
                                                        <div class="text font-m clr-light2">Category Name</div>
                                                        <div class="text font-sb cat_name">{items.CategoryName}</div>
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-2 col-md-12'>
                                                    <div className='category-icon'>
                                                        <i class="bi bi-hash"></i>
                                                    </div>
                                                    <div class="">
                                                        <div class="text font-m clr-light2">Category Code</div>
                                                        <div class="text font-sb">{items.CategoryCode || "N/A"}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-end">
                                                    <div className="d-flex justify-content-end gap-1">
                                                        <button class="btn btn-icon-transparent clr-muted fs-18" onClick={() => handleDeleteModal(items.ExpenseCategoryID)}><i class="icon-delete"></i></button>
                                                        <button className="edit-icon" onClick={() => AddCategory(items.ExpenseCategoryID)}></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>);
                        })} */}
          </div>
          {isLoading ? null : CategoryData?.length === 0 ? (
            <div className="no_data">
              <span class="icon-ar-nodata">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
                <span class="path11"></span>
                <span class="path12"></span>
                <span class="path13"></span>
                <span class="path14"></span>
                <span class="path15"></span>
                <span class="path16"></span>
                <span class="path17"></span>
                <span class="path18"></span>
                <span class="path19"></span>
                <span class="path20"></span>
                <span class="path21"></span>
                <span class="path22"></span>
                <span class="path23"></span>
                <span class="path24"></span>
                <span class="path25"></span>
                <span class="path26"></span>
                <span class="path27"></span>
                <span class="path28"></span>
                <span class="path29"></span>
                <span class="path30"></span>
                <span class="path31"></span>
                <span class="path32"></span>
                <span class="path33"></span>
                <span class="path34"></span>
                <span class="path35"></span>
                <span class="path36"></span>
                <span class="path37"></span>
                <span class="path38"></span>
                <span class="path39"></span>
                <span class="path40"></span>
                <span class="path41"></span>
                <span class="path42"></span>
                <span class="path43"></span>
                <span class="path44"></span>
                <span class="path45"></span>
                <span class="path46"></span>
                <span class="path47"></span>
                <span class="path48"></span>
                <span class="path49"></span>
                <span class="path50"></span>
                <span class="path51"></span>
                <span class="path52"></span>
                <span class="path53"></span>
                <span class="path54"></span>
                <span class="path55"></span>
                <span class="path56"></span>
                <span class="path57"></span>
                <span class="path58"></span>
                <span class="path59"></span>
                <span class="path60"></span>
                <span class="path61"></span>
                <span class="path62"></span>
                <span class="path63"></span>
                <span class="path64"></span>
                <span class="path65"></span>
                <span class="path66"></span>
                <span class="path67"></span>
                <span class="path68"></span>
                <span class="path69"></span>
                <span class="path70"></span>
                <span class="path71"></span>
                <span class="path72"></span>
                <span class="path73"></span>
                <span class="path74"></span>
                <span class="path75"></span>
                <span class="path76"></span>
                <span class="path77"></span>
                <span class="path78"></span>
                <span class="path79"></span>
                <span class="path80"></span>
                <span class="path81"></span>
                <span class="path82"></span>
                <span class="path83"></span>
                <span class="path84"></span>
                <span class="path85"></span>
                <span class="path86"></span>
                <span class="path87"></span>
                <span class="path88"></span>
                <span class="path89"></span>
                <span class="path90"></span>
                <span class="path91"></span>
                <span class="path92"></span>
                <span class="path93"></span>
                <span class="path94"></span>
                <span class="path95"></span>
                <span class="path96"></span>
                <span class="path97"></span>
                <span class="path98"></span>
                <span class="path99"></span>
                <span class="path100"></span>
                <span class="path101"></span>
                <span class="path102"></span>
                <span class="path103"></span>
                <span class="path104"></span>
                <span class="path105"></span>
                <span class="path106"></span>
                <span class="path107"></span>
                <span class="path108"></span>
                <span class="path109"></span>
                <span class="path110"></span>
                <span class="path111"></span>
                <span class="path112"></span>
                <span class="path113"></span>
                <span class="path114"></span>
                <span class="path115"></span>
                <span class="path116"></span>
                <span class="path117"></span>
                <span class="path118"></span>
                <span class="path119"></span>
                <span class="path120"></span>
                <span class="path121"></span>
                <span class="path122"></span>
                <span class="path123"></span>
                <span class="path124"></span>
                <span class="path125"></span>
                <span class="path126"></span>
                <span class="path127"></span>
                <span class="path128"></span>
              </span>
              <p className="text fst-italic text-center font-m">
                No Data Found!
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpenseCategory;
