import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ModalBody, ModalFooter } from "reactstrap"
import { Nav, Tab, Tabs } from "react-bootstrap";
import EmployeeInformation from "../MyInfo/Account/EmployeeInformation/EmployeeInformation"
import Menu from "@mui/material/Menu";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import format from "date-fns/format";
// import AddLeaveForm from './AddLeaveForm';
import { ServerRequestProtected } from '../../utils/ServerDataRequest';
import { enumUtil } from '../../utils/Enum';
import jwt_decode from 'jwt-decode';
import NotificationHandler from '../../utils/NotificationHandler';
import DeleteConfirmation from "../shared/Delete/DeleteConfirmation"
import { useSelector } from 'react-redux';
import { DateFormat, DateFormat_YMd_String, DateFormat_dMy } from '../../utils/Constants';
import Loader from '../shared/Loader/Loader';
import ReactDataGrid from "react-data-grid";
import CustomUseRef from "../shared/CustomUseRef/CustomUseRef"
import AddLeaveForm from "../MyAttendance/AddLeaveForm"
import { ManageHistory } from '@mui/icons-material';
import MainTop from '../../utils/MainTop';
import ApproveConfirmation from '../shared/Approve/ApproveConfirmation';
import OtherRequest from './AllRequestComponents/OtherRequest';
import LeaveRequest from './AllRequestComponents/LeaveRequest';
import RequestTopMenu from './RequestLayout/RequestTopMenu';

export const AllRequests = () => {
    const API_URL_DASHBOARD = process.env.REACT_APP_API_Dashboard;

    const [isLoading, setIsLoading] = useState(false);


    const API_URL = process.env.REACT_APP_API_Dashboard

    const [approveID, setApproveID] = useState(null);
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [LeaveStatus, setLeaveStatus] = useState(false)
    const [adddelete2, setpopdelete1] = useState(false);

    const handleApproveModal = (ID) => {
        setApproveID(ID);
        setIsOpenApprove(true);
        setLeaveStatus("Approved");
    }

    const onCloseApprove = () => {
        setApproveID(0);
        setIsOpenApprove(false)
        // loadTimeAdjustmentRequests()
    }

    const onClose = () => {
        setIsOpenApprove(false)
    }

    let ApprovePopup = "";
    if (isOpenApprove) {
        ApprovePopup = <ApproveConfirmation isOpenAlert={isOpenApprove} onCloseDelete={onCloseApprove} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeTimeAdjustment} ID={approveID} onClose={onClose} />
    }


    return (
        <>
            <div className="mt-3">
                <RequestTopMenu />
                {/* <Tab.Container defaultActiveKey="MyRequests">
                    <div className='app-tabs'>
                        <div className='tabs-header position-relative mx-auto'>
                            <div className="row h-100 justify-content-center align-items-center">
                                <div className="tabs-v2-custom ar_tab-v2-custom">
                                    <Nav variant="tabs" className="rb-tabs-v3 tabs_view mb-2">
                                        <Nav.Item>
                                            <Nav.Link eventKey="MyRequests">Leave Requests</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="EmployeeRequests">Other Requests</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="EmployeeRequests">
                            <OtherRequest/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="MyRequests">
                            <LeaveRequest/>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container> */}
            </div>

        </>
    )
}
