import ExpenseTopMenu from "./ExpenseLayout/ExpenseTopMenu"

const Expense = () => {

    return (
        <div>
            <ExpenseTopMenu />
        </div>
    )
}
export default Expense