import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalFooter from '../shared/ModalFooter/ModalFooter'
import close from "../../assetsOld/img/x-close.svg"
import Loader from '../shared/Loader/Loader';
import format from 'date-fns/format';
import { DateFormat, DateFormatCalculations, DateFormat_YMd_Time, DateFormat_dMy } from "../../utils/Constants"
import jwt_decode from 'jwt-decode';
import FormValidator from '../../utils/FormValidator';
import { enumUtil } from '../../utils/Enum';
import NotificationHandler from '../../utils/NotificationHandler';
import { useSelector } from 'react-redux';
import { ServerRequestProtected, ServerRequestProtectedParentID } from '../../utils/ServerDataRequest';
import CalendarInput from "../../utils/CalendarInput"
import DatePicker from '../../utils/DatePicker';
import isNull from '../../utils/NullChecking';
import ModalFooterAdmin from '../shared/ModalFooterAdmin/ModalFooterAdmin';
import DenyConfirmation from '../shared/Deny/DenyConfirmation';
import ApproveConfirmation from '../shared/Approve/ApproveConfirmation';

const AddNewLeave = (props) => {
    const { isLeaveOpen, onClose, onCloseSave, ID, setIsRefresh, view, Status } = { ...props }
  
    let Leave_Modal = {
        Subject: "",
        LeavesTypesID: "",
        StartTime: "",
        EndTime: "",
        DateFrom: null,
        DateTo: null,
        Description: "",
        LeaveStatus: "",
        HalfDay: "",
        FullDay: "",
    }

    let valModal = {}
    const ValidationModal = {
        Subject: "",
        LeavesTypesID: "",
        Description: "",
        FullDay: "",
        HalfDay: "",
        StartTime: "",
        EndTime: "",
        StartDate: "",
        EndDate: "",
    }

    const DateValidationModal = {
        DateFrom: '',
        DateTo: '',
    }

    const [isLoading, setIsLoading] = useState(false)
    const [mainLeaveModal, setMainLeaveModal] = useState(Leave_Modal);
    const [leaveSaveModal, setLeaveSaveModal] = useState({});
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let EmployeeID = Token.UserID;
    const OrganizationID = useSelector((state) => state.Organization);
    // let API_URL = process.env.REACT_APP_Employee
    let API_URL = process.env.REACT_APP_API
    let API_URl = process.env.REACT_APP_API
    const [leaveValidationModal, setLeaveValidationModal] = useState(ValidationModal);
    const [leaveTypeDropDown, setLeaveTypeDropDown] = useState([])
    const [dateValidation, setDateValidation] = useState(DateValidationModal);
    const [EmployeeLeaveID, setEmployeeLeaveID] = useState(0);
    const [approveID, setApproveID] = useState(0);
    const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [denyID, setDenyID] = useState(null);
    const [isOpenDeny, setIsOpenDeny] = useState(false)
    const [LeaveStatus, setLeaveStatus] = useState({})
    const [request, setRequest] = useState()
    const [selectedOption, setSelectedOption] = useState({})
    const [RequestStatus, setRequestStatus] = useState()
    const [showCalendar, setShowCalendar] = useState({
        DateFrom: false,
        DateTo: false,
    });
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const openStartDate = Boolean(startDate)
    const openEndDate = Boolean(endDate)


    const handleLeaveChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainLeaveModal }
        let saveModal = { ...leaveSaveModal }
        modal[name] = value;
        setMainLeaveModal(modal);
        for (let property in Leave_Modal) {
            if (name === property) {
                saveModal[property] = value;
            }

        }
        setLeaveSaveModal(saveModal)
    }
    // const handleCheck = (e)=>{
    //     let { name, value, checked, isChecked} = e.target;

    //     setSelectedOption(prev => {
    //         console.log("prev",prev)

    //     return    {[name]: checked}
    //     })
    // let modal = { ...mainLeaveModal }
    // let saveModal = { ...leaveSaveModal }
    // console.log("checked",modal)
    // modal[name] = checked;
    // setMainLeaveModal(modal);
    //     for (let property in Leave_Modal) {
    //         if (name === property) {
    //             saveModal[property] = checked;
    //         }
    //     }
    // }

    const handleCheckBoxChange = (e) => {
        let { name, checked } = e.target;
        // setSelectedOption(name);
        let modal = { ...mainLeaveModal }
        let saveModal = { ...leaveSaveModal }
        // console.log("MODALHalf",modal['HalfDay'],"MODALFull",modal['FullDay']);
        if (name === 'FullDay') {
            modal['HalfDay'] = false;
        } else {
            modal['FullDay'] = false;
        }
        modal[name] = checked;
        setMainLeaveModal(modal);
        for (let property in Leave_Modal) {
            if (name === property) {
                if (name === 'FullDay') {
                    saveModal['HalfDay'] = false + "";
                } else {
                    saveModal['FullDay'] = false + "";
                }
                saveModal[property] = checked + "";
            }
        }
        setLeaveSaveModal(saveModal)
    }
    /// Leave Type Drop Down API ///

    const loadDropDown = () => {

        ServerRequestProtected(API_URl + "/LoadDropDownLIst", "post", {
            TableName: enumUtil.enumAPITableName.LeaveType
        }).then((response) => {
            
            setLeaveTypeDropDown(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })
    }

    const handleCalendarChange = (date, type) => {

        const FormatedDateShow = format(date, "MM/dd/yyyy")
        const FormatedDateSave = format(date, DateFormat)
        //===========to split YYYY-MM-dd Format Date then to Time============//
        const currDate = DateFormat_YMd_Time(date);
        //===========to Convert dd/MM/YYYY to YYYY-MM-dd then Time============//
        const startDate = DateFormatCalculations(mainLeaveModal.DateFrom)
        const endDate = DateFormatCalculations(mainLeaveModal.DateTo)

        if (type === 'DateFrom') {
            if (endDate && currDate <= endDate) {
                setMainLeaveModal({ ...mainLeaveModal, DateFrom: FormatedDateShow })
                setLeaveSaveModal({ ...leaveSaveModal, DateFrom: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateFrom: '' })
            }
            else if (!endDate) {
                setMainLeaveModal({ ...mainLeaveModal, DateFrom: FormatedDateShow })
                setLeaveSaveModal({ ...leaveSaveModal, DateFrom: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateFrom: '' })
            }
            else {
                setDateValidation({ ...dateValidation, DateFrom: 'error' })
            }
        }
        else if (type === 'DateTo') {
            console.log("DateToOuter", currDate, "DateFromOuter", startDate)
            if (startDate && currDate >= startDate) {
                console.log("DateTo", currDate, "DateFrom", startDate)
                setMainLeaveModal({ ...mainLeaveModal, DateTo: FormatedDateShow })
                setLeaveSaveModal({ ...leaveSaveModal, DateTo: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateTo: '' })
            }
            else if (!startDate) {
                setMainLeaveModal({ ...mainLeaveModal, DateTo: FormatedDateShow })
                setLeaveSaveModal({ ...leaveSaveModal, DateTo: FormatedDateSave })
                setDateValidation({ ...dateValidation, DateTo: '' })
            }
            else {
                console.log("DateToinner", currDate, "DateFrominner", startDate)
                setDateValidation({ ...dateValidation, DateTo: 'error' })
            }
        }
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setStartDate(null)
        setEndDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'DateFrom') { setStartDate(event.currentTarget) }
        else if (type === 'DateTo') { setEndDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };

    const FormValidationOnCondition = (type) => {
        let valid = {};
        if (mainLeaveModal.HalfDay) {
            valid = {
                StartTime: FormValidator(
                    mainLeaveModal.StartTime,
                    enumUtil.enumValidationType.Required,
                    "Start Time"
                ),
                EndTime: FormValidator(
                    mainLeaveModal.EndTime,
                    enumUtil.enumValidationType.Required,
                    "End Time"
                ),
            }

            return valid[type]
        }
        else if (!mainLeaveModal.HalfDay) {
            valid = {
                DateTo: FormValidator(
                    mainLeaveModal.DateTo,
                    enumUtil.enumValidationType.Required,
                    "End Date"
                ),
            }
            return valid[type]
        }

        else {
            return
        }

    }

    /// Leave input fields validation ///
    const LeaveValidation = () => {
        let ValidationModal = {
            Subject: FormValidator(
                mainLeaveModal.Subject,
                enumUtil.enumValidationType.Required,
                "Subject"
            ),
            LeavesTypesID: FormValidator(
                mainLeaveModal.LeavesTypesID,
                enumUtil.enumValidationType.Required,
                "Leave Type"
            ),
            StartDate: FormValidator(
                mainLeaveModal.DateFrom,
                enumUtil.enumValidationType.Required,
                "Start Date"
            ),
            Description: FormValidator(
                mainLeaveModal.Description,
                enumUtil.enumValidationType.Required,
                "Leave Details"
            ),
            EndDate: FormValidationOnCondition("DateTo"),
            StartTime: FormValidationOnCondition("StartTime"),
            EndTime: FormValidationOnCondition("EndTime"),


        }
        setLeaveValidationModal(ValidationModal);
        valModal = ValidationModal;
    }

    const handleSaveLeave = (e) => {
        LeaveValidation();
        let Validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (Validation) {
            NotificationHandler("Fill all Required Fields", enumUtil.enumtoaster.error)
            return
        } else {
            setIsLoading(true);
            // leaveSaveModal["UserID"] = EmployeeID;
            // leaveSaveModal["OrganizationID"] = OrganizationID
            let saveModal = {
                TableName: enumUtil.enumAPITableName.EmployeeLeave,
                ID: EmployeeLeaveID,
                Data: { ...leaveSaveModal }
            }
            ServerRequestProtected(API_URL + "/SaveSingleRecord", "post", saveModal).then((response) => {
                setIsLoading(false);
                NotificationHandler("Record Saved Successfully", enumUtil.enumtoaster.success);
                setEmployeeLeaveID(0);
                setIsRefresh(true)
                onCloseSave()
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
                setIsRefresh(false)
            })
        }
    }

    const handleApproveModal = (ID) => {
        // setApproveID(ID);
        setIsOpenApprove(true);
        setLeaveStatus({ RequestStatus: "Approved" });
    }

    const onCloseApprove = () => {
        setApproveID(0);
        setRequest("Approved")
        setIsRefresh(true)
        setIsOpenApprove(false)
        onClose()
    }

    const onApprovalClose = () => {
        setIsOpenApprove(false)
    }

    let ApprovePopup = "";
    if (isOpenApprove) {
        ApprovePopup = <ApproveConfirmation isOpenAlert={isOpenApprove} onCloseDelete={onCloseApprove} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeLeave} ID={approveID} LeaveStatus={LeaveStatus} onClose={onApprovalClose} LoadData={onCloseSave} />
    }

    const handleDenyModal = (ID) => {
        // setDenyID(ID);
        setIsOpenDeny(true);
        setLeaveStatus({ RequestStatus: "Dennied" });
    }

    const onCloseDeny = () => {
        setDenyID(0);
        setRequest("Dennied")
        setIsOpenDeny(false)
        setIsRefresh(true)
        onClose()
    }

    const onDenyClose = () => {
        setIsOpenDeny(false)
    }

    let DenyPopup = "";
    if (isOpenDeny) {
        DenyPopup = <DenyConfirmation isOpenAlert={isOpenDeny} onCloseDelete={onCloseDeny} API_URL={API_URL} TableName={enumUtil.enumAPITableName.EmployeeLeave} ID={denyID} LeaveStatus={LeaveStatus} onClose={onDenyClose} LoadData={onCloseSave} />
    }

    useEffect(() => {
        if (!isNull(ID)) {
            setApproveID(ID)
            setEmployeeLeaveID(ID)
            setDenyID(ID)
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.EmployeeLeave
            }).then((response) => {
                setIsLoading(false)
                console.log("leaveReqAdmin", response)
                setRequestStatus(response?.LeaveStatus)
                setMainLeaveModal({ ...response })
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [ID, request])

    useEffect(() => {
        loadDropDown()
    }, [])

    return (
        <div>
            <Modal show={isLeaveOpen} onHide={isLeaveOpen} backdrop="static" keyboard={false} centered dialogClassName={`${Status && Status === 'Pending' ? '' : 'ar_disable-popup'} app-modal ems_modal apply_leave`}>
                <Modal.Body>
                    {isLoading ? <Loader /> : <></>}
                    {DenyPopup}
                    {ApprovePopup}
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={() => onClose()}></img>
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Vector-3"></i>
                            </span>
                            {view && view === "Admin" ?
                                <p class="heading mt-2"> Leave Confirmation</p>
                                :
                                <p class="heading mt-2"> Apply Leave</p>
                            }
                        </div>
                    </div>
                    <div>
                        <div className={leaveValidationModal.Subject ? "form-group input error" : "form-group input"}>
                            <label>Subject<span className='req'>*</span></label>
                            <input type="text"
                                className="form-control"
                                name="Subject"
                                id="Subject"
                                maxLength={35}
                                value={mainLeaveModal.Subject}
                                onChange={handleLeaveChange}
                            />
                            {leaveValidationModal.Subject}
                        </div>
                        <div className={`form-group input ${leaveValidationModal.LeavesTypesID && 'error'}`}>
                            <div className='d-flex justify-content-between'>
                                <label>Leave Type<span className='req'>*</span></label>
                                <p className='text font-m clr-green fst-italic'>Leave Bal : <span>12</span></p>
                            </div>
                            <select className="form-control"
                                name="LeavesTypesID"
                                id="LeavesTypesID"
                                value={mainLeaveModal.LeavesTypesID}
                                aria-label="Default select example"
                                onChange={handleLeaveChange}
                            >
                                <option value="">Select</option>
                                {leaveTypeDropDown && leaveTypeDropDown.map((item) => {
                                    { console.log("leaveID", item.LeaveTypeID, "LeaveName", item.LeaveName) }
                                    return <option value={item.LeaveTypeID} key={item.LeaveTypeID}>{item.LeaveName}</option>
                                })}

                            </select>
                            {leaveValidationModal.LeavesTypesID}
                        </div>    <div className='d-flex align-items-center gap-2'>
                            <div className='d-flex align-items-center gap-1'>
                                <div class="custom_check_box">
                                    <input type="checkbox"
                                        className="form-check-input"
                                        name="FullDay"
                                        id="FullDay"
                                        // value={mainLeaveModal.FullDay}
                                        checked={mainLeaveModal.FullDay}
                                        // onChange={handleCheckBoxChange}
                                        onChange={handleCheckBoxChange}
                                    />
                                </div>
                                <p className='text clr_gry'>Full Day</p>
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                                <div class="custom_check_box">
                                    <input type="checkbox"
                                        className="form-check-input"
                                        name="HalfDay"
                                        id="HalfDay"
                                        // value={mainLeaveModal.HalfDay}
                                        checked={mainLeaveModal.HalfDay}
                                        // onChange={handleCheckBoxChange}
                                        onChange={handleCheckBoxChange}
                                    />
                                </div>
                                <p className='text clr_gry'>Half Day</p>
                            </div>
                        </div>

                        <div className={leaveValidationModal.StartDate ? "form-group input error" : `form-group input ${dateValidation.DateFrom}`}>

                            <DatePicker
                                label={<label>Start Date<span className='req'>*</span></label>}
                                value={mainLeaveModal.DateFrom}
                                openCalendarMenufunc={(event) => openCalendarMenu(event, 'DateFrom')}
                                // maxDate={new Date()}
                                anchorEl={startDate}
                                open={openStartDate}
                                closeCalendarMenufunc={closeCalendarMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'DateFrom')}
                                placeholder={true}
                            />
                            {leaveValidationModal.StartDate}
                            {dateValidation.DateFrom === 'error' ? <label className='text-danger'>Start Date must be less than End Date</label> : ''}
                        </div>
                        <div className={leaveValidationModal.EndDate ? "form-group input error" : `form-group input ${dateValidation.DateTo}`}>
                            <DatePicker
                                label={mainLeaveModal?.HalfDay ? <label>End Date</label> : <label>End Date<span className='req'>*</span></label>}
                                value={mainLeaveModal.DateTo}
                                openCalendarMenufunc={(event) => openCalendarMenu(event, 'DateTo')}
                                // maxDate={new Date()}
                                anchorEl={endDate}
                                open={openEndDate}
                                closeCalendarMenufunc={closeCalendarMenu}
                                handleCalendarChangefunc={date => handleCalendarChange(date, 'DateTo')}
                                placeholder={true}
                            />
                            {leaveValidationModal.EndDate}
                            {dateValidation.DateTo === 'error' ? <label className='text-danger'>End Date must be greater than Start Date</label> : ''}
                        </div>
                        {mainLeaveModal.HalfDay ? (<>
                            <div className='row'>
                                <div className={leaveValidationModal.StartTime ? "form-group input error" : `form-group input col-md-6 ${dateValidation.DateFrom}`}>
                                    <label>Start Time<span className='req'>*</span></label>
                                    <input type="time"
                                        className="form-control"
                                        name="StartTime"
                                        id="StartTime"
                                        value={mainLeaveModal.StartTime}
                                        onChange={handleLeaveChange}
                                    />
                                    {leaveValidationModal.StartTime}

                                </div>
                                <div className={leaveValidationModal.EndTime ? "form-group input error" : `form-group input col-md-6 ${dateValidation.DateTo}`}>
                                    <label>End Time<span className='req'>*</span></label>
                                    <input type="time"
                                        className="form-control"
                                        name="EndTime"
                                        id="EndTime"
                                        value={mainLeaveModal.EndTime}
                                        onChange={handleLeaveChange}
                                    />
                                    {leaveValidationModal.EndTime}

                                </div>
                            </div>
                        </>) : <></>}
                        <div className={`form-group input  ${leaveValidationModal?.Description ? 'error' : ''}`}>
                            <label>Leave Details<span className='req'>*</span></label>
                            <textarea type="text"
                                rows="6"
                                className="form-control"
                                name="Description"
                                id="Description"
                                value={mainLeaveModal.Description}
                                onChange={handleLeaveChange}
                            />
                            {leaveValidationModal?.Description}
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}
                </Modal.Body>
                {console.log("objectAdmin", view)}
                {view && view === "Admin" ?
                    <ModalFooterAdmin isSaved={handleDenyModal} handleSave={handleApproveModal} RequestStatus={RequestStatus} />
                    :
                    <ModalFooter isSaved={onClose} handleSave={handleSaveLeave} RequestStatus={Status} />
                }
                {/* <ModalFooter isSaved={onClose} handleSave={handleSaveLeave}/> */}
            </Modal>
        </div>
    )
}

export default AddNewLeave
