import React, { useEffect, useState } from 'react'
import close from "../../../../assetsOld/img/x-close.svg"
import CalendarInput from "../../../../utils/CalendarInput";
import DeleteConfirmation from '../../../shared/Delete/DeleteConfirmation';
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import { useSelector, useDispatch } from "react-redux"
import { CancelButton } from "../../../../actions/CancelButton";
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import format from "date-fns/format"
import { Calendar } from "react-date-range"
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import jwt_decode from 'jwt-decode';
import Modal from 'react-bootstrap/Modal'
import DatePicker from '../../../../utils/DatePicker';

const AddNewExperiences = (props) => {
    const { isOpenExperience, handleOnClose, handleOnCloseSave, ID, setIsRefresh, type } = { ...props }
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token)
    let EmployeeID = "";
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    //  else {
    //     EmployeeID = Token.UserID;
    // }
    let dispatch = useDispatch();
    let Experience_Modal = {
        CompanyName: "",
        Designation: "",
        LeavingReason: "",
        Salary: "",
        StartDate: null,
        EndDate: null,

    }
    let valModal = {}
    let ValidationModal = {
        CompanyName: "",
        Designation: "",
    }
    const [experienceValidationModal, setExperienceValidationModal] = useState(ValidationModal)
    const [experienceSaveUpdateModal, setExperienceSaveUpdateModal] = useState({})
    const [mainExperienceModal, setmainExperienceModal] = useState(Experience_Modal);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const [position, setPosition] = useState();
    const [delID, setDelID] = useState(0);
    const [ExperienceID, setExperienceID] = useState(0);
    const [dateValidation, setDateValidation] = useState({
        StartDate: false,
        EndDate: false,
    })
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const openStartCalendar = Boolean(startDate);
    const openEndCalendar = Boolean(endDate)

    const handleExperienceChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainExperienceModal };
        let saveModal = { ...experienceSaveUpdateModal }
        modal[name] = value
        setmainExperienceModal(modal)
        for (var property in Experience_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setExperienceSaveUpdateModal(saveModal)
    }

    const handleCalendarChange = (date, type) => {
        let FormatedDateShow = format(date, "MM/dd/yyyy")
        let FormatedDateSave = format(date, DateFormat)
        if (type === 'StartDate') {
            if (!isNull(mainExperienceModal.EndDate)) {
                const startDateCompareable = new Date(FormatedDateShow.split("/").reverse().join("/"));
                const endDateCompareable = new Date(mainExperienceModal.EndDate.split("/").reverse().join("/"));
                console.log("DAT#E", startDateCompareable, endDateCompareable, startDateCompareable <= endDateCompareable)
                if (startDateCompareable <= endDateCompareable) {
                    mainExperienceModal['StartDate'] = FormatedDateShow
                    experienceSaveUpdateModal['StartDate'] = FormatedDateSave
                    setDateValidation({ ...dateValidation, StartDate: false })
                }
                else {
                    setDateValidation({ ...dateValidation, StartDate: true })
                }
            }
            else {
                mainExperienceModal['StartDate'] = FormatedDateShow
                experienceSaveUpdateModal['StartDate'] = FormatedDateSave
                setDateValidation({ ...dateValidation, StartDate: false })
            }

            // setShowCalendar({StartDate: false})
        }
        if (type === 'EndDate') {
            if (!isNull(mainExperienceModal.StartDate)) {
                if (FormatedDateShow >= mainExperienceModal.StartDate) {
                    mainExperienceModal['EndDate'] = FormatedDateShow
                    experienceSaveUpdateModal['EndDate'] = FormatedDateSave
                    setDateValidation({ ...dateValidation, EndDate: false })
                } else {
                    setDateValidation({ ...dateValidation, EndDate: true })
                }
            }
            else {
                mainExperienceModal['EndDate'] = FormatedDateShow
                experienceSaveUpdateModal['EndDate'] = FormatedDateSave
                setDateValidation({ ...dateValidation, EndDate: false })
            }
            // setShowCalendar({EndDate: false})
        }
        closeCalendarMenu()
    }

    const handleSaveExperience = async () => {
        await ExperienceValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            // experienceSaveUpdateModal['StartDate'] =startSelectedDate;
            // experienceSaveUpdateModal['EndDate'] = endSelectedDate;
            // experienceSaveUpdateModal['OrganizationID'] = OrganizationID;
            setExperienceSaveUpdateModal(experienceSaveUpdateModal)
            experienceSaveUpdateModal["EmployeeID"] = EmployeeID + "";
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Experience,
                // ParentID: EmployeeID,
                ID: ExperienceID,
                data: { ...experienceSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setExperienceID(response.id)
                    setIsRefresh(true)
                    handleOnCloseSave()
                    //Setting Save model to null
                    //setExperienceSaveUpdateModal('')
                }
            }).catch((error) => {
                setIsLoading(false)
                console.log('error', error);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsRefresh(false)
            })
        }
    }

    const ExperienceValidation = async () => {
        let ValidationModal = {
            CompanyName: FormValidator(
                mainExperienceModal.CompanyName,
                enumUtil.enumValidationType.Required,
                'Company Name',
            ),
            Designation: FormValidator(
                mainExperienceModal.Designation,
                enumUtil.enumValidationType.Required,
                'Designation',
            ),
        }
        await setExperienceValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const closeCalendarMenu = () => {
        setStartDate(null);
        setEndDate(null);
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'StartDate') {
            setStartDate(event.currentTarget);
        }
        if (type === 'EndDate') {
            setEndDate(event.currentTarget);
        }
    };

    const handleDeleteModal = (ID) => {
        console.log(ID)
        setDelID(ID)
        setIsOpenAlert(true)
    }
    const onCloseDelete = () => {
        setmainExperienceModal(Experience_Modal)
        setExperienceID(0)
        setIsOpenAlert(false)
    }
    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Experience} ID={delID} onClose={onClose} />
    }

    useEffect(() => {
        console.log(DateFormat)
        setExperienceID(ID)
        if (!isNull(ID)) {
            setIsLoading(true)
            // setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Experience
            }).then((response) => {
                setIsLoading(false)
                setmainExperienceModal(response);
                // setAddNew(true);  

                // if(response.StartDate != null){
                //     var a =new Date(response.StartDate)
                //     setStartSelectedDate(a);
                // }    
                // if(response.EndDate != null){
                //     var b =new Date(response.EndDate)
                //     setEndSelectedDate(b);
                // }
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [ExperienceID, isOpenAlert])

    return (
        <Modal  show={isOpenExperience} onHide={isOpenExperience} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal add_new_des add_new_skill">
            <div className="education_popup">
                {isLoading ? <Loader /> : null}
                <img className="cursor-pointer" src={close} onClick={() => handleOnClose()}></img>
                <div className="text-center">
                    <span className="icon">
                        <div className="certification_icon">
                        <i className="icon-Group-2694"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></i>
                        </div>
                    </span>
                    <p className="heading my-2">Add New Experience</p>
                </div>
                <div>
                    <div className={experienceValidationModal.CompanyName ? "form-group input error" : "form-group input"}>
                        <label>Company Name<span className='req'>*</span></label>
                        <input type="text" className="form-control"
                            name="CompanyName"
                            id="CompanyName"
                            maxLength={40}
                            value={mainExperienceModal.CompanyName}
                            onChange={handleExperienceChange}
                        />
                        {experienceValidationModal.CompanyName}
                    </div>
                    <div className={experienceValidationModal.Designation ? "form-group input error" : "form-group input"}>
                        <label>Designation<span className='req'>*</span></label>
                        <input type="text" className="form-control"
                            name="Designation"
                            id="Designation"
                            maxLength={30}
                            value={mainExperienceModal.Designation}
                            onChange={handleExperienceChange}
                        />
                        {experienceValidationModal.Designation}
                    </div>
                    <div className={dateValidation.StartDate ? "form-group input error" : "form-group input"}>
                        <DatePicker
                            label='Start Date'
                            maxDate={new Date()}
                            handleCalendarChangefunc={(date) => handleCalendarChange(date, 'StartDate')}
                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'StartDate')}
                            // maxDate={new Date()}
                            anchorEl={startDate}
                            open={openStartCalendar}
                            closeCalendarMenufunc={closeCalendarMenu}
                            value={mainExperienceModal.StartDate}
                            placeholder={true}
                        />
                        {dateValidation.StartDate ? <label>Start Date must be less than End Date </label> : ''}
                    </div>
                    <div className={dateValidation.EndDate ? "form-group input error" : "form-group input"}>
                        <DatePicker
                            label='End Date'
                            maxDate={new Date()}
                            value={mainExperienceModal.EndDate}
                            handleCalendarChangefunc={(date) => handleCalendarChange(date, 'EndDate')}
                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'EndDate')}
                            // maxDate={new Date()}
                            anchorEl={endDate}
                            open={openEndCalendar}
                            closeCalendarMenufunc={closeCalendarMenu}
                            placeholder={true}
                        />
                        {dateValidation.EndDate ? <label>End Date must be greater than Start Date</label> : ''}
                    </div>
                    <div className="form-group input">
                        <label>Leaving Reason</label>
                        <textarea
                            rows={"3"}
                            type="text"
                            className="form-control"
                            maxLength={35}
                            name="LeavingReason"
                            id="LeavingReason"
                            value={mainExperienceModal.LeavingReason}
                            onChange={handleExperienceChange}
                        />
                    </div>
                    <div className="d-flex justify-content-end gap-3 ">
                        <button className="btn btn-gray" onClick={() => handleOnClose()}>Cancel</button>
                        <button className="btn btn-blue" onClick={() => handleSaveExperience()}>Save</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddNewExperiences
