import { useEffect, useState } from "react";
import { ServerRequestProtected } from "../../../utils/ServerDataRequest";
import jwt_decode from 'jwt-decode';
import { enumUtil } from "../../../utils/Enum";
import { useSelector } from "react-redux";

export const useUserData = () => {
    const token = useSelector(state => state.TokenReducer);
    const { UserID } = token ? jwt_decode(token) : {};
    const API_URL = process.env.REACT_APP_API;
    
    const [mainTopNavbarModal, setMainTopNavbarModal] = useState({});
    useEffect(() => {
        if (!UserID) return;
        const fetchData  = (ID) => {
            ServerRequestProtected(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Employee
            }).then((response) => {
                setMainTopNavbarModal(response)
            }).catch((error) => {
                // NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
        fetchData (UserID)
    }, [UserID])

  return mainTopNavbarModal
}