export const PayrollRecordAction = (Data) => {
    return {
        type: 'PayrollRecordAction',
        payload: Data
    }
}

export const PayrollActiveTab = (key) => {
    return {
        type: 'PayrollActiveTab',
        payload: key
    }
}

export const PayrollDataID = (id) => {
    return {
        type: 'PayrollDataID',
        payload: id
    }
}