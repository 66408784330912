import React, { useEffect, useState } from "react";
import greeting from "../../../assetsOld/img/Greetings.svg"
// import calender from "../../../assetsOld/img/Calender.svg"
// import profileicon from "../../../assetsOld/img/Ellipse.svg"
// import dosts from "../../../assetsOld/img/dosts.svg"
// import ballon from "../../../assetsOld/img/ballons.svg"
// import { useDispatch, useSelector } from "react-redux";
// import jwtDecode from 'jwt-decode';
// import { OrganizationID } from "../../../actions/Organization";
import YourAttendance from "../YourAttendance";
// import LineChart from "./Charts/LineChart";
import BarChart from "./Charts/BarChart";
// import ClusteredStackedColumnChart from "./Charts/ClusteredStackedColumnChart";
import CelebrationCorner from "./Components/CeleberationCorner";
import { enumUtil } from "../../../utils/Enum";
import { ServerRequestProtectedParentID } from "../../../utils/ServerDataRequest";
import NewHirings from "./Components/NewHirings";
import Announcements from "./Components/Announcements";
import OverallActivity from "./Components/OverallActivity";
import AttendanceOverview from "./Components/AttendanceOverview";
import PayrollSummary from "./Components/PayrollSummary";
import WeeklyInsight from "./Components/WeeklyInsight";

const EmployeeDashboard = () => {
    // let dispatch = useDispatch()
    // let activeTab = useSelector(state => state.MainTabContentReducer);
    // let GoTo = useSelector(state => state.GoToReducer);
    const API_URL_DASHBOARD = process.env.REACT_APP_API_Dashboard;
    // const [adminDashboardData, setAdminDashboardData] = useState([])
    // const [payrollSummaryData, setPayrollSummaryData] = useState([])

    const [reloadChart, setReloadChart] = useState({})
    const [employeeActivityTimeData, setEmployeeActivityTimeData] = useState()
    const [attendanceOverviewData, setAttendanceOverviewData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isviewReport, setIsviewReport] = useState(false)
    const [celebrationData, setCelebrationData] = useState([])
    const [newHiringData, setNewHiringData] = useState([])
    const [overallActivityData, setOverallActivityData] = useState([])
    const [reportData, setReportData] = useState([])

    const [newHiringFilter, setNewHiringFilter] = useState("Month")
    const [celebrationCornerFilter, setCelebrationCornerFilter] = useState("Month")
    const [overallActivityFilter, setOverallActivityFilter] = useState("This Week")


    const LoadData = (searchParam) => {
        let url = searchParam === "GridData" ? '/GetData' :
                    searchParam === "Report" ? '/GetReport' :
                        searchParam === "PayrollSummary" ? '/GetPayrollSummary' :
                            searchParam === "EmployeeActivityTime" ? '/EmployeeActivityTime' :
                                searchParam === "AttendanceOverview" ? '/AttendanceOverview' :
                                    searchParam === "NewHiring" ? '/NewHiring' :
                                        searchParam === "Celebration" ? '/Celebration' :
                                            searchParam === "OverallActivity" ? '/OverallActivity' : '';
        let value =
            // searchParam === "GridData" ? {RequestBy : celebrationCornerFilter}: 
            searchParam === "NewHiring" ? { RequestBy: newHiringFilter } :
                searchParam === "Celebration" ? { RequestBy: celebrationCornerFilter } :
                    searchParam === "OverallActivity" ? { RequestBy: overallActivityFilter } :
                        '';
        // setAdminDashboardData([]);
        // setPayrollSummaryData([]);
        searchParam === "EmployeeActivityTime" && setEmployeeActivityTimeData();
        setIsLoading(true);
        let saveModal = {
            "TableName": enumUtil.enumAPITableName.Dashboard,
            "SearchParam": searchParam,
            ...(value ? { "SearchCriteria": value } : {})
        }
        ServerRequestProtectedParentID(API_URL_DASHBOARD + url, "post", saveModal
        ).then((response) => {
            setIsLoading(false);
            // searchParam === "GridData" && setAdminDashboardData(response);
            // searchParam === "PayrollSummary" && setPayrollSummaryData(response);
            searchParam === "EmployeeActivityTime" && setEmployeeActivityTimeData(response);
            searchParam === "AttendanceOverview" && setAttendanceOverviewData(response);
            searchParam === "NewHiring" && setNewHiringData(response);
            searchParam === "Celebration" && setCelebrationData(response);
            searchParam === "OverallActivity" && setOverallActivityData(response);
            searchParam === "Report" && setReportData(response);
            setReloadChart(prev => ({ ...prev, [searchParam]: !prev[searchParam] }))
            // NotificationHandler("Data Fetched Successfully", enumUtil.enumtoaster.success)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
        })

    }

    const LoadDashboardData = () => {
        LoadData("GridData");
        LoadData("PayrollSummary");
        LoadData("EmployeeActivityTime");
        LoadData("AttendanceOverview");
        LoadData("Report");
        // LoadData("NewHiring");
        // LoadData("Celebration");
        // LoadData("OverallActivity");
    }

    useEffect(() => {
        LoadData("NewHiring");
    }, [newHiringFilter])

    useEffect(() => {
        LoadData("Celebration");
    }, [celebrationCornerFilter])

    useEffect(() => {
        LoadData("OverallActivity");
    }, [overallActivityFilter])

    useEffect(() => {
        LoadDashboardData()
    }, [])
    return (
        <>
            <div className="dashboard_screen">
                <div className="row m-0">
                    <div className="mt-3  dashboard col-md-9 m-0" >
                        <div className="row mb-1">
                            <div className="col-md-4 pe-0">
                                <div className="view_report card_tabs  dashboard h-100">
                                    <div className="row m-0 align-items-center">
                                        <WeeklyInsight isviewReport={isviewReport} setIsviewReport={setIsviewReport} reportData={reportData}/>
                                        <div className="col-md-6 px-0">
                                            <img src={greeting} className="w-100"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 pe-0">
                                <div className="card_tabs   ar_dashborad h-100">
                                    <p className="text font-m ps-2 ms-1 text-nowrap">Active Time</p>
                                    <p className="heading fs-20  ps-2 ms-1 pb-3">{employeeActivityTimeData?.ActivityTime?.[0]?.ActivityTime || '00:00:00'}</p>
                                    {employeeActivityTimeData?.ActivityTime?.[0]?.ActivityTime ? 
                                    <ActiveTime 
                                    time={employeeActivityTimeData?.ActivityTime?.[0]?.ActivityTime} 
                                    targettime={employeeActivityTimeData?.ActivityTime?.[0]?.TargetTime}/> : <></>}
                                </div>
                            </div>

                            <div className="col-md-3 pe-0">
                                <div className="card_tabs  ar_dashborad h-100">
                                    <p className="text font-m">Target Hours </p>
                                    <p className="heading fs-20 pb-2 text-nowrap">{employeeActivityTimeData?.ActivityTime?.[0]?.TargetTime || '00:00:00'} H(Per Day)</p>
                                    <p className="text mt-1 clr_gry text-nowrap">Average Working Hours</p>
                                    <p className="heading fs-16 text-center clr_gry">{employeeActivityTimeData?.ActivityTime?.[0]?.AverageHours || '00:00:00'}</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="card_tabs h-100 za-leave-balance">
                                    <p className="text font-m">Leave Balance </p>
                                    <p className="heading fs-20 text-nowrap">{employeeActivityTimeData?.LeaveBalance?.[0]?.TargetTime} 08 Remaining</p>
                                    <BarChart data={[{
                                        name: '04 Used',
                                        data: ['04']
                                    }, {
                                        name: '08 Remaining',
                                        data: ['08']
                                    },]} />
                                </div>
                            </div>

                        </div>
                        <AttendanceOverview data={attendanceOverviewData} />
                        <div className="dashboard row mt-2 mb-2">
                            <OverallActivity key={reloadChart?.['OverallActivity']} data={overallActivityData?.[0]?.data} filter={overallActivityFilter} setFilter={setOverallActivityFilter} />
                            <CelebrationCorner data={celebrationData?.Celebration} filter={celebrationCornerFilter} setFilter={setCelebrationCornerFilter} />
                        </div>
                        <NewHirings data={newHiringData?.NewHiring} filter={newHiringFilter} setFilter={setNewHiringFilter} />
                    </div>
                    <div className="dashboard mt-3 col-md-3 ps-0 pe-1">
                        <div className="card_tabs attendence mb-2">
                            <YourAttendance />
                        </div>
                        <PayrollSummary />
                        <Announcements />
                    </div>
                </div>

            </div >
        </>
    )
}

export default EmployeeDashboard

const ActiveTime = ({time,targettime}) => {
    let timeArr = time.split(":");
    let targetTimeArr = targettime.split(":");
    const elements = [];
    for (let i = 1; i <= targetTimeArr?.[0]; i++) {
        if( i <= timeArr?.[0] ){
            elements.push(
                <div key={i} className="d-flex flex-column h-100">
                <div className="bar inactive" style={{ height: "0%" }}></div>
                <div className="bar" style={{ height: "100%" }}></div>
                </div>
            );
        } else if(i === parseInt(timeArr?.[0] ,10) + 1 && timeArr?.[1]) {
            let val = timeArr?.[1] / 60 * 100;
            elements.push(
            <div key={i} className="d-flex flex-column h-100">
                <div className="bar inactive" style={{ height: `${100 - val}%` }}></div>
                <div className="bar" style={{ height: `${val}%` }}></div>
            </div>
            );
        } else {
            elements.push(
            <div key={i} className="d-flex flex-column h-100">
                <div className="bar inactive" style={{ height: "100%" }}></div>
                <div className="bar" style={{ height: "0%" }}></div>
            </div>
            );
        }
    }

        let checkCondition = (parseInt(timeArr?.[0],10) >=  parseInt(targetTimeArr?.[0] ,10)) && (parseInt(timeArr?.[1] ,10) > 0)
        let activeTime = timeArr?.[1] / 60 * 100;
        let targval =  targetTimeArr?.[1] / 60 * 100;
        let remainingTime =  (60 - targetTimeArr?.[1]) / 0.60;

        // let inActiveVal = targval * activeTime / 100;
        let activeResult = (timeArr?.[1] > targetTimeArr?.[1]) ? targval : activeTime;
        let inActiveResult = (targval - activeTime) > 0 ? targval - activeTime : 0;
        
            elements.push(
                <div className="d-flex flex-column h-100">
                { checkCondition ?

                    <>  
                    <div className="bar remaining" style={{ height: `${remainingTime}%`}}></div>
                    <div className="bar inactive" style={{ height: `${inActiveResult}%` }}></div>
                    <div className="bar active" style={{ height: `${activeResult}%` }}></div>
                    </>: <></>}
                </div>
            );


return(<>
    <div className="active_time-bar d-flex gap-2 align-items-center">
        {elements}
    </div>
    </>)
}