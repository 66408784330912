import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import close from "../../../../assetsOld/img/x-close.svg"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"
const AddNewLeaveType = (props) => {
    let leave_Modal={
        LeaveName: "",
        LeaveDescription: "",  
        LeavePeriodID :"",
        LeavePeriod :   "",
    }
    let valModal = {}
    let ValidationModal = {
        LeaveName: "",
    }
    const [newLeaveTypeSaved,setNewLeaveTypeSaved] = useState(false);
    const [leaveValidationModal, setleaveValidationModal] = useState(ValidationModal)
    const [LeaveNameID, setLeaveNameID] = useState()
    const [leaveUpdateModal, setleaveUpdateModal] = useState({})
    const [mainleaveModal, setmainleaveModal] = useState(leave_Modal);
    const [dropDown,setDropDown] =useState([])
    const [isLoading, setIsLoading] = useState(false)
    const[del,setDel]=useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenLeaveType, onClose, onCloseSave,  setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setIsLoading(false)
        // right now, use any providerid to test update case/find by id
        if(props.LeaveTypeID != null){
             setLeaveNameID(props.LeaveTypeID)
             setDel(true)
        }else {
             if(!newLeaveTypeSaved){
                setLeaveNameID(0);
                setDel(false)
            }
            else{setDel(true)} 
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.LeavePeriod,
        }).then((response) => {
            console.log("asdaasdfsfaddfa",response)
            setIsLoading(false)
            setDropDown(response)
        }).catch((error) => {
            setIsLoading(false)
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);    
        })
            if (!isNull(LeaveNameID)) {
                setIsLoading(true)
                ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                    "TableName": enumUtil.enumAPITableName.LeaveType,
                    "ID" : LeaveNameID,
                }).then((response) => {
                    setmainleaveModal(response);   
                    setIsLoading(false)
                }).catch((error) => {
                    NotificationHandler(error.message, enumUtil.enumtoaster.error)
                    setIsLoading(false)
                })
            }
    }, [LeaveNameID])

    const handleLeaveChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainleaveModal };
        let saveModal = { ...leaveUpdateModal }
        modal[name] = value
        setmainleaveModal(modal)
        for (var property in leave_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setleaveUpdateModal(saveModal)
    }

    const LeaveTypeValidation = async () => {
        let ValidationModal = {
            LeaveName: FormValidator(
                mainleaveModal.LeaveName,
                enumUtil.enumValidationType.Required,
                'Leave Name',
            ),
        }
        await setleaveValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleSaveLeaveType = async () => {
        await LeaveTypeValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setleaveUpdateModal(leaveUpdateModal)
            let saveModal = {
                TableName: enumUtil.enumAPITableName.LeaveType,
                ID: LeaveNameID,
                data: { ...leaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewLeaveTypeSaved(true)
                    setLeaveNameID(response.id)
                    setIsRefresh(true)
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    // const handleDeleteclick = () => {
    //     setIsOpenAlert(true)
    // }
    // const AlertClose = () => {
    //     setIsOpenAlert(false)
    // }
    // const handleLeaveTypeDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": LeaveNameID,
    //         "TableName": enumUtil.enumAPITableName.LeaveType
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Leave Type Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }
    const SetLeaveTypeName = () => {
        if (mainleaveModal.LeaveName != null && mainleaveModal.LeaveName != "") {
            return (
                <p className="heading validation_message">{mainleaveModal.LeaveName}</p>
            )
        } else {
            return (
                <p className="heading">Add New Leave Type</p>
            )
        }
    }
  
    //////////
    return (
        <>
            <Modal show={isOpenLeaveType} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal add_new_leave ems_modal">
                <Modal.Body> 
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close}onClick={() => onClose(newLeaveTypeSaved)}></img>
                 
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="icon_bg">
                                    <i className="icon-plus-icon"></i>
                                </div>
                            </span>
                            <p class="heading mt-2"> {SetLeaveTypeName()}</p>
                        </div>
                    </div>  
                    {isLoading ? <Loader/> : null}
                    <div className="row">
                        {/* <div className="col-md-6"> */}
                            <div className={leaveValidationModal.LeaveName ? "form-group input error":"form-group input"}>
                                <label>Leave Name<span className='req'>*</span></label>
                                <input type="text"
                                className="form-control"
                                name="LeaveName"
                                id="LeaveName"
                                maxLength={35}
                                value={mainleaveModal.LeaveName}
                                onChange={handleLeaveChange}     
                                />
                                {leaveValidationModal.LeaveName}
                            </div>
                            <div className="form-group input">
                                <label>Description</label>
                                <textarea rows="3" 
                                className="form-control"
                                name="LeaveDescription"
                                id="LeaveDescription"
                                maxLength={100}
                                value={mainleaveModal.LeaveDescription}
                                onChange={handleLeaveChange}     
                                ></textarea>
                            </div>
                        {/* </div> */}
                        {/* <div className="col-6"> */}
                            {/* <div className="form-group input">
                                <label>Leaves Period Type</label>
                                <select 
                                className="form-control"
                                name="LeavePeriodID"
                                id="LeavePeriodID"
                                value={mainleaveModal.LeavePeriodID}
                                onChange={handleLeaveChange}
                                >
                                    <option>Select</option>
                                {dropDown.map((e,index)=>(
                                <option value={e.LeavePeriodID} key={index}>
                                    {e.LeavePeriodName}
                                </option>
                                ))}
                                </select>
                            </div>
                            <div className="form-group input ">
                                <label>Leaves Period</label>
                                <input type="text"
                                    className="form-control"
                                    maxLength={3}
                                    name="LeavePeriod"
                                    id="LeavePeriod"
                                    value={mainleaveModal.LeavePeriod}
                                    onChange={handleLeaveChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                />
                            </div> */}
                        {/* </div> */}
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveLeaveType}/>
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                    <button className='btn btn-gray' onClick={() => onClose(newLeaveTypeSaved)}>Cancel</button>
                    <button className='btn btn-orange' onClick={handleSaveLeaveType}>Save</button>
                </div> */}
            </Modal>
        </>
    )
}

export default AddNewLeaveType