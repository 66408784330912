
import React from "react"
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { useDispatch } from "react-redux";
import { PayrollActiveTab } from "../../../../actions/PayrollRecordAction";
import PayrollTopMenu from "../../PayrollLayout/PayrollTopMenu";

const PayrollTab = (props) => {
    let dispatch = useDispatch();
    dispatch(PayrollActiveTab(props.Active))
    return (
        <div>
            <PayrollTopMenu />
        </div>
    )
}

export default PayrollTab

