import React, { useEffect, useState } from 'react'
import MenuList from './Menu'
import { TabContent, TabPane } from 'reactstrap';

const LeaveTopMenuContent = (props) => {
    const { activeTab, dynamicTabs } = { ...props }
    const [tabs, setTabs] = useState([]);
    const MenuItems = MenuList();
    const activeComponent = MenuItems?.filter((tab) => tab.right === true)[0];

    useEffect(() => {
        setTabs(dynamicTabs)
    }, [activeTab])
    return (
        <div>
            {tabs.length > 0 ?
                <TabContent activeTab={activeTab} className="content">
                    {tabs.map((tab) => {
                        return (
                            <TabPane tabId={tab.key} key={tab.key}>
                                {tab.component}
                            </TabPane>
                        )

                    })}
                </TabContent>
                :
                <>
                    <TabContent activeTab={activeComponent.key} className="content">
                        <TabPane tabId={activeComponent.key} >
                            {activeComponent.component}
                        </TabPane>
                    </TabContent>
                </>
            }
        </div>
    )
}

export default LeaveTopMenuContent
