import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";
import ModalFooter from "../../../shared/ModalFooter/ModalFooter";
import { format } from 'date-fns';
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import CalendarInput from '../../../../utils/CalendarInput';
import ReactQuill from 'react-quill';
import TextEditor from '../../../../shared-components/TextEditor';
import { Autocomplete, TextField } from "@mui/material";
import { enumUtil } from '../../../../utils/Enum';
import NotificationHandler from '../../../../utils/NotificationHandler';
import { ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import isNull from '../../../../utils/NullChecking';

const AddNewAnnouncement = (props) => {

    const { onClose, isOpenPopup, ID } = { ...props }

    let ANNOUNCEMENT_MODAL = {
        Title: '',
        Description: '',
        TeamIDs: '',
        StartDate: '',
        EndDate: '',
        Attachment: '',
    }

    let Radio_Modal = {
        AllOrgUsers: false,
        Department: false,
        Shift: false,
        Team: false,
    }

    const API_URL = process.env.REACT_APP_API;
    const [mainAnnouncementModal, setMianAnnouncmentModal] = useState(ANNOUNCEMENT_MODAL);
    const [saveAnnouncementModal, setSaveAnnouncementModal] = useState({});
    const [mainRadioModal, setMainRadioModal] = useState(Radio_Modal);
    const [saveRadioModal, setSaveRadioModal] = useState({});
    const [addTeamDropDown, setAddTeamDropDown] = useState([]);
    const [teamValue, setTeamValue] = useState([])
    const [StartDate, setStartDate] = useState(null);
    const [EndDate, setEndDate] = useState(null);
    const [AnnouncementID, setAnnouncementID] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const openStartDateRangePicker = Boolean(StartDate);
    const openEndDateRangePicker = Boolean(EndDate);

    const openStartDateRangePickerMenu = (event) => {
        setStartDate(event.currentTarget);

    };
    const openEndDateRangePickerMenu = (event) => {
        setEndDate(event.currentTarget);

    };

    const closeStartDateRangePickerMenu = () => {
        setStartDate(null);
    };
    const closeEndDateRangePickerMenu = () => {
        setEndDate(null);
    };

    const handleOnChange = (e) => {
        let { name, value, type, checked } = e.target;
        let modal = { ...mainAnnouncementModal }
        let saveModal = { ...saveAnnouncementModal }

        modal[name] = value;
        setMianAnnouncmentModal(modal);

        for (let property in ANNOUNCEMENT_MODAL) {
            if (name === property) {

                saveModal[property] = value;

            }
        }
        setSaveAnnouncementModal(saveModal);
    }
    
    const handleRadioOnChange = (e) => {
        let { name, value, type, checked } = e.target;
        let modal = { ...mainRadioModal }
        let saveModal = { ...saveRadioModal }

        for (let property in Radio_Modal) {
            if (name === property) {
                modal[property] = checked
            } else {
                modal[property] = false;
            }
        }

        setMainRadioModal(modal);
        for (let property in Radio_Modal) {
            if (name === property) {
                saveModal[property] = checked;
            } else {
                saveModal[property] = false;
            }
        }
        setSaveRadioModal(saveModal);
    }

    const handleMultiSelectChange = (values, type, reason) => {
        let arr = [];
        let arrVal = [];
        values.map((key, index) => {
            arr.push(key)
        })
        values.map((key, index) => {
            arrVal.push(key.TeamID)
        })
        setTeamValue(arr);
        console.log("TeamIDs", arr, arrVal)
        mainAnnouncementModal['TeamIDs'] = arr.length == 0 ? "" : arr
        saveAnnouncementModal['TeamIDs'] = arrVal.toString()
    }
    const handleCalendarChange = (date, name) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, DateFormat_dMy)
        let modal = { ...mainAnnouncementModal };
        let saveModal = { ...saveAnnouncementModal }
        modal[name] = FormatedDateShow
        setMianAnnouncmentModal(modal)
        for (var property in ANNOUNCEMENT_MODAL) {
            if (name === property) {
                saveModal[property] = FormatedDateSave
            }
        }
        setSaveAnnouncementModal(saveModal)
        closeStartDateRangePickerMenu()
        closeEndDateRangePickerMenu()
    }

    const handleSaveAnnouncement = () => {
        let saveModal = {
            ...saveAnnouncementModal, ...saveRadioModal
        }
       
    }

    useEffect(() => {
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Team,
        }).then((response) => {
            setIsLoading(false)
            setAddTeamDropDown(response)
        }).catch((error) => {

            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })

        if (!isNull(ID)) {
            setIsLoading(true)
            setAnnouncementID(ID)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ID,
                "TableName": enumUtil.enumAPITableName.Announcement,
            }).then((response) => {
                setMianAnnouncmentModal(response?.Shift[0]);
                setIsLoading(false)
                // if (response?.Shift[0]?.DepartmentIDs) { setTeamValue(JSON.parse(response?.Shift[0]?.DepartmentIDs)) }
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [ID])

    return (
        <div>
            <Modal
                show={isOpenPopup}
                onHide={isOpenPopup}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="delete-modal hk-modal-v1 ems_modal add_new_announcement"
            >
                <ModalBody>
                    <div className="d-flex justify-content-end">
                        <i
                            className="icon-Vector7"
                            onClick={() => onClose()}
                        ></i>
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Group-1 fs-22"></i>
                            </span>
                            <p class="heading mt-2 mb-3">Add Announcement</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-15">
                        <div className="form-group input">
                            <label>Title</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Title"
                                id="Title"
                                maxLength={250}
                                onChange={handleOnChange}
                                value={mainAnnouncementModal?.Title}
                            />
                        </div>
                        <div className="hk-EmailFormat-Editor">
                            <p className='gry_heading mb-2'>Description</p>
                            <TextEditor name="Description" id="Description" value={mainAnnouncementModal?.Description}
                                //  setValue={handleOnChange}
                                setValue={value => handleOnChange({ target: { name: "Description", value } })}
                            />
                        </div>
                        <div>
                            <p className="text clr_gry">Send To</p>
                            <div className="d-flex">
                                <div className="col-md-6">
                                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                                        <input type="radio" name="AllOrgUsers" id="AllOrgUsers" checked={mainRadioModal?.AllOrgUsers} value={mainRadioModal?.AllOrgUsers} onChange={handleRadioOnChange} />
                                        <p className="text clr_gry">All Organization Users</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex gap-1 align-items-center">
                                        <div className="ar_radio-btn">
                                            <input type="radio" name="Department" id="Department" checked={mainRadioModal?.Department} value={mainRadioModal?.Department} onChange={handleRadioOnChange} />
                                        </div>
                                        <p className="text clr_gr">Departments</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-md-6">
                                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                                        <input type="radio" name="Shift" id="Shift" checked={mainRadioModal?.Shift} value={mainRadioModal?.Shift} onChange={handleRadioOnChange} />
                                        <p className="text clr_gry">Shifts</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ar_radio-btn d-flex gap-1 align-items-center">
                                        <input type="radio" name="Team" id="Team" checked={mainRadioModal?.Team} value={mainRadioModal?.Team} onChange={handleRadioOnChange} />
                                        <p className="text clr_gry">Teams</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group input">
                            <label>Teams</label>
                            <Autocomplete
                                multiple
                                limitTags={1}
                                id='TeamIDs'
                                name='TeamIDs'
                                value={teamValue}
                                options={addTeamDropDown}
                                isOptionEqualToValue={(option, value) => option.TeamID === value.TeamID}
                                onChange={(event, value, reason) => handleMultiSelectChange(value, 'TeamIDs', reason)}
                                getOptionLabel={(option) => option.TeamName}
                                noOptionsText={"Not Available"}
                                renderInput={(params) => <TextField {...params} variant="standard" />}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-6 pe-0">
                                <CalendarInput
                                    label={<span>Start Date<span className="req"> *</span></span>}
                                    value={mainAnnouncementModal?.StartDate}
                                    openCalendarMenufunc={(event) => openStartDateRangePickerMenu(event, 'StartDate')}
                                    maxDate={new Date()}
                                    anchorEl={StartDate}
                                    open={openStartDateRangePicker}
                                    closeCalendarMenufunc={closeStartDateRangePickerMenu}
                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'StartDate')}
                                />
                            </div>
                            <div className="col-md-6">
                                <CalendarInput
                                    label={<span>End Date<span className="req">*</span></span>}
                                    value={mainAnnouncementModal?.EndDate}
                                    openCalendarMenufunc={(event) => openEndDateRangePickerMenu(event, 'EndDate')}
                                    maxDate={new Date()}
                                    anchorEl={EndDate}
                                    open={openEndDateRangePicker}
                                    closeCalendarMenufunc={closeEndDateRangePickerMenu}
                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'EndDate')}
                                />
                            </div>
                        </div>
                        <div className="form-group input">
                            <label>Attachement</label>
                            <input
                                type="text"
                                className="form-control"
                                name="Attachment"
                                id="Attachment"
                                onChange={handleOnChange}
                                value={mainAnnouncementModal?.Attachment}
                            ></input>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter isSaved={onClose} handleSave={handleSaveAnnouncement} />
            </Modal>
        </div>
    )
}

export default AddNewAnnouncement
