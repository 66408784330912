import React, { useEffect, useState } from "react";
import { NavItem, NavLink } from 'reactstrap'
import Nav from 'react-bootstrap/Nav';
import close from "../../../../../assetsOld/img/x-close.svg"
import AcademicsInnerLeftMenuContent from "./AcademicsInnerLeftMenuContent";
import AcademicsMenu from "./AcademicsMenu";
import { useSelector } from "react-redux";
import CalendarInput from "../../../../../utils/CalendarInput";
import Education from "../../Education/Education";
import Certification from "../../Certification/Certification";
import Experience from "../../Experience/Experience";
import Skills from "../../Skill/Skills";
import EmployeeInformation from "../../EmployeeInformation/EmployeeInformation";

const AcademicsInnerLeftMenu = (props) => {
    const MenuItems = AcademicsMenu();
    const [subCategItems, setSubCategItems] = useState([])
    const [tabList, setTabsList] = useState([])
    const [education, setEducation] = useState(false)
    const [experience, setExperience] = useState(false)
    const [skills, setSkills] = useState(false)
    const [certification, setCertification] = useState(false)
    const [activeTab, setActiveTab] = useState()
    let menuTab = useSelector(state => state.HandleMenuActiveTab);
    const [activeMenuTab, seActiveMenuTab] = useState(false)

    useEffect(() => {
        const selected_Item_List = MenuItems.filter((item) => item.key === props.subCategKey);
        if (selected_Item_List && selected_Item_List.length > 0) {
            let subCategories = selected_Item_List[0].subCategoryItems
            setSubCategItems(subCategories)
            setActiveTab(subCategories[0].key)
        }
        if (menuTab === 9) { // basicinfo hide
            seActiveMenuTab(true)
        } else {
            seActiveMenuTab(false)
        }
    }, [props.subCategKey, menuTab])

    const handleActiveTab = (item) => {
        const { key, name, right, component } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const activeTabElement = subCategItems.filter((item) => item.key === activeTab)
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
                component: activeTabElement.component,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
            component: component,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }

    return (
        <>
            <div>
                {/* <div className='academics-tabs'>
                <Nav variant="tabs" className=''>
                    {subCategItems && subCategItems.map((item) => {
                        return (
                            <React.Fragment key={item.key}>
                                <NavItem onClick={() => handleActiveTab(item)}>
                                    <NavLink href="#" className={activeTab === item.key ? `${item.class} active `: `wrapper ${item.class}`}>
                                        <div className='tab-icon'>
                                            <i className={item.icon}></i>
                                        </div>
                                        {item.name}
                                    </NavLink>
                                </NavItem>
                            </React.Fragment>
                        )

                    })}
                </Nav>
                <AcademicsInnerLeftMenuContent activeTab={activeTab} dynamicTabs={tabList} subCategItems={subCategItems} />
            </div> */}
            </div>
            <div className='academics_screen basic_info'>
                {/* <div className="row m-0 mt-2">
                    <div className="col-md-8 pe-0">
                    </div>
                    <div className="col-md-4 left_border add_new_ed p-0">
                        <div className="header_content p-2">
                            <p className="white_heading">Add New Education <i className="ms-1 icon-Icon-awesome-graduation-cap"></i></p>
                        </div>
                        <div className="content px-2 py-3">
                            <div className="form-group input">
                                <label>Title</label>
                                <input type="text" className="form-control" name="Title" id="Title" maxlength="35" value=""/>
                            </div>
                            <div className="form-group input">
                                <label>Institute</label>
                                <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value=""/>
                            </div> 
                            <div className="form-group input">
                                <CalendarInput 
                                    label = 'Start Date'
                                    maxDate={new Date()} 
                                     
                                    
                                />
                            </div> 
                            <div className="form-group input">
                                <CalendarInput 
                                    label = 'End Date'
                                    maxDate={new Date()} 
                                     
                                />
                            </div>
                            <div className="form-group input">
                                <label>Current Status</label>
                                <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value=""/>
                            </div> 
                            <div className="d-flex justify-content-end gap-3 ">
                            <button class="btn btn-gray">Cancel</button>
                            <button class="btn btn-blue">Apply</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row m-0 pt-1">

                    <div className="col-md-12 p-0 pb-1 accademics_right-side">

                        <div className="row m-0">
                            {/* <div className="col-md-6 ps-0 education">
                                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-2">
                                        <span><i className="icon-Icon-awesome-graduation-cap"></i></span>
                                        <p className="text">Education</p>
                                    </div>
                                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => setEducation(true)}></i>
                                </div>
                                <div className="card_content pb-3 mt-2">
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">BS Computer Science</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Graduate</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">BS Computer Science</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Graduate</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">BS Computer Science</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Graduate</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <Education type={props.type} />
                                <Experience type={props.type} />
                            </div>
                            <div className="col-md-6">
                                <Certification type={props.type} />
                                <Skills type={props.type} />
                            </div>
                            {/* <div className="col-md-6 ps-0 education certification">
                                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-2">
                                        <span><i className="icon-Icon-awesome-certificate"></i></span>
                                        <p className="text">Certification</p>
                                    </div>
                                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => setCertification(true)}></i>
                                </div>
                                <div className="card_content pb-3 mt-2">
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">React Beginners Course</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Remarks</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">React Beginners Course</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Remarks</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">React Beginners Course</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Remarks</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-md-6 mt-3 ps-0 education experience">
                                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-2">
                                        <span><i class="icon-Group-2694-1"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i></i></span>
                                        <p className="text">Experience</p>
                                    </div>
                                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => setExperience(true)}></i>
                                </div>
                                <div className="card_content pb-3 mt-2">
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">UI/UX Designer</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Reason</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">UI/UX Designer</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Reason</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">UI/UX Designer</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Reason</p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-md-6 mt-3 ps-0 education skills">
                                <div className="card_header d-flex px-2 py-1  align-items-center justify-content-between">
                                    <div className="d-flex align-items-center gap-2">
                                        <span><i className="icon-Icon-material-work"></i></span>
                                        <p className="text">Skills</p>
                                    </div>
                                    <i className="btn-icon-transparent-add cursor-pointer" onClick={() => setSkills(true)}></i>
                                </div>
                                <div className="card_content pb-3 mt-2">
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">UI/UX Designer</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Reason</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">UI/UX Designer</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Reason</p>
                                    </div>
                                    <div className="bottom_border mt-3"></div>
                                    <div className="d-flex px-2 pt-2 align-items-center justify-content-between">
                                        <p className="heading">UI/UX Designer</p>
                                        <p className="text xsm">[3,NOV 2019 - 4,OCT 2023]</p>
                                    </div>
                                    <p className="text px-2">Comsats University</p>
                                    <div className="status py-1 px-2">
                                        <p className="heading sm">Reason</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* {education &&
                    <>
                        <div className="education_popup">
                            <img className="cursor-pointer" src={close} onClick={() => setEducation(false)}></img>
                            <div className="text-center">
                                <span className="icon">
                                    <i className="icon-Icon-awesome-graduation-cap"></i>
                                </span>
                                <p className="heading mt-2">Add New Education</p>
                            </div>
                            <div>
                                <div className="form-group input">
                                    <label>Title</label>
                                    <input type="text" className="form-control" name="Title" id="Title" maxlength="35" value="" />
                                </div>
                                <div className="form-group input">
                                    <label>Institute</label>
                                    <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                                </div>
                                <div className="form-group input">
                                    <CalendarInput
                                        label='Start Date'
                                        maxDate={new Date()}


                                    />
                                </div>
                                <div className="form-group input">
                                    <CalendarInput
                                        label='End Date'
                                        maxDate={new Date()}

                                    />
                                </div>
                                <div className="form-group input">
                                    <label>Current Status</label>
                                    <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                                </div>
                                <div className="d-flex justify-content-end gap-3 ">
                                    <button class="btn btn-gray">Cancel</button>
                                    <button class="btn btn-blue">Apply</button>
                                </div>
                            </div>
                        </div>
                    </>
                } */}
                {/* {certification &&
                    <>
                        <div className="education_popup">
                            <img className="cursor-pointer" src={close} onClick={() => setCertification(false)}></img>
                            <div className="text-center">
                                <span className="icon">
                                    <div className="certification_icon">
                                        <i className="icon-Icon-awesome-certificate"></i>
                                    </div>
                                </span>
                                <p className="heading mt-2">Add New Certification</p>
                            </div>
                            <div>
                                <div className="form-group input">
                                    <label>Title</label>
                                    <input type="text" className="form-control" name="Title" id="Title" maxlength="35" value="" />
                                </div>
                                <div className="form-group input">
                                    <label>Institute</label>
                                    <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                                </div>
                                <div className="form-group input">
                                    <CalendarInput
                                        label='Start Date'
                                        maxDate={new Date()}


                                    />
                                </div>
                                <div className="form-group input">
                                    <CalendarInput
                                        label='End Date'
                                        maxDate={new Date()}

                                    />
                                </div>
                                <div className="form-group input">
                                    <label>Current Status</label>
                                    <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                                </div>
                                <div className="d-flex justify-content-end gap-3 ">
                                    <button class="btn btn-gray">Cancel</button>
                                    <button class="btn btn-blue">Apply</button>
                                </div>
                            </div>
                        </div>
                    </>
                } */}
                {/* {experience &&
                    <div className="education_popup">
                        <img className="cursor-pointer" src={close} onClick={() => setExperience(false)}></img>
                        <div className="text-center">
                            <span className="icon">
                                <div className="certification_icon">
                                    <i class="icon-Group-2694-1"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i></i>
                                </div>
                            </span>
                            <p className="heading mt-2">Add New Experience</p>
                        </div>
                        <div>
                            <div className="form-group input">
                                <label>Company Name</label>
                                <input type="text" className="form-control" name="Title" id="Title" maxlength="35" value="" />
                            </div>
                            <div className="form-group input">
                                <label>Designation</label>
                                <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                            </div>
                            <div className="form-group input">
                                <CalendarInput
                                    label='Start Date'
                                    maxDate={new Date()}


                                />
                            </div>
                            <div className="form-group input">
                                <CalendarInput
                                    label='End Date'
                                    maxDate={new Date()}

                                />
                            </div>
                            <div className="form-group input">
                                <label>Leaving Reason</label>
                                <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                            </div>
                            <div className="d-flex justify-content-end gap-3 ">
                                <button class="btn btn-gray">Cancel</button>
                                <button class="btn btn-blue">Apply</button>
                            </div>
                        </div>
                    </div>
                } */}
                {/* {skills &&
                    <>
                        <div className="education_popup">
                            <img className="cursor-pointer" src={close} onClick={() => setSkills(false)}></img>
                            <div className="text-center">
                                <span className="icon">
                                    <div className="certification_icon">
                                        <i className="icon-Icon-material-work"></i>
                                    </div>
                                </span>
                                <p className="heading mt-2">Add New Skills</p>
                            </div>
                            <div>
                                <div className="form-group input">
                                    <label>Skill Name</label>
                                    <input type="text" className="form-control" name="Title" id="Title" maxlength="35" value="" />
                                </div>
                                <div className="form-group input">
                                    <label>Institute</label>
                                    <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                                </div>
                                <div className="form-group input">
                                    <label>Granted On</label>
                                    <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                                </div>
                                <div className="form-group input">
                                    <label>Remarks</label>
                                    <input type="text" className="form-control" name="Institute" id="Institute" maxlength="35" value="" />
                                </div>
                                <div className="d-flex justify-content-end gap-3 ">
                                    <button class="btn btn-gray">Cancel</button>
                                    <button class="btn btn-blue">Apply</button>
                                </div>
                            </div>
                        </div>
                    </>
                } */}
            </div>
        </>
    )
}

export default AcademicsInnerLeftMenu