import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({ type, data: seriesVals }) => {
  let total = parseInt(seriesVals?.Male) + parseInt(seriesVals?.Female) ;
  let malePercentage = seriesVals?.MalePercentage; 
  let femalePercentage = seriesVals?.FemalePercentage;
  let series = [ parseInt(seriesVals?.Male),  parseInt(seriesVals?.Female)];
  const legends = {
    categories: [`Male(${parseFloat(malePercentage).toFixed(2)}% ) <span class='font-sb'> ${seriesVals?.Male} </span>`, `Female(${parseFloat(femalePercentage).toFixed(2)}% ) <span class='font-sb'> ${seriesVals?.Female} </span>`],
    colors: ['#FFCEB0', '#9747FF'],
  };

  const initialOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              fontSize: '18px',
              fontFamily: 'Poppins-semibold',
              color: '#707070',
              // offsetY: 30,
              formatter: function (w) {
                return total;
              }
            },
            name: {
              show: true,
              fontSize: '10px',
              fontFamily: 'Poppins-semibold',
              color: undefined,
              offsetY: 20,
              formatter: function (val) {
                // Clean the string inside the formatter
                const cleanStr = val.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ').trim();
                return cleanStr;
              },
            },
            value: {
              show: true,
              fontSize: '24px',
              fontFamily: 'Poppins-semibold',
              color: '#707070',
              offsetY: -20,
              formatter: function (val) {
                return val;
              }
            },
          }
        }
      }
    },
    chart: {
      type: 'donut',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    series: series,
    labels: legends.categories,
    colors: legends.colors,
    dataLabels: {
      enabled: false,
    },
    grid: {
      border: '0.25px solid #959595',
    },
    legend: {
      show: type === "Appointments" ? true : true,
      fontSize: 10,
      position: type === "Appointments" ? 'bottom' : 'bottom',
      markers: {
        radius: 1,
        height: 6,
        width: 6,
      },
    }
  };

  const [state, setState] = useState(initialOptions);

  return (
    <>
      <Chart
        options={state}
        series={state.series}
        type={state.chart.type}
        height={'200'}
      />
    </>
  );
};

export default DonutChart;