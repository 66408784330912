import React from "react"
import { useEffect, useState } from "react"
import { TabContent, TabPane } from 'reactstrap'
import ExpenseInnerLeftMenu from "./ExpenseInnerLeftMenu";
import ExpenseMenu from "./ExpenseMenu";

const SetupTopMenuContent = (props) => {
    const MenuItems = ExpenseMenu();
    const [tabs, setTabs] = useState([])
    const [component, setComponent] = useState({})

    useEffect(() => {
        setTabs(props.dynamicTabs)
        const activeComponent = MenuItems.filter((tab, key) => {
            return tab.right === true
        })[0]
        setComponent(activeComponent)
    }, [props.activeTab])

    return (
        <>
             <ExpenseInnerLeftMenu subCategKey={component.key} />
        </>
    )
}
export default SetupTopMenuContent