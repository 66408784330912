
import LeaveRequest from "../AllRequestComponents/LeaveRequest";
import OtherRequest from "../AllRequestComponents/OtherRequest";

export default function MenuList() {
   return [
        {
            key: 1,
            Name: 'Leave Request',
            right: true,
            component: <LeaveRequest />

        },
        {
            key: 2,
            Name: 'Other Request',
            right: true,
            component: <OtherRequest />
        }
    ]
}
