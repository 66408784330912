import React, { useState, useMemo } from "react";
import partyimg from "../../../../assetsOld/img/party.svg";
import anversaryimg from "../../../../assetsOld/img/anniversaries.svg";
import birthdayimg from "../../../../assetsOld/img/birthday.svg";
import NoDataFound from "./NoDataFound";
import LetterProfilePicture from "../../AdminDashboard/shared/LetterProfilePicture";
import { isFemale, isMale } from "../../../../utils/Constants";

const CelebrationCorner = ({ data, filter, setFilter }) => {
  console.log("CelebrationCorner", data);

  const [showAllMembers, setShowAllMembers] = useState({});
  const handleShowAllMembers = (name) => {
    setShowAllMembers((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <div className="col-md-4 ps-0">
      <div className="celebration_pop admin_celebration_pop card_tabs">
        <div className="d-flex justify-content-between align-items-center mb-2 me-1 pe-2">
          <p className="heading text-nowrap">Celebration Corner</p>
          <div className="input">
            <select
              className="form-control"
              id="RequestsPeriod"
              name="RequestsPeriod"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="Week">This Week</option>
              <option value="Month">This Month</option>
            </select>
          </div>
        </div>
        <div className="d-flex flex-column gap-10 content ar_hov-scrollbar">
          {data?.length > 0 ? (
            data?.map((item, index) => {
              return (
                <div className="d-flex align-items-center br_bottom" key={index}>
                  <div className="w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="heading font-m">{item.name}</p>
                      {item.name === "BirthDay" ? (
                        <img src={birthdayimg} alt="Birthday" />
                      ) : item.name === "Work Anniversaries" ? (
                        <img src={anversaryimg} alt="Work Anniversaries" />
                      ) : item.name === "Retirement Party" ? (
                        <img src={partyimg} alt="Retirement Party" />
                      ) : null}
                    </div>
                    <div className="d-flex gap-15 align-items-center me-2 pb-1 overflow-auto ar_hov-scrollbar">
                      {item?.data?.map((emp, index1) => {
                        if (showAllMembers[item.name] || index1 < 2) {
                          return (
                            <div className="d-flex flex-column align-items-center" key={index1}>
                              <div className="img-container circle border-2 border-white s-45">
                              {emp.ProfilePicture ? (
                              <img src={emp.ProfilePicture} alt="Profile" />
                              ) : (
                              <LetterProfilePicture classes={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-20 text-white ${isMale(emp.Gender) ? 'male' : isFemale(emp.Gender) ? 'female':'rather-undefined'}`} name={emp.EmployeeName} />
                              )}
                              </div>
                              <div className="d-flex flex-column ">
                                <p className="text font-m fs-12 text-center">
                                  {emp.FirstName}
                                </p>
                                <div className="date-text">
                                  <p className="text font-m fs-11 text-center fst-italic">
                                    {emp.EventDate}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                      {item?.data?.length > 2 && (
                        <div
                          className="cursor-pointer text-center"
                          onClick={() => {
                            handleShowAllMembers(item.name);
                          }}
                        >
                          {showAllMembers[item.name] ? (
                            <>
                              <p className="text font-m link-text">
                                -{item?.data?.length - 2}
                              </p>
                              <p className="text font-m link-text">less</p>
                            </>
                          ) : (
                            <>
                              <p className="text font-m link-text">
                                +{item?.data?.length - 2}
                              </p>
                              <p className="text font-m link-text">more</p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="position-relative" style={{ height: 200 }}>
              <NoDataFound />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CelebrationCorner;
