import React from "react"
import { useEffect, useState } from "react"
import { TabContent, TabPane } from 'reactstrap'
import PayrollInnerLeftMenu from "./PayrollInnerLeftMenu";
import PayrollMenu from "./PayrollMenu";

const PayrollTopMenuContent = (props) => {

    const MenuItems = PayrollMenu();
    const [tabs, setTabs] = useState([])
    const [component, setComponent] = useState({})

    useEffect(() => {
        setTabs(props.dynamicTabs)
        const activeComponent = MenuItems.filter((tab, key) => {
            return tab.right === true
        })[0]
        setComponent(activeComponent)
    }, [props.activeTab])

    
    return (
        <>
            {tabs.length > 0 ? (
                <>
                    <TabContent activeTab={props.activeTab}>
                        {tabs &&
                            tabs.map((item) => {
                                return (
                                    <React.Fragment key={item.key}>
                                        <TabPane tabId={item.key}>
                                            <div>
                                                <div>
                                                    <PayrollInnerLeftMenu subCategKey={item.key} />
                                                </div>
                                            </div>
                                        </TabPane>
                                    </React.Fragment>
                                )
                            })}
                    </TabContent>
                </>
            ) : (
                <TabContent activeTab={component.key}>
                    <React.Fragment key={component.key}>
                        <TabPane tabId={component.key}>
                            <div>
                                <div>
                                    <PayrollInnerLeftMenu subCategKey={component.key} />
                                </div>
                            </div>
                        </TabPane>
                    </React.Fragment>
                </TabContent>
            )}
        </>
    )

}

export default PayrollTopMenuContent
