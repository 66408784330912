import React from 'react'
import ClusteredStackedColumnChart from '../Charts/ClusteredStackedColumnChart'
import NoDataFound from './NoDataFound'

const AttendanceOverview = ({data}) => {
    console.log("EEE",data)
    const currentYear = new Date().getFullYear();
    const years = [currentYear, currentYear - 1, currentYear - 2];
  return (
    <div className="dashboard d-flex gap-15 mt-2">
        <div className="attendence_overview card_tabs col-md-12">
            <div className="d-flex justify-content-between">
                <p className="heading">Attendance Overview</p>
                {/* <div class="input">
                    <select class="form-control" id="PaymentType" name="PaymentType">
                    {years.map((item)=>{return <option value={item}>{item}</option>})}
                    </select>
                </div> */}
                <div className='input'>
                    <select className="form-control" name="" id="">
                        <option value="year">2024</option>
                        <option value="year">2023</option>
                    </select>
                </div>
                
            </div>
            {data?.length > 0 ? 
            <ClusteredStackedColumnChart data={data} /> : 
            <div className='position-relative' style={{height:220}}><NoDataFound/></div>}
        </div>
    </div>
  )
}

export default AttendanceOverview