import React from 'react'

const AddAdvancePolicy = (props) => {
    const {Policy_Advance_Modal, mainPolicyAdvanceModal, setMainPolicyAdvanceModal, savePolicyAdvanceModal, setSavePolicyAdvanceModal} = {...props}

    
    const handleOnChange = (e) => {
        const {name, value} = e.target;
        let modal = {...mainPolicyAdvanceModal}
        let saveModal = {...savePolicyAdvanceModal}
        modal[name] = value;
        setMainPolicyAdvanceModal(modal);
        for(let property in Policy_Advance_Modal){
            if(property === name){
                saveModal[property] = value;
            }
        }
        setSavePolicyAdvanceModal(saveModal);
    }

    return (
        <div>
            <div className="d-flex flex-column gap-15">
                <div className={`form-group input ps-0`}>
                    <label>Leave Notice Period</label>
                    <select
                        className="form-control fc-xs"
                        name="NoticePeriod"
                        id="NoticePeriod"
                        aria-label="Default select example"
                        value={mainPolicyAdvanceModal?.NoticePeriod}
                        onChange={handleOnChange}
                    >
                        <option selected></option>
                    </select>
                </div>
                <div className={`form-group input ps-0`}>
                    <label>Send Email Notifications</label>
                    <select
                        className="form-control fc-xs"
                        name="MailNotification"
                        id="MailNotification"
                        aria-label="Default select example"
                        value={mainPolicyAdvanceModal?.MailNotification}
                        onChange={handleOnChange}
                    >
                        <option selected></option>
                    </select>
                </div>
                <div className={`form-group input ps-0`}>
                    <label>Leave Carried Forward</label>
                    <select
                        className="form-control fc-xs"
                        name="CarriedForward"
                        id="CarriedForward"
                        aria-label="Default select example"
                        value={mainPolicyAdvanceModal?.CarriedForward}
                        onChange={handleOnChange}
                    >
                        <option selected></option>
                    </select>
                </div>
                <div className="form-group input">
                    <label>Percentage of Leave Carried Forward </label>
                    <input
                        type="text"
                        className="form-control"
                        name="CarriedForwardPercentage"
                        id="CarriedForwardPercentage"
                        maxLength={250}
                        value={mainPolicyAdvanceModal?.CarriedForwardPercentage}
                        onChange={handleOnChange}
                    ></input>
                </div>
                <div className={`form-group input ps-0`}>
                    <label>Availability Period</label>
                    <select
                        className="form-control fc-xs"
                        name="Availability"
                        id="Availability"
                        aria-label="Default select example"
                        value={mainPolicyAdvanceModal?.Availability}
                        onChange={handleOnChange}
                    >
                        <option selected></option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default AddAdvancePolicy
