import React, { useReducer, useState } from 'react'
import AddNewLeavePolicy from './AddNewLeavePolicy.js/AddNewLeavePolicy';
import DeleteConfirmation from '../../../shared/Delete/DeleteConfirmation';
import { enumUtil } from '../../../../utils/Enum';
import GridColumnHeader from '../../../shared/GridColumnHeader/GridColumnHeader';
import { ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest';
import isNull from '../../../../utils/NullChecking';
import Loader from '../../../shared/Loader/Loader';
import MainTop from '../../../../utils/MainTop';
import CustomUseRef from '../../../shared/CustomUseRef/CustomUseRef';
import ReactDataGrid from 'react-data-grid';

const LeavePolicySearch = () => {
    const [actionsbutton, setActionsbutton] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [PolicyData, setPolicyData] = useState([])
    const [PolicyID, setPolicyID] = useState(0)
    const [status, setStatus] = useState("")
    const [IsPolicyOpen, setIsPolicyOpen] = useState()
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const API_URL = process.env.REACT_APP_API;
    const [delID, setDelID] = useState(0);
    const [delData, setDelData] = useState();
    const [searchParam, setSearchParam] = useState();
    const [EnterParam, setEnterParam] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [PerPage, setPerPage] = useState(10);
    const [PageNo, setPageNo] = useState(1);
    const [TotalPages, setTotalPages] = useState();
    const handlePageClick = (page) => { setPageNo(page); }
    const PreviousPage = () => { if (PageNo > 1) { setPageNo(PageNo - 1) } }
    const NextPage = () => { if (PageNo < TotalPages) setPageNo(PageNo + 1); }
    const handleFingByCreteria = (e) => {
        setPerPage(e.target.value)
        setPageNo(1)
    }
    const [gridHeader, handleGridHeader] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, { defaultData: '', activeSortIcon: '' })

    const CloseActionPopup = () => {
        setActionsbutton(false);
    }
    const handeActionPopUp = (e, id) => {
        e.stopPropagation();
        setActionsbutton(true);
        setSelectedRow(id);
    }

    const rows = [
        { id: 1, PolicyName: 'EID', LeaveType: 'Shawal Moon', AllocationFrequency: '01/01/2024', AllowedLeaves: 'Haris', CreatedBy: 'Haris', CreatedDate: '01/10/2024', Status: 'Approved' },
        { id: 2, PolicyName: 'Shab e barat', LeaveType: 'Night of blessings', AllocationFrequency: '01/01/2024', AllowedLeaves: 'Taimoor', CreatedDate: '01/10/2024', Status: 'Approved' },
        { id: 3, PolicyName: 'Eid Ul Azha', LeaveType: 'Zil Haj Moon', AllocationFrequency: '01/01/2024', AllowedLeaves: 'Muhammad Usman', CreatedDate: '01/10/2024', Status: 'Approved' },
        { id: 4, PolicyName: 'Defence Day', LeaveType: 'Hifazti din', AllocationFrequency: '01/01/2024', AllowedLeaves: 'Sheery', CreatedDate: '01/10/2024', Status: 'Denied' },
        { id: 5, PolicyName: 'Youm e takbeer', LeaveType: 'Atom Bomb', AllocationFrequency: '01/01/2024', AllowedLeaves: 'Abdul Mateen', CreatedDate: '01/10/2024', Status: 'Pending' },
    ]
    const columns = [
        {
            key: 'PolicyName',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Policy Name'} sortIcon={true} type={'string'} colKey={'PolicyName'} data={PolicyData} setData={setPolicyData} />),

            resizable: true,
            flex: 1,
            formatter: ({ row }) => {
                return (
                    <button className='btn btn-link' onClick={""}>
                        {row?.PolicyName}
                    </button>
                )
            }
        },
        {
            key: 'LeaveType',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Leave Type'} sortIcon={true} type={'string'} colKey={'LeaveType'} data={PolicyData} setData={setPolicyData} />),

            resizable: true,
            flex: 1,
            // formatter: ({ row }) => {
            //     return (
            //         <div className="validation_message" title={row?.Description}>
            //             {row?.Description}
            //         </div>
            //     )
            // }
        },
        {
            key: 'AllocationFrequency',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Allocation Frequency'} sortIcon={true} type={'date'} colKey={'AllocationFrequency'} data={PolicyData} setData={setPolicyData} />),

            resizable: true,
            flex: 1,
        },
        {
            key: 'AllowedLeaves',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Allowed Leaves'} sortIcon={true} type={'string'} colKey={'AllowedLeaves'} data={PolicyData} setData={setPolicyData} />),

            resizable: true,
            flex: 1,
        },
        {
            key: 'CreatedBy',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Created By'} sortIcon={true} type={'string'} colKey={'CreatedBy'} data={PolicyData} setData={setPolicyData} />),

            resizable: true,
            flex: 1,

        },
        {
            key: 'CreatedDate',
            name: (<GridColumnHeader gridHeader={gridHeader} handleGridHeader={handleGridHeader}
                iconName={'icon-card'} headerName={'Created Date'} sortIcon={true} type={'string'} colKey={'CreatedDate'} data={PolicyData} setData={setPolicyData} />),

            resizable: true,
            flex: 1,
            // formatter: ({ row }) => {
            //     return (

            //         <div class="form-check form-switch">
            //             <input
            //                 class="form-check-input border-0 rounded-5 shadow"
            //                 type="checkbox"
            //                 role="switch"
            //                 id="flexSwitchCheckChecked"
            //             />
            //         </div>
            //     )
            // }
        },
        {
            key: 'Actions',
            name: 'Actions',
            width: 80,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <>
                        <div className="w-50 text-start" onClick={(e) => handeActionPopUp(e, row?.ID)} >
                            <i className="icon-menuicon cursor-pointer"></i>
                        </div>
                        {console.log("selectedRow === row?.EmployeeDocumentID", selectedRow === row?.ID, selectedRow, row?.ID)}
                        {selectedRow === row?.ID &&
                            <CustomUseRef onClose={CloseActionPopup} IsActivePtPayments={actionsbutton}>
                                <div className="actions_popup cursor-pointer">
                                    <p className="text  p-2 py-0" onClick={() => AddPolicy(row)}><i className="icon-edit-pencil me-1"></i>Edit</p>
                                    {row?.Status === "Pending" && <p className="text p-2 py-0" onClick={() => handleDeleteModal(row?.ID)}><i className="icon-Group-3994 me-1"></i>Delete</p>}
                                </div>
                            </CustomUseRef>
                        }
                    </>
                );
            },
        },
    ];

    const handleDeleteModal = (row) => {
        setDelID(row?.CheckOutRequestID);
        setDelData(row);
        setIsOpenAlert(true);
    }

    const onCloseDelete = () => {
        setDelID(0);
        setIsOpenAlert(false)
        // LoadData()
    }

    const onClose = () => {
        setIsOpenAlert(false)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.LeavePolicy} ID={delID} data={delData} onClose={onClose} />
    }

    const AddPolicy = (row) => {
        setPolicyID(row?.ID);
        setIsPolicyOpen(true);
    }

    const OnClosePolicy = () => {
        setIsPolicyOpen(false);

    }
    const OnClosePolicyOnSave = () => {
        setIsPolicyOpen(false);
        LoadData(searchParam)
    }

    let PopUpScreen = ""
    if (IsPolicyOpen) {
        PopUpScreen = <AddNewLeavePolicy isOpenPopup={IsPolicyOpen} onClose={OnClosePolicy} onCloseSave={OnClosePolicyOnSave} ID={PolicyID} setIsRefresh={setIsRefresh} />
    }

    const LoadData = (param) => {
        setPolicyData([])
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/FindByMultipleCriteria", 'post', {
            "TableName": enumUtil.enumAPITableName.LeavePolicy,
            "SearchParam": param,
            "PerPage": PerPage,
            "PageNo": PageNo,
        }).then((response) => {
            setIsLoading(false);
            setPolicyData(response);
            handleGridHeader({ defaultData: response })
            setTotalPages(response[0].NumOfPages);
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false);
        })
    }

    const SearchOnEnter = (e) => {
        if (e.key === "Enter") {
            setEnterParam(e.target.value)
            // setSearchParam(EnterParam)
            SearchByParam(searchParam)
        }
    }
    
    const SearchByParam = (searchParam) => {
        if (!isNull(searchParam)) {
            setPageNo(1)
            LoadData(searchParam)
        } else {
            LoadData()
        }
    }
    const Change = (e) => {
        setSearchParam(e.target.value)
    }
    return (
        <div className='table-view all_employee'>
            {PopUpScreen}
            {ConfirmDelete}
            {isLoading ? <Loader /> : <></>}

            <MainTop openModal={AddPolicy} searchParam={searchParam} Change={Change} LoadData={LoadData} SearchOnEnter={SearchOnEnter} />
            <ReactDataGrid
                enableVirtualization={false}
                // virtualizeColumns={columns.length > 10}
                columns={columns}
                rows={rows}
                rowHeight={30}
                className={"fill-grid DataGrid-Table hk-exp-table all_employee_grid"}
                style={{
                    width: '100%',
                    // height:'100px',
                    // maxHeight: '330px',
                }}
            />
        </div>
    )
}

export default LeavePolicySearch
