import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import close from "../../../../assetsOld/img/x-close.svg"
import { useSelector } from "react-redux"
import handleNumericCheck from "../../../../utils/HandleNumericKeys";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"


const AddNewVendor = (props) => {
    let Vendor_Modal = {
        VendorName: "",
        Address: "",
        City: "",
        PhoneNumber: "",
        MobileNumber: "",
        Email: "",
        Website: "",

    }

    let valModal = {}
    let ValidationModal = {
        VendorName: "",
        VendorEmail: "",
        VendorWebsite: "",
    }
    const [newVendorSaved, setNewVendorSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [VendorValidationModal, setVendorValidationModal] = useState(ValidationModal)
    const [VendorID, setVendorID] = useState()
    const [VendorSaveUpdateModal, setVendorSaveUpdateModal] = useState({})
    const [mainVendorModal, setmainVendorModal] = useState(Vendor_Modal);
    const [del, setDel] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenVendor, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setIsLoading(false)
        // right now, use any providerid to test update case/find by id
        if (props.VendorID != null) {
            setVendorID(props.VendorID)
            setDel(true)
        } else {
            if (!newVendorSaved) {
                setVendorID(0)
                setDel(false)
            }
            else { setDel(true) }
        }
        if (!isNull(VendorID)) {
            setIsLoading(true)
            // setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": VendorID,
                "TableName": enumUtil.enumAPITableName.Vendor
            }).then((response) => {
                setmainVendorModal(response);
                setIsLoading(false)

            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [VendorID])

    const VendorValidation = async () => {
        let ValidationModal = {
            VendorName: FormValidator(
                mainVendorModal.VendorName,
                enumUtil.enumValidationType.Required,
                'Vendor Name',
            ),
            VendorEmail: FormValidator(
                mainVendorModal.Email,
                enumUtil.enumValidationType.Email,
                "Email"
            ),
            VendorWebsite: FormValidator(
                mainVendorModal.Website,
                enumUtil.enumValidationType.Website,
                "Website"
            ),
        }

        await setVendorValidationModal(ValidationModal)
        valModal = ValidationModal
    }

    const handleVendorChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainVendorModal };
        let saveModal = { ...VendorSaveUpdateModal }
        modal[name] = value
        setmainVendorModal(modal)
        for (var property in Vendor_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setVendorSaveUpdateModal(saveModal)
    }

    const handleSaveVendor = async () => {
        await VendorValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            // NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Vendor,
                ID: VendorID,
                data: { ...VendorSaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewVendorSaved(true)
                    setVendorID(response.id)
                    setIsRefresh(true)
                    onCloseSave()
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(true)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    const SetVendorName = () => {
        if (mainVendorModal.VendorName != null && mainVendorModal.VendorName != "") {
            return (
                <p className="heading validation_message">{mainVendorModal.VendorName}</p>
            )
        } else {
            return (
                <p className="heading">Add New Vendor</p>
            )
        }
    }
    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup = ""
    if (del) {
        DeletePopup = <button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Vendor} ID={VendorID} onClose={onClose} />
    }
    //////////

    return (
        <>
            {ConfirmDelete}
            <React.Fragment>
                <Modal show={isOpenVendor} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal modal-c-lg  add_new_vendor ems_modal">
                    <Modal.Body>
                        <div className='d-flex justify-content-end'>
                            <img className="close cursor-pointer" src={close} onClick={() => onClose(newVendorSaved)}></img>
                            {/* {DeletePopup} */}
                        </div>
                        <div className="modal_header">
                            <div className="text-center">
                                <span className="icon">
                                    <div className="icon_bg">
                                        <i className="icon-plus-icon"></i>
                                    </div>
                                </span>
                                <p class="heading mt-2"> {SetVendorName()}</p>
                            </div>
                        </div>
                        {isLoading ? <Loader /> : null}
                        <div className="row">
                            <div className="col-md-6">
                                <div className={VendorValidationModal.VendorName ? "form-group input error" : "form-group input"}>
                                    <label>Vendor Name<span className="req">*</span></label>
                                    <input type="text"
                                        className="form-control pro_name"
                                        name="VendorName"
                                        id="VendorName"
                                        maxLength={50}
                                        value={mainVendorModal.VendorName}
                                        onChange={handleVendorChange}
                                    />
                                    {VendorValidationModal.VendorName}
                                </div>
                                <div className='form-group input'>
                                    <label>City</label>
                                    <input type="text"
                                        className="form-control"
                                        name="City"
                                        id="City"
                                        maxLength={50}
                                        value={mainVendorModal.City}
                                        onChange={handleVendorChange}
                                    />
                                </div>
                                <div className='form-group input'>
                                    <label>Phone #</label>
                                    <input type="text"
                                        className="form-control"
                                        name="PhoneNumber"
                                        id="PhoneNumber"
                                        maxLength={15}
                                        value={mainVendorModal.PhoneNumber}
                                        onChange={handleVendorChange}
                                        onKeyPress={(event) => handleNumericCheck(event)}
                                    />
                                </div>
                                <div className={VendorValidationModal.VendorWebsite ? "form-group input error" : "form-group input"}>
                                    <label>Website</label>
                                    <input type="text"
                                        className="form-control"
                                        name="Website"
                                        id="Website"
                                        maxLength={256}
                                        value={mainVendorModal.Website}
                                        onChange={handleVendorChange}
                                    />
                                    {VendorValidationModal.VendorWebsite}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-group input'>
                                    <label>Address</label>
                                    <textarea
                                        rows={4}
                                        type="text"
                                        className="form-control"
                                        name="Address"
                                        id="Address"
                                        maxLength={250}
                                        value={mainVendorModal.Address}
                                        onChange={handleVendorChange}
                                        placeholder="Type Address"
                                    />
                                </div>

                            
                                <div className={VendorValidationModal.VendorEmail ? "form-group input error" : "form-group input"}>
                                    <label>Email</label>
                                    <input type="email"
                                        className="form-control"
                                        name="Email"
                                        id="Email"
                                        maxLength={50}
                                        value={mainVendorModal.Email}
                                        onChange={handleVendorChange}
                                    />
                                    {VendorValidationModal.VendorEmail}
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <ModalFooter isSaved={onClose} handleSave={handleSaveVendor} />
                    {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                        <button className='btn btn-gray' onClick={() => onClose(newVendorSaved)}>Cancel</button>
                        <button className='btn btn-orange' onClick={handleSaveVendor}>Save</button>
                    </div> */}
                </Modal>



            </React.Fragment>
        </>
    )
}

export default AddNewVendor