import React, { useEffect, useState } from 'react'
import ModalFooter from '../../../shared/ModalFooter/ModalFooter'
import { enumUtil } from '../../../../utils/Enum'
import FormValidator from '../../../../utils/FormValidator'
import close from "../../../../assetsOld/img/x-close.svg"
import NotificationHandler from '../../../../utils/NotificationHandler'
import Modal from 'react-bootstrap/Modal'
import { ServerRequestProtected, ServerRequestProtectedParentID } from '../../../../utils/ServerDataRequest'
import isNull from '../../../../utils/NullChecking'
import CalendarInput from '../../../../utils/CalendarInput'
import { useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode';
import { DateFormat, DateFormat_dMy, DateFormatCalculations, DateFormat_YMd_Time } from "../../../../utils/Constants"
import { format } from 'date-fns'
import Loader from '../../../shared/Loader/Loader'
import handleNumericCheck from "../../../../utils/HandleNumericKeys";
import DatePicker from '../../../../utils/DatePicker'

const AddNewCareerGrowth = (props) => {

    const { isCareerOpen, onClose, ID, setIsRefresh, type } = { ...props }

    const API_URL = process.env.REACT_APP_API
    let token = useSelector(state => state.TokenReducer);
    let activeEmployee = useSelector(state => state.EmployeeActiveTabReducer);
    let Token = token && jwt_decode(token)
    let AdminID = Token.UserID;
    let EmployeeID = "";
    if (!isNull(type) && type === "Employee") {
        EmployeeID = activeEmployee;
    }
    // else {
    //     EmployeeID = Token.UserID;
    // }
    const OrganizationID = useSelector((state) => state.Organization);

    let Growth_Modal = {
        StartDate: null,
        CurrentStatus: "",
        CurrentSalary: "",
        PreviousStatus: "",
        Type: "",
        IncrementedAmount: "",
        PreviousSalary: "",
        Remarks: "",
        EndDate: null,
        JobTitle: "",
        DesignationID: "",
    }

    let valModal = {}
    const ValidationModal = {
        StartDate: "",
        CurrentStatus: "",
        Description: "",
        CurrentSalary: "",
        PreviousStatus: "",
        Type: "",
        IncrementedAmount: "",
        PreviousSalary: "",
    }

    const [isLoading, setIsLoading] = useState(false)
    const [MainGrowthModal, setMainGrowthModal] = useState(Growth_Modal);
    const [GrowthSaveModal, setGrowthSaveModal] = useState({});
    const [CareerGrowthID, setCareerGrowthID] = useState(0);
    const [GrowthValidationModal, setGrowthValidationModal] = useState(ValidationModal);
    const [addDesignstionDropDown, setAddDesignstionDropDown] = useState([]);
    const [startDate, setStartDate] = useState();
    const openStartDate = Boolean(startDate)
    const openCalendarMenu = (event) => {
        setStartDate(event.currentTarget)
    };
    const closeCalendarMenu = () => {
        setStartDate(null)
    };

    const handleGrowthChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...MainGrowthModal }
        let saveModal = { ...GrowthSaveModal }
        if (name === "IncrementedAmount") {
            console.log("INCREMENTED", (value || 0), modal["PreviousSalary"])
            modal["CurrentSalary"] = (parseInt(value || 0) + modal["PreviousSalary"]) + "";
        }
        modal[name] = value;
        setMainGrowthModal(modal);
        for (let property in Growth_Modal) {
            if (name === property) {
                if (name === "IncrementedAmount") {
                    saveModal["CurrentSalary"] = ((parseInt(value) || 0) + modal["PreviousSalary"]) + "";
                }
                saveModal[name] = value;
            }
        }
        setGrowthSaveModal(saveModal)
    }

    const handleCalendarChange = (date, name) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, "MM/dd/yyyy")

        let modal = { ...MainGrowthModal };
        let saveModal = { ...GrowthSaveModal }
        modal[name] = FormatedDateShow
        setMainGrowthModal(modal)
        for (var property in Growth_Modal) {
            if (name === property) {
                saveModal[name] = FormatedDateSave
            }
        }
        setGrowthSaveModal(saveModal)
        closeCalendarMenu()
    }
    const GrowthValidation = () => {
        let ValidationModal = {
            StartDate: FormValidator(
                MainGrowthModal.StartDate,
                enumUtil.enumValidationType.Required,
                "Start Date"
            ),
            // CurrentStatus: FormValidator(
            //     MainGrowthModal.CurrentStatus,
            //     enumUtil.enumValidationType.Required,
            //     "Current Status"
            // ),
            // PreviousStatus: FormValidator(
            //     MainGrowthModal.PreviousStatus,
            //     enumUtil.enumValidationType.Required,
            //     "New Status"
            // ),
            // Type: FormValidator(
            //     MainGrowthModal.Type,
            //     enumUtil.enumValidationType.Required,
            //     "Type"
            // ),
            // CurrentSalary: FormValidator(
            //     MainGrowthModal.CurrentSalary,
            //     enumUtil.enumValidationType.Required,
            //     "Current Salary"
            // ),
            PreviousSalary: FormValidator(
                MainGrowthModal.PreviousSalary,
                enumUtil.enumValidationType.Required,
                "Previous Salary"
            ),
            IncrementedAmount: FormValidator(
                MainGrowthModal.IncrementedAmount,
                enumUtil.enumValidationType.Required,
                "Increment"
            ),



        }
        setGrowthValidationModal(ValidationModal);
        valModal = ValidationModal;
    }

    const handleCareerGrowth = (e) => {
        GrowthValidation();
        let Validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (Validation) {
            NotificationHandler("Fill all Required Fields", enumUtil.enumtoaster.error)
            return
        } else {
            setIsLoading(true);
            // GrowthSaveModal["UserID"] = AdminID;
            GrowthSaveModal["EmployeeID"] = EmployeeID + "";
            // GrowthSaveModal["OrganizationID"] = OrganizationID;
            let saveModal = {
                TableName: enumUtil.enumAPITableName.CareerGrowth,
                ID: CareerGrowthID,
                Data: { ...GrowthSaveModal }
            }
            ServerRequestProtected(API_URL + "/SaveSingleRecord", "post", saveModal).then((response) => {
                setIsLoading(false);
                NotificationHandler("Record Saved Successfully", enumUtil.enumtoaster.success);
                setCareerGrowthID(0);
                // setIsRefresh(true)
                onClose(true)
            }).catch((error) => {
                setIsLoading(false)
                NotificationHandler("Error while saving", enumUtil.enumtoaster.error);
                setIsRefresh(false)
            })
        }
    }

    useEffect(() => {
        // if (!isNull(ID)) {
        setCareerGrowthID(ID)
        setIsLoading(true)
        ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
            "ID": ID,
            "TableName": enumUtil.enumAPITableName.CareerGrowth,
            "SearchParam": EmployeeID + "",
        }).then((response) => {
            setIsLoading(false)
            // console.log(response.dt)
            setMainGrowthModal({...MainGrowthModal, ...response })
        }).catch((error) => {
            NotificationHandler(error.message, enumUtil.enumtoaster.error)
            setIsLoading(false)
        })
        // }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Designation,
        }).then((response) => {
            setIsLoading(false)
            setAddDesignstionDropDown(response)
        }).catch((error) => {

            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })
    }, [ID])

    return (
        <div>
            <Modal show={isCareerOpen} onHide={isCareerOpen} backdrop="static" keyboard={false} centered dialogClassName={`${type ? '' : 'ar_disable-popup'} app-modal ems_modal add_new_des add_new_growth`}>
                <Modal.Body>
                    {isLoading ? <Loader /> : <></>}
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={() => onClose()}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Vector-10"></i>
                            </span>
                            <p class="heading mt-2">Update Status  </p>
                        </div>
                    </div>
                    {console.log("mainModal",MainGrowthModal)}
                    <div className={`form-group input ${GrowthValidationModal.StartDate && 'error'}`}>
                        <DatePicker
                            label={<label for="">Start Date<span className='req'>*</span></label>}
                            value={MainGrowthModal.StartDate}
                            openCalendarMenufunc={(event) => openCalendarMenu(event, 'StartDate')}
                            // maxDate={new Date()}
                            anchorEl={startDate}
                            open={openStartDate}
                            closeCalendarMenufunc={closeCalendarMenu}
                            handleCalendarChangefunc={date => handleCalendarChange(date, 'StartDate')}
                            placeholder={true}
                        />
                        
                        {GrowthValidationModal.StartDate}
                    </div>
                    <div className={"form-group input"}>
                        <label>Job Title</label>
                        <input type="text"
                            maxLength={100}
                            className="form-control"
                            name="JobTitle"
                            id="JobTitle"
                            value={MainGrowthModal.JobTitle}
                            onChange={(e) => handleGrowthChange(e)}
                            autoComplete="off"
                        />
                        {/* {EmployeeValidationModal.JobTitle} */}
                    </div>
                    <div className={"form-group input"}>
                        <label>Designation</label>
                        <select className="form-control"
                            name="DesignationID"
                            id="DesignationID"
                            aria-label="Default select example"
                            value={MainGrowthModal.DesignationID}
                            onChange={(e) => handleGrowthChange(e)}
                        >
                            <option value="">Select</option>

                            {addDesignstionDropDown.map((e, index) => (
                                <option value={e.DesignationID} key={index}>
                                    {e.DesignationName}
                                </option>
                            ))}
                            {/* <option value="Intern">Intern</option>
                                                    <option value="Software Developer">Software Developer</option> */}
                        </select>
                        {/* {EmployeeValidationModal.DesignationID} */}
                    </div>
                    <div className={"form-group input"}>
                        <label>Previous  Status<span className="req">*</span></label>
                        {/* <select className="form-control pro_name" name="PreviousStatus" id="PreviousStatus" aria-label="Default select example" value={MainGrowthModal.PreviousStatus} onChange={(e) => handleGrowthChange(e)}>
                            <option value="">Select</option>
                            <option value="1">HP LAPTOP</option>
                            <option value="26">DELL LAPTOP 5</option>
                        </select> */}
                        <input className="form-control pro_name" readOnly disabled name="PreviousStatus" id="PreviousStatus" aria-label="Default select example" value={MainGrowthModal.PreviousStatus} onChange={(e) => handleGrowthChange(e)} />
                        {/* {GrowthValidationModal.PreviousStatus} */}
                    </div>
                    <div className={" form-group input"}>
                        <label>Current Status</label>
                        <select className="form-control"
                            name="CurrentStatus"
                            id="CurrentStatus"
                            aria-label="Default select example"
                            value={MainGrowthModal.CurrentStatus}
                            onChange={(e) => handleGrowthChange(e)}
                        >
                            <option value="">Select</option>
                            {/* {ddRoles?.map((item) => {
                                                        return (
                                                            <option value={item?.Id}>{item?.Name}</option>
                                                        )
                                                    })} */}

                            <option value="Intern">Intern</option>
                            <option value="PartTime">Part Time</option>
                            <option value="Contract">Contract</option>
                            <option value="Permanent">Permanent</option>
                            <option value="Consultant">Consultant</option>
                            {/* <option value="SuperAdmin">Super Admin</option> */}
                        </select>
                        {/* {GrowthValidationModal.CurrentStatus} */}
                    </div>
                    {/* <div className={GrowthValidationModal.CurrentStatus ? "form-group input error" : "form-group input"}>
                        <label for="">Current Status<span className='req'>*</span></label>
                        <input type="text" className="form-control" name="CurrentStatus" id="CurrentStatus" maxlength="15" value={MainGrowthModal.CurrentStatus} onChange={(e) => handleGrowthChange(e)} />
                        {GrowthValidationModal.CurrentStatus}
                    </div> */}


                    {/* <div className={GrowthValidationModal.Type ? "form-group input error" : "form-group input"}>
                        <label>Type<span className="req">*</span></label>
                        <select className="form-control pro_name" name="Type" id="Type" aria-label="Default select example" value={MainGrowthModal.Type} onChange={(e) => handleGrowthChange(e)}>
                            <option value="">Select</option>
                            <option value="1">HP LAPTOP</option>
                            <option value="26">DELL LAPTOP 5</option>
                        </select>
                        {GrowthValidationModal.Type}
                    </div> */}
                    <div className={"form-group input"}>
                        <label for="">Previous Salary<span className='req'>*</span></label>
                        <input type="text" className="form-control" name="PreviousSalary" id="PreviousSalary" readOnly disabled maxlength="15" value={MainGrowthModal.PreviousSalary} onChange={(e) => handleGrowthChange(e)} onKeyPress={(event) => handleNumericCheck(event)} />
                        {GrowthValidationModal.PreviousSalary}
                    </div>
                    <div className={GrowthValidationModal.IncrementedAmount ? "form-group input error" : "form-group input"}>
                        <label for="">Increment<span className='req'>*</span></label>
                        <input type="text" className="form-control" name="IncrementedAmount" id="IncrementedAmount" maxlength="15" value={MainGrowthModal.IncrementedAmount} onChange={(e) => handleGrowthChange(e)} onKeyPress={(event) => handleNumericCheck(event)} />
                        {GrowthValidationModal.IncrementedAmount}
                    </div>
                    <div className={GrowthValidationModal.CurrentSalary ? "form-group input error" : "form-group input"}>
                        <label for=""> Current Salary<span className='req'>*</span></label>
                        <input type="text" readOnly disabled className="form-control" name="CurrentSalary" id="CurrentSalary" maxlength="15" value={MainGrowthModal.CurrentSalary} onChange={(e) => handleGrowthChange(e)} onKeyPress={(event) => handleNumericCheck(event)} />
                        {GrowthValidationModal.CurrentSalary}
                    </div>
                    <div className={"form-group input"}>
                        <label for="">Remarks</label>
                        <textarea rows={"3"} type="text" className="form-control" name="Remarks" id="Remarks" maxlength="250" value={MainGrowthModal.Remarks} onChange={(e) => handleGrowthChange(e)} />
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleCareerGrowth} />

            </Modal>
        </div>
    )
}

export default AddNewCareerGrowth
