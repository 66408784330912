import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import NotificationHandler from "../../../../utils/NotificationHandler"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import close from "../../../../assetsOld/img/x-close.svg"
import Modal from 'react-bootstrap/Modal';
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"

const AddNewExpenseCategory = (props) => {

    let Category_Modal = {
        CategoryName: "",
        CategoryCode: "",
    }

    let valModal = {}
    let ValidationModal = {
        CategoryName: "",
    }
    const [newExpenseCategorySaved, setNewExpenseCategorySaved] = useState(false);
    const [CategoryValidationModal, setCategoryValidationModal] = useState(ValidationModal)
    const [CategoryID, setCategoryID] = useState(0)
    const [CategorySaveUpdateModal, setCategorySaveUpdateModal] = useState({})
    const [mainCategoryModal, setmainCategoryModal] = useState(Category_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [del, setDel] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenCategory, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setIsLoading(false)
        if (props.CategoryID != null) {
            setCategoryID(props.CategoryID)
            setDel(true)
        } else {
            if (!newExpenseCategorySaved) {
                setCategoryID(0)
                setDel(false)
            }
            else { setDel(true) }
        }
        if (!isNull(CategoryID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": CategoryID,
                "TableName": enumUtil.enumAPITableName.ExpenseCategory,
            }).then((response) => {

                setIsLoading(false)
                setmainCategoryModal(response)
                // setPracticeID(response.PracticeID)
            }).catch((error) => {

                NotificationHandler(error.message)
                setIsLoading(false)
            })
        }
    }, [CategoryID])

    const handleCategoryChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainCategoryModal };
        let saveModal = { ...CategorySaveUpdateModal }

        if( name === "CategoryCode" ){
          let truncateValue= handleCodeValidation(value);  
          console.log("truncVal",truncateValue)
          modal["CategoryCode"] = truncateValue;
        

        }
        else{
        modal[name] = value;
        // setmainCategoryModal(modal);
        }
        setmainCategoryModal(modal);
        for (var property in Category_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setCategorySaveUpdateModal(saveModal)
    }

    const handleCodeValidation =(value) => {
      let truncateVal
        if(value.length > 19){
           truncateVal = value.slice(0,20);
           return truncateVal;
        }
        else{
            return value
        }

    }

    const CategoryValidation = async () => {
        let ValidationModal = {
            CategoryName: FormValidator(
                mainCategoryModal.CategoryName,
                enumUtil.enumValidationType.Required,
                'Category Name',
            ),
        }
        await setCategoryValidationModal(ValidationModal)
        valModal = ValidationModal
    }


    const handleSaveCategory = async (e) => {
        await CategoryValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            setCategorySaveUpdateModal(CategorySaveUpdateModal)

            let saveModal = {
                TableName: enumUtil.enumAPITableName.ExpenseCategory,
                ID: CategoryID,
                data: { ...CategorySaveUpdateModal },
                value: null
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveSingleRecord", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewExpenseCategorySaved(true);
                    setCategoryID(response.id);
                    setIsRefresh(true);
                    onCloseSave();
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false)
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })
        }
    }
    // const handleExpensesCategoryDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": CategoryID,
    //         "TableName": enumUtil.enumAPITableName.ExpenseCategory
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Expense Category Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }
    const SetExpenseCategoryName = () => {
        if (mainCategoryModal.CategoryName != null && mainCategoryModal.CategoryName != "") {
            return (
                <p className="heading validation_message" title={mainCategoryModal.CategoryName}>{mainCategoryModal.CategoryName}</p>
            )
        } else {
            return (
                <p className="heading validation_message pb-1">Add Expense Type</p>
            )
        }

    }
    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup = ""
    if (del) {
        DeletePopup = <button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.ExpenseCategory} ID={CategoryID} onClose={onClose} />
    }
    //////////
    return (
        <>
            <Modal show={isOpenCategory} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal expense_type">
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={onClose}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="icon_bg">
                                    <span className="icon-expense">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </span>
                                </div>
                            </span>
                            <p class="heading mt-2"> {SetExpenseCategoryName()}</p>
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}
                    <div className="mt-2">
                        <div className={CategoryValidationModal.CategoryName ? "form-group input error" : "form-group input"}>
                            <label>Category Name<span className="req">*</span></label>
                            <input type="text"
                                className="form-control "
                                name="CategoryName"
                                id="CategoryName"
                                maxLength={50}
                                value={mainCategoryModal.CategoryName}
                                onChange={handleCategoryChange}
                            />
                            {CategoryValidationModal.CategoryName}
                        </div>
                        <div className="form-group input">
                            <label>Category Code</label>
                            <input type="text"
                                className="form-control"
                                name="CategoryCode"
                                id="CategoryCode"
                                maxLength={20}
                                value={mainCategoryModal.CategoryCode}
                                onChange={handleCategoryChange}
                                onKeyPress={(event) => handleNumericCheck(event)}
                            />

                        </div>
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveCategory} />
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                        <button className='btn btn-gray' onClick={() => onClose(newExpenseCategorySaved)}>Cancel</button>
                        <button className='btn btn-orange' onClick={handleSaveCategory}>Save</button>
                    </div> */}
            </Modal>


        </>
    )
}

export default AddNewExpenseCategory