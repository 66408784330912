import React, { useState } from 'react'
import MenuList from './RequestMenu'
import { Nav, NavItem, NavLink } from 'reactstrap'
import RequestTopMenuContent from './RequestTopMenuContent';

const RequestTopMenu = () => {
    let MenuItems = MenuList();
    let activeComponent = MenuItems.filter((item) => item.right === true)[0];
    const [tabList, SetTabList] = useState([]);
    const [activeTab, setActiveTab] = useState(1)
    const handleActiveItem = (item) => {

        let { key, Name, component } = item
        let dynamicTab = [...tabList];

        // if (dynamicTab?.length === 0) {
        //     let obj = {
        //         key:
        //     }
        // }
        let newObj = {
            key: key,
            name: Name,
            component: component,
        }
        let tabExist = tabList?.find((tab) => tab.key === item.key)

        if (!tabExist) {
            dynamicTab = [...dynamicTab, newObj]
            SetTabList(dynamicTab);
        }
        setActiveTab(key)
    }
    return (
        <div>
            <div className='app-tabs'>
                <div className='tabs-header position-relative mx-auto'>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="tabs-v2-custom ar_tab-v2-custom">
                            <Nav variant="tabs" className="rb-tabs-v3 tabs_view mb-2 nav-tabs">
                                {MenuItems?.map((Link) => {
                                    return (
                                        <React.Fragment key={Link.key}>
                                            <NavItem onClick={() => handleActiveItem(Link)}>
                                                <NavLink className={activeTab === Link.key ? 'active' : ''}>
                                                    {Link.Name}
                                                </NavLink>
                                            </NavItem>
                                        </React.Fragment>
                                    )
                                })}
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
            <RequestTopMenuContent activeTab={activeTab} dynamicTab={tabList} />
        </div >
    )
}

export default RequestTopMenu
