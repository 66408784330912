import NotificationHandler from "../utils/NotificationHandler";
import isNull from "../utils/NullChecking";

export default function OPEN_TAB_PAYROLL(state = [], action) {
    if (action?.type === 'OPEN_TAB_PAYROLL') {
        let arrayList; let arrayObject;

        if (action.payload.data) {
            arrayObject = action.payload.data;

            // check if we have data in data object
            if (arrayObject.length === 0) {
                arrayList = []
            } else {

                if (isNull(action.payload.id)) {
                    let initiallData = [...state]
                    let isTabExist = [...state].filter((tab) => {
                        return tab.key === arrayObject.key && tab.data.id === arrayObject.data.id
                    })
                    if (!(isTabExist && isTabExist.length > 0)) {
                        let patientTabs = state.filter(el => el.key === 2)
                        if (patientTabs.length === 5 && arrayObject.key === 2) {
                            NotificationHandler('Sorry! You can add maximum 5', 'success')
                            arrayList = [...state]
                        } else {
                            arrayObject.active = true
                            initiallData.forEach((row) => (row.active = false))
                            arrayList = [...state, arrayObject]
                        }
                    } else {
                        initiallData.forEach((row, index) => {
                            if (arrayObject.data.id === row.data.id) {
                                row.active = true
                                initiallData[index] = arrayObject;
                            } else {
                                row.active = false
                            }
                        })
                        arrayList = initiallData
                    }
                } else {
                    let updatedArray = [...state].filter((items) => items.data.id !== action.payload.id)
                    let arrayLength = updatedArray.length;
                    if (arrayLength > 0) {
                        updatedArray[arrayLength - 1].active = true
                    }

                    arrayList = updatedArray
                }
            }
            return arrayList

        } else {
            return []
        }
    }
    if (action?.type === 'UPDATE_TAB_PAYROLL') {
        return action.payload.data;
    }
    return state
}