import React, { useEffect, useState } from "react";
import { TabContent, TabPane } from 'reactstrap'

const PayrollInnerLeftMenuContent = (props) => {

  const [tabs, setTabs] = useState([])
  const [component, setComponent] = useState({})

  useEffect(() => {
    setTabs(props.dynamicTabs)
    const activeComponent = props.subCategItems.filter((tab, key) => {
      return tab.right === true
    })[0]
    if (activeComponent !== undefined) {
      setComponent(activeComponent)
    }

  }, [props.activeTab, props.subCategItems])

  return (
    <>
      {tabs.length > 0 ? (
        <>
          <TabContent activeTab={props.activeTab} className="content">
            {tabs &&
              tabs.map((item) => {
                return (
                  <React.Fragment key={item.key}>
                    <TabPane tabId={item.key}>{item.component}</TabPane>
                  </React.Fragment>
                )
              })}
          </TabContent>
        </>
      ) : (
        <TabContent activeTab={component.key} className="content">
          <React.Fragment key={component.key}>
            <TabPane tabId={component.key}>{component.component}</TabPane>
          </React.Fragment>
        </TabContent>
      )}

    </>
  )
}

export default PayrollInnerLeftMenuContent



