import React, { useState } from 'react';
import Chart from 'react-apexcharts';
// import useSidebarSubMainTab from '../../../shared/HandleOpenSidebarSubTab/useSidebarSubMainTab';

const BarChart = ({data,type}) => {
  // let seriesData = data.map(item=> { return {name:item.name ,data:item.data}});
  let used = data?.Used?.padStart(2, '0');
  let remaining = data?.Remaining?.padStart(2, '0');
  let seriesData = [{
    name: `${used} Used`,
    data: [used]
  }, {
      name: `${remaining} Remaining`,
      data: [remaining]
  },];
  
  let options = {
      chart: {
        type: 'bar',
        height: 50,
        stacked: true,
        stackType: '100%',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          show: true, 
          horizontal: true,
          borderRadius: 5,
          barHeight: 10,
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        row: {
          show: false, // Set show to false to hide the horizontal grid lines
        },
      },
      colors:[ '#FCD3AB', '#7BD57E', '#92ECE5', '#D6E5FF'],
      legend: {
        show: true, 
        position: 'bottom',
        markers: {
          fillColors: [ '#FCD3AB','#7BD57E', '#92ECE5', '#D6E5FF'],
          radius: 12,
          height: 6,
          width: 6,
        },
        labels: {
          colors: ['#FCD3AB','#7BD57E',  '#92ECE5', '#D6E5FF'],
        },
        itemMargin: {
          horizontal: 9, // Adjust the horizontal margin between legend items
          vertical: 0, // Adjust the vertical margin between legend items
        },
      },
      fill: {
        opacity: 1,
      },
    };
  return (
  <div className="m-2">
      <Chart 
      options={options} 
      series={seriesData} 
      type="bar" 
      height={70} />
  </div>);
}

export default BarChart;