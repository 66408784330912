import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import Modal from 'react-bootstrap/Modal';
import close from "../../../../assetsOld/img/x-close.svg"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import { useSelector } from "react-redux"
import { Autocomplete, TextField } from "@mui/material";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"

const AddNewShift = (props) => {

    let Shift_Modal = {
        ShiftName: "",
        ManagerID: "",
        DepartmentIDs: "",
    }
    let Shift_Time_Modal = [
        {
            ShiftScheduleID: "0",
            ShiftID: "0",
            DayName: 'Monday',
            StartTime: null,
            EndTime: null,
            BreakStartTime: null,
            BreakEndTime: null,
            DaysOnOff: false,
            Deleted: "false"
        },
        {
            ShiftScheduleID: '0',
            ShiftID: "0",
            DayName: 'Tuesday',
            StartTime: null,
            EndTime: null,
            BreakStartTime: null,
            BreakEndTime: null,
            DaysOnOff: false,
            Deleted: "false"
        },
        {
            ShiftScheduleID: "0",
            ShiftID: "0",
            DayName: 'Wednesday',
            StartTime: null,
            EndTime: null,
            BreakStartTime: null,
            BreakEndTime: null,
            DaysOnOff: false,
            Deleted: "false"
        },
        {
            ShiftScheduleID: "0",
            ShiftID: "0",
            DayName: 'Thursday',
            StartTime: null,
            EndTime: null,
            BreakStartTime: null,
            BreakEndTime: null,
            DaysOnOff: false,
            Deleted: "false"
        },
        {
            ShiftScheduleID: "0",
            ShiftID: "0",
            DayName: 'Friday',
            StartTime: null,
            EndTime: null,
            BreakStartTime: null,
            BreakEndTime: null,
            DaysOnOff: false,
            Deleted: "false"
        },
        {
            ShiftScheduleID: "0",
            ShiftID: "0",
            DayName: 'Saturday',
            StartTime: null,
            EndTime: null,
            BreakStartTime: null,
            BreakEndTime: null,
            DaysOnOff: false,
            Deleted: "false"
        },
        {
            ShiftScheduleID: "0",
            ShiftID: "0",
            DayName: 'Sunday',
            StartTime: null,
            EndTime: null,
            BreakStartTime: null,
            BreakEndTime: null,
            DaysOnOff: false,
            Deleted: "false"
        },
    ]
    let valModal = {}
    let ValidationModal = {
        ShiftName: "",
        ManagerID: "",
        DepartmentIDVal: "",
    }
    let ValidationShiftModal = [
        {
            StartTime: "",
            EndTime: "",
            BreakStartTime: "",
            BreakEndTime: "",
        },
        {
            StartTime: "",
            EndTime: "",
            BreakStartTime: "",
            BreakEndTime: "",
        },
        {
            StartTime: "",
            EndTime: "",
            BreakStartTime: "",
            BreakEndTime: "",
        },
        {
            StartTime: "",
            EndTime: "",
            BreakStartTime: "",
            BreakEndTime: "",
        },
        {
            StartTime: "",
            EndTime: "",
            BreakStartTime: "",
            BreakEndTime: "",
        },
        {
            StartTime: "",
            EndTime: "",
            BreakStartTime: "",
            BreakEndTime: "",
        },
        {
            StartTime: "",
            EndTime: "",
            BreakStartTime: "",
            BreakEndTime: "",
        },
    ]
    let valShiftModal = []

    const [newShiftSaved, setNewShiftSaved] = useState(false);
    const [addManagerDropDown, setAddManagerDropDown] = useState([]);
    const [dropDownDataDepartment, setDropDownDataDepartment] = useState([])
    const [departmentValue, setDepartmentValue] = useState([])
    const [shiftValidationModal, setShiftValidationModal] = useState(ValidationModal)
    const [shiftTimeValidationModal, setShiftTimeValidationModal] = useState(ValidationShiftModal)
    const [ShiftID, setShiftID] = useState()
    const [ShiftSaveUpdateModal, setShiftSaveUpdateModal] = useState({})
    const [mainShiftModal, setmainShiftModal] = useState(Shift_Modal);
    const [mainShiftTimeModal, setmainShiftTimeModal] = useState(Shift_Time_Modal);
    const [saveShiftTimeModal, setSaveShiftTimeModal] = useState(Shift_Time_Modal);
    const [isLoading, setIsLoading] = useState(false);
    const [isShiftValidation, setIsShiftValidation] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const { isOpenShift, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [del, setDel] = useState(false)
    const OrganizationID = useSelector((state) => state.Organization);
    const [isOpenAlert, setIsOpenAlert] = useState(false);

    useEffect(() => {
        setIsLoading(false)
        // right now, use any providerid to test update case/find by id
        if (props.ShiftID != null) {
            setShiftID(props.ShiftID)
            setDel(true)
        } else {
            if (!newShiftSaved) {
                setShiftID(0)
                setDel(false)
            }
            else { setDel(true) }
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Department,
        }).then((response) => {
            setDropDownDataDepartment(response)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        })
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Employee,
            "Indicator": "Manager"
        }).then((response) => {
            console.log("manager", response)
            setIsLoading(false)
            setAddManagerDropDown(response)
        }).catch((error) => {
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
            setIsLoading(false)
        })

        if (!isNull(ShiftID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/ShiftByID", 'post', {
                "ID": ShiftID,
                "TableName": enumUtil.enumAPITableName.Shift,
            }).then((response) => {
                setmainShiftModal(response?.Shift[0]);
                setmainShiftTimeModal(response?.Shift[0]?.ShiftSchedule);
                setSaveShiftTimeModal(response?.Shift[0]?.ShiftSchedule);
                setIsLoading(false)
                if (response?.Shift[0]?.DepartmentIDs) { setDepartmentValue(JSON.parse(response?.Shift[0]?.DepartmentIDs)) }
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
    }, [ShiftID])

    const handleShiftChange = (e) => {
        let { name, value } = e.target;
        let modal = { ...mainShiftModal };
        let saveModal = { ...ShiftSaveUpdateModal }
        modal[name] = value
        setmainShiftModal(modal)
        for (var property in Shift_Modal) {
            if (name === property) {
                saveModal[name] = value
            }
        }
        setShiftSaveUpdateModal(saveModal)
    }

    const handleShiftTimeChange = (e, index) => {
        let { name, value, checked } = e.target;
        // let modal = [...mainShiftTimeModal];
        // let saveModal = [...saveShiftTimeModal]
        // if (name === 'DaysOnOff') {
        //     if (checked) {
        //         setIsShiftValidation(true);
        //     }
        //     else {
        //         setIsShiftValidation(false);
        //     }
        //     // Log the type and value of checked before assignment
        //     console.log("Before assignment:");
        //     console.log("Checked value:", checked, "Type:", typeof checked);
        //     modal[index][name] = checked;
        //     saveModal[index][name] = checked.toString();
        //     // Log the type and value after assignment
        //     console.log("After assignment to modal:");
        //     console.log("modal[index][name] value:", modal[index][name], "Type:", typeof modal[index][name]);
        // } else {
        //     console.log("NAMEINDEXELSE", name, index, checked, modal);
        //     modal[index][name] = value;
        //     saveModal[index][name] = value.toString();
        // }
        // setmainShiftTimeModal(modal)
        // setSaveShiftTimeModal(saveModal)
        setmainShiftTimeModal(prevModal => {
            // const newModal = [...prevModal];
            const newModal = structuredClone(prevModal);

            if (name === 'DaysOnOff') {
                newModal[index][name] = checked;

                // Log after assignment

            } else {
                newModal[index][name] = value;
            }
            console.log("After assignment to modal:");
            console.log("newModal[index][name] value:", newModal, newModal[index][name], "Type:", typeof newModal[index][name]);
            return newModal;
        });

        setSaveShiftTimeModal(prevSaveModal => {
            // const newSaveModal = [...prevSaveModal];
            const newSaveModal = structuredClone(prevSaveModal);
            if (name === 'DaysOnOff') {
                newSaveModal[index][name] = checked.toString();
            } else {
                newSaveModal[index][name] = value.toString();
            }
            return newSaveModal;
        });
    }

    const ShiftValidation = async () => {
        let ValidationModal = {
            ShiftName: FormValidator(
                mainShiftModal.ShiftName,
                enumUtil.enumValidationType.Required,
                'Shift Name',
            ),
            ManagerID: FormValidator(
                mainShiftModal.ManagerID,
                enumUtil.enumValidationType.Required,
                'Manager ID',
            ),
            DepartmentIDVal: FormValidator(
                mainShiftModal.DepartmentIDs,
                enumUtil.enumValidationType.Required,
                'Department ID',
            )
        }
        ShiftTimeValidation()
        await setShiftValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const ShiftTimeValidation = async () => {
        for (let index = 0; index < ValidationShiftModal.length; index++) {

            let shiftObject = ValidationShiftModal[index]
            if (mainShiftTimeModal[index]?.['DaysOnOff'] == true) {
                for (let property in shiftObject) {
                    shiftTimeValidationModal[index][property] = FormValidator(mainShiftTimeModal[index][property], enumUtil.enumValidationType.Required, property);

                }
            } else {
                for (let property in shiftObject) {
                    shiftTimeValidationModal[index][property] = "";
                }
            }
        }
        valShiftModal = shiftTimeValidationModal;
    }

    const handleSaveShift = async () => {
        await ShiftValidation()
        // await  ShiftTimeValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        let shiftValidation = FormValidator(valShiftModal, enumUtil.enumValidationType.arrayOfObject)
        if (validation || shiftValidation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            // ShiftSaveUpdateModal['OrganizationID'] = OrganizationID+"";
            let updatedData = saveShiftTimeModal.map((item) => {
                let newItem = { ...item, 'DaysOnOff': item['DaysOnOff'] + "", 'Deleted': item['Deleted'] + "" }
                return newItem
            })
            // console.log("HANDLER", shiftValidation, updatedData)
            setSaveShiftTimeModal(updatedData);
            setShiftSaveUpdateModal(ShiftSaveUpdateModal);
            let saveModal = {
                TableName: enumUtil.enumAPITableName.Shift,
                ID: ShiftID,
                data: { ...ShiftSaveUpdateModal },
                OtherData: [...updatedData],
            }
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/SaveShift", 'post', saveModal).then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewShiftSaved(true)
                    setShiftID(response.id)
                    setIsRefresh(true);
                    onCloseSave();
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsRefresh(false);
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
            })

        }
    }
    const handleMultiSelectChange = (values, type, reason) => {
        let arr = [];
        let arrVal = [];
        values.map((key, index) => {
            arr.push(key)
        })
        values.map((key, index) => {
            arrVal.push(key.DepartmentID)
        })
        setDepartmentValue(arr);
        mainShiftModal['DepartmentIDs'] = arr.length == 0 ? "" : arr
        ShiftSaveUpdateModal['DepartmentIDs'] = arrVal.toString()
    }

    // const handleDeleteclick = () => {
    //     // setIsOpenAlert(true)
    // }
    // const AlertClose = () => {
    //     // setIsOpenAlert(false)
    // }
    // const handleShiftDelete = () => {
    //     setIsLoading(true)
    //     ServerRequestProtectedParentID(API_URL + "/DeleteSingleRecord", 'post', {
    //         "ID": ShiftID,
    //         "TableName": enumUtil.enumAPITableName.Shift
    //     }).then((response) => {
    //         setIsLoading(false)
    //         NotificationHandler('Shift Deleted Successfully!', enumUtil.enumtoaster.success)
    //     }).catch((error) => {
    //         NotificationHandler(error.message, enumUtil.enumtoaster.error)
    //         setIsLoading(false)
    //     })
    //     onClose()
    // }


    const SetShiftName = () => {
        if (mainShiftModal.ShiftName != null && mainShiftModal.ShiftName != "") {
            return (
                <p className="heading">{mainShiftModal.ShiftName}</p>
            )
        } else {
            return (
                <p className="heading">Add New Shift</p>
            )
        }
    }
    // const [expanded, setExpanded] =useState(false);
    // const handleChangeAccordian = (panel) => (event, isExpanded) => {
    //      setExpanded(isExpanded ? panel : false);
    //  };

    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup = ""
    if (del) {
        DeletePopup = <button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Shift} ID={ShiftID} onClose={onClose} />
    }
    //////////

    const [time12Hour, setTime12Hour] = useState('09:24 PM');
    const [time24Hour, setTime24Hour] = useState('');

    const convertTo24HourFormat = (time12) => {
        const [time, period] = time12.split(' ');
        const [hours, minutes] = time.split(':');

        let hours24 = parseInt(hours);

        if (period === 'PM' && hours24 !== 12) {
            hours24 += 12;
        } else if (period === 'AM' && hours24 === 12) {
            hours24 = 0;
        }

        const time24 = `${hours24.toString().padStart(2, '0')}:${minutes}`;
        return time24;
    };

    const handleConvert = () => {
        const convertedTime = convertTo24HourFormat(time12Hour);
        setTime24Hour(convertedTime);
    };

    return (
        <>
            {ConfirmDelete}
            <Modal show={isOpenShift} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal modal-lg add_new_shift ems_modal">
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img className="close cursor-pointer" src={close} onClick={() => onClose(newShiftSaved)}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <i className="icon-Vector4 fs-22"></i>
                            </span>
                            <p class="heading mt-2 mb-2"> {SetShiftName()}</p>
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}
                    <div className="row fa-layout h-100">
                        <div className="col-md-4 ">
                            <div className="">
                                <div className={shiftValidationModal.ManagerID ? "form-group input error" : "form-group input"}>
                                    <label>Manager<span className='req'>*</span></label>
                                    <select className="form-control"
                                        name="ManagerID"
                                        id="ManagerID"
                                        aria-label="Default select example"
                                        value={mainShiftModal.ManagerID}
                                        onChange={handleShiftChange}
                                    >
                                        {/* <option value="">Select</option> */}
                                        {addManagerDropDown.map((e, index) => (
                                            <option value={e.EmployeeID} key={index}>
                                                {e.EmployeeName}
                                            </option>
                                        ))}
                                    </select>
                                    {shiftValidationModal.ManagerID}
                                </div>

                                {/* ///////// */}
                                <div className={shiftValidationModal.DepartmentIDVal ? "form-group input error" : "form-group input"}>
                                    <label>Department<span className='req'>*</span></label>
                                    {/* <div className='input-group'> */}
                                    <Autocomplete
                                        multiple
                                        limitTags={1}
                                        id='DepartmentIDs'
                                        name='DepartmentIDs'
                                        value={departmentValue}
                                        options={dropDownDataDepartment}
                                        isOptionEqualToValue={(option, value) => option.DepartmentID === value.DepartmentID}
                                        onChange={(event, value, reason) => handleMultiSelectChange(value, 'DepartmentIDs', reason)}
                                        getOptionLabel={(option) => option.DepartmentName}
                                        noOptionsText={"Not Available"}
                                        renderInput={(params) => <TextField {...params} variant="standard" />}
                                    />
                                    {/* </div> */}
                                    {shiftValidationModal.DepartmentIDVal}
                                </div>
                                {/* ///////// */}
                                <div className={shiftValidationModal.ShiftName ? "form-group input error" : "form-group input"}>
                                    <label>Shift<span className='req'>*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="ShiftName"
                                        id="ShiftName"
                                        maxLength={35}
                                        value={mainShiftModal.ShiftName}
                                        onChange={handleShiftChange}
                                    />
                                    {shiftValidationModal.ShiftName}
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className="col-md-7 br-left-1 clr_light">
                            <div className="app-accordian">
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <p className="heading">Staring Time and Ending Time</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <div className="form-group input">
                                                    <label>Start Time</label>
                                                    <input type="time"
                                                        className="form-control"
                                                        name="StartTime"
                                                        id="StartTime"
                                                        value={mainShiftModal.StartTime}
                                                        onChange={handleShiftChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group input">
                                                    <label>End Time</label>
                                                    <input type="time"
                                                        className="form-control"
                                                        name="EndTime"
                                                        id="EndTime"
                                                        value={mainShiftModal.EndTime}
                                                        onChange={handleShiftChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group input">
                                                    <label>Days On</label>
                                                    <input type="text"
                                                        maxLength={1}
                                                        className="form-control"
                                                        name="DaysOn"
                                                        id="DaysOn"
                                                        aria-label="Default select example"
                                                        value={mainShiftModal.DaysOn}
                                                        onChange={handleShiftChange}
                                                        onKeyPress={(event) => handleNumericCheck(event)}
                                                    >
                                                    </input>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group input">
                                                    <label>Day Off</label>
                                                    <input type="text"
                                                        maxLength={1}
                                                        className="form-control"
                                                        name="DaysOff"
                                                        id="DaysOff"
                                                        aria-label="Default select example"
                                                        value={mainShiftModal.DaysOff}
                                                        onChange={handleShiftChange}
                                                        onKeyPress={(event) => handleNumericCheck(event)}
                                                    >
                                                    </input>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <p className="heading">Break Timing and Details</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                <div className="form-group input">
                                                    <label>Break Start Time</label>
                                                    <input type="time"
                                                        className="form-control"
                                                        name="BreakStartTime"
                                                        id="BreakStartTime"
                                                        value={mainShiftModal.BreakStartTime}
                                                        onChange={handleShiftChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group input">
                                                    <label>Break End Time</label>
                                                    <input type="time"
                                                        className="form-control"
                                                        name="BreakEndTime"
                                                        id="BreakEndTime"
                                                        value={mainShiftModal.BreakEndTime}
                                                        onChange={handleShiftChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group input">
                                                    <label>Details</label>
                                                    <textarea rows="3"
                                                        className="form-control"
                                                        name="Detail"
                                                        id="Detail"
                                                        maxLength={100}
                                                        value={mainShiftModal.Detail}
                                                        onChange={handleShiftChange}
                                                    ></textarea>
                                                </div>

                                            </div>

                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div> */}

                        <div className="col-md-8 br-left-1 clr_light ">
                            <div className="ar-shift-header d-flex align-items-center">
                                <div className="text-center">
                                    <p className="text fs-10 font-m">Start Time  </p>
                                </div>
                                <div className=" text-center">
                                    <p className="text fs-10 font-m">End Time</p>
                                </div>
                                <div className="">
                                    <p className="text fs-10 font-m">Days On/Off</p>
                                </div>
                                <div className=" text-center">
                                    <p className="text fs-10 font-m">Break Start</p>
                                </div>
                                <div className=" text-center">
                                    <p className="text fs-10 font-m">Break End</p>
                                </div>

                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-2" >
                                <div className="">
                                    <p className="heading day mon fs-10">
                                        MON
                                    </p>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[0]?.StartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className={"form-control"}
                                            name="StartTime"
                                            id="StartTime"
                                            value={mainShiftTimeModal[0]?.StartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 0)}
                                        />
                                        {/* {console.log("VALID_VALID", shiftTimeValidationModal[0]?.StartTime)} */}
                                        {/* {shiftTimeValidationModal[0]?.StartTime} */}
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[0]?.EndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="EndTime"
                                            id="EndTime"
                                            value={mainShiftTimeModal[0]?.EndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 0)}
                                        />
                                    </div>
                                </div>
                                <div className="w-50px text-center">
                                    <div className="custom_check_box ">

                                        <input
                                            type="checkbox"
                                            name="DaysOnOff"
                                            id="DaysOnOff"
                                            // value={mainShiftTimeModal[0]?.DaysOnOff}
                                            checked={mainShiftTimeModal[0]?.DaysOnOff}
                                            onChange={(e) => handleShiftTimeChange(e, 0)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[0]?.BreakStartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakStartTime"
                                            id="BreakStartTime"
                                            value={mainShiftTimeModal[0]?.BreakStartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 0)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[0]?.BreakEndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakEndTime"
                                            id="BreakEndTime"
                                            value={mainShiftTimeModal[0]?.BreakEndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 0)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="heading day tue fs-10">
                                        TUE
                                    </p>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[1]?.StartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="StartTime"
                                            id="StartTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[1]?.StartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 1)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[1]?.EndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="EndTime"
                                            id="EndTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[1]?.EndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 1)}
                                        />
                                    </div>
                                </div>
                                <div className="w-50px text-center">
                                    <div className="custom_check_box ">

                                        <input
                                            type="checkbox"
                                            name="DaysOnOff"
                                            id="DaysOnOff"
                                            checked={mainShiftTimeModal[1]?.DaysOnOff}
                                            onChange={(e) => handleShiftTimeChange(e, 1)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[1]?.BreakStartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="BreakStartTime"
                                            id="BreakStartTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[1]?.BreakStartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 1)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[1]?.BreakEndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="BreakEndTime"
                                            id="BreakEndTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[1]?.BreakEndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 1)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="heading day wed fs-10">
                                        WED
                                    </p>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[2]?.StartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="StartTime"
                                            id="StartTime"
                                            value={mainShiftTimeModal[2]?.StartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 2)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[2]?.EndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="EndTime"
                                            id="EndTime"
                                            value={mainShiftTimeModal[2]?.EndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 2)}
                                        />
                                    </div>
                                </div>
                                <div className="w-50px text-center">
                                    <div className="custom_check_box ">

                                        <input
                                            type="checkbox"
                                            name="DaysOnOff"
                                            id="DaysOnOff"
                                            checked={mainShiftTimeModal[2]?.DaysOnOff}
                                            onChange={(e) => handleShiftTimeChange(e, 2)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[2]?.BreakStartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakStartTime"
                                            id="BreakStartTime"
                                            value={mainShiftTimeModal[2]?.BreakStartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 2)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[2]?.BreakEndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakEndTime"
                                            id="BreakEndTime"
                                            value={mainShiftTimeModal[2]?.BreakEndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 2)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="heading day thu fs-10">
                                        THU
                                    </p>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[3]?.StartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="StartTime"
                                            id="StartTime"
                                            value={mainShiftTimeModal[3]?.StartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 3)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[3]?.EndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="EndTime"
                                            id="EndTime"
                                            value={mainShiftTimeModal[3]?.EndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 3)}
                                        />
                                    </div>
                                </div>
                                <div className="w-50px text-center">
                                    <div className="custom_check_box ">

                                        <input
                                            type="checkbox"
                                            name="DaysOnOff"
                                            id="DaysOnOff"
                                            checked={mainShiftTimeModal[3]?.DaysOnOff}
                                            onChange={(e) => handleShiftTimeChange(e, 3)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[3]?.BreakStartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakStartTime"
                                            id="BreakStartTime"
                                            value={mainShiftTimeModal[3]?.BreakStartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 3)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[3]?.BreakEndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakEndTime"
                                            id="BreakEndTime"
                                            value={mainShiftTimeModal[3]?.BreakEndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 3)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="heading day fri fs-10">
                                        FRI
                                    </p>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[4]?.StartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="StartTime"
                                            id="StartTime"
                                            value={mainShiftTimeModal[4]?.StartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 4)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[4]?.EndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="EndTime"
                                            id="EndTime"
                                            value={mainShiftTimeModal[4]?.EndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 4)}
                                        />
                                    </div>
                                </div>
                                <div className="w-50px text-center">
                                    <div className="custom_check_box ">

                                        <input
                                            type="checkbox"
                                            name="DaysOnOff"
                                            id="DaysOnOff"
                                            checked={mainShiftTimeModal[4]?.DaysOnOff}
                                            onChange={(e) => handleShiftTimeChange(e, 4)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[4]?.BreakStartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakStartTime"
                                            id="BreakStartTime"
                                            value={mainShiftTimeModal[4]?.BreakStartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 4)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[4]?.BreakEndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakEndTime"
                                            id="BreakEndTime"
                                            value={mainShiftTimeModal[4]?.BreakEndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 4)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <div className="">
                                    <p className="heading day sat fs-10">
                                        Sat
                                    </p>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[5]?.StartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="StartTime"
                                            id="StartTime"
                                            value={mainShiftTimeModal[5]?.StartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 5)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[5]?.EndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="EndTime"
                                            id="EndTime"
                                            value={mainShiftTimeModal[5]?.EndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 5)}
                                        />
                                    </div>
                                </div>
                                <div className="w-50px text-center">
                                    <div className="custom_check_box ">

                                        <input
                                            type="checkbox"
                                            name="DaysOnOff"
                                            id="DaysOnOff"
                                            checked={mainShiftTimeModal[5]?.DaysOnOff}
                                            onChange={(e) => handleShiftTimeChange(e, 5)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[5]?.BreakStartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakStartTime"
                                            id="BreakStartTime"
                                            value={mainShiftTimeModal[5]?.BreakStartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 5)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[5]?.BreakEndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            className="form-control"
                                            name="BreakEndTime"
                                            id="BreakEndTime"
                                            value={mainShiftTimeModal[5]?.BreakEndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 5)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center my-2">
                                <div className="">
                                    <p className="heading day sun fs-10">
                                        Sun
                                    </p>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[6]?.StartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="StartTime"
                                            id="StartTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[6]?.StartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 6)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[6]?.EndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="EndTime"
                                            id="EndTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[6]?.EndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 6)}
                                        />
                                    </div>
                                </div>
                                <div className="w-50px text-center">
                                    <div className="custom_check_box ">

                                        <input
                                            type="checkbox"
                                            name="DaysOnOff"
                                            id="DaysOnOff"
                                            checked={mainShiftTimeModal[6]?.DaysOnOff}
                                            onChange={(e) => handleShiftTimeChange(e, 6)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[6]?.BreakStartTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="BreakStartTime"
                                            id="BreakStartTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[6]?.BreakStartTime}
                                            onChange={(e) => handleShiftTimeChange(e, 6)}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <div className={shiftTimeValidationModal[6]?.BreakEndTime ? "form-group input error" : "form-group input"}>
                                        <input
                                            type="time"
                                            name="BreakEndTime"
                                            id="BreakEndTime"
                                            className="form-control"
                                            value={mainShiftTimeModal[6]?.BreakEndTime}
                                            onChange={(e) => handleShiftTimeChange(e, 6)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveShift} />
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                    <button className='btn btn-gray' onClick={() => onClose(newShiftSaved)}>Cancel</button>
                    <button className='btn btn-orange' onClick={handleSaveShift}>Save</button>
                </div>  */}
            </Modal >
        </>
    )
}

export default AddNewShift