import React, { useEffect, useState } from 'react'
import { Nav, Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'
import close from "../../assetsOld/img/x-close.svg"
import EmployeeInformation from '../MyInfo/Account/EmployeeInformation/EmployeeInformation'
import ModalFooter from "../shared/ModalFooter/ModalFooter";
import CalendarInput from '../../utils/CalendarInput';
import { enumUtil } from '../../utils/Enum';
import NotificationHandler from '../../utils/NotificationHandler';
import { ServerRequestProtected } from '../../utils/ServerDataRequest';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import TodayProgress from './DailyTrackingComponents/TodayProgress';
import Tasks from './DailyTrackingComponents/Tasks';
import AllSessionsScreenShots from './DailyTrackingComponents/AllSessionsScreenShots';
import { format } from 'date-fns';
import { DateFormat, DateFormat_dMy } from "../../utils/Constants"

const DailyTracking = (props) => {

    const currentMonth = String(new Date().getMonth() + 1);
    const currentDay = format(new Date(), DateFormat_dMy);
    const currentDaySave = format(new Date(), DateFormat);
    const API_URL = process.env.REACT_APP_API
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token);
    let UserID = Token.UserID;
    const [activeTab2, setActiveTab2] = useState("todayProgress");
    const [addTodayTask, setAddTodayTask] = useState(false);
    const handleTabSelect2 = (eventKey) => {
        setActiveTab2(eventKey);
    }
    const { isLeaveOpen, onClose, ID, view } = { ...props }


    let TimeAdjustment_Modal = {
        Month: currentMonth,
        UserID: UserID,
    }

    let ScreenShot_Modal = {
        Date: currentDay,
    }
    let ScreenShotSave_Modal = {
        Date: currentDaySave,
    }

    const [mainDropDownModal, setMainDropDownModal] = useState(TimeAdjustment_Modal);
    const [saveDropDownModal, setSaveDropDownModal] = useState({})
    const [mainScreenShotDateModal, setMainScreenShotDateModal] = useState(ScreenShot_Modal)
    const [saveScreenShotDateModal, setSaveScreenShotDateModal] = useState(ScreenShotSave_Modal)
    const [isRefresh, setIsRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [TrackingData, setTrackingData] = useState()
    const [position, setPosition] = useState(null);
    const openDateRangePicker = Boolean(position);

    const openDateRangePickerMenu = (event) => {
        setPosition(event.currentTarget);
    };
    const closeDateRangePickerMenu = () => {
        setPosition(null);
    };

    const handleCalendarChange = (date, name) => {
        var FormatedDateSave = format(date, DateFormat)
        var FormatedDateShow = format(date, DateFormat_dMy)
        let modal = { ...mainScreenShotDateModal };
        let saveModal = { ...saveScreenShotDateModal }
        modal[name] = FormatedDateShow
        console.log("MAINDATE",FormatedDateShow)
        setMainScreenShotDateModal(modal)
        for (var property in ScreenShotSave_Modal) {
            if (name === property) {
                saveModal[name] = FormatedDateSave
                console.log("SAVEDATE",FormatedDateSave)
            }
        }
        setSaveScreenShotDateModal(saveModal)
        closeDateRangePickerMenu()
    }

    let handleMonthChange = (e) => {
        const { name, value, selectedIndex } = e.target;

        let modal = { ...mainDropDownModal };
        let saveModal = { ...saveDropDownModal }
        const selectedOptionText = e.target.options[selectedIndex].text;
        modal[name] = value;

        setMainDropDownModal(modal)
        for (var property in TimeAdjustment_Modal) {
            if (name === property) {
                saveModal[name] = value

            }
        }
        setSaveDropDownModal(saveModal)
    }

    let MonthsDropdown = [
        { value: '1', display: 'January' },
        { value: '2', display: 'February' },
        { value: '3', display: 'March' },
        { value: '4', display: 'April' },
        { value: '5', display: 'May' },
        { value: '6', display: 'June' },
        { value: '7', display: 'July' },
        { value: '8', display: 'August' },
        { value: '9', display: 'September' },
        { value: '10', display: 'October' },
        { value: '11', display: 'November' },
        { value: '12', display: 'December' },
    ]

    return (
        <div className={`daily_tracking allocate_screen h-auto basic_info pb-2 ${props.type === "Employee" ? 'daily_tracking ' : 'ar_daily_tracking'}`}>
            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <div className="d-flex my-2 mt-3 justify-content-between align-items-center">
                        <p className="heading doc py-1 px-2"> Daily Tracking</p>
                        {activeTab2 === "task" ?
                            <div className="input">
                                <select className="form-control" id="Month" name="Month" value={mainDropDownModal.Month} onChange={(e) => handleMonthChange(e)}>
                                    {MonthsDropdown.map((item) => {
                                        return (
                                            <option value={item.value} >{item.display}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            : activeTab2 === "screenshot" ?
                                <CalendarInput
                                    // label='Valid Till'
                                    value={mainScreenShotDateModal.Date}
                                    openCalendarMenufunc={(event) => openDateRangePickerMenu(event, 'CreatedDate')}
                                    maxDate={new Date()}
                                    anchorEl={position}
                                    open={openDateRangePicker}
                                    closeCalendarMenufunc={closeDateRangePickerMenu}
                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'Date')}
                                />
                                : ''
                        }
                    </div>
                    <Tab.Container defaultActiveKey="todayProgress">
                        <Nav variant="tabs" className="rb-tabs-v3 ps-2 tabs_view mt-1 gap-4 tabs_view2" activeKey={activeTab2} onSelect={handleTabSelect2}>
                            <Nav.Item>
                                <Nav.Link eventKey="todayProgress">Today’s Progress</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="task">  Tasks  </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="screenshot">Screenshots</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey={"todayProgress"}>
                                <TodayProgress type={props.type}  setIsRefresh={setIsRefresh} isRefresh={isRefresh} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={"task"}>
                                <Tasks type={props.type} currentMonth={saveDropDownModal} setIsRefresh={setIsRefresh} isRefresh={isRefresh}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey={"screenshot"} className='ar_daily_tracking_screnshot'>
                                <AllSessionsScreenShots type={props.type} currentDate={saveScreenShotDateModal}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default DailyTracking
