import React, { useState } from 'react'
import { Nav, Tab, Tabs } from "react-bootstrap";
// import LeaveView from '../Tab Views/LeaveView';
import { useSelector } from 'react-redux';
import Cookies from "js-cookie";
import jwt_decode from 'jwt-decode';
import TimeAdjustmentEmployee from './TimeAdjustmentEmployee';
import TimeAdjustmentAdmin from './TimeAdjustmentAdmin';

const TimeAdjustment = ({ Active }) => {

    let Role = useSelector((state) => state.RoleReducer);
    let token = useSelector(state => state.TokenReducer);
    let Token = token && jwt_decode(token);
    const cookieValue = Cookies.get('cookedToken');
    const user = jwt_decode(cookieValue);
    let userRole = user.Role;

    if (Role === 1) {
        return (
            <div>
                <div className="">
                    <Tabs className="rb-tabs bg-light-blue">
                        <Tab eventKey="timeAdjustment" title="">
                            <Tab.Container defaultActiveKey="myreq">
                                <>
                                    {!Active &&
                                        <div className='app-tabs'>
                                            <div className='tabs-header position-relative mx-auto'>
                                                <div className="row h-100 justify-content-center align-items-center">
                                                    <div className="tabs-v2-custom ar_tab-v2-custom">

                                                        <Nav variant="tabs" className="rb-tabs-v3 tabs_view mb-2">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="myreq">My Requests</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="employeereq">Employee Requests</Nav.Link>
                                                            </Nav.Item>

                                                        </Nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row row-gap-15 row-half pe-0">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="myreq">
                                                <TimeAdjustmentEmployee Active={Active} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="employeereq">
                                                <TimeAdjustmentAdmin Active={Active} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </>
                            </Tab.Container>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                <TimeAdjustmentEmployee />
            </div>
        )
    }
}

export default TimeAdjustment
