import { GetMenuDetails } from "./MenuDetails"
import { enumUtil } from "../../utils/Enum"
import { useSelector } from "react-redux"
import { useMemo } from "react"

const MenuItemsList = (Role) => {
    let Sub_Role = useSelector((state) => state.SubRoleReducer)
    console.log("MenuItemsList",Role,Sub_Role)
    let MenuLst = [];
    const fnc = (Role, Sub_Role) => {
        switch (Sub_Role) {
            case true:
                switch (Role) {
                    case 1:
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DashboardAdminKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.EmployeeKey));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.AttendenceKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DailyTracking));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.PayrollKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.PayslipKey));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.AllRequest));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Leaves));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.ExpenseKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.EmployeeAttendance));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.MyInfo));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.MiscllaneousKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.SetupKey));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Setting));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Logout));
                        break;
                    case 2:
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DashboardEmployeeKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.MyInfo));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.EmployeeAttendance));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.PayslipKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DailyTracking));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Leaves));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Logout));
                        break;
                    case 3:
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DashboardTeamKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.MyInfo));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.EmployeeAttendance));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.PayslipKey));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DailyTracking));
                        MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Leaves));
                        // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Logout));
                        break;
                }
                break;
            case false:
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DashboardAdminKey));
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.EmployeeKey));
                // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.AttendenceKey));
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.DailyTracking));
                // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.PayrollKey));
                // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.AllRequest));
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Leaves));
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.ExpenseKey));
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.EmployeeAttendance));
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.MyInfo));
                // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.MiscllaneousKey));
                MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.SetupKey));
                // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Setting));
                // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Setting));
                // MenuLst.push(GetMenuDetails(enumUtil.menuItemsList.Logout));
                break;
        }

        return MenuLst
    }

    return useMemo(() => fnc(Role, Sub_Role), [Role, Sub_Role]);
}

export default MenuItemsList