import React, { useEffect, useState, useReducer, useMemo } from "react";
import Popover from "./Popover"
import { useDispatch } from 'react-redux';
import { GoTo } from '../../actions/GotoPage';
import { OpenEmployeeAttendance } from '../../actions/OpenEmployeeAttendance';
// import PopupCheckout from "./PopupCheckout";
import YearlyAttendance from "./YearlyAttendance";
import { ServerRequestProtected, ServerRequestProtectedParentID } from "../../utils/ServerDataRequest";
import { enumUtil } from "../../utils/Enum";
// import NotificationHandler from "../../utils/NotificationHandler";
import { useSelector } from "react-redux";
// import jwt_decode from 'jwt-decode';
import Loader from "../shared/Loader/Loader";
import { TabActionAttendance } from "../../actions/TabActions";
import NoDataFound from "../shared/NoDataFound/NoDataFound";
const MonthlyAttendance = (props) => {
  const { Active, refresh, setRefresh, handleCheckboxChange, handleMonthSearch, type, searchParamYear, monthlyParam } = { ...props }

  const OpenAttendance = (data) => {
    let obj = {
      key: 2,
      name: "Attendance",
      active: true,
      data: {
        id: data.id,
        name: data.name,
      },
    };
    dispatch(TabActionAttendance(obj));
  }
  // let token = useSelector(state => state.TokenReducer);
  // let Token = token && jwt_decode(token)
  // let UserID = Token?.UserID
  let OrgID = useSelector(state => state.Organization)
  /////////Year Options///////
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  let AttAndLv_Modal = {
    // "OrganizationID": OrgID,
    "ShiftIDs": null,
    "TeamIDs": null,
    "DepartmentIDs": null,
    Month: currentMonth + "",
    Year: currentYear + "",
  }

  let dispatch = useDispatch();
  const hasProps = type && type === "Employee";
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const API_URL = process.env.REACT_APP_API;
  const [searchParam, setSearchParam] = useState(AttAndLv_Modal)
  const [attendanceData, setAttendanceData] = useState([])
  const [checkoutPopup, setCheckoutPopup] = useState("")
  const [attendanceMapData, setAttendanceMapData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [addManagerDropDown, setAddManagerDropDown] = useState([])
  const [popup, setPopups] = useState(null);
  const [hover, handleHover] = useReducer((prev, next) => { return { ...prev, ...next } }, { empID: null, empDate: '' })
  const currDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()

  const LoadData = (searchParam) => {
    setIsLoading(true);
    // setSearchOverAllDataParam(OverAllData_Search)
    setAttendanceData([])
    ServerRequestProtected(API_URL + "/FindByMultipleCriteria", 'post', {
      "TableName": enumUtil.enumAPITableName.Attendance,
      "SearchCriteria": searchParam,
    })
      .then((response) => {
        let jsonStr = '';
        for (let i in response) {
          const jsonData = response[i]['JSON_F52E2B61-18A1-11d1-B105-00805F49916B'];
          for (let j in jsonData) {
            jsonStr += jsonData[j];
          }
        }

        const parsedJson = JSON.parse(jsonStr);
        setAttendanceData(parsedJson.Employees);
        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
      })
  }
  const LoadDropdownList = () => {
    ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
      "TableName": enumUtil.enumAPITableName.Employee,
      "Indicator": "Manager"
    }).then((response) => {

      setIsLoading(false)
      setAddManagerDropDown(response)
    }).catch((error) => {
      // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
      setIsLoading(false)
    });
  }



  useEffect(() => {
    // if (monthlyParam) {
    // setSearchParam(monthlyParam);
    LoadData(monthlyParam);
    // }
  }, [monthlyParam])

  const groupByMonth = (attendance) => {
    const grouped = {};
    attendance.forEach(({ Date: attendanceDate, Status = 0, TotalHours, Check_In, Check_Out, CheckOutRequestStatus }) => {
      const [day, month, year] = attendanceDate.split("/").map(Number);
      const dateObj = new Date(year, month - 1, day);
      const monthName = dateObj.toLocaleString("default", { month: "long" });
      grouped[monthName] = grouped[monthName] || [];
      grouped[monthName].push({ Date: attendanceDate, Status, TotalHours, Check_In, Check_Out, CheckOutRequestStatus });
    });
    return grouped;
  };

  let rows = useMemo(() => {
    return attendanceData?.length === 0 ? []
      :
      attendanceData.map(({ EmployeeID, Name, ProfilePicture, Attendance }) => {
        const groupedAttendance = groupByMonth(Attendance);
        const months = Object.keys(groupedAttendance);
        const cells = months.flatMap((month) => {
          const attendanceForMonth = groupedAttendance[month];
          ///To get Month properly by converting to dd/MM/yyyy
          const [days, months, years] = attendanceForMonth[0].Date.split('/').map(Number);
          const date = new Date(years, months - 1, days);
          const monthNum = date.getMonth();
          ///////////////////////////////////////////////////
          const dates = getDaysInMonth(monthNum, new Date(attendanceForMonth[0].Date).getFullYear());
          const dateCells = dates.map(date => {
            // console.log("dateodatedated",date)

            const dateString = `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}/${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}/${date.getFullYear()}`;
            const attendance = attendanceForMonth.find(({ Date }) => Date === dateString);
            return (
              <td onMouseEnter={(e) => attendance?.Status === "P"  ? showPopup(e, attendance, dateString, EmployeeID) : null} onMouseLeave={() => hidePopup()} key={dateString}>

                {attendance && attendance?.Status === "P" &&
                  (<div className={`hk-tabel-status green-bg`}>{attendance?.Status}
                    {!attendance.Check_Out ? date.getTime() < currDate ? <div className="astrik-circle">*</div> : <></> : <></>}
                  </div>)}
                {attendance && attendance.Status === "A" && <div className="hk-tabel-status litePink-bg">{attendance?.Status}</div>}
                {attendance && attendance.Status === "W" && <div className="ar_weakly"></div>}
                {attendance && attendance.Status === "L" && <div className="hk-tabel-status pink-bg">{attendance?.Status}</div>}
                {attendance && attendance.Status === "NH" && <div className="hk-tabel-status liteBlue-bg">{attendance?.Status}</div>}
                {EmployeeID === hover.empID && dateString === hover.empDate && attendance?.Status === "P" && popup}
              </td>
            );
          });
          return dateCells;
        });
        return (
          <>
            <tr key={EmployeeID}>
              <td>
                <div className="hk-table-input custom_check_box ">
                  <input type="checkbox" onChange={(e) => handleCheckboxChange(e, EmployeeID)} />
                </div>
              </td>
              <td className="hk-table-UserCol w-25">
                <div className="gap-3">
                  {
                    // ProfilePicture ?
                    <img
                      src={ProfilePicture ? ProfilePicture : "https://www.w3schools.com/howto/img_avatar.png"}
                      alt=""
                    />
                    // :
                    // <p className={`align-items-center d-flex h-100 heading justify-content-center w-100 fs-24 text-white ${''}`}>{Name?.split(' ')[0]?.split('')[0]}{Name?.split(' ')[1]?.split('')[0]}</p>
                  }
                  <p className="heading office_name_truncate cursor-pointer" title={Name} onClick={() =>
                    // handleOpenEmployee(EmployeeID)
                    OpenAttendance({ id: EmployeeID, name: Name })
                  }>{Name}</p>
                </div>
              </td>
              {cells}
            </tr>
          </>
        );
      });
  }, [attendanceData, hover])

  let monthHeaders = useMemo(() => {
    return (
      Object.entries(groupByMonth(attendanceData.flatMap(({ Attendance }) => Attendance)))
        .map(([month, attendance]) => {
          const firstAttendanceDate = attendance[0].Date;
          const [day, monthNum, year] = firstAttendanceDate.split("/").map(Number);
          // const dateObj = new Date(year, monthNum - 1, day);
          // const options = { weekday: "short", day: "numeric" };
          // const dayAndDate = dateObj.toLocaleString("default", options);
          const daysInMonth = getDaysInMonth(monthNum - 1, year);
          const dateHeaders = daysInMonth.map(date => {
            const dayName = date.toLocaleString("default", { weekday: "short" });
            return (
              <th key={date}>
                {dayName}<span>{`${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`}</span>
              </th>
            );
          });
          return (<>{dateHeaders}</>);
          // return (
          //   <th key={month} colSpan={daysInMonth.length}>
          //     {dayAndDate} - {month}
          //     <tr>
          //       {dateHeaders}
          //     </tr>
          //   </th>
          // );
        })
    )
  }, [attendanceData])

  useEffect(() => {
    handleMonthSearch()
  }, [attendanceData])

  // useEffect(() => {
  //   LoadData(searchParam);
  // }, [searchParam.Month, searchParam.Year]);

  // const handleSelectAll = (e) =>{
  //   const isChecked = e.target.checked;
  //   if (isChecked) {
  //     let id = attendanceData.map((date)=>{return date.EmployeeID})
  //     console.log( "All",id)
  //   }
  //   else{}
  // }
  // useEffect(()=>{console.log("Popover",Popover)},[Popover])

  //////////////////Switch to Employee Tab and Open the selected Employee////////////////
  // const handleOpenEmployee = (ID) => {
  //   const goToEmployee = (component, ID) => {
  //     dispatch(GoTo(component));
  //     dispatch(OpenEmployeeAttendance(ID));
  //   };
  //   return goToEmployee(2, ID)
  // }
  //////////////////Popover////////////////
  const showPopup = (e, attendance, dateString, EmployeeID) => {
    setPopups(attendance ? <Popover refresh={refresh} setRefresh={Popover} attendance={attendance} EmployeeID={EmployeeID} setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen} setCheckoutPopup={setCheckoutPopup} /> : null);
    handleHover({ empDate: dateString, empID: EmployeeID })
  }

  const hidePopup = () => {
    setPopups(null);
  }
  /////////////////////////////////
  return (
    <>

      <div className="col-md-12 overflow-auto attendenceScreen">
        {isPopupOpen && checkoutPopup}
        {isLoading ? <Loader /> : null}
        <table className="hk-attendance-table">
          <thead>
            <tr>
              <th>ID</th>
              <th className="at-right">Employee Name</th>
              {monthHeaders}
            </tr>
          </thead>
          <tbody>{rows?.length > 0 ?
            rows
            :
            <NoDataFound isLoading={isLoading} Data={rows} />

          }</tbody>
        </table>
      </div>

    </>
  );


};

export default MonthlyAttendance;


export const getDaysInMonth = (month, year) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};