import React, { useEffect, useState } from "react"
import { enumUtil } from "../../../../utils/Enum"
import FormValidator from "../../../../utils/FormValidator"
import NotificationHandler from "../../../../utils/NotificationHandler"
import { ServerRequestProtectedParentID, ServerRequestPublic } from "../../../../utils/ServerDataRequest"
import isNull from "../../../../utils/NullChecking"
import Loader from "../../../shared/Loader/Loader"
import CalendarInput from "../../../../utils/CalendarInput"
import Modal from 'react-bootstrap/Modal';
import { useSelector } from "react-redux"
import close from "../../../../assetsOld/img/x-close.svg"
import handleNumericCheck from "../../../../utils/HandleNumericKeys"
import { Calendar } from "react-date-range";
import DeleteConfirmation from "../../../shared/Delete/DeleteConfirmation"
import format from "date-fns/format";
import { saveAs, Delete } from "file-saver"
import { DateFormat, DateFormat_dMy } from "../../../../utils/Constants"
import ModalFooter from "../../../shared/ModalFooter/ModalFooter"
import DatePicker from "../../../../utils/DatePicker"

const AddNewExpense = (props) => {

    let Expense_Modal = {
        OfficeID: "",
        ExpenseName: "",
        ExpenseAmount: "",
        ExpenseCategoryID: "",
        ExpenseDetails: "",
        IsRefundable: false,
        ReferenceNumber: "",
        ExpenseDate: null,
        FileName: "",
        AttachmentPath: "",
    }
    let File_Modal = {
        File: "",
        BucketPathNew: ""
    }
    let valModal = {}
    let ValidationModal = {
        OfficeID: "",
        ExpenseCategoryID: "",
        ExpenseName: "",
    }

    const [ddExpenseCategory, setDDExpenseCategory] = useState([])
    const [temporaryRemove, setTemporaryRemove] = useState(false)
    const [ddOffice, setDDOffice] = useState([])
    const [ExpenseValidationModal, setExpenseValidationModal] = useState(ValidationModal)
    const [ExpenseID, setExpenseID] = useState()
    const [ExpenseSaveUpdateModal, setExpenseSaveUpdateModal] = useState({})
    const [mainExpenseModal, setmainExpenseModal] = useState(Expense_Modal);
    const [isLoading, setIsLoading] = useState(false)
    const [del, setDel] = useState(false)
    const API_URL = process.env.REACT_APP_API
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const { isOpenExpense, onClose, onCloseSave, setIsRefresh } = { ...props }
    const [file, setfile] = useState('')
    const [fileDeleted, setFileDeleted] = useState(false);

    const OrganizationID = useSelector((state) => state.Organization);
    const [newExpenseSaved, setNewExpenseSaved] = useState(false);
    /////////Calendar//////////
    const [expenseDate, setExpenseDate] = useState();
    const openExpenseDate = Boolean(expenseDate)

    const handleCalendarChange = (date, type) => {
        const FormatedDateShow = format(date, "MM/dd/yyyy")
        const FormatedDateSave = format(date, DateFormat)
        let modal = { ...mainExpenseModal };
        let saveModal = { ...ExpenseSaveUpdateModal }
        modal[type] = FormatedDateShow
        setmainExpenseModal(modal)
        for (var property in Expense_Modal) {
            if (type === property) {
                saveModal[type] = FormatedDateSave
            }
        }
        setExpenseSaveUpdateModal(saveModal)
        closeCalendarMenu()
    }
    const closeCalendarMenu = () => {
        setExpenseDate(null)
    };
    const openCalendarMenu = (event, type) => {
        if (type === 'ExpenseDate') { setExpenseDate(event.currentTarget) }
        else { console.log("No Type Exist") }
    };
    ////////////////////////////
    useEffect(() => {
        setIsLoading(false)
        if (props.ExpenseID != null) {
            setExpenseID(props.ExpenseID)
            setDel(true)
        } else {
            if (!newExpenseSaved) {
                setExpenseID(0)
                setDel(false)
            }
            else { setDel(true) }
        }

        if (!isNull(ExpenseID)) {
            setIsLoading(true)
            ServerRequestProtectedParentID(API_URL + "/FindByID", 'post', {
                "ID": ExpenseID,
                "TableName": enumUtil.enumAPITableName.Expense
            }).then((response) => {
                if (response.FileName) { setFileDeleted(true) }
                else { setFileDeleted(false) }
                setmainExpenseModal(response);
                setIsLoading(false)
            }).catch((error) => {
                NotificationHandler(error.message, enumUtil.enumtoaster.error)
                setIsLoading(false)
            })
        }
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.ExpenseCategory,
        }).then((response) => {
            setDDExpenseCategory(response)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        })
        ServerRequestProtectedParentID(API_URL + "/LoadDropDownList", 'post', {
            "TableName": enumUtil.enumAPITableName.Office,
        }).then((response) => {
            setDDOffice(response)
            console.log("Office", response)
        }).catch((error) => {
            setIsLoading(false);
            // NotificationHandler(error.response.data, enumUtil.enumtoaster.error);
        })
    }, [ExpenseID, fileDeleted])

    const handleExpenseChange = (e) => {

        let { name, value } = e.target;
        let modal = { ...mainExpenseModal };
        let saveModal = { ...ExpenseSaveUpdateModal }
        if (name === "IsRefundable") {
            modal[name] = !modal[name]
        } else {
            modal[name] = value
        }
        setmainExpenseModal(modal)
        for (var property in Expense_Modal) {
            if (name === property) {
                if (name === "IsRefundable") {
                    saveModal[name] = modal[name] + ""
                } else {
                    saveModal[name] = value
                }
            }
        }
        setExpenseSaveUpdateModal(saveModal)
    }
    const ExpenseValidation = async () => {
        let ValidationModal = {
            ExpenseName: FormValidator(
                mainExpenseModal.ExpenseName,
                enumUtil.enumValidationType.Required,
                'Expense Name',
            ),
            ExpenseCategoryID: FormValidator(
                mainExpenseModal.ExpenseCategoryID,
                enumUtil.enumValidationType.Required,
                'Expense Category ID ',
            ),
            OfficeID: FormValidator(
                mainExpenseModal.OfficeID,
                enumUtil.enumValidationType.Required,
                'Office ID ',
            ),
        }
        await setExpenseValidationModal(ValidationModal)
        valModal = ValidationModal
    }
    const handleSaveExpense = async () => {
        await ExpenseValidation()
        let validation = FormValidator([valModal], enumUtil.enumValidationType.NullCheck)
        if (validation) {
            NotificationHandler('Fill Required Fields', enumUtil.enumtoaster.error)
            return
        } else {
            if (temporaryRemove) { DeleteFile(mainExpenseModal.AttachmentPath) }
            let data = { ...ExpenseSaveUpdateModal };
            ///////////
            const formData = new FormData();
            formData.append('TableName', enumUtil.enumAPITableName.Expense);
            formData.append('ID', ExpenseID);
            if (file) {
                formData.append('file', file);
            }
            for (var key in data) {
                formData.append(`data[${key}]`, data[key]);
            }
            ////////
            setIsLoading(true)
            ServerRequestPublic(API_URL + "/SaveExpenseRecord", 'post', formData, "Upload").then((response) => {
                if (!isNull(response.id)) {
                    setIsLoading(false)
                    NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
                    setNewExpenseSaved(true)
                    setExpenseID(response.id)
                    setIsRefresh(true);
                    onCloseSave()
                }
            }).catch((error) => {
                setIsRefresh(false);
                setIsLoading(false)
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error)
            })
        }
    }
    const SetExpenseName = () => {
        if (mainExpenseModal.ExpenseName != null && mainExpenseModal.ExpenseName != "") {
            return (
                <p className="heading validation_message">{mainExpenseModal.ExpenseName}</p>
            )
        } else {
            return (
                <p className="heading">Add Expense</p>
            )
        }
    }
    //////handleFileNameLength
    // const handleFileNameLength = (name) => {
    //     let file=name,type='',space='',FileName;
    //     if(name.length>50){
    //          file=name.substring(0, 48);
    //          type=name.split(".").pop();
    //          space="..."
    //     }
    //     return FileName=file+space+type;
    // }
    //////////////////////////
    const savefile = (e) => {
        // console.log("target", e.target.files[0].type);
        setfile(e.target.files[0]);
        mainExpenseModal['FileName'] = e.target.files[0].name;
    };
    const DownloadFile = (fileName) => {
        setIsLoading(true)
        var postData = {
            "BucketPath": fileName
        };
        const API_URL = 'https://emsbackend.theeasyedi.com/Wasabi/DownloadFile'
        ServerRequestPublic(API_URL, 'post', postData, "Download")
            .then((res) => {
                let blob = new Blob([res]);
                saveAs(blob, mainExpenseModal.FileName);
                setIsLoading(false)
                NotificationHandler('Record Saved Successfully!', enumUtil.enumtoaster.success)
            })
            .catch((error) => {
                setIsLoading(false)
                NotificationHandler("No File Found ", enumUtil.enumtoaster.error)
            })
    }
    const DeleteFile = (AttachmentPath) => {
        setIsLoading(true)
        ServerRequestProtectedParentID(API_URL + "/DeleteExpenseFile", 'post', {
            "AttachmentPath": AttachmentPath,
            "TableName": enumUtil.enumAPITableName.FileReference
        }).then((response) => {
            setIsLoading(false)
            setFileDeleted(false);
            // NotificationHandler('Expense Deleted Successfully!', enumUtil.enumtoaster.success)
        }).catch((error) => {
            NotificationHandler(error.message, enumUtil.enumtoaster.error)
            setIsLoading(false)
        })
    }

    ///Delete///
    const handleDeleteModal = () => {
        setIsOpenAlert(true)
    }
    let DeletePopup = ""
    if (del) {
        DeletePopup = <button className='btn-icon-transparent' onClick={handleDeleteModal}><i class="bi bi-trash-fill"></i></button>
    }
    const onCloseDelete = () => {
        setIsOpenAlert(false)
        onClose(true)
    }
    let ConfirmDelete = "";
    if (isOpenAlert) {
        ConfirmDelete = <DeleteConfirmation isOpenAlert={isOpenAlert} setIsOpenAlert={setIsOpenAlert} onCloseDelete={onCloseDelete} API_URL={API_URL} TableName={enumUtil.enumAPITableName.Expense} ID={ExpenseID} onClose={onClose} />
    }
    const handleOnClose = () => {
        setTemporaryRemove(false)
        onClose(newExpenseSaved)
    }
    //////////
    const handleTemporaryRemove = (e) => {
        e.preventDefault();
        setTemporaryRemove(true);
    }
    let downloadDeleteButton = "";
    if (fileDeleted) {
        downloadDeleteButton = <>
            <div className="d-flex align-align-items-center gap-1">
                <i className='bi bi-download fs-10' onClick={() => DownloadFile(mainExpenseModal.AttachmentPath)}></i>
                <i className='bi bi-trash fs-10' onClick={handleTemporaryRemove}></i>
            </div>
        </>;
    }
    return (
        <>
            {ConfirmDelete}
            <Modal show={isOpenExpense} onHide={onClose} backdrop="static" keyboard={false} centered dialogClassName="app-modal ems_modal modal-c-lg">
                <Modal.Body>
                    <div className='d-flex justify-content-end'>
                        <img className="close" src={close} onClick={handleOnClose}></img>
                        {/* {DeletePopup} */}
                    </div>
                    <div className="modal_header">
                        <div className="text-center">
                            <span className="icon">
                                <div className="icon_bg">
                                    <span className="icon-expense">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </span>
                                </div>
                            </span>
                            <p class="heading mt-2"> {SetExpenseName()}</p>
                        </div>
                    </div>
                    {isLoading ? <Loader /> : null}
                    <div className="row m-0 mt-2">
                        <div className="col-md-6 ps-0">
                            <div className="">
                                <div className={ExpenseValidationModal.ExpenseName ? "form-group input error" : "form-group input"}>
                                    <label>Name<span className="req">*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        name="ExpenseName"
                                        id="ExpenseName"
                                        maxLength={50}
                                        value={mainExpenseModal.ExpenseName}
                                        onChange={handleExpenseChange}
                                    />
                                    {ExpenseValidationModal.ExpenseName}
                                </div>
                            </div>
                            <div className={ExpenseValidationModal.ExpenseCategoryID ? "form-group input error" : "form-group input"}>
                                <label> Category<span className="req">*</span></label>
                                <select className="form-control"
                                    name="ExpenseCategoryID"
                                    id="ExpenseCategoryID"
                                    aria-label="Default select example"
                                    value={mainExpenseModal.ExpenseCategoryID}
                                    onChange={handleExpenseChange}
                                >
                                    <option value="">Select</option>
                                    {ddExpenseCategory.map((e, index) => (
                                        <option value={e.ExpenseCategoryID} key={index}>
                                            {e.CategoryName}
                                        </option>
                                    ))}
                                </select>
                                {ExpenseValidationModal.ExpenseCategoryID}
                            </div>
                            <div className={ExpenseValidationModal.OfficeID ? "form-group input error" : "form-group input"}>
                                <label> Office<span className="req">*</span></label>
                                <select className="form-control"
                                    name="OfficeID"
                                    id="OfficeID"
                                    aria-label="Default select example"
                                    value={mainExpenseModal.OfficeID}
                                    onChange={handleExpenseChange}
                                >
                                    <option value="">Select</option>
                                    {ddOffice.map((e, index) => (
                                        <option value={e.OfficeID} key={index}>
                                            {e.OfficeName}
                                        </option>
                                    ))}
                                </select>
                                {ExpenseValidationModal.OfficeID}
                            </div>
                            <div className="form-group input">
                                <DatePicker
                                    label='Expense Date'
                                    maxDate={new Date()}
                                    value={mainExpenseModal.ExpenseDate}
                                    openCalendarMenufunc={(event) => openCalendarMenu(event, 'ExpenseDate')}
                                    anchorEl={expenseDate}
                                    open={openExpenseDate}
                                    closeCalendarMenufunc={closeCalendarMenu}
                                    handleCalendarChangefunc={date => handleCalendarChange(date, 'ExpenseDate')}
                                    placeholder={true}
                                />
                            </div>
                            <div className="form-group input">
                                <label>Amount</label>
                                <input type="text"
                                    className="form-control"
                                    name="ExpenseAmount"
                                    id="ExpenseAmount"
                                    maxLength={7}
                                    value={mainExpenseModal.ExpenseAmount}
                                    onChange={handleExpenseChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                />
                            </div>
                            <div className="form-group input">
                                <label>Reference #</label>
                                <input type="text"
                                    className="form-control"
                                    name="ReferenceNumber"
                                    id="Reference Number"
                                    maxLength={35}
                                    value={mainExpenseModal.ReferenceNumber}
                                    onChange={handleExpenseChange}
                                    onKeyPress={(event) => handleNumericCheck(event)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 pe-0">
                            <div className="form-group input">
                                <label>Details</label>
                                <textarea type="text"
                                    className="form-control"
                                    name="ExpenseDetails"
                                    id="Expense Details"
                                    rows="6"
                                    maxLength={500}
                                    value={mainExpenseModal.ExpenseDetails}
                                    onChange={handleExpenseChange}
                                />
                            </div>
                            <div className="input">
                                <label>File</label>
                                <div className='upload-file'>
                                    <div className="row">
                                        <div>
                                            <label className='form-control d-flex align-items-center'>
                                                <input
                                                    type={`${mainExpenseModal.FileName ? !temporaryRemove ? 'text' : 'file' : 'file'}`}
                                                    className="form-control"
                                                    // readOnly={`${mainExpenseModal.FileName ? !temporaryRemove ? true : false : false }`}
                                                    name="File"
                                                    id="File"
                                                    onChange={savefile}
                                                    accept=" .gif, .jpg, .pdf "
                                                //  accept="image/*,application/pdf,text/plain" 
                                                />
                                                <div className={`${mainExpenseModal.FileName ? !temporaryRemove ? 'ar_document' : '' : ''} d-flex align-items-center`}>

                                                    <span className="validation_message text-nowrap me-1" title="">
                                                        {/* {console.log("UPLOADFILE", mainExpenseModal)} */}
                                                        <div className="d-flex align-items-center gap-1">
                                                            {mainExpenseModal.FileName ? temporaryRemove ? <>
                                                                <i className='icon-upload-file-icon fs-16'></i><p className="text fs-11 fst-italic clr_light"> Upload a File</p></> : mainExpenseModal.FileName : <> <i className='icon-upload-file-icon fs-16'></i> <p className="text fs-11 fst-italic clr_light"> Upload a File</p></>}
                                                        </div>
                                                    </span>
                                                    {temporaryRemove ? <></> : downloadDeleteButton}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-check mt-3 custom_check_box form-check-inline">
                                <label class="form-check-label" htmlFor="IsRefundable">Refundable</label>
                                <input type="checkbox"
                                    class="form-check-input"
                                    name="IsRefundable"
                                    id="IsRefundable"
                                    checked={mainExpenseModal.IsRefundable}
                                    onChange={handleExpenseChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <ModalFooter isSaved={onClose} handleSave={handleSaveExpense} />
                {/* <div className='btns-group text-end px-3 py-3 br-top-1px br-clr'>
                    <button className='btn btn-gray' onClick={handleOnClose}>Cancel</button>
                    <button className='btn btn-blue' onClick={handleSaveExpense}>Save</button>
                </div> */}
            </Modal>
        </>
    )
}

export default AddNewExpense