import BasicInformation from "../Account/BasicInformation/BasicInformation"
import Academics from "../Account/Academics/Academics"
import Certification from "../Account/Certification/Certification"
import Office from "../Account/Education/Education"
import EmployeeStockReference from "../Account/EmployeeStockReference/EmployeeStockReference"
import Experience from "../Account/Experience/Experience"
import JoiningDetailsU from "../Account/JoiningDetails/JoiningDetails"
import Skills from "../Account/Skill/Skills"
import Attendance from "../Account/Attendance/Attendance"
import EmployeeDocuments from "../Account/Documnets/Documents"
import EmployeeLeave from "../Account/EmployeeLeaves/EmployeeLeave"
import CareerGrowth from "../Account/CareerGrowth/CareerGrowth"

const EmployeeMenu = () => {

    return [
        {
            name: '',
            key: 21,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Basic Information',
                    key: 211,
                    icon: "",
                    component: <BasicInformation />,
                    right: true,
                },
                {
                    name: 'Academics',
                    key: 212,
                    icon: "",
                    component: <Academics />,
                    right: true,
                },
                // {
                //     name: 'Joining Details',
                //     key: 215,
                //     icon: "",
                //     component: <JoiningDetailsU/>,
                //     right: true,
                // },
             
                {
                    name: 'Allocated Devices',
                    key: 217,
                    icon: "",
                    component: <EmployeeStockReference/>,
                    right: true,
                },
                {
                    name: 'Documents',
                    key: 218,
                    icon: "",
                    component: <EmployeeDocuments/>,
                    right: true,
                },
                {
                    name: 'Career Growth',
                    key: 215,
                    icon: "",
                    component: <CareerGrowth/>,
                    right: true,
                },
                // {
                //     name: 'Career Status',
                //     key: 216,
                //     icon: "",
                //     component: <CareerGrowth/>,
                //     right: true,
                // },
                // {
                //     name: 'Attendance',
                //     key: 219,
                //     icon: "",
                //     component: <Attendance/>,
                //     right: true,
                // },
                // {
                //     name: 'Leaves',
                //     key: 220,
                //     icon: "",
                //     //component: <EmployeeLeave/>,
                //     right: true,
                // },
                // {
                //     name: 'Certification',
                //     key: 213,
                //     icon: "",
                //     component: <Certification />,
                //     right: true,
                // },
                // {
                //     name: 'Education',
                //     key: 214,
                //     icon: "",
                //     //component: <Office />,
                //     right: true,
                // },
               
                // {
                //     name: 'Skills',
                //     key: 216,
                //     icon: "",
                //     //component: <Skills/>,
                //     right: true,
                // },
               
                
               
            ]
        },
    ]
}


export default EmployeeMenu