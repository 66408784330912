import Organization from "../Account/Organization/Organization"
import Office from "../Account/Office/Office"
import Shifts from "../Account/Shifts/Shifts"
import Departments from "../Account/Departments/Departments"
import Designation from "../Account/Designation/Designation"
import Team from "../Account/Teams/Team"
import LunchAndDinner from "../Account/LunchAndDinner/LunchAndDinner"
import Vendor from "../Stock/Vendor/Vendor"
import Product from "../Stock/Product/Product"
import Stock from "../Stock/Stockk/Stock"
import LeaveType from "../Miscellaneous/LeaveType/LeaveType"
import BenefitType from "../Miscellaneous/Benefits/BenefitType"

import Latecountpolicies from "../Miscellaneous/late-count-policies/latecountpolicies"
import AddNewFile from "../Miscellaneous/FileType/FileName"
import OrganizationBenefit from "../Account/OrganizationBenefits/OrganizationBenefits"

import AttendanceStatus from "../Account/Calendar/Attendance/AttendanceStatus"
import CalendarEvents from "../Account/Calendar/Calendars/CalendarEvents"
import LeavePolicy from "../Miscellaneous/LeavePolicy/LeavePolicy"
import MedicalLeavePolicy from "../Miscellaneous/MedicalLeavePolicy/MedicalLeavePolicy"
import ProvidentFundPolicy from "../Miscellaneous/ProvidentFundPolicy/ProvidentFundPolicy"
import RolesAndRights from "../Miscellaneous/RolesAndRights/RolesAndRights"
import SwitchOrganization from "../../Setting/Account/SwitchOrganization/SwitchOrganization"
import ExceptionLog from "../Account/ExceptionLog/ExceptionLog"
import Announcement from "../Account/Announcements/Announcement"
import LeavePolicySearch from "../PolicyManagement/LeavePolicy/LeavePolicySearch"
// import Leaves from "../Account/Calendar/Leaves"
const SetupMenu = () => {

    return [
        {
            name: 'Admin',
            key: 61,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Organization',
                    key: 611,
                    icon: "",
                    component: <Organization />,
                    right: true,
                },
                {
                    name: 'Offices',
                    key: 612,
                    icon: "",
                    component: <Office />,
                    right: true,
                },
                {
                    name: 'Shifts',
                    key: 613,
                    icon: "",
                    component: <Shifts />,
                    right: true,
                },
                {
                    name: 'Designations',
                    key: 614,
                    icon: "",
                    component: <Designation />,
                    right: true,
                },
                {
                    name: 'Departments',
                    key: 615,
                    icon: "",
                    component: <Departments />,
                    right: true,
                },
                {
                    name: 'Teams',
                    key: 616,
                    icon: "",
                    component: <Team />,
                    right: true,
                },
                {
                    name: 'Announcement',
                    key: 617,
                    icon: "",
                    component: <Announcement />,
                    right: true,
                },
                {
                    name: 'Lunch And Dinner',
                    key: 618,
                    icon: "",
                    component: <LunchAndDinner />,
                    right: true,
                },
                {
                    name: 'Lunch And Desing',
                    key: 619,
                    icon: "",
                    component: <LunchAndDinner />,
                    right: true,
                },
            ]
        },
        {
            name: 'Stock',
            key: 62,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Product',
                    key: 621,
                    icon: "",
                    component: <Product />,
                    right: true,
                },
                {
                    name: 'Vendor',
                    key: 623,
                    icon: "",
                    component: <Vendor />,
                    right: true,
                },
                {
                    name: 'Stock',
                    key: 622,
                    icon: "",
                    component: <Stock />,
                    right: true,
                },
            ]
        },
        {
            name: 'Miscellaneous',
            key: 63,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Leave Type',
                    key: 631,
                    icon: "",
                    component: <LeaveType />,
                    right: true,
                },
                {
                    name: 'Benefit Type',
                    key: 632,
                    icon: "",
                    component: <BenefitType />,
                    right: true,
                },
                {
                    name: 'Document Type',
                    key: 634,
                    icon: "",
                    component: <AddNewFile />,
                    right: true,
                },
                {
                    name: 'Roles and Rights',
                    key: 912,
                    icon: "",
                    component: <RolesAndRights />,
                    right: true,
                },
                {
                    name: 'Leave Policy',
                    key: 635,
                    icon: "",
                    component: <LeavePolicy />,
                    right: true,
                },
                {
                    name: 'Medical Leave Policy  ',
                    key: 636,
                    icon: "",
                    component: <MedicalLeavePolicy />,
                    right: true,
                },
                {
                    name: 'Provident Fund Policy',
                    key: 637,
                    icon: "",
                    component: <ProvidentFundPolicy />,
                    right: true,
                },
                // {
                //     name: 'Late Count Policies',
                //     key: 633,
                //     icon: "",
                //     component: <Latecountpolicies/>,
                //     right: true,
                // },
            ]
        },
        {
            name: 'Calendar',
            key: 64,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Calendar Events',
                    key: 641,
                    icon: "",
                    component: <CalendarEvents />,
                    right: true,
                },
                // {
                //     name: 'Leaves',
                //     key: 642,
                //     icon: "",
                //     component: <Leaves/>,
                //     right: true,
                // },
                // {
                //     name: 'Late Count Policies',
                //     key: 643,
                //     icon: "",
                //     component: <LateCountPolicies/>,
                //     right: true,
                // },
                // {
                //     name: 'Attendance Status',
                //     key: 644,
                //     icon: "",
                //     component: <AttendanceStatus/>,
                //     right: true,
                // },
            ]
        },
        {
            name: 'Policy Management',
            key: 66,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Leave Policy',
                    key: 661,
                    icon: "",
                    component: <LeavePolicySearch/>,
                    right: true,
                },
                {
                    name: 'Increment Policy',
                    key: 662,
                    icon: "",
                    component: <CalendarEvents />,
                    right: true,
                },
                {
                    name: 'Tax Policy',
                    key: 663,
                    icon: "",
                    component: <CalendarEvents />,
                    right: true,
                },
                {
                    name: 'EPF Policy',
                    key: 664,
                    icon: "",
                    component: <CalendarEvents />,
                    right: true,
                },
                {
                    name: 'EOBF Policy',
                    key: 665,
                    icon: "",
                    component: <CalendarEvents />,
                    right: true,
                },
                // {
                //     name: 'Leaves',
                //     key: 642,
                //     icon: "",
                //     component: <Leaves/>,
                //     right: true,
                // },
                // {
                //     name: 'Late Count Policies',
                //     key: 643,
                //     icon: "",
                //     component: <LateCountPolicies/>,
                //     right: true,
                // },
                // {
                //     name: 'Attendance Status',
                //     key: 644,
                //     icon: "",
                //     component: <AttendanceStatus/>,
                //     right: true,
                // },
            ]
        },
        {
            name: 'Developer',
            key: 65,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'Switch Organization',
                    key: 651,
                    icon: "",
                    component: <SwitchOrganization />,
                    right: true,
                },
                {
                    name: 'Exception Log',
                    key: 652,
                    icon: "",
                    component: <ExceptionLog />,
                    right: true,
                },
            ]
        },
        
    ]
}


export default SetupMenu