import React from 'react'
import AddNewPayroll from '../PayrollComponents/PayrollAdmin/AddNewPayroll'

const PayrollMenu = () => {

    return [
        {
            name: '',
            key: 21,
            icon: "",
            right: true,
            subCategoryItems: [
                {
                    name: 'PayRoll',
                    key: 211,
                    icon: "",
                    component: <AddNewPayroll />,
                    right: true,
                },
                
               
            ]
        },
    ]
}


export default PayrollMenu
