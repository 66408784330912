import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Provider } from "react-redux";
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import Store from "./store";
import Login from './components/Login/Login'
import App from './App';
import ForgotPassword from './components/Login/ForgotPassword';
import ChangePassword from "./components/Login/ChangePassword"
import CheckEmail from "./components/Login/CheckEmail"
import ResetSuccessfull from "./components/Login/ResetSuccessfull"
import EmailVerification from "./components/Login/EmailVerification"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/ems" element={ <App />} />
        <Route path="/changepassword" element={<ChangePassword/>} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/checkemail" element={<CheckEmail />} />
        <Route path="/resetsuccessfull" element={<ResetSuccessfull />} />
        <Route path="/emailverification" element={<EmailVerification />} />
      </Routes>
    </Router>
</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();