import { useEffect, useState } from "react";
import format from 'date-fns/format';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DateRangePicker } from 'react-date-range';
import InputMask from 'react-input-mask';
import { startOfToday, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import isNull from "../../../utils/NullChecking";

const CalendaRangeInput = (props) => {
    const { label, placeholder, searchParam, setSearchParam, options } = props;
    let minSlctdDate = new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate());
    let maxSlctdDate = new Date(new Date().getFullYear() + 20, new Date().getMonth(), new Date().getDate());
    const [dates, setDate] = useState()
    const [position, setPosition] = useState(null);
    const openRangePicker = Boolean(position);
    const [selectedOption, setSelectedOption] = useState('');
    const [value, setValue] = useState(null); // changed empty string "" to null

    const [range, setRange] = useState([
        {
            startDate: new Date,
            endDate: null,
            key: 'selection'
        }
    ])
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const shortcutsItems = monthNames.map((monthName, index) => ({
        label: monthName,
        getValue: () => {
            const year = new Date().getFullYear();
            const start = new Date(year, index, 1);
            const end = endOfMonth(start);
            return [start, end];
        },
    }));
    const [dateType, setDateType] = useState(options[0].split(" ").join(""));
    /////////Date Type////////////////////
    const handleDateType = (event) => {
        setDateType(event.target.value);
        handleChange(dates, event.target.value)
    };

    //////////////////////////////////////
    useEffect(() => {
        let clear;
        for (let type of options) {
            if (isNull(searchParam[`${type.split(" ").join("")}From`])) { clear = true } else { clear = false; break; }
            if (isNull(searchParam[`${type.split(" ").join("")}To`])) { clear = true } else { clear = false; break; }
        }
        if (clear === true) {
            setValue("")
        }
    }, [searchParam, setSearchParam])
    //////////////////////////////////////
    const handleChange = (dates, dateType) => {
        if (!isNull(dates)) {
            let startDate, endDate;
            if (typeof dates === 'string') {
                const regex = /^(\d{2}\/\d{2}\/\d{4})/;
                const regexDouble = /^(\d{2}\/\d{2}\/\d{4})-(\d{2}\/\d{2}\/\d{4})$/;
                const matches = dates.match(regex);
                const matchesDouble = dates.match(regexDouble);
                if (matchesDouble) {
                    startDate = matchesDouble[1].split("/");
                    endDate = matchesDouble[2].split("/");
                    dates = [{
                        startDate: new Date(startDate[2], startDate[0] - 1, startDate[1]),
                        endDate: new Date(endDate[2], endDate[0] - 1, endDate[1]),
                        key: "selection"
                    },]
                    console.log("dates", dates);
                }
                else if (matches) {
                    startDate = matches[1].split("/");
                    dates = [{
                        startDate: new Date(startDate[2], startDate[0] - 1, startDate[1]),
                        endDate: null,
                        key: "selection"
                    },]
                    console.log("dates", dates);
                }
                else { console.log("Invalid dates string format"); }
                console.log("dates", dates);
                if (dates[0].startDate < minSlctdDate) {
                    dates[0].startDate = minSlctdDate;
                }
                if (dates[0].startDate > maxSlctdDate) {
                    dates[0].startDate = maxSlctdDate;
                }
                if (dates[0].endDate < minSlctdDate) {
                    dates[0].endDate = minSlctdDate;
                }
                if (dates[0].endDate > maxSlctdDate) {
                    dates[0].endDate = maxSlctdDate;
                }
                console.log("dates", dates);
                setDate(dates)
                setRange(dates)
                setValue(format(dates[0].startDate, 'MM/dd/yyyy') + ' ‐ ' + format(dates[0].endDate, 'MM/dd/yyyy'));
            }
            else {
                setDate(dates)
                setRange(dates);
                setValue(format(dates[0].startDate, 'MM/dd/yyyy') + ' ‐ ' + format(dates[0].endDate, 'MM/dd/yyyy'));
            }
            /////////////////////////////////////
            for (let type of options) {
                if (type.split(" ").join("") !== dateType) {
                    searchParam[`${type.split(" ").join("")}From`] = null
                    searchParam[`${type.split(" ").join("")}To`] = null
                }
            }
            // searchParam[`${dateType}From`] = format(dates[0].startDate, 'MM/dd/yyyy')
            // searchParam[`${dateType}To`] = format(dates[0].endDate, 'MM/dd/yyyy')
            // setSearchParam(searchParam) old 
            setSearchParam({
                ...searchParam,
                [`${dateType}From`]: format(dates[0].startDate, 'MM/dd/yyyy'),
                [`${dateType}To`]: format(dates[0].endDate, 'MM/dd/yyyy')
            });
        } else {
            setRange([
                {
                    startDate: null,
                    endDate: null,
                    key: 'selection'
                }
            ]);
            setSearchParam((prev) => ({
                ...prev,
                [`${dateType}From`]: null,
                [`${dateType}To`]: null,
            }))

        }
    }
    const show = (event) => { setPosition(event.currentTarget) };
    const hide = () => { setPosition(null) };
    /////////////////////////////////////////
    const handleCustomDate = (e) => {
        let valueArr = [2, 5, 10, 13, 16]
        let value = e.target.value.replace(/\D/g, '')
        // let valueLength = formatValue(value).length
        if (valueArr.includes(formatValue(value).length)) {
            e.target.setSelectionRange((formatValue(value).length) + 1, (formatValue(value).length) + 1)
        } else {
            e.target.setSelectionRange(formatValue(value).length, formatValue(value).length)
        }
        setValue(e.target.value);
        setValue(formatValue(e.target.value))
        handleChange(formatValue(e.target.value), dateType)
    }
    const formatValue = (value) => {
        const formattedString = value.replace(/\D/g, ''); // Remove all non-numeric characters
        let formattedValue = '';
        if (formattedString.length >= 1) { formattedValue += formattedString.slice(0, 2); }
        if (formattedString.length >= 3) { formattedValue += '/' + formattedString.slice(2, 4); }
        if (formattedString.length >= 5) { formattedValue += '/' + formattedString.slice(4, 8); }
        if (formattedString.length >= 9) { formattedValue += '-' + formattedString.slice(8, 10); }
        if (formattedString.length >= 11) { formattedValue += '/' + formattedString.slice(10, 12); }
        if (formattedString.length >= 13) { formattedValue += '/' + formattedString.slice(12, 16); }
        return formattedValue;
    };
    const handleSelectChange = (index) => {
        setSelectedOption(index)
        const selectedShortcut = shortcutsItems[index];
        const range = selectedShortcut.getValue();
        const startDate = range[0];
        const endDate = range[1];

        const newRange = [
            {
                startDate,
                endDate,
                key: 'selection'
            }
        ];

        setRange(newRange);
        setValue(format(startDate, 'MM/dd/yyyy') + ' ‐ ' + format(endDate, 'MM/dd/yyyy'));

        setSearchParam({
            ...searchParam,
            [`${dateType}From`]: format(startDate, 'MM/dd/yyyy'),
            [`${dateType}To`]: format(endDate, 'MM/dd/yyyy')
        });
    };
    ///////////////////////////////////////////////////
    return (<>
        <div className="input input calender hk-daterange-picker">
            {label === false ? <></> : <label htmlFor="">Date</label>}
            <div className='calender-icon'>
                <div className="calender-input w-100">
                    <InputMask className='form-control rounded'
                        {...(placeholder ? { placeholder: placeholder } : {})}
                        mask={"99/99/9999-99/99/9999"}
                        onChange={(e) => { handleCustomDate(e) }}
                        value={value} />
                    {/* <input
                        // ref={dateRangePickerInput}
                        value={value}
                        onChange={handleCustomDate}
                        className='form-control fc-xs rounded'
                        onFocus={()=>setRangeLengthNull(true)}
                        onClick={()=>setRangeLengthNull(true)}
                        onBlur={()=>setRangeLengthNull(false)}
                        id="form-control-input"
                        placeholder={rangeLengthNull ? placeholder : ""}
                    /> */}
                    <span className="icon-calender" onClick={show}><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span></span>
                </div>
                <span className="calender-btn btn-orange input-group-text p-0">
                    {options.length !== 1 &&
                        <Select
                            value={dateType}
                            onChange={handleDateType}
                            displayEmpty
                            className='app-mui-select-no-ui'>
                            {options.map((item) => {
                                return (
                                    <MenuItem value={item.split(" ").join("")}>{item.split(" ")[0]}</MenuItem>
                                )
                            })}
                        </Select>}
                </span>
            </div>
            <Menu
                className='calender-menu'
                anchorEl={position}
                open={openRangePicker}
                onClose={hide}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div className='DateRangePicker-Wrapper'>
                    <DateRangePicker
                        onChange={item => handleChange([item.selection], dateType)}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        months={2}
                        direction="horizontal"
                    // staticRanges={[]}
                    />
                    <div className='DateRangePicker-Action d-flex justify-content-between align-items-center px-1'>
                        <div className="input">
                            <select className="form-control"
                                value={selectedOption}
                                onChange={(event) => handleSelectChange(event.target.value)}>
                                <option value="">Select Type</option>
                                {shortcutsItems.map((item, index) => (
                                    <option key={index} value={index}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                            <button className='btn btn-gray btn-sm' onClick={hide}>Cancel</button>
                            <button className='btn btn-orange btn-sm' onClick={hide}>Apply</button>
                        </div>
                    </div>
                </div>
            </Menu>
        </div>
    </>);
}

export default CalendaRangeInput;