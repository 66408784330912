import React, { useEffect, useState } from 'react'
// import Modal from 'react-bootstrap/Modal'
// import close from "../../../assetsOld/img/x-close.svg"
// import ModalFooter from '../../../shared/ModalFooter/ModalFooter'
import ReactDataGrid, { textEditor } from "react-data-grid";
// import jwt_decode from 'jwt-decode';
import { SelectColumn } from "react-data-grid"
import { TotalPayroll } from "../../../../actions/TotalPayroll";
import { useDispatch, useSelector } from 'react-redux';
import NotificationHandler from "../../../../utils/NotificationHandler";
import { ServerRequestProtected, ServerRequestProtectedParentID } from "../../../../utils/ServerDataRequest";
import { enumUtil } from "../../../../utils/Enum";
import Loader from '../../../shared/Loader/Loader';
import { MonthsDropdown } from '../../../../utils/Constants';
import { NewTabAction, TabAction } from '../../../../actions/HandlePaymentTabAction';
// import OPEN_TAB_PAYROLL from '../../../../reducers/HandlePayrollTabReducer';
import handleNumericCheck from '../../../../utils/HandleNumericKeys';
import AmountFormatter from '../../../../utils/AmountFormatter';
import isNull from "../../../../utils/NullChecking"

const AddNewPayroll = () => {
    let OrgID = useSelector(state => state.Organization)
    let PayrollActive = useSelector(state => state)?.OPEN_TAB_PAYROLL?.find(item => item?.active);
    let ActivePayroll = useSelector(state => state.PayrollActiveTabReducer);
    let PayrollList = useSelector(state => state)?.OPEN_TAB_PAYROLL;
    // const [createPopup, setCreatePopup] = useState(ActivePayroll == 0.1 ? true : false)
    const [sidePopup, setSidePopup] = useState({})
    const [showSidePopup, setShowSidePopup] = useState(false)
    const [searchParam, setSearchParam] = useState('');
    // const [showPopup, setShowPopup] = useState(false)
    // const [tableView, setTableView] = useState(false)
    // console.log("data", PayrollList, PayrollActive)
    // console.log("data", PayrollList?.filter(item => item.data.id == ActivePayroll)?.[0]?.data?.FirstName)
    // console.log("ActivePayroll", ActivePayroll)
    const COLUMNS = [
        {
            ...SelectColumn,
        },
        {
            key: 'EmployeeID',
            name: 'Employee ID',
            flex: 1,
            // height: "40px",

            formatter: ({ row }) => {
                return (
                    <button className="btn border-0 p-0">
                        {row.EmployeeID}
                    </button>
                    // <>  {row.Month}</>
                );
            },
        },
        {
            key: 'EmployeeName',
            name: 'Name',
            flex: 1,
            // height: "40px",

            formatter: ({ row }) => {
                return (
                    <button className="btn bordor-0 p-0">
                        {row.EmployeeName}
                    </button>
                    // <>  {row.Month}</>
                );
            },
        },

        {
            key: 'JobTitle',
            name: 'Designation',
            // flex: 1,
            // width:"40px",
            formatter: ({ row }) => (
                <>
                    {row.JobTitle}
                </>
            ),
        },
        {
            key: 'WorkingDays',
            name: 'W. Days',
            flex: 1,
            formatter: ({ row }) => (
                <>

                    <div className='ar_sum-light'>
                        {row.WorkingDays}
                    </div>
                </>
            ),
        },
        {
            key: 'Present',
            name: 'P',
            flex: 1,
            formatter: ({ row }) => (
                <>
                    <div className='ar_sum-light'>
                        {row.Present}
                    </div>
                </>
            ),
        },
        {
            key: 'Leaves',
            name: 'L',
            flex: 1,
            editable: true, editor: textEditor,
            formatter: ({ row }) => (
                <>
                    <div className='ar_sum-light'>
                        <p className='leave-hove mb-0'>{row.Leaves}</p>
                        <div className="p-2 paid_leave">
                            <div className="pb-1 br-bottom-1 clr-light">
                                <p className="text fs-10 d-flex justify-content-between align-items-center">Paid Leaves <span className="d-flex align-items-center"><span className="fs-14 pe-2">|</span> 02</span></p>
                            </div>
                            <div>
                                <p className="text clr_gry fs-9 d-flex align-items-center"><span className="ar_box"></span>Half Day : 02</p>
                                <p className="text clr_gry fs-9 d-flex align-items-center"><span className="ar_box"></span>Full Day : 01</p>
                                <p className="text clr_gry fs-9 d-flex align-items-center"><span className="ar_box"></span>Short Leaves : 01</p>
                            </div>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: 'Absent',
            name: 'A',
            flex: 1,
            formatter: ({ row }) => (
                <>
                    <div className='ar_sum-light'>
                        {row.Absent}
                    </div>

                </>
            ),
        },
        {
            key: 'LateCount',
            name: 'Late',
            flex: 1,
            // editable: true, editor: textEditor,
            formatter: ({ row }) => (
                <>
                    <div className='ar_sum-light'>
                        {row.LateCount}
                    </div>
                </>
            ),
        },
        {
            key: 'BasicSalary',
            name: 'Basic',
            flex: 1,
            formatter: ({ row }) => (
                <>
                    <div className='ar_gros-light'>
                        {row.BasicSalary}
                    </div>

                </>
            ),
        },
        {
            key: 'Bonus',
            name: 'Bonus',
            flex: 1,
            editable: true, editor: textEditor,
            formatter: ({ row }) => (
                <>
                    <div className='ar_gros-light'>
                        {row.Bonus}
                    </div>

                </>
            ),
        },
        {
            key: 'TotalSalary',
            name: 'Total',
            flex: 1,
            formatter: ({ row }) => (
                <>
                    <div className='ar_gros-light'>
                        {row.TotalSalary}
                    </div>
                </>
            ),
        },
        {
            key: 'LeaveDeductions',
            name: 'Leave',
            flex: 1,
            // editable: true, editor: textEditor,
            formatter: ({ row }) => {
                return (
                    <div
                        className="office_name_truncate ar_detuct-light">
                        {row.LeaveDeductions}
                    </div>
                );
            },
        },
        {
            key: 'TaxAmount',
            name: 'Tax',
            flex: 1,
            editable: true, editor: textEditor,
            formatter: ({ row }) => {
                return (
                    <div
                        className="office_name_truncate ar_detuct-light">
                        {row.TaxAmount}
                    </div>
                );
            },
        },
        {
            key: 'ProvidentFund',
            name: 'EPF',
            flex: 1,
            editable: true, editor: textEditor,
            formatter: ({ row }) => (
                <>
                    <div
                        className="ar_detuct-light">
                        {row.ProvidentFund}
                    </div>
                </>
            ),
        },
        {
            key: 'TotalDeductions',
            name: 'Total',
            flex: 1,
            formatter: ({ row }) => (
                <>
                    <div
                        className="ar_detuct-light">
                        {row.TotalDeductions}
                    </div>
                </>
            ),
        },
        {
            key: 'NetSalary',
            name: 'Net Salary',
            flex: 1,
            formatter: ({ row }) => (
                <>
                    <div className='ar_net-sallery'>
                        {row.NetSalary}
                    </div>
                </>
            ),
        },
        {
            key: 'Status',
            name: 'Status',
            flex: 1,
            formatter: ({ row }) => (
                <>
                    <div className='ar_net-sallery'>
                        {row.Status}
                    </div>

                </>
            ),
        },
    ]
    const [columns, setColumns] = useState(COLUMNS)
    const [columnsType, setColumnsType] = useState({ AttendanceSummary: true, GrossPayElements: true, Deductions: true, NetSalary: true })
    // const { isPayRollOpen, onClose, ID } = { ...props }
    const [selectedRows, setSelectedRows] = useState(new Set())
    const [selectedPayrollID, setSelectedPayrollID] = useState([])
    const [payrollData, setPayrollData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [TotalPages, setTotalPages] = useState();
    const API_URL = process.env.REACT_APP_API;
    let dispatch = useDispatch();
    const currentMonth = new Date().getMonth() + 1;
    
    let TimeAdjustment_Modal = {
        Month: "",
        Year: "",
    }

    const [mainDropDownModal, setMainDropDownModal] = useState(TimeAdjustment_Modal);
    const [saveDropDownModal, setSaveDropDownModal] = useState({});

    const onRowsChange = (rows, { indexes }) => {
        console.log("ROWS", rows)
        const row = rows[indexes[0]];
        // if (row.type === 'MASTER') {
        //     if (!row.expanded) {
        //         rows.splice(indexes[0] + 1, 1);
        //     }
        //     else {
        //         // findRecord(row.ID)
        //         console.log("row",row)
        //         rows.splice(indexes[0] + 1, 0, {
        //             type: 'DETAIL',
        //             id: row.ID,
        //             parentId: row.ID,
        //             SubmittedBy : row.ID,
        //             ControlNumber : row.ControlNumber,
        //             AckStatus999 : row.AckStatus999,
        //             RejStatus277CA : row.RejStatus277CA,
        //             LastUpdatedDate :row.LastUpdatedDate,
        //         });
        //     }
        setPayrollData(rows)
        // }
    }

    const onRowSelection = (listOfIds, show) => {
        const arrayOfIds = Array.from(listOfIds); // Convert the Set to an array
        const lastSelectedId = arrayOfIds[arrayOfIds.length - 1]; // Get the last element in the array
        let data = PayrollActive?.data;
        let monthName = ActivePayroll === 0.1 ? MonthsDropdown?.[data?.searchData?.Month - 1]?.display : PayrollList?.filter(item => item.data.id == ActivePayroll)?.[0]?.data?.FirstName;
        let year = ActivePayroll === 0.1 ? data?.searchData?.Year : data?.year;
        payrollData?.map((item, index) => {
            if (item.EmployeeID === lastSelectedId) {
                setSidePopup(
                    <div className="create_payroll">
                        <div className="ar_top-heading d-flex align-items-center justify-content-between  pb-2 mb-1">
                            <div className="content">
                                <p className="heading clr">Create Payroll for the Month {monthName}</p>
                            </div>
                            <i className="icon-Icon-metro-cross fs-10" onClick={() => setShowSidePopup(false)}></i>
                        </div>
                        <div className='scroll'>
                            <div className='d-flex gap-1 align-items-center'>
                                <div className="img-container circle border-2 border-white s-50">
                                    <img src="https://www.w3schools.com/howto/img_avatar.png"></img>
                                </div>
                                <div>
                                    <p className='heading ps-2 fs-14'>{item.EmployeeName}</p>
                                    <button className="btn px-2 btn-blue cursor-default">{item.JobTitle}</button>
                                </div>
                            </div>
                            <div className="Gross_pay pb-2">
                                <p className="heading ar_header">Attendance Summary</p>
                                <div className="content row row-gap-10">
                                    <div className="col-md-6">
                                        <p className="text clr_gry">Total Working Days</p>
                                        <p className="text inp-box">{item.WorkingDays}</p>
                                    </div>
                                    <div className="col-md-6 pe-0">
                                        <p className="text clr_gry">Present</p>
                                        <p className="text inp-box">{item.Present}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="text clr_gry">Absent</p>
                                        <p className="text inp-box">{item.Absent}</p>
                                    </div>
                                    <div className="col-md-6 pe-0">
                                        <p className="text clr_gry">Leaves</p>
                                        <p className="text inp-box">{item.Leaves}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="text clr_gry">Late Count</p>
                                        <p className="text inp-box">{item.LateCount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="Gross_pay pb-2">
                                <p className="heading ar_header">Gross Pay Elements</p>
                                <div className="row content">
                                    <div className="form-group input col-md-4 pe-0">
                                        <label>Basic Salary</label>
                                        <input type="text"
                                            disabled
                                            className="form-control"
                                            name="BasicSalary"
                                            id="BasicSalary"
                                            value={item?.BasicSalary}
                                        />
                                    </div>
                                    <div className="form-group input col-md-4 pe-0">
                                        <label>Bonuses</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            value={item.Bonus}
                                            name='Bonus'
                                            id='Bonus'
                                            onChange={(e) => handleOnChange(e, index, "popup")}
                                            onKeyPress={(event) => handleNumericCheck(event)}
                                        />
                                    </div>
                                    <div className="form-group input col-md-4 pe-0">
                                        <label>Total Gross Salary</label>
                                        <input type="text"
                                            disabled
                                            className="form-control"
                                            name="TotalSalary"
                                            id="TotalSalary"
                                            value={item?.TotalSalary}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="Gross_pay pb-2">
                                <p className="heading ar_header">Deductions</p>
                                <div className="row content">
                                    <div className="form-group input col-md-4 pe-0">
                                        <label>Leave Deductions</label>
                                        <input type="text"
                                            disabled
                                            className="form-control"
                                            name="LeaveDeductions"
                                            id="LeaveDeductions"
                                            value={item.LeaveDeductions}
                                        />
                                    </div>
                                    <div className="form-group input col-md-4 pe-0">
                                        <label>Other Deductions</label>
                                        <input type="text"
                                            disabled
                                            className="form-control"
                                            name="OtherDeductions"
                                            id="OtherDeductions"
                                            value={item.OtherDeductions}
                                        />
                                    </div>
                                    <div className="form-group input col-md-4 pe-0">
                                        <label>Total Deductions</label>
                                        <input type="text"
                                            disabled
                                            className="form-control"
                                            name="TotalDeductions"
                                            id="TotalDeductions"
                                            value={item?.TotalDeductions}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="Gross_pay pb-2 mb-1">
                                <p className="heading ar_header blue">Net Salary</p>
                                <div className="row content">
                                    <div className="col-md-4 pe-0">
                                        <p className="text clr_gry">Total Gross Salary</p>
                                        <p className="text inp-box">{item?.TotalSalary}</p>
                                    </div>
                                    <div className="col-md-4 pe-0">
                                        <p className="text clr_gry">Total Deductions</p>
                                        <p className="text inp-box">{item?.TotalDeductions}</p>
                                    </div>
                                    <div className="col-md-4 pe-0">
                                        <p className="text clr_gry">Net Salary</p>
                                        <p className="text inp-box">{item?.NetSalary}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="orange-line d-flex justify-content-center align-items-center pb-2">
                            <p className="heading font-m text-white">Net Salary for the Month {monthName} {year} is {item.NetSalary} </p>
                        </div>
                    </div>
                )
            }
        })
        setShowSidePopup(show === "popup" ? true : false);
        setSelectedRows(lastSelectedId ? new Set([lastSelectedId]) : new Set()); // Set the last selected ID as a new Set
        setSelectedPayrollID(lastSelectedId);
    }

    const rowKeyGetter = (row) => {
        return row.EmployeeID;
    }

    const handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        setPayrollData((prevRows) => {
            const updatedRows = [...prevRows];
            for (let i = fromRow; i <= toRow; i++) {
                updatedRows[i] = { ...updatedRows[i], ...updated };
            }
            return updatedRows;
        });
    };
    //////// On Press Enter Search Bar working ////////
    const SearchOnEnter = (e) => {
        if (e.key === "Enter") {
            // setEnterParam(e.target.value)
            // setSearchParam(EnterParam)
            SearchByParam(searchParam)
        }
    }
    const SearchByParam = () => {
        if (!isNull(searchParam)) {
            // setPageNo(1)
            LoadData(searchParam)
        } else {
            LoadData()
        }
    }

    const LoadData = () => {
        console.log("PayrollActive?.data?.searchData?", PayrollActive?.data?.searchData)
        let modal = {
            "TableName": enumUtil.enumAPITableName.Payroll,
            "SearchCriteria":
            {
                "Month": ActivePayroll === 0.1 ? "0" : ActivePayroll,
                ...(ActivePayroll === 0.1 ? { "MonthName": MonthsDropdown.find(item => item.value === PayrollActive?.data?.searchData?.Month)?.display } : {}),
                ...(ActivePayroll === 0.1 ? { "Year": PayrollActive?.data?.searchData?.Year } : {}),
                // "OrganizationID": OrgID
            },
            SearchParam: searchParam,
        }
        setPayrollData([])
        setIsLoading(true);
        ServerRequestProtectedParentID(API_URL + "/FindByMultipleCriteria", 'post', modal).then((response) => {
            setIsLoading(false);
            response = response.map(data => ({
                ...data, BasicSalary: data.TotalSalary
            }))
            setPayrollData(response)
            setTotalPages(response[0].NumOfPages);
            dispatch(TotalPayroll(response[0].TotalCount))
        }).catch((error) => {
            setIsLoading(false);
            NotificationHandler(error?.response?.data, enumUtil.enumtoaster.error);
        })
    }

    useEffect(() => {
        if (ActivePayroll === 0.1) {
            console.log("PayrollActive", PayrollActive.data.searchData)
            LoadData();
        } else if (ActivePayroll !== 0.1) {
            LoadData();
        }
    }, [])

    const handleSavePayroll = async (e) => {
        setIsLoading(true)
        let stringPayrollData = payrollData.map(obj =>
            Object.fromEntries(
                Object.entries(obj).map(([key, value]) => [key, String(value)])
            )
        )
        let monthNo = ActivePayroll === 0.1 ? MonthsDropdown.find(item => item.display === PayrollActive.data.FirstName)?.value : mainDropDownModal.Month;
        let monthName = MonthsDropdown.find(item => item.value === PayrollActive?.data?.searchData?.Month)?.display;
        let saveModal = {
            tablename: enumUtil.enumAPITableName.Payroll,
            ID: ActivePayroll === 0.1 ? "0" : ActivePayroll,
            Data: {
                // "OrganizationID": OrgID,
                "MonthNo": monthNo,
                "MonthName": monthName,
                ...(ActivePayroll === 0.1 ? { "Year": PayrollActive?.data?.searchData?.Year } : {}),
                "PayrollStatus": "Finalized"
            },
            ListofData: stringPayrollData
            // value: null
        }
        ServerRequestProtected(API_URL + "/savesinglerecord", 'post', saveModal
        ).then((response) => {
            let obj = {
                key: 2,
                name: "Payroll",
                active: true,
                data: {
                    id: response?.id,
                    FirstName: monthName,
                    // PayrollDate: data.PayrollDate,
                    // Employees: data.Employees,
                    // Deductions: data.Deductions,
                    // TotalTax: data.TaxAmount,
                    // NetSalary: data.NetSalary,
                },
            };
            let arrList = PayrollList.map(item => {
                if (item.data.id === 0.1) {
                    return obj;
                } else { return item }
            })
            if (ActivePayroll === 0.1) {
                dispatch(NewTabAction(arrList));
            }
            NotificationHandler('Updated Successfully', enumUtil.enumtoaster.success)
            setIsLoading(false)
        }).catch((error) => {
            ActivePayroll === 0.1 ?
                NotificationHandler(error.response.data, enumUtil.enumtoaster.error) :
                NotificationHandler('Failed to Update', enumUtil.enumtoaster.error)
            setIsLoading(false)
        })
    }
    const EditColumns = (type) => {
        console.log("type", type)
        console.log("columns", columns)
        let AttendanceSummary = ['WorkingDays', 'Present', 'Leaves', 'Absent', 'LateCount'];
        let GrossPayElements = ['BasicSalary', 'Bonus', 'TotalSalary'];
        let Deductions = ['LeaveDeductions', 'TaxAmount', 'ProvidentFund', 'TotalDeductions'];
        let NetSalary = ['NetSalary', 'Status'];
        setColumnsType(prev => {
            prev = { ...prev, [type]: !prev[type] }
            setColumns(column => {
                let Data = [
                    !prev.AttendanceSummary ? AttendanceSummary : [],
                    !prev.GrossPayElements ? GrossPayElements : [],
                    !prev.Deductions ? Deductions : [],
                    !prev.NetSalary ? NetSalary : [],]
                // Data to not include //
                return COLUMNS.filter(item => ![].concat(...Data).includes(item.key))
            })
            return prev;
        })

    }
    const handleOnChange = (e, index, show) => {
        let data = payrollData[index];
        data[e.target.name] = e.target.value;
        if (e.target.name === 'Bonus') {
            data['TotalSalary'] = parseInt(data.BasicSalary, 10) + (data.Bonus && parseInt(data.Bonus, 10));
        }
        if (e.target.name === 'TaxAmount' || e.target.name === 'ProvidentFund' || e.target.name === 'LeaveDeductions') {
            data['TotalDeductions'] = (data.LeaveDeductions && parseInt(data.LeaveDeductions, 10)) + (data.TaxAmount && parseInt(data.TaxAmount, 10)) + (data.ProvidentFund && parseInt(data.ProvidentFund, 10));
        }
        data['NetSalary'] = parseInt(data['TotalSalary'], 10) - parseInt(data['TotalDeductions']);
        setPayrollData(prev => [...payrollData])
        onRowSelection(new Set([data['EmployeeID']]), show)
    }
    return (
        <div>
            {isLoading ? <Loader /> : <></>}
            {showSidePopup && sidePopup}
            <div className=" employee_screen payroll_screen">
                <div className="d-flex justify-content-between px-2 py-2 bottom_border btn-group align-items-center">
                    <p className="heading">Payroll  Month -<span className="text">
                        {ActivePayroll === 0.1 ?
                            MonthsDropdown.find(item => item.value === PayrollActive?.data?.searchData?.Month)?.display :
                            PayrollList?.filter(item => item.data.id == ActivePayroll)?.[0]?.data?.FirstName}</span></p>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="input">
                            {/* <select className="form-control fc-xs" id="Month" name="Month" value={mainDropDownModal.Month} onChange={(e) => handleMonthChange(e)}>
                                {MonthsDropdown.map((item) => {
                                    return (
                                        <option value={item.value} >{item.display}</option>
                                    )
                                })}
                            </select> */}
                        </div>
                    </div>
                </div>
                <div className="row m-0 mt-2 px-2">
                    <div className="col-md-3 p-0">
                        <div className="input">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control fc-xs"
                                    placeholder="Search by name or id…"
                                    onChange={(e) => setSearchParam(e.target.value)}
                                    value={searchParam}
                                    onKeyDown={SearchOnEnter}
                                />
                                <span className="input-group-search bg-orange br-clr-orange cursor-pointer" id="basic-addon2" onClick={() => LoadData()}>
                                    <p className="text text-light">Search</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex gap-15 justify-content-end">
                            {/* <input class="form-check-input position-absolute" type="checkbox" id="tableview" name="tableview" autocomplete="off" checked={tableView} onChange={(e) => setTableView(e.target.checked)} /> */}
                            <div className='d-flex align-items-center gap-2'>
                                <div>
                                    <p className="heading font-m">Employees</p>
                                    <p className="heading sm">{payrollData.length}</p>
                                </div>
                                <div className="left_border gross"></div>
                            </div>
                            <div className="d-flex gap-2 justify-content-end">
                                <div>
                                    <p className="heading font-m">Gross Pay Total</p>
                                    <p className="heading sm">{payrollData.reduce((total, current) => {
                                        const value = isNaN(parseInt(current.TotalSalary)) ? 0 : parseInt(current.TotalSalary);
                                        return total + value;
                                    }, 0)}</p>
                                </div>
                                <div className="left_border deu"></div>
                            </div>
                            <div className="d-flex gap-2 justify-content-end">
                                <div>
                                    <p className="heading font-m"> Deductions Total  </p>
                                    <p className="heading sm">{payrollData.reduce((total, current) => {
                                        const value = isNaN(parseInt(current.TotalDeductions)) ? 0 : parseInt(current.TotalDeductions);
                                        return total + value;
                                    }, 0)}</p>
                                </div>
                                <div className="left_border att"></div>
                            </div>
                            <div className="d-flex gap-2 justify-content-end">
                                <div>
                                    <p className="heading font-m">Net Salary Total  </p>
                                    <p className="heading sm">{payrollData.reduce((total, current) => {
                                        const value = isNaN(parseInt(current.NetSalary)) ? 0 : parseInt(current.NetSalary);
                                        return total + value;
                                    }, 0)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2 d-flex gap-10 justify-content-end">
                    <div className={`d-flex gap-5 att_summary align-items-center justify-content-between ${columnsType['AttendanceSummary'] ? '' : 'w-auto'}`}>
                        <p className="heading ">Attendance Summary</p>
                        <i className={`icon-angle-right`} onClick={() => EditColumns("AttendanceSummary")}></i>
                    </div>
                    <div className={`d-flex gap-5 gross_pay justify-content-between align-items-center ${columnsType['GrossPayElements'] ? '' : 'w-auto'}`}>
                        <p className="heading ">Gross Pay Elements </p>
                        <i className={`icon-angle-right`} onClick={() => EditColumns("GrossPayElements")}></i>
                    </div>
                    <div className={`d-flex gap-5 deduction justify-content-between align-items-center ${columnsType['Deductions'] ? '' : 'w-auto'}`}>
                        <p className="heading ">Deductions</p>
                        <i className={`icon-angle-right`} onClick={() => EditColumns("Deductions")}></i>
                    </div>
                    {/* <div className={`d-flex gap-5 net-salery  justify-content-between align-items-center ${columnsType['NetSalary'] ? '' : 'w-auto'}`}>
                        <p className="heading ">Net Salary</p>
                        <i className={`icon-angle-right`} onClick={() => EditColumns("NetSalary")}></i>
                    </div> */}
                </div>
                <table className='payroll_table d-block payroll_grid payroll_table overflow-auto'>
                    <thead>
                        <tr>
                            {columns.map((item, index) => (item.name ? <th key={index}>{item.name}</th> : null))}
                        </tr>
                    </thead>
                    <tbody>
                        {payrollData.map((item, index) => {
                            let showItems = columns.map(el => { return el.key });
                            return (
                                <tr key={index}>
                                    {showItems.includes('EmployeeID') && <td><div className="font-m heading">{item.EmployeeID}</div></td>}
                                    {showItems.includes('EmployeeName') && <td><p className="heading font-m link-text cursor-pointer" title={item.EmployeeName}
                                        onClick={() => onRowSelection(new Set([item.EmployeeID]), "popup")}
                                    >{item.EmployeeName}</p></td>}
                                    {showItems.includes('JobTitle') && <td>
                                        <div className='office_name_truncate' title={item.JobTitle}>
                                            {item.JobTitle}
                                        </div>
                                    </td>}
                                    {showItems.includes('WorkingDays') && <td className='ateendance-summary'>{item.WorkingDays}</td>}
                                    {showItems.includes('Present') && <td className='ateendance-summary'>{item.Present}</td>}
                                    {showItems.includes('Leaves') && <td className='ateendance-summary ar_sum-light'>
                                        <span className='leave-hove cursor-pointer '>
                                            {item.Leaves}
                                        </span>
                                        <div className="p-2 paid_leave">
                                            <div className="pb-1 br-bottom-1 clr-light">
                                                <p className="text fs-10 d-flex justify-content-between align-items-center">
                                                    Paid Leaves <span className="d-flex align-items-center"><span className="fs-14 pe-2">|</span> 02</span>
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text clr_gry fs-9 d-flex align-items-center"><span className="ar_box"></span>Half Day : 02</p>
                                                <p className="text clr_gry fs-9 d-flex align-items-center"><span className="ar_box"></span>Full Day : 01</p>
                                                <p className="text clr_gry fs-9 d-flex align-items-center"><span className="ar_box"></span>Short Leaves : 01</p>
                                            </div>
                                        </div>
                                    </td>}
                                    {showItems.includes('Absent') && <td className='ateendance-summary'>{item.Absent}</td>}
                                    {showItems.includes('LateCount') && <td className='ateendance-summary'>{item.LateCount}</td>
                                        // <td>
                                        //     <input
                                        //     className='ar_sum-light'
                                        //     value={item.LateCount}
                                        //     name='LateCount'
                                        //     id='LateCount'
                                        //     onChange={(e) => handleOnChange(e, index)}
                                        //     onKeyPress={(event) => handleNumericCheck(event)}
                                        //     />
                                        // </td>
                                    }
                                    {showItems.includes('BasicSalary') &&
                                        <td className='gross-pay'>{<AmountFormatter val={item?.BasicSalary} />}</td>}
                                    {showItems.includes('Bonus') && <td className='gross-pay'>
                                        <div className='input'>
                                            <input
                                                className='form-control'
                                                value={item.Bonus}
                                                name='Bonus'
                                                id='Bonus'
                                                onChange={(e) => handleOnChange(e, index)}
                                                onKeyPress={(event) => handleNumericCheck(event)}
                                            />
                                        </div>
                                    </td>}
                                    {showItems.includes('TotalSalary') && <td className='gross-pay'>{<AmountFormatter val={item?.TotalSalary} />}</td>}
                                    {showItems.includes('LeaveDeductions') &&
                                        // <td className='deduction'>{<AmountFormatter val={item?.LeaveDeductions} />}</td>
                                        <td className='deduction' >
                                            <div className='input'>
                                                <input
                                                    className='form-control'
                                                    value={item.LeaveDeductions}
                                                    name='LeaveDeductions'
                                                    id='LeaveDeductions'
                                                    onChange={(e) => handleOnChange(e, index)}
                                                    onKeyPress={(event) => handleNumericCheck(event)}
                                                />
                                            </div>
                                        </td>
                                    }
                                    {showItems.includes('TaxAmount') &&
                                        <td className='deduction'>
                                            <div className='input'>
                                                <input
                                                    className='form-control'
                                                    value={item.TaxAmount}
                                                    name='TaxAmount'
                                                    id='TaxAmount'
                                                    onChange={(e) => handleOnChange(e, index)}
                                                    onKeyPress={(event) => handleNumericCheck(event)}
                                                />
                                            </div>
                                        </td>}
                                    {showItems.includes('ProvidentFund') &&
                                        <td className='deduction'>
                                            <div className='input'>
                                                <input
                                                    className='form-control'
                                                    value={item.ProvidentFund}
                                                    name='ProvidentFund'
                                                    id='ProvidentFund'
                                                    onChange={(e) => handleOnChange(e, index)}
                                                    onKeyPress={(event) => handleNumericCheck(event)}
                                                />
                                            </div>
                                        </td>}
                                    {showItems.includes('TotalDeductions') && <td className='deduction'>{<AmountFormatter val={item?.TotalDeductions} />}</td>}
                                    {showItems.includes('NetSalary') && <td className='net-salary'>{<AmountFormatter val={item?.NetSalary} />}</td>}
                                    {showItems.includes('Status') && <td className='net-salary'>{item.Status}</td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="btns-group text-end px-3 br-top-1 br-bottom-1 br-clr d-flex align-items-center justify-content-end py-1 ar-tabs-action-bottom">
                {/* <button class="btn btn-gray" onClick={()=>TabAction({}, 0.1)}>Cancel</button> */}
                <button class="btn btn-orange" onClick={() => handleSavePayroll()}>Save</button>
            </div>
        </div>
    )
}

export default AddNewPayroll
