const initialState="";

const Collapse=(state=initialState,action)=>{

    switch(action.type){
        case "CollapseSideBar":
        if(state==="")
        {
        return state="active";
        }
        else{
            return state="";
        }

        default:return state;
    }
    
}
export default Collapse;