import React, { useEffect, useState } from "react";
//import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from 'reactstrap'
import SetupInnerLeftMenuContent from "./SetupInnerLeftMenuContent";
import SetupMenu from "./SetupMenu";

const SetupInnerLeftMenu = (props) => {

    const MenuItems = SetupMenu();
    const [subCategItems, setSubCategItems] = useState([])
    const [tabList, setTabsList] = useState([])
    const [activeTab, setActiveTab] = useState()
    // const Organization=useSelector((state)=>state.Organization);
    // const Office=useSelector((state)=>state.ResponseDataReducer);

    useEffect(() => {
        const selected_Item_List = MenuItems.filter((item) => item.key === props.subCategKey);
        if (selected_Item_List && selected_Item_List.length > 0) {
            let subCategories = selected_Item_List[0].subCategoryItems
            setSubCategItems(subCategories)
            setActiveTab(subCategories[0].key)
        }
    }, [props.subCategKey])

    const handleActiveTab = (item) => {
        const { key, name, right,component } = { ...item }
        let dynamicTabs = [...tabList]
        if (tabList.length === 0) {
            const activeTabElement = subCategItems.filter((item) => item.key === activeTab)
            const obj = {
                key: activeTabElement.key,
                name: activeTabElement.name,
                right: activeTabElement.right,
                component: activeTabElement.component,
            }
            dynamicTabs.push(obj)
        }
        const newTabObject = {
            key: key,
            name: name,
            right: right,
            component: component,
        }
        const isTabExist = dynamicTabs.filter((tab) => {
            return tab.name === name
        })
        if (!(isTabExist && isTabExist.length > 0)) {
            dynamicTabs = [...dynamicTabs, newTabObject]
            setTabsList(dynamicTabs)
        }
        if (activeTab !== key) {
            setActiveTab(key)
        }
    }
    return (
        <div className='app-tabs inner_tabs'>
                <Nav className="rb-tabs-v2">
                    {subCategItems && subCategItems.map((item) => {
                        return (
                            <React.Fragment key={item.key}>
                                <NavItem onClick={() => handleActiveTab(item)}>
                                    <NavLink href="#" className={activeTab === item.key ? "active py-1" : "py-1" } >
                                        {/* <i className={item.key===611 && Organization? "icon-Icon-awesome-check-circle": ""}></i>
                                        <i className={item.key===612 && Office? "icon-Icon-awesome-check-circle": ""}></i> */}
                                       {item.name}
                                    </NavLink>
                                </NavItem>
                            </React.Fragment>
                        )

                    })}
                </Nav>
            <SetupInnerLeftMenuContent activeTab={activeTab} dynamicTabs={tabList} subCategItems={subCategItems} />
           </div>
    )
}

export default SetupInnerLeftMenu