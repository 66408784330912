export const Role = (key) => {
    return {
        type:'Role',
        payload: key,
    }
}
export const SubRole = (key) => {
    return {
        type:'SubRole',
        payload: key,
    }
}